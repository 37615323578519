import React from 'react'
import PropTypes from 'prop-types'
import InternalError from './InternalError'

class AppErrorHandler extends React.Component {
  static propTypes = {
    onError: PropTypes.func,
    children: PropTypes.node,
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  state = { error: null }

  componentDidCatch(error, info) {
    console.error('Uncaught React error:', error, info)

    if (this.props.onError) {
      this.props.onError(error, info)
    }
  }

  render() {
    if (this.state.error) {
      return <InternalError />
    }

    return this.props.children
  }
}

export default AppErrorHandler
