import React from 'react'
import DashboardPanel from './DashboardPanel'
import MessageCard from '../cards/MessageCard'
import imgMessage1 from '../cards/images/message1.jpg'
import imgMessage2 from '../cards/images/message2.jpg'
import imgMessage3 from '../cards/images/message3.jpg'
import withTranslations from '../../hocs/withTranslations'

const FeaturePanel = ({ i18n }) => {
  return (
    <DashboardPanel size="one-whole">
      <div className="feature-panel-content">
        <MessageCard
          title={i18n('welcome-dashboard:discover')}
          description={i18n('welcome-dashboard:discover-description')}
          image={imgMessage1}
        />
        <MessageCard
          title={i18n('welcome-dashboard:connect')}
          description={i18n('welcome-dashboard:connect-description')}
          image={imgMessage2}
        />
        <MessageCard
          title={i18n('welcome-dashboard:report')}
          description={i18n('welcome-dashboard:report-description')}
          image={imgMessage3}
        />
      </div>
    </DashboardPanel>
  )
}

export default withTranslations(FeaturePanel)
