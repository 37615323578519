import React from 'react'
import PropTypes from 'prop-types'

import { PasswordInput } from '@redradix/components.input'
import Button from '@redradix/components.button'
import AnchorButton from './buttons/AnchorButton'
import AnchorLink from './texts/AnchorLink'
import FormattedFormErrors from './FormattedFormErrors'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../hocs/withFormErrors'
import CoobisPropTypes from '../app/common/coobis-prop-types'
import {
  ROUTE_PASSWORD_RECOVERY_REQUEST,
  ROUTE_ROOT,
} from '../app/common/routes'
import withTranslations from '../hocs/withTranslations'
import WarningMessage from './messages/WarningMessage'
import { PASSWORD_MAX_LENGTH } from '../config/constants'
import RecaptchaTermsConditions from './RecaptchaTermsConditions'

const PasswordRecoveryReset = ({
  isResetPending,
  isInvalidToken,
  // withTranslations
  i18n,
  // withFormErrors
  validationMessages,
  globalMessages,
  // withFormik
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
}) => {
  let content

  if (isInvalidToken) {
    content = (
      <>
        <WarningMessage
          text={<p>{i18n('errors:password-reset-token-not-found')}</p>}
        />

        <AnchorButton
          widthBehaviour="full"
          linkUrl={ROUTE_PASSWORD_RECOVERY_REQUEST.linkTo()}
        >
          {i18n('password-reset:request-new')}
        </AnchorButton>

        <AnchorLink
          linkUrl={ROUTE_ROOT.linkTo()}
          text={i18n('password-reset:action-close')}
        />
      </>
    )
  } else {
    content = (
      <>
        <PasswordInput
          name="password"
          widthBehaviour="full"
          label={i18n('label:password')}
          placeholder={i18n('label:password')}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isResetPending}
          isTouched={touched.password}
          maxLength={PASSWORD_MAX_LENGTH}
          errorText={
            validationMessages.password && (
              <FormattedFormErrors errors={validationMessages.password} />
            )
          }
          autoComplete="new-password"
        />

        <PasswordInput
          name="confirmPassword"
          widthBehaviour="full"
          label={i18n('label:confirm-password')}
          placeholder={i18n('label:confirm-password')}
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isResetPending}
          isTouched={touched.confirmPassword}
          maxLength={PASSWORD_MAX_LENGTH}
          errorText={
            validationMessages.confirmPassword && (
              <FormattedFormErrors
                errors={validationMessages.confirmPassword}
              />
            )
          }
          autoComplete="new-password"
        />

        {globalMessages && (
          <WarningMessage
            text={<FormattedFormErrors errors={globalMessages} />}
          />
        )}

        <Button
          type="submit"
          hierarchy="tertiary"
          widthBehaviour="full"
          isLoading={isResetPending}
        >
          {i18n('password-reset:action-password-reset')}
        </Button>

        <RecaptchaTermsConditions i18n={i18n} />

        <p>
          <AnchorLink
            linkUrl={ROUTE_ROOT.linkTo()}
            text={i18n('password-reset:action-close')}
          />
        </p>
      </>
    )
  }

  return (
    <div className="login-form-wrapper is-public">
      <form onSubmit={handleSubmit} className="public-form" noValidate>
        <h2>{i18n('password-reset:password-reset')}</h2>

        {content}
      </form>
    </div>
  )
}

const FORM_VALUES_PROPTYPES = {
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
}

PasswordRecoveryReset.propTypes = {
  isResetPending: PropTypes.bool.isRequired,
  isInvalidToken: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(PasswordRecoveryReset)
