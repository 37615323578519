import BriefingInstagramPostForm from '../../ui/checkout/BriefingInstagramPostForm'
import withInstagramPostForm from './withInstagramPostForm'
import { compose, withPropsOnChange } from 'recompose'
import withTranslations from '../../hocs/withTranslations'
import { SERVICE_TYPES } from '../../services/orders/business'
import { connect } from 'react-redux'
import { submitInstagramPhotoBriefing } from '../../services/checkout'
import { withCart } from '../../services/checkout/hocs'

const enhance = compose(
  withTranslations,
  withCart,

  withPropsOnChange(['i18n'], ({ i18n }) => ({
    title: i18n('service-type:instagram-photo'),
  })),

  connect(
    null,
    { submitBriefing: submitInstagramPhotoBriefing },
  ),

  withInstagramPostForm(SERVICE_TYPES.INSTAGRAM_PHOTO),
)

export default enhance(BriefingInstagramPostForm)
