import React from 'react'
import { Input } from '@redradix/components.input'

const FormattedNumberInput = React.forwardRef((props, ref) => (
  <div className="input-number" ref={ref}>
    <Input
      {...props}
      appearance="box"
      behaviour="static"
      widthBehaviour="full"
      type="text"
      // Mobile number keys
      inputMode="decimal"
      pattern="[0-9]*"
    />
  </div>
))

export default FormattedNumberInput
