import { withPropsOnChange } from 'recompose'
import * as R from 'ramda'
import { getGenderAudienceData } from '../common/statistics-utils'

const withGenderAudience = withPropsOnChange(
  ['stats', 'i18n', 'i18nNumber'],
  ({ stats, i18n, i18nNumber }) => ({
    genderData: getGenderAudienceData(
      i18n,
      i18nNumber,
      R.pick(['male', 'female'], stats.stats.demographics),
    ),
  }),
)

export default withGenderAudience
