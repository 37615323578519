import React from 'react'
import { compose, branch, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import WebResources from '../../ui/web-resources/WebResources'
import WebResourcesGeneralDataForm from '../../ui/web-resources/WebResourcesGeneralDataForm'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withTranslations from '../../hocs/withTranslations'
import {
  WEB_RESOURCE_SECTIONS,
  RESOURCE_TYPES,
} from '../../services/resources/constants'
import {
  CREATE_WEB_RESOURCE,
  EDIT_WEB_RESOURCE_GENERAL_DATA,
} from '../../services/resources/action-types'
import {
  createWebResource,
  editWebResourceGeneralData,
} from '../../services/resources'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withLanguageSelectField, {
  LANGUAGES,
} from '../../hocs/withLanguageSelectField'
import withCountrySelectField from '../../hocs/withCountrySelectField'
import withCategoriesSelectField from '../../hocs/withCategoriesSelectField'
import { getWebResourcesGeneralData } from '../../services/resources/business'
import { webResourcesGeneralDataSchema } from '../../services/resources/validation'
import {
  withReachedWebResourceStep,
  withUploadedImage,
} from '../../services/resources/hocs'
import withImageField from './withImageField'
import withHolidays from './withHolidays'
import { withCountries, withCategories } from '../../services/config/hocs'
import withWebResource from './withWebResource'

const WebResourcesGeneralData = ({ i18n, ...props }) => (
  <WebResources
    title={i18n('sections:data-long')}
    currentNavItem={WEB_RESOURCE_SECTIONS.GENERAL_DATA}
    reachedNavItem={props.reachedNavItem}
    isEditing={props.isEditing}
    resourceId={props.resourceId}
  >
    <WebResourcesGeneralDataForm {...props} />
  </WebResources>
)

const enhance = compose(
  withTranslations,

  withWebResource(WEB_RESOURCE_SECTIONS.GENERAL_DATA),

  branch(
    ({ isEditing }) => !isEditing,

    // Creation
    compose(
      withIsRequestPending(CREATE_WEB_RESOURCE),
      withIsRequestSucceeded(CREATE_WEB_RESOURCE),
      withRequestError(CREATE_WEB_RESOURCE),
      withDeleteRequestOnUnmount(CREATE_WEB_RESOURCE),
      connect(
        null,
        {
          submitForm: createWebResource,
        },
      ),
    ),

    // Edition
    compose(
      withIsRequestPending(EDIT_WEB_RESOURCE_GENERAL_DATA),
      withIsRequestSucceeded(EDIT_WEB_RESOURCE_GENERAL_DATA),
      withRequestError(EDIT_WEB_RESOURCE_GENERAL_DATA),
      withDeleteRequestOnUnmount(EDIT_WEB_RESOURCE_GENERAL_DATA),
      connect(
        null,
        (dispatch, ownProps) => ({
          submitForm: data =>
            dispatch(
              editWebResourceGeneralData(ownProps.resourceData.id, data),
            ),
        }),
      ),
    ),
  ),

  withReachedWebResourceStep(WEB_RESOURCE_SECTIONS.GENERAL_DATA),

  withUploadedImage,
  withCountries,
  withCategories,
  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ isEditing, resourceData }) =>
      isEditing
        ? getWebResourcesGeneralData({ resourceData })
        : {
            name: '',
            url: '',
            countryId: null,
            languageId: null,
            categoryId: null,
            secondaryCategoryId: null,
            description: '',
            acceptsFollowLinks: true,
            acceptsWritingArticles: true,
            marksSponsoredContent: true,
            holidaysBeginAt: null,
            holidaysEndAt: null,
          },

    selectFields: [
      'countryId',
      'languageId',
      'categoryId',
      'secondaryCategoryId',
    ],
    dateFields: ['holidaysBeginAt', 'holidaysEndAt'],

    validationSchema: webResourcesGeneralDataSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSend = {
        ...values,
        fileName: props.lastTemporaryImage
          ? props.lastTemporaryImage.fileName
          : undefined,
        holidaysBeginAt: undefined,
        holidaysEndAt: undefined,
        holidays: {
          beginAt: values.holidaysBeginAt,
          endAt: values.holidaysEndAt,
        },
        type: RESOURCE_TYPES.WEB,
      }

      props.submitForm(valuesToSend)
    },
  }),

  withHandlers({
    // Make marksSponsoredContent boolean value
    handleChange: props => (...args) => {
      const fieldName = args[0].target.name

      if (fieldName === 'marksSponsoredContent') {
        return props.handleChange(
          {
            target: {
              name: fieldName,
              value: args[0].target.value === 'true' ? true : false,
            },
          },
          ...args.slice(1),
        )
      }

      return props.handleChange(...args)
    },
  }),

  withHolidays,

  // Normalize holidays field names for server errors
  withPropsOnChange(['requestError'], ({ requestError, ...props }) => ({
    requestError:
      requestError && requestError.validationMessages
        ? {
            ...requestError,
            validationMessages: {
              ...requestError.validationMessages,
              holidaysBeginAt: requestError.validationMessages.beginAt,
              beginAt: undefined,
              holidaysEndAt: requestError.validationMessages.endAt,
              endAt: undefined,
            },
          }
        : requestError,
  })),

  withFormErrors([
    'name',
    'url',
    'countryId',
    'languageId',
    'categoryId',
    'secondaryCategoryId',
    'description',
    'acceptsFollowLinks',
    'acceptsWritingArticles',
    'marksSponsoredContent',
    'holidaysBeginAt',
    'holidaysEndAt',
  ]),

  withCountrySelectField({ valueField: 'countryId' }),
  withLanguageSelectField(LANGUAGES, { valueField: 'languageId' }),
  withCategoriesSelectField({ categoryValueField: 'categoryId' }),
  withCategoriesSelectField({ categoryValueField: 'secondaryCategoryId' }),

  withImageField,
)

export default enhance(WebResourcesGeneralData)
