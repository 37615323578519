import * as actionTypes from './action-types'

export const fetchMyAlerts = language => ({
  type: actionTypes.FETCH_MY_ALERTS,
  payload: { language },
})

export const periodicFetchMyAlerts = language => ({
  type: actionTypes.PERIODIC_FETCH_MY_ALERTS,
  payload: { language },
})

export const discardAlert = alertId => ({
  type: actionTypes.DISCARD_ALERT,
  payload: { alertId },
})
