import { compose, withState, withProps, withPropsOnChange } from 'recompose'
import { withFormik } from 'formik'
import * as R from 'ramda'
import { isNilOrEmpty, isFile } from '../app/utils'

const transformUploadedFile = obj =>
  obj
    ? {
        name: obj.clientFileName || obj.fileName,
        size: obj.fileSize,
        type: obj.mimeType,
        isUploaded: true,
      }
    : null

// withFormik alternative with enhanced functionality
const withEnhancedFormik = (
  { fileFields = [], selectFields = [], dateFields = [], ...formikConfig },
  {
    lastSubmittedValuesProp = 'lastSubmittedValues',
    touchedSinceSubmitProp = 'touchedSinceSubmit',
  } = {},
) => {
  const touchedSinceSubmitSetterProp = `set${lastSubmittedValuesProp}`

  return compose(
    withState(lastSubmittedValuesProp, touchedSinceSubmitSetterProp, {}),
    withFormik({
      ...formikConfig,

      // Normalize server data for file fields
      mapPropsToValues: props => {
        const values = formikConfig.mapPropsToValues(props)
        if (fileFields && fileFields.length > 0) {
          for (const fileField of fileFields) {
            const value = values[fileField]

            // Only transform one time and when is not file
            values[fileField] =
              isFile(value) || R.prop('isUploaded', value)
                ? value
                : transformUploadedFile(value)
          }
        }

        return values
      },

      // Proxy for Formik's handleSubmit
      handleSubmit: (values, formikBag) => {
        let cleanValues = R.pipe(
          // Backend expects `false` instead of `null` to clear files
          isNilOrEmpty(fileFields)
            ? R.identity
            : R.mapObjIndexed((value, key) =>
                fileFields.includes(key)
                  ? value === null
                    ? false
                    : value
                  : value,
              ),

          // Backend expects `false` instead of `null` to clear selects
          isNilOrEmpty(selectFields)
            ? R.identity
            : R.mapObjIndexed((value, key) =>
                selectFields.includes(key)
                  ? value === null
                    ? false
                    : value
                  : value,
              ),

          // Backend expects `false` instead of `null` to clear dates
          isNilOrEmpty(dateFields)
            ? R.identity
            : R.mapObjIndexed((value, key) =>
                dateFields.includes(key)
                  ? value === null
                    ? false
                    : value
                  : value,
              ),
        )(values)

        // Delegate to config handleSubmit
        formikConfig.handleSubmit(cleanValues, formikBag)

        // Save submitted values to check if they have been changed since submit
        formikBag.props[touchedSinceSubmitSetterProp](values)
      },
    }),

    // Inject prop to check which fields have been touched since submit,
    // regardless of success or error
    //
    // Used later to decide if we should show server errors
    withProps(ownProps => ({
      [touchedSinceSubmitProp]: R.mapObjIndexed((value, key) => {
        const lastSubmittedValues = ownProps[lastSubmittedValuesProp]
        const prevValues = Object.keys(lastSubmittedValues).length
          ? lastSubmittedValues
          : ownProps.initialValues
        const prevValue = prevValues[key]
        const notEquals = R.complement(R.equals)

        // NOT TESTED!! The backend needs to send us `clientFilename`
        // For Files we need to compare equal types but the server returns an
        // Object when we submit a File. Fix: Normalize server Object and JS Files
        if (isFile(prevValue) && !isFile(value)) {
          return notEquals(
            {
              name: prevValue.name,
              size: prevValue.size,
              type: prevValue.type,
              isUploaded: true,
            },
            value,
          )
        }
        // Default case
        return notEquals(prevValue, value)
      })(ownProps.values),
    })),

    // Remove unneeded injected props
    withProps(R.omit([lastSubmittedValuesProp, touchedSinceSubmitSetterProp])),

    // Formik allows nested values but, since we have objects as values (for files and dates)
    // it's treating inner values as values and setting them as touched: true
    // instead of setting the whole file key as touched
    //
    // Force them as booleans
    withPropsOnChange(['touched'], ({ touched }) => {
      return {
        touched: {
          ...touched,
          ...R.pipe(
            R.map(fieldName => [
              fieldName,
              !!touched[fieldName] ? true : undefined,
            ]),
            R.fromPairs,
          )([...fileFields, ...dateFields]),
        },
      }
    }),

    // Special handling of changes for non-native fields
    withPropsOnChange(['handleChange'], ({ handleChange }) => ({
      handleChange: e => {
        // File inputs
        if (fileFields && fileFields.includes(e.target.name)) {
          return handleChange({
            target: { name: e.target.name, value: e.target.files[0] || null },
          })
        }

        // Default case
        return handleChange(e)
      },
    })),
  )
}

export default withEnhancedFormik
