import React from 'react'
import PropTypes from 'prop-types'

const TooltipRegularText = props => {
  return <p className="tooltip-regular-text">{props.text}</p>
}

TooltipRegularText.propTypes = {
  text: PropTypes.any,
}

export default TooltipRegularText
