import { connect } from 'react-redux'
import { getHasRequestWithIdError } from '../services/communication/selectors'

const withHasRequestWithIdError = (
  requestType,
  { idProp = 'id', outProp = 'hasRequestError' } = {},
) =>
  connect((state, ownProps) => ({
    [outProp]: getHasRequestWithIdError(requestType, ownProps[idProp], state),
  }))

export default withHasRequestWithIdError
