// Import moment locales for react-dates
import 'moment'
// import 'moment/locale/en' // No need to import! Locale is already loaded
import 'moment/locale/es'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/it'

const LOCALES = [
  { locale: 'es', match: /es/, thousandsHackEnabled: true },
  { locale: 'en', match: /en/ },
  { locale: 'fr', match: /fr/ },
  { locale: 'it', match: /it/ },
]

export default LOCALES
