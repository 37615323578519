import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailFacebookPublication from '../../ui/campaigns/views/CampaignDetailFacebookPublication'
import withExportReportContents from './withExportReportContents'

const enhance = compose(
  withPropsOnChange(['stats'], ({ stats }) => ({
    reach: stats.kpis.reach,
    engagements: stats.kpis.engagements,
    engagementRate: stats.kpis.engagementRate,
    clicks: stats.kpis.clicks,
    likes: stats.kpis.likes,
    comments: stats.kpis.comments,
  })),
  withExportReportContents(),
)

export default enhance(CampaignDetailFacebookPublication)
