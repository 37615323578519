import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailInstagramAll from '../../ui/campaigns/views/CampaignDetailInstagramAll'
import { mapServiceTypeToOrderTypeI18n } from '../../services/orders/business'
import withTranslations from '../../hocs/withTranslations'
import {
  getInstagramServiceEngagementData,
  getInstagramResourceEngagementData,
  getUserBarChartData,
} from '../common/statistics-utils'
import withPublicationsTable from './withPublicationsTable'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  publications: stats.kpis.totalOrders,
  influencers: stats.kpis.totalResources,
  reach: stats.kpis.totalReach,
  engagements: stats.kpis.totalEngagements,
  likes: stats.kpis.totalLikes,
  views: stats.kpis.totalViews,
  comments: stats.kpis.totalComments,
  saved: stats.kpis.totalSaved,
}))

const withInstagramPublicationsTable = compose(
  withTranslations,

  withPublicationsTable(
    (orderStatsDetail, { i18n }) => ({
      service: i18n(
        mapServiceTypeToOrderTypeI18n[orderStatsDetail.serviceType],
      ),
      reach: orderStatsDetail.kpis.reach,
      engagements: orderStatsDetail.kpis.engagements,
      likes: orderStatsDetail.kpis.likes,
    }),
    ['i18n'],
  ),
)

const withEngagementCharts = compose(
  withPropsOnChange(
    ['stats', 'i18n', 'i18nNumber'],
    ({ stats, i18n, i18nNumber }) => {
      const { engagementsByService, engagementsByResource } = stats.stats

      return {
        serviceEngagementData: getInstagramServiceEngagementData(
          engagementsByService,
          i18n,
          i18nNumber,
        ),
        influencerEngagementData: getInstagramResourceEngagementData(
          engagementsByResource,
          i18n,
          i18nNumber,
        ),
      }
    },
  ),
)

const withReachChart = compose(
  withPropsOnChange(['stats', 'i18n'], ({ stats, i18n }) => ({
    reachByInfluencer: getUserBarChartData(stats.stats.reachByResource, i18n),
  })),
)

const enhance = compose(
  withTranslations,
  withKPIs,
  withInstagramPublicationsTable,
  withEngagementCharts,
  withReachChart,
  withExportReportContents(),
)

export default enhance(CampaignDetailInstagramAll)
