import React from 'react'
import ProfilePicture from '../form-elements/ProfilePicture'

const PictureProfileForm = props => (
  <div className="account-block-form">
    <ProfilePicture {...props} />
  </div>
)

export default PictureProfileForm
