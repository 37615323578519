import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import IconSocial from '../icons/IconSocial'
import {
  mapServiceTypeToResourceType,
  mapServiceTypeToOrderTypeI18n,
  SERVICE_TYPES,
} from '../../services/orders/business'

const OrderDetailGeneralData = ({ brand, web, serviceType, i18n }) => {
  return (
    <OrderDetailSection title={i18n('sections:data-long')}>
      {brand && (
        <div className="order-detail-section-block">
          <h3 className="order-detail-section-subtitle">
            {i18n('noun:brand')}
          </h3>
          <p className="order-detail-section-regular-text">{brand}</p>
        </div>
      )}
      {web && (
        <div className="order-detail-section-block">
          <h3 className="order-detail-section-subtitle">
            {i18n('label:website')}
          </h3>
          <p className="order-detail-section-regular-text">
            <a href={web} target="_blank" rel="noopener noreferrer">
              {web}
            </a>
          </p>
        </div>
      )}
      {serviceType && (
        <div className="order-detail-section-block">
          <h3 className="order-detail-section-subtitle">
            {i18n('label:service-type')}
          </h3>
          <p className="order-detail-section-regular-text">
            <span className="order-type-group">
              <span className="order-type-icon">
                <IconSocial type={mapServiceTypeToResourceType[serviceType]} />
              </span>
              <span className="order-type-text">
                {i18n(mapServiceTypeToOrderTypeI18n[serviceType])}
              </span>
            </span>
          </p>
        </div>
      )}
    </OrderDetailSection>
  )
}

OrderDetailGeneralData.propTypes = {
  brand: PropTypes.string,
  web: PropTypes.string,
  serviceType: PropTypes.oneOf(Object.keys(SERVICE_TYPES)),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailGeneralData)
