import React from 'react'
import PropTypes from 'prop-types'
import { MediaQueryPortable, MediaQueryDesk } from '../../shared/MediaQueries'
import { Link } from 'react-router-dom'

const PublicationsRow = props => {
  const { linkUrl, children } = props
  return (
    <>
      <MediaQueryPortable>
        <Link to={linkUrl} className="publications-row is-interactive">
          {children}
        </Link>
      </MediaQueryPortable>
      <MediaQueryDesk>
        <div className="publications-row">{children}</div>
      </MediaQueryDesk>
    </>
  )
}
PublicationsRow.propTypes = {
  children: PropTypes.node,
  linkUrl: PropTypes.string,
}

export default PublicationsRow
