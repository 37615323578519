import { compose, withPropsOnChange } from 'recompose'
import * as Yup from 'yup'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import {
  BRIEFING_UPLOAD_CONDITIONS,
  BRIEFING_UPLOAD_CONDITIONS_REDUCED,
  briefingRequestType,
  BRIEFINGS_MAX_FILE_SIZE,
} from '../../services/checkout/business'
import { i18nValidation, validateMaxFileSize } from '../common/validation'
import BriefingTiktokShortForm from '../../ui/checkout/BriefingTiktokShortForm'
import { prepareMultiValueInputToSave } from '../utils'
import withConditionOptions from './withConditionOptions'
import withTranslations from '../../hocs/withTranslations'
import { SERVICE_TYPES } from '../../services/orders/business'
import { submitTiktokShortBriefing } from '../../services/checkout'
import { connect } from 'react-redux'
import withDownloadBriefingFileLink from './withDownloadBriefingFileLink'
import { withCart } from '../../services/checkout/hocs'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withValuesCache from './withValuesCache'

const validationSchema = Yup.object().shape({
  description: Yup.string().required(i18nValidation.required),
  hashtags: Yup.string(),
  url: Yup.string().url(i18nValidation.invalidURL),
  mentions: Yup.string(),
  conditionId: Yup.string()
    .oneOf(BRIEFING_UPLOAD_CONDITIONS_REDUCED, i18nValidation.required)
    .required(i18nValidation.required),
  file: Yup.mixed()
    .nullable()
    .when('conditionId', {
      is: BRIEFING_UPLOAD_CONDITIONS.BRAND,
      then: Yup.mixed()
        .nullable()
        .required(i18nValidation.required),
    })
    .test(validateMaxFileSize(BRIEFINGS_MAX_FILE_SIZE)),
  isAdvertising: Yup.boolean().required(i18nValidation.required),
})

const enhance = compose(
  withTranslations,
  withCart,

  withPropsOnChange(['i18n'], ({ i18n }) => ({
    title: i18n('service-type:tiktok-short'),
  })),

  connect(
    null,
    { submitBriefing: submitTiktokShortBriefing },
  ),
  withDownloadBriefingFileLink(SERVICE_TYPES.TIKTOK_SHORT),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ cart: { briefings }, cachedValues }) => {
      const actualData = briefings[SERVICE_TYPES.TIKTOK_SHORT]
      const actualHashtags =
        actualData && actualData.hashtags
          ? actualData.hashtags
              .filter(Boolean)
              .map(v => '#' + v)
              .join(' ')
          : ''
      const actualMentions =
        actualData && actualData.userMentions
          ? actualData.userMentions
              .filter(Boolean)
              .map(v => '@' + v)
              .join(' ')
          : ''
      const actualFile =
        actualData && Array.isArray(actualData.files)
          ? actualData.files[0]
          : null

      return {
        description:
          actualData && actualData.description ? actualData.description : '',
        hashtags: actualHashtags,
        url: actualData && actualData.url ? actualData.url : '',
        userMentions: actualMentions,
        conditionId:
          actualData && actualData.conditionId
            ? actualData.conditionId
            : BRIEFING_UPLOAD_CONDITIONS.MEDIA,
        file: actualFile,
        isAdvertising:
          actualData && actualData.isAdvertising
            ? actualData.isAdvertising
            : false,
        ...cachedValues,
      }
    },

    validationSchema,

    fileFields: ['file'],
    selectFields: ['conditionId'],

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSend = {
        ...values,
        hashtags: prepareMultiValueInputToSave(values.hashtags, '#'),
        userMentions: prepareMultiValueInputToSave(values.userMentions, '@'),
      }

      props.submitBriefing(valuesToSend)
    },
  }),
  withValuesCache(SERVICE_TYPES.TIKTOK_SHORT),

  withIsRequestPending(briefingRequestType(SERVICE_TYPES.TIKTOK_SHORT)),
  withIsRequestSucceeded(briefingRequestType(SERVICE_TYPES.TIKTOK_SHORT)),
  withRequestError(briefingRequestType(SERVICE_TYPES.TIKTOK_SHORT)),
  withDeleteRequestOnUnmount(briefingRequestType(SERVICE_TYPES.TIKTOK_SHORT)),
  withFormErrors([
    'description',
    'hashtags',
    'url',
    'userMentions',
    'conditionId',
    'file',
    'isAdvertising',
  ]),

  withConditionOptions(BRIEFING_UPLOAD_CONDITIONS_REDUCED),
)

export default enhance(BriefingTiktokShortForm)
