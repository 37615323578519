import { compose, withProps, branch } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as Yup from 'yup'

import {
  passwordRecoveryResetPassword,
  passwordRecoveryCheckResetToken,
} from '../../services/password-recovery'
import PasswordRecoveryReset from '../../ui/PasswordRecoveryReset'
import {
  RESET_PASSWORD,
  CHECK_RESET_TOKEN,
} from '../../services/password-recovery/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { i18nValidation } from '../common/validation'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEffect from '../../hocs/withEffect'
import withSpinner from '../../hocs/withSpinner'
import withIsLoading from '../../hocs/withIsLoading'
import withHasRequestError from '../../hocs/withHasRequestError'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const PasswordResetSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18nValidation.required)
    .min(8, i18nValidation.minLength),
  confirmPassword: Yup.string()
    .required(i18nValidation.required)
    .oneOf([Yup.ref('password')], i18nValidation.passwordsDoNotMatch),
})

const enhance = compose(
  withRouter,
  withProps(({ match }) => ({
    token: match.params.token,
  })),

  connect(
    null,
    {
      passwordRecoveryResetPassword,
      passwordRecoveryCheckResetToken,
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: false,

    mapPropsToValues: () => ({
      password: '',
      confirmPassword: '',
    }),

    validationSchema: PasswordResetSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.passwordRecoveryResetPassword(props.token, values.password)
    },
  }),

  withIsRequestPending(RESET_PASSWORD, 'isResetPending'),
  withRequestError(RESET_PASSWORD, 'resetRequestError'),

  withFormErrors(['password', 'confirmPassword'], {
    serverErrorsProp: 'resetRequestError',
  }),

  // Mark token as invalid if there was an error
  withHasRequestError(CHECK_RESET_TOKEN, 'hasCheckTokenRequestError'),
  withProps(({ token, hasCheckTokenRequestError }) => ({
    isInvalidToken: !token || hasCheckTokenRequestError,
  })),

  // Check password reset token if needed
  withIsRequestPending(CHECK_RESET_TOKEN, 'isCheckTokenPending'),
  withIsLoading(CHECK_RESET_TOKEN, 'isCheckTokenLoading'),
  withEffect(
    ({
      token,
      isCheckTokenLoading,
      isCheckTokenPending,
      passwordRecoveryCheckResetToken,
    }) => {
      if (token && isCheckTokenLoading && !isCheckTokenPending) {
        passwordRecoveryCheckResetToken(token)
      }
    },
    ({
      token,
      isCheckTokenLoading,
      isCheckTokenPending,
      passwordRecoveryCheckResetToken,
    }) => [
      token,
      isCheckTokenLoading,
      isCheckTokenPending,
      passwordRecoveryCheckResetToken,
    ],
  ),

  // Only show spinner if password reset token is present
  branch(
    ({ token }) => !!token,
    withSpinner(CHECK_RESET_TOKEN, {
      i18nKey: 'password-reset:checking-token',
    }),
  ),

  // Clear request status when navigating away
  withDeleteRequestOnUnmount(CHECK_RESET_TOKEN),
  withDeleteRequestOnUnmount(RESET_PASSWORD),
)

export default enhance(PasswordRecoveryReset)
