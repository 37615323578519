const makeSort = fields => ({
  sort: [
    ...fields,
    { 'statistics.rank.rank': { order: 'desc' } },
    { _score: { order: 'desc' } },
    '_doc',
  ],
})

const makeSortRating = order => _ => ({
  sort: [
    { 'statistics.rank.rank': { order } },
    { totalRating: { order } },
    { _score: { order: 'desc' } },
    '_doc',
  ],
})

const makeSortCreatedAt = order => _ =>
  makeSort([
    {
      createdAt: { order },
    },
  ])

const makeSortFollowers = order => _ =>
  makeSort([
    {
      meta_followers: { order },
    },
  ])

const makeSortEngagements = order => _ =>
  makeSort([
    {
      'statistics.engagements.engagements': { order },
    },
    {
      'statistics.engagements.rate': { order },
    },
  ])

const makeSortPrice = order => priceFilter =>
  makeSort([
    {
      'prices.finalPrice.EUR': {
        order,
        mode: 'min',
        nested: {
          path: 'prices',
          filter: priceFilter,
        },
      },
    },
  ])

const makeSortUniqueUsers = order => _ =>
  makeSort([
    {
      'statistics.googleAnalytics.uniqueVisitorsPerMonth': { order },
    },
  ])

const makeSortPageAuthority = order => _ =>
  makeSort([
    {
      'statistics.seoMOZ.pageAuthority': { order },
    },
  ])

const makeSortEngagementRate = order => _ =>
  makeSort([
    {
      'statistics.engagements.rate': { order },
    },
    {
      'statistics.engagements.engagements': { order },
    },
  ])

const makeSortPageAuthorityPriceRatio = order => priceFilter =>
  makeSort([
    {
      'prices.seoPriceRatio': {
        order,
        mode: 'max', // we pick max because price is in denominator
        nested: {
          path: 'prices',
          filter: priceFilter,
        },
      },
    },
  ])

const makeSortUsersPriceRatio = order => priceFilter =>
  makeSort([
    {
      'prices.usersPriceRatio': {
        order,
        mode: 'max', // we pick max because price is in denominator
        nested: {
          path: 'prices',
          filter: priceFilter,
        },
      },
    },
  ])

export const SORT_QUERIES = {
  RATING: makeSortRating('desc'),
  CREATED_ASC: makeSortCreatedAt('asc'),
  CREATED_DESC: makeSortCreatedAt('desc'),
  FOLLOWERS: makeSortFollowers('desc'),
  ENGAGEMENTS: makeSortEngagements('desc'),
  PRICE_DESC: makeSortPrice('desc'),
  PRICE_ASC: makeSortPrice('asc'),
  UNIQUE_USERS_DESC: makeSortUniqueUsers('desc'),
  UNIQUE_USERS_ASC: makeSortUniqueUsers('asc'),
  PAGE_AUTHORITY_DESC: makeSortPageAuthority('desc'),
  PAGE_AUTHORITY_ASC: makeSortPageAuthority('asc'),
  ENGAGEMENT_RATE: makeSortEngagementRate('desc'),
  PAGE_AUTHORITY_PRICE_RATIO: makeSortPageAuthorityPriceRatio('desc'),
  USERS_PRICE_RATIO: makeSortUsersPriceRatio('asc'),
}

export const SORT_TYPES = {
  RATING: 'RATING',
  CREATED_ASC: 'CREATED_ASC',
  CREATED_DESC: 'CREATED_DESC',
  FOLLOWERS: 'FOLLOWERS',
  ENGAGEMENTS: 'ENGAGEMENTS',
  PRICE_DESC: 'PRICE_DESC',
  PRICE_ASC: 'PRICE_ASC',
  UNIQUE_USERS_DESC: 'UNIQUE_USERS_DESC',
  UNIQUE_USERS_ASC: 'UNIQUE_USERS_ASC',
  PAGE_AUTHORITY_DESC: 'PAGE_AUTHORITY_DESC',
  PAGE_AUTHORITY_ASC: 'PAGE_AUTHORITY_ASC',
  ENGAGEMENT_RATE: 'ENGAGEMENT_RATE',
  PAGE_AUTHORITY_PRICE_RATIO: 'PAGE_AUTHORITY_PRICE_RATIO',
  USERS_PRICE_RATIO: 'USERS_PRICE_RATIO',
}

export const DEFAULT_SORT_TYPE = SORT_TYPES.RATING
