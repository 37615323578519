import availableLocales from '../config/locales'
import translations from '../config/translations'

export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE || 'en'

export const findMatchingLocale = (language = undefined) => {
  // When e2e testing using Cypress, we cannot control the navigator language
  // Since we're using text to test user interaction, we force the language to "es"
  if (window.Cypress) {
    return 'es'
  }

  const matchingLocale = availableLocales.find(({ match }) =>
    match.test(language ? language : navigator.language),
  )

  return matchingLocale ? matchingLocale.locale : DEFAULT_LOCALE
}

export const setupI18n = (language = undefined) => {
  const locale = findMatchingLocale(language)

  const messages = translations[locale] || translations[DEFAULT_LOCALE]

  if (!translations[locale]) {
    console.warn('Missing translations for locale', locale)
  }

  return {
    messages,
    locale,
  }
}

const SEPARATOR_DETECTION_NUMBER = 123456789

const hackNumberParts = (intl, formatOptions, parts) => {
  const firstIntegerIndex = parts.findIndex(({ type }) => type === 'integer')
  const firstInteger = parts[firstIntegerIndex]

  if (!firstInteger) return [{ type: 'integer', value: 0 }]

  // Detected >=4-digit integer, split
  // For now only works with numbers of up to 6 digits,
  // which should solve our problem
  if (firstInteger.value.length >= 4 && firstInteger.value.length <= 6) {
    // Try to detect locale's native grouping separator
    const detectedSeparator = intl
      .formatNumberToParts(SEPARATOR_DETECTION_NUMBER, formatOptions)
      .find(({ type }) => type === 'group')

    // We couldn't detect a separator (maybe because of configuration, or
    // locale not having a separator), return parts unchanged
    if (!detectedSeparator) {
      return parts
    }

    // Separate in thousands and units
    const [thousands, units] = [
      firstInteger.value.slice(0, -3),
      firstInteger.value.slice(-3),
    ]

    // Add manually separated parts
    return [
      ...parts.slice(0, firstIntegerIndex),
      { type: 'integer', value: thousands },
      { type: 'group', value: detectedSeparator.value },
      { type: 'integer', value: units },
      ...parts.slice(firstIntegerIndex + 1),
    ]
  }

  return parts
}

// For some reason Chrome formats numbers 1000 <= n <= 9999 without thousands
// separator for locale es. Fix it by manually replacing number parts.
export const hackFormatNumber = (intl, formatOptions, parts) =>
  hackNumberParts(intl, formatOptions, parts)
    .map(({ value }) => value)
    .join('')
