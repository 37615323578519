import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { ReactComponent as CloseIcon } from '../../styles/icons/close.svg'

const SearchFiltersHeader = ({ i18n, onClose, onClear }) => (
  <div className="search-filters-header">
    <div className="search-filters-header-close">
      <button onClick={onClose}>
        <CloseIcon />
      </button>
    </div>
    <div className="search-filters-header-title">{i18n('noun:filters')}</div>
    <div className="search-filters-header-action">
      <button className="anchor-link" onClick={onClear}>
        {i18n('action:clean-filters')}
      </button>
    </div>
  </div>
)

SearchFiltersHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(SearchFiltersHeader)
