import React, { useEffect, useCallback } from 'react'
import RegularCheckbox from '../../ui/form-elements/RegularCheckbox'

const CheckboxWrapper = ({ value, setQuery, makeQuery, label, isDisabled }) => {
  const handleChange = useCallback(checked => setQuery(makeQuery(checked)), [
    makeQuery,
    setQuery,
  ])

  // Update values to match URLParams
  useEffect(() => {
    handleChange(value)
  }, [handleChange, value])

  const checked = value > 0

  return (
    <RegularCheckbox
      label={label}
      checked={checked}
      onChange={e => handleChange(e.target.checked ? 1 : 0)}
      isDisabled={!checked && isDisabled}
    />
  )
}

export default CheckboxWrapper
