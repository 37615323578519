import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { FormattedMessage } from 'react-intl'
import { globalMessagesPropType } from '../../hocs/withFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import FormattedFormErrors from '../FormattedFormErrors'
import PaperRadio from '../papers/PaperRadio'
import { ReactComponent as Euro } from '../../styles/icons/euro.svg'
import { ReactComponent as Card } from '../../styles/icons/card.svg'
import { Input } from '@redradix/components.input'
import Button from '@redradix/components.button'
import HelpMessage from '../messages/HelpMessage'
import InputCardAccount from '../form-elements/InputCardAccount'
import InputCardCvc from '../form-elements/InputCardCvc'
import InputCardExpirationDate from '../form-elements/InputCardExpirationDate'
import SuccessMessage from '../messages/SuccessMessage'
import HackFormattedMoney from '../HackFormattedMoney'
import { floatToMoney } from '../../app/utils'

const CheckoutPayment = ({
  // Payment
  amountBalance,
  isPaymentWithCard,
  isPaymentWithBalanceDisabled,
  onPaymentWithBalanceClick,
  onPaymentWithCardClick,
  onSubmit,
  isRequestPending,

  // Card payment
  cardBrand,
  onCardNumberChange,
  onCardNumberBlur,
  cardNumberError,
  onCardExpirationChange,
  onCardExpirationBlur,
  cardExpirationError,
  onCardCVCChange,
  onCardCVCBlur,
  cardCVCError,

  // Discount coupon
  discountCoupon,
  onDiscountCouponChange,
  onCouponSubmit,
  isValidatingCoupon,
  successCode,
  errorCode,

  // Order summary
  total,
  totalOrder,
  totalVat,
  totalDiscount,
  vatPercentage,

  // withFormErrors
  globalMessages,

  // withTranslations
  i18n,
}) => {
  return (
    <>
      <form id="couponForm" onSubmit={onCouponSubmit} />

      <form className="basic-form" onSubmit={onSubmit}>
        <PaperRadio
          name="isPaymentWithCard"
          id="paymentBalance"
          value={false}
          checked={!isPaymentWithCard}
          onChange={onPaymentWithBalanceClick}
          isDisabled={isRequestPending || isPaymentWithBalanceDisabled}
          renderLabelIcon={props => <Euro {...props} />}
        >
          <div className="paper-main">
            <p className="paper-title">{i18n('checkout:balance-payment')}</p>
          </div>
          <p className="paper-extra-info">
            <span>{i18n('checkout:current-balance')}: </span>
            <strong>
              <HackFormattedMoney value={amountBalance} />
            </strong>
          </p>
        </PaperRadio>
        {isPaymentWithCard && (
          <>
            <div className="card-form">
              <div className="card-form-row">
                <InputCardAccount
                  label={i18n('checkout:card-number')}
                  placeholder={i18n('checkout:card-number-placeholder')}
                  brand={cardBrand}
                  errorText={cardNumberError}
                  isDisabled={isRequestPending}
                  onChange={onCardNumberChange}
                  onBlur={onCardNumberBlur}
                />
                <InputCardExpirationDate
                  label={i18n('checkout:expiration-date')}
                  placeholder={i18n('checkout:expiration-date-placeholder')}
                  errorText={cardExpirationError}
                  isDisabled={isRequestPending}
                  onChange={onCardExpirationChange}
                  onBlur={onCardExpirationBlur}
                />
              </div>
              {/* Card form row */}
              <div className="card-form-row">
                <InputCardCvc
                  label={i18n('checkout:card-security-code')}
                  placeholder={i18n('checkout:card-security-code-placeholder')}
                  errorText={cardCVCError}
                  isDisabled={isRequestPending}
                  onChange={onCardCVCChange}
                  onBlur={onCardCVCBlur}
                />
                <div className="coupon-code-field-wrapper">
                  <div className="coupon-code-field">
                    <Input
                      type="text"
                      name="discountCoupon"
                      id="discountCoupon"
                      label={i18n('checkout:discount-coupon')}
                      placeholder={i18n('checkout:discount-coupon-placeholder')}
                      widthBehaviour="full"
                      value={discountCoupon}
                      onChange={onDiscountCouponChange}
                      isDisabled={isValidatingCoupon || isRequestPending}
                      form="couponForm"
                    />
                    <Button
                      type="submit"
                      isLoading={isValidatingCoupon}
                      isDisabled={
                        !discountCoupon ||
                        discountCoupon.length === 0 ||
                        isRequestPending
                      }
                      widthBehaviour="full"
                      hierarchy="secondary"
                      onClick={onCouponSubmit}
                      form="couponForm"
                    >
                      {i18n('action:apply')}
                    </Button>
                  </div>
                  {/* Coupon code field */}
                  {successCode && (
                    <SuccessMessage iconType="fill-success">
                      <FormattedMessage
                        id="checkout:discount-applied"
                        defaultMessage="checkout:discount-applied"
                        values={{
                          couponDetails: (
                            <strong>
                              {i18n(
                                'checkout:discount-applied-detail',
                                successCode,
                              )}
                            </strong>
                          ),

                          couponLimits: (
                            <strong>
                              {i18n(
                                'checkout:discount-applied-limits',
                                successCode,
                              )}
                            </strong>
                          ),
                          br: <br />,
                        }}
                      />
                    </SuccessMessage>
                  )}
                  {errorCode && (
                    <WarningMessage
                      iconType="fill-warning"
                      text={<FormattedFormErrors errors={errorCode} />}
                    />
                  )}
                </div>
              </div>

              {/* Card form row */}
            </div>
            <div className="cart-resume">
              {totalDiscount || vatPercentage ? (
                <div className="price-item">
                  <span>{i18n('cart:total-order')}</span>
                  <span className="amount">
                    <HackFormattedMoney value={totalOrder} />
                  </span>
                </div>
              ) : null}
              {totalDiscount ? (
                <div className="price-item with-discount">
                  <span>{i18n('checkout:discount-coupon')}</span>
                  <span className="amount">
                    -
                    <HackFormattedMoney value={totalDiscount} />
                  </span>
                </div>
              ) : null}
              {vatPercentage ? (
                <div className="price-item">
                  <span>
                    {i18n('cart:vat')} ({vatPercentage}%)
                  </span>
                  <span className="amount">
                    <HackFormattedMoney value={totalVat} />
                  </span>
                </div>
              ) : null}
              <div className="price-item total-price">
                <span>{i18n('cart:total')}</span>
                <span className="amount">
                  <HackFormattedMoney value={total ? total : floatToMoney(0)} />
                </span>
              </div>
            </div>
          </>
        )}
        {globalMessages && (
          <WarningMessage
            text={<FormattedFormErrors errors={globalMessages} />}
          />
        )}
        <div className="form-actions cart-resume-actions">
          <Button
            type="submit"
            isLoading={isRequestPending}
            isDisabled={isValidatingCoupon}
          >
            {i18n('action:make-payment')}
          </Button>
        </div>
        <HelpMessage
          iconType="help"
          phone="+34914149191"
          formattedPhone="(+34) 914 14 91 91"
        />
      </form>
    </>
  )
}

CheckoutPayment.propTypes = {
  // Payment
  amountBalance: CoobisPropTypes.money,
  isPaymentWithCard: PropTypes.bool,
  isPaymentWithBalanceDisabled: PropTypes.bool,
  onPaymentWithBalanceClick: PropTypes.func,
  onPaymentWithCardClick: PropTypes.func,
  onSubmit: PropTypes.func,
  isRequestPending: PropTypes.bool.isRequired,

  // Card payment
  cardBrand: PropTypes.oneOf([
    'visa',
    'mastercard',
    'amex',
    'discover',
    'diners',
    'jcb',
    'unionpay',
    'unknown',
  ]),
  onCardNumberChange: PropTypes.func,
  onCardNumberBlur: PropTypes.func,
  cardNumberError: PropTypes.string,
  onCardExpirationChange: PropTypes.func,
  onCardExpirationBlur: PropTypes.func,
  cardExpirationError: PropTypes.string,
  onCardCVCChange: PropTypes.func,
  onCardCVCBlur: PropTypes.func,
  cardCVCError: PropTypes.string,

  // Discount coupon
  discountCoupon: PropTypes.string,
  onDiscountCouponChange: PropTypes.func,
  onCouponSubmit: PropTypes.func,
  isValidatingCoupon: PropTypes.bool,
  successCode: PropTypes.any,
  errorCode: PropTypes.any,

  // Order summary
  total: CoobisPropTypes.money,
  totalOrder: CoobisPropTypes.money,
  totalVat: CoobisPropTypes.money,
  totalDiscount: CoobisPropTypes.money,
  vatPercentage: PropTypes.number,

  // withFormErrors
  globalMessages: globalMessagesPropType,

  // withTranslations
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(CheckoutPayment)
