import {
  put,
  call,
  all,
  takeLatest,
  takeEvery,
  select,
} from 'redux-saga/effects'
import * as R from 'ramda'
import * as actionTypes from './action-types'
import * as api from './api'
import * as hash from 'reduken/hash'
import { push } from 'connected-react-router'
import {
  startRequest,
  endRequestError,
  endRequestSuccess,
  startRequestWithId,
  endRequestWithIdError,
  endRequestWithIdSuccess,
} from '../communication/actions'
import {
  DOMAIN,
  HASH_KEY_CART,
  HASH_KEY_DATES_CONFIG,
  HASH_KEY_COUPON,
  REFRESH_CART_TIME,
} from './constants'
import { addErrorToast, addWarningToast, addSuccessToast } from '../toasts'
import { SUCCESS } from '../communication/action-types'
import { CREATE_CAMPAIGN } from '../campaigns/action-types'
import { closeCreateCampaignModal, repeatingFetchCart } from './actions'
import {
  ROUTE_BRAND_CHECKOUT_BRIEFING,
  ROUTE_BRAND_CHECKOUT_PAYMENT,
  prettifyConstant,
  ROUTE_BRAND_CAMPAIGNS,
} from '../../app/common/routes'
import {
  getPendingBriefings,
  briefingRequestType,
  PAYMENT_METHODS,
  isUserFacingStripeError,
} from './business'
import { getCart } from './selectors'
import { fetchWalletSaga } from '../wallet/sagas'
import repeatingFetchEffect from '../repeating-fetch-effect'
/*
import { getBrandInfo } from '../brand'
import { getLanguage } from '../me'
import { getCountries } from '../config/selectors'
*/

const EMPTY_CART = {
  items: [],
  briefings: [],
}

export function* fetchCartSaga() {
  yield put(startRequest(actionTypes.FETCH_CART))

  const response = yield call(api.fetchCart)

  if (response.error) {
    // Ignore error if shopping cart is not found
    if (
      response.errorData &&
      response.errorData.global_messages &&
      response.errorData.global_messages.ShoppingCartNotFoundException
    ) {
      yield put(hash.set(DOMAIN, HASH_KEY_CART, EMPTY_CART))

      yield put(endRequestSuccess(actionTypes.FETCH_CART))

      return
    }

    yield put(endRequestError(actionTypes.FETCH_CART, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_CART, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_CART))
}

function* deleteCartSaga() {
  yield put(startRequest(actionTypes.DELETE_CART))

  const response = yield call(api.deleteCart)

  if (response.error) {
    yield put(endRequestError(actionTypes.DELETE_CART, response))

    yield put(
      addErrorToast(
        'errors:delete-cart-title',
        'errors:delete-cart-description',
      ),
    )

    return
  }

  yield call(fetchCartSaga)

  yield put(endRequestSuccess(actionTypes.DELETE_CART))
}

function* addItemSaga({ payload: { id, service, words, broadcast } }) {
  yield put(startRequestWithId(actionTypes.ADD_ITEM, id))

  const response = yield call(api.addItem, id, service, words, broadcast)

  if (response.error) {
    if (
      response.errorData &&
      response.errorData.global_messages &&
      response.errorData.global_messages.ShoppingCartAlreadyExistsItemException
    ) {
      yield put(endRequestWithIdSuccess(actionTypes.ADD_ITEM, id))

      yield put(
        addWarningToast(
          'errors:add-cart-item-already-exists-title',
          'errors:add-cart-item-already-exists-description',
        ),
      )

      return
    }

    yield put(endRequestWithIdError(actionTypes.ADD_ITEM, id, response))

    yield put(
      addErrorToast(
        'errors:add-cart-item-title',
        'errors:add-cart-item-description',
      ),
    )

    return
  }

  yield call(fetchCartSaga)

  yield put(endRequestWithIdSuccess(actionTypes.ADD_ITEM, id))
}

function* deleteItemSaga({ payload: { id, service } }) {
  yield put(startRequestWithId(actionTypes.DELETE_ITEM, `${id}-${service}`))

  const response = yield call(api.deleteItem, id, service)

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.DELETE_ITEM,
        `${id}-${service}`,
        response,
      ),
    )

    yield put(
      addErrorToast(
        'errors:delete-cart-item-title',
        'errors:delete-cart-item-description',
      ),
    )

    return
  }

  yield call(fetchCartSaga)

  yield put(
    endRequestWithIdSuccess(actionTypes.DELETE_ITEM, `${id}-${service}`),
  )
}

function* fetchDatesConfigSaga() {
  yield put(startRequest(actionTypes.FETCH_DATES_CONFIG))

  const response = yield call(api.fetchDatesConfig)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_DATES_CONFIG, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_DATES_CONFIG, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_DATES_CONFIG))
}

function* createCampaignSuccessSaga() {
  yield put(closeCreateCampaignModal())
}

function* submitGeneralDataSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.SUBMIT_GENERAL_DATA))

  const response = yield call(api.submitGeneralData, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.SUBMIT_GENERAL_DATA, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_CART, response.data))

  yield put(endRequestSuccess(actionTypes.SUBMIT_GENERAL_DATA))

  yield put(push(ROUTE_BRAND_CHECKOUT_BRIEFING.linkTo()))
}

function* fetchBriefingSaga(serviceType) {
  const response = yield call(api.fetchBriefing, serviceType)

  if (response.error) {
    return [null, response]
  }

  return [response.data, null]
}

function* submitBriefingSaga({ payload: { serviceType, data } }) {
  yield put(startRequest(briefingRequestType(serviceType)))

  // Upload the file and manage it's own errors
  if (data.file && !data.file.isUploaded) {
    const fileUploadResponse = yield call(
      api.uploadBriefingFile,
      serviceType,
      data.file,
    )

    if (fileUploadResponse.error) {
      yield put(
        endRequestError(briefingRequestType(serviceType), fileUploadResponse),
      )
      return
    }
  }

  // Remove the uploaded file
  let cart = yield select(getCart)
  const hasUploadedFile =
    cart.briefings[serviceType] &&
    cart.briefings[serviceType].files &&
    cart.briefings[serviceType].files[0]

  if (data.file === false && hasUploadedFile) {
    const fileDeleteResponse = yield call(api.deleteBriefingFile, serviceType)

    if (fileDeleteResponse.error) {
      yield put(
        endRequestError(briefingRequestType(serviceType), fileDeleteResponse),
      )
      return
    }
  }

  // Submits the briefing
  const response = yield call(
    api.submitBriefing,
    serviceType,
    // Apparently backend ignores whatever we send on `file` and just uses the
    // file uploaded before, so we omit it
    R.omit(['file'], data),
  )

  if (response.error) {
    yield put(endRequestError(briefingRequestType(serviceType), response))
    return
  }

  // Fetches new data and stores it
  const [briefingData, fetchBriefingErrors] = yield call(
    fetchBriefingSaga,
    serviceType,
  )

  if (fetchBriefingErrors) {
    yield put(
      endRequestError(briefingRequestType(serviceType), fetchBriefingErrors),
    )
    return
  }

  yield put(hash.merge(DOMAIN, [HASH_KEY_CART, 'briefings'], briefingData))

  // Finish request
  yield put(endRequestSuccess(briefingRequestType(serviceType)))

  // Calculate pending briefings and redirect to next briefing or payment
  cart = yield select(getCart) // Refresh cart
  const pendingBriefings = getPendingBriefings(cart)

  if (pendingBriefings[0]) {
    yield put(
      push(
        ROUTE_BRAND_CHECKOUT_BRIEFING.linkTo({
          serviceType: prettifyConstant(pendingBriefings[0]),
        }),
      ),
    )
  } else {
    yield put(push(ROUTE_BRAND_CHECKOUT_PAYMENT.linkTo()))
  }
}

export function* validateDiscountCouponSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.VALIDATE_DISCOUNT_COUPON))

  yield put(hash.remove(DOMAIN, HASH_KEY_COUPON))

  const response = yield call(api.validateDiscountCoupon, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.VALIDATE_DISCOUNT_COUPON, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_COUPON, response.data))

  yield put(endRequestSuccess(actionTypes.VALIDATE_DISCOUNT_COUPON))
}

function* payWithWalletSaga({ payload: { couponCode, cartTotalPayment } }) {
  yield put(startRequest(actionTypes.SUBMIT_PAYMENT))

  const response = yield call(
    api.submitPayment,
    PAYMENT_METHODS.WALLET_BALANCE,
    couponCode,
    cartTotalPayment,
  )

  if (response.error) {
    yield call(handlePaymentErrorSaga, response)
    yield put(endRequestError(actionTypes.SUBMIT_PAYMENT, response))
    return
  }

  // Update cart and move to dashboard
  yield call(fetchCartSaga)
  yield call(fetchWalletSaga)
  yield put(
    addSuccessToast(
      'checkout:success-message-title',
      'checkout:success-message-description',
    ),
  )
  yield put(push(ROUTE_BRAND_CAMPAIGNS.linkTo()))

  yield put(endRequestSuccess(actionTypes.SUBMIT_PAYMENT))
}

function* payWithCardSaga({
  payload: { couponCode, cartTotalPayment, stripe },
}) {
  yield put(startRequest(actionTypes.SUBMIT_PAYMENT))

  const response = yield call(
    api.submitPayment,
    PAYMENT_METHODS.CREDIT_CARD,
    couponCode,
    cartTotalPayment,
  )

  if (response.error) {
    yield call(handlePaymentErrorSaga, response)
    yield put(endRequestError(actionTypes.SUBMIT_PAYMENT, response))
    return
  }

  /*
  const { billing } = yield select(getBrandInfo)
  const language = yield select(getLanguage)
  const countries = yield select(getCountries)
  const state =
    countries[billing.country].provinces[billing.state].translations[language]
  */

  const result = yield call(
    [stripe, 'handleCardPayment'],
    response.data.authToken,
    /*
    {
      payment_method_data: {
        billing_details: {
          address: {
            phone: undefined,
            country: billing.country,
            state,
            city: billing.city,
            line1: billing.address,
            line2: undefined,
            postal_code: billing.postalCode,
          },
          name: billing.businessName,
          email: billing.invoiceEmail,
        },
      },
      receipt_email: billing.invoiceEmail,
    },
    */
  )

  const { error: stripeError } = result

  if (stripeError) {
    if (isUserFacingStripeError(stripeError)) {
      yield put(
        endRequestError(actionTypes.SUBMIT_PAYMENT, {
          globalMessages: [
            { key: 'errors:stripe-user-facing-generic', values: stripeError },
          ],
        }),
      )
    } else {
      yield put(
        endRequestError(actionTypes.SUBMIT_PAYMENT, {
          globalMessages: [
            { key: 'errors:stripe-technical-error', values: stripeError },
          ],
        }),
      )
    }

    return
  }

  const confirmResponse = yield call(api.confirmPayment)

  if (confirmResponse.error) {
    yield put(endRequestError(actionTypes.SUBMIT_PAYMENT, response))
    return
  }

  // Update cart and move to dashboard
  yield call(fetchCartSaga)
  yield call(fetchWalletSaga)
  yield put(
    addSuccessToast(
      'checkout:success-message-title',
      'checkout:success-message-description',
    ),
  )
  yield put(push(ROUTE_BRAND_CAMPAIGNS.linkTo()))

  yield put(endRequestSuccess(actionTypes.SUBMIT_PAYMENT))
}

function* handlePaymentErrorSaga(response) {
  // Here we are only handling global errors from backend. Ignore other cases.
  if (!response.errorData || !response.errorData.global_messages) {
    return
  }

  // We shouldn't have received any of these error messages
  // It means our balance or cart is desynchronized, update them
  if (
    response.errorData.global_messages.ShoppingCartPaymentAmountException ||
    response.errorData.global_messages.WalletInsufficientFundsException
  ) {
    yield all([call(fetchCartSaga), call(fetchWalletSaga)])
  }
}

export default function*() {
  yield all([
    repeatingFetchEffect({
      fetchSaga: fetchCartSaga,
      intervalMillisecs: REFRESH_CART_TIME,
      startAction: repeatingFetchCart(),
      requestActionType: actionTypes.FETCH_CART,
    }),
    takeLatest(actionTypes.DELETE_CART, deleteCartSaga),
    takeEvery(actionTypes.ADD_ITEM, addItemSaga),
    takeEvery(actionTypes.DELETE_ITEM, deleteItemSaga),
    takeLatest(actionTypes.FETCH_DATES_CONFIG, fetchDatesConfigSaga),
    // Close modal after successfully creating a campaign
    takeLatest(
      action =>
        action.type === SUCCESS &&
        action.payload.requestType === CREATE_CAMPAIGN,
      createCampaignSuccessSaga,
    ),
    takeLatest(actionTypes.SUBMIT_GENERAL_DATA, submitGeneralDataSaga),
    takeLatest(actionTypes.SUBMIT_BRIEFING, submitBriefingSaga),
    takeLatest(
      actionTypes.VALIDATE_DISCOUNT_COUPON,
      validateDiscountCouponSaga,
    ),
    takeLatest(
      action =>
        action.type === actionTypes.SUBMIT_PAYMENT &&
        action.payload.paymentMethod === PAYMENT_METHODS.WALLET_BALANCE,
      payWithWalletSaga,
    ),
    takeLatest(
      action =>
        action.type === actionTypes.SUBMIT_PAYMENT &&
        action.payload.paymentMethod === PAYMENT_METHODS.CREDIT_CARD,
      payWithCardSaga,
    ),
  ])
}
