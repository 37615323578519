import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  compose,
  withProps,
  withPropsOnChange,
  withState,
  withHandlers,
  branch,
} from 'recompose'
import * as R from 'ramda'
import MediaDashboard from '../../ui/dashboard/media/MediaDashboard'
import { getMediaInfo } from '../../services/media'
import {
  fetchMediaOrdersInProgress,
  fetchMediaOrdersFinished,
  getMediaOrdersInProgress,
  getMediaOrdersFinished,
} from '../../services/orders'
import withEffect from '../../hocs/withEffect'
import {
  FETCH_MEDIA_ORDERS_IN_PROGRESS,
  FETCH_MEDIA_ORDERS_FINISHED,
} from '../../services/orders/action-types'
import withIsLoading from '../../hocs/withIsLoading'
import withSpinner from '../../hocs/withSpinner'
import withRouteServerError from '../../hocs/withRouteServerError'
import withSortOrders, {
  getMediaCurrentOrderSortBy,
  getClosedOrderSortBy,
  CLOSED_ORDERS_DEFAULT_SORT_TYPE,
  CURRENT_ORDERS_DEFAULT_SORT_TYPE,
} from '../common/withSortOrders'
import {
  mapServiceTypeToOrderTypeI18n,
  mapServiceTypeToResourceType,
  getLimitDateForStatus,
  mapOrderStatusToI18n,
} from '../../services/orders/business'
import { mapResourceTypeToI18N } from '../../services/resources/constants'
import { remainingDays, simplifyString } from '../utils'
import withTranslations from '../../hocs/withTranslations'
import { usePagination } from '@redradix/components.pagination'
import withOrderModalActions from '../common/withOrderModalActions'
import {
  getIsWelcomeMediaTourViewed,
  markTourAsViewed,
} from '../../services/tour'

const CLOSED_ORDERS_PER_PAGE = 10

const MediaDashboardWithPagination = ({ closedOrders, ...props }) => {
  // Closed orders pagination
  const [page, setPage] = useState(1)

  const totalItems = !!closedOrders ? closedOrders.length : 0

  const paginationProps = usePagination({
    page,
    onPageChange: setPage,
    totalItems,
    itemsPerPage: CLOSED_ORDERS_PER_PAGE,
  })

  // Limit page to total number of pages
  useEffect(() => {
    if (page > paginationProps.lastPage) {
      setPage(paginationProps.lastPage)
    } else if (page < 1 && totalItems > 0) {
      setPage(1)
    }
  }, [paginationProps.lastPage, page, setPage, totalItems])

  // Only show orders in current page
  const paginatedClosedOrders = !!closedOrders
    ? closedOrders.slice(
        paginationProps.firstItemInPage - 1,
        paginationProps.lastItemInPage,
      )
    : closedOrders

  return (
    <MediaDashboard
      {...props}
      closedOrders={paginatedClosedOrders}
      closedCurrentPage={
        // Limit page to [1, last page]
        Math.max(Math.min(page, paginationProps.lastPage), 1)
      }
      closedTotalPages={paginationProps.lastPage}
      closedOnPageClick={paginationProps.onPageClick}
    />
  )
}

const withCurrentOrders = compose(
  // Transform backend to maqueta
  withPropsOnChange(['currentOrders'], ({ currentOrders }) => ({
    currentOrders: !!currentOrders
      ? currentOrders.map(order => {
          const limitDateToAction = getLimitDateForStatus(
            order.status,
            order.dates,
          )

          return {
            ...order,
            price: order.income,
            limitDateToAction,
            remainDays: limitDateToAction
              ? remainingDays(limitDateToAction)
              : undefined,
          }
        })
      : currentOrders,
  })),

  // Sort current orders
  withSortOrders({
    inProp: 'currentOrders',
    sortValueProp: 'currentSortValue',
    onSortChangeProp: 'onCurrentSortChange',
    defaultSortType: CURRENT_ORDERS_DEFAULT_SORT_TYPE,
    getSortByFunction: getMediaCurrentOrderSortBy,
  }),

  // Current orders actions
  withOrderModalActions,
  withHandlers({
    onCurrentActionClick: props => (id, action) => {
      props.handleOrderAction(id, action)
    },
  }),
)

const withClosedOrders = compose(
  // Transform backend to maqueta
  withPropsOnChange(['closedOrders'], ({ closedOrders }) => ({
    hasClosedOrders: !!closedOrders && closedOrders.length > 0,
    closedOrders: !!closedOrders
      ? closedOrders.map(order => ({
          ...order,
          price: order.income,
          statusDate: new Date(order.statusDate),
        }))
      : closedOrders,
  })),

  // Filter closed orders
  withState('searchValue', 'setSearchValue', ''),
  withHandlers({
    onSearchChange: props => e => {
      props.setSearchValue(e.target.value)
    },
  }),

  withPropsOnChange(
    ['closedOrders', 'searchValue', 'i18n'],
    ({ closedOrders, searchValue, i18n }) => {
      const simpleSearchValue = simplifyString(searchValue)

      return {
        closedOrders:
          !!closedOrders && searchValue !== ''
            ? R.filter(
                order =>
                  // Status
                  simplifyString(
                    i18n(mapOrderStatusToI18n[order.status]),
                  ).includes(simpleSearchValue) ||
                  // Brand
                  simplifyString(order.brandName).includes(simpleSearchValue) ||
                  // Resource
                  simplifyString(order.resource.name).includes(
                    simpleSearchValue,
                  ) ||
                  // Service (channel)
                  simplifyString(
                    i18n(
                      mapResourceTypeToI18N[
                        mapServiceTypeToResourceType[order.type]
                      ],
                    ),
                  ).includes(simpleSearchValue) ||
                  // Service (service)
                  simplifyString(
                    i18n(mapServiceTypeToOrderTypeI18n[order.type]),
                  ).includes(simpleSearchValue),
                closedOrders,
              )
            : closedOrders,
      }
    },
  ),

  // Sort closed orders
  withSortOrders({
    inProp: 'closedOrders',
    sortValueProp: 'closedSortValue',
    onSortChangeProp: 'onClosedSortChange',
    defaultSortType: CLOSED_ORDERS_DEFAULT_SORT_TYPE,
    getSortByFunction: getClosedOrderSortBy,
  }),
)

const enhance = compose(
  withTranslations,

  connect(
    state => ({
      myInfo: getMediaInfo(state),
      currentOrders: getMediaOrdersInProgress(state),
      closedOrders: getMediaOrdersFinished(state),
      isWelcomeMediaTourViewed: getIsWelcomeMediaTourViewed(state),
    }),
    {
      markTourAsViewed,
      fetchMediaOrdersInProgress,
      fetchMediaOrdersFinished,
    },
  ),

  withCurrentOrders,
  withClosedOrders,

  withProps(({ myInfo }) => ({ username: myInfo.name })),

  withEffect(
    ({ fetchMediaOrdersInProgress, fetchMediaOrdersFinished }) => {
      fetchMediaOrdersInProgress()
      fetchMediaOrdersFinished()
    },
    ({ fetchMediaOrdersInProgress, fetchMediaOrdersFinished }) => [
      fetchMediaOrdersInProgress,
      fetchMediaOrdersFinished,
    ],
  ),

  branch(
    props => R.isNil(props.currentOrders) || R.isNil(props.closedOrders),
    compose(
      withSpinner(FETCH_MEDIA_ORDERS_IN_PROGRESS),
      withSpinner(FETCH_MEDIA_ORDERS_FINISHED),
    ),
  ),

  withIsLoading(FETCH_MEDIA_ORDERS_IN_PROGRESS, 'isLoadingOrdersInProgress'),
  withIsLoading(FETCH_MEDIA_ORDERS_FINISHED, 'isLoadingOrdersFinished'),
  withRouteServerError(FETCH_MEDIA_ORDERS_IN_PROGRESS),
  withRouteServerError(FETCH_MEDIA_ORDERS_FINISHED),
)

export default enhance(MediaDashboardWithPagination)
