import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import HackFormattedNumber from '../HackFormattedNumber'
import { truncateNumber } from '../../app/utils'
import Tooltip from '@redradix/components.tooltip'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import TooltipTitle from '../texts/TooltipSubtitle'
import TooltipSubtitle from '../texts/TooltipSubtitle'
import TooltipRegularText from '../texts/TooltipRegularText'
import withTranslations from '../../hocs/withTranslations'

const getTimeText = (i18n, totalSeconds) => {
  const mins = Math.floor(totalSeconds / 60)
  const secs = (totalSeconds % 60).toString().padStart(2, '0')

  return totalSeconds >= 60
    ? secs === '00'
      ? i18n('kpi:time-min', { mins, secs })
      : i18n('kpi:time-min-secs', { mins, secs })
    : i18n('kpi:time-secs', { mins, secs })
}

const CardKpi = ({
  children,
  className,
  figure,
  label,
  imageBg,
  notifications,
  hasInfo,
  hasFixedWidthInfo,
  infoTitle,
  infoSubtitle,
  infoText,
  isPercentage,
  isTruncated,
  isTime,
  i18n,
  i18nNumber,
}) => {
  const classes = cx(`card-kpi ${className || ''}`)
  return (
    <div className={classes}>
      {children ? (
        <span
          className="card-kpi-image"
          style={{ backgroundColor: `${imageBg}` }}
        >
          {children}
        </span>
      ) : null}
      <div className="card-kpi-text">
        <span className="card-kpi-figure">
          {isTime ? (
            getTimeText(i18n, figure)
          ) : isPercentage ? (
            <HackFormattedNumber
              value={figure}
              maximumFractionDigits={2}
              style={`percent`}
            />
          ) : isTruncated ? (
            truncateNumber(figure, i18nNumber)
          ) : (
            <HackFormattedNumber value={figure} maximumFractionDigits={2} />
          )}
        </span>
        <span className="card-kpi-label">
          {label}
          {Number(notifications) > 0 && (
            <span className="card-kpi-notification">{notifications}</span>
          )}
          {hasInfo && (
            <span className="card-kpi-info">
              <Tooltip
                position="right"
                widthFixed={hasFixedWidthInfo}
                renderTooltip={() => (
                  <>
                    {infoTitle && <TooltipTitle text={infoTitle} />}
                    {infoSubtitle && <TooltipSubtitle text={infoSubtitle} />}
                    {infoText && <TooltipRegularText text={infoText} />}
                  </>
                )}
                renderTrigger={(
                  { open, close, triggerRef },
                  triggerClasses,
                ) => (
                  <TooltipTrigger
                    classNames={triggerClasses}
                    onMouseOver={open}
                    onMouseOut={close}
                    ref={triggerRef}
                  />
                )}
              />
            </span>
          )}
        </span>
      </div>
    </div>
  )
}

CardKpi.defaultProps = {
  isPercentage: false,
  isTruncated: false,
}

CardKpi.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  figure: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  imageBg: PropTypes.string,
  infoTitle: PropTypes.string,
  infoSubtitle: PropTypes.string,
  notifications: PropTypes.number,
  hasInfo: PropTypes.bool,
  hasFixedWidthInfo: PropTypes.bool,
  isPercentage: PropTypes.bool,
  isTruncated: PropTypes.bool,
  isTime: PropTypes.bool,
  i18nNumber: PropTypes.func,
}

export default withTranslations(CardKpi)
