import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import {
  getRequest,
  deleteRequest,
  postRequest,
  putRequest,
  postFormRequest,
} from '../api-call'
import { parseResponseErrors } from '../error-handling'
import { i18nErrorKeys } from '../../app/common/validation'
import { moneyToFloat } from '../../app/utils'

const CART_ENDPOINT = `${envConfig.usersBackendUrl}/brand/cart`
const CART_ITEMS_ENDPOINT = `${envConfig.usersBackendUrl}/brand/cart/items`
const DATES_CONFIG_ENDPOINT = `${envConfig.usersBackendUrl}/brand/cart/shiping-dates`
const GENERAL_DATA_ENDPOINT = `${envConfig.usersBackendUrl}/brand/cart/general`
const BRIEFING_ENDPOINT = `${envConfig.usersBackendUrl}/brand/cart/briefings`
const PAYMENT_ENDPOINT = `${envConfig.usersBackendUrl}/brand/cart/payment`
const PAYMENT_CONFIRMATION_ENDPOINT = `${envConfig.usersBackendUrl}/brand/cart/payment/confirmation`
const DISCOUNT_COUPON_ENDPOINT = `${envConfig.usersBackendUrl}/brand/cart/payment/coupon`

export function* fetchCart() {
  const response = yield call(getRequest, CART_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* deleteCart() {
  const response = yield call(deleteRequest, CART_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* addItem(id, service, words, broadcast) {
  const response = yield call(
    postRequest,
    CART_ITEMS_ENDPOINT,
    { id, service, words, broadcast },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* deleteItem(id, service) {
  const response = yield call(
    deleteRequest,
    CART_ITEMS_ENDPOINT,
    { id, service },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchDatesConfig() {
  const response = yield call(getRequest, DATES_CONFIG_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

const MAP_GLOBAL_SUBMIT_GENERAL = {
  ShoppingCartNotFoundException: values => ({
    key: i18nErrorKeys.SHOPPING_CART_NOT_FOUND,
    values,
  }),
}

export function* submitGeneralData(data) {
  const response = yield call(putRequest, GENERAL_DATA_ENDPOINT, data, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_SUBMIT_GENERAL,
  })

  return parsedResponse
}

const MAP_GLOBAL_SUBMIT_BRIEFING = {
  ShoppingCartNotFoundException: values => ({
    key: i18nErrorKeys.SHOPPING_CART_NOT_FOUND,
    values,
  }),
}

export function* submitBriefing(serviceType, data) {
  const response = yield call(
    putRequest,
    `${BRIEFING_ENDPOINT}/${serviceType.toLowerCase()}`,
    data,
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_SUBMIT_BRIEFING,
  })

  return parsedResponse
}

const MAP_GLOBAL_UPLOAD_BRIEFING_FILE = {
  ShoppingCartUploadedDocumentNotFound: values => ({
    key: i18nErrorKeys.SHOPPING_CART_UPLOADED_DOCUMENT_NOT_FOUND,
    values,
  }),
}

export function* uploadBriefingFile(serviceType, file) {
  const response = yield call(
    postFormRequest,
    `${BRIEFING_ENDPOINT}/${serviceType.toLowerCase()}/file`,
    { file },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_UPLOAD_BRIEFING_FILE,
  })

  return parsedResponse
}

const MAP_GLOBAL_DELETE_BRIEFING_FILE = {
  ShoppingCartNotExistsItemException: values => ({
    key: i18nErrorKeys.SHOPPING_CART_NOT_FOUND,
    values,
  }),
  ShoppingCartUploadedDocumentNotFound: values => ({
    key: i18nErrorKeys.SHOPPING_CART_UPLOADED_DOCUMENT_NOT_FOUND,
    values,
  }),
}

export function* deleteBriefingFile(serviceType) {
  const response = yield call(
    deleteRequest,
    `${BRIEFING_ENDPOINT}/${serviceType.toLowerCase()}/file`,
    {},
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_DELETE_BRIEFING_FILE,
  })

  return parsedResponse
}

export function* fetchBriefing(serviceType) {
  const response = yield call(
    getRequest,
    `${BRIEFING_ENDPOINT}/${serviceType.toLowerCase()}`,
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export const MAP_GLOBAL_DISCOUNT_COUPON = {
  CouponNotFoundException: values => ({
    key: i18nErrorKeys.COUPON_NOT_FOUND,
    values,
  }),
  CouponExpiredException: values => ({
    key: i18nErrorKeys.COUPON_EXPIRED,
    values,
  }),
  CouponMaxUsesException: values => ({
    key: i18nErrorKeys.COUPON_MAX_USES,
    values,
  }),
  CouponMinAmountException: values => ({
    key: i18nErrorKeys.COUPON_MIN_AMOUNT,
    values: { minAmount: moneyToFloat(values.value) },
  }),
  CouponMaxAmountException: values => ({
    key: i18nErrorKeys.COUPON_MAX_AMOUNT,
    values: { maxAmount: moneyToFloat(values.value) },
  }),
}

export function* validateDiscountCoupon(coupon) {
  const response = yield call(
    putRequest,
    DISCOUNT_COUPON_ENDPOINT,
    {
      code: coupon,
    },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_DISCOUNT_COUPON,
  })

  return parsedResponse
}

const MAP_GLOBAL_PAYMENT = {
  ShoppingCartPaymentAmountException: values => ({
    key: i18nErrorKeys.PAYMENT_WRONG_AMOUNT,
    values,
  }),
  WalletInsufficientFundsException: values => ({
    key: i18nErrorKeys.INSUFFICIENT_FUNDS,
    values,
  }),
}

export function* submitPayment(paymentMethod, couponCode, cartTotalPayment) {
  const response = yield call(
    postRequest,
    PAYMENT_ENDPOINT,
    {
      paymentMethod,
      couponCode,
      cartTotalPayment,
    },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: { ...MAP_GLOBAL_DISCOUNT_COUPON, ...MAP_GLOBAL_PAYMENT },
  })

  return parsedResponse
}

export function* confirmPayment(paymentIntentId) {
  const response = yield call(
    postRequest,
    PAYMENT_CONFIRMATION_ENDPOINT,
    { paymentIntentId },
    { parseJSON: false },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}
