import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@redradix/components.button'
import DeleteButton from '../buttons/DeleteButton'
import { ReactComponent as NoPhoto } from '../../styles/icons/nophoto.svg'
import { ReactComponent as ErrorPhoto } from '../../styles/icons/errorphoto.svg'
import withTranslations from '../../hocs/withTranslations'
import { getErrorTextFromUploadError } from '../../app/common/uploads-utils'

const ALLOWED_IMAGE_EXTENSIONS = '.png,.jpg,.jpeg,.img,.gif'

const ProfilePicture = ({
  photoURL,
  isUploadingPhoto,
  isDeletingPhoto,
  hasErrorUploadingPhoto,
  hasErrorDeletingPhoto,
  errorUploadingPhoto,
  handleUpload,
  handleDelete,
  photoErrored,
  setErroredURL,
  isLoaded,
  setLoadedURL,
  i18n,
}) => {
  const inputFileRef = useRef()

  const triggerFileInputClick = useCallback(
    () => inputFileRef.current.click(),
    [inputFileRef],
  )

  const errorText = hasErrorUploadingPhoto
    ? getErrorTextFromUploadError(
        errorUploadingPhoto,
        i18n('profile:error-upload-photo'),
        i18n,
      )
    : hasErrorDeletingPhoto
    ? i18n('profile:error-delete-photo')
    : undefined

  const buttonText = isLoaded
    ? i18n('profile:change-photo')
    : i18n('profile:upload-photo')

  const isLoadingPhoto =
    !isLoaded && !photoErrored && !isUploadingPhoto && !isDeletingPhoto

  return (
    <div className="profile-picture">
      <input
        type="file"
        className="hidden-input-file"
        ref={inputFileRef}
        onChange={handleUpload}
        accept={ALLOWED_IMAGE_EXTENSIONS}
        hidden
      />
      <div className="profile-picture-image">
        {!photoErrored && (
          <picture className="image" key={photoURL}>
            <source srcSet={photoURL} />
            <img
              src={photoURL}
              alt="Avatar"
              onLoad={() => setLoadedURL(photoURL)}
              onError={() => setErroredURL(photoURL)}
            />
          </picture>
        )}
        {photoErrored && !errorText && <NoPhoto className="icon" />}
        {photoErrored && !isLoaded && hasErrorUploadingPhoto && (
          <ErrorPhoto className="icon" />
        )}
      </div>
      <div className="profile-picture-controlls">
        <div className="profile-picture-buttons">
          <Button
            type="button"
            hierarchy="tertiary"
            variant="default"
            widthBehaviour="adaptative"
            heightBehaviour="fixed"
            isLoading={isLoadingPhoto || isUploadingPhoto}
            isDisabled={isDeletingPhoto}
            onClick={triggerFileInputClick}
          >
            <p>{!isLoadingPhoto && buttonText}</p>
          </Button>

          {isLoaded && (
            <DeleteButton
              buttonText={i18n('profile:delete-photo')}
              onDelete={handleDelete}
              isLoading={isDeletingPhoto}
              isDisabled={isUploadingPhoto}
            />
          )}
        </div>
        {errorText && <p className="profile-picture-error-text">{errorText}</p>}
      </div>
    </div>
  )
}

ProfilePicture.propTypes = {
  photoURL: PropTypes.string,
  isRequesting: PropTypes.bool,
  isUploadingPhoto: PropTypes.bool,
  isDeletingPhoto: PropTypes.bool,
  hasErrorUploadingPhoto: PropTypes.bool,
  hasErrorDeletingPhoto: PropTypes.bool,
  handleUpload: PropTypes.func,
  handleDelete: PropTypes.func,

  photoErrored: PropTypes.bool,
  setPhotoErrored: PropTypes.func,
  isLoaded: PropTypes.bool,
  setLoadedURL: PropTypes.func,
}

export default withTranslations(ProfilePicture)
