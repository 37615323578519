import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { Input } from '@redradix/components.input'
import Tooltip from '@redradix/components.tooltip'
import Select from '../form-elements/Select'

import SelectSearchable from '../form-elements/SelectSearchable/SelectSearchable'
import FormattedFormErrors from '../../ui/FormattedFormErrors'
import IconIndicator from '../../ui/indicators/IconIndicator'
import Button from '@redradix/components.button'
import InputFile from '../../ui/form-elements/InputFile'
import * as R from 'ramda'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import WarningMessage from '../messages/WarningMessage'
import envConfig from '../../environments'
import { COUNTRIES_WITH_IBAN } from '../../services/media/business'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import TooltipTitle from '../texts/TooltipTitle'

const ALLOWED_DOCUMENT_EXTENSIONS = '.jpg,.png,.pdf,.docx'
const DOWNLOAD_DOC_URL = `${envConfig.authBackendUrl}/session/media/billing`

const MediaBillingForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  setFieldTouched,
  myInfo,
  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  hasSucceeded,

  professionalTypeOptions,
  countryOptions,
  stateOptions,
  currencyOptions,

  hasPendingBalance,
  hasWorkInProgressOrders,
  hasIdentityDocument,
  hasCountry,

  isCompany,
  isSelfEmployedOrIndividual,
  billingCountry,

  requiresResidenceDocument,
  requiresTypeDocument,
}) => {
  const COUNTRIES_WITH_IDENTITY_DOCUMENT_TRANS = ['ES', 'FR', 'IT']
  const getIdentityDocumentTrans = transKeyPrefix => {
    const typeSufix = isCompany
      ? 'company'
      : isSelfEmployedOrIndividual
      ? 'selfEmployedOrIndividual'
      : undefined
    const countrySufix = COUNTRIES_WITH_IDENTITY_DOCUMENT_TRANS.includes(
      billingCountry,
    )
      ? billingCountry
      : undefined
    const transKey =
      typeSufix && countrySufix
        ? [transKeyPrefix, typeSufix, countrySufix].filter(x => x).join(':')
        : transKeyPrefix
    return transKey
  }

  const hasPendingBalanceTooltip = hasPendingBalance
    ? {
        text: i18n(
          'billing-data:tooltip-cannot-change-billing-info-has-pending-balance',
        ),
        position: 'right',
      }
    : undefined

  const hasWorkInProgressOrdersTooltip = hasWorkInProgressOrders
    ? {
        text: i18n(
          'billing-data:tooltip-cannot-change-billing-info-has-wip-orders',
        ),
        position: 'right',
      }
    : undefined

  return (
    <form onSubmit={handleSubmit}>
      <div className="account-block-form">
        <Select
          name="type"
          label={i18n('label:account-type')}
          placeholder={i18n('label:account-type')}
          options={professionalTypeOptions}
          value={values.type}
          isTouched={touched.type}
          isDisabled={isRequestPending || R.isEmpty(professionalTypeOptions)}
          onChange={item =>
            handleChange({ target: { name: 'type', value: item.value } })
          }
          onBlur={e => {
            handleBlur({ target: { name: 'type' } })
          }}
          errorText={
            validationMessages.type && (
              <FormattedFormErrors errors={validationMessages.type} />
            )
          }
        />

        {isCompany && (
          <Input
            type="text"
            name="businessName"
            label={i18n('label:businessName')}
            placeholder={i18n('label:businessName')}
            widthBehaviour="full"
            value={values.businessName}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.businessName}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.businessName && (
                <FormattedFormErrors errors={validationMessages.businessName} />
              )
            }
          />
        )}

        <Input
          type="text"
          name="identityDocument"
          label={
            <>
              {i18n(getIdentityDocumentTrans('label:identityDocument'))}
              <Tooltip
                position="right"
                renderTooltip={() => (
                  <TooltipRegularText
                    text={i18n(
                      getIdentityDocumentTrans(
                        'billing-data:tooltip-identity-document',
                      ),
                    )}
                  />
                )}
                renderTrigger={(
                  { open, close, triggerRef },
                  triggerClasses,
                ) => (
                  <TooltipTrigger
                    classNames={triggerClasses}
                    onMouseOver={open}
                    onMouseOut={close}
                    ref={triggerRef}
                  />
                )}
                hasArrow
                widthFixed
              />
            </>
          }
          placeholder={i18n(getIdentityDocumentTrans('label:identityDocument'))}
          widthBehaviour="full"
          value={values.identityDocument}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.identityDocument}
          isDisabled={isRequestPending}
          errorText={
            validationMessages.identityDocument && (
              <FormattedFormErrors
                errors={validationMessages.identityDocument}
              />
            )
          }
        />

        {isSelfEmployedOrIndividual && (
          <InputFile
            type="text"
            id="identityDocumentUpload"
            name="identityDocumentUpload"
            label={
              <>
                {i18n('label:identityDocumentUpload')}
                <Tooltip
                  position="right"
                  renderTooltip={() => (
                    <TooltipRegularText
                      text={i18n(
                        'billing-data:tooltip-identity-document-upload',
                      )}
                    />
                  )}
                  renderTrigger={(
                    { open, close, triggerRef },
                    triggerClasses,
                  ) => (
                    <TooltipTrigger
                      classNames={triggerClasses}
                      onMouseOver={open}
                      onMouseOut={close}
                      ref={triggerRef}
                    />
                  )}
                  hasArrow
                  widthFixed
                />
              </>
            }
            placeholder={i18n('label:identityDocumentUpload')}
            widthBehaviour="full"
            fileName={R.path(['identityDocumentUpload', 'name'], values)}
            isUploaded={R.path(
              ['identityDocumentUpload', 'isUploaded'],
              values,
            )}
            accept={ALLOWED_DOCUMENT_EXTENSIONS}
            linkText={i18n('action:download-file')}
            linkUrl={`${DOWNLOAD_DOC_URL}/identity-document/${myInfo.id}`}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.identityDocumentUpload}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.identityDocumentUpload && (
                <FormattedFormErrors
                  errors={validationMessages.identityDocumentUpload}
                />
              )
            }
          />
        )}
      </div>

      {isSelfEmployedOrIndividual && (
        <div className="account-block-form">
          <Input
            type="text"
            name="name"
            label={i18n('label:name')}
            placeholder={i18n('label:name')}
            widthBehaviour="full"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.name}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.name && (
                <FormattedFormErrors errors={validationMessages.name} />
              )
            }
          />

          <Input
            type="text"
            name="surname"
            label={i18n('label:surname')}
            placeholder={i18n('label:surname')}
            widthBehaviour="full"
            value={values.surname}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.surname}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.surname && (
                <FormattedFormErrors errors={validationMessages.surname} />
              )
            }
          />
        </div>
      )}

      <div className="account-block-form">
        <Input
          type="text"
          name="address"
          label={i18n('label:address')}
          placeholder={i18n('label:address')}
          widthBehaviour="full"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.address}
          isDisabled={isRequestPending}
          errorText={
            validationMessages.address && (
              <FormattedFormErrors errors={validationMessages.address} />
            )
          }
        />

        <Input
          type="text"
          name="postalCode"
          label={i18n('label:postalCode')}
          placeholder={i18n('label:postalCode')}
          widthBehaviour="full"
          value={values.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.postalCode}
          isDisabled={isRequestPending}
          errorText={
            validationMessages.postalCode && (
              <FormattedFormErrors errors={validationMessages.postalCode} />
            )
          }
        />

        <SelectSearchable
          name="country"
          label={i18n('label:country')}
          /*toolTip={
            hasPendingBalance
              ? hasPendingBalanceTooltip
              : hasWorkInProgressOrdersTooltip
          }*/
          placeholder={i18n('label:country')}
          options={countryOptions}
          value={values.country}
          isTouched={touched.country}
          isDisabled={
            isRequestPending /* ||
              hasPendingBalance ||
              hasWorkInProgressOrders*/ &&
            hasIdentityDocument &&
            hasCountry
          }
          isSearchable={true}
          onChange={item => {
            handleChange({ target: { name: 'country', value: item.value } })
            handleChange({ target: { name: 'state', value: null } })
            setFieldTouched('state', false)
            //handleChange({ target: { name: 'currency', value: null } })
            //setFieldTouched('currency', false)
          }}
          onBlur={_ => {
            handleBlur({ target: { name: 'country' } })
          }}
          errorText={
            validationMessages.country && (
              <FormattedFormErrors errors={validationMessages.country} />
            )
          }
        />
        {/* COOBIS-2404 should be USD */}

        <SelectSearchable
          name="state"
          label={i18n('label:state')}
          placeholder={i18n('label:state')}
          options={stateOptions}
          value={values.state}
          isTouched={touched.state}
          isDisabled={isRequestPending || R.isEmpty(stateOptions)}
          onChange={item =>
            handleChange({ target: { name: 'state', value: item.value } })
          }
          onBlur={e => {
            handleBlur({ target: { name: 'state' } })
          }}
          errorText={
            validationMessages.state && (
              <FormattedFormErrors errors={validationMessages.state} />
            )
          }
        />

        <Input
          type="text"
          name="city"
          label={i18n('label:city')}
          placeholder={i18n('label:city')}
          widthBehaviour="full"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.city}
          isDisabled={isRequestPending}
          errorText={
            validationMessages.city && (
              <FormattedFormErrors errors={validationMessages.city} />
            )
          }
        />

        <Select
          name="currency"
          label={i18n('label:currency')}
          toolTip={
            hasPendingBalance
              ? hasPendingBalanceTooltip
              : hasWorkInProgressOrdersTooltip
          }
          placeholder={i18n('label:currency')}
          options={currencyOptions}
          value={values.currency}
          isTouched={touched.currency}
          isDisabled={
            (isRequestPending ||
              R.isEmpty(currencyOptions) ||
              hasPendingBalance ||
              hasWorkInProgressOrders) &&
            hasIdentityDocument &&
            hasCountry
          }
          onChange={item =>
            handleChange({ target: { name: 'currency', value: item.value } })
          }
          onBlur={e => {
            handleBlur({ target: { name: 'currency' } })
          }}
          errorText={
            validationMessages.currency && (
              <FormattedFormErrors errors={validationMessages.currency} />
            )
          }
        />
        {/* COOBIS-2404 should be USD OJO al permiter cambiar la moneda cuando tenga saldo y no tenga cif */}

        {requiresTypeDocument && (
          <InputFile
            type="text"
            id="typeDocumentUpload"
            name="typeDocumentUpload"
            label={
              <>
                {i18n('label:typeDocumentUpload')}
                <Tooltip
                  position="right"
                  renderTooltip={() => (
                    <>
                      <TooltipRegularText
                        text={i18n('billing-data:tooltip-type-document-036', {
                          strong: name => <strong>{name}</strong>,
                        })}
                      />
                      <TooltipRegularText
                        text={i18n('billing-data:tooltip-type-document-037', {
                          strong: name => <strong>{name}</strong>,
                        })}
                      />
                    </>
                  )}
                  renderTrigger={(
                    { open, close, triggerRef },
                    triggerClasses,
                  ) => (
                    <TooltipTrigger
                      classNames={triggerClasses}
                      onMouseOver={open}
                      onMouseOut={close}
                      ref={triggerRef}
                    />
                  )}
                  hasArrow
                  widthFixed
                />
              </>
            }
            placeholder={i18n('label:typeDocumentUpload')}
            widthBehaviour="full"
            fileName={R.path(['typeDocumentUpload', 'name'], values)}
            isUploaded={R.path(['typeDocumentUpload', 'isUploaded'], values)}
            accept={ALLOWED_DOCUMENT_EXTENSIONS}
            linkText={i18n('action:download-file')}
            linkUrl={`${DOWNLOAD_DOC_URL}/type-document/${myInfo.id}`}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.typeDocumentUpload}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.typeDocumentUpload && (
                <FormattedFormErrors
                  errors={validationMessages.typeDocumentUpload}
                />
              )
            }
          />
        )}

        {requiresResidenceDocument && (
          <InputFile
            type="text"
            id="residenceDocumentUpload"
            name="residenceDocumentUpload"
            label={
              <>
                {i18n('label:residenceDocumentUpload')}
                <Tooltip
                  position="right"
                  renderTooltip={() => (
                    <>
                      <TooltipTitle
                        text={i18n(
                          'billing-data:tooltip-residence-document-title',
                        )}
                      />
                      <TooltipRegularText
                        text={i18n(
                          'billing-data:tooltip-residence-document-description',
                        )}
                      />
                    </>
                  )}
                  renderTrigger={(
                    { open, close, triggerRef },
                    triggerClasses,
                  ) => (
                    <TooltipTrigger
                      classNames={triggerClasses}
                      onMouseOver={open}
                      onMouseOut={close}
                      ref={triggerRef}
                    />
                  )}
                  hasArrow
                  widthFixed
                />
              </>
            }
            placeholder={i18n('label:residenceDocumentUpload')}
            widthBehaviour="full"
            fileName={R.path(['residenceDocumentUpload', 'name'], values)}
            isUploaded={R.path(
              ['residenceDocumentUpload', 'isUploaded'],
              values,
            )}
            accept={ALLOWED_DOCUMENT_EXTENSIONS}
            linkText={i18n('action:download-file')}
            linkUrl={`${DOWNLOAD_DOC_URL}/residence-document/${myInfo.id}`}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.residenceDocumentUpload}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.residenceDocumentUpload && (
                <FormattedFormErrors
                  errors={validationMessages.residenceDocumentUpload}
                />
              )
            }
          />
        )}
      </div>

      <div className="account-block-form">
        <Input
          type="text"
          name="paypal"
          label={i18n('label:paypal')}
          placeholder={i18n('label:paypal')}
          widthBehaviour="full"
          value={values.paypal}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.paypal}
          isDisabled={isRequestPending}
          errorText={
            validationMessages.paypal && (
              <FormattedFormErrors errors={validationMessages.paypal} />
            )
          }
        />

        {COUNTRIES_WITH_IBAN.includes(R.path(['country', 'value'], values)) && (
          <Input
            type="text"
            name="iban"
            label={i18n('label:iban')}
            placeholder={i18n('label:iban')}
            widthBehaviour="full"
            value={values.iban}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.iban}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.iban && (
                <FormattedFormErrors errors={validationMessages.iban} />
              )
            }
          />
        )}
      </div>

      <div className="account-block-form">
        {globalMessages && (
          <WarningMessage
            text={<FormattedFormErrors errors={globalMessages} />}
          />
        )}

        <div className="button-container">
          {hasSucceeded ? (
            <IconIndicator type={'success'} />
          ) : (
            <Button
              type="submit"
              hierarchy="secondary"
              isLoading={isRequestPending}
            >
              {i18n('action:save')}
            </Button>
          )}
        </div>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  type: CoobisPropTypes.makeSelectOption(PropTypes.string),
  name: PropTypes.string,
  surname: PropTypes.string,
  surname2: PropTypes.string,

  iban: PropTypes.string,
  paypal: PropTypes.string,
  typeDocumentUpload: PropTypes.any,
  identityDocumentUpload: PropTypes.any,
  residenceDocumentUpload: PropTypes.any,

  businessName: PropTypes.string,
  identityDocument: PropTypes.string,
  country: CoobisPropTypes.makeSelectOption(PropTypes.string),
  state: CoobisPropTypes.makeSelectOption(PropTypes.string),
  city: PropTypes.string,
  address: PropTypes.string,
  postalCode: PropTypes.string,
}

MediaBillingForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  hasSucceeded: PropTypes.bool.isRequired,
  setConfirmModalOpen: PropTypes.func,
  professionalTypeOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  countryOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  stateOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  currencyOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  hasPendingBalance: PropTypes.bool.isRequired,
  hasWorkInProgressOrders: PropTypes.bool.isRequired,
  hasIdentityDocument: PropTypes.bool.isRequired,
  hasCountry: PropTypes.bool.isRequired,
  isCompany: PropTypes.bool.isRequired,
  isSelfEmployedOrIndividual: PropTypes.bool.isRequired,
  billingCountry: PropTypes.string.isRequired,
  requiresResidenceDocument: PropTypes.bool.isRequired,
  requiresTypeDocument: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default MediaBillingForm
