import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { globalMessagesPropType } from '../../hocs/withFormErrors'
import WarningMessage from '../messages/WarningMessage'
import FormattedFormErrors from '../FormattedFormErrors'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import Paragraph from '../texts/Paragraph'
import PreformattedText from '../texts/PreformattedText'

const OrderModalReviewCounterbriefing = ({
  onAccept,
  isAcceptRequestPending,
  onReject,
  isRejectRequestPending,
  text,
  globalMessages,
  i18n,
}) => {
  return (
    <div className="order-modal">
      <ModalSimpleContent
        doubleActions
        action1Text={i18n('action:reject')}
        action1={onReject}
        isButton1Loading={isRejectRequestPending}
        action2Text={i18n('action:accept')}
        action2={onAccept}
        isButton2Loading={isAcceptRequestPending}
        buttonVariant2="default"
        isButtonDisabled={isAcceptRequestPending || isRejectRequestPending}
      >
        <ModalHeader title={i18n('action:review-counterbriefing')} />
        <Paragraph title={i18n('noun:media-counterbriefing')}>
          <div className="modal-large-content">
            <PreformattedText text={text} />
          </div>
        </Paragraph>
        {globalMessages && (
          <WarningMessage
            text={<FormattedFormErrors errors={globalMessages} />}
          />
        )}
      </ModalSimpleContent>
    </div>
  )
}

OrderModalReviewCounterbriefing.propTypes = {
  onAccept: PropTypes.func,
  isAcceptRequestPending: PropTypes.bool,
  onReject: PropTypes.func,
  isRejectRequestPending: PropTypes.bool,
  text: PropTypes.string,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  globalMessages: globalMessagesPropType,
}

export default withTranslations(OrderModalReviewCounterbriefing)
