import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { FormattedMessage } from 'react-intl'
import Card from '../cards/Card'
import HistoryItem from '../blocks/HistoryItem'
import Spinner from '../loaders/Spinner'
import PreformattedText from '../texts/PreformattedText'

const OrderDetailHistory = ({
  items,
  isLoading,
  resourceName,
  brandName,
  i18n,
}) => {
  return (
    <section className="order-detail-section history">
      <Card>
        <div className="order-detail-history-frame">
          {isLoading ? (
            <Spinner size={60} text={i18n('status:loading-history')} />
          ) : (
            <>
              {items.map(item => (
                <HistoryItem
                  key={item.id}
                  photoURL={item.photoURL}
                  username={item.username}
                  date={item.date}
                  titleSlot={
                    <FormattedMessage
                      id={`${item.actionText}`}
                      defaultMessage={`${item.actionText}`}
                      values={{
                        username: (
                          <span className="username">{`${item.username}`}</span>
                        ),
                        resourcename: (
                          <span className="username">{`${resourceName}`}</span>
                        ),
                        brandname: (
                          <span className="username">{`${brandName}`}</span>
                        ),
                      }}
                    />
                  }
                >
                  {!!item.messageSlot && (
                    <PreformattedText text={item.messageSlot} />
                  )}
                </HistoryItem>
              ))}
            </>
          )}
        </div>
      </Card>
    </section>
  )
}

OrderDetailHistory.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  i18n: PropTypes.func,
}

export default withTranslations(OrderDetailHistory)
