import * as actionTypes from './action-types'
import * as hash from 'reduken/hash'
import * as api from './api'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
} from '../communication/actions'
import {
  DOMAIN,
  HASH_KEY_BALANCE,
  REFRESH_BALANCE_TIME,
  HASH_KEY_MOVEMENTS,
  HASH_KEY_TOTAL_INCOME,
  HASH_KEY_RECHARGE_DISCOUNT_COUPON,
  HASH_KEY_IS_WITHDRAW_FUNDS_MODAL_OPEN,
  HASH_KEY_IS_BILLING_DATA_DISCLAIMER_MODAL_OPEN,
  HASH_KEY_WITHDRAW_FUNDS_DATA,
  HASH_KEY_IS_WITHDRAW_SUCCESS_MODAL_OPEN,
  REFRESH_WALLET_MOVEMENTS_TIME,
} from './constants'
import { getScope } from '../auth'
import {
  periodicFetchWallet,
  periodicFetchBrandWalletMovements,
  periodicFetchMediaWalletMovements,
  closeBalanceRechargeModal,
} from './actions'
import repeatingFetchEffect from '../repeating-fetch-effect'
import { AuthScope } from '../auth/business'
import { isUserFacingStripeError } from '../checkout/business'
import { addSuccessToast } from '../toasts/actions'
import { MAP_GLOBAL_DISCOUNT_COUPON } from '../checkout/api'
import { getHasCompletedBillingData } from '../media/getHasCompletedBillingData'

export function* fetchWalletSaga() {
  yield put(startRequest(actionTypes.FETCH_WALLET))

  const scope = yield select(getScope)
  const response = yield call(api.fetchBalance, scope)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_WALLET, response))
    return
  }

  // Custom error if wallet has not balance
  if (!response.data || !response.data.balance) {
    yield put(
      endRequestError(actionTypes.FETCH_WALLET, new Error('Missing balance')),
    )
    return
  }

  // Media wallets has total income
  if (scope === AuthScope.MEDIA) {
    if (!response.data || !response.data.totalCurrency) {
      yield put(
        endRequestError(
          actionTypes.FETCH_WALLET,
          new Error('Missing total income'),
        ),
      )
      return
    }

    yield put(
      hash.set(DOMAIN, HASH_KEY_TOTAL_INCOME, response.data.totalCurrency),
    )
  }

  yield put(hash.set(DOMAIN, HASH_KEY_BALANCE, response.data.balance))
  yield put(endRequestSuccess(actionTypes.FETCH_WALLET))
}

export function* fetchWalletMovementsSaga({ payload: { page } }) {
  yield put(startRequest(actionTypes.FETCH_MOVEMENTS))

  const scope = yield select(getScope)
  const response = yield call(api.fetchWalletMovements, scope, page)
  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_MOVEMENTS, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_MOVEMENTS, response.data))
  yield put(endRequestSuccess(actionTypes.FETCH_MOVEMENTS))
}

function* validateRechargeDiscountCouponSaga({ payload: { amount, coupon } }) {
  yield put(startRequest(actionTypes.VALIDATE_RECHARGE_DISCOUNT_COUPON))

  const response = yield call(
    api.validateRechargeDiscountCoupon,
    amount,
    coupon,
  )

  if (response.error) {
    const globalMessagesKey = Object.keys(response.errorData.global_messages)
    const couponCodeErrorKeys = Object.keys(MAP_GLOBAL_DISCOUNT_COUPON)
    const hasCouponCodeError = globalMessagesKey.some(key =>
      couponCodeErrorKeys.includes(key),
    )

    // Re-fetch recharge summary if invalid coupon
    if (hasCouponCodeError) {
      yield call(validateRechargeDiscountCouponSaga, { payload: { amount } })
    }

    yield put(
      endRequestError(actionTypes.VALIDATE_RECHARGE_DISCOUNT_COUPON, response),
    )
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_RECHARGE_DISCOUNT_COUPON, response.data))
  yield put(endRequestSuccess(actionTypes.VALIDATE_RECHARGE_DISCOUNT_COUPON))
}

export function* openWithdrawFundsModalSaga() {
  const hasCompletedBillingData = yield select(getHasCompletedBillingData)

  if (hasCompletedBillingData) {
    yield put(hash.set(DOMAIN, HASH_KEY_IS_WITHDRAW_FUNDS_MODAL_OPEN, true))
  } else {
    yield put(
      hash.set(DOMAIN, HASH_KEY_IS_BILLING_DATA_DISCLAIMER_MODAL_OPEN, true),
    )
  }
}

export function* closeWithdrawFundsModalSaga() {
  yield put(hash.set(DOMAIN, HASH_KEY_IS_WITHDRAW_FUNDS_MODAL_OPEN, false))
  yield put(
    hash.set(DOMAIN, HASH_KEY_IS_BILLING_DATA_DISCLAIMER_MODAL_OPEN, false),
  )
  yield put(hash.set(DOMAIN, HASH_KEY_IS_WITHDRAW_SUCCESS_MODAL_OPEN, false))
}

export function* fetchWithdrawFundsDataSaga() {
  yield put(startRequest(actionTypes.FETCH_WITHDRAW_FUNDS_DATA))

  const response = yield call(api.fetchWithdrawFundsData)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_WITHDRAW_FUNDS_DATA, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_WITHDRAW_FUNDS_DATA, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_WITHDRAW_FUNDS_DATA))
}

export function* withdrawFundsSaga({ payload }) {
  yield put(startRequest(actionTypes.WITHDRAW_FUNDS))

  let invoiceUploadId

  // Upload the invoice if needed
  if (payload.invoiceUpload) {
    const invoiceUploadResponse = yield call(
      api.uploadInvoiceFile,
      payload.invoiceUpload,
    )

    if (invoiceUploadResponse.error) {
      yield put(
        endRequestError(actionTypes.WITHDRAW_FUNDS, invoiceUploadResponse),
      )
      return
    }

    invoiceUploadId = invoiceUploadResponse.data.uploadId
  }

  const response = yield call(api.withdrawFunds, {
    ...payload,
    invoiceUpload: invoiceUploadId,
  })

  if (response.error) {
    yield put(endRequestError(actionTypes.WITHDRAW_FUNDS, response))

    // We shouldn't have received this error message
    // It means our balance is desynchronized, update it
    if (
      response.errorData &&
      response.errorData.global_messages &&
      response.errorData.global_messages.WithdrawalAmountMismatchException
    ) {
      yield call(fetchWalletSaga)
    }

    return
  }

  yield all([call(fetchWalletSaga), put(periodicFetchMediaWalletMovements())])

  yield call(closeWithdrawFundsModalSaga)
  yield put(hash.set(DOMAIN, HASH_KEY_IS_WITHDRAW_SUCCESS_MODAL_OPEN, true))

  yield put(endRequestSuccess(actionTypes.WITHDRAW_FUNDS))
}

export function* rechargeFundsSaga({ payload: { stripe, ...data } }) {
  yield put(startRequest(actionTypes.RECHARGE_FUNDS))

  const response = yield call(api.rechargeFunds, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.RECHARGE_FUNDS, response))
    return
  }

  const result = yield call(
    [stripe, 'handleCardPayment'],
    response.data.authToken,
  )

  const { error: stripeError } = result

  if (stripeError) {
    if (isUserFacingStripeError(stripeError)) {
      yield put(
        endRequestError(actionTypes.RECHARGE_FUNDS, {
          globalMessages: [
            { key: 'errors:stripe-user-facing-generic', values: stripeError },
          ],
        }),
      )
    } else {
      yield put(
        endRequestError(actionTypes.RECHARGE_FUNDS, {
          globalMessages: [
            { key: 'errors:stripe-technical-error', values: stripeError },
          ],
        }),
      )
    }

    return
  }

  yield all([call(fetchWalletSaga), put(periodicFetchBrandWalletMovements())])

  // SUCCESS
  yield put(
    addSuccessToast(
      'add-funds:success-toast-title',
      'add-funds:success-toast-description',
    ),
  )
  yield put(closeBalanceRechargeModal())

  yield put(endRequestSuccess(actionTypes.RECHARGE_FUNDS))
}

export default function*() {
  yield all([
    repeatingFetchEffect({
      fetchSaga: fetchWalletSaga,
      intervalMillisecs: REFRESH_BALANCE_TIME,
      startAction: periodicFetchWallet(),
      requestActionType: actionTypes.FETCH_WALLET,
    }),
    takeLatest(actionTypes.FETCH_MOVEMENTS, fetchWalletMovementsSaga),
    takeLatest(
      actionTypes.VALIDATE_RECHARGE_DISCOUNT_COUPON,
      validateRechargeDiscountCouponSaga,
    ),
    takeLatest(
      actionTypes.OPEN_WITHDRAW_FUNDS_MODAL,
      openWithdrawFundsModalSaga,
    ),
    takeLatest(
      actionTypes.CLOSE_WITHDRAW_FUNDS_MODAL,
      closeWithdrawFundsModalSaga,
    ),
    takeLatest(
      actionTypes.FETCH_WITHDRAW_FUNDS_DATA,
      fetchWithdrawFundsDataSaga,
    ),
    takeLatest(actionTypes.WITHDRAW_FUNDS, withdrawFundsSaga),
    takeLatest(actionTypes.RECHARGE_FUNDS, rechargeFundsSaga),
    repeatingFetchEffect({
      fetchSaga: fetchWalletMovementsSaga,
      intervalMillisecs: REFRESH_WALLET_MOVEMENTS_TIME,
      startAction: periodicFetchBrandWalletMovements(),
      requestActionType: actionTypes.PERIODIC_FETCH_BRAND_MOVEMENTS,
      limitRepeat: 5,
    }),
    repeatingFetchEffect({
      fetchSaga: fetchWalletMovementsSaga,
      intervalMillisecs: REFRESH_WALLET_MOVEMENTS_TIME,
      startAction: periodicFetchMediaWalletMovements(),
      requestActionType: actionTypes.PERIODIC_FETCH_MEDIA_MOVEMENTS,
      limitRepeat: 5,
    }),
  ])
}
