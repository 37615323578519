import React from 'react'
import PropTypes from 'prop-types'

const TooltipInfoText = props => {
  return <p className="tooltip-info-text">{props.text}</p>
}

TooltipInfoText.propTypes = {
  text: PropTypes.string,
}

export default TooltipInfoText
