import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Glide from '@glidejs/glide'
//import { MediaQueryLap } from '../shared/MediaQueries'
import withTranslations from '../../hocs/withTranslations'
import Card from '../cards/Card'
import { ReactComponent as ChevronLeft } from '../../styles/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from '../../styles/icons/chevron-right.svg'

const CampaignDetailSlider = ({ children, i18n }) => {
  const sliderNode = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const glide = new Glide(sliderNode.current, {
      type: 'slider',
      startAt: 0,
      gap: -10,
      perView: 3,
      focusAt: 'center',
      breakpoints: {
        1024: { perView: 2 },
        768: { perView: 1 },
      },
    })
    glide.on(['mount.before', 'run'], () => setCurrentSlide(glide.index))
    glide.mount()

    return () => glide.destroy()
  }, [])
  return (
    <section className="campaign-detail-preview-section">
      <Card isHighlighted>
        <div className="preview-card">
          <h3 className="preview-card-title">{i18n('noun:previews')}</h3>
          <div className="preview-card-wrapper">
            <div className="campaign-detail-slider">
              <div className="glide" ref={sliderNode}>
                <div className="glide__track" data-glide-el="track">
                  <div className="glide__slides">{children}</div>
                </div>
                {children.length > 1 && (
                  <div className="glide__arrows" data-glide-el="controls">
                    <button
                      className={`${
                        currentSlide === 0
                          ? 'glide__arrow glide__arrow--left glide__arrow--disabled'
                          : 'glide__arrow glide__arrow--left'
                      }`}
                      data-glide-dir="<"
                    >
                      <ChevronLeft className="icon" />
                    </button>
                    <button
                      className={`${
                        currentSlide === children.length - 1
                          ? 'glide__arrow glide__arrow--right glide__arrow--disabled'
                          : 'glide__arrow glide__arrow--right'
                      }`}
                      data-glide-dir=">"
                    >
                      <ChevronRight className="icon" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </section>
  )
}

CampaignDetailSlider.propTypes = {
  children: PropTypes.any,
}

export default withTranslations(CampaignDetailSlider)
