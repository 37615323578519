import { compose, branch, withProps, renderComponent } from 'recompose'
import * as R from 'ramda'
import withHasRequestWithIdError from './withHasRequestWithIdError'
import Error500Message from '../ui/global-message/Error500Message'

const OUT_PROP = 'withRouteServerErrorWithId/hasRequestError'

const withRouteServerErrorWithId = (requestType, idProp = 'id') =>
  compose(
    withHasRequestWithIdError(requestType, { idProp, outProp: OUT_PROP }),
    branch(ownProps => ownProps[OUT_PROP], renderComponent(Error500Message)),
    withProps(R.dissoc(OUT_PROP)),
  )

export default withRouteServerErrorWithId
