import { compose, withPropsOnChange, withProps } from 'recompose'
import * as R from 'ramda'
import { withTranslatedCountries } from '../services/config/hocs'
import withCountrySelectField from './withCountrySelectField'

const TRANSLATED_COUNTRIES_PROP =
  'withCountryStateSelectFields/translatedCountries'

const withCountryStateSelectFields = ({
  valuesProp = 'values',
  countryOptionsProp = 'countryOptions',
  stateOptionsProp = 'stateOptions',
  countryValueField = 'country',
  stateValueField = 'state',
} = {}) =>
  compose(
    withCountrySelectField({
      valuesProp,
      optionsProps: countryOptionsProp,
      valueField: countryValueField,
    }),

    withTranslatedCountries({ outProp: TRANSLATED_COUNTRIES_PROP }),

    withPropsOnChange([TRANSLATED_COUNTRIES_PROP, valuesProp], props => {
      const values = props[valuesProp]

      const stateOptions = values[countryValueField]
        ? R.pipe(
            R.find(R.propEq('id', values[countryValueField].value)),
            R.prop('provinces'),
            R.values,
            R.map(item => ({
              value: item.id,
              label: item.label,
              isDisabled: false,
            })),
            R.sort((a, b) => a.label.localeCompare(b.label)),
          )(props[TRANSLATED_COUNTRIES_PROP])
        : []

      const stateValue =
        values[countryValueField] && values[stateValueField]
          ? R.pipe(
              R.find(R.propEq('id', values[countryValueField].value)),
              R.prop('provinces'),
              R.prop(values[stateValueField]),
              R.ifElse(
                R.isNil,
                () => ({
                  value: values[stateValueField],
                  label: values[stateValueField],
                  isDisabled: false,
                }),
                ({ id, label }) => ({
                  value: id,
                  label,
                  isDisabled: false,
                }),
              ),
            )(props[TRANSLATED_COUNTRIES_PROP])
          : null

      return {
        [valuesProp]: {
          ...values,
          [stateValueField]: stateValue,
        },
        [stateOptionsProp]: stateOptions,
      }
    }),

    withProps(R.dissoc(TRANSLATED_COUNTRIES_PROP)),
  )

export default withCountryStateSelectFields
