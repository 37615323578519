import React from 'react'
import withTranslations from '../../hocs/withTranslations'
import { ReactComponent as Bubble1 } from './images/search-bubble-1.svg'
import { ReactComponent as Bubble2 } from './images/search-bubble-2.svg'
import { ReactComponent as Bubble3 } from './images/search-bubble-3.svg'
import { ReactComponent as Bubble4 } from './images/search-bubble-4.svg'
import { ReactComponent as Bubble5 } from './images/search-bubble-5.svg'
import { ReactComponent as Bubble6 } from './images/search-bubble-6.svg'
import Card from './Card'

const BubblesCard = ({ children }) => {
  return (
    <Card isHighlighted isContained>
      <div className="bubbles-card">
        <Bubble1 className="bubbles-card-bubble -bubble-1" />
        <Bubble2 className="bubbles-card-bubble -bubble-2" />
        <Bubble3 className="bubbles-card-bubble -bubble-3" />
        <Bubble6 className="bubbles-card-bubble -bubble-4" />
        <Bubble4 className="bubbles-card-bubble -bubble-5" />
        <Bubble5 className="bubbles-card-bubble -bubble-6" />
        <div className="bubbles-card-content">{children}</div>
      </div>
    </Card>
  )
}

BubblesCard.propTypes = {}

export default withTranslations(BubblesCard)
