import React from 'react'
import PropTypes from 'prop-types'
import ModalSimpleContent from './ModalSimpleContent'
import withTranslations from '../../hocs/withTranslations'
import WarningMessage from '../messages/WarningMessage'

const ActionConfirmationModal = ({
  i18n,
  title,
  description,
  mainActionText,
  errorText,
  hasError,

  secondaryAction,
  mainAction,

  ...props
}) => (
  <ModalSimpleContent
    doubleActions
    title={title}
    subtitle={description}
    action1Text={i18n('action:cancel')}
    action1={secondaryAction}
    action2Text={mainActionText}
    action2={mainAction}
    {...props}
  >
    {hasError && (
      <WarningMessage iconType="warning" text={errorText}></WarningMessage>
    )}
  </ModalSimpleContent>
)

ActionConfirmationModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  mainActionText: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  mainAction: PropTypes.func,
  secondaryAction: PropTypes.func,
}

ActionConfirmationModal.defaultProps = {
  mainAction: a => a,
  secondaryAction: a => a,
}

export default withTranslations(ActionConfirmationModal)
