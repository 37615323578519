import React from 'react'
import PropTypes from 'prop-types'
import ModalSimpleContent from './ModalSimpleContent'
import { Input } from '@redradix/components.input'
import withTranslations from '../../hocs/withTranslations'
import FormattedFormErrors from '../FormattedFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import WarningMessage from '../messages/WarningMessage'

const CreateCampaignModal = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => (
  <form onSubmit={handleSubmit}>
    <ModalSimpleContent
      title={i18n('create-campaign-modal:title')}
      actionText={i18n('create-campaign-modal:button-text')}
      isButtonLoading={isRequestPending}
    >
      <Input
        type="text"
        name="name"
        label={i18n('create-campaign-modal:input-label')}
        placeholder={i18n('create-campaign-modal:input-label')}
        widthBehaviour="full"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.name}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.name && (
            <FormattedFormErrors errors={validationMessages.name} />
          )
        }
      />

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}
    </ModalSimpleContent>
  </form>
)

const FORM_VALUES_PROPTYPES = {
  name: PropTypes.string,
}

CreateCampaignModal.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(CreateCampaignModal)
