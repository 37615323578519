import { SERVICE } from './constants'

export const FETCH_WALLET = `${SERVICE}/FETCH_WALLET`

export const PERIODIC_FETCH_WALLET = `${SERVICE}/PERIODIC_FETCH_WALLET`

export const FETCH_MOVEMENTS = `${SERVICE}/FETCH_MOVEMENTS`

export const PERIODIC_FETCH_BRAND_MOVEMENTS = `${SERVICE}/PERIODIC_FETCH_BRAND_MOVEMENTS`

export const PERIODIC_FETCH_MEDIA_MOVEMENTS = `${SERVICE}/PERIODIC_FETCH_MEDIA_MOVEMENTS`

export const OPEN_BALANCE_RECHARGE_MODAL = `${SERVICE}/OPEN_BALANCE_RECHARGE_MODAL`

export const VALIDATE_RECHARGE_DISCOUNT_COUPON = `${SERVICE}/VALIDATE_RECHARGE_DISCOUNT_COUPON`

export const OPEN_WITHDRAW_FUNDS_MODAL = `${SERVICE}/OPEN_WITHDRAW_FUNDS_MODAL`

export const CLOSE_WITHDRAW_FUNDS_MODAL = `${SERVICE}/CLOSE_WITHDRAW_FUNDS_MODAL`

export const FETCH_WITHDRAW_FUNDS_DATA = `${SERVICE}/FETCH_WITHDRAW_FUNDS_DATA`

export const WITHDRAW_FUNDS = `${SERVICE}/WITHDRAW_FUNDS`

export const RECHARGE_FUNDS = `${SERVICE}/RECHARGE_FUNDS`
