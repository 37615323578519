import * as actionTypes from './action-types'

export const uploadPhoto = (scope, file) => ({
  type: actionTypes.UPLOAD,
  payload: { scope, file },
})

export const deletePhoto = scope => ({
  type: actionTypes.DELETE,
  payload: { scope },
})
