import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailTiktokPublication from '../../ui/campaigns/views/CampaignDetailTiktokPublication'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  views: stats.kpis.views,
  likes: stats.kpis.followers,
  engagements: stats.kpis.engagements,
}))

const enhance = compose(
  withKPIs,
  withExportReportContents(),
)

export default enhance(CampaignDetailTiktokPublication)
