const useRecaptcha = () => (action, handleAction) => {
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
  if (!siteKey) {
    console.log('ReCaptcha site key not defined')
    handleAction()
    return
  }
  const grecaptcha = window.grecaptcha
  grecaptcha.ready(function() {
    grecaptcha
      .execute(siteKey, { action })
      .then(recaptcha => handleAction(recaptcha, action))
  })
}

export default useRecaptcha
