import React from 'react'
import PropTypes from 'prop-types'
import { MediaQueryPalm, MediaQueryLap } from '../shared/MediaQueries'
import withTranslations from '../../hocs/withTranslations'
import BreadcrumbNavigation from '../navigation/BreadcrumbNavigation'
import BreadcrumbItem from '../navigation/BreadcrumbItem'
import {
  ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA,
  ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES,
  prettifyConstant,
} from '../../app/common/routes'
import { SOCIAL_RESOURCE_SECTIONS } from '../../services/resources/constants'

const SocialResourceHeader = ({
  i18n,
  currentNavItem,
  reachedNavItem,
  resourceId,
  socialNetwork,
  onClickItem,
}) => {
  const generalDataURL = resourceId
    ? ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA.linkTo({
        id: resourceId,
        socialNetwork: prettifyConstant(socialNetwork),
      })
    : ''

  const servicesURL = resourceId
    ? ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES.linkTo({
        id: resourceId,
        socialNetwork: prettifyConstant(socialNetwork),
      })
    : ''

  const servicesNavItemId = 2

  return (
    <div className="steps-layout-header">
      <div className="wrapper">
        <div className="steps-layout-header-content">
          <MediaQueryPalm>
            <BreadcrumbNavigation>
              <BreadcrumbItem
                url={generalDataURL}
                text={i18n('sections:data-short')}
                listNumber={SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA}
                onClick={onClickItem}
                isActive={
                  currentNavItem === SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA
                }
                isDisabled={
                  reachedNavItem < SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA
                }
              />
              <BreadcrumbItem
                url={servicesURL}
                text={i18n('sections:services-prices-short')}
                listNumber={servicesNavItemId}
                onClick={onClickItem}
                isActive={currentNavItem === servicesNavItemId}
                isDisabled={reachedNavItem < servicesNavItemId}
              />
            </BreadcrumbNavigation>
          </MediaQueryPalm>
          <MediaQueryLap>
            <BreadcrumbNavigation>
              <BreadcrumbItem
                url={generalDataURL}
                text={i18n('sections:data-long')}
                listNumber={SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA}
                onClick={onClickItem}
                isActive={
                  currentNavItem === SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA
                }
                isDisabled={
                  reachedNavItem < SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA
                }
              />
              <BreadcrumbItem
                url={servicesURL}
                text={i18n('sections:services-prices-long')}
                listNumber={servicesNavItemId}
                onClick={onClickItem}
                isActive={currentNavItem === servicesNavItemId}
                isDisabled={reachedNavItem < servicesNavItemId}
              />
            </BreadcrumbNavigation>
          </MediaQueryLap>
        </div>
      </div>
    </div>
  )
}

SocialResourceHeader.propTypes = {
  currentNavItem: PropTypes.number,
  reachedNavItem: PropTypes.number,
  resourceId: PropTypes.string,
  onClickItem: PropTypes.func,
  socialNetwork: PropTypes.string,
}

export default withTranslations(SocialResourceHeader)
