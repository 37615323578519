import React, { useState, useMemo } from 'react'
import * as R from 'ramda'
import RegularCheckbox from '../../ui/form-elements/RegularCheckbox'
import { entitiesArrayToKeyedObject } from '../utils'
import withTranslations from '../../hocs/withTranslations'

const MultiSelect = ({
  error,
  data,
  value,
  handleChange,
  translateKey = R.identity,

  size,
  hasCount,
  allData,
  isAlphabeticSorted,

  i18n,
  i18nNumber,
}) => {
  const [isOpen, setOpen] = useState(false)

  const memoizedData = useMemo(() => {
    const translatedData = data.map(item => ({
      ...item,
      label: translateKey(item.key),
    }))

    const emptyData = allData.map(item => ({
      key: item,
      label: translateKey(item),
      doc_count: 0,
    }))

    const finalData = R.values(
      R.mergeDeepLeft(
        entitiesArrayToKeyedObject(translatedData, 'key'),
        entitiesArrayToKeyedObject(emptyData, 'key'),
      ),
    )

    // Order by allData indices if no count is available and !isAlphabeticSorted
    const sortedData = isAlphabeticSorted
      ? R.sort((a, b) => a.label.localeCompare(b.label), finalData)
      : !hasCount
      ? R.sortBy(
          R.pipe(
            R.prop('key'),
            R.indexOf(R.__, allData),
          ),
          finalData,
        )
      : finalData

    if (!isOpen && size) {
      return sortedData.slice(0, size)
    }

    return sortedData
  }, [data, allData, hasCount, isAlphabeticSorted, isOpen, size, translateKey])

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  return (
    <>
      {memoizedData.map(item => {
        const checked = value[item.key] || false

        return (
          <RegularCheckbox
            key={item.key}
            label={item.label}
            counterNumber={hasCount ? i18nNumber(item.doc_count) : null}
            checked={checked}
            isDisabled={!checked && item.doc_count === 0}
            onChange={() => handleChange(item.key)}
          />
        )
      })}

      {size &&
        (isOpen ? (
          <button type="button" onClick={() => setOpen(false)}>
            {i18n('action:show-less')}
          </button>
        ) : (
          <button type="button" onClick={() => setOpen(true)}>
            {i18n('action:show-more')}
          </button>
        ))}
    </>
  )
}

export default withTranslations(MultiSelect)
