import React from 'react'
import PropTypes from 'prop-types'
import ModalTitle from '../texts/ModalTitle'

const MessageModalContent = ({ imageUrl, title, children, actions }) => {
  return (
    <div className="message-modal-content">
      <div className="message-modal-content-image">
        <picture>
          <img src={imageUrl} alt="Decoration" />
        </picture>
      </div>
      <ModalTitle text={title} />
      <div className="message-modal-content-texts">{children}</div>
      {actions && (
        <div className="message-modal-content-action-group">{actions}</div>
      )}
    </div>
  )
}

MessageModalContent.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
}

export default MessageModalContent
