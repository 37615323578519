import * as actionTypes from './action-types'

export const addInfoToast = (title, description) => ({
  type: actionTypes.ADD_TOAST,
  payload: { type: 'default', title, description },
})

export const addSuccessToast = (title, description) => ({
  type: actionTypes.ADD_TOAST,
  payload: { type: 'success', title, description },
})

export const addWarningToast = (title, description, values) => ({
  type: actionTypes.ADD_TOAST,
  payload: { type: 'warning', title, description, values },
})

export const addErrorToast = (title, description) => ({
  type: actionTypes.ADD_TOAST,
  payload: { type: 'error', title, description },
})

export const closeToast = () => ({
  type: actionTypes.CLOSE_TOAST,
})
