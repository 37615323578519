import * as actionTypes from './action-types'
import { all, takeLatest, call, put, takeEvery } from 'redux-saga/effects'
import { updateEntities, mergeEntities, removeOne } from 'reduken/entities'
import { ENTITY_KEY_FAVORITES } from './constants'
import {
  startRequest,
  endRequestError,
  endRequestSuccess,
  startRequestWithId,
  endRequestWithIdError,
  endRequestWithIdSuccess,
} from '../communication/actions'
import * as api from './api'
import { entitiesArrayToKeyedObject } from '../../app/utils'

const EMPTY_FAVORITES = {}

function* fetchMyFavoritesSaga() {
  yield put(startRequest(actionTypes.FETCH_MY_FAVORITES))

  const response = yield call(api.fetchMyFavorites)

  if (response.error) {
    // When the brand has no favorites backend send us a ResourcesNotFoundException
    // so we set favorites to empty array
    const isNoFavoritesException =
      response.errorData &&
      response.errorData.detail &&
      response.errorData.detail === 'ResourcesNotFoundException'

    if (isNoFavoritesException) {
      yield put(
        updateEntities({
          [ENTITY_KEY_FAVORITES]: EMPTY_FAVORITES,
        }),
      )
      yield put(endRequestSuccess(actionTypes.FETCH_MY_FAVORITES))
      return
    }

    // Handle other errors
    yield put(endRequestError(actionTypes.FETCH_MY_FAVORITES, response))
    return
  }

  yield put(
    updateEntities({
      [ENTITY_KEY_FAVORITES]: entitiesArrayToKeyedObject(response.data.data),
    }),
  )

  yield put(endRequestSuccess(actionTypes.FETCH_MY_FAVORITES))
}

function* addToFavoritesSaga({ payload: { resource } }) {
  yield put(startRequestWithId(actionTypes.ADD_TO_FAVORITES, resource.id))

  yield put(
    mergeEntities({ [ENTITY_KEY_FAVORITES]: { [resource.id]: resource } }),
  )

  const response = yield call(api.addToFavorites, resource.id)

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.ADD_TO_FAVORITES,
        resource.id,
        response,
      ),
    )
    return
  }

  yield put(endRequestWithIdSuccess(actionTypes.ADD_TO_FAVORITES, resource.id))
}

function* removeFavoriteSaga({ payload: { resourceId } }) {
  yield put(startRequestWithId(actionTypes.REMOVE_FAVORITE, resourceId))

  yield put(removeOne(ENTITY_KEY_FAVORITES, resourceId))

  const response = yield call(api.removeFavorite, resourceId)

  if (response.error) {
    yield put(
      endRequestWithIdError(actionTypes.REMOVE_FAVORITE, resourceId, response),
    )
    return
  }

  yield put(endRequestWithIdSuccess(actionTypes.REMOVE_FAVORITE, resourceId))
}

export default function*() {
  yield all([
    takeLatest(actionTypes.FETCH_MY_FAVORITES, fetchMyFavoritesSaga),
    takeEvery(actionTypes.ADD_TO_FAVORITES, addToFavoritesSaga),
    takeEvery(actionTypes.REMOVE_FAVORITE, removeFavoriteSaga),
  ])
}
