import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@redradix/components.checkbox'

const RegularCheckbox = ({
  checked,
  counterNumber,
  errorText,
  id,
  isDisabled,
  isFocused,
  isTouched,
  label,
  name,
  onBlur,
  onChange,
  onClick,
}) => {
  return (
    <Checkbox
      name={name}
      checked={checked}
      errorText={errorText}
      id={id}
      isFocused={isFocused}
      isTouched={isTouched}
      onBlur={onBlur}
      isDisabled={isDisabled}
      onChange={isDisabled ? () => {} : onChange}
      onClick={onClick}
    >
      <span className="text">{label}</span>
      {counterNumber && <span className="text">({counterNumber})</span>}
    </Checkbox>
  )
}

RegularCheckbox.propTypes = {
  label: PropTypes.string,
  counterNumber: PropTypes.string,
  /** Boolean indicating whether the checkbox is checked */
  name: PropTypes.string,
  /** String that defines the input error message */
  checked: PropTypes.bool.isRequired,
  /** Property indicating the value of the component */
  errorText: PropTypes.string,
  /** String that defines the input id */
  id: PropTypes.string,
  /** Boolean indicating whether the checkbox should be rendered as disabled */
  isDisabled: PropTypes.bool,
  /** Boolean indicating whether the checkbox is focused */
  isFocused: PropTypes.bool,
  /** Boolean indicating whether the checkbox has been touched (interacted with) by the user */
  isTouched: PropTypes.bool,
  /** String that defines the input name */
  onBlur: PropTypes.func,
  /** Function that is triggered when the input is changing */
  onChange: PropTypes.func,
  /** Function that is triggered when the input is clicked */
  onClick: PropTypes.func,
}

export default RegularCheckbox
