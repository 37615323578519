import {
  compose,
  branch,
  withProps,
  renderComponent,
  withHandlers,
} from 'recompose'
import * as R from 'ramda'
import Error500Message from '../ui/global-message/Error500Message'
import withRequestError from './withRequestError'
import { showSentryReportDialog } from '../services/sentry-error'

const OUT_PROP = 'withRouteServerError/requestError'

const withRouteServerError = requestType =>
  compose(
    withRequestError(requestType, OUT_PROP),
    branch(
      ownProps => ownProps[OUT_PROP],
      compose(
        branch(
          ownProps => ownProps[OUT_PROP].sentryData,
          withHandlers({
            onReport: ({ eventId, user }) => _ =>
              showSentryReportDialog(eventId, user),
          }),
        ),
        renderComponent(Error500Message),
      ),
    ),
    withProps(R.dissoc(OUT_PROP)),
  )

export default withRouteServerError
