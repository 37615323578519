import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import { globalMessagesPropType } from '../../../hocs/withFormErrors'
import ActionConfirmationModal from '../../modals/ActionConfirmationModal'
import { isNilOrEmpty } from '../../../app/utils'
import FormattedFormErrors from '../../FormattedFormErrors'

const DeleteCampaignModal = ({
  onSubmit,
  onCancel,
  globalMessages,
  isDeletingCampaign,
  i18n,
}) => {
  return (
    <ActionConfirmationModal
      title={i18n('message:delete-campaign')}
      mainActionText={i18n('action:delete')}
      mainAction={onSubmit}
      secondaryActionText={i18n('action:cancel')}
      secondaryAction={onCancel}
      hasError={!isNilOrEmpty(globalMessages)}
      errorText={<FormattedFormErrors errors={globalMessages} />}
      isButton2Loading={isDeletingCampaign}
    />
  )
}

DeleteCampaignModal.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  globalMessages: globalMessagesPropType,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(DeleteCampaignModal)
