import { useState, useCallback } from 'react'
import { usePagination } from '@redradix/components.pagination'

const useMovementsPagination = (movementsMeta, fetchWalletMovements) => {
  const [currentPage, setCurrentPage] = useState(1)

  const onPageChange = useCallback(
    page => {
      setCurrentPage(page)
      fetchWalletMovements(page)
    },
    [fetchWalletMovements],
  )

  const { onPageClick } = usePagination({
    page: currentPage,
    onPageChange,
    totalItems: movementsMeta.total,
    itemsPerPage: movementsMeta.limit,
  })

  return { currentPage, onPageClick }
}

export default useMovementsPagination
