import React from 'react'
import PropTypes from 'prop-types'
import Collapsed from '@redradix/components.collapsed'
import { ReactComponent as ChevronDown } from '../../styles/icons/chevron-down.svg'

const Dropdown = ({ buttonText, buttonExtra, children }) => {
  return (
    <div className="basic-dropdown">
      <Collapsed
        widthBehaviour="full"
        buttonIcon={<ChevronDown className="rdx-icon rdx-icon-down" />}
        buttonText={buttonText}
        buttonExtra={buttonExtra}
      >
        {children}
      </Collapsed>
    </div>
  )
}

Dropdown.propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
  buttonExtra: PropTypes.node,
}

export default Dropdown
