import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'

const AnchorLink = props => {
  const {
    isDisabled,
    linkUrl,
    text,
    small,
    isNative,
    isNewTab,
    download,
  } = props
  const classes = cx('anchor-link', {
    sm: small,
    'is-disabled': isDisabled,
  })

  return isNative ? (
    <a
      className={classes}
      href={linkUrl}
      title={text}
      download={download}
      target={isNewTab ? '_blank' : undefined}
      rel={isNewTab ? 'noopener noreferrer' : undefined}
    >
      {text}
    </a>
  ) : (
    <Link className={classes} to={linkUrl} title={text}>
      {text}
    </Link>
  )
}

AnchorLink.propTypes = {
  isDisabled: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.string,
  linkUrl: PropTypes.string,
  isNative: PropTypes.bool,
  isNewTab: PropTypes.bool,
}

export default AnchorLink
