import { getFromPath } from 'reduken/hash'
import {
  DOMAIN,
  HASH_KEY_CART,
  HASH_KEY_IS_CREATE_CAMPAIGN_MODAL_OPEN,
  HASH_KEY_IS_BILLING_DATA_MODAL_OPEN,
  HASH_KEY_DATES_CONFIG,
  HASH_KEY_COUPON,
} from './constants'

export const getCart = getFromPath(DOMAIN, HASH_KEY_CART)

export const getIsCreateCampaignModalOpen = getFromPath(
  DOMAIN,
  HASH_KEY_IS_CREATE_CAMPAIGN_MODAL_OPEN,
)

export const getIsBillingDataModalOpen = getFromPath(
  DOMAIN,
  HASH_KEY_IS_BILLING_DATA_MODAL_OPEN,
)

export const getDatesConfig = getFromPath(DOMAIN, HASH_KEY_DATES_CONFIG)

export const getAppliedCouponPaymentData = getFromPath(DOMAIN, HASH_KEY_COUPON)
