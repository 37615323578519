import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../avatar/Avatar'
import IconButton from './../buttons/IconButton.jsx'
import { ReactComponent as Delete } from '../../styles/icons/delete.svg'
import HackFormattedMoney from '../HackFormattedMoney'
import withTranslations from '../../hocs/withTranslations'
import {
  mapServiceTypeToOrderTypeI18n,
  mapServiceTypeToResourceType,
} from '../../services/orders/business'
import { SERVICE_TYPES } from '../../services/orders/business'

const CartItem = ({
  isDeleting,
  onDelete,
  photoURL,
  name,
  service,
  price,
  i18n,
}) => {
  return (
    <div className="cart-item">
      <div className="cart-item-info">
        <Avatar
          image={photoURL}
          avatarName={name}
          channel={mapServiceTypeToResourceType[service]}
        />
        <div className="cart-item-text">
          <p className="cart-item-name">{name}</p>
          <p className="cart-item-resource">
            {i18n(mapServiceTypeToOrderTypeI18n[service])}
          </p>
          <p className="cart-item-price">
            <HackFormattedMoney value={price} />
          </p>
        </div>
      </div>
      <IconButton type="button" isLoading={isDeleting} onClick={onDelete}>
        <Delete className="icon rdx-button-icon" />
      </IconButton>
    </div>
  )
}

CartItem.propTypes = {
  isDeleting: PropTypes.bool,
  onDelete: PropTypes.func,
  photoURL: PropTypes.string,
  name: PropTypes.string,
  service: PropTypes.oneOf(Object.values(SERVICE_TYPES)),
  price: PropTypes.number,
}

export default withTranslations(CartItem)
