import { BRIEFING_UPLOAD_CONDITIONS_I18N } from '../../services/checkout/business'
import { withPropsOnChange, compose } from 'recompose'
import withTranslations from '../../hocs/withTranslations'

const withConditionOptions = conditionOptions =>
  compose(
    withTranslations,

    withPropsOnChange(['i18n'], ({ i18n }) => ({
      conditionOptions: conditionOptions.map(value => ({
        label: i18n(BRIEFING_UPLOAD_CONDITIONS_I18N[value]),
        value,
        isDisabled: false,
      })),
    })),

    withPropsOnChange(['i18n', 'values'], ({ i18n, values }) => {
      const conditionId = values.conditionId

      if (!values.conditionId) {
        return {}
      }

      return {
        values: {
          ...values,
          conditionId: !!conditionId
            ? {
                label: i18n(BRIEFING_UPLOAD_CONDITIONS_I18N[conditionId]),
                value: conditionId,
                isDisabled: false,
              }
            : conditionId,
        },
      }
    }),
  )

export default withConditionOptions
