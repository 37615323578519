import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import { FormattedDate } from 'react-intl'
import Button from '@redradix/components.button'
import Chip from '@redradix/components.chip'
import CardSocialBroadcast from '../../cards/CardSocialBroadcast'
import Select from '../../form-elements/Select'
import CardLabel from '../../cards/CardLabel'
import HackFormattedMoney from '../../HackFormattedMoney'
import { ReactComponent as Cart } from '../../../styles/icons/cart.svg'
import { useResourceServices } from '../../../app/common/useResourceServices'
import resourceToViewProps from '../../../app/common/resourceToViewProps'

const ResourceDetailServiceWeb = ({
  i,
  service,
  item,
  i18n,
  inCartItems,
  addItem,
  resourceStatus,
  updateResourceStatus,
  currency,
}) => {
  const isCartLoading = false
  const {
    services,
    actualSelectedService,
    handleCartClickService,
    handleServiceChange,
    isBroadcastChecked,
    handleBroadcastChange,
  } = useResourceServices({
    item,
    addItem,
    i18n,
    service,
    resourceStatus,
    updateResourceStatus,
  })

  const {
    price,
    finalPrice,
    holidaysEndDate,
    acceptsBroadcast,
    broadcastSocialNetworks,
    isInCart,
  } = resourceToViewProps(
    item,
    isBroadcastChecked,
    actualSelectedService,
    inCartItems,
    currency,
  )

  return (
    <div className="resource-detail-service-list-item" key={i}>
      <div className="resource-detail-service-list-item-header">
        <h4 className="resource-detail-service-list-item-title">
          {service.title}
        </h4>
      </div>
      <div className="resource-detail-service-list-item-description">
        {service.description}
      </div>
      <div className="result-card-actions">
        <div className="result-card-action">
          {holidaysEndDate ? (
            <p className="card-holidays-indicator">
              <span>{i18n('noun:vacations-until')} </span>
              <span>
                <FormattedDate format="coobis" value={holidaysEndDate} />
              </span>
            </p>
          ) : (
            <>
              {acceptsBroadcast && !holidaysEndDate ? (
                <CardSocialBroadcast
                  socialNetworks={broadcastSocialNetworks}
                  checked={isBroadcastChecked}
                  onChange={handleBroadcastChange}
                />
              ) : null}
              <div>
                {services.length > 1 && (
                  <Select
                    options={services}
                    value={actualSelectedService}
                    isDisabled={false}
                    onChange={handleServiceChange}
                  />
                )}
                {finalPrice < price ? (
                  <CardLabel
                    type="saledetail"
                    price={price}
                    finalPrice={finalPrice}
                  />
                ) : (
                  <Chip className="chip-basic">
                    <HackFormattedMoney value={finalPrice} />
                  </Chip>
                )}

                <Button
                  isDisabled={isInCart}
                  isLoading={isCartLoading}
                  onClick={handleCartClickService}
                >
                  <Cart className="icon rdx-button-icon" />
                  <span>{i18n(isInCart ? 'action:added' : 'action:add')}</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ResourceDetailServiceWeb.propTypes = {
  item: PropTypes.object,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceDetailServiceWeb)
