import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import * as R from 'ramda'
import { BRIEFING_WEB_GOALS } from '../../services/checkout/business'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import TabsTitle from '../texts/TabsTitle'
import FormField from '../form-elements/FormField'
import Tooltip from '@redradix/components.tooltip'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import InputFile from '../form-elements/InputFile'
import Dropdown from '../dropdown/Dropdown'
import Input from '../form-elements/Input'
import FormFieldRow from '../form-elements/FormFieldRow'
import Radio from '@redradix/components.radio'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import AnchorLinkButton from '../texts/AnchorLinkButton'

const ALLOWED_DOCUMENT_EXTENSIONS = '.pdf,.doc,.docx,.txt'

const BriefingWebOnlyPostForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,

  title,
  onAddLink,
  downloadFileLink,
}) => {
  return (
    <form className="briefing-form" onSubmit={handleSubmit}>
      <div className="tabs-header">
        <TabsTitle text={title} />
      </div>
      <FormField
        label={
          <>
            {i18n('checkout:campaign-goal')}
            <Tooltip
              position="bottom"
              widthFixed
              renderTooltip={() => (
                <TooltipRegularText
                  text={
                    <FormattedMessage
                      id="checkout:campaign-goal-long"
                      defaultMessage="checkout:campaign-goal-long"
                      values={{
                        branding: <strong>{i18n('noun:branding')}</strong>,
                        seo: <strong>{i18n('noun:seo')}</strong>,
                        br: <br />,
                      }}
                    />
                  }
                />
              )}
              renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
                <TooltipTrigger
                  classNames={triggerClasses}
                  onMouseOver={open}
                  onMouseOut={close}
                  ref={triggerRef}
                />
              )}
            />
          </>
        }
        isTouched={touched.goal}
        errorText={
          validationMessages.goal && (
            <FormattedFormErrors errors={validationMessages.goal} />
          )
        }
      >
        <Radio
          name="goal"
          checked={values.goal === BRIEFING_WEB_GOALS.BRANDING}
          value={BRIEFING_WEB_GOALS.BRANDING}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isRequestPending}
          isTouched={touched.goal}
        >
          <span className="text">{i18n('noun:branding')}</span>
        </Radio>
        <Radio
          name="goal"
          checked={values.goal === BRIEFING_WEB_GOALS.SEO}
          value={BRIEFING_WEB_GOALS.SEO}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isRequestPending}
          isTouched={touched.goal}
        >
          <span className="text">{i18n('noun:seo')}</span>
        </Radio>
      </FormField>
      <InputFile
        label={i18n('checkout:text-docs')}
        name="file"
        widthBehaviour="full"
        id="file"
        accept={ALLOWED_DOCUMENT_EXTENSIONS}
        linkText={i18n('action:download-file')}
        linkUrl={downloadFileLink}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('action:upload-document')}
        fileName={R.path(['file', 'name'], values)}
        isUploaded={R.path(['file', 'isUploaded'], values)}
        isTouched={touched.file}
        isDisabled={isRequestPending}
        isRequired
        errorText={
          validationMessages.file && (
            <FormattedFormErrors errors={validationMessages.file} />
          )
        }
      />

      <Dropdown buttonText={i18n('noun:links')}>
        {values.links.map((link, i) => {
          return (
            <Fragment key={i}>
              <FormFieldRow>
                <Input
                  type="text"
                  id="linksUrl"
                  name={`links[${i}].url`}
                  label={i18n('checkout:link-url')}
                  placeholder={i18n('checkout:link-url')}
                  widthBehaviour="full"
                  value={
                    !!values.links && !!values.links[i]
                      ? values.links[i].url
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isTouched={
                    !!touched.links && !!touched.links[i]
                      ? touched.links[i].url
                      : undefined
                  }
                  isDisabled={isRequestPending}
                  errorText={
                    !!validationMessages.links &&
                    !!validationMessages.links[i] &&
                    !!validationMessages.links[i].url ? (
                      <FormattedFormErrors
                        errors={validationMessages.links[i].url}
                      />
                    ) : (
                      undefined
                    )
                  }
                />
                <Input
                  type="text"
                  id="linkText"
                  name={`links[${i}].text`}
                  label={i18n('checkout:link-text')}
                  placeholder={i18n('checkout:link-text')}
                  widthBehaviour="full"
                  value={
                    !!values.links && !!values.links[i]
                      ? values.links[i].text
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isTouched={
                    !!touched.links && !!touched.links[i]
                      ? touched.links[i].text
                      : undefined
                  }
                  isDisabled={isRequestPending}
                  errorText={
                    !!validationMessages.links &&
                    !!validationMessages.links[i] &&
                    !!validationMessages.links[i].text ? (
                      <FormattedFormErrors
                        errors={validationMessages.links[i].text}
                      />
                    ) : (
                      undefined
                    )
                  }
                />
              </FormFieldRow>
              <FormField
                label={i18n('checkout:link-type')}
                isTouched={
                  !!touched.links && !!touched.links[i]
                    ? touched.links[i].isFollow
                    : undefined
                }
                errorText={
                  !!validationMessages.links &&
                  !!validationMessages.links[i] &&
                  !!validationMessages.links[i].isFollow ? (
                    <FormattedFormErrors
                      errors={validationMessages.links[i].isFollow}
                    />
                  ) : (
                    undefined
                  )
                }
              >
                <Radio
                  name={`links[${i}].isFollow`}
                  value={true}
                  checked={
                    // Branding goal forces links to no-follow
                    values.goal !== BRIEFING_WEB_GOALS.BRANDING &&
                    (!!values.links && !!values.links[i]
                      ? values.links[i].isFollow
                      : true)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isDisabled={
                    // Branding goal forces links to no-follow
                    values.goal === BRIEFING_WEB_GOALS.BRANDING ||
                    isRequestPending
                  }
                  isTouched={
                    !!touched.links && !!touched.links[i]
                      ? touched.links[i].isFollow
                      : undefined
                  }
                >
                  <span className="text">{i18n('noun:do-follow')}</span>
                </Radio>
                <Radio
                  name={`links[${i}].isFollow`}
                  value={false}
                  checked={
                    // Branding goal forces links to no-follow
                    values.goal === BRIEFING_WEB_GOALS.BRANDING ||
                    (!!values.links && !!values.links[i]
                      ? !values.links[i].isFollow
                      : false)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isTouched={
                    !!touched.links && !!touched.links[i]
                      ? touched.links[i].isFollow
                      : undefined
                  }
                  isDisabled={
                    // Branding goal forces links to no-follow
                    values.goal === BRIEFING_WEB_GOALS.BRANDING ||
                    isRequestPending
                  }
                >
                  <span className="text">{i18n('noun:no-follow')}</span>
                </Radio>
              </FormField>
            </Fragment>
          )
        })}

        <div className="right-align">
          <AnchorLinkButton
            onClick={onAddLink}
            type="button"
            small
            text={i18n('action:add-another-link')}
          />
        </div>
      </Dropdown>
      <Dropdown
        buttonText={i18n('checkout:html-labels')}
        buttonExtra={
          <Tooltip
            position="bottom"
            widthFixed
            renderTooltip={() => (
              <TooltipRegularText text={i18n('checkout:add-suggestions')} />
            )}
            renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
              <TooltipTrigger
                classNames={triggerClasses}
                onMouseOver={open}
                onMouseOut={close}
                ref={triggerRef}
              />
            )}
          />
        }
      >
        <Input
          type="text"
          id="html.titleTag"
          name="html.titleTag"
          label={i18n('checkout:label-title')}
          placeholder={i18n('checkout:label-title-long')}
          widthBehaviour="full"
          value={values.html.titleTag}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={R.path(['html', 'titleTag'], touched)}
          isDisabled={isRequestPending}
          errorText={
            R.path(['html', 'titleTag'], validationMessages) && (
              <FormattedFormErrors
                errors={R.path(['html', 'titleTag'], validationMessages)}
              />
            )
          }
        />
        <Input
          type="text"
          id="html.descriptionTag"
          name="html.descriptionTag"
          label={i18n('checkout:label-description')}
          placeholder={i18n('checkout:label-description-long')}
          widthBehaviour="full"
          value={values.html.descriptionTag}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={R.path(['html', 'descriptionTag'], touched)}
          isDisabled={isRequestPending}
          errorText={
            R.path(['html', 'descriptionTag'], validationMessages) && (
              <FormattedFormErrors
                errors={R.path(['html', 'descriptionTag'], validationMessages)}
              />
            )
          }
        />
        <Input
          type="text"
          id="html.slug"
          name="html.slug"
          label={i18n('checkout:url-page-to-publish')}
          placeholder={i18n('checkout:url-page-to-publish-long')}
          widthBehaviour="full"
          value={values.html.slug}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={R.path(['html', 'slug'], touched)}
          isDisabled={isRequestPending}
          errorText={
            R.path(['html', 'slug'], validationMessages) && (
              <FormattedFormErrors
                errors={R.path(['html', 'slug'], validationMessages)}
              />
            )
          }
        />
      </Dropdown>

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const linkPropType = PropTypes.shape({
  url: PropTypes.string,
  text: PropTypes.string,
  isFollow: PropTypes.bool,
})

const FORM_VALUES_PROPTYPES = {
  goal: PropTypes.oneOf(Object.values(BRIEFING_WEB_GOALS)),
  file: PropTypes.any,
  links: PropTypes.arrayOf(linkPropType),
  html: PropTypes.shape({
    titleTag: PropTypes.string,
    descriptionTag: PropTypes.string,
    slug: PropTypes.string,
  }),
}

BriefingWebOnlyPostForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onAddLink: PropTypes.func.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(BriefingWebOnlyPostForm)
