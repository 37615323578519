import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import * as R from 'ramda'
import TiktokServicesPrices from '../../ui/social-resource/TiktokServicesPrices'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation } from '../common/validation'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withFormErrors from '../../hocs/withFormErrors'
import { editSocialResourceServicesPrices } from '../../services/resources'
import { EDIT_SOCIAL_RESOURCE_SERVICES_PRICES } from '../../services/resources/action-types'
import { floatToMoney } from '../utils'
import { SERVICE_TYPES } from '../../services/orders/business'
import { calculateTotalPrice } from './util'

const validationSchema = Yup.object().shape({
  shortEnabled: Yup.boolean().required(i18nValidation.required),
  shortBasePrice: Yup.number().when('shortEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min),
  }),
  shortDiscount: Yup.number().when('shortEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min)
      .max(100, i18nValidation.max),
  }),

  longEnabled: Yup.boolean().required(i18nValidation.required),
  longBasePrice: Yup.number().when('longEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min),
  }),
  longDiscount: Yup.number().when('longEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min)
      .max(100, i18nValidation.max),
  }),
})

const hasMentionTotalPriceError = ({ shortTotalPrice = 0 }, touched) =>
  shortTotalPrice < 10 && (touched.shortBasePrice || touched.shortDiscount)

const hasDedicatedTotalPriceError = ({ longTotalPrice = 0 }, touched) =>
  longTotalPrice < 10 && (touched.longBasePrice || touched.longDiscount)

const calculatePrices = values => ({
  shortTotalPrice: calculateTotalPrice(
    values.shortBasePrice,
    values.shortDiscount,
  ),
  longTotalPrice: calculateTotalPrice(
    values.longBasePrice,
    values.longDiscount,
  ),
})

const hasCalculatedPricesErrors = values => {
  const calculatedValues = calculatePrices(values)
  const { shortEnabled, longEnabled } = values

  const touched = {
    shortBasePrice: true,
    shortDiscount: true,
    longBasePrice: true,
    longDiscount: true,
  }

  return (
    (shortEnabled && hasMentionTotalPriceError(calculatedValues, touched)) ||
    (longEnabled && hasDedicatedTotalPriceError(calculatedValues, touched))
  )
}

const enhance = compose(
  connect(
    null,
    {
      editSocialResourceServicesPrices,
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ resourceData }) => ({
      shortBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.TIKTOK_SHORT, 'basePrice'],
        resourceData,
      ),
      shortDiscount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.TIKTOK_SHORT, 'discount'],
        resourceData,
      ),
      shortEnabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.TIKTOK_SHORT, 'enabled'],
            resourceData,
          ),
      shortCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.TIKTOK_SHORT, 'currency'],
        resourceData,
      ),
      longBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.TIKTOK_LONG, 'basePrice'],
        resourceData,
      ),
      longDiscount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.TIKTOK_LONG, 'discount'],
        resourceData,
      ),
      longEnabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.TIKTOK_LONG, 'enabled'],
            resourceData,
          ),
      longCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.TIKTOK_LONG, 'currency'],
        resourceData,
      ),
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      if (hasCalculatedPricesErrors(values)) {
        return
      }

      const { shortEnabled, longEnabled } = values

      const valuesToSend = [
        shortEnabled
          ? {
              serviceType: SERVICE_TYPES.TIKTOK_SHORT,
              enabled: true,
              basePrice: floatToMoney(
                values.shortBasePrice,
                values.shortCurrency,
              ),
              discount: values.shortDiscount,
            }
          : {
              serviceType: SERVICE_TYPES.TIKTOK_SHORT,
              enabled: false,
            },
        longEnabled
          ? {
              serviceType: SERVICE_TYPES.TIKTOK_LONG,
              enabled: true,
              basePrice: floatToMoney(
                values.longBasePrice,
                values.longCurrency,
              ),
              discount: values.longDiscount,
            }
          : {
              serviceType: SERVICE_TYPES.TIKTOK_LONG,
              enabled: false,
            },
      ]

      props.editSocialResourceServicesPrices(
        props.resourceId,
        props.channel,
        valuesToSend,
      )
    },
  }),

  withIsRequestPending(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withIsRequestSucceeded(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withRequestError(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withDeleteRequestOnUnmount(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),

  withFormErrors([
    'shortBasePrice',
    'shortDiscount',
    'shortEnabled',
    'longBasePrice',
    'longDiscount',
    'longEnabled',
  ]),

  // Change final prices when changes offer
  withPropsOnChange(['values'], ({ values }) => ({
    calculatedValues: calculatePrices(values),
  })),

  // Inject calculatedPrices errors
  withPropsOnChange(
    ['calculatedValues', 'validationMessages', 'touched', 'values'],
    ({ calculatedValues, validationMessages, touched, values }) => {
      const { shortEnabled, longEnabled } = values

      const showMentionTotalPriceError = hasMentionTotalPriceError(
        calculatedValues,
        touched,
      )

      const showDedicatedTotalPriceError = hasDedicatedTotalPriceError(
        calculatedValues,
        touched,
      )

      return {
        validationMessages: {
          ...validationMessages,
          shortTotalPrice:
            showMentionTotalPriceError && shortEnabled
              ? [i18nValidation.min({ path: 'shortTotalPrice', min: 10 })]
              : undefined,
          longTotalPrice:
            showDedicatedTotalPriceError && longEnabled
              ? [i18nValidation.min({ path: 'longTotalPrice', min: 10 })]
              : undefined,
        },
      }
    },
  ),
)

export default enhance(TiktokServicesPrices)
