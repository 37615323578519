import { connect } from 'react-redux'
import { hasToast, getToast, closeToast } from './'

export const withToast = connect(
  state => ({
    hasToast: hasToast(state),
    toast: getToast(state),
  }),
  {
    closeToast,
  },
)
