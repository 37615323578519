import React from 'react'
import PropTypes from 'prop-types'
import { safelyRender } from '../../../src/app/utils'
import { ReactComponent as IconBadgeDefault } from '../../styles/icons/fill-checkmark.svg'

const CardBadge = ({ renderIcon }) => {
  return (
    <span className="card-badge">
      <span className="card-badge-image">
        {safelyRender(renderIcon, { className: 'icon' }) || (
          <IconBadgeDefault className="icon" />
        )}
      </span>
    </span>
  )
}

CardBadge.propTypes = {
  renderIcon: PropTypes.func,
}

export default CardBadge
