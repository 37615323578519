import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import { FormattedDate } from 'react-intl'
import HackFormattedMoney from '../HackFormattedMoney'
import AnchorButton from '../buttons/AnchorButton'
import { ReactComponent as Download2 } from './../../styles/icons/download-2.svg'
import { isNilOrEmpty } from '../../app/utils'

const ProfileMovementsRow = ({
  date,
  concept,
  resource,
  amount,
  balance,
  downloadUrl,
  isDownloadDisabled,
  onDownload,
  i18n,
}) => {
  return (
    <div className="profile-movements-row">
      <div className="profile-movements-row-item profile-movements-row-date">
        <FormattedDate format="coobis" value={date} />
      </div>
      <div className="profile-movements-row-item profile-movements-row-concept">
        {concept}
      </div>
      <div className="profile-movements-row-item profile-movements-row-resource">
        {resource}
      </div>
      <div className="profile-movements-row-item profile-movements-row-amount">
        <HackFormattedMoney
          value={amount}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </div>
      <div className="profile-movements-row-item profile-movements-row-balance">
        <HackFormattedMoney
          value={balance}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </div>
      <div className="profile-movements-row-item profile-movements-row-action">
        {!isNilOrEmpty(downloadUrl) && (
          <AnchorButton
            hierarchy="secondary"
            linkUrl={downloadUrl}
            isIconButton
            isDisabled={isDownloadDisabled}
            isNative
            download
          >
            <span>{i18n('noun:invoice')}</span>
            <Download2 className="icon rdx-button-icon" />
          </AnchorButton>
        )}
      </div>
    </div>
  )
}

ProfileMovementsRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  concept: PropTypes.string,
  resource: PropTypes.string,
  amount: CoobisPropTypes.money.isRequired,
  balance: CoobisPropTypes.money,
  downloadUrl: PropTypes.string,
  isDownloadDisabled: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ProfileMovementsRow)
