import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import ChipBasic from './../chips/ChipBasic'

const OrderDetailAdvices = ({ items, i18n }) => {
  return (
    <OrderDetailSection title={i18n('sections:tips')}>
      {items.map((item, i) => {
        const tipNumber = `${i + 1}`
        return (
          <ChipBasic
            key={i}
            title={i18n('order-detail:tip-number', { tipNumber })}
            text={item}
          />
        )
      })}
    </OrderDetailSection>
  )
}

OrderDetailAdvices.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailAdvices)
