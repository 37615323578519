import React from 'react'
import withTranslations from '../../hocs/withTranslations'
import imageBig from './images/emptyResultBig.png'
import imageSmall from './images/emptyResultSmall.png'

const EmptyResult = ({ i18n }) => (
  <div className="empty-result">
    <picture className="empty-result-image">
      <source media="(max-width: 799px)" srcSet={imageSmall} />
      <source media="(min-width: 800px)" srcSet={imageBig} />
      <img src={imageBig} alt="Decoration" />
    </picture>
    <div className="empty-result-contents">
      <p className="empty-result-title">{i18n('message:no-results-title')}</p>
      <p className="empty-result-description">
        {i18n('message:no-results-subtitle')}
      </p>
    </div>
  </div>
)

export default withTranslations(EmptyResult)
