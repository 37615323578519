import { lifecycle } from 'recompose'
import { arrayItemsEqual } from '../app/utils'

const withEffect = (effectFromProps, dependenciesFromProps) =>
  lifecycle({
    componentDidMount() {
      this.cleanEffect = effectFromProps(this.props)
    },

    componentDidUpdate(prevProps, _) {
      if (
        !arrayItemsEqual(
          dependenciesFromProps(prevProps),
          dependenciesFromProps(this.props),
        )
      ) {
        if (this.cleanEffect) {
          this.cleanEffect()
        }

        this.cleanEffect = effectFromProps(this.props)
      }
    },

    componentWillUnmount() {
      if (this.cleanEffect) {
        this.cleanEffect()
      }
    },
  })

export default withEffect
