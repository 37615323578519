import { compose, withPropsOnChange, withStateHandlers } from 'recompose'
import * as R from 'ramda'
import { memoize1NAry } from '../utils'

const MAX_CITIES = 15

const withCityWidget = ({ dataPath, perPage = 4 }) =>
  compose(
    withPropsOnChange(
      (props, nextProps) =>
        !R.equals(
          R.pathOr([], dataPath, nextProps),
          R.pathOr([], dataPath, props),
        ),
      props => {
        const cityDemographics = R.path(dataPath, props) || []
        const cityWidgetData = getCityWidgetData(cityDemographics)
        return { cityWidgetData, cityWidgetTotal: cityWidgetData.length }
      },
    ),
    withStateHandlers(() => ({ cityWidgetPage: 0 }), {
      onPrevCityHandler: ({ cityWidgetPage }) => _ => ({
        cityWidgetPage: Math.max(cityWidgetPage - 1, 0),
      }),
      onNextCityHandler: ({ cityWidgetPage }) => _ => ({
        cityWidgetPage: Math.min(cityWidgetPage + 1, MAX_CITIES / perPage - 1),
      }),
    }),
    withPropsOnChange(
      ['cityWidgetData', 'cityWidgetPage'],
      ({ cityWidgetData, cityWidgetPage }) => {
        const lastInCurrentPage = (cityWidgetPage + 1) * perPage
        const currentPageData = cityWidgetData.slice(
          cityWidgetPage * perPage,
          lastInCurrentPage,
        )
        return {
          cityWidgetData: currentPageData,
          isPrevCityDisabled: cityWidgetPage === 0,
          isNextCityDisabled: lastInCurrentPage >= cityWidgetData.length,
        }
      },
    ),
  )

const getCityWidgetData = memoize1NAry(cityDemographics => {
  return R.pipe(
    R.reject(R.propEq('city', '(not set)')),
    R.map(({ country, city, density, ratio }) => ({
      id: `${country}/${city}`,
      label: city,
      value: density || ratio,
    })),
    R.sort(R.descend(R.prop('value'))),
  )(cityDemographics)
})

export default withCityWidget
