import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import { MediaQueryLap, MediaQueryPalm } from '../shared/MediaQueries'
import Slider from 'react-slick'
import DashboardPanel from './DashboardPanel'
import CardPlaceholder from '../cards/CardPlaceholder'
import Spinner from '../loaders/Spinner'

const RecommendationsPanel = ({
  resources,
  isLoading,
  renderRecommendedCard,
  i18n,
}) => {
  const SLIDER_SETTINGS = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <DashboardPanel title={i18n('noun:recommended')} size="auto">
      {isLoading ? (
        <div className="loading-dashboard-section-placeholder">
          <CardPlaceholder>
            <Spinner size={60} />
          </CardPlaceholder>
        </div>
      ) : (
        <div className="recommendations-panel-content">
          <MediaQueryPalm>
            {
              <Slider {...SLIDER_SETTINGS}>
                {resources.map(renderRecommendedCard)}
              </Slider>
            }
          </MediaQueryPalm>
          <MediaQueryLap>{resources.map(renderRecommendedCard)}</MediaQueryLap>
        </div>
      )}
    </DashboardPanel>
  )
}

RecommendationsPanel.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      isNew: PropTypes.bool,
      name: PropTypes.string,
      category: PropTypes.string,
      image: PropTypes.string,
      type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
      followers: PropTypes.number,
      engagements: PropTypes.number,
      engagementRate: PropTypes.number,
      price: PropTypes.number,
      finalPrice: PropTypes.number,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          isDisabled: PropTypes.bool,
        }),
      ),
      selectedService: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      acceptsBroadcast: PropTypes.bool,
      broadcastSocialNetworks: PropTypes.arrayOf(
        PropTypes.oneOf([RESOURCE_TYPES.FACEBOOK, RESOURCE_TYPES.TWITTER]),
      ),
      isUserVerified: PropTypes.bool,
      isFavourited: PropTypes.bool,
      holidaysEndDate: PropTypes.object,
    }),
  ),
  isLoading: PropTypes.bool,
  renderRecommendedCard: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(RecommendationsPanel)
