import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import withTranslations from '../../hocs/withTranslations'
import Panel from '../panels/Panel'
import InputSuffix from '../form-elements/InputSuffix'
import WarningMessage from '../messages/WarningMessage'
import Checkbox from '@redradix/components.checkbox'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import Tooltip from '@redradix/components.tooltip'
import { MediaQueryPortable, MediaQueryDesk } from '../shared/MediaQueries'
import FormattedCurrency from '../FormattedCurrency'

const getTooltipWithOptions = (options, i18n) => {
  return (
    <Tooltip
      position={options.position}
      widthFixed={options.widthFixed}
      renderTooltip={() => (
        <>
          <TooltipRegularText text={i18n('web-resources:prices-tooltip')} />
        </>
      )}
      renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
        <TooltipTrigger
          classNames={triggerClasses}
          onMouseOver={open}
          onMouseOut={close}
          ref={triggerRef}
        />
      )}
    />
  )
}

const WebResourcesServicesPrices = ({
  handleChange,
  handleBlur,
  touched,
  values,
  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  handleSubmit,

  acceptsBroadcasts,
  isPricesVipResource,
  calculatedValues,
}) => {
  const touchedAndWithErrors = useCallback(
    fieldName =>
      touched[fieldName] &&
      validationMessages[fieldName] &&
      Array.isArray(validationMessages[fieldName]),
    [touched, validationMessages],
  )

  return (
    <form className="basic-form" onSubmit={handleSubmit}>
      <div className="indented-block">
        <Checkbox
          name="enabled"
          checked={values.enabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.enabled}
          isDisabled={isRequestPending}
        >
          <p className="title">
            {i18n('web-resources:writing-and-publishing-post')}
          </p>
          <p className="subtitle">
            {i18n('web-resources:writing-and-publishing-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="basePrice"
              name="basePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.basePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.basePrice}
              isDisabled={
                isRequestPending || !isPricesVipResource || !values.enabled
              }
              suffix={<FormattedCurrency value={values.currency} />}
            />
            <InputSuffix
              type="number"
              id="discount"
              name="discount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              onChange={handleChange}
              value={values.discount}
              onBlur={handleBlur}
              isTouched={touched.discount}
              isDisabled={isRequestPending || !values.enabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="totalPrice"
              name="totalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.totalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.currency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('basePrice') ||
          touchedAndWithErrors('discount') ||
          validationMessages.totalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.basePrice || []),
                  ...(validationMessages.discount || []),
                  ...(validationMessages.totalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>

      {isPricesVipResource && acceptsBroadcasts && (
        <div className="indented-block">
          <div className="indented-block-header">
            <p className="subtitle">
              {i18n('web-resources:writing-and-publishing-post-price')}
            </p>
          </div>
          <Panel dark>
            <div className="inline-digits">
              <InputSuffix
                type="number"
                id="broadcastBasePrice"
                name="broadcastBasePrice"
                label={i18n('label:base-price')}
                placeholder="30"
                widthBehaviour="full"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.broadcastBasePrice}
                isTouched={touched.broadcastBasePrice}
                isDisabled={
                  isRequestPending || !isPricesVipResource || !values.enabled
                }
                suffix={<FormattedCurrency value={values.broadcastCurrency} />}
              />
              <InputSuffix
                type="number"
                id="broadcastDiscount"
                name="broadcastDiscount"
                label={i18n('label:offer')}
                placeholder="15"
                widthBehaviour="full"
                value={values.discount}
                isTouched={touched.discount}
                isDisabled={true}
                suffix="%"
              />
              <InputSuffix
                type="number"
                id="broadcastTotalPrice"
                name="broadcastTotalPrice"
                label={i18n('label:total-price')}
                placeholder="30"
                widthBehaviour="full"
                value={calculatedValues.broadcastTotalPrice}
                isDisabled={true}
                suffix={<FormattedCurrency value={values.broadcastCurrency} />}
              />
              <MediaQueryPortable>
                {getTooltipWithOptions(
                  { position: 'bottom', widthFixed: false },
                  i18n,
                )}
              </MediaQueryPortable>
              <MediaQueryDesk>
                {getTooltipWithOptions(
                  { position: 'right', widthFixed: true },
                  i18n,
                )}
              </MediaQueryDesk>
            </div>
          </Panel>

          {(touchedAndWithErrors('broadcastBasePrice') ||
            validationMessages.broadcastTotalPrice) && (
            <WarningMessage
              text={
                <FormattedFormErrors
                  errors={[
                    ...(validationMessages.broadcastBasePrice || []),
                    ...(validationMessages.broadcastTotalPrice || []),
                  ]}
                />
              }
            />
          )}
        </div>
      )}

      <div className="indented-block">
        <Checkbox
          name="postEnabled"
          checked={values.postEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.postEnabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('web-resources:publishing-post')}</p>
          <p className="subtitle">
            {i18n('web-resources:publishing-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="postBasePrice"
              name="postBasePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.postBasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.postBasePrice}
              isDisabled={
                isRequestPending || !isPricesVipResource || !values.postEnabled
              }
              suffix={<FormattedCurrency value={values.postCurrency} />}
            />
            <InputSuffix
              type="number"
              id="postDiscount"
              name="postDiscount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              onChange={handleChange}
              value={values.postDiscount}
              onBlur={handleBlur}
              isTouched={touched.postDiscount}
              isDisabled={isRequestPending || !values.postEnabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="postTotalPrice"
              name="postTotalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.postTotalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.postCurrency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('postBasePrice') ||
          touchedAndWithErrors('postDiscount') ||
          validationMessages.postTotalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.postBasePrice || []),
                  ...(validationMessages.postDiscount || []),
                  ...(validationMessages.postTotalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>

      {isPricesVipResource && acceptsBroadcasts && (
        <div className="indented-block">
          <div className="indented-block-header">
            <p className="subtitle">
              {i18n('web-resources:publishing-post-price')}
            </p>
          </div>
          <Panel dark>
            <div className="inline-digits">
              <InputSuffix
                type="number"
                id="postBroadcastBasePrice"
                name="postBroadcastBasePrice"
                label={i18n('label:base-price')}
                placeholder="30"
                widthBehaviour="full"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.postBroadcastBasePrice}
                isTouched={touched.postBroadcastBasePrice}
                isDisabled={
                  isRequestPending ||
                  !isPricesVipResource ||
                  !values.postEnabled
                }
                suffix={
                  <FormattedCurrency value={values.postBroadcastCurrency} />
                }
              />
              <InputSuffix
                type="number"
                id="postBroadcastDiscount"
                name="postBroadcastDiscount"
                label={i18n('label:offer')}
                placeholder="15"
                widthBehaviour="full"
                value={values.postDiscount}
                isTouched={touched.postDiscount}
                isDisabled={true}
                suffix="%"
              />
              <InputSuffix
                type="number"
                id="postBroadcastTotalPrice"
                name="postBroadcastTotalPrice"
                label={i18n('label:total-price')}
                placeholder="30"
                widthBehaviour="full"
                value={calculatedValues.postBroadcastTotalPrice}
                isDisabled={true}
                suffix={
                  <FormattedCurrency value={values.postBroadcastCurrency} />
                }
              />
              <MediaQueryPortable>
                {getTooltipWithOptions(
                  { position: 'bottom', widthFixed: false },
                  i18n,
                )}
              </MediaQueryPortable>
              <MediaQueryDesk>
                {getTooltipWithOptions(
                  { position: 'right', widthFixed: true },
                  i18n,
                )}
              </MediaQueryDesk>
            </div>
          </Panel>

          {(touchedAndWithErrors('postBroadcastBasePrice') ||
            validationMessages.postBroadcastTotalPrice) && (
            <WarningMessage
              text={
                <FormattedFormErrors
                  errors={[
                    ...(validationMessages.postBroadcastBasePrice || []),
                    ...(validationMessages.postBroadcastTotalPrice || []),
                  ]}
                />
              }
            />
          )}
        </div>
      )}

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  basePrice: PropTypes.number,
  postBasePrice: PropTypes.number,
  discount: PropTypes.number,
  postDiscount: PropTypes.number,
  broadcastBasePrice: PropTypes.number,
  postBroadcastBasePrice: PropTypes.number,
}

WebResourcesServicesPrices.propTypes = {
  calculatedValues: PropTypes.shape({
    totalPrice: PropTypes.number,
    postTotalPrice: PropTypes.number,
    broadcastTotalPrice: PropTypes.number,
    postBroadcastTotalPrice: PropTypes.number,
  }),
  isPricesVipResource: PropTypes.bool,
  isRequestPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(WebResourcesServicesPrices)
