import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import * as R from 'ramda'
import { isNilOrEmpty, splitByKeys } from '../app/utils'
import { i18nErrorKeys } from '../app/common/validation'

const splitValidationMessages = R.curry((fields, requestError) => {
  if (!requestError || !requestError.validationMessages) {
    return {
      validationMessages: {},
      hasUnknownValidationMessages: false,
    }
  }

  const [knownMessages, unknownMessages] = splitByKeys(
    fields,
    R.filter(R.complement(R.isNil))(requestError.validationMessages),
  )

  !isNilOrEmpty(unknownMessages) && console.warn({ unknownMessages })

  return {
    validationMessages: knownMessages,
    hasUnknownValidationMessages: !isNilOrEmpty(unknownMessages),
  }
})

const withFormErrors = (
  fieldsMaybeFn,
  {
    // Input
    serverErrorsProp = 'requestError',
    formikErrorsProp = 'errors',
    formikTouchedProp = 'touched',
    formikTouchedSinceSubmitProp = 'touchedSinceSubmit',
    formikValidateOnSubmit = false,

    // Output
    validationMessagesProp = 'validationMessages',
    globalMessagesProp = 'globalMessages',
  } = {},
) =>
  withProps(props => {
    const fields =
      typeof fieldsMaybeFn === 'function' ? fieldsMaybeFn(props) : fieldsMaybeFn

    const requestError = props[serverErrorsProp]
    const formikErrors = props[formikErrorsProp]
    const formikTouched = props[formikTouchedProp]
    const formikTouchedSinceSubmit = props[formikTouchedSinceSubmitProp]

    // Detect unhandled fields in server errors
    const {
      validationMessages: serverValidationMessages,
      hasUnknownValidationMessages: hasUnknownServerValidationMessages,
    } = splitValidationMessages(fields, requestError)

    // Only show server validation messages if not modified since last submit
    const untouchedServerValidationMessages = R.pipe(
      R.mapObjIndexed((error, field) =>
        formikTouchedSinceSubmit[field] ? undefined : error,
      ),
      R.reject(R.isNil),
    )(serverValidationMessages)

    // Avoid i18nError objects ({key: 'errors:something', value: {}})
    const i18nObjectShape = R.where({
      key: R.is(String),
      values: R.is(Object),
    })

    // For nested objects and arrays with objects inside
    const handleNestedObjects = item =>
      R.ifElse(
        R.allPass([R.is(Object), R.complement(i18nObjectShape)]),
        R.map(handleNestedObjects),
        R.of,
      )(item)

    // Detect unhandled fields in formik errors
    const {
      validationMessages: formikValidationMessages,
      hasUnknownValidationMessages: hasUnknownFormikValidationMessages,
    } = R.pipe(
      R.objOf('validationMessages'),
      splitValidationMessages(fields),
    )(formikErrors)

    // Only show formik validation messages if touched
    const touchedFormikMessages = R.pipe(
      R.mapObjIndexed((error, field) =>
        formikTouched[field] || formikValidateOnSubmit ? error : undefined,
      ),
      R.reject(R.isNil),
      R.map(handleNestedObjects),
    )(formikValidationMessages)

    // If there are Formik errors, give priority as validation messages
    const validationMessages = R.merge(
      untouchedServerValidationMessages,
      touchedFormikMessages,
    )

    // Only show server global messages if no fields have been modified since last submit
    const anyFieldModified =
      R.complement(isNilOrEmpty)(formikTouchedSinceSubmit) &&
      R.pipe(
        R.values,
        R.any(R.identity),
      )(formikTouchedSinceSubmit)

    const globalMessages =
      !anyFieldModified && requestError && requestError.globalMessages
        ? // Server global messages have precedence
          requestError.globalMessages
        : // If we got a unknown field error, report as a global unknown error instead
        hasUnknownServerValidationMessages || hasUnknownFormikValidationMessages
        ? [{ key: i18nErrorKeys.UNKNOWN, values: {} }]
        : // No errors
          undefined

    return {
      [validationMessagesProp]: validationMessages,
      [globalMessagesProp]: globalMessages,
    }
  })

export default withFormErrors

export const i18nErrorMessagePropType = PropTypes.shape({
  key: PropTypes.string,
  values: PropTypes.object,
})

export const validationMessagesPropType = fields =>
  PropTypes.shape(
    fields.reduce(
      (object, field) => ({
        ...object,
        [field]: PropTypes.arrayOf(i18nErrorMessagePropType),
      }),
      {},
    ),
  )

export const globalMessagesPropType = PropTypes.arrayOf(
  i18nErrorMessagePropType,
)
