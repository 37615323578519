import React from 'react'
import PropTypes from 'prop-types'
import { SelectSimple } from '@redradix/components.select'
import { ReactComponent as ChevronDown } from '../../styles/icons/chevron-down.svg'
import { ReactComponent as ChevronUp } from '../../styles/icons/chevron-up.svg'
import { ReactComponent as Delete } from '../../styles/icons/delete.svg'

import Tooltip from '@redradix/components.tooltip'
import TooltipTitle from '../texts/TooltipTitle'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'

const Select = ({
  defaultIsOpen,
  id,
  name,
  label,
  toolTip,
  placeholder,
  helperText,
  errorText,
  options,
  value,
  autoFocus,
  isTouched,
  isDisabled,
  onBlur,
  onChange,
  onClose,
  onFocus,
  onKeyDown,
  onOpen,
  isRequired,
}) => {
  return (
    <SelectSimple
      defaultIsOpen={defaultIsOpen}
      id={id}
      label={
        toolTip ? (
          <>
            {label}
            <Tooltip
              position={toolTip.position}
              renderTooltip={() => (
                <>
                  {toolTip.title && <TooltipTitle text={toolTip.title} />}
                  <TooltipRegularText text={toolTip.text} />
                </>
              )}
              renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
                <TooltipTrigger
                  classNames={triggerClasses}
                  onMouseOver={open}
                  onMouseOut={close}
                  ref={triggerRef}
                />
              )}
              hasArrow
              widthFixed
            />
          </>
        ) : (
          label
        )
      }
      name={name}
      options={options}
      placeholder={placeholder}
      helperText={helperText}
      errorText={errorText}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isTouched={isTouched}
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onOpen={onOpen}
      renderClearIcon={props => <Delete {...props} />}
      renderDownIcon={props => <ChevronDown {...props} />}
      renderUpIcon={props => <ChevronUp {...props} />}
      value={value}
    />
  )
}

Select.propTypes = {
  /** Boolean that specifies whether an <select> should automatically get focus when the page loads */
  autoFocus: PropTypes.bool,
  /** String that defines the error text of the <select> */
  errorText: PropTypes.node,
  /** String that specifies the helper text of the <select> */
  helperText: PropTypes.string,
  /** String that defines a unique identifier of the <select> */
  id: PropTypes.string,
  /** Boolean that defines whether the <select> is disabled */
  isDisabled: PropTypes.bool,
  /** Boolean that specifies whether the <select> field has been modified */
  isTouched: PropTypes.bool,
  /** String with the text of <label> related to the <select> */
  label: PropTypes.node,
  /** Object with the properties of the tooltip { text, position } **/
  toolTip: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
    position: PropTypes.oneOf([
      'top',
      'top-start',
      'top-end',
      'right',
      'right-start',
      'right-end',
      'bottom',
      'bottom-start',
      'bottom-end',
      'left',
      'left-start',
      'left-end',
    ]),
  }),
  /** String that specifies the name of an <select> */
  name: PropTypes.string,
  /** Function that is triggered when the component loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when changes in the selection occurs */
  onChange: PropTypes.func,
  /** Function that is triggered when the options menu is closed */
  onClose: PropTypes.func,
  /** Function that is triggered when the component gets focus */
  onFocus: PropTypes.func,
  /** Function that is triggered when a key is pressed and the component has focus*/
  onKeyDown: PropTypes.func,
  /** Function that is triggered when the options menu is opened */
  onOpen: PropTypes.func,
  /** Array of pairs label, value with the options of the <select> */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
      imgPath: PropTypes.string,
      imgAlt: PropTypes.string,
    }),
  ),
  /** String that defines the default value of the <select> */
  placeholder: PropTypes.string,
  /** Function that is triggered when the <select> isMulti */
  renderClearIcon: PropTypes.func,
  /** Function that is triggered when the <select> is closed */
  renderDownIcon: PropTypes.func,
  /** Function that is triggered when the <select> is open */
  renderUpIcon: PropTypes.func,
  /** Object that defines the selected value of the <select> */
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}

export default Select
