import React from 'react'
import PropTypes from 'prop-types'
import IconSocial from '../icons/IconSocial'
import { truncateNumber } from '../../app/utils'
import withTranslations from '../../hocs/withTranslations'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import { renderSocialValue } from '../../app/common/statistics-utils'
import { ReactComponent as LinkOutIcon } from '../../styles/icons/logout.svg'
//import { ReactComponent as LinkOut } from '../../styles/icons/search.svg'
//import { ReactComponent as LinkOut } from '../../styles/icons/stats.svg'

const SocialData = ({ type, data, socialUrl, isTruncated, i18nNumber }) => {
  const value =
    data && isTruncated
      ? renderSocialValue(truncateNumber(data, i18nNumber))
      : data
  return socialUrl ? (
    <div className="social-data">
      {getSocialLink(<IconSocial type={type} />, socialUrl)}
      <div className="social-data-data">
        {getSocialLink(
          value || <LinkOutIcon width={14} height={14} />,
          socialUrl,
        )}
      </div>
    </div>
  ) : null
}

const getSocialLink = (label, url) => (
  <a
    href={url}
    onClick={e => e.stopPropagation()}
    rel="noopener noreferrer"
    target="_blank"
  >
    {label}
  </a>
)

SocialData.defaultProps = {
  isTruncated: false,
}

SocialData.propTypes = {
  data: PropTypes.number,
  socialUrl: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)).isRequired,
  isTruncated: PropTypes.bool,
}

export default withTranslations(SocialData)
