import { compose, withPropsOnChange } from 'recompose'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getLanguage } from '../services/me/selectors'
import { hackFormatNumber } from '../libs/i18n'
import { memoize1NAry } from '../app/utils'

const getI18nFromIntl = memoize1NAry(intl => ({
  i18n: (msg, values) =>
    intl.formatMessage({ id: msg, defaultMessage: msg }, values),
  i18nNumber: (value, options = {}) => {
    const parts = intl.formatNumberToParts(value, options)

    return hackFormatNumber(intl, options, parts)
  },
  i18nDate: intl.formatDate,
  i18nTime: intl.formatTime,
}))

const withTranslations = compose(
  connect(state => ({
    locale: getLanguage(state),
  })),
  injectIntl,
  withPropsOnChange(['intl'], ({ intl }) => getI18nFromIntl(intl)),
)

export default withTranslations
