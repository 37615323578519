import React from 'react'
import PropTypes from 'prop-types'
import Card from './Card'

const MessageCard = ({ title, description, image }) => {
  return (
    <Card>
      <div className="message-card">
        <div className="message-card-image">
          <img src={image} alt="Decoration" />
        </div>
        <div className="message-card-contents">
          <p className="message-card-title">{title}</p>
          <p className="message-card-description">{description}</p>
        </div>
      </div>
    </Card>
  )
}

MessageCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default MessageCard
