import React from 'react'
import PropTypes from 'prop-types'

const SingleData = ({ label, children }) => {
  return (
    <div className="single-data">
      <div className="single-data-label">{label}</div>
      <div className="single-data-data">{children}</div>
    </div>
  )
}

SingleData.propTypes = {
  label: PropTypes.string,
}

export default SingleData
