export const SERVICE = 'WALLET'

export const DOMAIN = 'wallet'

export const HASH_KEY_BALANCE = 'balance'

export const HASH_KEY_TOTAL_INCOME = 'totalCurrency'

export const HASH_KEY_MOVEMENTS = 'movements'

export const HASH_KEY_BALANCE_RECHARGE_MODAL = 'isBalanceRechargeModalOpen'

export const HASH_KEY_RECHARGE_DISCOUNT_COUPON = 'rechargeDiscountCoupon'

export const HASH_KEY_IS_WITHDRAW_FUNDS_MODAL_OPEN = 'isWithdrawFundsModalOpen'

export const HASH_KEY_IS_WITHDRAW_SUCCESS_MODAL_OPEN =
  'isWithdrawSuccessModalOpen'

export const HASH_KEY_IS_BILLING_DATA_DISCLAIMER_MODAL_OPEN =
  'isBillingDataDisclaimerModalOpen'

export const HASH_KEY_WITHDRAW_FUNDS_DATA = 'withdrawFundsData'

export const REFRESH_BALANCE_TIME = 30000

export const REFRESH_WALLET_MOVEMENTS_TIME = 5000
