import React from 'react'
import PropTypes from 'prop-types'
import ModalTitle from '../texts/ModalTitle'
import ModalTagline from '../texts/ModalTagline'

const ModalHeader = props => {
  return (
    <div className="modal-header">
      <ModalTitle text={props.title} />
      {props.tagline && <ModalTagline text={props.tagline} />}
    </div>
  )
}

ModalHeader.propTypes = {
  title: PropTypes.string,
  tagline: PropTypes.string,
}

export default ModalHeader
