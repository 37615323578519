// WARNING! In its own file instead of selectors.js to avoid circular dependency
import { billingDataSchema } from './business'
import { createSelector } from 'reselect'
import { getCountries } from '../config'
import { getMediaInfo } from './selectors'
import * as R from 'ramda'

export const getHasCompletedBillingData = createSelector(
  getCountries,
  getMediaInfo,
  (countries, mediaInfo) => {
    if (!mediaInfo) {
      return false
    }

    const billingData = {
      ...mediaInfo.billing,
      currency: R.pathOr(null, ['currency', 'isoCode'], mediaInfo.billing),
    }

    try {
      billingDataSchema({ countries }).validateSync(billingData)
      return true
    } catch (error) {
      return false
    }
  },
)
