import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Layout from '../../ui/shared/Layout'
import { logout } from '../../services/auth'

const enhance = compose(
  withRouter,

  connect(
    null,
    {
      onLogout: logout,
    },
  ),

  withProps(() => ({
    isPublic: true,
    navItems: [],
    userName: undefined,
    useProfile: undefined,
    userAvatar: undefined,
    scope: undefined,
  })),
)

export default enhance(Layout)
