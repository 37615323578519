import * as R from 'ramda'
import { SERVICE_TYPES } from '../orders/business'
import { SUBMIT_BRIEFING } from './action-types'
import { getYupHoursContext, generalDataValidationSchema } from './validation'
import { validateYupSchema } from 'formik'
import { toMomentOnlyHours } from '../../app/utils'
import { getDateInfoFromDateConfig } from '../order-dates'
import { AuthScope } from '../auth/business'

export const PAYMENT_METHODS = {
  CREDIT_CARD: 'CREDIT_CARD',
  WALLET_BALANCE: 'WALLET_BALANCE',
}

export const BRIEFING_WEB_GOALS = {
  BRANDING: 'Branding',
  SEO: 'SEO',
}

export const BRIEFING_UPLOAD_CONDITIONS = {
  NONE: 'NONE',
  MEDIA: 'MEDIA',
  BRAND: 'BRAND',
}

export const BRIEFINGS_ORDER = [
  SERVICE_TYPES.WEB_POST,
  SERVICE_TYPES.WEB_ONLY_POST,
  SERVICE_TYPES.FACEBOOK,
  SERVICE_TYPES.TWITTER,
  SERVICE_TYPES.INSTAGRAM_PHOTO,
  SERVICE_TYPES.INSTAGRAM_VIDEO,
  SERVICE_TYPES.INSTAGRAM_STORY,
  SERVICE_TYPES.YOUTUBE_MENTION,
  SERVICE_TYPES.YOUTUBE_DEDICATED,
  SERVICE_TYPES.TWITCH,
  SERVICE_TYPES.TIKTOK_SHORT,
  SERVICE_TYPES.TIKTOK_LONG,
]

export const BRIEFINGS_MAX_FILE_SIZE = 52428800
export const PREDELIVERY_MAX_FILE_SIZE = 52428800
export const PREDELIVERY_TIKTOK_MAX_FILE_SIZE = 314572800

export const BRIEFING_UPLOAD_CONDITIONS_I18N = {
  [BRIEFING_UPLOAD_CONDITIONS.NONE]: 'checkout-condition:none',
  [BRIEFING_UPLOAD_CONDITIONS.MEDIA]: 'checkout-condition:media-choice',
  [BRIEFING_UPLOAD_CONDITIONS.BRAND]: 'checkout-condition:brand-choice',
}

export const BRIEFING_UPLOAD_CONDITIONS_ORDER_DETAIL_I18N = {
  // From brand's POV
  [AuthScope.BRAND]: {
    [BRIEFING_UPLOAD_CONDITIONS.NONE]: 'order-detail:checkout-condition-none',
    [BRIEFING_UPLOAD_CONDITIONS.MEDIA]:
      'order-detail:brand-checkout-condition-media-choice',
    [BRIEFING_UPLOAD_CONDITIONS.BRAND]:
      'order-detail:brand-checkout-condition-brand-choice',
  },

  // From media's POV
  [AuthScope.MEDIA]: {
    [BRIEFING_UPLOAD_CONDITIONS.NONE]: 'order-detail:checkout-condition-none',
    [BRIEFING_UPLOAD_CONDITIONS.MEDIA]:
      'order-detail:media-checkout-condition-media-choice',
    [BRIEFING_UPLOAD_CONDITIONS.BRAND]:
      'order-detail:media-checkout-condition-brand-choice',
  },
}

export const BRIEFING_UPLOAD_CONDITIONS_NORMAL = [
  BRIEFING_UPLOAD_CONDITIONS.NONE,
  BRIEFING_UPLOAD_CONDITIONS.MEDIA,
  BRIEFING_UPLOAD_CONDITIONS.BRAND,
]

export const BRIEFING_UPLOAD_CONDITIONS_REDUCED = [
  BRIEFING_UPLOAD_CONDITIONS.MEDIA,
  BRIEFING_UPLOAD_CONDITIONS.BRAND,
]

export const STEPPER_NAV_ITEMS = {
  NO_CART: 0,
  GENERAL: 1,
  BRIEFINGS: 2,
  PAYMENT: 3,
}

export const cartToGeneralDataValues = ({ cart }) => ({
  express: R.pathOr(false, ['shippingDates', 'express'], cart),
  campaign: R.propOr(null, 'campaign', cart),
  brand: R.propOr('', 'brand', cart),
  url: R.propOr('', 'url', cart),
  preDelivery: R.pathOr(false, ['shippingDates', 'preDelivery'], cart),

  acceptDate: R.pipe(
    R.pathOr(null, ['shippingDates', 'acceptDate']),
    R.unless(R.isNil, toMomentOnlyHours),
  )(cart),
  sendToReviewDate: R.pipe(
    R.pathOr(null, ['shippingDates', 'sendToReviewDate']),
    R.unless(R.isNil, toMomentOnlyHours),
  )(cart),
  validateReviewDate: R.pipe(
    R.pathOr(null, ['shippingDates', 'validateReviewDate']),
    R.unless(R.isNil, toMomentOnlyHours),
  )(cart),
  deliveryDate: R.pipe(
    R.pathOr(null, ['shippingDates', 'deliveryDate']),
    R.unless(R.isNil, toMomentOnlyHours),
  )(cart),
  approveDate: R.pipe(
    R.pathOr(null, ['shippingDates', 'approveDate']),
    R.unless(R.isNil, toMomentOnlyHours),
  )(cart),
})

export const briefingRequestType = serviceType =>
  `${SUBMIT_BRIEFING}_${serviceType}`

export const DATE_FIELDS = [
  'acceptDate',
  'sendToReviewDate',
  'validateReviewDate',
  'deliveryDate',
  'approveDate',
]

export const hasDataBriefingCompleted = (cart, datesConfig) => {
  const values = cartToGeneralDataValues({ cart })

  const dateInfo = getDateInfoFromDateConfig(
    values.preDelivery,
    R.pick(DATE_FIELDS, values),
    datesConfig,
  )

  const context = !values.express
    ? getYupHoursContext(dateInfo, datesConfig)
    : undefined

  try {
    validateYupSchema(values, generalDataValidationSchema, true, context)
    return true
  } catch (err) {
    return false
  }
}

export const getPendingBriefings = cart => {
  const pendingBriefingTypes = R.converge(R.difference, [
    R.pipe(
      R.propOr([], 'items'),
      R.pluck('service'),
    ),
    R.pipe(
      R.propOr({}, 'briefings'),
      R.keys,
    ),
  ])(cart)

  return BRIEFINGS_ORDER.filter(x => pendingBriefingTypes.includes(x))
}

export const getFirstBriefing = cart => {
  const availableBriefingTypes = R.pipe(
    R.propOr({}, 'briefings'),
    R.keys,
  )(cart)

  return BRIEFINGS_ORDER.filter(x => availableBriefingTypes.includes(x))[0]
}

export const getReachedNavItem = (cart, datesConfig) => {
  if (!cart || cart.items.length === 0) return STEPPER_NAV_ITEMS.NO_CART

  const completedDataBriefing = hasDataBriefingCompleted(cart, datesConfig)
  const pendingBriefings = getPendingBriefings(cart)

  if (pendingBriefings.length === 0 && completedDataBriefing) {
    return STEPPER_NAV_ITEMS.PAYMENT
  }

  if (completedDataBriefing) {
    return STEPPER_NAV_ITEMS.BRIEFINGS
  }

  return STEPPER_NAV_ITEMS.GENERAL
}

export const isUserFacingStripeError = stripeError =>
  stripeError.type === 'card_error' ||
  (stripeError.type === 'invalid_request_error' &&
    stripeError.code === 'payment_intent_authentication_failure')

export const MAX_SAMPLE_MESSAGE_CHARACTERS_FACEBOOK = 500

export const MAX_SAMPLE_MESSAGE_CHARACTERS_TWITTER = 280

export const MAX_SAMPLE_MESSAGE_CHARACTERS_INSTAGRAM = 500

export const MAX_SAMPLE_MESSAGE_CHARACTERS_TWITCH = 500
