import { useEffect } from 'react'

const usePermanentScript = url => {
  useEffect(() => {
    // Ensure we're only loading it once
    if (document.querySelector(`script[src="${url}"]`)) {
      return
    }

    const script = document.createElement('script')

    script.src = url
    script.async = true

    document.body.appendChild(script)
  }, [url])
}

export default usePermanentScript
