import React from 'react'
import DashboardPanel from './DashboardPanel'
import SearchCard from '../cards/SearchCard'

const SearchPanel = () => {
  return (
    <DashboardPanel size="two-thirds">
      <SearchCard />
    </DashboardPanel>
  )
}

export default SearchPanel
