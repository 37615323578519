import React from 'react'
import PropTypes from 'prop-types'
import OrdersPendingTable from '../orders/OrdersPendingTable'
import Card from './Card'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const OrdersCard = ({ orders }) => {
  return (
    <Card isHighlighted isContained>
      <div className="orders-card">
        <OrdersPendingTable orders={orders} />
      </div>
    </Card>
  )
}

OrdersCard.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.orderRow),
}

export default OrdersCard
