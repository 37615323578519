import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import CampaignDetailPanel from '../CampaignDetailPanel'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import ChartCard from '../../cards/ChartCard'
import PieChart from '../../charts/PieChart'
import UserBarChart from '../../charts/UserBarChart'
import CampaignPreviewInstagram from '../social-previews/CampaignPreviewInstagram'
import PublicationsTableInstagram from '../tables/PublicationsTableInstagram'
import { isNilOrEmpty } from '../../../app/utils'

const PIE_CHART_WITH_OTHERS_COLORS = ['#ffd960', '#3e17b3', '#cccdd8']

const CampaignDetailInstagramAll = ({
  previews,
  publicationsTableData,
  i18n,
  // Metrics values
  publications,
  influencers,
  reach,
  engagements,
  likes,
  views,
  comments,
  saved,
  serviceEngagementData,
  influencerEngagementData,
  reachByInfluencer,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        <AnalyticsKpi
          iconType="megaphone"
          value={publications}
          label={i18n('metrics:publications')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="influencer"
          value={influencers}
          label={i18n('metrics:influencers')}
          isTruncated
        />
        {reach > 0 && (
          <AnalyticsKpi
            iconType="target"
            value={reach}
            label={i18n('metrics:reach')}
            isTruncated
          />
        )}
        {engagements > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagements}
            label={i18n('metrics:engagements')}
            isTruncated
          />
        )}
        {likes > 0 && (
          <AnalyticsKpi
            iconType="like"
            value={likes}
            label={i18n('metrics:likes')}
            isTruncated
          />
        )}
        {views > 0 && (
          <AnalyticsKpi
            iconType="eye"
            value={views}
            label={i18n('metrics:views')}
            isTruncated
          />
        )}
        {comments > 0 && (
          <AnalyticsKpi
            iconType="comment"
            value={comments}
            label={i18n('metrics:comments')}
            isTruncated
          />
        )}
        {saved > 0 && (
          <AnalyticsKpi
            iconType="upload"
            value={saved}
            label={i18n('metrics:saved')}
            isTruncated
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewInstagram items={previews} />
      ) : null}
      <div className="campaign-detail-section-row">
        {!isNilOrEmpty(influencerEngagementData) && (
          <div className="engagements campaign-detail-section-cell two-thirds">
            <ChartCard title={i18n('metrics:engagements')} isDouble isPie>
              <PieChart
                data={serviceEngagementData}
                title={i18n('metrics:engagements-by-type')}
              />
              <PieChart
                title={i18n('metrics:engagements-by-resource')}
                data={influencerEngagementData}
                colors={PIE_CHART_WITH_OTHERS_COLORS}
              />
            </ChartCard>
          </div>
        )}
        {!isNilOrEmpty(reachByInfluencer) && (
          <div className="reach campaign-detail-section-cell one-third">
            <ChartCard title={i18n('metrics:reach-by-influencer')}>
              <UserBarChart
                data={reachByInfluencer}
                popupI18NKey="user-bar-chart:reach-tooltip"
              />
            </ChartCard>
          </div>
        )}
      </div>
      <CampaignDetailPanel title={i18n('noun:publications-table')}>
        <PublicationsTableInstagram
          publications={publicationsTableData}
        ></PublicationsTableInstagram>
      </CampaignDetailPanel>
    </>
  )
}

CampaignDetailInstagramAll.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  publicationsTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      service: PropTypes.string,
      reach: PropTypes.number,
      engagements: PropTypes.number,
      likes: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  publications: PropTypes.number,
  influencers: PropTypes.number,
  reach: PropTypes.number,
  engagements: PropTypes.number,
  likes: PropTypes.number,
  views: PropTypes.number,
  comments: PropTypes.number,
  saved: PropTypes.number,
  serviceEngagementData: PropTypes.array,
  influencerEngagementData: PropTypes.array,
  reachByInfluencer: PropTypes.array,
}

export default withTranslations(CampaignDetailInstagramAll)
