import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import ModalSimpleContent from './ModalSimpleContent'

const ListInformationModal = ({
  title,
  description1,
  description2,
  i18n,
  listItems,
  onClose,
  onContinue,
  isRequesting,
}) => (
  <ModalSimpleContent
    doubleActions
    title={title}
    action1Text={i18n('action:cancel')}
    action1={onClose}
    action2Text={i18n('action:continue')}
    action2={onContinue}
    isButton2Loading={isRequesting}
    isButtonDisabled={isRequesting}
    buttonVariant2="default"
    buttonHierarchy2="secondary"
  >
    <div className="modal-large-content">
      <p>{description1}</p>
      <ul>
        {listItems.map(listItem => (
          <li key={listItem.index}>{listItem}</li>
        ))}
      </ul>
      <p>{description2}</p>
    </div>
  </ModalSimpleContent>
)

ListInformationModal.propTypes = {
  title: PropTypes.string,
  description1: PropTypes.string,
  description2: PropTypes.string,
  listItems: PropTypes.array,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  isRequesting: PropTypes.bool,
}

export default withTranslations(ListInformationModal)
