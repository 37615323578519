import React from 'react'
import PropTypes from 'prop-types'
import CampaignDetailSlider from '../CampaignDetailSlider'
import CampaignDetailSlide from '../CampaignDetailSlide'
import useScript from '../../../hooks/useScript'

const CampaignPreviewTwitter = ({ items }) => {
  useScript('https://platform.twitter.com/widgets.js')
  return (
    <CampaignDetailSlider>
      {items.map((item, i) => (
        <CampaignDetailSlide type="twitter" key={i}>
          <div dangerouslySetInnerHTML={{ __html: item }}></div>
        </CampaignDetailSlide>
      ))}
    </CampaignDetailSlider>
  )
}

CampaignPreviewTwitter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
}

export default CampaignPreviewTwitter
