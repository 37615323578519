import React from 'react'
import PropTypes from 'prop-types'

const ModalTitle = props => {
  return <p className="modal-title">{props.text}</p>
}

ModalTitle.propTypes = {
  text: PropTypes.string,
}

export default ModalTitle
