import { withPropsOnChange } from 'recompose'
import * as R from 'ramda'

const withResourceURLParams = withPropsOnChange(['match'], ({ match }) => {
  return match.params.id
    ? {
        isEditing: !R.isNil(match.params.id),
        resourceId: match.params.id || undefined,
      }
    : {
        isEditing: !R.isNil(match.params.id),
      }
})

export default withResourceURLParams
