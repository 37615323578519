import { useEffect } from 'react'
import qs from 'query-string'

const DEFAULT_WIDTH = 800
const DEFAULT_HEIGHT = 500
const DEFAULT_BACKGROUND = '#fff'

const getStyleInnerText = ({
  selector,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  background = DEFAULT_BACKGROUND,
}) => `
    header{
      display:none
    }
    #root {
      visibility: hidden;
    }
    ${selector} {
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
      width: ${width}px;
      height: ${height}px;
      background: ${background};
      margin: 0 !important;
      padding: 0 !important;
      z-index: 10000;
    }
`

const hideAllExcept = options => {
  const innerText = getStyleInnerText(options)
  const style = Object.assign(document.createElement('style'), { innerText })
  document.head.appendChild(style)
}

const filterSelector = selector => selector.replace(/[^\s_.\-0-9a-zA-Z]/g, '')

const useHideAllExcept = () => {
  useEffect(() => {
    const parsedQs = qs.parse(window.location.search)
    if (!parsedQs.hideAllExcept) {
      return
    }

    hideAllExcept({
      selector: filterSelector(parsedQs.hideAllExcept),
      ...parsedQs,
    })
  }, [])
}

export default useHideAllExcept
