import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { Input } from '@redradix/components.input'
import Button from '@redradix/components.button'
import Tooltip from '@redradix/components.tooltip'
import FormattedFormErrors from '../FormattedFormErrors'
import SelectSearchable from '../form-elements/SelectSearchable/SelectSearchable'
import Select from '../form-elements/Select'
import FormField from '../form-elements/FormField'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import withTranslations from '../../hocs/withTranslations'
import IconIndicator from '../indicators/IconIndicator'
import WarningMessage from '../messages/WarningMessage'
import BrandBillingConfirmModal from './BrandBillingConfirmModal'
import Modal from '../../app/common/Modal'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'

const BrandBillingForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  submitForm,
  setFieldTouched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  hasSucceeded,

  countryOptions,
  stateOptions,
  isIdentityDocumentDisabled,
  isConfirmModalOpen,
  setConfirmModalOpen,
  currencyOptions,
  hasWorkInProgressOrders,
}) => {
  const hasWorkInProgressOrdersTooltip = hasWorkInProgressOrders && {
    text: i18n('billing-data:tooltip-cannot-change-currency-has-wip-orders'),
    position: 'right',
  }
  return (
    <form className="account-block-form" onSubmit={handleSubmit}>
      <Input
        type="text"
        name="businessName"
        label={i18n('label:businessName')}
        placeholder={i18n('label:businessName')}
        widthBehaviour="full"
        value={values.businessName}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.businessName}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.businessName && (
            <FormattedFormErrors errors={validationMessages.businessName} />
          )
        }
      />

      <FormField
        label={
          <>
            {i18n('label:identityDocument:company')}
            <Tooltip
              position="right"
              renderTooltip={() => (
                <TooltipRegularText
                  text={i18n('billing-data:tooltip-identity-document')}
                />
              )}
              renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
                <TooltipTrigger
                  classNames={triggerClasses}
                  onMouseOver={open}
                  onMouseOut={close}
                  ref={triggerRef}
                />
              )}
              hasArrow
              widthFixed
            />
          </>
        }
        isTouched={touched.identityDocument}
        errorText={
          validationMessages.identityDocument && (
            <FormattedFormErrors errors={validationMessages.identityDocument} />
          )
        }
      >
        <Input
          type="text"
          name="identityDocument"
          placeholder={i18n('label:identityDocument:company')}
          widthBehaviour="full"
          value={values.identityDocument}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.identityDocument}
          isDisabled={isIdentityDocumentDisabled || isRequestPending}
        />
      </FormField>

      <Input
        type="text"
        name="invoiceEmail"
        label={i18n('label:invoiceEmail')}
        placeholder={i18n('label:invoiceEmail')}
        widthBehaviour="full"
        value={values.invoiceEmail}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.invoiceEmail}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.invoiceEmail && (
            <FormattedFormErrors errors={validationMessages.invoiceEmail} />
          )
        }
      />

      <Input
        type="text"
        name="address"
        label={i18n('label:address')}
        placeholder={i18n('label:address')}
        widthBehaviour="full"
        value={values.address}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.address}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.address && (
            <FormattedFormErrors errors={validationMessages.address} />
          )
        }
      />

      <Input
        type="text"
        name="postalCode"
        label={i18n('label:postalCode')}
        placeholder={i18n('label:postalCode')}
        widthBehaviour="full"
        value={values.postalCode}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.postalCode}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.postalCode && (
            <FormattedFormErrors errors={validationMessages.postalCode} />
          )
        }
      />

      <SelectSearchable
        name="country"
        label={i18n('label:country')}
        toolTip={
          hasWorkInProgressOrdersTooltip ? hasWorkInProgressOrdersTooltip : ''
        }
        placeholder={i18n('label:country')}
        options={countryOptions}
        value={values.country}
        isTouched={touched.country}
        isDisabled={isRequestPending || hasWorkInProgressOrders}
        isSearchable={true}
        onChange={item => {
          handleChange({ target: { name: 'country', value: item.value } })
          handleChange({ target: { name: 'state', value: null } })
          setFieldTouched('state', false)
          handleChange({ target: { name: 'currency', value: null } })
          setFieldTouched('currency', false)
        }}
        onBlur={_ => {
          handleBlur({ target: { name: 'country' } })
        }}
        errorText={
          validationMessages.country && (
            <FormattedFormErrors errors={validationMessages.country} />
          )
        }
      />

      <SelectSearchable
        name="state"
        label={i18n('label:state')}
        placeholder={i18n('label:state')}
        options={stateOptions}
        value={values.state}
        isTouched={touched.state}
        isDisabled={isRequestPending || R.isEmpty(stateOptions)}
        onChange={item =>
          handleChange({ target: { name: 'state', value: item.value } })
        }
        onBlur={e => {
          handleBlur({ target: { name: 'state' } })
        }}
        errorText={
          validationMessages.state && (
            <FormattedFormErrors errors={validationMessages.state} />
          )
        }
      />

      <Input
        type="text"
        name="city"
        label={i18n('label:city')}
        placeholder={i18n('label:city')}
        widthBehaviour="full"
        value={values.city}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.city}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.city && (
            <FormattedFormErrors errors={validationMessages.city} />
          )
        }
      />
      <Select
        name="currency"
        label={i18n('label:currency')}
        toolTip={
          hasWorkInProgressOrdersTooltip ? hasWorkInProgressOrdersTooltip : ''
        }
        placeholder={i18n('label:currency')}
        options={currencyOptions}
        value={values.currency}
        isTouched={touched.currency}
        isDisabled={
          isRequestPending ||
          R.isEmpty(currencyOptions) ||
          hasWorkInProgressOrders
        }
        onChange={item =>
          handleChange({ target: { name: 'currency', value: item.value } })
        }
        onBlur={e => {
          handleBlur({ target: { name: 'currency' } })
        }}
        errorText={
          validationMessages.currency && (
            <FormattedFormErrors errors={validationMessages.currency} />
          )
        }
      />

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="button-container">
        {hasSucceeded ? (
          <IconIndicator type={'success'} />
        ) : (
          <Button
            type="submit"
            hierarchy="secondary"
            isLoading={isRequestPending}
          >
            {i18n('action:save')}
          </Button>
        )}
      </div>

      {isConfirmModalOpen && (
        <Modal onClose={() => setConfirmModalOpen(false)}>
          <BrandBillingConfirmModal
            onConfirm={submitForm}
            onBack={() => setConfirmModalOpen(false)}
          />
        </Modal>
      )}
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  invoiceEmail: PropTypes.string,
  businessName: PropTypes.string,
  identityDocument: PropTypes.string,
  country: CoobisPropTypes.makeSelectOption(PropTypes.string),
  state: CoobisPropTypes.makeSelectOption(PropTypes.string),
  city: PropTypes.string,
  address: PropTypes.string,
  postalCode: PropTypes.string,
  currency: CoobisPropTypes.makeSelectOption(PropTypes.string),
}

BrandBillingForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  hasSucceeded: PropTypes.bool.isRequired,
  isIdentityDocumentDisabled: PropTypes.bool.isRequired,
  setConfirmModalOpen: PropTypes.func,
  countryOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  stateOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  currencyOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  hasWorkInProgressOrders: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(BrandBillingForm)
