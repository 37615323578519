import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import ResourceDetailSection from './ResourceDetailSection'
import ResourceDetailServiceSocial from './ResourceDetailServiceSocial'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'

const ResourceDetailServicesSocial = ({
  services,
  item,
  i18n,
  cart,
  addItem,
  ...props
}) => {
  return (
    <ResourceDetailSection titleText={i18n('sections:services')}>
      <div className="resource-detail-service-list">
        {services.map((service, i) => {
          return (
            <ResourceDetailServiceSocial
              key={i}
              service={service}
              i={i}
              item={item}
              cart={cart}
              addItem={addItem}
              {...props}
            />
          )
        })}
      </div>
    </ResourceDetailSection>
  )
}

ResourceDetailServicesSocial.propTypes = {
  item: PropTypes.object,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      price: CoobisPropTypes.price,
      finalPrice: CoobisPropTypes.price,
    }),
  ),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceDetailServicesSocial)
