import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderSort from './OrderSort'
import { SORT_ORDERS } from '../../app/common/withSortOrders'

const OrderClosedHeadRow = ({ value, onChange, i18n }) => {
  return (
    <div className="order-head-row">
      <div className="order-head-row-item orders-closed-table-status">
        <OrderSort
          text={i18n('noun:status')}
          isActiveDesc={value === SORT_ORDERS.STATUS_DESC}
          isActiveAsc={value === SORT_ORDERS.STATUS_ASC}
          onSort={() =>
            onChange(
              // Lexicographical sort: ascending by default
              value === SORT_ORDERS.STATUS_ASC
                ? SORT_ORDERS.STATUS_DESC
                : SORT_ORDERS.STATUS_ASC,
            )
          }
        />
      </div>
      <div className="order-head-row-item orders-closed-table-brand">
        <OrderSort
          text={i18n('noun:brand')}
          isActiveDesc={value === SORT_ORDERS.BRAND_DESC}
          isActiveAsc={value === SORT_ORDERS.BRAND_ASC}
          onSort={() =>
            onChange(
              // Lexicographical sort: ascending by default
              value === SORT_ORDERS.BRAND_ASC
                ? SORT_ORDERS.BRAND_DESC
                : SORT_ORDERS.BRAND_ASC,
            )
          }
        />
      </div>
      <div className="order-head-row-item orders-closed-table-resource">
        <OrderSort
          text={i18n('noun:resource')}
          isActiveDesc={value === SORT_ORDERS.RESOURCE_DESC}
          isActiveAsc={value === SORT_ORDERS.RESOURCE_ASC}
          onSort={() =>
            onChange(
              // Lexicographical sort: ascending by default
              value === SORT_ORDERS.RESOURCE_ASC
                ? SORT_ORDERS.RESOURCE_DESC
                : SORT_ORDERS.RESOURCE_ASC,
            )
          }
        />
      </div>
      <div className="order-head-row-item orders-closed-table-service">
        <OrderSort
          text={i18n('noun:service')}
          isActiveDesc={value === SORT_ORDERS.SERVICE_DESC}
          isActiveAsc={value === SORT_ORDERS.SERVICE_ASC}
          onSort={() =>
            onChange(
              // Lexicographical sort: ascending by default
              value === SORT_ORDERS.SERVICE_ASC
                ? SORT_ORDERS.SERVICE_DESC
                : SORT_ORDERS.SERVICE_ASC,
            )
          }
        />
      </div>
      <div className="order-head-row-item orders-closed-table-date">
        <OrderSort
          text={i18n('noun:close-date')}
          isActiveDesc={value === SORT_ORDERS.DATE_DESC}
          isActiveAsc={value === SORT_ORDERS.DATE_ASC}
          onSort={() =>
            onChange(
              // Chronological sort: descending by default
              value === SORT_ORDERS.DATE_DESC
                ? SORT_ORDERS.DATE_ASC
                : SORT_ORDERS.DATE_DESC,
            )
          }
        />
      </div>
      <div className="order-head-row-item orders-closed-table-price">
        <OrderSort
          text={i18n('noun:price')}
          isActiveDesc={value === SORT_ORDERS.PRICE_DESC}
          isActiveAsc={value === SORT_ORDERS.PRICE_ASC}
          onSort={() =>
            onChange(
              // Numerical sort: ascending by default
              value === SORT_ORDERS.PRICE_ASC
                ? SORT_ORDERS.PRICE_DESC
                : SORT_ORDERS.PRICE_ASC,
            )
          }
        />
      </div>
    </div>
  )
}

OrderClosedHeadRow.propTypes = {
  value: PropTypes.oneOf(Object.values(SORT_ORDERS)).isRequired,
  onChange: PropTypes.func.isRequired,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderClosedHeadRow)
