import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { MediaQueryLap, MediaQueryPalm } from '../shared/MediaQueries'
import withTranslations from '../../hocs/withTranslations'
import { ReactComponent as ClockIcon } from '../../styles/icons/clock.svg'
import OrderStatus from './OrderStatus'
import OrderType from './OrderType'
import {
  mapServiceTypeToResourceType,
  mapServiceTypeToOrderTypeI18n,
  mapServiceTypeToI18nWithoutWords,
  ORDER_STATUS,
} from '../../services/orders/business'
import OrderActionButton from './OrderActionButton'
import { AuthScope } from '../../services/auth/business'
import { SERVICE_TYPES } from '../../services/orders/business'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import HackFormattedMoney from '../HackFormattedMoney'

const OrderCurrentRow = ({
  i18n,
  linkUrl,
  status,
  type,
  brand,
  resource,
  remainDays,
  price,
  onActionClick,
  hasPredelivery,
  isExpress,
}) => {
  return (
    <Link to={linkUrl} className="order-row">
      <div className="order-row-item orders-current-table-status">
        <OrderStatus status={status} />
      </div>
      <div className="order-row-item orders-current-table-brand" title={brand}>
        {brand}
      </div>
      <div
        className="order-row-item orders-current-table-resource"
        title={resource}
      >
        {resource}
      </div>
      <div
        className="order-row-item orders-current-table-service"
        title={i18n(mapServiceTypeToI18nWithoutWords[type])}
      >
        <OrderType
          i18nKey={mapServiceTypeToOrderTypeI18n[type]}
          icon={mapServiceTypeToResourceType[type]}
        />
      </div>
      <div className="order-row-item orders-current-table-date">
        <MediaQueryPalm>
          <ClockIcon className="icon" />
          {!isExpress
            ? remainDays >= 0
              ? i18n('message:remain-n-days', { days: remainDays })
              : i18n('message:has-expired')
            : i18n('message:is-express')}
        </MediaQueryPalm>
        <MediaQueryLap>
          {!isExpress
            ? remainDays >= 0
              ? i18n('message:remain-n-days', { days: remainDays })
              : i18n('message:has-expired')
            : i18n('message:is-express')}
        </MediaQueryLap>
      </div>
      <div className="order-row-item orders-current-table-price">
        <HackFormattedMoney value={price} />
      </div>
      <div className="order-row-item orders-current-table-actions">
        <OrderActionButton
          scope={AuthScope.MEDIA}
          orderStatus={status}
          hasPreDelivery={hasPredelivery}
          onClick={onActionClick}
        />
      </div>
    </Link>
  )
}

OrderCurrentRow.propTypes = {
  linkUrl: PropTypes.string,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)),
  type: PropTypes.oneOf(Object.values(SERVICE_TYPES)),
  brand: PropTypes.string,
  resource: PropTypes.string,
  remainDays: PropTypes.number,
  price: CoobisPropTypes.money,
  onActionClick: PropTypes.func.isRequired,
  hasPredelivery: PropTypes.bool,
  isExpress: PropTypes.bool,
}

export default withTranslations(OrderCurrentRow)
