import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import RangeSlider from './RangeSlider'
import NumberFormat from 'react-number-format'
import withTranslations from '../../hocs/withTranslations'
import FormattedNumberInput from '../../app/common/FormattedNumberInput'
import useNumberFormat from '../../hooks/useNumberFormat'
const RangeWidget = ({
  min,
  max,
  maxValue,
  minValue,

  isDisabled,
  onSliderChange,
  name,

  minInputId,
  minInputErrorText,
  isMinInputTouched,
  onMinInputBlur,
  onMinInputChange,
  onMinInputFocus,

  maxInputId,
  maxInputErrorText,
  isMaxInputTouched,
  onMaxInputBlur,
  onMaxInputChange,
  onMaxInputFocus,

  floatPrecision,
  formatOptions = {},

  i18nNumber,
}) => {
  const candidateNewMinValue = useRef()
  const candidateNewMaxValue = useRef()

  // ATTENTION! HACK!
  //
  // react-number-format triggers onValueChange even if the change comes from
  // props.
  //
  // Since we need to distinguish between values triggered by user (triggers
  // both onChange + onValueChange) and by prop change (only onValueChange), but
  // onValueChange is always called _before_ onChange, we store our new
  // candidate in a ref and commit it once onChange (user input) is triggered.
  const onMinValueChange = useCallback(values => {
    const { floatValue } = values
    candidateNewMinValue.current = floatValue
  }, [])

  const onMaxValueChange = useCallback(values => {
    const { floatValue } = values
    candidateNewMaxValue.current = floatValue
  }, [])

  const onMinChange = useCallback(
    _ => {
      onMinInputChange({
        target: { name, value: candidateNewMinValue.current },
      })
    },
    [name, onMinInputChange],
  )

  const onMaxChange = useCallback(
    _ => {
      onMaxInputChange({
        target: { name, value: candidateNewMaxValue.current },
      })
    },
    [name, onMaxInputChange],
  )

  const { format, removeFormatting } = useNumberFormat(
    formatOptions,
    i18nNumber,
  )

  // Reflect slider values (while dragging) in number inputs
  const [internalMinValue, setInternalMinValue] = useState(minValue)
  const [internalMaxValue, setInternalMaxValue] = useState(maxValue)

  const onSliderUpdated = ({ values: [min, max] }) => {
    setInternalMinValue(min)
    setInternalMaxValue(max)
  }

  const onSliderChangeWrapper = (...args) => {
    setInternalMinValue(undefined)
    setInternalMaxValue(undefined)

    onSliderChange(...args)
  }

  return (
    <div className="range-widget">
      <RangeSlider
        min={min}
        max={max}
        values={[Math.min(minValue, max), Math.max(maxValue, min)]}
        onChange={onSliderChangeWrapper}
        onValuesUpdated={onSliderUpdated}
        isDisabled={isDisabled}
        floatPrecision={floatPrecision}
      />
      <div className="range-widget-input-group">
        <NumberFormat
          value={minValue || internalMinValue}
          format={format}
          removeFormatting={removeFormatting}
          onValueChange={onMinValueChange}
          customInput={FormattedNumberInput}
          // Magically cascade props to customInput
          id={minInputId}
          name={name}
          errorText={minInputErrorText}
          isDisabled={isDisabled}
          isTouched={isMinInputTouched}
          onBlur={onMinInputBlur}
          onFocus={onMinInputFocus}
          onChange={onMinChange}
        />
        <NumberFormat
          value={maxValue || internalMaxValue}
          format={format}
          removeFormatting={removeFormatting}
          onValueChange={onMaxValueChange}
          customInput={FormattedNumberInput}
          // Magically cascade props to customInput
          id={maxInputId}
          name={name}
          errorText={maxInputErrorText}
          isDisabled={isDisabled}
          isTouched={isMaxInputTouched}
          onBlur={onMaxInputBlur}
          onFocus={onMaxInputFocus}
          onChange={onMaxChange}
        />
      </div>
    </div>
  )
}

RangeWidget.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,

  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  onSliderChange: PropTypes.func,

  minInputId: PropTypes.string,
  minInputErrorText: PropTypes.string,
  isMinInputTouched: PropTypes.bool,
  onMinInputBlur: PropTypes.func,
  onMinInputChange: PropTypes.func,
  onMinInputFocus: PropTypes.func,

  maxInputId: PropTypes.string,
  maxInputErrorText: PropTypes.string,
  isMaxInputTouched: PropTypes.bool,
  onMaxInputBlur: PropTypes.func,
  onMaxInputChange: PropTypes.func,
  onMaxInputFocus: PropTypes.func,

  floatPrecision: PropTypes.number,
  inputSuffix: PropTypes.string,
}

export default withTranslations(RangeWidget)
