import * as R from 'ramda'
import { memoize1NAry, truncateNumber } from '../utils'
import { mapServiceTypeToOrderTypeI18n } from '../../services/orders/business'
import { resourceAvatarURL } from '../../services/resources/business'

const percentageToChartValue = (number, precision = 0) =>
  Number((number * 100).toFixed(precision))

const AGE_RANGES = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']

export const getAgeAudienceData = memoize1NAry(demographicsData =>
  AGE_RANGES.map(ageRange => {
    const men = demographicsData[`age${ageRange.replace('-', '_')}_male`]
    const women = demographicsData[`age${ageRange.replace('-', '_')}_female`]

    return {
      'age-range': ageRange,
      men: percentageToChartValue(men),
      women: percentageToChartValue(women),
    }
  }),
)

export const getGenderAudienceData = memoize1NAry(
  (i18n, i18nNumber, { female, male }) => [
    {
      name: i18n('gender:woman'),
      legendValue: i18nNumber(female, { style: 'percent' }),
      value: percentageToChartValue(female),
    },
    {
      name: i18n('gender:man'),
      legendValue: i18nNumber(male, { style: 'percent' }),
      value: percentageToChartValue(male),
    },
  ],
)

export const getCountryDensityData = memoize1NAry(
  (translatedCountries, countryDemographics) =>
    Object.keys(countryDemographics).map(function(country) {
      return {
        id: country,
        value: countryDemographics[country],
        label: translatedCountries.find(trans => trans.id === country).label,
      }
    }),
)

export const getCountryDensityDataForResource = memoize1NAry(
  (translatedCountries, countryDemographics) =>
    R.map(
      ({ country, density }) => ({
        id: country,
        value: density,
        label: translatedCountries.find(trans => trans.id === country).label,
      }),
      countryDemographics,
    ),
)

export const getInstagramServiceEngagementData = memoize1NAry(
  (engagementsByService, i18n, i18nNumber) => {
    const totalKpi = Object.values(engagementsByService).reduce(
      (acc, n) => acc + n,
      0,
    )

    return totalKpi > 0
      ? R.pipe(
          R.toPairs,
          R.filter(([_, value]) => !R.isNil(value) && value !== 0),
          R.map(([key, value]) => ({
            name: i18n(mapServiceTypeToOrderTypeI18n[key]),
            value: percentageToChartValue(value / totalKpi, 1),
            legendValue: truncateNumber(engagementsByService[key], i18nNumber),
          })),
        )(engagementsByService)
      : []
  },
)

export const sumChartValues = R.pipe(
  R.pluck('value'),
  R.sum,
)

export const sumChartWomenValues = R.pipe(
  R.pluck('women'),
  R.sum,
)

export const sumChartMenValues = R.pipe(
  R.pluck('men'),
  R.sum,
)

export const getInstagramResourceEngagementData = memoize1NAry(
  (engagementsByResource, i18n, i18nNumber) => {
    const totalKpi = sumChartValues(engagementsByResource)

    const MAXIMUM_INFLUENCERS = 2

    const sortedInfluencers = R.sort(
      R.descend(R.prop('value')),
      engagementsByResource,
    )

    const explicitInfluencers = R.take(MAXIMUM_INFLUENCERS, sortedInfluencers)

    const others = R.drop(MAXIMUM_INFLUENCERS, sortedInfluencers)
    const totalOthersValue = sumChartValues(others)

    return totalKpi > 0
      ? R.pipe(
          R.map(item => ({
            name: item.resource.name,
            value: percentageToChartValue(item.value / totalKpi, 1),
            legendValue: truncateNumber(item.value, i18nNumber),
          })),
          R.when(
            () => totalOthersValue > 0,
            R.append({
              name: i18n('metrics:others'),
              value: percentageToChartValue(totalOthersValue / totalKpi, 1),
              legendValue: truncateNumber(totalOthersValue, i18nNumber),
            }),
          ),
        )(explicitInfluencers)
      : []
  },
)

export const getUserBarChartData = memoize1NAry((data, i18n) => {
  const totalKpi = sumChartValues(data)

  const MAXIMUM_INFLUENCERS = 3

  const sortedInfluencers = R.sort(R.descend(R.prop('value')), data)

  const explicitInfluencers = R.take(MAXIMUM_INFLUENCERS, sortedInfluencers)

  const others = R.drop(MAXIMUM_INFLUENCERS, sortedInfluencers)
  const totalOthersValue = sumChartValues(others)

  return totalKpi > 0
    ? R.pipe(
        R.map(item => ({
          category: `${item.resource.username}-${item.resource.id}`,
          username: item.resource.username || item.resource.name,
          value: item.value,
          picture: resourceAvatarURL(item.resource.id),
        })),
        R.when(
          () => totalOthersValue > 0,
          R.append({
            category: i18n('metrics:others'),
            username: i18n('metrics:others'),
            value: totalOthersValue,
          }),
        ),
      )(explicitInfluencers)
    : []
})

const getSeriesMinAndMaxDates = items => {
  return items.reduce(
    (innerAcc, { date }) => {
      return {
        minDate: Math.min(innerAcc.minDate, +new Date(date)),
        maxDate: Math.max(innerAcc.maxDate, +new Date(date)),
      }
    },
    {
      minDate: +Infinity,
      maxDate: -Infinity,
    },
  )
}

export const getChartMinAndMaxDates = (items, getSerieItemsFn) => {
  return items.reduce(
    (acc, serie) => {
      const serieItems = getSerieItemsFn(serie)
      const serieDates = getSeriesMinAndMaxDates(serieItems)

      return {
        minDate: Math.min(acc.minDate, serieDates.minDate),
        maxDate: Math.max(acc.maxDate, serieDates.maxDate),
      }
    },
    {
      minDate: +Infinity,
      maxDate: -Infinity,
    },
  )
}

export const MAX_INITIAL_MONTHS = 10

export const getSeriesMaxInitialDay = minDate =>
  new Date(minDate.getFullYear(), minDate.getMonth() + MAX_INITIAL_MONTHS, 1)

export const containsNotNullValues = data => {
  return Object.values(data).some(i => {
    return i !== null
  })
}
const isNotNil = value => {
  return !R.isNil(value)
}
export const containsNotNullValuesChartCard = R.pipe(
  R.pluck('value'),
  R.any(isNotNil),
)

export const containsNotNullValuesMultilineChart = R.pipe(
  R.pluck('items'),
  R.map(R.pluck('value')),
  R.flatten,
  R.any(isNotNil),
)

export const renderSocialValue = value => {
  return value === null ? '-' : value
}
