import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import customComponents from './react-select-components'
import { ReactComponent as IconDefaultDown } from '../../../styles/icons/chevron-down.svg'
import { ReactComponent as ClearIconDefault } from '../../../styles/icons/delete.svg'

const Select = ({
  appearance,
  ariaLabel,
  autoFocus,
  behaviour,
  defaultIsOpen,
  dropDownPos,
  errorText,
  heightBehaviour,
  helperText,
  id,
  isDisabled,
  isMulti,
  isRequired,
  isSearchable,
  isTouched,
  label,
  name,
  onBlur,
  onChange,
  onClose,
  onFocus,
  onInputChange,
  onKeyDown,
  onOpen,
  options,
  placeholder,
  renderClearIcon,
  renderDownIcon,
  searchHint,
  value,
  widthBehaviour,
}) => {
  const hasError = errorText && isTouched

  // Overwrite onChange logic to support fixed values
  const handleChange = (value, { action, removedValue }) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        value = options.filter(v => v.isFixed)
        break
      default:
        break
    }

    onChange(value, { action, removedValue })
  }

  return (
    <ReactSelect
      id={id}
      ariaLabel={ariaLabel || label || id}
      autoFocus={autoFocus}
      defaultMenuIsOpen={defaultIsOpen}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isSearchable={isSearchable}
      name={name}
      options={options}
      value={value}
      isClearable={isMulti && value && value.some(v => !v.isFixed)}
      onBlur={onBlur}
      onChange={handleChange}
      onClose={onClose}
      onFocus={onFocus}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      onOpen={onOpen}
      components={customComponents}
      componentsProps={{
        renderClearIcon,
        renderDownIcon,
        label,
        isRequired,
        hasError,
        behaviour,
        isTouched,
        heightBehaviour,
        widthBehaviour,
        appearance,
        dropDownPos,
        errorText,
        helperText,
        searchHint,
      }}
    />
  )
}

Select.defaultProps = {
  appearance: 'box',
  behaviour: 'static',
  dropDownPos: 'down',
  defaultIsOpen: false,
  isSearchable: true,
  renderDownIcon: props => <IconDefaultDown {...props} />,
  renderClearIcon: props => <ClearIconDefault {...props} />,
}

Select.propTypes = {
  /** String that defines the appearance of the <input> */
  appearance: PropTypes.oneOf(['box', 'line']),
  /** String that defines the <label> when it is 'not visible' on the screen */
  ariaLabel: PropTypes.string,
  /** Boolean that specifies whether an <select> should automatically get focus when the page loads */
  autoFocus: PropTypes.bool,
  /** String that specifies whether the <select> has to be animated.
   It only can be dynamic when the select 'isSearchable' */
  behaviour: PropTypes.oneOf(['static', 'dynamic']),
  /** Boolean that defines whether the <select> options list should be open on first render or not */
  defaultIsOpen: PropTypes.bool,
  /** String that defines whether the dropdown opens 'up' or 'down' */
  dropDownPos: PropTypes.oneOf(['down', 'up']),
  /** String that defines the error text of the <select> */
  errorText: PropTypes.node,
  /** String that defines the behaviour of the <select> height */
  heightBehaviour: PropTypes.oneOf(['adaptative', 'fixed']),
  /** String that specifies the helper text of the <select> */
  helperText: PropTypes.string,
  /** String that defines a unique identifier of the <select> */
  id: PropTypes.string,
  /** Boolean that defines whether the <select> is disabled */
  isDisabled: PropTypes.bool,
  /** Boolean that defines whether the <select> allows multiple selection */
  isMulti: PropTypes.bool,
  /** Boolean that defines whether the <select> is autocomplete */
  isRequired: PropTypes.bool,
  /** Boolean that defines whether the <select> is required */
  isSearchable: PropTypes.bool,
  /** Boolean that specifies whether the <select> field has been modified */
  isTouched: PropTypes.bool,
  /** String with the text of <label> related to the <select> */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** String that defines the name of the <select> */
  name: PropTypes.string,
  /** Function that is triggered when the component loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when changes in the selection occurs */
  onChange: PropTypes.func.isRequired,
  /** Function that is triggered when the options menu is closed */
  onClose: PropTypes.func.isRequired,
  /** Function that is triggered when the component gets focus */
  onFocus: PropTypes.func.isRequired,
  /** Function that is triggered when search input field changes its value. Only with selects 'autocomplete' */
  onInputChange: PropTypes.func,
  /** Function that is triggered when a key is pressed and the component has focus*/
  onKeyDown: PropTypes.func.isRequired,
  /** Function that is triggered when the options menu is opened */
  onOpen: PropTypes.func.isRequired,
  /** Array of pairs label, value with the options of the <select> */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
      isFixed: PropTypes.bool,
    }),
  ),
  /** String that defines the default value of the <select> */
  placeholder: PropTypes.string,
  /** Function that is triggered when the <select> isMulti */
  renderClearIcon: PropTypes.func,
  /** Function that is triggered when the <select> is closed */
  renderDownIcon: PropTypes.func,
  /** Object that defines the selected value of the <select> */
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isFixed: PropTypes.bool,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isFixed: PropTypes.bool,
      }),
    ),
  ]),
  /** String that defines the behaviour of the input width */
  widthBehaviour: PropTypes.oneOf(['adaptative', 'fixed', 'full']),
}

export default Select
