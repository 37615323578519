import ResourceDistributor from '../../ui/resources/ResourceDistributor'
import { withPropsOnChange } from 'recompose'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import {
  ROUTE_MEDIA_CREATE_WEB_RESOURCES,
  ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE,
  prettifyConstant,
} from '../common/routes'

const enhance = withPropsOnChange([], () => ({
  webLinkUrl: ROUTE_MEDIA_CREATE_WEB_RESOURCES.linkTo(),
  twitterLinkUrl: ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE.linkTo({
    socialNetwork: prettifyConstant(RESOURCE_TYPES.TWITTER),
  }),
  facebookLinkUrl: ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE.linkTo({
    socialNetwork: prettifyConstant(RESOURCE_TYPES.FACEBOOK),
  }),
  instagramLinkUrl: ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE.linkTo({
    socialNetwork: prettifyConstant(RESOURCE_TYPES.INSTAGRAM),
  }),
  youtubeLinkUrl: ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE.linkTo({
    socialNetwork: prettifyConstant(RESOURCE_TYPES.YOUTUBE),
  }),
  twitchLinkUrl: ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE.linkTo({
    socialNetwork: prettifyConstant(RESOURCE_TYPES.TWITCH),
  }),
  tiktokLinkUrl: ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE.linkTo({
    socialNetwork: prettifyConstant(RESOURCE_TYPES.TIKTOK),
  }),
}))

export default enhance(ResourceDistributor)
