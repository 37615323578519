import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import GlobalContent from '../shared/GlobalContent'
import CampaignDetailHeader from './CampaignDetailHeader'
import ChipAnchor from '../chips/ChipAnchor'
import CampaignDetailContent from './CampaignDetailContent'
import Spinner from '../loaders/Spinner'
import { STATISTICS_TABS } from '../../services/campaigns/business'
import {
  ROUTE_BRAND_CAMPAIGNS_BY_ID,
  prettifyConstant,
} from '../../app/common/routes'

const CampaignDetail = ({
  heading,
  type,
  selectorOptions,
  selectorValue,
  selectorErrorText,
  onSelectorChange,
  isCampaignDeletable,
  isLoading,
  children,
  onEditCampaign,
  onDeleteCampaign,
  campaignId,
  availableStats,
  i18n,
  ...props
}) => {
  return (
    <GlobalContent isMobileFullWidth>
      <div className="campaign-detail">
        <CampaignDetailHeader
          heading={heading}
          isCampaignDeletable={isCampaignDeletable}
          onEdit={onEditCampaign}
          onDelete={onDeleteCampaign}
          navigationSlot={
            <>
              <ChipAnchor
                url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                  campaignId,
                  resourceType: prettifyConstant(STATISTICS_TABS.GLOBAL),
                })}
                isInline
                text={i18n('noun:global')}
                isSelected={type === STATISTICS_TABS.GLOBAL}
              />
              {availableStats.includes(STATISTICS_TABS.WEB_BRANDING) && (
                <ChipAnchor
                  url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                    campaignId,
                    resourceType: prettifyConstant(
                      STATISTICS_TABS.WEB_BRANDING,
                    ),
                  })}
                  isInline
                  text={i18n('noun:web-branding')}
                  isSelected={type === STATISTICS_TABS.WEB_BRANDING}
                />
              )}
              {availableStats.includes(STATISTICS_TABS.WEB_SEO) && (
                <ChipAnchor
                  url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                    campaignId,
                    resourceType: prettifyConstant(STATISTICS_TABS.WEB_SEO),
                  })}
                  isInline
                  text={i18n('noun:web-seo')}
                  isSelected={type === STATISTICS_TABS.WEB_SEO}
                />
              )}
              {availableStats.includes(STATISTICS_TABS.FACEBOOK) && (
                <ChipAnchor
                  url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                    campaignId,
                    resourceType: prettifyConstant(STATISTICS_TABS.FACEBOOK),
                  })}
                  isInline
                  text={i18n('noun:facebook')}
                  isSelected={type === STATISTICS_TABS.FACEBOOK}
                />
              )}
              {availableStats.includes(STATISTICS_TABS.TWITTER) && (
                <ChipAnchor
                  url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                    campaignId,
                    resourceType: prettifyConstant(STATISTICS_TABS.TWITTER),
                  })}
                  isInline
                  text={i18n('noun:twitter')}
                  isSelected={type === STATISTICS_TABS.TWITTER}
                />
              )}
              {availableStats.includes(STATISTICS_TABS.INSTAGRAM) && (
                <ChipAnchor
                  url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                    campaignId,
                    resourceType: prettifyConstant(STATISTICS_TABS.INSTAGRAM),
                  })}
                  isInline
                  text={i18n('noun:instagram')}
                  isSelected={type === STATISTICS_TABS.INSTAGRAM}
                />
              )}
              {availableStats.includes(STATISTICS_TABS.YOUTUBE) && (
                <ChipAnchor
                  url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                    campaignId,
                    resourceType: prettifyConstant(STATISTICS_TABS.YOUTUBE),
                  })}
                  isInline
                  text={i18n('noun:youtube')}
                  isSelected={type === STATISTICS_TABS.YOUTUBE}
                />
              )}
              {availableStats.includes(STATISTICS_TABS.TWITCH) && (
                <ChipAnchor
                  url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                    campaignId,
                    resourceType: prettifyConstant(STATISTICS_TABS.TWITCH),
                  })}
                  isInline
                  text={i18n('noun:twitch')}
                  isSelected={type === STATISTICS_TABS.TWITCH}
                />
              )}
              {availableStats.includes(STATISTICS_TABS.TIKTOK) && (
                <ChipAnchor
                  url={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                    campaignId,
                    resourceType: prettifyConstant(STATISTICS_TABS.TIKTOK),
                  })}
                  isInline
                  text={i18n('noun:tiktok')}
                  isSelected={type === STATISTICS_TABS.TIKTOK}
                />
              )}
            </>
          }
        />
        <CampaignDetailContent
          title={
            type === STATISTICS_TABS.GLOBAL
              ? `${i18n('noun:all-campaign-publications')}`
              : null
          }
          selectorOptions={selectorOptions}
          selectorValue={selectorValue}
          selectorErrorText={selectorErrorText}
          onSelectorChange={onSelectorChange}
          isLoading={isLoading}
          {...props}
        >
          {isLoading ? (
            <Spinner size={60} text={i18n('status:loading-statistics')} />
          ) : (
            children
          )}
        </CampaignDetailContent>
      </div>
    </GlobalContent>
  )
}

CampaignDetail.propTypes = {
  campaignId: PropTypes.number,
  heading: PropTypes.string,
  type: PropTypes.oneOf(Object.values(STATISTICS_TABS)),
  selectorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
      imgPath: PropTypes.string,
      imgAlt: PropTypes.string,
    }),
  ),
  selectorValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  selectorErrorText: PropTypes.node,
  onSelectorChange: PropTypes.func,
  children: PropTypes.node,
  isCampaignDeletable: PropTypes.bool,
  isLoading: PropTypes.bool,
  onEditCampaign: PropTypes.func,
  onDeleteCampaign: PropTypes.func,
  availableStats: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(STATISTICS_TABS)),
  ),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(CampaignDetail)
