import * as hash from 'reduken/hash'
import { all, call, takeLatest, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as actionTypes from './action-types'
import { DOMAIN, HASH_KEY_NEEDS_SCOPE } from './constants'
import { newToken } from '../auth'
import { fetchMyInfo } from '../me'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
  deleteRequest,
} from '../communication/actions'
import * as api from './api'
import { ROUTE_ROOT } from '../../app/common/routes'

function* passwordRecoveryRequestSaga({
  payload: { username, recaptcha, recaptchaAction },
}) {
  yield put(deleteRequest(actionTypes.REQUEST_WITH_SCOPE)) // delete any errors from logging in with scope
  yield put(hash.remove(DOMAIN, HASH_KEY_NEEDS_SCOPE))
  yield put(startRequest(actionTypes.REQUEST))

  console.log('jjjjjjjjjjjjjjjjjooooooooooo')

  const response = yield call(
    api.passwordRecoveryRequest,
    username,
    recaptcha,
    recaptchaAction,
  )

  console.log(response, 'aaaaaaaaaaaaaaaaa')

  if (response.needsScope) {
    yield put(hash.set(DOMAIN, HASH_KEY_NEEDS_SCOPE, true))
    yield put(endRequestSuccess(actionTypes.REQUEST))
    return
  }

  if (response.error) {
    yield put(endRequestError(actionTypes.REQUEST, response))
    return
  }

  yield put(endRequestSuccess(actionTypes.REQUEST))
}

function* passwordRecoveryRequestWithScopeSaga({
  payload: { username, scope, recaptcha, recaptchaAction },
}) {
  yield put(startRequest(actionTypes.REQUEST_WITH_SCOPE))

  const response = yield call(
    api.passwordRecoveryRequestWithScope,
    username,
    scope,
    recaptcha,
    recaptchaAction,
  )

  if (response.error) {
    yield put(endRequestError(actionTypes.REQUEST_WITH_SCOPE, response))
    yield put(hash.remove(DOMAIN, HASH_KEY_NEEDS_SCOPE))
    return
  }

  yield put(hash.remove(DOMAIN, HASH_KEY_NEEDS_SCOPE))
  yield put(endRequestSuccess(actionTypes.REQUEST_WITH_SCOPE))
}

function* passwordRecoveryCheckResetTokenSaga({ payload: { token } }) {
  yield put(startRequest(actionTypes.CHECK_RESET_TOKEN))

  const response = yield call(api.passwordRecoveryCheckResetToken, token)

  if (response.error) {
    yield put(endRequestError(actionTypes.CHECK_RESET_TOKEN, response))
    return
  }

  yield put(endRequestSuccess(actionTypes.CHECK_RESET_TOKEN))
}

function* passwordRecoveryResetPasswordSaga({
  payload: { token, newPassword },
}) {
  yield put(startRequest(actionTypes.RESET_PASSWORD))

  const response = yield call(
    api.passwordRecoveryResetPassword,
    token,
    newPassword,
  )

  if (response.error) {
    yield put(endRequestError(actionTypes.RESET_PASSWORD, response))
    return
  }

  yield put(
    newToken(
      response.data.access_token,
      response.data.scope,
      response.data.expires_in,
    ),
  )

  yield put(push(ROUTE_ROOT.linkTo()))
  yield put(fetchMyInfo())
  yield put(endRequestSuccess(actionTypes.RESET_PASSWORD))
}

export default function*() {
  yield all([
    takeLatest(actionTypes.REQUEST, passwordRecoveryRequestSaga),
    takeLatest(
      actionTypes.REQUEST_WITH_SCOPE,
      passwordRecoveryRequestWithScopeSaga,
    ),
    takeLatest(
      actionTypes.CHECK_RESET_TOKEN,
      passwordRecoveryCheckResetTokenSaga,
    ),
    takeLatest(actionTypes.RESET_PASSWORD, passwordRecoveryResetPasswordSaga),
  ])
}
