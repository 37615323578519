import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { ReactComponent as Personal } from '../../styles/icons/user.svg'
import { ReactComponent as Balance } from '../../styles/icons/balance.svg'
import { ReactComponent as Logout } from '../../styles/icons/logout.svg'
import { ReactComponent as Info } from '../../styles/icons/info.svg'
import {
  ROUTE_BRAND_PERSONAL_DATA,
  ROUTE_BRAND_WALLET,
  ROUTE_MEDIA_PERSONAL_DATA,
  ROUTE_MEDIA_WALLET,
  ROUTE_BRAND_PERSONAL_DATA_MATCH,
  ROUTE_MEDIA_PERSONAL_DATA_MATCH,
  ROUTE_BRAND_WALLET_MATCH,
  ROUTE_MEDIA_WALLET_MATCH,
} from '../../app/common/routes'
import UserNavigationItem from './UserNavigationItem'
import Avatar from '../avatar/Avatar'
import { AuthScope } from '../../services/auth/business'

const UserNavigation = ({
  i18n,
  onClickItem,
  onLogout,
  userAvatar,
  userName,
  userProfile,
  scope,
  location,
}) => {
  const handleLogout = useCallback(() => {
    onClickItem && onClickItem()
    onLogout && onLogout()
  }, [onClickItem, onLogout])

  return (
    <nav className="user-navigation">
      <ul>
        <li className="user-navigation-info">
          <Avatar image={userAvatar} />
          <div className="user-dropdown-info">
            <span className="user-dropdown-name">{userName}</span>
            <span className="user-dropdown-profile">{i18n(userProfile)}</span>
          </div>
        </li>
        <UserNavigationItem
          to={
            scope === AuthScope.BRAND
              ? ROUTE_BRAND_PERSONAL_DATA.linkTo()
              : scope === AuthScope.MEDIA
              ? ROUTE_MEDIA_PERSONAL_DATA.linkTo()
              : undefined
          }
          onClick={onClickItem}
          isActive={
            scope === AuthScope.BRAND
              ? ROUTE_BRAND_PERSONAL_DATA_MATCH.test(location.pathname)
              : scope === AuthScope.MEDIA
              ? ROUTE_MEDIA_PERSONAL_DATA_MATCH.test(location.pathname)
              : undefined
          }
        >
          <Personal className="user-navigation-icon" />{' '}
          {i18n('noun:personal-data')}
        </UserNavigationItem>
        <UserNavigationItem
          to={
            scope === AuthScope.BRAND
              ? ROUTE_BRAND_WALLET.linkTo()
              : scope === AuthScope.MEDIA
              ? ROUTE_MEDIA_WALLET.linkTo()
              : undefined
          }
          onClick={onClickItem}
          isActive={
            scope === AuthScope.BRAND
              ? ROUTE_BRAND_WALLET_MATCH.test(location.pathname)
              : scope === AuthScope.MEDIA
              ? ROUTE_MEDIA_WALLET_MATCH.test(location.pathname)
              : undefined
          }
        >
          <Balance className="user-navigation-icon" />
          {i18n('noun:balance-movements')}
        </UserNavigationItem>
        <li>
          <a
            className="user-navigation-item"
            href={i18n('public:privacy-link')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Info className="user-navigation-icon" />
            {i18n('noun:privacy')}
          </a>
        </li>
        <li>
          <a
            className="user-navigation-item"
            href={i18n('public:terms-link')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Info className="user-navigation-icon" />
            {i18n('noun:terms')}
          </a>
        </li>
        <li>
          <button className="user-navigation-item" onClick={handleLogout}>
            <Logout className="user-navigation-icon" />
            {i18n('action:logout')}
          </button>
        </li>
      </ul>
    </nav>
  )
}

UserNavigation.propTypes = {
  onClickItem: PropTypes.func,
  onLogout: PropTypes.func,
  userName: PropTypes.string,
  userAvatar: PropTypes.string,
  userProfile: PropTypes.string,
  scope: PropTypes.oneOf([AuthScope.BRAND, AuthScope.MEDIA]),
}

export default withTranslations(UserNavigation)
