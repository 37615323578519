import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  getIsWithdrawFundsModalOpen,
  getIsBillingDataDisclaimerModalOpen,
  openWithdrawFundsModal,
  closeWithdrawFundsModal,
  getIsWithdrawSuccessModalOpen,
} from '../../services/wallet'

const withWithdrawFunds = compose(
  connect(
    state => ({
      isWithdrawFundsModalOpen: getIsWithdrawFundsModalOpen(state),
      isWithdrawSuccessModalOpen: getIsWithdrawSuccessModalOpen(state),
      isBillingDataDisclaimerModalOpen: getIsBillingDataDisclaimerModalOpen(
        state,
      ),
    }),
    {
      openWithdrawFundsModal,
      closeWithdrawFundsModal,
    },
  ),
)

export default withWithdrawFunds
