import { connect } from 'react-redux'
import {
  fetchCategories,
  fetchCountries,
  fetchCurrencies,
} from '../services/config'
import { compose } from 'recompose'
import {
  FETCH_CATEGORIES,
  FETCH_COUNTRIES,
  FETCH_CURRENCIES,
} from '../services/config/action-types'
import withEffect from './withEffect'
import withRouteServerError from './withRouteServerError'
import withSpinner from './withSpinner'

const withFetchConfig = compose(
  connect(
    null,
    { fetchCategories, fetchCountries, fetchCurrencies },
  ),

  withEffect(
    ({ fetchCategories, fetchCountries, fetchCurrencies }) => {
      fetchCategories()
      fetchCountries()
      fetchCurrencies()
    },
    ({ fetchCategories, fetchCountries, fetchCurrencies }) => [
      fetchCategories,
      fetchCountries,
      fetchCurrencies,
    ],
  ),

  withRouteServerError(FETCH_CATEGORIES),
  withRouteServerError(FETCH_COUNTRIES),
  withRouteServerError(FETCH_CURRENCIES),

  withSpinner(FETCH_CATEGORIES, { i18nKey: 'status:loading-config' }),
  withSpinner(FETCH_COUNTRIES, { i18nKey: 'status:loading-config' }),
  withSpinner(FETCH_CURRENCIES, { i18nKey: 'status:loading-config' }),
)

export default withFetchConfig
