import React, { useState, useEffect, useCallback } from 'react'
import { compose, withProps, lifecycle, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as R from 'ramda'

import Layout from '../../ui/shared/Layout'
import { AuthScope } from '../../services/auth/business'
import { logout } from '../../services/auth'
import {
  ROUTE_BRAND_DASHBOARD,
  ROUTE_BRAND_RESOURCES_SEARCH,
  ROUTE_BRAND_CAMPAIGNS,
  ROUTE_BRAND_DASHBOARD_MATCH,
  ROUTE_BRAND_RESOURCES_MATCH,
  ROUTE_BRAND_CAMPAIGNS_MATCH,
  ROUTE_BRAND_PERSONAL_DATA,
  ROUTE_BRAND_PERSONAL_DATA_MATCH,
  ROUTE_BRAND_CHECKOUT_MATCH,
} from '../common/routes'
import { getBrandInfo } from '../../services/brand'
import { getPhotoURL } from '../../services/photo/selectors'
import { withCart } from '../../services/checkout/hocs'
import Cart from '../brand/Cart'
import withActiveLink from '../common/withActiveLink'
import withPeriodicFetchWallet from '../common/withPeriodicFetchWallet'
import {
  getOrderCancelModalId,
  getOrderReviewCounterbriefingModalId,
  getOrderReviewPredeliveryModalId,
  getOrderReviewDeliveryModalId,
  closeOrderModal,
} from '../../services/orders'
import Modal from '../common/Modal'
import OrderModalCancel from './OrderModalCancel'
import OrderModalReviewCounterbriefing from './OrderModalReviewCounterbriefing'
import OrderModalReviewDelivery from './OrderModalReviewDelivery'
import OrderModalReviewPredelivery from './OrderModalReviewPredelivery'
import {
  getIsCreateCampaignModalOpen,
  closeCreateCampaignModal,
  repeatingFetchCart,
  getIsBillingDataModalOpen,
  closeBillingDataModal,
} from '../../services/checkout'
import CreateCampaignModal from '../checkout/CreateCampaignModal'
import { withWallet } from '../../services/wallet/hocs'
import withBalanceRecharge from '../wallet/withBalanceRecharge'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import BrandBillingForm from './BrandBillingForm'
import withTranslations from '../../hocs/withTranslations'
import AddFundsModal from '../wallet/AddFundsModal'
import { Elements } from 'react-stripe-elements'
import { getNotificationsCount } from '../../services/notifications'
import { withNotifications } from '../../services/notifications/hocs'

const BRAND_NAVIGATION_ITEMS = [
  {
    i18nKey: 'noun:dashboard',
    url: ROUTE_BRAND_DASHBOARD.linkTo(),
    match: ROUTE_BRAND_DASHBOARD_MATCH,
  },
  {
    i18nKey: 'noun:media-influencers',
    url: ROUTE_BRAND_RESOURCES_SEARCH.linkTo(),
    match: ROUTE_BRAND_RESOURCES_MATCH,
  },
  {
    i18nKey: 'noun:campaigns',
    url: ROUTE_BRAND_CAMPAIGNS.linkTo(),
    match: ROUTE_BRAND_CAMPAIGNS_MATCH,
  },
  {
    i18nKey: 'noun:my-profile',
    url: ROUTE_BRAND_PERSONAL_DATA.linkTo(),
    match: ROUTE_BRAND_PERSONAL_DATA_MATCH,
    isMobile: true,
  },
]

const LayoutWithCart = props => {
  const {
    i18n,
    children,
    scope,
    cartItems,
    location,
    handleBalanceRecharge,
    orderCancelModalId,
    orderReviewCounterbriefingModalId,
    orderReviewPredeliveryModalId,
    orderReviewDeliveryModalId,
    handleCloseOrderModal,
    isCreateCampaignModalOpen,
    closeCreateCampaignModal,
    // Billing modal
    myInfo,
    isBillingDataModalOpen,
    closeBillingDataModal,
    // Add funds modal
    isBalanceRechargeModalOpen,
    closeBalanceRechargeModal,
    // Notifications
    notifications,
    isReadingAllNotifications,
    readAllNotifications,
    onNotificationClick,
    notificationCount,
  } = props

  const [isCartOpen, setCartOpen] = useState(false)

  // Close cart when it reaches 0 items
  useEffect(() => {
    if (cartItems === 0) {
      setCartOpen(false)
    }
  }, [scope, cartItems])

  // Open/close handlers
  const handleCartClick = useCallback(
    () => setCartOpen(isCartOpen => !isCartOpen),
    [setCartOpen],
  )

  const handleCloseCart = useCallback(() => setCartOpen(false), [setCartOpen])

  const isCheckoutPage = ROUTE_BRAND_CHECKOUT_MATCH.test(location.pathname)

  // Render
  return (
    <Layout
      {...props}
      onCartClick={handleCartClick}
      isLight={isCheckoutPage}
      onBalanceAction={handleBalanceRecharge}
      notifications={notifications}
      onNotificationClick={onNotificationClick}
      onMarkAllClick={readAllNotifications}
      isMarkAllLoading={isReadingAllNotifications}
      numberAlerts={notificationCount}
    >
      {children}

      <Cart isOpen={isCartOpen} handleClose={handleCloseCart} />

      {!R.isNil(orderCancelModalId) && (
        <Modal onClose={handleCloseOrderModal}>
          <OrderModalCancel orderId={orderCancelModalId} />
        </Modal>
      )}

      {!R.isNil(orderReviewCounterbriefingModalId) && (
        <Modal onClose={handleCloseOrderModal}>
          <OrderModalReviewCounterbriefing
            orderId={orderReviewCounterbriefingModalId}
          />
        </Modal>
      )}

      {!R.isNil(orderReviewPredeliveryModalId) && (
        <Modal onClose={handleCloseOrderModal}>
          <OrderModalReviewPredelivery
            orderId={orderReviewPredeliveryModalId}
          />
        </Modal>
      )}

      {!R.isNil(orderReviewDeliveryModalId) && (
        <Modal onClose={handleCloseOrderModal}>
          <OrderModalReviewDelivery orderId={orderReviewDeliveryModalId} />
        </Modal>
      )}

      {isCreateCampaignModalOpen && (
        <Modal onClose={closeCreateCampaignModal}>
          <CreateCampaignModal />
        </Modal>
      )}

      {isBillingDataModalOpen && (
        <Modal onClose={closeBillingDataModal} closeOnClickOutside={false}>
          <ModalSimpleContent>
            <div className="brand-billing-modal">
              <ModalHeader
                title={i18n('checkout:billing-form-title')}
                tagline={i18n('checkout:billing-form-tagline')}
              />
              <BrandBillingForm brandInfo={myInfo} />
            </div>
          </ModalSimpleContent>
        </Modal>
      )}

      {isBalanceRechargeModalOpen && (
        <Modal onClose={closeBalanceRechargeModal}>
          <Elements>
            <AddFundsModal />
          </Elements>
        </Modal>
      )}
    </Layout>
  )
}

const enhance = compose(
  withTranslations,
  withRouter,

  connect(
    state => ({
      myInfo: getBrandInfo(state),
      userAvatar: getPhotoURL(state),
      notificationCount: getNotificationsCount(state),

      orderCancelModalId: getOrderCancelModalId(state),
      orderReviewCounterbriefingModalId: getOrderReviewCounterbriefingModalId(
        state,
      ),
      orderReviewPredeliveryModalId: getOrderReviewPredeliveryModalId(state),
      orderReviewDeliveryModalId: getOrderReviewDeliveryModalId(state),
      isCreateCampaignModalOpen: getIsCreateCampaignModalOpen(state),
      isBillingDataModalOpen: getIsBillingDataModalOpen(state),
    }),
    {
      onLogout: logout,
      handleCloseOrderModal: closeOrderModal,
      closeCreateCampaignModal,
      closeBillingDataModal,
    },
  ),

  withPropsOnChange(['myInfo'], ({ myInfo }) => {
    if (!myInfo) {
      return {
        isPublic: true,
        navItems: [],
        userName: undefined,
        useProfile: undefined,
        scope: undefined,
      }
    }

    return {
      isPublic: false,
      isMedia: false,
      navItems: BRAND_NAVIGATION_ITEMS,
      userName: myInfo.name,
      userProfile: 'user-dropdown:profile-brand',
      scope: AuthScope.BRAND,
    }
  }),

  withActiveLink,
  withNotifications,
  withWallet,
  withPeriodicFetchWallet,

  // Add cart for header
  compose(
    withCart,
    withProps(({ cart }) => ({
      cartItems: cart && cart.items ? cart.items.length : undefined,
    })),
    connect(
      null,
      {
        repeatingFetchCart,
      },
    ),
    lifecycle({
      componentDidMount() {
        this.props.repeatingFetchCart()
      },
    }),
  ),

  withBalanceRecharge,
)

export default enhance(LayoutWithCart)
