import React from 'react'
import PropTypes from 'prop-types'

import { getFiltersWithout } from './filterUtils'

import { MultiDropdownList } from '@appbaseio/reactivesearch'
import MultiSelectSearchable from './MultiSelectSearchable'

import { useMemo } from 'react'

const ReactiveMultiSelect = ({
  componentId,
  dataField,
  nestedField,
  placeholder,
  URLParams,
  react,
  innerClass,
  allData,
  translateKey,
  extraFilter,
  ...props
}) => {
  const makeDefaultQuery = useMemo(
    () =>
      rangeBooleanAggregationQueryFactory(dataField, extraFilter, nestedField),
    [dataField, extraFilter, nestedField],
  )

  const makeCustomQuery = useMemo(
    () => rangeBooleanQueryFactory(dataField, extraFilter, nestedField),
    [dataField, extraFilter, nestedField],
  )

  return (
    <MultiDropdownList
      componentId={componentId}
      dataField={dataField}
      nestedField={nestedField}
      URLParams={true}
      defaultQuery={makeDefaultQuery}
      customQuery={makeCustomQuery}
      react={{ and: getFiltersWithout(componentId) }}
      innerClass={{ select: 'filter-group-multiselect' }}
      {...props}
      render={props => {
        return (
          <MultiSelectSearchable
            placeholder={placeholder}
            allData={allData}
            translateKey={translateKey}
            {...props}
          />
        )
      }}
    />
  )
}

const rangeBooleanQueryFactory = (
  dataField,
  extraFilter,
  nestedField,
) => values => {
  if (values && values.length === 0) {
    return {}
  }

  const query = extraFilter
    ? {
        bool: {
          must: [{ terms: { [dataField]: values } }, { ...extraFilter }],
        },
      }
    : { bool: { must: [{ terms: { [dataField]: values } }] } }

  return nestedField
    ? { query: { nested: { path: nestedField, query } } }
    : { query }
}

const rangeBooleanAggregationQueryFactory = (
  dataField,
  extraFilter,
  nestedField = undefined,
) => () => {
  const aggs = extraFilter
    ? {
        reactivesearch_nested_filter: {
          filter: { ...extraFilter },
          aggs: {
            [dataField]: { terms: { field: dataField, size: 250 } },
          },
        },
      }
    : { [dataField]: { terms: { field: dataField, size: 250 } } }

  return nestedField
    ? {
        size: 0,
        aggs: {
          reactivesearch_nested: { nested: { path: nestedField }, aggs },
        },
      }
    : { size: 0, aggs }
}

ReactiveMultiSelect.propTypes = {
  componentId: PropTypes.string.isRequired,
  dataField: PropTypes.string.isRequired,
  nestedField: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
}

export default ReactiveMultiSelect
