import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest, postRequest, putRequest, deleteRequest } from '../api-call'
import { i18nErrorKeys, i18nValidationKeys } from '../../app/common/validation'
import { parseResponseErrors } from '../error-handling'
import { AuthScope } from './business'

const SESSION_ENDPOINT = `${envConfig.authBackendUrl}/session`

const changePasswordEndpoint = scope =>
  `${envConfig.authBackendUrl}/${scope}/change-password`

const deleteUserEndpoint = scope =>
  `${envConfig.usersBackendUrl}/${scope}/cancellation`

const DEFAULT_OPTIONS = {
  credentials: 'include',
}

const MAP_GLOBAL_ERRORS_LOGIN = {
  UserNotFoundException: values => ({
    key: i18nErrorKeys.USER_NOT_FOUND,
    values,
  }),
  invalid_scope: values => ({
    key: i18nErrorKeys.UNKNOWN,
    values,
  }),
  invalid_request: values => ({
    key: i18nErrorKeys.UNKNOWN,
    values,
  }),
  ReCaptchaException: values => ({
    key: i18nErrorKeys.RECAPTCHA_FAILED_ERROR,
    values,
  }),
}

export function* login(username, password, recaptcha, recaptchaAction) {
  const response = yield call(
    postRequest,
    SESSION_ENDPOINT,
    {
      username,
      password,
      recaptcha,
      recaptchaAction,
    },
    { ...DEFAULT_OPTIONS, parseJSON: true, ignore401: true },
  )

  // Handle case where email+password is registered for both brand and media
  if (
    response.error &&
    response.type === 'status' &&
    response.errorData &&
    response.errorData.global_messages &&
    response.errorData.global_messages.UserFoundMultipleTimesException
  ) {
    return { needsScope: true }
  }

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_LOGIN,
  })

  return parsedResponse
}

export function* loginWithScope(
  username,
  password,
  scope,
  recaptcha,
  recaptchaAction,
) {
  const response = yield call(
    postRequest,
    SESSION_ENDPOINT,
    {
      username,
      password,
      scope,
      recaptcha,
      recaptchaAction,
    },
    { ...DEFAULT_OPTIONS, parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_LOGIN,
  })

  return parsedResponse
}

export function* logout() {
  const response = yield call(
    deleteRequest,
    SESSION_ENDPOINT,
    { scope: 'user' },
    DEFAULT_OPTIONS,
  )

  // Ignore error if session not found
  // In practice it doesn't matter since we're logging out
  if (
    response.error &&
    response.type === 'status' &&
    response.errorData &&
    response.errorData.global_messages &&
    response.errorData.global_messages.SessionNotFoundException
  ) {
    return {}
  }

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* checkToken() {
  const response = yield call(
    getRequest,
    `${SESSION_ENDPOINT}?scope=user`,

    { ...DEFAULT_OPTIONS, parseJSON: true },
  )

  // Ignore error if session not found
  // In practice it doesn't matter since we're only checking if we're logged-in
  if (
    response.error &&
    response.type === 'status' &&
    response.errorData &&
    response.errorData.global_messages &&
    response.errorData.global_messages.SessionNotFoundException
  ) {
    return {}
  }

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_REFRESH_TOKEN = {
  SessionNotFoundException: values => ({
    key: i18nErrorKeys.COULD_NOT_REFRESH_SESSION,
    values,
  }),
  invalid_request: values => ({
    key: i18nErrorKeys.COULD_NOT_REFRESH_SESSION,
    values,
  }),
}

export function* refreshToken() {
  const response = yield call(
    putRequest,
    SESSION_ENDPOINT,
    { scope: 'user' },
    { ...DEFAULT_OPTIONS, parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_REFRESH_TOKEN,
  })

  return parsedResponse
}

const MAP_VALIDATION_ERRORS_CHANGE_PASSWORD = {
  eq: values => ({
    key: i18nValidationKeys.PASSWORDS_DO_NOT_MATCH,
    values: {},
  }),
}

const MAP_GLOBAL_ERRORS_CHANGE_PASSWORD = {
  UserNotFoundException: values => ({
    key: i18nErrorKeys.WRONG_PASSWORD,
    values,
  }),
}

export function* changePassword(scope, oldPassword, password, confirmPassword) {
  const response = yield call(
    postRequest,
    changePasswordEndpoint(scope),
    { oldPassword, password, confirmPassword },
    { parseJSON: false },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapValidation: MAP_VALIDATION_ERRORS_CHANGE_PASSWORD,
    mapGlobal: MAP_GLOBAL_ERRORS_CHANGE_PASSWORD,
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_DELETE_USER = {
  [AuthScope.BRAND]: {
    BrandNotFoundException: values => ({
      key: i18nErrorKeys.BRAND_NOT_FOUND,
      values,
    }),
    BrandCancellationAlreadyExistsException: values => ({
      key: i18nErrorKeys.BRAND_NOT_FOUND,
      values,
    }),
  },
  [AuthScope.MEDIA]: {
    MediaNotFoundException: values => ({
      key: i18nErrorKeys.MEDIA_NOT_FOUND,
      values,
    }),
    MediaCancellationAlreadyExistsException: values => ({
      key: i18nErrorKeys.MEDIA_NOT_FOUND,
      values,
    }),
  },
}

export function* deleteUser(scope, reason) {
  const response = yield call(
    postRequest,
    deleteUserEndpoint(scope),
    { reason },
    { parseJSON: false },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_DELETE_USER[scope],
  })

  return parsedResponse
}
