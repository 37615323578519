import { connect } from 'react-redux'
import { compose } from 'recompose'
import * as Yup from 'yup'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { acceptOrderReviewWithChanges } from '../../services/orders'
import { i18nValidation } from '../common/validation'
import { ACCEPT_ORDER_REVIEW_WITH_CHANGES } from '../../services/orders/action-types'
import withRequestError from '../../hocs/withRequestError'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withFormErrors from '../../hocs/withFormErrors'
import OrderModalReviewOrderAcceptWithChanges from '../../ui/order-modals/OrderModalReviewOrderAcceptWithChanges'

const validationSchema = Yup.object().shape({
  comments: Yup.string().required(i18nValidation.required),
})

const enhance = compose(
  connect(
    null,
    { acceptOrderReviewWithChanges },
  ),

  withIsRequestPending(ACCEPT_ORDER_REVIEW_WITH_CHANGES),
  withRequestError(ACCEPT_ORDER_REVIEW_WITH_CHANGES),
  withDeleteRequestOnUnmount(ACCEPT_ORDER_REVIEW_WITH_CHANGES),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      comments: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.acceptOrderReviewWithChanges(props.orderId, values.comments)
    },
  }),

  withFormErrors(['comments']),
)

export default enhance(OrderModalReviewOrderAcceptWithChanges)
