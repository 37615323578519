import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isNilOrEmpty } from '../../app/utils'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import AlertsIndicator from '../alerts/AlertsIndicator'
import NotificationsCard from '../cards/NotificationsCard'
import NoNotificationsCard from '../cards/NoNotificationsCard'

const NotificationsDropdown = ({
  notifications,
  numberAlerts,
  isMarkAllLoading,
  onMarkAllClick,
  onNotificationClick,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const classes = cx('notifications-dropdown', {
    'is-collapsed': isCollapsed,
  })

  // Toggle collapsed state when clicking inside
  const toggleState = useCallback(
    () => setIsCollapsed(isCollapsed => !isCollapsed),
    [],
  )

  // Close dropdown when clicking outside
  const dropdownRef = useRef()
  const collapse = useCallback(() => setIsCollapsed(true), [])
  useOnClickOutside(dropdownRef, collapse)

  const onMarkAllClickCloseDropdown = useCallback(() => {
    onMarkAllClick()
    setIsCollapsed(true)
  }, [onMarkAllClick])

  const onNotificationClickCloseDropdown = useCallback(
    notification => {
      onNotificationClick(notification)
      setIsCollapsed(true)
    },
    [onNotificationClick],
  )

  return (
    <div className={classes} ref={dropdownRef}>
      <AlertsIndicator onClick={toggleState} numberAlerts={numberAlerts} />
      <div className="notifications-dropdown-content">
        {!isNilOrEmpty(notifications) ? (
          <NotificationsCard
            notifications={notifications}
            isMarkAllLoading={isMarkAllLoading}
            onMarkAllClick={onMarkAllClickCloseDropdown}
            onNotificationClick={onNotificationClickCloseDropdown}
            hasTitle
          />
        ) : (
          <NoNotificationsCard />
        )}
      </div>
    </div>
  )
}

NotificationsDropdown.propTypes = {
  notifications: PropTypes.array,
  numberAlerts: PropTypes.number,
  isLoading: PropTypes.bool,
}

export default NotificationsDropdown
