import React from 'react'
import PropTypes from 'prop-types'

const DropdownText = props => {
  return <p className="dropdown-text">{props.text}</p>
}

DropdownText.propTypes = {
  text: PropTypes.string,
}

export default DropdownText
