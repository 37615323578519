import { compose, withPropsOnChange, branch } from 'recompose'
import withResourceURLParams from './withResourceURLParams'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import withEditResource from './withEditResource'
import { withReachedWebResourceStep } from '../../services/resources/hocs'

const withWebResource = currentStep =>
  compose(
    withResourceURLParams,
    withPropsOnChange([], () => ({ channel: RESOURCE_TYPES.WEB })),
    branch(({ isEditing }) => isEditing, withEditResource),
    withReachedWebResourceStep(currentStep),
  )

export default withWebResource
