import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withTranslations from '../../hocs/withTranslations'
import { MediaQueryLap, MediaQueryPalm } from '../shared/MediaQueries'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import Button from '@redradix/components.button'
import IconButton from '../buttons/IconButton.jsx'
import { ReactComponent as Download2 } from './../../styles/icons/download-2.svg'

const NotificationsDropdown = ({ children, i18n }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const classes = cx('reports-download-dropdown', {
    'is-collapsed': isCollapsed,
  })

  // Toggle collapsed state when clicking inside
  const toggleState = useCallback(
    () => setIsCollapsed(isCollapsed => !isCollapsed),
    [],
  )

  // Close dropdown when clicking outside
  const dropdownRef = useRef()
  const collapse = useCallback(() => setIsCollapsed(true), [])
  useOnClickOutside(dropdownRef, collapse)

  return (
    <div className={classes} ref={dropdownRef}>
      <MediaQueryPalm>
        <IconButton
          type="button"
          onClick={toggleState}
          className="reports-download-dropdown-trigger"
        >
          <Download2 className="icon rdx-button-icon" />
        </IconButton>
      </MediaQueryPalm>
      <MediaQueryLap>
        <Button
          onClick={toggleState}
          className="reports-download-dropdown-trigger"
        >
          <span>{i18n('noun:report')}</span>
          <Download2 className="icon rdx-button-icon" />
        </Button>
      </MediaQueryLap>
      <div className="reports-download-dropdown-content">{children}</div>
    </div>
  )
}

NotificationsDropdown.propTypes = {
  children: PropTypes.any,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(NotificationsDropdown)
