import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import cx from 'classnames'
import { ReactComponent as IconDots } from '../../styles/icons/ellipsis.svg'

const SortDrop = ({
  isCollapsed,
  setIsCollapsed,
  children,
  isSelected,
  i18n,
}) => {
  const classes = cx('sort-drop', {
    'is-collapsed': isCollapsed,
  })
  const toggleClasses = cx('sort-item', {
    'is-selected': isSelected,
  })

  // Close dropdown when clicking outside
  const dropdownRef = useRef()

  useOnClickOutside(dropdownRef, () => setIsCollapsed(true))

  return (
    <div className={classes} ref={dropdownRef}>
      <button
        className={toggleClasses}
        title={i18n('action:show-more')}
        onClick={() => setIsCollapsed(isCollapsed => !isCollapsed)}
      >
        <IconDots />
      </button>
      <div className="sort-drop-content">{children}</div>
    </div>
  )
}

SortDrop.propTypes = {
  isSelected: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(SortDrop)
