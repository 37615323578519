import React from 'react'
import PropTypes from 'prop-types'
import Button from '@redradix/components.button'
import * as R from 'ramda'
import {
  ORDER_STATUS,
  mapOrderActionToI18n,
  mapOrderActionInDetailToI18n,
  getBrandOrderAction,
  getMediaOrderAction,
  WAIT_ORDER_ACTIONS,
  DESTRUCTIVE_ORDER_ACTIONS,
} from '../../services/orders/business'
import { AuthScope } from '../../services/auth/business'
import withTranslations from '../../hocs/withTranslations'

const OrderActionButton = ({
  scope,
  orderStatus,
  hasPreDelivery,
  onClick,
  isOrderDetail,
  i18n,
}) => {
  const action =
    scope === AuthScope.BRAND
      ? getBrandOrderAction(orderStatus, hasPreDelivery)
      : scope === AuthScope.MEDIA
      ? getMediaOrderAction(orderStatus, hasPreDelivery)
      : undefined

  if (R.isNil(action)) {
    return null
  }

  return (
    <Button
      type="button"
      hierarchy="primary"
      widthBehaviour="full"
      variant={
        DESTRUCTIVE_ORDER_ACTIONS.includes(action) ? 'destruct' : undefined
      }
      isDisabled={WAIT_ORDER_ACTIONS.includes(action)}
      onClick={e => {
        onClick(action)
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      {i18n(
        isOrderDetail
          ? mapOrderActionInDetailToI18n[action]
          : mapOrderActionToI18n[action],
      )}
    </Button>
  )
}

OrderActionButton.propTypes = {
  scope: PropTypes.oneOf([AuthScope.BRAND, AuthScope.MEDIA]).isRequired,
  orderStatus: PropTypes.oneOf(Object.values(ORDER_STATUS)).isRequired,
  hasPreDelivery: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isOrderDetail: PropTypes.bool,
}

export default withTranslations(OrderActionButton)
