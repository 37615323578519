import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as OrderIcon } from '../../styles/icons/ordenation.svg'

const OrderSort = ({ text, isActiveAsc, isActiveDesc, onSort }) => {
  const classes = cx('order-sort', {
    'is-active-asc': isActiveAsc,
    'is-active-desc': isActiveDesc,
  })
  return (
    <button className={classes} onClick={onSort}>
      {text}
      <OrderIcon className="order-sort-icon" />
    </button>
  )
}

OrderSort.propTypes = {
  text: PropTypes.string.isRequired,
  isActiveAsc: PropTypes.bool,
  isActiveDesc: PropTypes.bool,
  onSort: PropTypes.func,
}

export default OrderSort
