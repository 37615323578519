import * as R from 'ramda'
import { getFromPath } from 'reduken/hash'
import {
  DOMAIN,
  HASH_KEY_REQUEST_STATUS,
  REQUEST_EMPTY_STATUS,
  REQUEST_STATUS,
} from './constants'

export const getRequestStatus = R.curry(
  (requestType, state) =>
    getFromPath(DOMAIN, `${HASH_KEY_REQUEST_STATUS}_${requestType}`, state) ||
    REQUEST_EMPTY_STATUS,
)

export const getIsRequestPending = R.curry(
  (requestType, state) =>
    getRequestStatus(requestType, state).status === REQUEST_STATUS.PENDING,
)

export const getIsRequestSucceeded = R.curry(
  (requestType, state) =>
    getRequestStatus(requestType, state).status === REQUEST_STATUS.SUCCESS,
)

export const getRequestError = R.curry(
  (requestType, state) => getRequestStatus(requestType, state).error,
)

export const getHasRequestError = R.curry(
  (requestType, state) => !R.isNil(getRequestError(requestType, state)),
)

// With id

export const getIsRequestWithIdPending = R.curry((requestType, id, state) => {
  return getIsRequestPending(`${requestType}_${id}`, state)
})

export const getIsRequestWithIdSucceeded = R.curry((requestType, id, state) =>
  getIsRequestSucceeded(`${requestType}_${id}`, state),
)

export const getRequestWithIdError = R.curry((requestType, id, state) =>
  getRequestError(`${requestType}_${id}`, state),
)

export const getHasRequestWithIdError = R.curry((requestType, id, state) =>
  getHasRequestError(`${requestType}_${id}`, state),
)
