import * as actionTypes from './action-types'
import {
  all,
  call,
  takeLatest,
  put,
  spawn,
  fork,
  take,
  cancel,
  delay,
} from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import { push } from 'connected-react-router'
import {
  DOMAIN,
  HASH_KEY_TOKEN,
  HASH_KEY_NEEDS_SCOPE,
  HASH_KEY_SCOPE,
} from './constants'
import { newToken, deleteToken, refreshToken, logoutComplete } from './actions'
import { fetchMyInfo } from '../me'
import { fetchMyTours } from '../tour'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
  deleteRequest,
} from '../communication/actions'
import * as api from './api'
import { addErrorToast, addWarningToast, addSuccessToast } from '../toasts'
import { ROUTE_ROOT } from '../../app/common/routes'
import {
  TOAST_TRANSITION_MSECS,
  TOAST_AUTO_CLOSE_MSECS,
} from '../toasts/constants'

const REFRESH_MARGIN_SECONDS =
  parseInt(process.env.REACT_APP_TOKEN_REFRESH_MARGIN_SECONDS, 10) || 60

function* loginSaga({
  payload: { username, password, recaptcha, recaptchaAction },
}) {
  yield put(deleteToken())
  yield put(deleteRequest(actionTypes.LOGIN_WITH_SCOPE)) // delete any errors from logging in with scope
  yield put(hash.remove(DOMAIN, HASH_KEY_NEEDS_SCOPE))
  yield put(startRequest(actionTypes.LOGIN))

  const response = yield call(
    api.login,
    username,
    password,
    recaptcha,
    recaptchaAction,
  )

  if (response.needsScope) {
    yield put(hash.set(DOMAIN, HASH_KEY_NEEDS_SCOPE, true))
    yield put(endRequestSuccess(actionTypes.LOGIN))
    return
  }

  if (response.error) {
    yield put(endRequestError(actionTypes.LOGIN, response))
    return
  }

  yield put(
    newToken(
      response.data.access_token,
      response.data.scope,
      response.data.expires_in,
    ),
  )

  yield put(fetchMyInfo())
  yield put(fetchMyTours())
  yield put(endRequestSuccess(actionTypes.LOGIN))
}

function* loginWithScopeSaga({
  payload: { username, password, scope, recaptcha, recaptchaAction },
}) {
  yield put(deleteToken())
  yield put(startRequest(actionTypes.LOGIN_WITH_SCOPE))

  const response = yield call(
    api.loginWithScope,
    username,
    password,
    scope,
    recaptcha,
    recaptchaAction,
  )

  if (response.error) {
    yield put(endRequestError(actionTypes.LOGIN_WITH_SCOPE, response))
    yield put(hash.remove(DOMAIN, HASH_KEY_NEEDS_SCOPE))
    return
  }

  yield put(
    newToken(
      response.data.access_token,
      response.data.scope,
      response.data.expires_in,
    ),
  )
  yield put(hash.remove(DOMAIN, HASH_KEY_NEEDS_SCOPE))
  yield put(fetchMyInfo())
  yield put(fetchMyTours())
  yield put(endRequestSuccess(actionTypes.LOGIN_WITH_SCOPE))
}

function* logoutSaga() {
  yield put(startRequest(actionTypes.LOGOUT))

  const response = yield call(api.logout)

  if (response.error) {
    yield put(endRequestError(actionTypes.LOGOUT, response))
    yield put(addErrorToast('errors:logout-title', 'errors:logout-description'))
    return
  }

  yield put(deleteToken())
  yield put(endRequestSuccess(actionTypes.LOGOUT))
  yield put(push(ROUTE_ROOT.linkTo()))
  yield put(logoutComplete())
}

function* checkTokenSaga() {
  yield put(deleteToken())
  yield put(hash.remove(DOMAIN, HASH_KEY_NEEDS_SCOPE))
  yield put(startRequest(actionTypes.CHECK_TOKEN))

  const response = yield call(api.checkToken)

  if (response.error) {
    yield put(endRequestError(actionTypes.CHECK_TOKEN, response))
    yield put(
      addWarningToast(
        'warning:check-token-title',
        'warning:check-token-description',
      ),
    )
    return
  }

  // If we got a token from API, save it
  if (response.data) {
    yield put(
      newToken(
        response.data.access_token,
        response.data.scope,
        response.data.expires_in,
      ),
    )
    yield put(fetchMyInfo())
  }

  yield put(endRequestSuccess(actionTypes.CHECK_TOKEN))
}

function* refreshTokenSaga() {
  yield put(startRequest(actionTypes.REFRESH_TOKEN))

  const response = yield call(api.refreshToken)

  if (response.error) {
    yield put(endRequestError(actionTypes.REFRESH_TOKEN, response))
    yield put(
      addErrorToast(
        'errors:refresh-token-title',
        'errors:refresh-token-description',
      ),
    )
    return
  }

  yield put(
    newToken(
      response.data.access_token,
      response.data.scope,
      response.data.expires_in,
    ),
  )
  yield put(endRequestSuccess(actionTypes.REFRESH_TOKEN))
}

function* handleTokenSaga() {
  let refreshTask = undefined

  while (true) {
    const action = yield take([actionTypes.NEW_TOKEN, actionTypes.DELETE_TOKEN])

    // Cancel scheduled token refresh (if any)
    if (refreshTask) {
      yield cancel(refreshTask)
      refreshTask = undefined
    }

    switch (action.type) {
      // We've got a new token: schedule token refresh
      case actionTypes.NEW_TOKEN: {
        const {
          payload: { token, scope, expiresIn },
        } = action

        yield put(hash.set(DOMAIN, HASH_KEY_TOKEN, token))
        yield put(hash.set(DOMAIN, HASH_KEY_SCOPE, scope))

        refreshTask = yield spawn(refreshTokenTask, expiresIn)

        break
      }

      // Token has been deleted
      case actionTypes.DELETE_TOKEN: {
        yield put(hash.remove(DOMAIN, HASH_KEY_TOKEN))
        yield put(hash.remove(DOMAIN, HASH_KEY_SCOPE))
        break
      }

      default:
        throw new Error('Unreachable')
    }
  }
}

function* refreshTokenTask(expiresIn) {
  const nextUpdate = Math.max(expiresIn - REFRESH_MARGIN_SECONDS, 0)

  yield delay(nextUpdate * 1000)

  // Do not try refreshing token if we're offline
  if (!navigator.onLine) {
    return
  }

  yield put(refreshToken())
}

function* changePasswordSaga({
  payload: { scope, oldPassword, password, confirmPassword },
}) {
  yield put(startRequest(actionTypes.CHANGE_PASSWORD))

  const response = yield call(
    api.changePassword,
    scope,
    oldPassword,
    password,
    confirmPassword,
  )

  if (response.error) {
    yield put(endRequestError(actionTypes.CHANGE_PASSWORD, response))
    return
  }

  yield put(endRequestSuccess(actionTypes.CHANGE_PASSWORD))
}

function* deleteUserSaga({ payload: { scope, reason } }) {
  yield put(startRequest(actionTypes.DELETE_USER))

  const response = yield call(api.deleteUser, scope, reason)

  if (response.error) {
    yield put(endRequestError(actionTypes.DELETE_USER, response))
    return
  }

  yield put(endRequestSuccess(actionTypes.DELETE_USER))

  // Show confirmation toast
  yield put(
    addSuccessToast(
      'profile:delete-toast-title',
      'profile:delete-toast-description',
    ),
  )

  // Wait for toast to close
  yield delay(TOAST_AUTO_CLOSE_MSECS + TOAST_TRANSITION_MSECS * 4)

  // Logout
  yield put(logoutComplete())
}

export default function*() {
  yield all([
    takeLatest(actionTypes.LOGIN, loginSaga),
    takeLatest(actionTypes.LOGIN_WITH_SCOPE, loginWithScopeSaga),
    takeLatest(actionTypes.LOGOUT, logoutSaga),
    takeLatest(actionTypes.CHECK_TOKEN, checkTokenSaga),
    takeLatest(actionTypes.REFRESH_TOKEN, refreshTokenSaga),
    fork(handleTokenSaga),
    takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordSaga),
    takeLatest(actionTypes.DELETE_USER, deleteUserSaga),
  ])
}
