import { compose } from 'recompose'
import * as Yup from 'yup'
import * as R from 'ramda'
import { connect } from 'react-redux'
import OrderModalReviewPredeliveryApprove from '../../ui/order-modals/OrderModalReviewPredeliveryApprove'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import { approveOrderPredelivery } from '../../services/orders'
import { APPROVE_ORDER_PREDELIVERY } from '../../services/orders/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { isNilOrEmpty } from '../utils'

const validationSchema = Yup.object().shape({
  comments: Yup.string(),
})

const enhance = compose(
  connect(
    null,
    {
      approveOrderPredelivery,
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      comments: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSubmit = R.filter(R.complement(isNilOrEmpty), values)
      props.approveOrderPredelivery(props.orderId, valuesToSubmit.comments)
    },
  }),

  withIsRequestPending(APPROVE_ORDER_PREDELIVERY),
  withIsRequestSucceeded(APPROVE_ORDER_PREDELIVERY),
  withRequestError(APPROVE_ORDER_PREDELIVERY),
  withDeleteRequestOnUnmount(APPROVE_ORDER_PREDELIVERY),

  withFormErrors(['comments']),
)

export default enhance(OrderModalReviewPredeliveryApprove)
