import React from 'react'
import PropTypes from 'prop-types'

const TooltipSubtitle = props => {
  return <p className="tooltip-subtitle">{props.text}</p>
}

TooltipSubtitle.propTypes = {
  text: PropTypes.string,
}

export default TooltipSubtitle
