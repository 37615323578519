import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as Star } from '../../styles/icons/star.svg'

const RatingButton = ({
  isSelected,
  isHovered,
  isDisabled,
  onClick,
  onMouseEnter,
}) => {
  const classes = cx('rating-button', {
    'is-selected': isSelected,
    'is-hovered': isHovered,
  })
  return (
    <button
      type="button"
      className={classes}
      disabled={isDisabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <Star className="rating-star" />
    </button>
  )
}

RatingButton.propTypes = {
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isHovered: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
}

export default RatingButton
