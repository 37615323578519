import { RESOURCE_TYPES } from '../resources/constants'

export const ORDER_STATUS = {
  DRAFT: 'DRAFT',

  REQUESTED: 'REQUESTED',
  CANCELED: 'CANCELLED',
  REJECTED: 'REJECTED',

  // counterbriefing
  PENDING: 'PENDING',

  // in progress
  ACCEPTED: 'ACCEPTED',

  // pre delivery
  IN_REVIEW: 'IN_REVIEW',
  REVIEWED_NOK: 'REVIEWED_NOK',
  REVIEWED: 'REVIEWED',

  // final delivery
  DELIVERED: 'DELIVERED',
  DELIVERED_NOK: 'DELIVERED_NOK',
  APPROVED: 'APPROVED',
}

export const HISTORY_ACTIONS = {
  // After checkout
  REQUEST: 'request',

  // Counterbriefing
  REQUEST_BRIEFING: 'request-briefing',
  ACCEPT_BRIEFING: 'accept-briefing',
  REJECT_BRIEFING: 'reject-briefing',

  // Review order
  ACCEPT: 'accept',
  REJECT: 'reject',
  CANCEL: 'cancel',

  // Pre-delivery
  SEND_REVIEW: 'send-review',
  REJECT_REVIEW: 'reject-review',
  FIX_REVIEW: 'fix-review',
  ACCEPT_REVIEW: 'accept-review',

  // Final delivery
  SEND_DELIVERY: 'send-delivery',
  REJECT_DELIVERY: 'reject-delivery',
  FIX_DELIVERY: 'fix-delivery',
  APPROVE_DELIVERY: 'approve-delivery',

  //reactivate
  REACTIVATE_TO_REQUEST: 'reactivate-to-request',
  REACTIVATE_TO_ACCEPT: 'reactivate-to-accept',
  REACTIVATE_TO_PENDING: 'reactivate-to-pending',
  REACTIVATE_TO_REVIEW: 'reactivate-to-review',
}

export const BRAND_ORDER_ACTIONS = {
  CANCEL: 'CANCEL',
  REVIEW_COUNTERBRIEFING: 'REVIEW_COUNTERBRIEFING',
  WAIT_MEDIA_SEND_PREDELIVERY: 'WAIT_MEDIA_SEND_PREDELIVERY',
  REVIEW_PREDELIVERY: 'REVIEW_PREDELIVERY',
  WAIT_MEDIA_FIX_PREDELIVERY: 'WAIT_MEDIA_FIX_PREDELIVERY',
  WAIT_MEDIA_SEND_DELIVERY: 'WAIT_MEDIA_SEND_DELIVERY',
  REVIEW_DELIVERY: 'REVIEW_DELIVERY',
  WAIT_MEDIA_FIX_DELIVERY: 'WAIT_MEDIA_FIX_DELIVERY',
}

export const getBrandOrderAction = (status, hasPreDelivery) => {
  switch (status) {
    case ORDER_STATUS.REQUESTED: {
      return BRAND_ORDER_ACTIONS.CANCEL
    }

    case ORDER_STATUS.PENDING: {
      return BRAND_ORDER_ACTIONS.REVIEW_COUNTERBRIEFING
    }

    case ORDER_STATUS.ACCEPTED: {
      return hasPreDelivery
        ? BRAND_ORDER_ACTIONS.WAIT_MEDIA_SEND_PREDELIVERY
        : BRAND_ORDER_ACTIONS.WAIT_MEDIA_SEND_DELIVERY
    }

    case ORDER_STATUS.REVIEWED: {
      return BRAND_ORDER_ACTIONS.WAIT_MEDIA_SEND_DELIVERY
    }

    case ORDER_STATUS.IN_REVIEW: {
      return BRAND_ORDER_ACTIONS.REVIEW_PREDELIVERY
    }

    case ORDER_STATUS.REVIEWED_NOK: {
      return BRAND_ORDER_ACTIONS.WAIT_MEDIA_FIX_PREDELIVERY
    }

    case ORDER_STATUS.DELIVERED_NOK: {
      return BRAND_ORDER_ACTIONS.WAIT_MEDIA_FIX_DELIVERY
    }

    case ORDER_STATUS.DELIVERED: {
      return BRAND_ORDER_ACTIONS.REVIEW_DELIVERY
    }

    case ORDER_STATUS.DRAFT:
    case ORDER_STATUS.CANCELED:
    case ORDER_STATUS.REJECTED:
    case ORDER_STATUS.APPROVED:
    default: {
      return null
    }
  }
}

export const MEDIA_ORDER_ACTIONS = {
  REVIEW_ORDER: 'REVIEW_ORDER', // accept, reject, counterbrief
  WAIT_BRAND_REVIEW_COUNTERBRIEFING: 'WAIT_BRAND_REVIEW_COUNTERBRIEFING',
  SEND_PREDELIVERY: 'SEND_PREDELIVERY',
  WAIT_BRAND_REVIEW_PREDELIVERY: 'WAIT_BRAND_REVIEW_PREDELIVERY',
  FIX_PREDELIVERY: 'FIX_PREDELIVERY',
  SEND_DELIVERY: 'SEND_DELIVERY',
  WAIT_BRAND_REVIEW_DELIVERY: 'WAIT_BRAND_REVIEW_DELIVERY',
  FIX_DELIVERY: 'FIX_DELIVERY',
}

export const getMediaOrderAction = (status, hasPreDelivery) => {
  switch (status) {
    case ORDER_STATUS.REQUESTED: {
      return MEDIA_ORDER_ACTIONS.REVIEW_ORDER
    }

    case ORDER_STATUS.PENDING: {
      return MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_COUNTERBRIEFING
    }

    case ORDER_STATUS.ACCEPTED: {
      return hasPreDelivery
        ? MEDIA_ORDER_ACTIONS.SEND_PREDELIVERY
        : MEDIA_ORDER_ACTIONS.SEND_DELIVERY
    }

    case ORDER_STATUS.REVIEWED: {
      return MEDIA_ORDER_ACTIONS.SEND_DELIVERY
    }

    case ORDER_STATUS.IN_REVIEW: {
      return MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_PREDELIVERY
    }

    case ORDER_STATUS.REVIEWED_NOK: {
      return MEDIA_ORDER_ACTIONS.FIX_PREDELIVERY
    }

    case ORDER_STATUS.DELIVERED_NOK: {
      return MEDIA_ORDER_ACTIONS.FIX_DELIVERY
    }

    case ORDER_STATUS.DELIVERED: {
      return MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_DELIVERY
    }

    case ORDER_STATUS.DRAFT:
    case ORDER_STATUS.CANCELED:
    case ORDER_STATUS.REJECTED:
    case ORDER_STATUS.APPROVED:
    default: {
      return null
    }
  }
}

export const WAIT_ORDER_ACTIONS = [
  BRAND_ORDER_ACTIONS.WAIT_MEDIA_SEND_PREDELIVERY,
  BRAND_ORDER_ACTIONS.WAIT_MEDIA_FIX_PREDELIVERY,
  BRAND_ORDER_ACTIONS.WAIT_MEDIA_SEND_DELIVERY,
  BRAND_ORDER_ACTIONS.WAIT_MEDIA_FIX_DELIVERY,
  MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_COUNTERBRIEFING,
  MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_PREDELIVERY,
  MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_DELIVERY,
]

export const DESTRUCTIVE_ORDER_ACTIONS = [BRAND_ORDER_ACTIONS.CANCEL]

export const mapOrderActionToI18n = {
  // Brand
  [BRAND_ORDER_ACTIONS.CANCEL]: 'action:cancel',
  [BRAND_ORDER_ACTIONS.REVIEW_COUNTERBRIEFING]: 'action:review-counterbriefing',
  [BRAND_ORDER_ACTIONS.WAIT_MEDIA_SEND_PREDELIVERY]:
    'action:wait-media-send-predelivery',
  [BRAND_ORDER_ACTIONS.REVIEW_PREDELIVERY]: 'action:review-predelivery',
  [BRAND_ORDER_ACTIONS.WAIT_MEDIA_FIX_PREDELIVERY]:
    'action:wait-media-fix-predelivery',
  [BRAND_ORDER_ACTIONS.WAIT_MEDIA_SEND_DELIVERY]:
    'action:wait-media-send-delivery',
  [BRAND_ORDER_ACTIONS.REVIEW_DELIVERY]: 'action:review-delivery',
  [BRAND_ORDER_ACTIONS.WAIT_MEDIA_FIX_DELIVERY]:
    'action:wait-media-fix-delivery',

  // Media
  [MEDIA_ORDER_ACTIONS.REVIEW_ORDER]: 'action:review-order', // accept, reject, counterbrief
  [MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_COUNTERBRIEFING]:
    'action:wait-brand-review-counterbriefing',
  [MEDIA_ORDER_ACTIONS.SEND_PREDELIVERY]: 'action:send-predelivery',
  [MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_PREDELIVERY]:
    'action:wait-brand-review-predelivery',
  [MEDIA_ORDER_ACTIONS.FIX_PREDELIVERY]: 'action:fix-predelivery',
  [MEDIA_ORDER_ACTIONS.SEND_DELIVERY]: 'action:send-delivery',
  [MEDIA_ORDER_ACTIONS.WAIT_BRAND_REVIEW_DELIVERY]:
    'action:wait-brand-review-delivery',
  [MEDIA_ORDER_ACTIONS.FIX_DELIVERY]: 'action:fix-delivery',
}

export const mapOrderActionInDetailToI18n = {
  ...mapOrderActionToI18n,
  [MEDIA_ORDER_ACTIONS.REVIEW_ORDER]: 'action:process-order', // accept, reject, counterbrief
}

export const getLimitDateForStatus = (
  status,
  {
    acceptDate,
    sendToReviewDate,
    validateReviewDate,
    deliveryDate,
    approveDate,
    preDelivery,
    express,
  },
) => {
  if (express) {
    return new Date()
  }

  switch (status) {
    case ORDER_STATUS.REQUESTED:
    case ORDER_STATUS.PENDING: {
      return new Date(acceptDate)
    }
    case ORDER_STATUS.ACCEPTED: {
      return new Date(preDelivery ? sendToReviewDate : deliveryDate)
    }

    // predelivery
    case ORDER_STATUS.IN_REVIEW: {
      return new Date(validateReviewDate)
    }
    case ORDER_STATUS.REVIEWED_NOK: {
      return new Date(sendToReviewDate)
    }
    case ORDER_STATUS.REVIEWED: {
      return new Date(deliveryDate)
    }

    // final delivery
    case ORDER_STATUS.DELIVERED: {
      return new Date(approveDate)
    }
    case ORDER_STATUS.DELIVERED_NOK: {
      return new Date(deliveryDate)
    }

    // no actions
    case ORDER_STATUS.DRAFT:
    case ORDER_STATUS.CANCELED:
    case ORDER_STATUS.REJECTED:
    case ORDER_STATUS.APPROVED:
    default: {
      return null
    }
  }
}

export const mapOrderStatusToI18n = {
  [ORDER_STATUS.DRAFT]: 'order-status:draft',
  [ORDER_STATUS.REQUESTED]: 'order-status:requested',
  [ORDER_STATUS.CANCELED]: 'order-status:canceled',
  [ORDER_STATUS.PENDING]: 'order-status:pending',
  [ORDER_STATUS.ACCEPTED]: 'order-status:accepted',
  [ORDER_STATUS.REJECTED]: 'order-status:rejected',
  [ORDER_STATUS.IN_REVIEW]: 'order-status:in-review',
  [ORDER_STATUS.REVIEWED_NOK]: 'order-status:pending-corrections-pre',
  [ORDER_STATUS.REVIEWED]: 'order-status:reviewed',
  [ORDER_STATUS.DELIVERED]: 'order-status:delivered',
  [ORDER_STATUS.DELIVERED_NOK]: 'order-status:pending-corrections',
  [ORDER_STATUS.APPROVED]: 'order-status:approved',
}

export const SERVICE_TYPES = {
  WEB_ONLY_POST: 'WEB_ONLY_POST',
  WEB_POST: 'WEB_POST',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  INSTAGRAM_PHOTO: 'INSTAGRAM_PHOTO',
  INSTAGRAM_VIDEO: 'INSTAGRAM_VIDEO',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  YOUTUBE_MENTION: 'YOUTUBE_MENTION',
  YOUTUBE_DEDICATED: 'YOUTUBE_DEDICATED',
  TWITCH: 'TWITCH',
  TIKTOK_SHORT: 'TIKTOK_SHORT',
  TIKTOK_LONG: 'TIKTOK_LONG',
}

export const mapServiceTypeToOrderTypeI18n = {
  [SERVICE_TYPES.WEB_ONLY_POST]: 'order-type:post-only',
  [SERVICE_TYPES.WEB_POST]: 'order-type:post',
  [SERVICE_TYPES.TWITTER]: 'order-type:tweet',
  [SERVICE_TYPES.FACEBOOK]: 'order-type:post',
  [SERVICE_TYPES.INSTAGRAM_PHOTO]: 'order-type:photo',
  [SERVICE_TYPES.INSTAGRAM_VIDEO]: 'order-type:video',
  [SERVICE_TYPES.INSTAGRAM_STORY]: 'order-type:story',
  [SERVICE_TYPES.YOUTUBE_MENTION]: 'order-type:mention',
  [SERVICE_TYPES.YOUTUBE_DEDICATED]: 'order-type:dedicated-video',
  [SERVICE_TYPES.TWITCH]: 'order-type:mention',
  [SERVICE_TYPES.TIKTOK_SHORT]: 'order-type:video-short',
  [SERVICE_TYPES.TIKTOK_LONG]: 'order-type:video-long',
}

export const mapServiceTypeToI18n = {
  [SERVICE_TYPES.WEB_ONLY_POST]: 'service-type:web-only-post',
  [SERVICE_TYPES.WEB_POST]: 'service-type:web-post',
  [SERVICE_TYPES.FACEBOOK]: 'service-type:facebook-post',
  [SERVICE_TYPES.TWITTER]: 'service-type:twitter-tweet',
  [SERVICE_TYPES.INSTAGRAM_PHOTO]: 'service-type:instagram-photo',
  [SERVICE_TYPES.INSTAGRAM_VIDEO]: 'service-type:instagram-video',
  [SERVICE_TYPES.INSTAGRAM_STORY]: 'service-type:instagram-story',
  [SERVICE_TYPES.YOUTUBE_MENTION]: 'service-type:youtube-mention',
  [SERVICE_TYPES.YOUTUBE_DEDICATED]: 'service-type:youtube-dedicated-video',
  [SERVICE_TYPES.TWITCH]: 'service-type:twitch',
  [SERVICE_TYPES.TIKTOK_SHORT]: 'service-type:tiktok-short',
  [SERVICE_TYPES.TIKTOK_LONG]: 'service-type:tiktok-long',
}

export const mapServiceTypeToI18nWithoutWords = {
  [SERVICE_TYPES.WEB_ONLY_POST]: 'service-type:web-only-post-without-words',
  [SERVICE_TYPES.WEB_POST]: 'service-type:web-post-without-words',
  [SERVICE_TYPES.FACEBOOK]: 'service-type:facebook-post',
  [SERVICE_TYPES.TWITTER]: 'service-type:twitter-tweet',
  [SERVICE_TYPES.INSTAGRAM_PHOTO]: 'service-type:instagram-photo',
  [SERVICE_TYPES.INSTAGRAM_VIDEO]: 'service-type:instagram-video',
  [SERVICE_TYPES.INSTAGRAM_STORY]: 'service-type:instagram-story',
  [SERVICE_TYPES.YOUTUBE_MENTION]: 'service-type:youtube-mention',
  [SERVICE_TYPES.YOUTUBE_DEDICATED]: 'service-type:youtube-dedicated-video',
  [SERVICE_TYPES.TWITCH]: 'service-type:twitch',
  [SERVICE_TYPES.TIKTOK_SHORT]: 'service-type:tiktok-short',
  [SERVICE_TYPES.TIKTOK_LONG]: 'service-type:tiktok-long',
}

export const mapServiceTypeToBriefingMandatoryFieldI18n = {
  [SERVICE_TYPES.WEB_ONLY_POST]: 'checkout:post-thematic',
  [SERVICE_TYPES.WEB_POST]: 'checkout:post-thematic',
  [SERVICE_TYPES.TWITTER]: 'checkout:post-description',
  [SERVICE_TYPES.FACEBOOK]: 'checkout:post-description',
  [SERVICE_TYPES.INSTAGRAM_PHOTO]: 'checkout:campaign-briefing',
  [SERVICE_TYPES.INSTAGRAM_VIDEO]: 'checkout:campaign-briefing',
  [SERVICE_TYPES.INSTAGRAM_STORY]: 'checkout:post-description',
  [SERVICE_TYPES.YOUTUBE_MENTION]: 'checkout:post-description',
  [SERVICE_TYPES.YOUTUBE_DEDICATED]: 'checkout:post-description',
  [SERVICE_TYPES.TWITCH]: 'checkout:post-description',
  [SERVICE_TYPES.TIKTOK_SHORT]: 'checkout:post-description',
  [SERVICE_TYPES.TIKTOK_LONG]: 'checkout:post-description',
}

export const mapServiceTypeToResourceType = {
  [SERVICE_TYPES.WEB_ONLY_POST]: RESOURCE_TYPES.WEB,
  [SERVICE_TYPES.WEB_POST]: RESOURCE_TYPES.WEB,
  [SERVICE_TYPES.TWITTER]: RESOURCE_TYPES.TWITTER,
  [SERVICE_TYPES.FACEBOOK]: RESOURCE_TYPES.FACEBOOK,
  [SERVICE_TYPES.INSTAGRAM_PHOTO]: RESOURCE_TYPES.INSTAGRAM,
  [SERVICE_TYPES.INSTAGRAM_VIDEO]: RESOURCE_TYPES.INSTAGRAM,
  [SERVICE_TYPES.INSTAGRAM_STORY]: RESOURCE_TYPES.INSTAGRAM,
  [SERVICE_TYPES.YOUTUBE_MENTION]: RESOURCE_TYPES.YOUTUBE,
  [SERVICE_TYPES.YOUTUBE_DEDICATED]: RESOURCE_TYPES.YOUTUBE,
  [SERVICE_TYPES.TWITCH]: RESOURCE_TYPES.TWITCH,
  [SERVICE_TYPES.TIKTOK_SHORT]: RESOURCE_TYPES.TIKTOK,
  [SERVICE_TYPES.TIKTOK_LONG]: RESOURCE_TYPES.TIKTOK,
}
