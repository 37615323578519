import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { ReactComponent as Logout } from '../../styles/icons/logout.svg'

const LogoutItem = ({ i18n, onLogout }) => {
  return (
    <button className="main-navigation-logout" onClick={onLogout}>
      <Logout className="logout-icon" />
      <span className="logout-text">{i18n('action:logout')}</span>
    </button>
  )
}

LogoutItem.propTypes = {
  onLogout: PropTypes.func,
}

export default withTranslations(LogoutItem)
