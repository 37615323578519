import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@redradix/components.tabs'

const VerticalTabs = ({ children, controllers, selectedIndex, onChange }) => {
  return (
    <div className="vertical-tabs">
      <Tabs
        isVertical
        controllers={controllers}
        selectedIndex={selectedIndex}
        onChange={onChange}
      >
        {children}
      </Tabs>
    </div>
  )
}

VerticalTabs.propTypes = {
  children: PropTypes.node,
}

export default VerticalTabs
