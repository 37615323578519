import { withPropsOnChange } from 'recompose'
import * as R from 'ramda'

const withCleanFormSuccess = ({
  fields: fieldsMaybeFn, // can be array or fn
  isRequestSucceededProp = 'isRequestSucceeded',
  touchedSinceSubmitProp = 'touchedSinceSubmit',
} = {}) =>
  withPropsOnChange([isRequestSucceededProp, touchedSinceSubmitProp], props => {
    const fields =
      typeof fieldsMaybeFn === 'function' ? fieldsMaybeFn(props) : fieldsMaybeFn

    const anyFieldTouchedSinceSubmit = R.pipe(
      !!fields ? R.pick(fields) : R.identity,
      R.values,
      R.all(R.equals(false)),
    )(props[touchedSinceSubmitProp])

    return {
      hasSucceeded: props[isRequestSucceededProp] && anyFieldTouchedSinceSubmit,
    }
  })

export default withCleanFormSuccess
