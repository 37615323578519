import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { Link } from 'react-router-dom'
import GlobalContent from '../shared/GlobalContent'
import ActionCard from '../cards/ActionCard'
import imgWebBig from './images/addResourceWebBig.png'
import imgWebSmall from './images/addResourceWebSmall.png'
import imgRRSSBig from './images/addResourceRRSSBig.png'
import imgRRSSSmall from './images/addResourceRRSSSmall.png'
import imgTwitter from './images/twitter.png'
import imgFacebook from './images/facebook.png'
import imgInstagram from './images/instagram.png'
import imgYoutube from './images/youtube.svg'
import imgTwitch from './images/twitch.png'
import imgTiktok from './images/tiktok.png'

const ResourceDistributor = ({
  webLinkUrl,
  twitterLinkUrl,
  facebookLinkUrl,
  instagramLinkUrl,
  youtubeLinkUrl,
  twitchLinkUrl,
  tiktokLinkUrl,
  i18n,
}) => {
  return (
    <GlobalContent title={i18n('noun:resources')}>
      <div className="resource-distributor">
        <ActionCard
          isHighlighted
          imageBig={imgWebBig}
          imageSmall={imgWebSmall}
          messageSlot={
            <div>
              <h2 className="resource-distributor-card-title">
                {i18n('resources:create-web-resource-title')}
              </h2>
              <p className="resource-distributor-card-description">
                {i18n('resources:create-web-resource-description')}
              </p>
            </div>
          }
          linkText={i18n('resources:create-web-resource-action')}
          linkUrl={webLinkUrl}
        />
        <ActionCard
          isHighlighted
          imageBig={imgRRSSBig}
          imageSmall={imgRRSSSmall}
          messageSlot={
            <div>
              <h2 className="resource-distributor-card-title">
                {i18n('resources:create-social-resource-title')}
              </h2>
              <p className="resource-distributor-card-description">
                {i18n('resources:create-social-resource-description')}
              </p>
            </div>
          }
          actionGroupSlot={
            <>
              <Link
                className="resource-distributor-image-link"
                to={twitterLinkUrl}
                title={i18n('resources:create-twitter-resource')}
              >
                <img src={imgTwitter} alt="Twitter" />
              </Link>
              <Link
                className="resource-distributor-image-link"
                to={facebookLinkUrl}
                title={i18n('resources:create-facebook-resource')}
              >
                <img src={imgFacebook} alt="Facebook" />
              </Link>
              <Link
                className="resource-distributor-image-link"
                to={instagramLinkUrl}
                title={i18n('resources:create-instagram-resource')}
              >
                <img src={imgInstagram} alt="Instagram" />
              </Link>
              <Link
                className="resource-distributor-image-link"
                to={youtubeLinkUrl}
                title={i18n('resources:create-youtube-resource')}
              >
                <img src={imgYoutube} alt="YouTube" />
              </Link>
              <Link
                className="resource-distributor-image-link"
                to={twitchLinkUrl}
                title={i18n('resources:create-twitch-resource')}
              >
                <img src={imgTwitch} alt="Twitch" />
              </Link>
              <Link
                className="resource-distributor-image-link"
                to={tiktokLinkUrl}
                title={i18n('resources:create-tiktok-resource')}
              >
                <img src={imgTiktok} alt={i18n('noun:tiktok')} />
              </Link>
            </>
          }
        />
      </div>
    </GlobalContent>
  )
}

ResourceDistributor.propTypes = {
  webLinkUrl: PropTypes.string,
  twitterLinkUrl: PropTypes.string,
  facebookLinkUrl: PropTypes.string,
  instagramLinkUrl: PropTypes.string,
  youtubeLinkUrl: PropTypes.string,
  twitchLinkUrl: PropTypes.string,
  tiktokLinkUrl: PropTypes.string,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceDistributor)
