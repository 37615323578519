import * as actionTypes from './action-types'
import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import {
  DOMAIN,
  HASH_KEY_BRAND_INFO,
  BRAND_STATUS_REFRESH_TIME,
  HASH_KEY_BRAND_STATUS,
} from './constants'
import { periodicFetchBrandStatus } from './actions'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
} from '../communication/actions'
import {
  fetchBrand,
  registerBrand,
  editBrandData,
  editBrandBillingData,
  fetchBrandStatus,
} from './api'
import moment from 'moment'
import repeatingFetchEffect from '../repeating-fetch-effect'

import { ROUTE_BRAND_CHECKOUT_MATCH } from '../../app/common/routes'
import { fetchCartSaga, validateDiscountCouponSaga } from '../checkout/sagas'
import { getAppliedCouponPaymentData } from '../checkout'

import { periodicFetchBrandWalletMovements } from '../wallet'

function* fetchBrandSaga() {
  yield put(startRequest(actionTypes.FETCH_BRAND))

  const response = yield call(fetchBrand)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_BRAND, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_BRAND_INFO, response.data))

  // Set moment's locale for react-dates: this is an imperative and global
  // operation but it's unavoidable AFAICT
  moment.locale(response.data.language)

  yield put(endRequestSuccess(actionTypes.FETCH_BRAND))
}

function* fetchBrandStatusSaga() {
  yield put(startRequest(actionTypes.FETCH_BRAND_STATUS))

  const response = yield call(fetchBrandStatus)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_BRAND_STATUS, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_BRAND_STATUS, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_BRAND_STATUS))
}

function* editBrandDataSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.EDIT_BRAND_DATA))

  const response = yield call(editBrandData, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.EDIT_BRAND_DATA, response))
    return
  }

  yield call(fetchBrandSaga)

  yield put(endRequestSuccess(actionTypes.EDIT_BRAND_DATA))
}

function* editBrandBillingDataSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.EDIT_BRAND_BILLING_DATA))
  const response = yield call(editBrandBillingData, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.EDIT_BRAND_BILLING_DATA, response))
    return
  }

  yield all([
    call(fetchBrandSaga),
    // Update cart to reflect possible changes in VAT according to billing data
    call(fetchCartSaga),
    // Fetch wallet movements to reflect posible currency exchange movements
    put(periodicFetchBrandWalletMovements()),
  ])

  // Also re-apply coupon if we're on checkout (possible billing data modal) so
  // cart prices are shown with coupon applied
  const appliedCoupon = yield select(getAppliedCouponPaymentData)

  if (
    appliedCoupon &&
    ROUTE_BRAND_CHECKOUT_MATCH.test(window.location.pathname)
  ) {
    yield call(validateDiscountCouponSaga, {
      payload: appliedCoupon.coupon.code,
    })
  }

  yield put(endRequestSuccess(actionTypes.EDIT_BRAND_BILLING_DATA))
}

function* registerBrandSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.REGISTER_BRAND))

  const response = yield call(registerBrand, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.REGISTER_BRAND, response))
    return
  }

  yield put(endRequestSuccess(actionTypes.REGISTER_BRAND))
}

export default function*() {
  yield all([
    takeLatest(actionTypes.FETCH_BRAND, fetchBrandSaga),
    takeLatest(actionTypes.EDIT_BRAND_DATA, editBrandDataSaga),
    takeLatest(actionTypes.EDIT_BRAND_BILLING_DATA, editBrandBillingDataSaga),
    takeLatest(actionTypes.REGISTER_BRAND, registerBrandSaga),
    takeLatest(actionTypes.FETCH_BRAND_STATUS, fetchBrandStatusSaga),
    repeatingFetchEffect({
      fetchSaga: fetchBrandStatusSaga,
      intervalMillisecs: BRAND_STATUS_REFRESH_TIME,
      startAction: periodicFetchBrandStatus(),
      requestActionType: actionTypes.FETCH_BRAND_STATUS,
    }),
  ])
}
