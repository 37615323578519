import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import * as Yup from 'yup'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { approveOrderDelivery } from '../../services/orders'
import { APPROVE_ORDER_DELIVERY } from '../../services/orders/action-types'
import OrderModalReviewDeliveryApprove from '../../ui/order-modals/OrderModalReviewDeliveryApprove'
import withFormErrors from '../../hocs/withFormErrors'
import { isNilOrEmpty } from '../utils'
import { i18nValidation } from '../common/validation'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'

const validationSchema = Yup.object().shape({
  comments: Yup.string(),
  rating: Yup.number()
    .nullable()
    .typeError(i18nValidation.required)
    .required(i18nValidation.required)
    .min(1, i18nValidation.min)
    .max(5, i18nValidation.max),
})

const enhance = compose(
  connect(
    null,
    { approveOrderDelivery },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      comments: '',
      rating: null,
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSubmit = R.filter(R.complement(isNilOrEmpty), values)
      props.approveOrderDelivery(props.orderId, valuesToSubmit)
    },
  }),

  withIsRequestPending(APPROVE_ORDER_DELIVERY),
  withIsRequestSucceeded(APPROVE_ORDER_DELIVERY),
  withRequestError(APPROVE_ORDER_DELIVERY),
  withDeleteRequestOnUnmount(APPROVE_ORDER_DELIVERY),

  withFormErrors(['comments', 'rating']),
)

export default enhance(OrderModalReviewDeliveryApprove)
