import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import * as R from 'ramda'
import { i18nValidation } from '../common/validation'
import { editSocialResourceServicesPrices } from '../../services/resources'
import { calculateTotalPrice } from './util'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { floatToMoney } from '../utils'
import { EDIT_SOCIAL_RESOURCE_SERVICES_PRICES } from '../../services/resources/action-types'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const validationSchema = Yup.object().shape({
  enabled: Yup.boolean().required(i18nValidation.required),
  basePrice: Yup.number().when('enabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min),
  }),
  discount: Yup.number().when('enabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min)
      .max(100, i18nValidation.max),
  }),
})

const hasTotalPriceError = ({ totalPrice = 0 }, touched) =>
  totalPrice < 10 && (touched.basePrice || touched.discount)

const calculatePrices = values => ({
  totalPrice: calculateTotalPrice(values.basePrice, values.discount),
})

const hasCalculatedPricesErrors = values => {
  const calculatedValues = calculatePrices(values)

  const touched = {
    basePrice: true,
    discount: true,
  }

  return values.enabled && hasTotalPriceError(calculatedValues, touched)
}

const withOneServicePrices = serviceType =>
  compose(
    connect(
      null,
      {
        editSocialResourceServicesPrices,
      },
    ),

    withEnhancedFormik({
      isInitialValid: true,

      enableReinitialize: true,

      mapPropsToValues: ({ resourceData }) => ({
        basePrice: R.pathOr(
          0,
          ['prices', serviceType, 'basePrice'],
          resourceData,
        ),
        discount: R.pathOr(
          0,
          ['prices', serviceType, 'discount'],
          resourceData,
        ),
        enabled: resourceData.blocked
          ? false
          : R.pathOr(true, ['prices', serviceType, 'enabled'], resourceData),
        currency: R.pathOr(
          true,
          ['prices', serviceType, 'currency'],
          resourceData,
        ),
      }),

      validationSchema,

      validateOnBlur: true,
      validateOnChange: true,

      handleSubmit: (values, { props }) => {
        if (hasCalculatedPricesErrors(values)) {
          return
        }

        const valuesToSend = [
          values.enabled
            ? {
                serviceType: serviceType,
                enabled: true,
                basePrice: floatToMoney(values.basePrice, values.currency),
                discount: values.discount,
              }
            : { serviceType: serviceType, enabled: false },
        ]

        props.editSocialResourceServicesPrices(
          props.resourceId,
          props.channel,
          valuesToSend,
        )
      },
    }),

    withIsRequestPending(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
    withIsRequestSucceeded(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
    withRequestError(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
    withDeleteRequestOnUnmount(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),

    withFormErrors(['basePrice', 'discount', 'enabled']),

    // Change final prices when changes offer
    withPropsOnChange(['values'], ({ values }) => ({
      calculatedValues: calculatePrices(values),
    })),

    // Inject calculatedPrices errors
    withPropsOnChange(
      ['calculatedValues', 'validationMessages', 'touched', 'values'],
      ({ calculatedValues, validationMessages, touched, values }) => {
        const showTotalPriceError = hasTotalPriceError(
          calculatedValues,
          touched,
        )

        return {
          validationMessages: {
            ...validationMessages,
            totalPrice:
              showTotalPriceError && values.enabled
                ? [i18nValidation.min({ path: 'totalPrice', min: 10 })]
                : undefined,
          },
        }
      },
    ),
  )

export default withOneServicePrices
