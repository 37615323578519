import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Rheostat from 'rheostat'
import '../../fixInitializeAirbnbComponents'

const makeFloatAlgorithm = precision => ({
  getPosition: (value, min, max) => ((value - min) / (max - min)) * 100,
  getValue: (pos, min, max) => {
    const decimal = pos / 100

    if (pos === 0) {
      return min
    }

    if (pos === 100) {
      return max
    }

    return parseFloat(((max - min) * decimal + min).toFixed(precision))
  },
})

const RangeSlider = ({
  min,
  max,
  values,
  onChange,
  onValuesUpdated,
  isDisabled,
  floatPrecision,
}) => {
  const algorithm = useMemo(
    () => (floatPrecision ? makeFloatAlgorithm(floatPrecision) : undefined),
    [floatPrecision],
  )

  return (
    <div className="range-slider">
      <Rheostat
        min={min}
        max={max}
        values={values}
        onChange={onChange}
        onValuesUpdated={onValuesUpdated}
        disabled={isDisabled}
        algorithm={algorithm}
      />
    </div>
  )
}

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  onValuesUpdated: PropTypes.func,
  floatPrecision: PropTypes.number,
}
export default RangeSlider
