import * as actionTypes from './action-types'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import moment from 'moment'

import {
  DOMAIN,
  HASH_KEY_INFO,
  LOCALSTORAGE_KEY_LANGUAGE,
  HASH_KEY_LANGUAGE,
} from './constants'
import {
  startRequest,
  endRequestError,
  endRequestSuccess,
} from '../communication/actions'
import { fetchMe } from './api'

function* fetchMyInfoSaga() {
  yield put(startRequest(actionTypes.FETCH_MY_INFO))

  const response = yield call(fetchMe)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_MY_INFO, response))
    return
  }

  yield put(
    hash.set(DOMAIN, HASH_KEY_INFO, {
      ...response.data,
      id: parseInt(response.data.id, 10), // parse id into number
    }),
  )

  yield put(endRequestSuccess(actionTypes.FETCH_MY_INFO))
}

function* setLanguageSaga({ payload: { language } }) {
  yield put(hash.set(DOMAIN, HASH_KEY_LANGUAGE, language))

  // Set moment's locale for react-dates: this is an imperative and global
  // operation but it's unavoidable AFAICT
  moment.locale(language)

  yield call([localStorage, 'setItem'], LOCALSTORAGE_KEY_LANGUAGE, language)
}

export default function*() {
  yield all([
    takeLatest(actionTypes.FETCH_MY_INFO, fetchMyInfoSaga),
    takeLatest(actionTypes.SET_LANGUAGE, setLanguageSaga),
  ])
}
