import { connect } from 'react-redux'
import { compose } from 'redux'
import { lifecycle } from 'recompose'
import { deleteRequest } from '../services/communication'

const withDeleteRequestOnUnmount = requestType =>
  compose(
    connect(
      null,
      {
        deleteRequest,
      },
    ),

    lifecycle({
      componentWillUnmount() {
        this.props.deleteRequest(requestType)
      },
    }),
  )

export default withDeleteRequestOnUnmount
