import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { ReactComponent as FiltersIcon } from '../../styles/icons/filters.svg'
import Spinner from '@redradix/components.spinner'
import { ReactComponent as Spin } from '../../styles/icons/spinner.svg'

const SearchResultsHeader = ({
  i18n,
  children,
  onShowFilters,
  results,
  searchExportUrl,
  handleSearchExportClick,
  isLoadingSearchExport,
}) => (
  <div className="search-results-header">
    <h2 className="search-results-header-title">
      {i18n('noun:media-influencers')}
    </h2>
    <div className="search-results-header-actions">
      {children}
      <div className="search-results-header-download">
        {searchExportUrl && (
          <a onClick={handleSearchExportClick} href={searchExportUrl}>
            {isLoadingSearchExport && (
              <Spinner isLoading={true}>
                <Spin
                  className="icon rdx-button-icon"
                  style={{ marginRight: '0.3rem' }}
                />
              </Spinner>
            )}
            {i18n('action:download-results')}
          </a>
        )}
      </div>
      <div className="search-results-header-counter">
        {results === null
          ? i18n('message:loading-results')
          : i18n('message:results', { results: results })}
      </div>
      <button className="search-results-header-filters" onClick={onShowFilters}>
        <FiltersIcon />
      </button>
    </div>
  </div>
)

SearchResultsHeader.propTypes = {
  i18n: PropTypes.func.isRequired,
  children: PropTypes.node,
  onShowFilters: PropTypes.func.isRequired,
  results: PropTypes.number,
  searchExportUrl: PropTypes.string,
  handleSearchExportClick: PropTypes.func,
  isLoadingSearchExport: PropTypes.bool,
}

export default withTranslations(SearchResultsHeader)
