import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest, postRequest, deleteRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

const FETCH_FAVORITES_ENDPOINT = `${envConfig.searchBackendUrl}/resources/favorites?limit=1000`

const BASE_FAVORITES_ENDPOINT = `${envConfig.usersBackendUrl}/brand/resources/favorites`

export function* fetchMyFavorites() {
  const response = yield call(getRequest, FETCH_FAVORITES_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* addToFavorites(resourceId) {
  const response = yield call(
    postRequest,
    BASE_FAVORITES_ENDPOINT,
    { resourceId: Number(resourceId) },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* removeFavorite(resourceId) {
  const response = yield call(
    deleteRequest,
    `${BASE_FAVORITES_ENDPOINT}/${resourceId}`,
    {},
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}
