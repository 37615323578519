export const SERVICE = 'RESOURCE'

export const DOMAIN = 'resources'

export const ENTITY_KEY_RESOURCES = 'resources'
export const HASH_KEY_RESOURCES_META = 'resourcesMeta'
export const RESOURCES_PER_PAGE = 10

export const ENTITY_KEY_RESOURCE_DETAILS = 'resourceDetails'

export const HASH_KEY_LAST_TEMPORARY_IMAGE = 'lastTemporaryImage'

export const ENTITY_KEY_RESOURCE_STATUS = 'resourcesStatus'

export const RESOURCE_TYPES = {
  WEB: 'WEB',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  INSTAGRAM: 'INSTAGRAM',
  YOUTUBE: 'YOUTUBE',
  TWITCH: 'TWITCH',
  TIKTOK: 'TIKTOK',
}

export const SOCIAL_NETWORK_RESOURCE_TYPES = [
  RESOURCE_TYPES.FACEBOOK,
  RESOURCE_TYPES.TWITTER,
  RESOURCE_TYPES.INSTAGRAM,
  RESOURCE_TYPES.YOUTUBE,
  RESOURCE_TYPES.TWITCH,
  RESOURCE_TYPES.TIKTOK,
]

export const mapResourceTypeToI18N = {
  [RESOURCE_TYPES.WEB]: 'noun:webs',
  [RESOURCE_TYPES.FACEBOOK]: 'noun:facebook',
  [RESOURCE_TYPES.TWITTER]: 'noun:twitter',
  [RESOURCE_TYPES.INSTAGRAM]: 'noun:instagram',
  [RESOURCE_TYPES.YOUTUBE]: 'noun:youtube',
  [RESOURCE_TYPES.TWITCH]: 'noun:twitch',
  [RESOURCE_TYPES.TIKTOK]: 'noun:tiktok',
}

export const mapResourceTypeToSocialConnectionI18N = {
  [RESOURCE_TYPES.WEB]: 'noun:webs',
  [RESOURCE_TYPES.FACEBOOK]: 'noun:facebook',
  [RESOURCE_TYPES.TWITTER]: 'noun:twitter',
  [RESOURCE_TYPES.INSTAGRAM]: 'sections:instagram-business',
  [RESOURCE_TYPES.YOUTUBE]: 'noun:youtube',
  [RESOURCE_TYPES.TWITCH]: 'noun:twitch',
  [RESOURCE_TYPES.TIKTOK]: 'noun:tiktok',
}

export const mapResourceTypeToSocialConnectionProviderI18N = {
  [RESOURCE_TYPES.WEB]: 'noun:webs',
  [RESOURCE_TYPES.FACEBOOK]: 'noun:facebook',
  [RESOURCE_TYPES.TWITTER]: 'noun:twitter',
  [RESOURCE_TYPES.INSTAGRAM]: 'noun:facebook',
  [RESOURCE_TYPES.YOUTUBE]: 'noun:youtube',
  [RESOURCE_TYPES.TWITCH]: 'noun:twitch',
  [RESOURCE_TYPES.TIKTOK]: 'noun:tiktok',
}

export const WEB_RESOURCE_SECTIONS = {
  GENERAL_DATA: 1,
  SOCIAL_NETWORKS: 2,
  ANALYTICS: 3,
  SERVICES: 4,
}

export const SOCIAL_RESOURCE_SECTIONS = {
  GENERAL_DATA: 1,
  SERVICES: 2,
}

export const SOCIAL_SERVICES = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  INSTAGRAM_BUSINESS: 'instagram-business',
  YOUTUBE: 'youtube',
  TWITCH: 'twitch',
  TIKTOK: 'tiktok',
}

export const RESOURCE_TYPE_TO_SOCIAL_SERVICE = {
  [RESOURCE_TYPES.WEB]: SOCIAL_SERVICES.GOOGLE,
  [RESOURCE_TYPES.FACEBOOK]: SOCIAL_SERVICES.FACEBOOK,
  [RESOURCE_TYPES.TWITTER]: SOCIAL_SERVICES.TWITTER,
  [RESOURCE_TYPES.INSTAGRAM]: SOCIAL_SERVICES.INSTAGRAM_BUSINESS,
  [RESOURCE_TYPES.YOUTUBE]: SOCIAL_SERVICES.YOUTUBE,
  [RESOURCE_TYPES.TWITCH]: SOCIAL_SERVICES.TWITCH,
  [RESOURCE_TYPES.TIKTOK]: SOCIAL_SERVICES.TIKTOK,
}

export const DESCRIPTION_MAX_CHARACTERS = 300

export const getSocialProfilesDomain = socialService =>
  `${socialService}Profiles`

export const getSocialDataDomain = socialService => `${socialService}Data`
