import React from 'react'
import PropTypes from 'prop-types'
import IconIndicator from '../indicators/IconIndicator'

const SuccessMessage = ({ children, iconType }) => {
  const type = iconType || 'success'
  return (
    <div className="success-message">
      <IconIndicator type={type} />
      <div className="success-message-text">{children}</div>
    </div>
  )
}

SuccessMessage.propTypes = {
  children: PropTypes.node.isRequired,
  iconType: PropTypes.oneOf(['success', 'fill-success']),
}

export default SuccessMessage
