import React from 'react'
import PropTypes from 'prop-types'
import { Input as RdxInput } from '@redradix/components.input'
import useRandomId from '../../hooks/useRandomId'

const Input = ({
  autoFocus,
  errorText,
  id: userId,
  isTouched,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  widthBehaviour,
  isMulti,
  multiSeparator,
  multiPrefix,
  value,
  ...props
}) => {
  const randomId = useRandomId()
  const id = userId ? userId : randomId

  const handleMultiOnKeyUp = e => {
    var cleanValues =
      e.keyCode === 8
        ? getCleanValues(e.target.value)
        : getFixedValues(e.target.value)
    e.target.value = getNewValue(e.target.value, cleanValues)
  }

  const handleMultiOnFocus = e => {
    var newValue = getNewValue(e.target.value, getFixedValues(e.target.value))
    if (newValue.length === 0) {
      newValue = newValue + multiPrefix
    }
    e.target.value = newValue
    onFocus && onFocus(e)
  }

  const handleMultiOnBlur = e => {
    e.target.value = getCleanValues(e.target.value).join(multiSeparator[0])
    onBlur && onBlur(e)
  }

  const getNewValue = (value, cleanValues) => {
    var newValue = cleanValues.join(multiSeparator[0])
    if (testEndsWithSeparator(value)) {
      newValue = newValue + multiSeparator[0] + multiPrefix
    }
    return newValue
  }

  const testEndsWithSeparator = value =>
    new RegExp('(' + multiSeparator.join('|\\') + ')$').test(value)

  const getCleanValues = value =>
    value
      .replace(new RegExp(multiSeparator.join('|\\'), 'g'), multiSeparator[0])
      .split(multiSeparator[0])
      .filter(value => value.length && value !== multiPrefix)

  const getFixedValues = value =>
    getCleanValues(value).map(value =>
      value.indexOf(multiPrefix) !== 0 ? multiPrefix + value : value,
    )

  return (
    <RdxInput
      {...props}
      autoFocus={autoFocus}
      errorText={errorText}
      id={id}
      isTouched={isTouched}
      label={label}
      name={name}
      onChange={onChange}
      onKeyUp={isMulti ? handleMultiOnKeyUp : null}
      onBlur={isMulti ? handleMultiOnBlur : onBlur}
      onFocus={isMulti ? handleMultiOnFocus : onFocus}
      placeholder={placeholder}
      value={value}
      widthBehaviour={widthBehaviour}
    />
  )
}

Input.defaultProps = {
  isMulti: false,
  multiSeparator: [' ', ',', ';', ':', '|'], // All this separators are converted to the first one at output formated value
  multiPrefix: '%', // This prefix is added at the beggining of every chunk value if not present yet
}

Input.propTypes = {
  /** Boolean that specifies whether an <input> should automatically get focus when the page loads */
  autoFocus: PropTypes.bool,
  /** String that defines the error text of the <input> */
  errorText: PropTypes.node,
  /** String that specifies the helper text of the <input> */
  id: PropTypes.string,
  /** Boolean that defines whether the <input> is disabled */
  isTouched: PropTypes.bool,
  /** String with the text of the <label> */
  label: PropTypes.string,
  /** String that specifies the name of an <input> */
  name: PropTypes.string,
  /** Function that is triggered when the <input> field loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when the <input> is changed */
  onChange: PropTypes.func,
  /** Function that is triggered when the <input> gets focus */
  onFocus: PropTypes.func,
  /** String that describes the expected value of the <input> */
  placeholder: PropTypes.string,
  /** String or number that specifies the value of the <input> */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isMulti: PropTypes.bool,
  /** String that defines the behaviour of the input width */
  widthBehaviour: PropTypes.oneOf(['fixed', 'full']),
}

export default Input
