import React from 'react'
import PropTypes from 'prop-types'

const TooltipTitle = props => {
  return <p className="tooltip-title">{props.text}</p>
}

TooltipTitle.propTypes = {
  text: PropTypes.string,
}

export default TooltipTitle
