import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import * as R from 'ramda'
import { STATISTICS_TABS } from '../../../services/campaigns/business'
import {
  MediaQueryPalm,
  MediaQueryLap,
  MediaQueryDesk,
} from '../../shared/MediaQueries'
import { FormattedDate } from 'react-intl'
import withTranslations from '../../../hocs/withTranslations'
import PublicationsTable from '../tables/PublicationsTable'
import PublicationsRow from '../tables/PublicationsRow'
import AnchorLink from '../../texts/AnchorLink'
import { ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID } from '../../../app/common/routes'
import HackFormattedMoney from '../../HackFormattedMoney'

const PublicationsTableGlobal = ({
  publications,
  isLoading,
  i18n,
  i18nNumber,
}) => {
  return (
    <PublicationsTable
      type={STATISTICS_TABS.GLOBAL}
      isLoading={isLoading}
      headRowCells={
        <>
          <div className="publications-head-row-item publications-table-name">
            {i18n('metrics:publication')}
          </div>
          <div className="publications-head-row-item publications-table-visits">
            {i18n('metrics:visits')}
          </div>
          <div className="publications-head-row-item publications-table-engagements">
            {i18n('metrics:engagements')}
          </div>
          <div className="publications-head-row-item publications-table-impressions">
            {i18n('metrics:impressions')}
          </div>
          <MediaQueryLap>
            <div className="publications-head-row-item publications-table-likes">
              {i18n('metrics:likes')}
            </div>
            <div className="publications-head-row-item publications-table-date">
              {i18n('noun:publication-date')}
            </div>
            <div className="publications-head-row-item publications-table-price">
              {i18n('noun:price')}
            </div>
          </MediaQueryLap>
          <MediaQueryDesk>
            <div className="publications-head-row-item publications-table-action"></div>
          </MediaQueryDesk>
        </>
      }
    >
      {publications.map(publication => {
        const linkUrl = ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.linkTo({
          campaignId: publication.campaignId,
          orderId: publication.id,
        })

        return (
          <PublicationsRow key={publication.id} linkUrl={linkUrl}>
            <div className="publications-row-item publications-table-name">
              {publication.name}
            </div>
            <div className="publications-row-item publications-table-visits">
              {!R.isNil(publication.visits) && (
                <>
                  <MediaQueryLap>
                    {i18nNumber(publication.visits)}
                  </MediaQueryLap>
                  <MediaQueryPalm>
                    {i18n('metrics:visits-cell', {
                      visits: i18nNumber(publication.visits),
                    })}
                  </MediaQueryPalm>
                </>
              )}
            </div>
            <div className="publications-row-item publications-table-engagements">
              {!R.isNil(publication.engagements) && (
                <>
                  <MediaQueryLap>
                    {i18nNumber(publication.engagements)}
                  </MediaQueryLap>
                  <MediaQueryPalm>
                    {i18n('metrics:engagements-cell', {
                      engagements: i18nNumber(publication.engagements),
                    })}
                  </MediaQueryPalm>
                </>
              )}
            </div>
            <div className="publications-row-item publications-table-impressions">
              {!R.isNil(publication.impressions) && (
                <>
                  <MediaQueryLap>
                    {i18nNumber(publication.impressions)}
                  </MediaQueryLap>
                  <MediaQueryPalm>
                    {i18n('metrics:impressions-cell', {
                      impressions: i18nNumber(publication.impressions),
                    })}
                  </MediaQueryPalm>
                </>
              )}
            </div>
            <MediaQueryLap>
              <div className="publications-row-item publications-table-likes">
                {!R.isNil(publication.likes) && i18nNumber(publication.likes)}
              </div>
              <div className="publications-row-item publications-table-date">
                <FormattedDate format="coobis" value={publication.date} />
              </div>
              <div className="publications-row-item publications-table-price">
                <HackFormattedMoney value={publication.price} />
              </div>
            </MediaQueryLap>
            <MediaQueryDesk>
              <div className="publications-row-item publications-table-action">
                <AnchorLink
                  linkUrl={linkUrl}
                  text={i18n('action:see-detail')}
                ></AnchorLink>
              </div>
            </MediaQueryDesk>
          </PublicationsRow>
        )
      })}
    </PublicationsTable>
  )
}

PublicationsTableGlobal.propTypes = {
  publications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      visits: PropTypes.number,
      engagements: PropTypes.number,
      impressions: PropTypes.number,
      likes: PropTypes.number,
      date: PropTypes.instanceOf(Date),
      price: CoobisPropTypes.money,
    }),
  ),
  isLoading: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(PublicationsTableGlobal)
