import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import CampaignDetailPanel from '../CampaignDetailPanel'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import PublicationsTableTiktok from '../tables/PublicationsTableTiktok'
import ChartCard from '../../cards/ChartCard'
import MultilineChart from '../../charts/MultilineChart'
import CampaignPreviewTiktok from '../social-previews/CampaignPreviewTiktok'
import { MAX_LEGEND_ITEMS } from '../../../services/campaigns/constants'
import { isNilOrEmpty } from '../../../app/utils'
import { containsNotNullValuesMultilineChart } from '../../../app/common/statistics-utils'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'

const CampaignDetailTiktokAll = ({
  previews,
  publicationsTableData,
  i18n,
  // Metrics values
  publications,
  views,
  likes,
  engagements,

  viewsData,
  onViewsLegendDelete,
  viewsLegendOptions,
  onViewsLegendOptionChange,

  likesData,
  onLikesLegendDelete,
  likesLegendOptions,
  onLikesLegendOptionChange,

  engagementsData,
  onEngagementsLegendDelete,
  engagementsLegendOptions,
  onEngagementsLegendOptionChange,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        <AnalyticsKpi
          iconType="megaphone"
          value={publications}
          label={i18n('metrics:publications')}
          isTruncated
        />
        {views > 0 && (
          <AnalyticsKpi
            iconType="eye"
            value={views}
            label={i18n('metrics:total-views')}
            isTruncated
          />
        )}
        <AnalyticsKpi
          iconType="heart"
          value={likes}
          label={i18n('metrics:likes')}
          isTruncated
        />
        {engagements > 0 && (
          <AnalyticsKpi
            iconType="click"
            value={engagements}
            label={i18n('metrics:engagements')}
            isTruncated
          />
        )}
      </div>

      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewTiktok items={previews} />
      ) : null}

      <div className="campaign-detail-section-row">
        {containsNotNullValuesMultilineChart(viewsData) && (
          <div className="views campaign-detail-section-cell one-whole">
            <ChartCard title={i18n('metrics:views')}>
              <MultilineChart
                data={viewsData}
                onLegendDelete={onViewsLegendDelete}
                legendOptions={viewsLegendOptions}
                onLegendOptionChange={onViewsLegendOptionChange}
                maxLegendItems={MAX_LEGEND_ITEMS}
              />
            </ChartCard>
          </div>
        )}
      </div>

      <div className="campaign-detail-section-row">
        {containsNotNullValuesMultilineChart(likesData) && (
          <div className="likes campaign-detail-section-cell one-whole">
            <ChartCard title={i18n('metrics:likes')}>
              <MultilineChart
                data={likesData}
                onLegendDelete={onLikesLegendDelete}
                legendOptions={likesLegendOptions}
                onLegendOptionChange={onLikesLegendOptionChange}
                maxLegendItems={MAX_LEGEND_ITEMS}
              />
            </ChartCard>
          </div>
        )}
      </div>

      <div className="campaign-detail-section-row">
        {containsNotNullValuesMultilineChart(likesData) && (
          <div className="engagements campaign-detail-section-cell one-whole">
            <ChartCard title={i18n('metrics:engagements')}>
              <MultilineChart
                data={engagementsData}
                onLegendDelete={onEngagementsLegendDelete}
                legendOptions={engagementsLegendOptions}
                onLegendOptionChange={onEngagementsLegendOptionChange}
                maxLegendItems={MAX_LEGEND_ITEMS}
              />
            </ChartCard>
          </div>
        )}
      </div>

      <CampaignDetailPanel title={i18n('noun:publications-table')}>
        <PublicationsTableTiktok
          publications={publicationsTableData}
        ></PublicationsTableTiktok>
      </CampaignDetailPanel>
    </>
  )
}

CampaignDetailTiktokAll.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  publicationsTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      views: PropTypes.number,
      likes: PropTypes.number,
      engagements: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  publications: PropTypes.number,

  views: PropTypes.number,
  likes: PropTypes.number,
  engagements: PropTypes.number,

  viewsData: PropTypes.array,
  onViewsLegendDelete: PropTypes.func,
  viewsLegendOptions: CoobisPropTypes.graphLegendOptions,
  onViewsLegendOptionChange: PropTypes.func,

  likesData: PropTypes.array,
  onLikesLegendDelete: PropTypes.func,
  likesLegendOptions: CoobisPropTypes.graphLegendOptions,
  onLikesLegendOptionChange: PropTypes.func,

  engagementsData: PropTypes.array,
  onEngagementsLegendDelete: PropTypes.func,
  engagementsLegendOptions: CoobisPropTypes.graphLegendOptions,
  onEngagementsLegendOptionChange: PropTypes.func,
}

export default withTranslations(CampaignDetailTiktokAll)
