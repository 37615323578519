import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import * as R from 'ramda'
import ChartLegend from './ChartLegend'
import withTranslations from '../../hocs/withTranslations'
am4core.useTheme(am4themes_animated)

const CLUSTERED_BAR_CHART_COLORS = ['#ffd960', '#3e17b3']

const createChart = (element, i18nNumber) => {
  const chart = am4core.create(element, am4charts.XYChart)

  // Category axis (X)
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
  categoryAxis.dataFields.category = 'age-range'
  categoryAxis.renderer.grid.template.location = 0
  categoryAxis.renderer.cellStartLocation = 0.4
  categoryAxis.renderer.cellEndLocation = 0.6
  categoryAxis.renderer.minGridDistance = 20
  categoryAxis.renderer.grid.template.stroke = am4core.color('#999ab1')
  categoryAxis.renderer.labels.template.fill = am4core.color('#999ab1')
  categoryAxis.renderer.labels.template.fontSize = 12

  // Value axis (Y)
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.min = 0
  valueAxis.renderer.minGridDistance = 20
  valueAxis.renderer.grid.template.stroke = am4core.color('#999ab1')
  valueAxis.renderer.baseGrid.disabled = true //remove baseline
  valueAxis.renderer.labels.template.fill = am4core.color('#999ab1')
  valueAxis.renderer.labels.template.fontSize = 12
  valueAxis.renderer.labels.template.adapter.add('text', function(text) {
    return text === 'Male' || text === 'Female' ? text : text + '%'
  })

  // Color list
  chart.colors.list = [am4core.color('#ffd960'), am4core.color('#3e17b3')]

  // Create series
  chart.series.push(createSeries('Women', 'women', i18nNumber))
  chart.series.push(createSeries('Men', 'men', i18nNumber))

  return chart
}

const createSeries = (name, field, i18nNumber) => {
  const series = new am4charts.ColumnSeries()

  series.name = name
  series.dataFields.valueY = field
  series.dataFields.categoryX = 'age-range'
  series.columns.template.width = 12
  series.columns.template.tooltipText = '{valueY} %'
  series.columns.template.adapter.add(
    'tooltipText',
    (_, target) => `${i18nNumber(target.dataItem.valueY)}%`,
  )
  series.columns.template.column.cornerRadiusTopLeft = 20
  series.columns.template.column.cornerRadiusTopRight = 20
  series.columns.template.column.cornerRadiusBottomRight = 20
  series.columns.template.column.cornerRadiusBottomLeft = 20
  series.tooltip.autoTextColor = false
  series.tooltip.background.fill = am4core.color('#fff')
  series.tooltip.getFillFromObject = false
  series.tooltip.label.fill = am4core.color('#999ab1')
  series.tooltip.label.fontSize = '12'
  series.tooltip.pointerOrientation = 'vertical'
  series.legendSettings.labelText = '[#999ab1 font-size: 0.875rem] texto [/]'

  return series
}

const getYMax = data => {
  const maxValue = data.reduce(
    (acc, { men, women }) =>
      men >= women && men > acc
        ? men
        : women >= men && women > acc
        ? women
        : acc,
    0,
  )

  const maxValueRoundedTo10 = Math.ceil(maxValue / 10) * 10

  return maxValueRoundedTo10
}

const ClusteredBarsChart = ({ data, i18n, i18nNumber }) => {
  const chart = useRef()
  const chartElementRef = useRef()

  // Create and destroy chart on (un)mount
  useEffect(() => {
    chart.current = createChart(chartElementRef.current, i18nNumber)

    return () => {
      if (chart.current) {
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [i18nNumber])

  // Handle data updates
  useEffect(() => {
    // Return if chart is unmounted
    if (!chart.current) {
      return
    }

    if (chart.current.data.length === 0) {
      // Set initial data
      chart.current.data = R.clone(data)
    } else {
      // Update current data
      // Needs to be imperative to trigger animation
      for (let i = 0; i < data.length; i++) {
        chart.current.data[i].men = data[i].men
        chart.current.data[i].women = data[i].women
      }

      // Trigger animation
      chart.current.invalidateRawData()
    }

    // Set max Y value based on data
    chart.current.yAxes.values[0].max = getYMax(data)
  }, [data, i18nNumber])

  const legendItems = useMemo(
    () => [
      { label: i18n('gender:women'), color: CLUSTERED_BAR_CHART_COLORS[0] },
      { label: i18n('gender:men'), color: CLUSTERED_BAR_CHART_COLORS[1] },
    ],
    [i18n],
  )

  return (
    <div className="chart-wrapper">
      <div ref={chartElementRef} className="clustered-bar-chart" />
      {legendItems && <ChartLegend items={legendItems}></ChartLegend>}
    </div>
  )
}

ClusteredBarsChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      'age-range': PropTypes.string,
      men: PropTypes.number,
      women: PropTypes.number,
    }),
  ),
}

export default withTranslations(ClusteredBarsChart)
