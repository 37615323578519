import { compose } from 'recompose'
import { connect } from 'react-redux'
import { requestConnectSocial } from '../../services/resources'
import { REQUEST_CONNECT_SOCIAL } from '../../services/resources/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withHasRequestError from '../../hocs/withHasRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRouteServerError from '../../hocs/withRouteServerError'
import { RESOURCE_TYPE_TO_SOCIAL_SERVICE } from '../../services/resources/constants'

const withSocialConnect = (socialServiceProp = 'channel') =>
  compose(
    connect(
      null,
      (dispatch, ownProps) => ({
        requestSocialConnect: () => {
          const resSocialService =
            RESOURCE_TYPE_TO_SOCIAL_SERVICE[ownProps[socialServiceProp]]
          const socialService = resSocialService
            ? resSocialService
            : ownProps[socialServiceProp]
          console.log('socialService', socialService, ownProps.resourceId)
          return dispatch(
            requestConnectSocial(ownProps.resourceId, socialService),
          )
        },
      }),
    ),

    withIsRequestPending(REQUEST_CONNECT_SOCIAL, 'isRequestingSocialConnect'),
    withHasRequestError(REQUEST_CONNECT_SOCIAL, 'hasSocialConnectRequestError'),
    withIsRequestSucceeded(
      REQUEST_CONNECT_SOCIAL,
      'isSocialConnectRequestSucceeded',
    ),
    withDeleteRequestOnUnmount(REQUEST_CONNECT_SOCIAL),
    withRouteServerError(REQUEST_CONNECT_SOCIAL),
  )

export default withSocialConnect
