import React from 'react'
import PropTypes from 'prop-types'
import IconSocial from '../../icons/IconSocial'
import { RESOURCE_TYPES } from '../../../services/resources/constants'

const TabItem = ({ title, subtitle, iconType }) => {
  return (
    <div className="tab-item">
      <IconSocial type={iconType} />
      <div className="tab-info">
        <p className="tab-title">{title}</p>
        {subtitle && <p className="tab-subtitle">{subtitle}</p>}
      </div>
    </div>
  )
}

TabItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconType: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
}

export default TabItem
