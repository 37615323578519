import React from 'react'
import { compose, branch } from 'recompose'
import { Elements } from 'react-stripe-elements'
import Checkout from '../../ui/checkout/Checkout'
import CheckoutPaymentForm from './CheckoutPaymentForm'
import { STEPPER_NAV_ITEMS } from '../../services/checkout/business'
import withSpinner from '../../hocs/withSpinner'
import { FETCH_CART } from '../../services/checkout/action-types'
import withTranslations from '../../hocs/withTranslations'
import { withCart, withReachedCheckoutStep } from '../../services/checkout/hocs'
import withTotalCartPrice from './withTotalCartPrice'

const CheckoutPayment = ({ totalCartPrice, i18n, reachedNavItem, locale }) => {
  return (
    <Checkout
      title={i18n('sections:payment-long')}
      price={totalCartPrice}
      currentNavItem={STEPPER_NAV_ITEMS.PAYMENT}
      reachedNavItem={reachedNavItem}
      hasContentTabs={false}
    >
      <Elements
        locale={locale}
        fonts={[
          {
            cssSrc:
              'https://fonts.googleapis.com/css?family=Rubik:400&display=block',
          },
        ]}
      >
        <CheckoutPaymentForm />
      </Elements>
    </Checkout>
  )
}

const enhance = compose(
  withTranslations,
  withCart,
  // Do not show spinner if cart is already loaded
  // Prevents losing payment data if billing data modal is shown, which updates
  // cart and could make Stripe elements to lose their internal state
  branch(
    ({ cart }) => !cart,
    withSpinner(FETCH_CART, { i18nKey: 'status:loading-cart' }),
  ),
  withReachedCheckoutStep(STEPPER_NAV_ITEMS.PAYMENT),
  withTotalCartPrice,
)

export default enhance(CheckoutPayment)
