import { put, call, all, takeLatest, select } from 'redux-saga/effects'
import * as actionTypes from './action-types'
import * as api from './api'
import {
  startRequest,
  endRequestError,
  endRequestSuccess,
  startRequestWithId,
  endRequestWithIdError,
  endRequestWithIdSuccess,
} from '../communication/actions'
import * as hash from 'reduken/hash'
import * as entities from 'reduken/entities'
import {
  DOMAIN,
  HASH_KEY_CAMPAIGNS,
  HASH_KEY_LAST_CREATED_CAMPAIGN_ID,
  HASH_KEY_CAMPAIGNS_SUMMARY,
  ENTITY_KEY_CAMPAIGN_RESOURCE_TYPE_STATS,
  ENTITY_KEY_CAMPAIGN_SUMMARY,
  ENTITY_KEY_CAMPAIGN_ORDERS_BY_TYPE,
  ENTITY_KEY_CAMPAIGN_ORDERS_STATS,
  ENTITY_KEY_CAMPAIGN_SEO_URL_STATS,
  ENTITY_KEY_PUBLICATION_PREVIEWS,
} from './constants'
import { REFRESH_DASHBOARD_TIME } from '../dashboard/constants'
import { periodicFetchCampaignsSummary } from './actions'
import repeatingFetchEffect from '../repeating-fetch-effect'
import { push } from 'connected-react-router'
import { ROUTE_BRAND_CAMPAIGNS } from '../../app/common/routes'
import { STATISTICS_TABS } from './business'
import { getOrderPreviews } from './selectors'

function* fetchCampaignsSaga() {
  yield put(startRequest(actionTypes.FETCH_CAMPAIGNS))

  const response = yield call(api.fetchCampaigns)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_CAMPAIGNS, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_CAMPAIGNS, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_CAMPAIGNS))
}

function* createCampaignSaga({ payload: { name } }) {
  yield put(startRequest(actionTypes.CREATE_CAMPAIGN))

  const response = yield call(api.createCampaign, name)

  if (response.error) {
    yield put(endRequestError(actionTypes.CREATE_CAMPAIGN, response))
    return
  }

  yield call(fetchCampaignsSaga)

  yield put(
    hash.set(DOMAIN, HASH_KEY_LAST_CREATED_CAMPAIGN_ID, response.data.id),
  )

  yield put(endRequestSuccess(actionTypes.CREATE_CAMPAIGN))
}

function* deleteCampaignSaga({ payload: { campaignId } }) {
  yield put(startRequestWithId(actionTypes.DELETE_CAMPAIGN, campaignId))

  // PENDING: Only delete empty campaigns

  const response = yield call(api.deleteCampaign, campaignId)

  if (response.error) {
    yield put(
      endRequestWithIdError(actionTypes.DELETE_CAMPAIGN, campaignId, response),
    )
    return
  }

  yield put(push(ROUTE_BRAND_CAMPAIGNS.linkTo()))

  yield put(endRequestWithIdSuccess(actionTypes.DELETE_CAMPAIGN, campaignId))
}

function* renameCampaignSaga({ payload: { campaignId, name } }) {
  yield put(startRequestWithId(actionTypes.RENAME_CAMPAIGN, campaignId))

  const response = yield call(api.renameCampaign, campaignId, name)

  if (response.error) {
    yield put(
      endRequestWithIdError(actionTypes.RENAME_CAMPAIGN, campaignId, response),
    )
    return
  }

  yield put(
    entities.mergeEntity(
      ENTITY_KEY_CAMPAIGN_SUMMARY,
      campaignId,
      response.data,
    ),
  )

  yield put(endRequestWithIdSuccess(actionTypes.RENAME_CAMPAIGN, campaignId))
}

export function* fetchCampaignsSummarySaga() {
  yield put(startRequest(actionTypes.FETCH_CAMPAIGNS_SUMMARY))

  const response = yield call(api.fetchCampaignsSummary)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_CAMPAIGNS_SUMMARY, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_CAMPAIGNS_SUMMARY, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_CAMPAIGNS_SUMMARY))
}

export function* fetchCampaignSummaryByIdSaga({ payload: { id } }) {
  yield put(startRequestWithId(actionTypes.FETCH_CAMPAIGN_SUMMARY_BY_ID, id))

  const response = yield call(api.fetchCampaignsSummaryById, id)

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.FETCH_CAMPAIGN_SUMMARY_BY_ID,
        id,
        response,
      ),
    )
    return
  }

  yield put(
    entities.updateEntity(ENTITY_KEY_CAMPAIGN_SUMMARY, id, response.data),
  )

  yield put(
    endRequestWithIdSuccess(actionTypes.FETCH_CAMPAIGN_SUMMARY_BY_ID, id),
  )
}

function* fetchPublicationPreviewsSaga({ payload: { orderIds, fetchId } }) {
  yield put(startRequestWithId(actionTypes.FETCH_PUBLICATION_PREVIEWS, fetchId))

  for (const orderId of orderIds) {
    // If the orderId has a preview fetch next
    const cachedPreview = yield select(getOrderPreviews([orderId]))

    if (cachedPreview[0]) {
      continue
    }

    // Fetch publication preview
    const response = yield call(api.fetchPublicationPreview, orderId)

    if (response.error) {
      // Ignore preview not found exception
      const previewNotFound =
        response.errorData &&
        response.errorData.global_messages &&
        response.errorData.global_messages.OrderPreviewNotFoundException

      if (previewNotFound) {
        continue
      }

      // Handle other errors
      yield put(
        endRequestWithIdError(
          actionTypes.FETCH_PUBLICATION_PREVIEWS,
          fetchId,
          response,
        ),
      )
      return
    }

    yield put(
      entities.updateEntity(
        ENTITY_KEY_PUBLICATION_PREVIEWS,
        orderId,
        response.data,
      ),
    )
  }

  yield put(
    endRequestWithIdSuccess(actionTypes.FETCH_PUBLICATION_PREVIEWS, fetchId),
  )
}

function* fetchCampaignResourceTypeStatsSaga({
  payload: { campaignId, resourceType },
}) {
  const fetchId = `${campaignId}_${resourceType}`

  yield put(
    startRequestWithId(actionTypes.FETCH_CAMPAIGN_RESOURCE_TYPE_STATS, fetchId),
  )

  const response = yield call(
    api.fetchCampaignResourceTypeStats,
    campaignId,
    resourceType,
  )

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.FETCH_CAMPAIGN_RESOURCE_TYPE_STATS,
        fetchId,
        response,
      ),
    )
    return
  }

  yield put(
    entities.updateEntity(
      ENTITY_KEY_CAMPAIGN_RESOURCE_TYPE_STATS,
      fetchId,
      response.data,
    ),
  )

  yield put(
    endRequestWithIdSuccess(
      actionTypes.FETCH_CAMPAIGN_RESOURCE_TYPE_STATS,
      fetchId,
    ),
  )
}

function* fetchCampaignOrderListByTypeSaga({
  payload: { campaignId, resourceType },
}) {
  const fetchId = `${campaignId}_${resourceType}`

  yield put(
    startRequestWithId(actionTypes.FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE, fetchId),
  )

  const response = yield call(
    resourceType === STATISTICS_TABS.WEB_SEO
      ? api.fetchcampaignSEOURLs
      : api.fetchCampaignOrderListByType,
    campaignId,
    resourceType,
  )

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE,
        fetchId,
        response,
      ),
    )
    return
  }

  yield put(
    entities.updateEntity(
      ENTITY_KEY_CAMPAIGN_ORDERS_BY_TYPE,
      fetchId,
      response.data,
    ),
  )

  yield put(
    endRequestWithIdSuccess(
      actionTypes.FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE,
      fetchId,
    ),
  )
}

function* fetchCampaignOrderStatsSaga({ payload: { campaignId, orderId } }) {
  const fetchId = `${campaignId}_${orderId}`

  yield put(startRequestWithId(actionTypes.FETCH_CAMPAIGN_ORDER_STATS, fetchId))

  const response = yield call(api.fetchCampaignOrderStats, campaignId, orderId)

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.FETCH_CAMPAIGN_ORDER_STATS,
        fetchId,
        response,
      ),
    )
    return
  }

  yield put(
    entities.updateEntity(
      ENTITY_KEY_CAMPAIGN_ORDERS_STATS,
      fetchId,
      response.data,
    ),
  )

  yield put(
    endRequestWithIdSuccess(actionTypes.FETCH_CAMPAIGN_ORDER_STATS, fetchId),
  )
}

function* fetchCampaignSEOURLStatsSaga({ payload: { campaignId, seoUrl } }) {
  const fetchId = `${campaignId}_${seoUrl}`

  yield put(
    startRequestWithId(actionTypes.FETCH_CAMPAIGN_SEO_URL_STATS, fetchId),
  )

  const response = yield call(api.fetchCampaignSEOURLStats, campaignId, seoUrl)

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.FETCH_CAMPAIGN_SEO_URL_STATS,
        fetchId,
        response,
      ),
    )
    return
  }

  yield put(
    entities.updateEntity(
      ENTITY_KEY_CAMPAIGN_SEO_URL_STATS,
      fetchId,
      response.data,
    ),
  )

  yield put(
    endRequestWithIdSuccess(actionTypes.FETCH_CAMPAIGN_SEO_URL_STATS, fetchId),
  )
}

export default function*() {
  yield all([
    takeLatest(actionTypes.FETCH_CAMPAIGNS, fetchCampaignsSaga),
    takeLatest(actionTypes.CREATE_CAMPAIGN, createCampaignSaga),
    takeLatest(actionTypes.FETCH_CAMPAIGNS_SUMMARY, fetchCampaignsSummarySaga),
    takeLatest(
      actionTypes.FETCH_CAMPAIGN_SUMMARY_BY_ID,
      fetchCampaignSummaryByIdSaga,
    ),
    repeatingFetchEffect({
      fetchSaga: fetchCampaignsSummarySaga,
      intervalMillisecs: REFRESH_DASHBOARD_TIME,
      startAction: periodicFetchCampaignsSummary(),
      requestActionType: actionTypes.FETCH_CAMPAIGNS_SUMMARY,
      cancelActionType: actionTypes.CANCEL_PERIODIC_FETCH_CAMPAIGNS_SUMMARY,
    }),
    takeLatest(actionTypes.DELETE_CAMPAIGN, deleteCampaignSaga),
    takeLatest(actionTypes.RENAME_CAMPAIGN, renameCampaignSaga),
    takeLatest(
      actionTypes.FETCH_PUBLICATION_PREVIEWS,
      fetchPublicationPreviewsSaga,
    ),
    // Statistics
    takeLatest(
      actionTypes.FETCH_CAMPAIGN_RESOURCE_TYPE_STATS,
      fetchCampaignResourceTypeStatsSaga,
    ),
    takeLatest(
      actionTypes.FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE,
      fetchCampaignOrderListByTypeSaga,
    ),
    takeLatest(
      actionTypes.FETCH_CAMPAIGN_ORDER_STATS,
      fetchCampaignOrderStatsSaga,
    ),
    takeLatest(
      actionTypes.FETCH_CAMPAIGN_SEO_URL_STATS,
      fetchCampaignSEOURLStatsSaga,
    ),
  ])
}
