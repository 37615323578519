import withYoutubeForm from './withYoutubeForm'
import BriefingYoutubeForm from '../../ui/checkout/BriefingYoutubeForm'
import { compose, withPropsOnChange } from 'recompose'
import withTranslations from '../../hocs/withTranslations'
import { SERVICE_TYPES } from '../../services/orders/business'
import { submitYoutubeMentionBriefing } from '../../services/checkout'
import { connect } from 'react-redux'
import { withCart } from '../../services/checkout/hocs'

const enhance = compose(
  withTranslations,
  withCart,

  withPropsOnChange(['i18n'], ({ i18n }) => ({
    title: i18n('service-type:youtube-mention'),
  })),

  connect(
    null,
    { submitBriefing: submitYoutubeMentionBriefing },
  ),

  withYoutubeForm({
    serviceType: SERVICE_TYPES.YOUTUBE_MENTION,
    maxDuration: { minutes: 3, seconds: 0 },
  }),
)

export default enhance(BriefingYoutubeForm)
