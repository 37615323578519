// Repurposed from https://developer.twitter.com/en/docs/twitter-for-websites/web-intents/overview

;(function() {
  if (window.__facebookShareHandler) return
  var intentRegex = /facebook\.com\/sharer\/sharer.php/,
    windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes',
    width = 550,
    height = 420,
    winHeight = window.screen.height,
    winWidth = window.screen.width

  function handleShare(e) {
    e = e || window.event
    var target = e.target || e.srcElement,
      m,
      left,
      top

    while (target && target.nodeName.toLowerCase() !== 'a') {
      target = target.parentNode
    }

    if (target && target.nodeName.toLowerCase() === 'a' && target.href) {
      m = target.href.match(intentRegex)
      if (m) {
        left = Math.round(winWidth / 2 - width / 2)
        top = 0

        if (winHeight > height) {
          top = Math.round(winHeight / 2 - height / 2)
        }

        window.open(
          target.href,
          'intent',
          windowOptions +
            ',width=' +
            width +
            ',height=' +
            height +
            ',left=' +
            left +
            ',top=' +
            top,
        )
        e.returnValue = false
        e.preventDefault && e.preventDefault()
      }
    }
  }

  if (document.addEventListener) {
    document.addEventListener('click', handleShare, false)
  } else if (document.attachEvent) {
    document.attachEvent('onclick', handleShare)
  }
  window.__facebookShareHandler = true
})()
