import { connect } from 'react-redux'
import { getIsVIPBrand, getVATRate } from './selectors'

export const withIsVIPBrand = connect(state => ({
  isVIPBrand: getIsVIPBrand(state),
}))

export const withVATRate = connect(state => ({
  vatRate: getVATRate(state),
}))
