import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as Close } from '../../styles/icons/close.svg'
import { ReactComponent as AlertTriangle } from '../../styles/icons/alert-triangle.svg'
import { ReactComponent as CheckMark } from '../../styles/icons/checkmark.svg'
import { ReactComponent as Flag } from '../../styles/icons/flag.svg'
import HelpLink from './HelpLink'

const Snackbar = ({
  title,
  description,
  helpLink,
  type,
  isVisible,
  hasIcon,
  onClose,
}) => {
  const classes = cx('snackbar', {
    [`is-type-${type}`]: type !== 'default',
    'is-visible': isVisible,
  })
  return (
    <div className={classes}>
      <div className="snackbar-wrapper">
        <p className="snackbar-title">
          {hasIcon && type === 'default' && <Flag className="icon" />}
          {hasIcon && (type === 'warning' || type === 'error') && (
            <AlertTriangle className="icon" />
          )}
          {hasIcon && type === 'success' && <CheckMark className="icon" />}
          <span className="text">{title}</span>
        </p>
        {description && description.trim().length > 0 && (
          <p className="snackbar-description">{description}</p>
        )}
        {helpLink && (
          <div className="snackbar-helplink">
            <HelpLink url={helpLink.url} text={helpLink.text} hasIcon={true} />
          </div>
        )}
        <button className="snackbar-close-button" onClick={onClose}>
          {<Close />}
        </button>
      </div>
    </div>
  )
}

Snackbar.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  helpLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
  type: PropTypes.oneOf(['default', 'error', 'warning', 'success']),
  isVisible: PropTypes.bool,
  hasIcon: PropTypes.bool,
  onClose: PropTypes.func,
}

export default Snackbar
