import React from 'react'
import PropTypes from 'prop-types'

const AffiliatesSection = ({ title, children }) => {
  return (
    <div className="affiliates-section">
      <h2 className="affiliates-section-title">{title}</h2>
      {children}
    </div>
  )
}

AffiliatesSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default AffiliatesSection
