import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'

const UserNavigationItem = ({ children, to, isActive, onClick }) => {
  const classes = cx('user-navigation-item', {
    'is-active': isActive,
  })

  return (
    <li>
      <Link className={classes} to={to} onClick={onClick}>
        {children}
      </Link>
    </li>
  )
}

UserNavigationItem.defaultProps = {
  isActive: false,
}

UserNavigationItem.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default UserNavigationItem
