import React from 'react'
import PropTypes from 'prop-types'
import IconIndicator from '../indicators/IconIndicator'

const WarningMessage = ({ text, iconType }) => {
  const type = iconType || 'warning'
  return (
    <div className="warning-message">
      <IconIndicator type={type} />
      <div className="warning-message-text">{text}</div>
    </div>
  )
}

WarningMessage.propTypes = {
  text: PropTypes.element.isRequired,
  iconType: PropTypes.oneOf(['warning', 'fill-warning']),
}

export default WarningMessage
