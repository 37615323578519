import PropTypes from 'prop-types'
import * as R from 'ramda'
import { i18nErrorMessagePropType } from '../../hocs/withFormErrors'
import { ORDER_STATUS } from '../../services/orders/business'
import { SERVICE_TYPES } from '../../services/orders/business'
import { COOBIS_CURRENCIES } from '../../config/formats'

const makeFormikPropTypes = fieldPropTypes => ({
  values: PropTypes.shape(fieldPropTypes).isRequired,
  errors: PropTypes.shape(
    R.map(R.always(i18nErrorMessagePropType), fieldPropTypes),
  ).isRequired,
  touched: PropTypes.shape(R.map(R.always(PropTypes.bool), fieldPropTypes))
    .isRequired,
  isValid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
})

const order = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(SERVICE_TYPES)).isRequired,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)).isRequired,
  resource: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
})

const orderRow = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(SERVICE_TYPES)).isRequired,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)).isRequired,
  resource: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
})

const closedOrderRow = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(SERVICE_TYPES)).isRequired,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)).isRequired,
  price: PropTypes.number.isRequired,
  statusDate: PropTypes.instanceOf(Date).isRequired,
})

const orderDates = PropTypes.shape({
  acceptDate: PropTypes.string,
  sendToReviewDate: PropTypes.string,
  validateReviewDate: PropTypes.string,
  deliveryDate: PropTypes.string,
  approveDate: PropTypes.string,
  preDelivery: PropTypes.bool.isRequired,
  express: PropTypes.bool.isRequired,
})

const currentOrderRow = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(SERVICE_TYPES)).isRequired,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)).isRequired,
  price: PropTypes.number.isRequired,
  remainDays: PropTypes.number.isRequired,
  brandName: PropTypes.string.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  dates: orderDates.isRequired,
})

const campaign = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  reach: PropTypes.number.isRequired,
  engagement: PropTypes.number.isRequired,
  orders: PropTypes.number.isRequired,
  ordersPending: PropTypes.number.isRequired,
})

const navItem = PropTypes.shape({
  i18nKey: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
})

const currency = PropTypes.shape({
  isoCode: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
})

const money = PropTypes.shape({
  integer: PropTypes.number.isRequired,
  decimal: PropTypes.number.isRequired,
  currency: currency.isRequired,
})

const price = PropTypes.shape({
  [COOBIS_CURRENCIES.EUR.isoCode]: PropTypes.number.isRequired,
  [COOBIS_CURRENCIES.USD.isoCode]: PropTypes.number.isRequired,
})

const duration = PropTypes.shape({
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
})

const makeSelectOption = valuePropType =>
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: valuePropType.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  })

const googleAnalyticsProfile = PropTypes.shape({
  id: PropTypes.string,
  profile: PropTypes.string,
  url: PropTypes.string,
  visits: PropTypes.number,
  users: PropTypes.number,
  webs: PropTypes.number,
})

const facebookProfile = PropTypes.shape({
  id: PropTypes.string,
  profile: PropTypes.string,
  link: PropTypes.string,
  fanCount: PropTypes.number,
})

const graphLegendOptions = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isDisabled: PropTypes.bool,
    isFixed: PropTypes.bool,
  }),
)

const demographicData = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
  }),
)

const uploadedFile = PropTypes.shape({
  clientFileName: PropTypes.string,
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
  mimeType: PropTypes.string,
})

const urlFile = PropTypes.shape({
  uploadedFile: uploadedFile,
  url: PropTypes.string,
})

const CoobisPropTypes = {
  makeFormikPropTypes,
  order,
  orderRow,
  campaign,
  navItem,
  makeSelectOption,
  closedOrderRow,
  orderDates,
  currentOrderRow,
  money,
  currency,
  price,
  duration,
  googleAnalyticsProfile,
  facebookProfile,
  graphLegendOptions,
  demographicData,
  uploadedFile,
  urlFile,
}

export default CoobisPropTypes
