import * as Yup from 'yup'
import * as R from 'ramda'
import { formatBytes } from '../utils'
import { SERVICE_TYPES } from '../../services/orders/business'

export const i18nValidationKeys = {
  INVALID_MAIL: 'errors:invalid-mail',
  INVALID_IBAN: 'errors:invalid-iban',
  REQUIRED: 'errors:required',
  MIN: 'errors:min',
  MIN_DATE: 'errors:min-date',
  MIN_RELATIVE_DATE: 'errors:min-relative-date',
  MIN_RELATIVE_TO_NOW: 'errors:min-relative-to-now',
  MIN_RELATIVE_TO_ACCEPTANCE_DATE: 'errors:min-relative-to-acceptance-date',
  MIN_RELATIVE_TO_SEND_TO_REVIEW_DATE:
    'errors:min-relative-to-send-to-review-date',
  MIN_RELATIVE_TO_VALIDATE_REVIEW_DATE:
    'errors:min-relative-to-validate-review-date',
  MIN_RELATIVE_TO_DELIVERY_DATE: 'errors:min-relative-to-delivery-date',
  MAX: 'errors:max',
  MAX_DATE: 'errors:max-date',
  MAX_RELATIVE_DATE: 'errors:max-relative-date',
  MAX_RELATIVE_TO_NOW: 'errors:max-relative-to-now',
  MAX_RELATIVE_TO_ACCEPTANCE_DATE: 'errors:max-relative-to-acceptance-date',
  MAX_RELATIVE_TO_SEND_TO_REVIEW_DATE:
    'errors:max-relative-to-send-to-review-date',
  MAX_RELATIVE_TO_VALIDATE_REVIEW_DATE:
    'errors:max-relative-to-validate-review-date',
  MAX_RELATIVE_TO_DELIVERY_DATE: 'errors:max-relative-to-delivery-date',
  BEFORE_END_DATE: 'errors:before-end-date',
  AFTER_START_DATE: 'errors:after-start-date',
  LENGTH: 'errors:length',
  MIN_LENGTH: 'errors:min-length',
  MAX_LENGTH: 'errors:max-length',
  PASSWORDS_DO_NOT_MATCH: 'errors:passwords-do-not-match',
  MUST_ACCEPT_TERMS: 'errors:must-accept-terms',
  INVALID_URL: 'errors:invalid-url',
  REQUIRE_IBAN_OR_PAYPAL: 'errors:require-iban-or-paypal',
  LOWER_THAN_MAX_DURATION: 'errors:lower-than-max-duration',
  HIGHER_THAN_MIN_DURATION: 'errors:higher-than-min-duration',
  EXCEED_MAX_FILE_SIZE: 'errors:exceed-max-file-size',
  REQUIRE_FILE_OR_URL: 'errors:require-file-or-url',
  REQUIRE_GOOGLE_ANALYTICS_PROFILE: 'errors:require-google-analytics-profile',
  REQUIRE_SOCIAL_PROFILE_ID_SELECTED:
    'errors:require-social-profile-id-selected',
  INVALID_VALUE: 'errors:invalid-value',
  INVALID_FILE_EXTENSION: 'errors:invalid-file-extension',
  UNKNOWN: 'errors:unknown',
  IDENTITY_DOCUMENT_TOO_BIG_FOR_UPLOAD:
    'errors:identity-document-too-big-for-upload',
  IDENTITY_DOCUMENT_EXCEED_MAX_FILE_SIZE:
    'errors:identity-document-exceeds-max-file-size',
  IDENTITY_DOCUMENT_FORMAT_NOT_ALLOWED:
    'errors:identity-document-file-format-not-allowed',
  TYPE_DOCUMENT_TOO_BIG_FOR_UPLOAD: 'errors:type-document-too-big-for-upload',
  TYPE_DOCUMENT_EXCEED_MAX_FILE_SIZE:
    'errors:type-document-exceeds-max-file-size',
  TYPE_DOCUMENT_FORMAT_NOT_ALLOWED:
    'errors:type-document-file-format-not-allowed',
  RESIDENCE_DOCUMENT_TOO_BIG_FOR_UPLOAD:
    'errors:residence-document-too-big-for-upload',
  RESIDENCE_DOCUMENT_EXCEED_MAX_FILE_SIZE:
    'errors:residence-document-exceeds-max-file-size',
  RESIDENCE_DOCUMENT_FORMAT_NOT_ALLOWED:
    'errors:residence-document-file-format-not-allowed',
  INVALID_IDENTY_DOCUMENT_NUMBER: 'errors:invalid-identity-document-number',
  INVALID_POSTAL_CODE: 'errors:invalid-postal-code',
}

export const i18nErrorKeys = {
  UNKNOWN: 'errors:unknown',
  USER_NOT_FOUND: 'errors:user-not-found',
  RECAPTCHA_FAILED_ERROR: 'errors:recaptcha-failed',
  PASSWORD_RESET_TOKEN_NOT_FOUND: 'errors:password-reset-token-not-found',
  NOT_FOUND: 'errors:not-found',
  WRONG_PASSWORD: 'errors:wrong-password',
  PHOTO_NOT_FOUND: 'errors:photo-not-found',

  BRAND_EXISTS: 'errors:brand-exists',
  BRAND_NOT_FOUND: 'errors:brand-not-found',
  IDENTITY_DOCUMENT_CANNOT_BE_CHANGED:
    'errors:identity-document-cannot-be-changed',
  CURRENCY_CANNOT_CHANGE_WITH_PENDING_PAYMENTS:
    'errors:currency-cannot-change-with-pending-payments',
  CAMPAIGN_ALREADY_EXIST: 'errors:campaign-already-exists',
  CAMPAIGN_NOT_FOUND: 'errors:campaign-not-found',
  CAMPAIGN_CANNOT_BE_DELETED: 'errors:campaign-cannot-be-deleted',
  SHOPPING_CART_NOT_FOUND: 'errors:shopping-cart-not-found',
  SHOPPING_CART_UPLOADED_DOCUMENT_NOT_FOUND:
    'errors:shopping-cart-uploaded-document-not-found',

  MEDIA_EXISTS: 'errors:media-exists',
  MEDIA_NOT_FOUND: 'errors:media-not-found',

  COUPON_NOT_FOUND: 'errors:coupon-not-found',
  COUPON_EXPIRED: 'errors:coupon-expired',
  COUPON_MAX_USES: 'errors:coupon-max-uses',
  COUPON_MIN_AMOUNT: 'errors:coupon-min-amount',
  COUPON_MAX_AMOUNT: 'errors:coupon-max-amount',
  PAYMENT_WRONG_AMOUNT: 'errors:payment-wrong-amount',
  INSUFFICIENT_FUNDS: 'errors:insufficient-funds',
  WITHDRAW_WRONG_AMOUNT: 'errors:withdraw-wrong-amount',
  WITHDRAW_INVOICE_ALREADY_EXISTS: 'errors:withdraw-invoice-already-exists',
  WITHDRAW_WRONG_FINAL_AMOUNT: 'errors:withdraw-wrong-final-amount',
  WITHDRAW_WRONG_TAXES_AMOUNT: 'errors:withdraw-wrong-taxes-amount',

  RESOURCE_NOT_FOUND: 'errors:resource-not-found',
  RESOURCE_URL_EXIST: 'errors:resource-url-exist',

  ORDER_NOT_FOUND: 'errors:order-not-found',
  ORDER_UPLOADED_FILE_EXCEED_MAX_FILE_SIZE:
    'errors:order-uploaded-file-exceeds-max-file-size',
  ORDER_UPLOADED_FILE_FORMAT_NOT_ALLOWED:
    'errors:order-uploaded-file-format-not-allowed',

  TRACKER_MISSING: 'errors:tracker-missing',

  ORDER_DELIVERY_URL_RESOURCE_MISMATCH:
    'errors:order-delivery-url-resource-mismatch',
}

// i18nValidation Keys for translate yup form errors
export const i18nValidation = {
  invalidMail: values => ({ key: i18nValidationKeys.INVALID_MAIL, values }),
  invalidIBAN: values => ({ key: i18nValidationKeys.INVALID_IBAN, values }),
  required: values => ({ key: i18nValidationKeys.REQUIRED, values }),
  min: values => ({ key: i18nValidationKeys.MIN, values }),
  minDate: values => ({ key: i18nValidationKeys.MIN_DATE, values }),
  minRelativeDate: values => ({
    key: i18nValidationKeys.MIN_RELATIVE_DATE,
    values,
  }),
  minRelativeToNow: values => ({
    key: i18nValidationKeys.MIN_RELATIVE_TO_NOW,
    values,
  }),
  minRelativeToAcceptanceDate: values => ({
    key: i18nValidationKeys.MIN_RELATIVE_TO_ACCEPTANCE_DATE,
    values,
  }),
  minRelativeToSendToReviewDate: values => ({
    key: i18nValidationKeys.MIN_RELATIVE_TO_SEND_TO_REVIEW_DATE,
    values,
  }),
  minRelativeToValidateReviewDate: values => ({
    key: i18nValidationKeys.MIN_RELATIVE_TO_VALIDATE_REVIEW_DATE,
    values,
  }),
  minRelativeToDeliveryDate: values => ({
    key: i18nValidationKeys.MIN_RELATIVE_TO_DELIVERY_DATE,
    values,
  }),
  max: values => ({ key: i18nValidationKeys.MAX, values }),
  maxDate: (values, ...rest) => ({ key: i18nValidationKeys.MAX_DATE, values }),
  maxRelativeDate: values => ({
    key: i18nValidationKeys.MAX_RELATIVE_DATE,
    values,
  }),
  maxRelativeToNow: values => ({
    key: i18nValidationKeys.MAX_RELATIVE_TO_NOW,
    values,
  }),
  maxRelativeToAcceptanceDate: values => ({
    key: i18nValidationKeys.MAX_RELATIVE_TO_ACCEPTANCE_DATE,
    values,
  }),
  maxRelativeToSendToReviewDate: values => ({
    key: i18nValidationKeys.MAX_RELATIVE_TO_SEND_TO_REVIEW_DATE,
    values,
  }),
  maxRelativeToValidateReviewDate: values => ({
    key: i18nValidationKeys.MAX_RELATIVE_TO_VALIDATE_REVIEW_DATE,
    values,
  }),
  maxRelativeToDeliveryDate: values => ({
    key: i18nValidationKeys.MAX_RELATIVE_TO_DELIVERY_DATE,
    values,
  }),
  beforeEndDate: values => ({
    key: i18nValidationKeys.BEFORE_END_DATE,
    values,
  }),
  afterStartDate: values => ({
    key: i18nValidationKeys.AFTER_START_DATE,
    values,
  }),
  length: values => ({ key: i18nValidationKeys.LENGTH, values }),
  minLength: values => ({ key: i18nValidationKeys.MIN_LENGTH, values }),
  maxLength: values => ({ key: i18nValidationKeys.MAX_LENGTH, values }),
  passwordsDoNotMatch: values => ({
    key: i18nValidationKeys.PASSWORDS_DO_NOT_MATCH,
    values,
  }),
  mustAcceptTerms: values => ({
    key: i18nValidationKeys.MUST_ACCEPT_TERMS,
    values,
  }),
  invalidURL: values => ({
    key: i18nValidationKeys.INVALID_URL,
    values,
  }),
  requireIBANOrPaypal: values => ({
    key: i18nValidationKeys.REQUIRE_IBAN_OR_PAYPAL,
    values,
  }),
  lowerThanMaxDuration: values => ({
    key: i18nValidationKeys.LOWER_THAN_MAX_DURATION,
    values,
  }),
  higherThanMinDuration: values => ({
    key: i18nValidationKeys.HIGHER_THAN_MIN_DURATION,
    values,
  }),
  exceedMaxFileSize: values => ({
    key: i18nValidationKeys.EXCEED_MAX_FILE_SIZE,
    values,
  }),
  requireFileOrUrl: values => ({
    key: i18nValidationKeys.REQUIRE_FILE_OR_URL,
    values,
  }),
  requireGoogleAnalyticsProfile: values => ({
    key: i18nValidationKeys.REQUIRE_GOOGLE_ANALYTICS_PROFILE,
    values,
  }),
  cif: values => ({
    key: i18nValidationKeys.INVALID_IDENTY_DOCUMENT_NUMBER,
    values,
  }),
  requireSocialProfileIdSelected: values => ({
    key: i18nValidationKeys.REQUIRE_SOCIAL_PROFILE_ID_SELECTED,
    values,
  }),
}

export const BasicRegisterDataSchema = Yup.object().shape({
  name: Yup.string().required(i18nValidation.required),
  surname: Yup.string().required(i18nValidation.required),
  email: Yup.string()
    .email(i18nValidation.invalidMail)
    .required(i18nValidation.required),
  password: Yup.string()
    .required(i18nValidation.required)
    .min(8, i18nValidation.minLength),
  phone: Yup.string().required(i18nValidation.required),
  confirmPassword: Yup.string()
    .required(i18nValidation.required)
    .oneOf([Yup.ref('password')], i18nValidation.passwordsDoNotMatch),
  acceptance: Yup.boolean()
    .required(i18nValidation.required)
    .oneOf([true], i18nValidation.mustAcceptTerms),
})

// From https://stackoverflow.com/questions/44656264/iban-regex-design
export const IBAN_REGEXP = /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})$/

// Provided by Antevenio's backend. Pending checks that they're correct.
export const FACEBOOK_URL_REGEXP = /https?:\/\/(www\.)?facebook\.com\/(pages\/([^"&?/ ]+)\/)?([^"&?/ ]+)/i
export const TWITTER_URL_REGEXP = /https?:\/\/(www\.)?twitter\.com\/([^"&?/ ]+)/i
export const INSTAGRAM_URL_REGEXP = /https?:\/\/(www\.)?(es\.)?instagram\.com\/([^"&?/ ]+)/i

export const validateMaxFileSize = (
  maxFileSize,
  i18nMessage = i18nValidation.exceedMaxFileSize,
) => ({
  name: 'File max size',
  params: {
    max: formatBytes(maxFileSize),
  },
  message: i18nMessage,
  test: value => {
    if (!value) return true

    const fileSize = value.size
    return !R.isNil(fileSize) && fileSize < maxFileSize
  },
})

export const testRelativeDateMinHours = (
  relativeTo,
  relativeToDefault,
  hours,
  message = i18nValidation.minRelativeDate,
) => ({
  name: 'testRelativeDateMinHours',
  exclusive: false,
  params: {
    relativeTo: relativeTo.path || relativeTo,
    hours,
  },
  message,
  test: function(value) {
    if (R.isNil(value)) {
      return true
    }

    const actualRelativeTo =
      this.resolve(relativeTo) || this.resolve(relativeToDefault)

    const minDate = +actualRelativeTo + this.resolve(hours) * 3600000

    return +value >= minDate
  },
})

export const testRelativeDateMaxHours = (
  relativeTo,
  relativeToDefault,
  hours,
  message = i18nValidation.maxRelativeDate,
) => ({
  name: 'testRelativeDateMaxHours',
  exclusive: false,
  params: {
    relativeTo: relativeTo.path || relativeTo,
    hours,
  },
  message,
  test: function(value) {
    if (R.isNil(value)) {
      return true
    }

    const actualRelativeTo =
      this.resolve(relativeTo) || this.resolve(relativeToDefault)

    const maxDate = +actualRelativeTo + this.resolve(hours) * 3600000

    return +value <= maxDate
  },
})

export const getPostUrlRegexpForServiceType = serviceType => {
  switch (serviceType) {
    case SERVICE_TYPES.FACEBOOK: {
      return /https?:\/\/(www\.|m\.)?facebook\.com\/([\w.-]+\/(videos|posts|photos)\/|(permalink\.php|story.php)\?(.+&)*story_fbid=).+/i
    }

    case SERVICE_TYPES.TWITTER: {
      return /https?:\/\/(www\.)?twitter\.com\/\w+\/status\/\d+/i
    }

    case SERVICE_TYPES.TWITCH: {
      return /https?:\/\/(www\.)?twitch\.tv\/(\w+\/clip\/\w+|videos\/\d+)/i
    }

    case SERVICE_TYPES.INSTAGRAM_VIDEO: {
      return /https?:\/\/(www\.)?instagram.com\/(p|tv)\/.+/i
    }

    case SERVICE_TYPES.INSTAGRAM_PHOTO: {
      return /https?:\/\/(www\.)?instagram.com\/p\/.+/i
    }

    case SERVICE_TYPES.YOUTUBE_DEDICATED:
    case SERVICE_TYPES.YOUTUBE_MENTION: {
      return /https?:\/\/(www\.)?(youtube\.com\/watch\?(.+&)*v=|youtu\.be\/).+/i
    }
    case SERVICE_TYPES.TIKTOK_SHORT:
    case SERVICE_TYPES.TIKTOK_LONG: {
      return /^https?:\/\/www\.tiktok\.com\/@[\w.]+\/video\/\d+\/?|^https?:\/\/vm\.tiktok\.com\/[\w.]+\/?/i
    }

    default: {
      return /https?:\/\/([A-Z]+\.)?[A-Z]+\.[A-Z]+\/\w+/
    }
  }
}

export const fixPostUrlToMatchServiceType = (url, serviceType) => {
  switch (serviceType) {
    case SERVICE_TYPES.TIKTOK_SHORT:
    case SERVICE_TYPES.TIKTOK_LONG: {
      const matches = getPostUrlRegexpForServiceType(serviceType).exec(
        url.trim(),
      )
      return matches ? matches[0] : url
    }
    default: {
      return url
    }
  }
}
