import * as R from 'ramda'
import { isNilOrEmpty, formatTimeObject } from '../../app/utils'
import moment from 'moment'
import { SERVICE_TYPES } from '../../services/orders/business'
import { BRIEFING_WEB_GOALS } from '../../services/checkout/business'
import { AuthScope } from '../../services/auth/business'

const parseDate = date => (!R.isNil(date) ? moment(date) : undefined)

const joinWithPrefix = R.curry((prefix, arr) =>
  !isNilOrEmpty(arr) ? `${prefix}${arr.join(` ${prefix}`)}` : null,
)

const orderDataToOrderTags = (orderData, i18n) => {
  const orderType = R.path(['product', 'service'], orderData)

  switch (orderType) {
    case SERVICE_TYPES.WEB_ONLY_POST:
    case SERVICE_TYPES.WEB_POST: {
      return [
        {
          title: i18n('checkout:label-title'),
          description: R.path(['briefing', 'html', 'titleTag'], orderData),
        },
        {
          title: i18n('checkout:label-description'),
          description: R.path(
            ['briefing', 'html', 'descriptionTag'],
            orderData,
          ),
        },
        {
          title: i18n('checkout:url-page-to-publish'),
          description: R.path(['briefing', 'html', 'slug'], orderData),
        },
      ]
    }

    case SERVICE_TYPES.YOUTUBE_DEDICATED:
    case SERVICE_TYPES.YOUTUBE_MENTION: {
      return [
        {
          title: i18n('checkout:title-words'),
          description: R.pipe(
            R.path(['briefing', 'titleWords']),
            R.join(', '),
          )(orderData),
        },
        {
          title: i18n('checkout:description-words'),
          description: R.pipe(
            R.path(['briefing', 'descriptionWords']),
            R.join(', '),
          )(orderData),
        },
        {
          title: i18n('checkout:description-tags'),
          description: R.pipe(
            R.pathOr([], ['briefing', 'hashtags']),
            R.filter(R.complement(isNilOrEmpty)),
            joinWithPrefix('#'),
          )(orderData),
        },
        {
          title: i18n('checkout:link-url'),
          description: R.path(['briefing', 'url'], orderData),
        },
      ]
    }

    case SERVICE_TYPES.TWITCH: {
      return [
        {
          title: i18n('checkout:campaign-hashtags'),
          description: R.pipe(
            R.pathOr([], ['briefing', 'hashtags']),
            R.filter(R.complement(isNilOrEmpty)),
            joinWithPrefix('#'),
          )(orderData),
        },
        {
          title: i18n('checkout:url-streaming-description'),
          description: R.path(['briefing', 'url'], orderData),
        },
        {
          title: i18n('checkout:add-text-chat'),
          description: R.path(['briefing', 'sampleMessage'], orderData),
        },
      ]
    }

    default: {
      const tags = []

      const SERVICES_WITH_HASHTAGS = [
        SERVICE_TYPES.FACEBOOK,
        SERVICE_TYPES.TWITTER,
        SERVICE_TYPES.INSTAGRAM_PHOTO,
        SERVICE_TYPES.INSTAGRAM_VIDEO,
        SERVICE_TYPES.INSTAGRAM_STORY,
      ]

      const SERVICES_WITH_URL = [
        SERVICE_TYPES.FACEBOOK,
        SERVICE_TYPES.TWITTER,
        SERVICE_TYPES.INSTAGRAM_STORY,
      ]

      const SERVICES_WITH_WORDS = [SERVICE_TYPES.FACEBOOK]

      const SERVICES_WITH_MENTIONS = [
        SERVICE_TYPES.FACEBOOK,
        SERVICE_TYPES.TWITTER,
        SERVICE_TYPES.INSTAGRAM_PHOTO,
        SERVICE_TYPES.INSTAGRAM_VIDEO,
        SERVICE_TYPES.INSTAGRAM_STORY,
      ]

      if (SERVICES_WITH_HASHTAGS.includes(orderType)) {
        tags.push({
          title: i18n('checkout:campaign-hashtags'),
          description: R.pipe(
            R.pathOr([], ['briefing', 'hashtags']),
            R.filter(R.complement(isNilOrEmpty)),
            joinWithPrefix('#'),
          )(orderData),
        })
      }

      if (SERVICES_WITH_URL.includes(orderType)) {
        tags.push({
          title: i18n('checkout:campaign-url'),
          description: R.path(['briefing', 'url'], orderData),
        })
      }

      if (SERVICES_WITH_WORDS.includes(orderType)) {
        tags.push({
          title: i18n('checkout:campaign-words'),
          description: R.pipe(
            R.path(['briefing', 'words']),
            R.join(', '),
          )(orderData),
        })
      }

      if (SERVICES_WITH_MENTIONS.includes(orderType)) {
        tags.push({
          title: i18n('order-detail:user-mentions'),
          description: R.pipe(
            R.pathOr([], ['briefing', 'userMentions']),
            R.filter(R.complement(isNilOrEmpty)),
            joinWithPrefix('@'),
          )(orderData),
        })
      }

      return tags
    }
  }
}

const orderDataToViewProps = (orderData, scope, i18n) => {
  const commonData = {
    resourceName: R.pathOr('', ['product', 'name'], orderData),
    brandName: R.propOr('', 'brand', orderData),
    brandUrl: R.propOr('', 'url', orderData),
    orderType: R.path(['product', 'service'], orderData),
    orderStatus: R.propOr('', 'status', orderData),
    hasActions: true,
    campaignId: R.propOr(null, 'campaignId', orderData),
    deliveryDates: {
      acceptanceDate: parseDate(R.path(['dates', 'acceptDate'], orderData)),
      approvalDate: parseDate(R.path(['dates', 'approveDate'], orderData)),
      deliveryDate: parseDate(R.path(['dates', 'deliveryDate'], orderData)),
      reviewDeliveryDate: parseDate(
        R.path(['dates', 'sendToReviewDate'], orderData),
      ),
      reviewDate: parseDate(R.path(['dates', 'validateReviewDate'], orderData)),
      hasExpressDelivery: R.path(['dates', 'express'], orderData),
    },
    postDescription: R.path(['briefing', 'description'], orderData),
    sampleMessage: R.ifElse(
      R.pathEq(['product', 'service'], SERVICE_TYPES.TWITCH),
      R.always(undefined),
      R.path(['briefing', 'sampleMessage']),
    )(orderData),
    urlSocialNetworks: R.path(['briefing', 'urlSocialNetworks'], orderData),
    hasPredelivery: R.path(['dates', 'preDelivery'], orderData),

    orderDocument: R.pipe(
      R.pathOr([], ['briefing', 'files']),
      R.map(file => ({
        url: file.url,
        size: R.path(['uploadedFile', 'fileSize'], file),
        title: R.path(['uploadedFile', 'clientFileName'], file),
      })),
    )(orderData),

    orderPredelivery: {
      comment: R.path(['predeliveryPayload', 'comments'], orderData),
      url: R.path(['predeliveryPayload', 'url'], orderData),
      fileUrl: R.path(['predeliveryPayload', 'urlFile', 'url'], orderData),
      fileSize: R.path(
        ['predeliveryPayload', 'urlFile', 'uploadedFile', 'fileSize'],
        orderData,
      ),
      fileTitle: R.path(
        ['predeliveryPayload', 'urlFile', 'uploadedFile', 'clientFileName'],
        orderData,
      ),
    },

    orderDelivery: {
      comment: R.path(['deliveryPayload', 'comments'], orderData),
      url: R.path(['deliveryPayload', 'url'], orderData),
      facebook: R.path(['deliveryPayload', 'facebook'], orderData),
      twitter: R.path(['deliveryPayload', 'twitter'], orderData),
      instagram: R.path(['deliveryPayload', 'instagram'], orderData),
      fileUrl: R.path(['deliveryPayload', 'urlFile', 'url'], orderData),
      fileSize: R.path(
        ['deliveryPayload', 'urlFile', 'uploadedFile', 'fileSize'],
        orderData,
      ),
      fileTitle: R.path(
        ['deliveryPayload', 'urlFile', 'uploadedFile', 'clientFileName'],
        orderData,
      ),
    },

    rating: R.prop('rating', orderData),
  }

  const { minVideoLength, maxVideoLength } = R.pathOr(
    {},
    ['briefing'],
    orderData,
  )

  const orderIsWebType =
    R.pathEq(['product', 'service'], SERVICE_TYPES.WEB_POST, orderData) ||
    R.pathEq(['product', 'service'], SERVICE_TYPES.WEB_ONLY_POST, orderData)

  return orderIsWebType
    ? {
        ...commonData,
        campaignObjective: R.path(['briefing', 'goal'], orderData),
        showDeliveryHelp: true,
        // Goal branding and service web-post only for medias
        showTrackerHelp:
          R.pathEq(
            ['briefing', 'goal'],
            BRIEFING_WEB_GOALS.BRANDING,
            orderData,
          ) && scope === AuthScope.MEDIA,
        trackerTag: R.path(['briefing', 'trackerTag', 'tag'], orderData),
        acceptsBroadcasts: R.path(['product', 'broadcast'], orderData),
        words: R.path(['product', 'words'], orderData),

        orderTags: R.filter(
          tag => !isNilOrEmpty(tag.description),
          orderDataToOrderTags(orderData, i18n),
        ),

        orderLinks: R.filter(
          link => !isNilOrEmpty(link.url),
          R.pathOr([], ['briefing', 'links'], orderData),
        ),
        orderAdvices: R.path(['briefing', 'tips'], orderData),
      }
    : {
        ...commonData,
        orderTags: R.filter(
          tag => !isNilOrEmpty(tag.description),
          orderDataToOrderTags(orderData, i18n),
        ),
        orderUploadCondition: R.path(['briefing', 'conditionId'], orderData),
        isAdvertising: R.path(['briefing', 'isAdvertising'], orderData),

        minVideoLength: minVideoLength
          ? formatTimeObject(minVideoLength)
          : null,
        maxVideoLength: maxVideoLength
          ? formatTimeObject(maxVideoLength)
          : null,
      }
}

export default orderDataToViewProps
