import React from 'react'
import PropTypes from 'prop-types'
import ModalPortalPreventScroll from './ModalPortalPreventScroll'
import ModalView from '../../ui/modals/Modal'

const Modal = ({
  modalRootId,
  onClose,
  children,
  closeOnClickOutside,
  isDarkThemed,
}) => (
  <ModalPortalPreventScroll modalRootId={modalRootId}>
    <ModalView
      onClose={onClose}
      closeOnClickOutside={closeOnClickOutside}
      isDarkThemed={isDarkThemed}
    >
      {children}
    </ModalView>
  </ModalPortalPreventScroll>
)

Modal.propTypes = {
  modalRootId: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  closeOnClickOutside: PropTypes.bool,
  isDarkThemed: PropTypes.bool,
}

export default Modal
