import React from 'react'
import PropTypes from 'prop-types'

const FilterSection = ({ title, children }) => (
  <section className="filter-section">
    {title && <h3 className="filter-section-title">{title}</h3>}
    {children}
  </section>
)

FilterSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
}

export default FilterSection
