import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import DashboardPanel from './DashboardPanel'
import { MediaQueryLap, MediaQueryPalm } from '../shared/MediaQueries'
import Slider from 'react-slick'
import { isNilOrEmpty } from '../../app/utils'
import NoFavsCard from '../cards/NoFavsCard'
import CardPlaceholder from '../cards/CardPlaceholder'
import Spinner from '../loaders/Spinner'

const FavouritesPanel = ({ resources, isLoading, renderFavCard, i18n }) => {
  const SLIDER_SETTINGS = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <DashboardPanel size="auto" title={i18n('noun:favourites')}>
      {isLoading ? (
        <div className="loading-dashboard-section-placeholder">
          <CardPlaceholder>
            <Spinner size={60} />
          </CardPlaceholder>
        </div>
      ) : !isNilOrEmpty(resources) ? (
        <div className="favourites-panel-content">
          <MediaQueryPalm>
            {
              <Slider {...SLIDER_SETTINGS}>
                {resources.map(renderFavCard)}
              </Slider>
            }
          </MediaQueryPalm>
          <MediaQueryLap>{resources.map(renderFavCard)}</MediaQueryLap>
        </div>
      ) : (
        <NoFavsCard />
      )}
    </DashboardPanel>
  )
}

FavouritesPanel.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
      price: PropTypes.number,
      finalPrice: PropTypes.number,
      isUserVerified: PropTypes.bool,
      isNew: PropTypes.bool,
      isFavourited: PropTypes.bool,
      acceptsBroadcast: PropTypes.bool,
      isBroadcastChecked: PropTypes.bool,
      broadcastSocialNetworks: PropTypes.arrayOf(
        PropTypes.oneOf([RESOURCE_TYPES.FACEBOOK, RESOURCE_TYPES.TWITTER]),
      ),
      selectedService: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      services: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          isDisabled: PropTypes.bool,
        }),
      ),
      holidaysEndDate: PropTypes.Object,
    }),
  ),
  isLoading: PropTypes.bool,
  onFavClick: PropTypes.func,
  onCartClick: PropTypes.func,
  onServiceChange: PropTypes.func,
  onBroadcastChange: PropTypes.func,
  i18n: PropTypes.func,
  renderFavCard: PropTypes.func,
}

export default withTranslations(FavouritesPanel)
