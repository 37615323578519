import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Error404Message from '../../ui/global-message/Error404Message'
import BrandDashboard from './BrandDashboard'
import { withEnsureMyInfo } from '../../services/me/hocs'
import withRouteServerError from '../../hocs/withRouteServerError'
import { compose, branch } from 'recompose'
import { FETCH_WALLET } from '../../services/wallet/action-types'
import {
  ROUTE_BRAND_DASHBOARD,
  ROUTE_ROOT,
  ROUTE_BRAND_PROFILE,
  ROUTE_BRAND_PERSONAL_DATA,
  ROUTE_BRAND_RESOURCES_SEARCH,
  ROUTE_BRAND_CHECKOUT,
  ROUTE_BRAND_CHECKOUT_GENERAL_DATA,
  ROUTE_BRAND_CHECKOUT_BRIEFING,
  ROUTE_BRAND_CHECKOUT_PAYMENT,
  ROUTE_BRAND_RESOURCES_BY_ID,
  ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID,
  ROUTE_BRAND_CAMPAIGNS,
  ROUTE_BRAND_CAMPAIGNS_BY_ID,
  ROUTE_BRAND_CAMPAIGNS_ORDER_STATS_BY_ID,
  ROUTE_BRAND_WALLET,
  ROUTE_BRAND_CAMPAIGNS_URL_STATS_BY_ID,
} from '../common/routes'
import Search from '../search/Search'
import withFetchConfig from '../../hocs/withFetchConfig'
import withEffect from '../../hocs/withEffect'
import withSpinner from '../../hocs/withSpinner'
import { FETCH_BRAND } from '../../services/brand/action-types'
import BrandProfile from './BrandProfile'
import {
  fetchBrand,
  getBrandInfo,
  periodicFetchBrandStatus,
} from '../../services/brand'
import { FETCH_CART } from '../../services/checkout/action-types'
import CheckoutGeneralData from '../checkout/CheckoutGeneralData'
import CheckoutBriefing from '../checkout/CheckoutBriefing'
import CheckoutPayment from '../checkout/CheckoutPayment'
import ResourceDetail from '../search/ResourceDetail'
import OrderDetailBrand from '../orders/OrderDetailBrand'
import CampaignsDashboard from '../campaigns/CampaignsDashboard'
import CampaignPublicationDetail from '../campaigns/CampaignPublicationDetail'
import CampaignDetail from '../campaigns/CampaignDetail'
import { withFetchNotifications } from '../../services/notifications/hocs'
import { fetchMyTours, getIsWelcomeBrandTourViewed } from '../../services/tour'
import { withFetchAlerts } from '../../services/alerts/hocs'

const BrandRoutes = props => (
  <Switch>
    <Route exact path={ROUTE_BRAND_DASHBOARD.path} component={BrandDashboard} />

    {/* RESOURCES */}

    <Route exact path={ROUTE_BRAND_RESOURCES_SEARCH.path} component={Search} />

    <Route
      exact
      path={ROUTE_BRAND_RESOURCES_BY_ID.path}
      component={ResourceDetail}
    />

    {/* CAMPAIGNS */}

    <Route
      exact
      path={ROUTE_BRAND_CAMPAIGNS.path}
      component={CampaignsDashboard}
    />

    <Route
      exact
      path={ROUTE_BRAND_CAMPAIGNS_BY_ID.path}
      component={CampaignDetail}
    />

    <Route
      exact
      path={ROUTE_BRAND_CAMPAIGNS_URL_STATS_BY_ID.path}
      component={CampaignPublicationDetail}
    />

    <Route
      exact
      path={ROUTE_BRAND_CAMPAIGNS_ORDER_STATS_BY_ID.path}
      component={CampaignPublicationDetail}
    />

    {/* Order detail */}
    <Route
      exact
      path={ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.path}
      component={OrderDetailBrand}
    />

    {/* PROFILE */}

    <Route
      exact
      path={ROUTE_BRAND_PERSONAL_DATA.path}
      component={BrandProfile}
    />

    {/* WALLET AND MOVEMENTS */}

    <Route exact path={ROUTE_BRAND_WALLET.path} component={BrandProfile} />

    {/* Profile root (which has no dedicated page) redirects to personal data */}
    <Route
      exact
      path={ROUTE_BRAND_PROFILE.path}
      render={_ => <Redirect to={ROUTE_BRAND_PERSONAL_DATA.linkTo()} />}
    />

    {/* CHECKOUT */}

    <Route
      exact
      path={ROUTE_BRAND_CHECKOUT_GENERAL_DATA.path}
      component={CheckoutGeneralData}
    />

    <Route
      exact
      path={ROUTE_BRAND_CHECKOUT_BRIEFING.path}
      component={CheckoutBriefing}
    />

    <Route
      exact
      path={ROUTE_BRAND_CHECKOUT_PAYMENT.path}
      component={CheckoutPayment}
    />

    {/* Checkout root (which has no dedicated page) redirects to general data section */}
    <Route
      exact
      path={ROUTE_BRAND_CHECKOUT.path}
      render={_ => <Redirect to={ROUTE_BRAND_CHECKOUT_GENERAL_DATA.linkTo()} />}
    />

    {/* GENERIC */}

    {/* Root redirects to dashboard */}
    <Route
      exact
      path={ROUTE_ROOT.path}
      render={_ => <Redirect to={ROUTE_BRAND_DASHBOARD.linkTo()} />}
    />

    {/* If none matched, 404 */}
    <Route component={Error404Message} />
  </Switch>
)

const enhance = compose(
  connect(
    state => ({
      brandInfo: getBrandInfo(state),
      isWelcomeBrandTourViewed: getIsWelcomeBrandTourViewed(state),
    }),
    { fetchBrand, fetchMyTours, periodicFetchBrandStatus },
  ),

  withEffect(
    props => {
      props.fetchBrand()
      props.fetchMyTours()
      props.periodicFetchBrandStatus()
    },
    ({ fetchBrand, fetchMyTours, periodicFetchBrandStatus }) => [
      fetchBrand,
      fetchMyTours,
      periodicFetchBrandStatus,
    ],
  ),

  withFetchConfig,
  withFetchNotifications,
  withFetchAlerts,
  withEnsureMyInfo,
  withRouteServerError(FETCH_WALLET),

  branch(({ brandInfo }) => !brandInfo, withSpinner(FETCH_BRAND)),
  withRouteServerError(FETCH_BRAND),

  withRouteServerError(FETCH_CART),
)

export default enhance(BrandRoutes)
