import React from 'react'
import { compose, branch } from 'recompose'
import Checkout from '../../ui/checkout/Checkout'
import withTranslations from '../../hocs/withTranslations'
import withTotalCartPrice from './withTotalCartPrice'
import { withCart, withReachedCheckoutStep } from '../../services/checkout/hocs'
import CheckoutGeneralDataForm from './CheckoutGeneralDataForm'
import { FETCH_CAMPAIGNS } from '../../services/campaigns/action-types'
import withRouteServerError from '../../hocs/withRouteServerError'

import {
  FETCH_DATES_CONFIG,
  FETCH_CART,
} from '../../services/checkout/action-types'
import { STEPPER_NAV_ITEMS } from '../../services/checkout/business'
import withSpinner from '../../hocs/withSpinner'

const CheckoutGeneralData = ({ totalCartPrice, i18n, reachedNavItem }) => (
  <Checkout
    title={i18n('sections:data-long')}
    price={totalCartPrice}
    currentNavItem={STEPPER_NAV_ITEMS.GENERAL}
    reachedNavItem={reachedNavItem}
    hasContentTabs={false}
  >
    <CheckoutGeneralDataForm />
  </Checkout>
)

const enhance = compose(
  withTranslations,
  withCart,
  // Do not show spinner in cart periodic fetches
  branch(
    ({ cart }) => !cart,
    withSpinner(FETCH_CART, { i18nKey: 'status:loading-cart' }),
  ),
  withReachedCheckoutStep(STEPPER_NAV_ITEMS.GENERAL),
  withTotalCartPrice,
  withRouteServerError(FETCH_CAMPAIGNS),
  withRouteServerError(FETCH_DATES_CONFIG),
)

export default enhance(CheckoutGeneralData)
