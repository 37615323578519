import pathToRegexp from 'path-to-regexp'
import { matchPath } from 'react-router'
import {
  RESOURCE_TYPES,
  SOCIAL_NETWORK_RESOURCE_TYPES,
} from '../../services/resources/constants'
import { BRIEFINGS_ORDER } from '../../services/checkout/business'
import { ORDER_DETAIL_TABS } from '../orders/withOrderDetailTabs'
import { STATISTICS_TABS } from '../../services/campaigns/business'

// Utils

const makePath = path => ({
  path,
  regexp: pathToRegexp(path),
  linkTo: pathToRegexp.compile(path),
  match: (pathname, options = {}) => matchPath(pathname, { ...options, path }),
})

export const prettifyConstant = string =>
  string.replace(/_/g, '-').toLowerCase()

export const unprettifyConstant = string =>
  string.replace(/-/g, '_').toUpperCase()

const orderDetailsTabRegexpGroup = `(${ORDER_DETAIL_TABS.join('|')})`

// Public routes

export const ROUTE_DEBUG = makePath('/debug')

export const ROUTE_PASSWORD_RECOVERY_REQUEST = makePath('/forgot-password')

export const ROUTE_PASSWORD_RECOVERY_RESET = makePath('/reset-password/:token')

// Brand

export const ROUTE_BRAND_DASHBOARD = makePath('/brand')
export const ROUTE_BRAND_DASHBOARD_MATCH = /^\/brand\/?$/

export const ROUTE_BRAND_WELCOME = makePath('/brand/welcome')

// Brand: list resources

export const ROUTE_BRAND_RESOURCES_SEARCH = makePath('/brand/resources')
export const ROUTE_BRAND_RESOURCES_BY_ID = makePath('/brand/resources/:id')
export const ROUTE_BRAND_RESOURCES_MATCH = /^\/brand\/resources/

// Brand: list campaigns

export const ROUTE_BRAND_CAMPAIGNS = makePath('/brand/campaigns')
export const ROUTE_BRAND_CAMPAIGNS_MATCH = /^\/brand\/campaigns/

const statisticsRegexpGroup = `(${Object.values(STATISTICS_TABS)
  .map(prettifyConstant)
  .join('|')})`

export const ROUTE_BRAND_CAMPAIGNS_BY_ID = makePath(
  `/brand/campaigns/:campaignId/:resourceType${statisticsRegexpGroup}?`,
)
export const ROUTE_BRAND_CAMPAIGNS_URL_STATS_BY_ID = makePath(
  `/brand/campaigns/:campaignId/:resourceType(${prettifyConstant(
    STATISTICS_TABS.WEB_SEO,
  )})/urls/:seoUrl`,
)
export const ROUTE_BRAND_CAMPAIGNS_ORDER_STATS_BY_ID = makePath(
  `/brand/campaigns/:campaignId/:resourceType${statisticsRegexpGroup}/orders/:orderId`,
)
export const ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID = makePath(
  `/brand/campaigns/:campaignId/orders/:orderId/:selectedTab${orderDetailsTabRegexpGroup}?`,
)

// Brand: profile

export const ROUTE_BRAND_PROFILE = makePath('/brand/me')

export const ROUTE_BRAND_PERSONAL_DATA = makePath('/brand/me/personal-data')
export const ROUTE_BRAND_PERSONAL_DATA_MATCH = /^\/brand\/me\/personal-data/

export const ROUTE_BRAND_WALLET = makePath('/brand/me/wallet')
export const ROUTE_BRAND_WALLET_ADD_FUNDS = makePath(
  '/brand/me/wallet/add-funds',
)
export const ROUTE_BRAND_WALLET_MATCH = /^\/brand\/me\/wallet/

// Brand: checkout

export const ROUTE_BRAND_CHECKOUT = makePath('/brand/checkout')
export const ROUTE_BRAND_CHECKOUT_GENERAL_DATA = makePath(
  '/brand/checkout/general-data',
)

const briefingRegexpGroup = `(${BRIEFINGS_ORDER.map(prettifyConstant).join(
  '|',
)})`

export const ROUTE_BRAND_CHECKOUT_BRIEFING = makePath(
  `/brand/checkout/briefing/:serviceType${briefingRegexpGroup}?`,
)
export const ROUTE_BRAND_CHECKOUT_PAYMENT = makePath('/brand/checkout/payment')
export const ROUTE_BRAND_CHECKOUT_MATCH = /^\/brand\/checkout/

// Media

export const ROUTE_MEDIA_DASHBOARD = makePath('/media')
export const ROUTE_MEDIA_DASHBOARD_MATCH = /^\/media\/?$/

export const ROUTE_MEDIA_WELCOME = makePath('/media/welcome')

// Media: profile

export const ROUTE_MEDIA_PROFILE = makePath('/media/me')

export const ROUTE_MEDIA_PERSONAL_DATA = makePath('/media/me/personal-data')
export const ROUTE_MEDIA_PERSONAL_DATA_MATCH = /^\/media\/me\/personal-data/

export const ROUTE_MEDIA_WALLET = makePath('/media/me/wallet')
export const ROUTE_MEDIA_WALLET_WITHDRAW_FUNDS = makePath(
  '/media/me/wallet/withdraw-funds',
)
export const ROUTE_MEDIA_WALLET_MATCH = /^\/media\/me\/wallet/

// Media: List resources

export const ROUTE_MEDIA_RESOURCES = makePath('/media/resources')
export const ROUTE_MEDIA_RESOURCES_MATCH = /^\/media\/resources/

// Media: Create resources

export const ROUTE_MEDIA_CREATE_RESOURCES_DISTRIBUTOR = makePath(
  '/media/resources/create',
)

// Media: Create/edit web resources

const webRouteFragment = prettifyConstant(RESOURCE_TYPES.WEB)

export const ROUTE_MEDIA_CREATE_WEB_RESOURCES = makePath(
  `/media/resources/create/${webRouteFragment}`,
)

export const ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID = makePath(
  `/media/resources/edit/${webRouteFragment}/:id/general-data`,
)
export const ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID = makePath(
  `/media/resources/edit/${webRouteFragment}/:id/social-networks`,
)
export const ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID = makePath(
  `/media/resources/edit/${webRouteFragment}/:id/analytics`,
)
export const ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID = makePath(
  `/media/resources/edit/${webRouteFragment}/:id/services`,
)

// Media: Create/edit social network resources

const socialNetworkRegexpGroup = `(${SOCIAL_NETWORK_RESOURCE_TYPES.map(
  prettifyConstant,
).join('|')})`

export const ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE = makePath(
  `/media/resources/create/:socialNetwork${socialNetworkRegexpGroup}`,
)

export const ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA = makePath(
  `/media/resources/edit/:socialNetwork${socialNetworkRegexpGroup}/:id/general-data`,
)

export const ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES = makePath(
  `/media/resources/edit/:socialNetwork${socialNetworkRegexpGroup}/:id/services`,
)

// Media: orders

export const ROUTE_MEDIA_ORDER_BY_ID = makePath(
  `/media/orders/:orderId/:selectedTab${orderDetailsTabRegexpGroup}?`,
)

export const ROUTE_MEDIA_AFFILIATES = makePath('/media/affiliates')
export const ROUTE_MEDIA_AFFILIATES_MATCH = /^\/media\/affiliates/

// Generic

export const ROUTE_ROOT = makePath('/')
