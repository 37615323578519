import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import Card from './Card'
import IconSocial from '../icons/IconSocial'
import Avatar from '../avatar/Avatar'
import CardBadge from '../cards/CardBadge'
import ButtonDropdown from '../dropdown/ButtonDropdown'
import { ReactComponent as IconDots } from '../../styles/icons/ellipsis.svg'
import { ReactComponent as IconTrash } from '../../styles/icons/delete.svg'
import { ReactComponent as IconEdit } from '../../styles/icons/edit.svg'
import { ReactComponent as IconStats } from '../../styles/icons/stats.svg'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import Spinner from '@redradix/components.spinner'
import { ReactComponent as Spin } from '../../styles/icons/spinner.svg'

const ResourceCard = ({
  alias,
  date,
  image,
  name,
  type,
  isUserVerified,
  onEdit,
  onDelete,
  onUpdateStats,
  isUpdatingStats,
  i18n,
}) => {
  return (
    <Card isHighlighted>
      <div className="resource-card">
        <div className="resource-card-social">
          <IconSocial type={type} />
        </div>
        <div>
          <ButtonDropdown
            content={[
              {
                buttonIcon: props => <IconEdit {...props} />,
                isWarning: false,
                action: onEdit,
                actionText: 'action:edit-resource',
              },
              {
                buttonIcon: props =>
                  isUpdatingStats ? (
                    <Spinner isLoading>
                      <Spin
                        className="icon rdx-button-icon"
                        style={{ padding: '0.15em' }}
                      />
                    </Spinner>
                  ) : (
                    <IconStats {...props} style={{ padding: '0.15em' }} />
                  ),
                isWarning: false,
                action: onUpdateStats,
                actionText: 'action:update-resource-stats',
              },
              {
                buttonIcon: props => <IconTrash {...props} />,
                isWarning: true,
                action: onDelete,
                actionText: 'action:remove-resource',
              },
            ]}
            renderIcon={props => <IconDots {...props} />}
          />
        </div>
        <figure className="resource-card-user">
          <Avatar image={image} avatarName={name} />
          <div>
            <h2 className="resource-card-name">
              {name}
              {isUserVerified && <CardBadge isVerified={isUserVerified} />}
            </h2>
            <p className="resource-card-alias">{alias}</p>
          </div>
        </figure>
        <div className="resource-card-date">
          {i18n('noun:creation-date')}: <span>{date}</span>
        </div>
      </div>
    </Card>
  )
}

ResourceCard.propTypes = {
  alias: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  isUserVerified: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdateStats: PropTypes.func,
  isUpdatingStats: PropTypes.bool,
  // withTranslations
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceCard)
