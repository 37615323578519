import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'

const CancelledOrdersHeadRow = props => {
  const { i18n } = props
  return (
    <div className="order-head-row">
      <div className="order-head-row-item orders-cancelled-table-status">
        {i18n('noun:status')}
      </div>
      <div className="order-head-row-item orders-cancelled-table-resource">
        {i18n('noun:media')}
      </div>
      <div className="order-head-row-item orders-cancelled-table-service">
        {i18n('noun:service')}
      </div>
      <div className="order-head-row-item orders-cancelled-table-date">
        {i18n('noun:creation-date')}
      </div>
    </div>
  )
}

CancelledOrdersHeadRow.propTypes = {
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(CancelledOrdersHeadRow)
