import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withTranslations from '../../hocs/withTranslations'
import { FormattedMessage } from 'react-intl'
import Card from './Card'
import AnchorLinkButton from '../texts/AnchorLinkButton'
import Notification from '../blocks/Notification'
import useScrollEnds from '../../hooks/useScrollEnds'

const NotificationsCard = ({
  notifications,
  hasTitle,
  isMarkAllLoading,
  onMarkAllClick,
  onNotificationClick,

  i18n,
}) => {
  const { handleScroll, setScrollerRef, scrollEnds } = useScrollEnds({
    top: 20,
    bottom: 20,
  })

  const frameClasses = cx('notifications-card-frame', {
    'has-top-gradient': !scrollEnds.top,
    'has-bottom-gradient': !scrollEnds.bottom,
  })

  const handleNotificationClick = useCallback(
    notification => () => onNotificationClick(notification),
    [onNotificationClick],
  )

  return (
    <div className="notifications-card">
      <Card isHighlighted>
        <div className="notifications-card-header">
          <div className="notifications-card-title">
            {hasTitle && `${i18n('noun:notifications')}`}
          </div>
          <AnchorLinkButton
            type="button"
            text={i18n('action:mark-all-as-read')}
            onClick={onMarkAllClick}
            isLoading={isMarkAllLoading}
          />
        </div>
        <div className="notifications-card-contents">
          <div
            className={frameClasses}
            ref={setScrollerRef}
            onScroll={handleScroll}
          >
            <div className="notifications-card-frame-contents">
              <div className="notifications-card-list">
                {notifications.map(notification => (
                  <Notification
                    key={notification.id}
                    photoURL={notification.photoURL}
                    date={notification.date}
                    onClick={handleNotificationClick(notification)}
                    titleSlot={
                      notification.text ? (
                        notification.text
                      ) : (
                        <FormattedMessage
                          id={`${notification.actionText}`}
                          defaultMessage={`${notification.actionText}`}
                          values={{
                            username: (
                              <span className="username">{`${notification.username}`}</span>
                            ),
                            resourcename: (
                              <span className="username">{`${notification.resourceName}`}</span>
                            ),
                            brandname: (
                              <span className="username">{`${notification.brandName}`}</span>
                            ),
                          }}
                        />
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

NotificationsCard.propTypes = {
  notifications: PropTypes.array,
  hasTitle: PropTypes.bool,
  isMarkAllLoading: PropTypes.bool,
  onMarkAllClick: PropTypes.func,
  onNotificationClick: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(NotificationsCard)
