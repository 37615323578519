import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from '@redradix/components.button'

const AnchorLinkButton = props => {
  const { isDisabled, isLoading, onClick, text, type, small } = props
  const classes = cx('anchor-link', {
    sm: small,
    'is-disabled': isDisabled,
  })

  return (
    <Button
      className={classes}
      type={type}
      hierarchy="tertiary"
      variant="destruct"
      widthBehaviour="adaptative"
      heightBehaviour="adaptative"
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {text}
    </Button>
  )
}

AnchorLinkButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
}

export default AnchorLinkButton
