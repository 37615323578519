import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import OrderStatus from './OrderStatus'
import OrderType from './OrderType'
import withTranslations from '../../hocs/withTranslations'
import {
  mapServiceTypeToOrderTypeI18n,
  mapServiceTypeToResourceType,
  mapServiceTypeToI18nWithoutWords,
  ORDER_STATUS,
} from '../../services/orders/business'
import { SERVICE_TYPES } from '../../services/orders/business'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import HackFormattedMoney from '../HackFormattedMoney'

const OrderClosedRow = props => {
  const { linkUrl, status, type, brand, resource, date, price, i18n } = props
  return (
    <Link to={linkUrl} className="order-row">
      <div className="order-row-item orders-closed-table-status">
        <OrderStatus status={status} />
      </div>
      <div className="order-row-item orders-closed-table-brand" title={brand}>
        {brand}
      </div>
      <div
        className="order-row-item orders-closed-table-resource"
        title={resource}
      >
        {resource}
      </div>
      <div
        className="order-row-item orders-closed-table-service"
        title={i18n(mapServiceTypeToI18nWithoutWords[type])}
      >
        <OrderType
          i18nKey={mapServiceTypeToOrderTypeI18n[type]}
          icon={mapServiceTypeToResourceType[type]}
        />
      </div>
      <div className="order-row-item orders-closed-table-date">
        <FormattedDate format="coobis" value={date} />
      </div>
      <div className="order-row-item orders-closed-table-price">
        <HackFormattedMoney value={price} />
      </div>
    </Link>
  )
}

OrderClosedRow.propTypes = {
  linkUrl: PropTypes.string,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)),
  type: PropTypes.oneOf(Object.values(SERVICE_TYPES)),
  brand: PropTypes.string,
  resource: PropTypes.string,
  statusDate: PropTypes.instanceOf(Date),
  price: CoobisPropTypes.money,
}

export default withTranslations(OrderClosedRow)
