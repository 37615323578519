import { compose, withPropsOnChange, withState } from 'recompose'
import queryString from 'query-string'
import withEffect from '../../hocs/withEffect'

const withTemporaryResourceId = compose(
  withPropsOnChange(['location'], ({ location }) => ({
    temporaryResourceId: queryString.parse(location.search).temporaryResourceId,
    hasJustConnected: queryString.parse(location.search).hasJustConnected,
  })),

  // We want to persist last known temporary id. Otherwise it gets reinitialized
  // when the URL param is removed. Needed to:
  // - Know if the form data should have an empty profile id. Otherwise it gets
  //   reinitialized when the URL param is removed.
  // - Show synchronization success message and data.
  // - Send it on form submit.
  withState('lastTemporaryResourceId', 'setLastTemporaryResourceId'),
  withEffect(
    ({
      temporaryResourceId,
      lastTemporaryResourceId,
      setLastTemporaryResourceId,
    }) => {
      if (
        !!temporaryResourceId &&
        temporaryResourceId !== lastTemporaryResourceId
      ) {
        setLastTemporaryResourceId(temporaryResourceId)
      }
    },
    ({
      temporaryResourceId,
      lastTemporaryResourceId,
      setLastTemporaryResourceId,
    }) => [
      temporaryResourceId,
      lastTemporaryResourceId,
      setLastTemporaryResourceId,
    ],
  ),

  withPropsOnChange(
    ['lastTemporaryResourceId'],
    ({ lastTemporaryResourceId }) => ({
      isSelectingProfile: !!lastTemporaryResourceId,
    }),
  ),
)

export default withTemporaryResourceId
