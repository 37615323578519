import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

const ME_ENDPOINT = `${envConfig.authBackendUrl}/me`

export function* fetchMe() {
  const response = yield call(getRequest, ME_ENDPOINT, { parseJSON: true })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}
