import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as QuestionMark } from '../../styles/icons/question-mark.svg'

const TooltipTrigger = forwardRef((props, ref) => {
  const { classNames, isLight, onMouseOver, onMouseOut } = props
  const classes = cx('tooltip-trigger', classNames, {
    'is-light': isLight,
  })

  return (
    <span
      className={classes}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      ref={ref}
    >
      <QuestionMark className="icon" />
    </span>
  )
})

TooltipTrigger.propTypes = {
  isLight: PropTypes.bool,
  classNames: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
}

export default TooltipTrigger
