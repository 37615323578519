import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@redradix/components.checkbox'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import Panel from '../panels/Panel'
import InputSuffix from '../form-elements/InputSuffix'
import PaperInfo from '../papers/PaperInfo'
import { MediaQueryPortable, MediaQueryDesk } from '../shared/MediaQueries'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import Tooltip from '@redradix/components.tooltip'
import FormattedCurrency from '../FormattedCurrency'

const getTooltipWithOptions = (options, i18n) => {
  return (
    <Tooltip
      position={options.position}
      widthFixed={options.widthFixed}
      renderTooltip={() => (
        <>
          <TooltipRegularText text={i18n('web-resources:prices-tooltip')} />
        </>
      )}
      renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
        <TooltipTrigger
          classNames={triggerClasses}
          onMouseOver={open}
          onMouseOut={close}
          ref={triggerRef}
        />
      )}
    />
  )
}

const TwitterServicesPrices = ({
  values,
  calculatedValues,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  isPricesVipResource,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  const touchedAndWithErrors = useCallback(
    fieldName =>
      touched[fieldName] &&
      validationMessages[fieldName] &&
      Array.isArray(validationMessages[fieldName]),
    [touched, validationMessages],
  )

  return (
    <form className="basic-form" onSubmit={handleSubmit}>
      <div className="indented-block">
        <Checkbox
          name="enabled"
          checked={values.enabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.enabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('social-resource:tweet-publication')}</p>
          <p className="subtitle">
            {i18n('social-resource:tweet-publication-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="basePrice"
              name="basePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.basePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.basePrice}
              isDisabled={
                isRequestPending || !isPricesVipResource || !values.enabled
              }
              suffix={<FormattedCurrency value={values.currency} />}
            />
            <InputSuffix
              type="number"
              id="discount"
              name="discount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              value={values.discount}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.discount}
              isDisabled={isRequestPending || !values.enabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="totalPrice"
              name="totalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.totalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.currency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('basePrice') ||
          touchedAndWithErrors('discount') ||
          validationMessages.totalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.basePrice || []),
                  ...(validationMessages.discount || []),
                  ...(validationMessages.totalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>

      <div className="indented-block">
        <PaperInfo type="notice">
          {i18n('social-resource:price-considerations')}
        </PaperInfo>
      </div>

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  enabled: PropTypes.bool,
  basePrice: PropTypes.string,
  discount: PropTypes.string,
}

TwitterServicesPrices.propTypes = {
  calculatedValues: PropTypes.shape({
    totalPrice: PropTypes.number,
  }),
  isPricesVipResource: PropTypes.bool,
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(TwitterServicesPrices)
