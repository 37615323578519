import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import * as R from 'ramda'
import ReactiveRangeSlider from './ReactiveRangeSlider'
import { getFiltersWithout, FILTERS } from './filterUtils'
import { MultiList, MultiDropdownList } from '@appbaseio/reactivesearch'
import MultiSelect from './MultiSelect'
import { getCategories, getCountries } from '../../services/config'
import { getLanguage } from '../../services/me'
import ReactiveRangeCheckbox from './ReactiveRangeCheckbox'
import FilterSection from '../../ui/search/FilterSection'
import FilterGroup from '../../ui/search/FilterGroup'
import withTranslations from '../../hocs/withTranslations'
import RatingFilter from './RatingFilter'
import ReactiveCheckbox from './ReactiveCheckbox'
import { withMyInfo } from '../../services/me/hocs'
import { connect as reactiveConnect } from '@appbaseio/reactivesearch/lib/utils'
import LoaderOverlay from '../../ui/loaders/LoaderOverlay'
import Spinner from '../../ui/loaders/Spinner'
import ReactiveRangeMultiCheckbox from './ReactiveRangeMultiCheckbox'
import FilterGroupColumn from '../../ui/search/FilterGroupColumn'
import ReactiveMultiSelect from './ReactiveMultiSelect'
import MultiSelectSearchable from './MultiSelectSearchable'

import {
  COOBIS_CURRENCY_OPTIONS,
  COOBIS_PERCENT_OPTIONS,
  COOBIS_WITHOUT_DECIMALS,
} from '../../config/formats'
import {
  RESOURCE_TYPES,
  mapResourceTypeToI18N,
} from '../../services/resources/constants'
import { getBrandCurrency } from '../../services/brand'

const GENRE_RANGE_FILTER = { gte: 0.5 }
const AGE_RANGE_FILTER = { gte: 0.3 }
const DISCOUNT_RANGE_FILTER = { gt: 0 }
const COUNTRY_AUDIENCE_FILTER = {
  range: { 'statistics.countryDemographics.density': { gte: 0.3 } },
}

const SearchFilters = ({
  categories,
  countries,
  language,
  priceFormatOptions,
  priceDataField,
  myInfo,
  i18n,
  firstLoadingFilters,
}) => {
  const AGE_OPTIONS = useMemo(
    () => ({
      age18_24: {
        label: i18n('search-filter:age-18-24'),
        dataField: 'statistics.demographics.age18_24',
        rangeFilter: AGE_RANGE_FILTER,
      },
      age25_34: {
        label: i18n('search-filter:age-25-34'),
        dataField: 'statistics.demographics.age25_34',
        rangeFilter: AGE_RANGE_FILTER,
      },
      age35_44: {
        label: i18n('search-filter:age-35-44'),
        dataField: 'statistics.demographics.age35_44',
        rangeFilter: AGE_RANGE_FILTER,
      },
      age45_64: {
        label: i18n('search-filter:age-45-64'),
        dataField: 'statistics.demographics.age45_64',
        rangeFilter: AGE_RANGE_FILTER,
      },
      'age65+': {
        label: i18n('search-filter:age-65+'),
        dataField: 'statistics.demographics.age65',
        rangeFilter: AGE_RANGE_FILTER,
      },
    }),
    [i18n],
  )

  const translateChannel = useCallback(
    id => i18n(mapResourceTypeToI18N[id] || `noun:${id}`),
    [i18n],
  )

  const translateCategory = useCallback(
    id => R.pathOr(id, [id, 'translations', language], categories),
    [categories, language],
  )

  const translateCountry = useCallback(
    id => R.pathOr(id, [id, 'translations', language], countries),
    [countries, language],
  )

  const translateLanguage = useCallback(id => i18n(`language:${id}`), [i18n])

  const translatedCategoryKeys = useMemo(() => R.keys(categories), [categories])

  const translatedCountryKeys = useMemo(
    () =>
      R.pipe(
        R.keys,
        R.sort((a, b) => a.localeCompare(b)),
      )(countries),
    [countries],
  )

  return (
    <>
      {firstLoadingFilters && (
        <LoaderOverlay>
          <Spinner text={i18n('status:loading-filters')} size={38} />
        </LoaderOverlay>
      )}

      <form noValidate>
        <FilterSection title={i18n('noun:channels')}>
          <FilterGroup>
            <MultiList
              componentId={FILTERS.channels}
              dataField="type.keyword"
              showLoadMore={false}
              showCount={false}
              showSearch={false}
              react={{
                and: getFiltersWithout(FILTERS.channels),
              }}
              URLParams={true}
              innerClass={{
                title: 'filter-group-title',
                list: 'filter-group-contents filter-group-list',
              }}
              render={props => (
                <MultiSelect
                  {...props}
                  allData={Object.values(RESOURCE_TYPES)}
                  translateKey={translateChannel}
                />
              )}
            />
          </FilterGroup>
        </FilterSection>

        <FilterSection title={i18n('noun:categories')}>
          <FilterGroup>
            <MultiList
              componentId={FILTERS.categories}
              dataField="categories.id.keyword"
              nestedField="categories"
              showCount={true}
              showLoadMore={false}
              showSearch={false}
              react={{
                and: getFiltersWithout(FILTERS.categories),
              }}
              URLParams={true}
              className="searchable-list"
              innerClass={{
                list: 'filter-group-list',
              }}
              render={props => (
                <MultiSelect
                  {...props}
                  size={5}
                  hasCount={true}
                  translateKey={translateCategory}
                  allData={translatedCategoryKeys}
                  isAlphabeticSorted={true}
                />
              )}
            />
          </FilterGroup>
        </FilterSection>

        <FilterSection title={i18n('noun:language')}>
          <FilterGroup>
            <MultiDropdownList
              componentId={FILTERS.language}
              dataField="language.keyword"
              sortBy="count"
              showCount={true}
              react={{
                and: getFiltersWithout(FILTERS.language),
              }}
              showFilter={false}
              URLParams={true}
              innerClass={{
                select: 'filter-group-multiselect',
              }}
              render={props => (
                <MultiSelectSearchable
                  {...props}
                  isSearchable={false}
                  placeholder={i18n('search-filter:select-language')}
                  translateKey={translateLanguage}
                />
              )}
            />
          </FilterGroup>
        </FilterSection>

        <FilterSection title={i18n('noun:country')}>
          <FilterGroup>
            <MultiDropdownList
              componentId={FILTERS.country}
              dataField="country.keyword"
              sortBy="count"
              showCount={true}
              showSearch={true}
              react={{
                and: getFiltersWithout(FILTERS.country),
              }}
              showFilter={false}
              URLParams={true}
              innerClass={{
                select: 'filter-group-multiselect',
              }}
              render={props => (
                <MultiSelectSearchable
                  {...props}
                  placeholder={i18n('search-filter:select-country')}
                  translateKey={translateCountry}
                  allData={translatedCountryKeys}
                />
              )}
            />
          </FilterGroup>
        </FilterSection>

        <FilterSection title={i18n('noun:metrics')}>
          <FilterGroup title={i18n('noun:price')}>
            <ReactiveRangeSlider
              componentId={FILTERS.price}
              nestedField="prices"
              dataField={priceDataField}
              formatOptions={priceFormatOptions}
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:monthly-users')}>
            <ReactiveRangeSlider
              componentId={FILTERS.monthlyUsers}
              dataField="statistics.googleAnalytics.uniqueVisitorsPerMonth"
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:page-authority')}>
            <ReactiveRangeSlider
              componentId={FILTERS.pageAuthority}
              dataField="statistics.seoMOZ.pageAuthority"
              formatOptions={COOBIS_WITHOUT_DECIMALS}
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:domain-authority')}>
            <ReactiveRangeSlider
              componentId={FILTERS.domainAuthority}
              dataField="statistics.seoMOZ.domainAuthority"
              formatOptions={COOBIS_WITHOUT_DECIMALS}
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:facebook-followers')}>
            <ReactiveRangeSlider
              componentId={FILTERS.facebookFollowers}
              dataField="statistics.socialNetworks.facebook"
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:twitter-followers')}>
            <ReactiveRangeSlider
              componentId={FILTERS.twitterFollowers}
              dataField="statistics.socialNetworks.twitter"
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:instagram-followers')}>
            <ReactiveRangeSlider
              componentId={FILTERS.instagramFollowers}
              dataField="statistics.socialNetworks.instagram"
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:youtube-followers')}>
            <ReactiveRangeSlider
              componentId={FILTERS.youtubeFollowers}
              dataField="statistics.socialNetworks.youtube"
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:twitch-followers')}>
            <ReactiveRangeSlider
              componentId={FILTERS.twitchFollowers}
              dataField="statistics.socialNetworks.twitch"
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:tiktok-followers')}>
            <ReactiveRangeSlider
              componentId={FILTERS.tiktokFollowers}
              dataField="statistics.socialNetworks.tiktok"
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:acceptance-rate')}>
            <ReactiveRangeSlider
              componentId={FILTERS.acceptanceRate}
              dataField="acceptanceRate"
              isPercentage
              floatPrecision={1}
              formatOptions={COOBIS_PERCENT_OPTIONS}
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:engagement')}>
            <ReactiveRangeSlider
              componentId={FILTERS.engagement}
              dataField="statistics.engagements.rate"
              isPercentage
              floatPrecision={1}
              formatOptions={COOBIS_PERCENT_OPTIONS}
            />
          </FilterGroup>
        </FilterSection>

        <FilterSection title={i18n('noun:audience')}>
          <FilterGroup title={i18n('noun:gender')}>
            <ReactiveRangeCheckbox
              label={i18n('search-filter:male-audience')}
              componentId={FILTERS.maleAudience}
              dataField="statistics.demographics.male"
              rangeFilter={GENRE_RANGE_FILTER}
            />

            <ReactiveRangeCheckbox
              label={i18n('search-filter:female-audience')}
              componentId={FILTERS.femaleAudience}
              dataField="statistics.demographics.female"
              rangeFilter={GENRE_RANGE_FILTER}
            />
          </FilterGroup>

          <FilterGroup title={i18n('noun:age')}>
            <FilterGroupColumn>
              <ReactiveRangeMultiCheckbox
                options={AGE_OPTIONS}
                componentId={FILTERS.age}
              />
            </FilterGroupColumn>
          </FilterGroup>

          <FilterGroup title={i18n('noun:locationAudience')}>
            <ReactiveMultiSelect
              componentId={FILTERS.locationAudience}
              dataField="statistics.countryDemographics.country"
              nestedField="statistics.countryDemographics"
              extraFilter={COUNTRY_AUDIENCE_FILTER}
              placeholder={i18n('search-filter:select-locationAudience')}
              allData={translatedCountryKeys}
              translateKey={translateCountry}
              sortBy="count"
              showCount={true}
              showFilter={false}
            />
          </FilterGroup>
        </FilterSection>

        <FilterSection title={i18n('noun:conditions')}>
          <FilterGroup>
            <ReactiveRangeCheckbox
              label={i18n('search-filter:with-discount')}
              componentId={FILTERS.withDiscount}
              dataField="prices.discount"
              nestedField="prices"
              rangeFilter={DISCOUNT_RANGE_FILTER}
            />
            <ReactiveCheckbox
              label={i18n('search-filter:accepts-broadcast')}
              componentId={FILTERS.acceptsBroadcast}
              dataField="socialNetworks.acceptsBroadcast"
              nestedField="socialNetworks"
              matchingTerm={true}
            />
            <ReactiveCheckbox
              label={i18n('search-filter:is-verified')}
              componentId={FILTERS.isVerified}
              dataField="isVerified"
              matchingTerm={true}
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:follow-links')}>
            <ReactiveCheckbox
              label={i18n('search-filter:accepts-follow-links')}
              componentId={FILTERS.acceptsFollowLinks}
              dataField="acceptsFollowLinks"
              matchingTerm={true}
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:writing-articles')}>
            <ReactiveCheckbox
              label={i18n('search-filter:rejects-writing-articles')}
              componentId={FILTERS.rejectsWritingArticles}
              dataField="acceptsWritingArticles"
              negated
              matchingTerm={true}
            />
          </FilterGroup>
          <FilterGroup title={i18n('noun:advertising-notice')}>
            <ReactiveCheckbox
              label={i18n('search-filter:always-marks-sponsored-content')}
              componentId={FILTERS.alwaysMarksSponsoredContent}
              dataField="marksSponsoredContent"
              matchingTerm={true}
            />
            <ReactiveCheckbox
              label={i18n('search-filter:may-mark-sponsored-content')}
              componentId={FILTERS.mayMarkSponsoredContent}
              dataField="marksSponsoredContent"
              matchingTerm={false}
            />
          </FilterGroup>
        </FilterSection>

        <FilterSection title={i18n('noun:orders')}>
          <FilterGroup>
            <ReactiveCheckbox
              label={i18n('search-filter:did-not-work-with-them')}
              componentId={FILTERS.didntWorkWithThem}
              dataField="usedBrands"
              matchingTerm={myInfo.id}
              negated
            />
            <ReactiveCheckbox
              label={i18n('search-filter:have-accepted-my-orders')}
              componentId={FILTERS.haveAcceptedMyOrders}
              dataField="acceptedBrands"
              matchingTerm={myInfo.id}
            />
          </FilterGroup>
        </FilterSection>

        <FilterSection title={i18n('noun:rating')}>
          <FilterGroup>
            <MultiList
              componentId={FILTERS.rating}
              dataField="rating"
              showLoadMore={false}
              showCount={false}
              showSearch={false}
              sortBy="asc"
              react={{
                and: getFiltersWithout(FILTERS.rating),
              }}
              URLParams={true}
              innerClass={{
                title: 'filter-group-title',
                list: 'filter-group-contents filter-group-list',
              }}
              render={props => <RatingFilter {...props} />}
            />
          </FilterGroup>
        </FilterSection>
      </form>
    </>
  )
}

const enhance = compose(
  withTranslations,
  withMyInfo,
  connect(state => ({
    categories: getCategories(state),
    countries: getCountries(state),
    language: getLanguage(state),
    currency: getBrandCurrency(state),
  })),
  reactiveConnect(
    state => ({
      firstLoadingFilters: R.pipe(
        R.propOr({}, 'aggregations'),
        R.keys,
        // Searchbox no needs aggregations
        R.difference(R.values(R.omit(['searchbox'], FILTERS))),
        R.isEmpty,
        R.not,
      )(state),
    }),
    {},
  ),

  withPropsOnChange(['currency'], ({ currency }) => ({
    priceFormatOptions: {
      ...COOBIS_CURRENCY_OPTIONS,
      currency: currency.isoCode,
      symbol: currency.symbol,
    },
    priceDataField: `prices.finalPrice.${currency.isoCode}`,
  })),
)

const transformElasticSearchResponse = async (
  elasticSearchResponse,
  componentId,
) => {
  if (componentId === 'locationAudience') {
    const { aggregations, ...otherData } = elasticSearchResponse
    return {
      aggregations: {
        reactivesearch_nested:
          aggregations.reactivesearch_nested.reactivesearch_nested_filter,
      },
      ...otherData,
    }
  }

  return elasticSearchResponse
}

export { transformElasticSearchResponse }
export default enhance(SearchFilters)
