import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { checkToken } from '../services/auth'
import {
  CHECK_TOKEN,
  REFRESH_TOKEN,
  LOGOUT,
} from '../services/auth/action-types'
import Login from './login/Login'
import BrandRoutes from './brand/BrandRoutes'
import MediaRoutes from './media/MediaRoutes'
import PasswordRecoveryRequest from './login/PasswordRecoveryRequest'
import PasswordRecoveryReset from './login/PasswordRecoveryReset'
import Debug from './debug/Debug'
import { AuthScope } from '../services/auth/business'
import withIsRequestPending from '../hocs/withIsRequestPending'
import withIsLoading from '../hocs/withIsLoading'
import withEffect from '../hocs/withEffect'
import withSpinner from '../hocs/withSpinner'
import withRouteServerError from '../hocs/withRouteServerError'
import {
  ROUTE_DEBUG,
  ROUTE_PASSWORD_RECOVERY_REQUEST,
  ROUTE_PASSWORD_RECOVERY_RESET,
} from './common/routes'
import { withScope } from '../services/auth/hocs'
import withTranslations from '../hocs/withTranslations'
import envConfig from '../environments'

const RootRoutes = ({ scope }) => (
  <Switch>
    {/* Public routes */}
    {envConfig.hasDebug && (
      <Route exact path={ROUTE_DEBUG.path} component={Debug} />
    )}
    <Route
      exact
      path={ROUTE_PASSWORD_RECOVERY_REQUEST.path}
      component={PasswordRecoveryRequest}
    />
    <Route
      exact
      path={ROUTE_PASSWORD_RECOVERY_RESET.path}
      component={PasswordRecoveryReset}
    />

    {!scope ? (
      // No scope = not logged in, show login regardless of route
      <Login />
    ) : (
      // Scope-specific routes
      <>
        {scope === AuthScope.BRAND && <BrandRoutes />}
        {scope === AuthScope.MEDIA && <MediaRoutes />}
      </>
    )}
  </Switch>
)

const enhance = compose(
  withTranslations,

  // If we fail refreshing a token, show fatal error
  withRouteServerError(REFRESH_TOKEN),
  // If we fail logging out, show fatal error
  withRouteServerError(LOGOUT),

  withScope,
  connect(
    null,
    {
      handleCheckToken: checkToken,
    },
  ),

  withIsLoading(CHECK_TOKEN, 'isCheckTokenLoading'),
  withIsRequestPending(CHECK_TOKEN, 'isCheckTokenPending'),

  withEffect(
    ({ handleCheckToken, isCheckTokenLoading, isCheckTokenPending }) => {
      if (isCheckTokenLoading && !isCheckTokenPending) {
        handleCheckToken()
      }
    },
    ({ handleCheckToken, isCheckTokenLoading, isCheckTokenPending }) => [
      handleCheckToken,
      isCheckTokenLoading,
      isCheckTokenPending,
    ],
  ),

  withSpinner(CHECK_TOKEN, {
    i18nKey: 'root-routes:checking-session',
  }),
)

RootRoutes.propTypes = {
  scope: PropTypes.oneOf([AuthScope.BRAND, AuthScope.MEDIA]),
  handleCheckToken: PropTypes.func.isRequired,
}

export default enhance(RootRoutes)
