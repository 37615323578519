import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import ChipBasic from '../chips/ChipBasic'
import {
  BRIEFING_UPLOAD_CONDITIONS,
  BRIEFING_UPLOAD_CONDITIONS_ORDER_DETAIL_I18N,
} from '../../services/checkout/business'
import { AuthScope } from '../../services/auth/business'

const OrderDetailConditions = ({ condition, scope, i18n }) => {
  return (
    <OrderDetailSection title={i18n('sections:conditions')}>
      <ChipBasic
        title={i18n('sections:image-publishing-conditions')}
        text={i18n(
          BRIEFING_UPLOAD_CONDITIONS_ORDER_DETAIL_I18N[scope][condition],
        )}
      />
    </OrderDetailSection>
  )
}

OrderDetailConditions.propTypes = {
  i18n: PropTypes.func.isRequired,
  condition: PropTypes.oneOf(Object.values(BRIEFING_UPLOAD_CONDITIONS)),
  scope: PropTypes.oneOf(Object.values(AuthScope)),
}

export default withTranslations(OrderDetailConditions)
