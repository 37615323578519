import { SERVICE } from './constants'

export const FETCH_CART = `${SERVICE}/FETCH_CART`
export const DELETE_CART = `${SERVICE}/DELETE_CART`
export const REPEATING_FETCH_CART = `${SERVICE}/REPEATING_FETCH_CART`

export const ADD_ITEM = `${SERVICE}/ADD_ITEM`
export const DELETE_ITEM = `${SERVICE}/DELETE_ITEM`

export const FETCH_DATES_CONFIG = `${SERVICE}/FETCH_DATES_CONFIG`

export const SUBMIT_GENERAL_DATA = `${SERVICE}/SUBMIT_GENERAL_DATA`

export const SUBMIT_BRIEFING = `${SERVICE}/SUBMIT_BRIEFING`

export const VALIDATE_DISCOUNT_COUPON = `${SERVICE}/VALIDATE_DISCOUNT_COUPON`

export const SUBMIT_PAYMENT = `${SERVICE}/SUBMIT_PAYMENT`
