import { compose, branch, renderComponent } from 'recompose'
import { connect } from 'react-redux'
import { getResourceById, fetchResourceById } from '../../services/resources'
import withEffect from '../../hocs/withEffect'
import withSpinnerWithId from '../../hocs/withSpinnerWithId'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import Error404Message from '../../ui/global-message/Error404Message'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'
import { FETCH_RESOURCE_BY_ID } from '../../services/resources/action-types'
import * as R from 'ramda'

const withEditResource = compose(
  // Fetch resource
  connect(
    (state, { resourceId }) => ({
      resourceData: getResourceById(resourceId, state),
    }),
    { fetchResourceById },
  ),

  withEffect(
    ({ resourceId, fetchResourceById }) => {
      fetchResourceById(resourceId)
    },
    ({ resourceId, fetchResourceById }) => [resourceId, fetchResourceById],
  ),

  // Spinner and error handling
  branch(
    ({ resourceData }) => R.isNil(resourceData),
    withSpinnerWithId(FETCH_RESOURCE_BY_ID, { idProp: 'resourceId' }),
  ),
  withRouteServerErrorWithId(FETCH_RESOURCE_BY_ID, 'resourceId'),
  withRoute404ErrorWithId(FETCH_RESOURCE_BY_ID, 'resourceId'),

  // Render 404 if resource is not of specified type
  branch(
    ({ resourceData, channel }) => resourceData.type !== channel,
    renderComponent(Error404Message),
  ),
)

export default withEditResource
