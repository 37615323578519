import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import OrderDetailSection from './OrderDetailSection'
import Paragraph from '../texts/Paragraph'
import SocialHeading from '../texts/SocialHeading'
import AnchorDownload from '../texts/AnchorDownload'
import AnchorLink from '../texts/AnchorLink'
import { isNilOrEmpty, formatBytes } from '../../app/utils'
import { SERVICE_TYPES } from '../../services/orders/business'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const OrderDetailDelivery = ({
  fileUrl,
  url,
  orderType,
  facebookUrl,
  twitterUrl,
  instagram,
  fileTitle,
  fileSize,
  isDisabled,
  i18n,
}) => {
  const documentTitle = `${fileTitle}`
  return (
    <OrderDetailSection title={i18n('sections:delivery')}>
      {!isNilOrEmpty(fileUrl) ? (
        <Paragraph>
          <AnchorDownload
            extraText={formatBytes(fileSize)}
            linkUrl={fileUrl}
            text={fileTitle}
            title={i18n('message:download-document', { documentTitle })}
            isDisabled={isDisabled}
          />
        </Paragraph>
      ) : null}

      {!isNilOrEmpty(url) && orderType === SERVICE_TYPES.WEB_POST ? (
        <Paragraph>
          <SocialHeading
            type={RESOURCE_TYPES.WEB}
            text={i18n('social-resource:post-url')}
          />

          <div>
            <AnchorLink text={url} linkUrl={url} isNative isNewTab />
          </div>
        </Paragraph>
      ) : !isNilOrEmpty(url) ? (
        <Paragraph title={i18n('order-detail:delivery-link-file')}>
          <AnchorLink
            text={url}
            linkUrl={url}
            isDisabled={isDisabled}
            isNewTab
            isNative
          />
        </Paragraph>
      ) : null}

      {!isNilOrEmpty(facebookUrl) ? (
        <Paragraph>
          <SocialHeading
            type={RESOURCE_TYPES.FACEBOOK}
            text={i18n('social-resource:facebook-url')}
          />

          <div>
            <AnchorLink
              text={facebookUrl}
              linkUrl={facebookUrl}
              isNative
              isNewTab
            />
          </div>
        </Paragraph>
      ) : null}

      {!isNilOrEmpty(twitterUrl) ? (
        <Paragraph>
          <SocialHeading
            type={RESOURCE_TYPES.TWITTER}
            text={i18n('social-resource:twitter-url')}
          />

          <div>
            <AnchorLink
              text={twitterUrl}
              linkUrl={twitterUrl}
              isNative
              isNewTab
            />
          </div>
        </Paragraph>
      ) : null}

      {!isNilOrEmpty(instagram) ? (
        <Paragraph>
          <SocialHeading
            type={RESOURCE_TYPES.INSTAGRAM}
            text={i18n('service-type:instagram-story')}
          />
          <div>
            <AnchorDownload
              urlFile={instagram}
              isDisabled={isDisabled}
              i18n={i18n}
            />
          </div>
        </Paragraph>
      ) : null}
    </OrderDetailSection>
  )
}

OrderDetailDelivery.propTypes = {
  fileUrl: PropTypes.string,
  url: PropTypes.string,
  orderType: PropTypes.string,
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagram: CoobisPropTypes.urlFile,
  fileTitle: PropTypes.string,
  fileSize: PropTypes.string,
  isDisabled: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailDelivery)
