import { withStateHandlers } from 'recompose'

const withExportReportContainer = withStateHandlers(
  {
    downloadPPTURL: null,
    downloadPDFURL: null,
  },
  {
    setReportDownloadURLs: _ => (pptURL, pdfURL) => ({
      downloadPPTURL: pptURL,
      downloadPDFURL: pdfURL,
    }),
  },
)

export default withExportReportContainer
