import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const OrderDetailSection = ({ title, isEmpty, children }) => {
  const classes = cx('order-detail-section', {
    'is-empty': isEmpty,
  })
  return (
    <section className={classes}>
      {title && <h2 className="order-detail-section-title">{title}</h2>}
      <div>{children}</div>
    </section>
  )
}

OrderDetailSection.propTypes = {
  title: PropTypes.string,
  isEmpty: PropTypes.bool,
  children: PropTypes.node,
}

export default OrderDetailSection
