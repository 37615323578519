import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import HackFormattedMoney from '../HackFormattedMoney'
import { moneyToFloat } from '../../app/utils'
import { FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import Truncate from 'react-truncate'
import Button from '@redradix/components.button'
import withTranslations from '../../hocs/withTranslations'
import Card from './Card'
import CardKpi from './CardKpi'
import Avatar from '../avatar/Avatar'
import CardLabel from './CardLabel'
import Select from '../form-elements/Select'
import CardSocialBroadcast from './CardSocialBroadcast'
import CardBadge from '../cards/CardBadge'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import { ReactComponent as Cart } from './../../styles/icons/cart.svg'
import { ReactComponent as Fav } from './../../styles/icons/fav.svg'
import { ReactComponent as FavFilled } from './../../styles/icons/fav-filled.svg'

const UserCard = ({
  i18n,
  linkUrl,
  isNew,
  image,
  name,
  category,
  type,
  followers,
  engagements,
  engagementRate,
  price,
  finalPrice,
  services,
  selectedService,
  isCartDisabled,
  isCartLoading,
  onServiceChange,
  acceptsBroadcast,
  broadcastSocialNetworks,
  isUserVerified,
  isFavourited,
  onCartClick,
  onFavClick,
  isBroadcastChecked,
  onBroadcastChange,
  holidaysEndDate,
  isInCart,
}) => {
  return (
    <Card isHighlighted>
      <div className="user-card">
        <Link className="user-card-link" to={linkUrl}>
          <div className="user-card-extra">
            {isNew ? <CardLabel type="new" /> : null}
            {moneyToFloat(finalPrice) < moneyToFloat(price) ? (
              <CardLabel type="sale" />
            ) : null}
          </div>
          <button
            className="user-card-extra-action"
            onClick={onFavClick}
            title={i18n('noun:favourites')}
          >
            {isFavourited ? (
              <FavFilled className="icon rdx-button-icon filled-icon" />
            ) : (
              <Fav className="icon rdx-button-icon" />
            )}
          </button>
          <div className="user-card-user">
            <Avatar iconBorder image={image} avatarName={name} channel={type} />
            <div className="user-card-name">
              <Truncate lines={2} ellipsis="...">
                {name}
              </Truncate>
              {isUserVerified && <CardBadge isVerified={isUserVerified} />}
            </div>
            <div className="user-card-category">{category}</div>
          </div>
          {followers || engagements || engagementRate ? (
            <div className="user-card-kpis">
              {followers ? (
                <CardKpi
                  figure={followers}
                  label={i18n('noun:followers')}
                  isTruncated
                />
              ) : null}
              {engagements ? (
                <CardKpi
                  figure={engagements}
                  label={i18n('noun:interactions')}
                  isTruncated
                />
              ) : null}
              {engagementRate ? (
                <CardKpi
                  figure={engagementRate}
                  label={i18n('noun:engagement')}
                  isPercentage
                />
              ) : null}
            </div>
          ) : null}
        </Link>
        {acceptsBroadcast && !holidaysEndDate ? (
          <div className="user-card-social">
            <CardSocialBroadcast
              socialNetworks={broadcastSocialNetworks}
              checked={isBroadcastChecked}
              onChange={onBroadcastChange}
            />
          </div>
        ) : null}
        <div className="user-card-prices">
          {moneyToFloat(price) === moneyToFloat(finalPrice) ? (
            <div className="user-card-prices-final">
              <HackFormattedMoney value={finalPrice} />
            </div>
          ) : (
            <>
              <div className="user-card-prices-initial">
                <HackFormattedMoney value={price} />
              </div>
              <div className="user-card-prices-final">
                <HackFormattedMoney value={finalPrice} />
              </div>
            </>
          )}
        </div>
        <div className="user-card-action">
          {holidaysEndDate ? (
            <p className="card-holidays-indicator">
              <span>{i18n('noun:vacations-until')} </span>
              <span>
                <FormattedDate format="coobis" value={holidaysEndDate} />
              </span>
            </p>
          ) : (
            <>
              {services.length > 1 && (
                <Select
                  options={services}
                  value={selectedService}
                  isDisabled={isCartDisabled}
                  onChange={onServiceChange}
                />
              )}
              <Button
                isDisabled={isCartDisabled || isInCart}
                isLoading={isCartLoading}
                onClick={onCartClick}
              >
                <Cart className="icon rdx-button-icon" />
                <span>{i18n(isInCart ? 'action:added' : 'action:add')}</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </Card>
  )
}

UserCard.propTypes = {
  linkUrl: PropTypes.string,
  isNew: PropTypes.bool,
  name: PropTypes.string,
  category: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  followers: PropTypes.number,
  engagements: PropTypes.number,
  engagementRate: PropTypes.number,
  price: CoobisPropTypes.money,
  finalPrice: CoobisPropTypes.money,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
    }),
  ),
  selectedService: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  isCartDisabled: PropTypes.bool,
  isCartLoading: PropTypes.bool,
  onServiceChange: PropTypes.func,
  acceptsBroadcast: PropTypes.bool,
  broadcastSocialNetworks: PropTypes.arrayOf(
    PropTypes.oneOf([RESOURCE_TYPES.FACEBOOK, RESOURCE_TYPES.TWITTER]),
  ),
  isUserVerified: PropTypes.bool,
  isFavourited: PropTypes.bool,
  onCartClick: PropTypes.func,
  onFavClick: PropTypes.func,
  isBroadcastChecked: PropTypes.bool,
  onBroadcastChange: PropTypes.func,
  holidaysEndDate: PropTypes.object,
  isInCart: PropTypes.bool,
}

export default withTranslations(UserCard)
