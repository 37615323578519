import * as actionTypes from './action-types'
import { periodicFetchMediaStatus } from './actions'
import { all, call, takeLatest, put } from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import * as R from 'ramda'
import {
  HASH_KEY_MEDIA_INFO,
  DOMAIN,
  HASH_KEY_MEDIA_STATUS,
  MEDIA_STATUS_REFRESH_TIME,
} from './constants'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
} from '../communication/actions'
import {
  fetchMedia,
  registerMedia,
  editMediaData,
  editMediaBillingData,
  uploadTypeDocument,
  uploadIdentityDocument,
  uploadResidenceDocument,
  fetchMediaStatus,
} from './api'
import { ensureFile } from '../../app/utils'
import moment from 'moment'
import repeatingFetchEffect from '../repeating-fetch-effect'

import { periodicFetchMediaWalletMovements } from '../wallet'

function* fetchMediaSaga() {
  yield put(startRequest(actionTypes.FETCH_MEDIA))

  const response = yield call(fetchMedia)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_MEDIA, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_MEDIA_INFO, response.data))

  // Set moment's locale for react-dates: this is an imperative and global
  // operation but it's unavoidable AFAICT
  moment.locale(response.data.language)

  yield put(endRequestSuccess(actionTypes.FETCH_MEDIA))
}

function* editMediaDataSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.EDIT_MEDIA_DATA))

  const response = yield call(editMediaData, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.EDIT_MEDIA_DATA, response))
    return
  }

  yield call(fetchMediaSaga)

  yield put(endRequestSuccess(actionTypes.EDIT_MEDIA_DATA))
}

function* uploadBillingDocuments({
  typeDocument,
  identityDocument,
  residenceDocument,
}) {
  const uploadedDocuments = yield all({
    typeDocumentUpload: call(uploadTypeDocument, typeDocument),
    identityDocumentUpload: call(uploadIdentityDocument, identityDocument),
    residenceDocumentUpload: call(uploadResidenceDocument, residenceDocument),
  })

  const erroredRequests = R.pipe(
    R.reject(R.isNil),
    R.reject(R.propSatisfies(R.isNil, 'error')),
  )(uploadedDocuments)

  if (!R.isEmpty(erroredRequests)) {
    const validationMessages = R.pipe(
      R.map(R.prop('validationMessages')),
      R.values,
      R.mergeAll,
    )(erroredRequests)

    const globalMessages = R.pipe(
      R.map(R.prop('globalMessages')),
      R.values,
      R.mergeAll,
      R.values,
    )(erroredRequests)

    return { uploadErrors: { validationMessages, globalMessages } }
  }

  const uploadedDocumentIds = R.pipe(
    R.reject(R.isNil),
    R.map(R.path(['data', 'uploadId'])),
  )(uploadedDocuments)

  return { uploadedDocumentIds }
}

function* editMediaBillingDataSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.EDIT_MEDIA_BILLING_DATA))

  const {
    typeDocumentUpload,
    identityDocumentUpload,
    residenceDocumentUpload,
    ...billingData
  } = data

  const { uploadedDocumentIds, uploadErrors } = yield call(
    uploadBillingDocuments,
    {
      typeDocument: ensureFile(typeDocumentUpload),
      identityDocument: ensureFile(identityDocumentUpload),
      residenceDocument: ensureFile(residenceDocumentUpload),
    },
  )

  if (uploadErrors) {
    yield put(
      endRequestError(actionTypes.EDIT_MEDIA_BILLING_DATA, uploadErrors),
    )
    return
  }

  const safetyExistingFiles = R.pipe(
    R.pick([
      'typeDocumentUpload',
      'identityDocumentUpload',
      'residenceDocumentUpload',
    ]),
    R.reject(item => item && item.isUploaded === true),
  )(data)

  const response = yield call(editMediaBillingData, {
    ...billingData,
    ...safetyExistingFiles,
    ...uploadedDocumentIds,
  })

  if (response.error) {
    yield put(endRequestError(actionTypes.EDIT_MEDIA_BILLING_DATA, response))
    return
  }

  yield all([
    call(fetchMediaSaga),
    // Fetch wallet movements to reflect posible currency exchange movements
    put(periodicFetchMediaWalletMovements()),
  ])

  yield put(endRequestSuccess(actionTypes.EDIT_MEDIA_BILLING_DATA))
}

function* registerMediaSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.REGISTER_MEDIA))

  const response = yield call(registerMedia, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.REGISTER_MEDIA, response))
    return
  }

  yield put(endRequestSuccess(actionTypes.REGISTER_MEDIA))
}

function* fetchMediaStatusSaga() {
  yield put(startRequest(actionTypes.FETCH_MEDIA_STATUS))

  const response = yield call(fetchMediaStatus)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_MEDIA_STATUS, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_MEDIA_STATUS, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_MEDIA_STATUS))
}

export default function*() {
  yield all([
    takeLatest(actionTypes.FETCH_MEDIA, fetchMediaSaga),
    takeLatest(actionTypes.EDIT_MEDIA_DATA, editMediaDataSaga),
    takeLatest(actionTypes.EDIT_MEDIA_BILLING_DATA, editMediaBillingDataSaga),
    takeLatest(actionTypes.REGISTER_MEDIA, registerMediaSaga),
    takeLatest(actionTypes.FETCH_MEDIA_STATUS, fetchMediaStatusSaga),
    repeatingFetchEffect({
      fetchSaga: fetchMediaStatusSaga,
      intervalMillisecs: MEDIA_STATUS_REFRESH_TIME,
      startAction: periodicFetchMediaStatus(),
      requestActionType: actionTypes.FETCH_MEDIA_STATUS,
    }),
  ])
}
