import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import Checkbox from '@redradix/components.checkbox'
import Panel from '../panels/Panel'
import InputSuffix from '../form-elements/InputSuffix'
import PaperInfo from '../papers/PaperInfo'
import { MediaQueryPortable, MediaQueryDesk } from '../shared/MediaQueries'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import Tooltip from '@redradix/components.tooltip'
import FormattedCurrency from '../FormattedCurrency'

const getTooltipWithOptions = (options, i18n) => {
  return (
    <Tooltip
      position={options.position}
      widthFixed={options.widthFixed}
      renderTooltip={() => (
        <>
          <TooltipRegularText text={i18n('web-resources:prices-tooltip')} />
        </>
      )}
      renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
        <TooltipTrigger
          classNames={triggerClasses}
          onMouseOver={open}
          onMouseOut={close}
          ref={triggerRef}
        />
      )}
    />
  )
}

const InstagramServicesPrices = ({
  values,
  calculatedValues,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  isPricesVipResource,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  const touchedAndWithErrors = useCallback(
    fieldName =>
      touched[fieldName] &&
      validationMessages[fieldName] &&
      Array.isArray(validationMessages[fieldName]),
    [touched, validationMessages],
  )

  return (
    <form className="basic-form" onSubmit={handleSubmit}>
      <div className="indented-block">
        <Checkbox
          name="photoEnabled"
          checked={values.photoEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.photoEnabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('social-resource:instagram-photo')}</p>
          <p className="subtitle">
            {i18n('social-resource:instagram-photo-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="photoBasePrice"
              name="photoBasePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.photoBasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.photoBasePrice}
              isDisabled={
                isRequestPending || !isPricesVipResource || !values.photoEnabled
              }
              suffix={<FormattedCurrency value={values.photoCurrency} />}
            />
            <InputSuffix
              type="number"
              id="photoDiscount"
              name="photoDiscount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              value={values.photoDiscount}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.photoDiscount}
              isDisabled={isRequestPending || !values.photoEnabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="photoTotalPrice"
              name="photoTotalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.photoTotalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.photoCurrency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('photoBasePrice') ||
          touchedAndWithErrors('photoDiscount') ||
          validationMessages.photoTotalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.photoBasePrice || []),
                  ...(validationMessages.photoDiscount || []),
                  ...(validationMessages.photoTotalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>
      <div className="indented-block">
        <Checkbox
          name="videoEnabled"
          checked={values.videoEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.videoEnabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('social-resource:instagram-video')}</p>
          <p className="subtitle">
            {i18n('social-resource:instagram-video-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="videoBasePrice"
              name="videoBasePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.videoBasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.videoBasePrice}
              isDisabled={
                isRequestPending || !isPricesVipResource || !values.videoEnabled
              }
              suffix={<FormattedCurrency value={values.videoCurrency} />}
            />
            <InputSuffix
              type="number"
              id="videoDiscount"
              name="videoDiscount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              value={values.videoDiscount}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.videoDiscount}
              isDisabled={isRequestPending || !values.videoEnabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="videoTotalPrice"
              name="videoTotalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.videoTotalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.videoCurrency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('videoBasePrice') ||
          touchedAndWithErrors('videoDiscount') ||
          validationMessages.videoTotalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.videoBasePrice || []),
                  ...(validationMessages.videoDiscount || []),
                  ...(validationMessages.videoTotalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>
      <div className="indented-block">
        <Checkbox
          name="storyEnabled"
          checked={values.storyEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.storyEnabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('social-resource:instagram-story')}</p>
          <p className="subtitle">
            {i18n('social-resource:instagram-story-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="storyBasePrice"
              name="storyBasePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.storyBasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.storyBasePrice}
              isDisabled={
                isRequestPending || !isPricesVipResource || !values.storyEnabled
              }
              suffix={<FormattedCurrency value={values.storyCurrency} />}
            />
            <InputSuffix
              type="number"
              id="storyDiscount"
              name="storyDiscount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              value={values.storyDiscount}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.storyDiscount}
              isDisabled={isRequestPending || !values.storyEnabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="storyTotalPrice"
              name="storyTotalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.storyTotalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.storyCurrency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('storyBasePrice') ||
          touchedAndWithErrors('storyDiscount') ||
          validationMessages.storyTotalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.storyBasePrice || []),
                  ...(validationMessages.storyDiscount || []),
                  ...(validationMessages.storyTotalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>
      <div className="indented-block">
        <PaperInfo type="notice">
          {i18n('social-resource:price-considerations')}
        </PaperInfo>
      </div>

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  photoEnabled: PropTypes.bool,
  photoBasePrice: PropTypes.number,
  photoDiscount: PropTypes.number,
  videoEnabled: PropTypes.bool,
  videoBasePrice: PropTypes.number,
  videoDiscount: PropTypes.number,
  storyEnabled: PropTypes.bool,
  storyBasePrice: PropTypes.number,
  storyDiscount: PropTypes.number,
}

InstagramServicesPrices.propTypes = {
  calculatedValues: PropTypes.shape({
    photoTotalPrice: PropTypes.number,
    videoTotalPrice: PropTypes.number,
    storyTotalPrice: PropTypes.number,
  }),
  isPricesVipResource: PropTypes.bool,
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(InstagramServicesPrices)
