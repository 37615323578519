import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import AnchorLinkButton from '../texts/AnchorLinkButton'
import AnchorLink from '../texts/AnchorLink'
import IconSocial from '../icons/IconSocial'

const ShareLinkWidget = ({
  linkText,
  onCopyClick,
  facebookLinkUrl,
  twitterLinkUrl,
  i18n,
}) => {
  return (
    <div className="share-link-widget">
      <div className="share-link-widget-box">
        <p className="share-link-widget-text">
          <span>{linkText}</span>
        </p>
        <AnchorLinkButton
          type="button"
          text={i18n('action:copy')}
          onClick={onCopyClick}
        />
      </div>
      <div className="share-link-widget-social">
        <AnchorLink
          isNative
          isNewTab
          linkUrl={facebookLinkUrl}
          text={<IconSocial type={RESOURCE_TYPES.FACEBOOK} />}
        />
        <AnchorLink
          isNative
          isNewTab
          linkUrl={twitterLinkUrl}
          text={<IconSocial type={RESOURCE_TYPES.TWITTER} />}
        />
      </div>
    </div>
  )
}

ShareLinkWidget.propTypes = {
  linkText: PropTypes.string,
  facebookLinkUrl: PropTypes.string,
  twitterLinkUrl: PropTypes.string,
  onCopyClick: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ShareLinkWidget)
