import { connect } from 'react-redux'
import { getMyFavorites } from './selectors'
import { fetchMyFavorites } from './actions'
import { compose } from 'recompose'
import withEffect from '../../hocs/withEffect'

export const withFetchFavorites = compose(
  connect(
    state => ({
      favorites: getMyFavorites(state),
    }),
    { fetchMyFavorites },
  ),

  withEffect(
    ({ fetchMyFavorites }) => {
      fetchMyFavorites()
    },
    ({ fetchMyFavorites }) => [fetchMyFavorites],
  ),
)
