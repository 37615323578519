import React from 'react'
import PropTypes from 'prop-types'
import IconSocial from '../../ui/icons/IconSocial'
import { RESOURCE_TYPES } from '../../services/resources/constants'

const SocialHeading = ({ text, type }) => {
  return (
    <div className="social-heading">
      <IconSocial type={type} />
      <p className="text-social">{text}</p>
    </div>
  )
}

SocialHeading.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
}

export default SocialHeading
