import React from 'react'
import Joyride, { EVENTS } from 'react-joyride'
import TourCard from './TourCard'
import withTranslations from '../../hocs/withTranslations'
import { MediaQueryDesk } from '../shared/MediaQueries'
import PropTypes from 'prop-types'

const Tooltip = withTranslations(
  ({
    continuous,
    index,
    step,
    backProps,
    skipProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    i18n,
  }) => {
    return (
      <div {...tooltipProps}>
        <TourCard
          title={step.content.title}
          titleIcon={step.content.titleIcon}
          texts={step.content.texts}
          renderIcon={props => step.content.icon}
          onNextClick={primaryProps.onClick}
          onCloseClick={skipProps.onClick}
          nextButtonText={i18n(isLastStep ? 'tour:done' : 'tour:next')}
        />
      </div>
    )
  },
)

const stepOptions = {
  disableBeacon: true,
  disableOverlayClose: true,
  floaterProps: {
    disableAnimation: true,
  },
}

const Tour = ({ i18n, steps, markTourAsViewed, tourName, ...props }) => {
  const joyrideSteps = steps.map(step => {
    return { ...step, ...stepOptions }
  })

  const handleJoyrideCallback = data => {
    const { type } = data
    if ([EVENTS.TOUR_END].includes(type)) {
      markTourAsViewed(tourName)
    }
  }

  return (
    <MediaQueryDesk>
      <div className="tour-card">
        <Joyride
          callback={handleJoyrideCallback}
          steps={joyrideSteps}
          tooltipComponent={Tooltip}
          styles={{
            options: {
              zIndex: 9000,
              overlayColor: 'rgba(255, 255, 255, 0.75)',
            },
          }}
          floaterProps={{
            hideArrow: true,
          }}
        />
      </div>
    </MediaQueryDesk>
  )
}

Tour.propTypes = {
  i18n: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  markTourAsViewed: PropTypes.func.isRequired,
  tourName: PropTypes.string.isRequired,
}

export default withTranslations(Tour)
