import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import CampaignDetailPanel from '../CampaignDetailPanel'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import ChartCard from '../../cards/ChartCard'
import UserBarChart from '../../charts/UserBarChart'
import CampaignPreviewYoutube from '../social-previews/CampaignPreviewYoutube'
import PublicationsTableYoutube from '../tables/PublicationsTableYoutube'
import { isNilOrEmpty } from '../../../app/utils'

const CampaignDetailYoutubeAll = ({
  previews,
  publicationsTableData,
  hasMentionsFullData,
  services,
  viewsSelectorValue,
  engagementsSelectorValue,
  isServicesSelectorDisabled,
  onViewsServiceChange,
  onEngagementsServiceChange,
  i18n,
  // Metrics values
  publications,
  influencers,
  engagements,
  reach,
  views,
  averageVisualization,
  viewsData,
  viewsLegend,
  engagementsData,
  engagementsLegend,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        <AnalyticsKpi
          iconType="megaphone"
          value={publications}
          label={i18n('metrics:publications')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="influencer"
          value={influencers}
          label={i18n('metrics:influencers')}
          isTruncated
        />
        {engagements > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagements}
            label={i18n('metrics:engagements')}
            isTruncated
          />
        )}
        {views > 0 && (
          <AnalyticsKpi
            iconType="eye"
            value={views}
            label={i18n('metrics:views')}
            isTruncated
          />
        )}
        {averageVisualization > 0 && (
          <AnalyticsKpi
            iconType="clock"
            value={averageVisualization}
            label={i18n('metrics:average-visualization')}
            isTime
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewYoutube items={previews} />
      ) : null}
      <div className="campaign-detail-section-row">
        {views > 0 && (
          <div className="views campaign-detail-section-cell one-half">
            <ChartCard
              title={i18n('metrics:views')}
              hasSelector={hasMentionsFullData}
              selectorOptions={services}
              isSelectorDisabled={isServicesSelectorDisabled}
              onSelectorChange={onViewsServiceChange}
              selectorValue={viewsSelectorValue}
            >
              <UserBarChart
                data={viewsData}
                popupI18NKey="user-bar-chart:views-tooltip"
              />
            </ChartCard>
          </div>
        )}
        {engagements > 0 && (
          <div className="engagements campaign-detail-section-cell one-half">
            <ChartCard
              title={i18n('metrics:engagements')}
              hasSelector={hasMentionsFullData}
              selectorOptions={services}
              isSelectorDisabled={isServicesSelectorDisabled}
              onSelectorChange={onEngagementsServiceChange}
              selectorValue={engagementsSelectorValue}
            >
              <UserBarChart
                data={engagementsData}
                popupI18NKey="user-bar-chart:engagements-tooltip"
              />
            </ChartCard>
          </div>
        )}
      </div>

      <CampaignDetailPanel title={i18n('noun:publications-table')}>
        <PublicationsTableYoutube
          publications={publicationsTableData}
        ></PublicationsTableYoutube>
      </CampaignDetailPanel>
    </>
  )
}

CampaignDetailYoutubeAll.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  publicationsTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      service: PropTypes.string,
      engagements: PropTypes.number,
      views: PropTypes.number,
      likes: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
    }),
  ),
  viewsSelectorValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  engagementsSelectorValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  hasMentionsFullData: PropTypes.bool,
  isServicesSelectorDisabled: PropTypes.bool,
  onViewsServiceChange: PropTypes.func,
  onEngagementsServiceChange: PropTypes.func,
  i18n: PropTypes.func.isRequired,
  // Metrics values
  publications: PropTypes.number,
  influencers: PropTypes.number,
  engagements: PropTypes.number,
  views: PropTypes.number,
  averageVisualization: PropTypes.number,
  viewsData: PropTypes.array,
  engagementsData: PropTypes.array,
}

export default withTranslations(CampaignDetailYoutubeAll)
