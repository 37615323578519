import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  fetchCampaignResourceTypeStats,
  getCampaignResourceTypeStats,
} from '../../services/campaigns'
import { FETCH_CAMPAIGN_RESOURCE_TYPE_STATS } from '../../services/campaigns/action-types'
import withIsLoadingWithId from '../../hocs/withIsLoadingWithId'
import withEffect from '../../hocs/withEffect'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'

const withCampaignResourceTypeStats = compose(
  withPropsOnChange(
    ['campaignId', 'resourceType'],
    ({ campaignId, resourceType }) => ({
      fetchId: `${campaignId}_${resourceType}`,
    }),
  ),

  withRoute404ErrorWithId(FETCH_CAMPAIGN_RESOURCE_TYPE_STATS, 'fetchId'),
  withRouteServerErrorWithId(FETCH_CAMPAIGN_RESOURCE_TYPE_STATS, 'fetchId'),

  connect(
    (state, { campaignId, resourceType }) => ({
      stats: getCampaignResourceTypeStats(campaignId, resourceType, state),
    }),
    {
      fetchCampaignResourceTypeStats,
    },
  ),

  withEffect(
    ({ fetchCampaignResourceTypeStats, campaignId, resourceType }) => {
      fetchCampaignResourceTypeStats(campaignId, resourceType)
    },
    ({ fetchCampaignResourceTypeStats, campaignId, resourceType }) => [
      fetchCampaignResourceTypeStats,
      campaignId,
      resourceType,
    ],
  ),

  withIsLoadingWithId(FETCH_CAMPAIGN_RESOURCE_TYPE_STATS, {
    idProp: 'fetchId',
    outProp: 'isLoadingResourceStats',
  }),
)

export default withCampaignResourceTypeStats
