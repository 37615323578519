import * as actionTypes from './action-types'
import * as hash from 'reduken/hash'
import { all, call, takeLatest, put } from 'redux-saga/effects'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
  deleteRequest,
} from '../communication/actions'
import * as api from './api'
import { DOMAIN, HASH_KEY_PHOTO_REFRESH_KEY } from './constants'

function* uploadPhotoSaga({ payload: { scope, file } }) {
  yield put(deleteRequest(actionTypes.DELETE)) // avoid showing delete errors
  yield put(startRequest(actionTypes.UPLOAD))

  const response = yield call(api.uploadPhoto, scope, file)

  if (response.error) {
    yield put(endRequestError(actionTypes.UPLOAD, response))
    return
  }

  // Refresh key to force photo to always be up to date (via query params)
  yield put(hash.set(DOMAIN, HASH_KEY_PHOTO_REFRESH_KEY, Date.now()))

  yield put(endRequestSuccess(actionTypes.UPLOAD))
}

function* deletePhotoSaga({ payload: { scope } }) {
  yield put(deleteRequest(actionTypes.UPLOAD)) // avoid showing upload errors
  yield put(startRequest(actionTypes.DELETE))

  const response = yield call(api.deletePhoto, scope)

  if (response.error) {
    yield put(endRequestError(actionTypes.DELETE, response))
    return
  }

  // Refresh key to force photo to always be up to date (via query params)
  yield put(hash.set(DOMAIN, HASH_KEY_PHOTO_REFRESH_KEY, Date.now()))

  yield put(endRequestSuccess(actionTypes.DELETE))
}

export default function*() {
  yield all([
    takeLatest(actionTypes.UPLOAD, uploadPhotoSaga),
    takeLatest(actionTypes.DELETE, deletePhotoSaga),
  ])
}
