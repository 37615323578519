import React from 'react'
import PropTypes from 'prop-types'
import DeleteButton from '../buttons/DeleteButton'
import PrivacyLinks from './PrivacyLinks'

const AccountDeleter = ({ descriptionText, buttonText, onDelete, i18n }) => {
  return (
    <div className="account-deleter">
      <PrivacyLinks
        privacyText={i18n('noun:privacy')}
        privacyLink={i18n('public:privacy-link')}
        termsText={i18n('noun:terms')}
        termsLink={i18n('public:terms-link')}
      />
      <p className="account-deleter-description">{descriptionText}</p>
      <DeleteButton buttonText={buttonText} onDelete={onDelete} />
    </div>
  )
}

AccountDeleter.propTypes = {
  descriptionText: PropTypes.string,
  buttonText: PropTypes.string,
  onDelete: PropTypes.func,
}

export default AccountDeleter
