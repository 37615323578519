import * as actionTypes from './action-types'
import {
  endRequestError,
  endRequestSuccess,
  startRequest,
} from '../communication'
import { fetchMyTours, markTourAsViewed } from './api'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import { DOMAIN, HASH_KEY_TOURS } from './constants'

function* fetchMyToursSaga() {
  yield put(startRequest(actionTypes.FETCH_MY_TOURS))

  const response = yield call(fetchMyTours)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_MY_TOURS, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_TOURS, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_MY_TOURS))
}

function* markTourAsViewedSaga({ payload: { tourName } }) {
  yield put(startRequest(actionTypes.MARK_TOUR_AS_VIEWED))

  const response = yield call(markTourAsViewed, tourName)

  if (response.error) {
    yield put(endRequestError(actionTypes.MARK_TOUR_AS_VIEWED, response))
    return
  }

  yield call(fetchMyToursSaga)

  yield put(endRequestSuccess(actionTypes.MARK_TOUR_AS_VIEWED))
}

export default function*() {
  yield all([
    takeLatest(actionTypes.FETCH_MY_TOURS, fetchMyToursSaga),
    takeLatest(actionTypes.MARK_TOUR_AS_VIEWED, markTourAsViewedSaga),
  ])
}
