import * as deepmerge from 'deepmerge'
import { call, select, put } from 'redux-saga/effects'
import * as R from 'ramda'
import { getToken, logoutComplete } from './auth'
import { reportSentryError500 } from './sentry-error'

const getTokenHeader = token =>
  token ? { Authorization: `Bearer ${token}` } : {}

const getDefaultOptions = token => ({
  credentials: 'omit',
  headers: {
    Accept: 'application/json',
    ...getTokenHeader(token),
  },
})

export function* getRequest(
  url,
  { parseJSON = false, ignore401 = false, ...options } = {},
) {
  const token = yield select(getToken)
  let res

  try {
    res = yield call(
      fetch,
      url,
      deepmerge.all([getDefaultOptions(token), { method: 'GET' }, options]),
    )
  } catch (error) {
    return {
      error,
      type: 'network',
    }
  }

  return yield call(getResultFromResponse, res, 'GET', { parseJSON, ignore401 })
}

export function* postRequest(
  url,
  data,
  { parseJSON = false, ignore401 = false, ...options } = {},
) {
  const token = yield select(getToken)
  let res

  try {
    res = yield call(
      fetch,
      url,
      deepmerge.all([
        getDefaultOptions(token),
        {
          method: 'POST',
          ...(!R.isNil(data)
            ? {
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              }
            : {}),
        },
        options,
      ]),
    )
  } catch (error) {
    return {
      error,
      type: 'network',
    }
  }

  return yield call(getResultFromResponse, res, 'POST', {
    parseJSON,
    ignore401,
  })
}

export function* postFormRequest(
  url,
  data,
  { parseJSON = false, ignore401 = false, ...options } = {},
) {
  const token = yield select(getToken)

  const formData = new FormData()

  for (const [k, v] of Object.entries(data)) {
    formData.set(k, v)
  }

  let res

  try {
    res = yield call(fetch, url, {
      body: formData,
      ...deepmerge.all([getDefaultOptions(token), { method: 'POST' }, options]),
    })
  } catch (error) {
    return {
      error,
      type: 'network',
    }
  }

  return yield call(getResultFromResponse, res, 'POST', {
    parseJSON,
    ignore401,
  })
}

export function* putRequest(
  url,
  data,
  { parseJSON = false, ignore401 = false, ...options } = {},
) {
  const token = yield select(getToken)
  let res

  try {
    res = yield call(
      fetch,
      url,
      deepmerge.all([
        getDefaultOptions(token),
        {
          method: 'PUT',
          ...(!R.isNil(data)
            ? {
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              }
            : {}),
        },
        options,
      ]),
    )
  } catch (error) {
    return {
      error,
      type: 'network',
    }
  }

  return yield call(getResultFromResponse, res, 'PUT', { parseJSON, ignore401 })
}

export function* patchRequest(
  url,
  data,
  { parseJSON = false, ignore401 = false, ...options } = {},
) {
  const token = yield select(getToken)
  let res

  try {
    res = yield call(
      fetch,
      url,
      deepmerge.all([
        getDefaultOptions(token),
        {
          method: 'PATCH',
          ...(!R.isNil(data)
            ? {
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              }
            : {}),
        },
        options,
      ]),
    )
  } catch (error) {
    return {
      error,
      type: 'network',
    }
  }

  return yield call(getResultFromResponse, res, 'PATCH', {
    parseJSON,
    ignore401,
  })
}

export function* deleteRequest(
  url,
  data,
  { parseJSON = false, ignore401 = false, ...options } = {},
) {
  const token = yield select(getToken)
  let res

  try {
    res = yield call(
      fetch,
      url,
      deepmerge.all([
        getDefaultOptions(token),
        {
          method: 'DELETE',
          ...(!R.isNil(data)
            ? {
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              }
            : {}),
        },
        options,
      ]),
    )
  } catch (error) {
    return {
      error,
      type: 'network',
    }
  }

  return yield call(getResultFromResponse, res, 'DELETE', {
    parseJSON,
    ignore401,
  })
}

function* getResultFromResponse(
  res,
  method,
  { parseJSON = false, ignore401 = false } = {},
) {
  if (!res.ok) {
    // Status 401 === Unauthorized, which means our token is no longer valid
    // Log out the user
    if (res.status === 401 && !ignore401) {
      yield put(logoutComplete())
      return
    }

    let errorData

    // Try parsing error JSON
    try {
      errorData = yield call([res, 'json'])
    } catch (err) {
      // If not ok response, just return empty `errorData` later
    }

    let sentryData

    // If server error 5xx, notify via Sentry
    if (res.status >= 500 && res.status < 600) {
      const state = yield select()

      sentryData = yield call(
        reportSentryError500,
        {
          url: res.url,
          method,
          status: res.status,
          statusText: res.statusText,
          errorData,
        },
        state,
      )
    }

    return {
      error: new Error(`Status: ${res.status} - ${res.statusText}`),
      type: 'status',
      status: res.status,
      statusText: res.statusText,
      errorData,
      sentryData,
    }
  }

  let json

  if (parseJSON) {
    // Try parsing JSON
    try {
      json = yield call([res, 'json'])
    } catch (err) {
      // If ok response, needs JSON, but returns no data: error out
      throw new Error('Could not parse JSON from response')
    }
  }

  return {
    status: res.status,
    statusText: res.statusText,
    data: json,
  }
}
