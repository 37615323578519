import React from 'react'
import PropTypes from 'prop-types'

const CampaignDetailNavigation = ({ children }) => {
  return (
    <div className="campaign-detail-navigation">
      <div className="campaign-detail-navigation-list">{children}</div>
    </div>
  )
}

CampaignDetailNavigation.propTypes = {
  children: PropTypes.node,
}

export default CampaignDetailNavigation
