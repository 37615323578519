import React, { useCallback, useEffect, useMemo } from 'react'
import RangeSlider from './RangeSlider'
import * as R from 'ramda'
import { getFiltersWithout } from './filterUtils'
import { ReactiveComponent } from '@appbaseio/reactivesearch'

const rangeSliderQueryFactory = (dataField, nestedField = undefined) => (
  min,
  max,
) => {
  const roundedMin = Number(Number(min).toFixed(3))
  const roundedMax = Number(Number(max).toFixed(3))

  const query = {
    range: {
      [dataField]: {
        gte: roundedMin,
        lte: roundedMax,
      },
    },
  }

  const value = [roundedMin, roundedMax]

  return nestedField
    ? {
        query: {
          nested: {
            path: nestedField,
            query,
          },
        },
        value,
      }
    : {
        query,
        value,
      }
}

const rangeSliderAggregationQueryFactory = (
  dataField,
  nestedField = undefined,
) => () => {
  const aggs = {
    min: { min: { field: dataField } },
    max: { max: { field: dataField } },
  }

  return nestedField
    ? {
        size: 0,
        aggs: {
          [dataField]: {
            nested: { path: nestedField },
            aggs,
          },
        },
      }
    : {
        size: 0,
        aggs,
      }
}

const RangeSliderWrapper = ({
  setQuery,
  value,
  error,
  loading,

  makeQuery,
  min,
  max,
  title,

  isPercentage,
  floatPrecision,

  formatOptions,
}) => {
  const updateRange = useCallback((min, max) => setQuery(makeQuery(min, max)), [
    setQuery,
    makeQuery,
  ])

  const safeValue = useMemo(
    () => value,
    // eslint-disable-next-line
    value ? [value[0], value[1]] : [null, null],
  )

  // Update values on mount if found in URLParams
  useEffect(() => {
    if (R.isNil(safeValue)) {
      setQuery({ value: null })
      return
    }

    const [minValue, maxValue] = safeValue

    updateRange(minValue, maxValue)
  }, [max, min, setQuery, updateRange, safeValue])

  return (
    <RangeSlider
      title={title}
      min={min}
      max={max}
      value={value}
      onChange={updateRange}
      isLoading={loading}
      hasError={Boolean(error)}
      isPercentage={isPercentage}
      floatPrecision={floatPrecision}
      formatOptions={formatOptions}
    />
  )
}

const ReactiveRangeSlider = ({
  componentId,
  dataField,
  nestedField,

  title,
  isPercentage,
  floatPrecision,

  formatOptions,
}) => {
  const makeDefaultQuery = useMemo(
    () => rangeSliderAggregationQueryFactory(dataField, nestedField),
    [dataField, nestedField],
  )

  const makeQuery = useMemo(
    () => rangeSliderQueryFactory(dataField, nestedField),
    [dataField, nestedField],
  )

  return (
    <ReactiveComponent
      componentId={componentId}
      defaultQuery={makeDefaultQuery}
      react={{ and: getFiltersWithout(componentId) }}
      URLParams={true}
      render={props => {
        const [min, max] = R.converge(
          (...args) => args,
          nestedField
            ? [
                R.path([dataField, 'min', 'value']),
                R.path([dataField, 'max', 'value']),
              ]
            : [R.path(['min', 'value']), R.path(['max', 'value'])],
        )(props.aggregations)

        return (
          <RangeSliderWrapper
            {...props}
            title={title}
            makeQuery={makeQuery}
            min={min}
            max={max}
            isPercentage={isPercentage}
            floatPrecision={floatPrecision}
            formatOptions={formatOptions}
          />
        )
      }}
    />
  )
}

export default ReactiveRangeSlider
