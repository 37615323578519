import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest, postRequest } from '../api-call'
import { i18nErrorKeys } from '../../app/common/validation'
import { parseResponseErrors } from '../error-handling'

const PASSWORD_RECOVERY_REQUEST_ENDPOINT = `${envConfig.authBackendUrl}/reset-password`

const PASSWORD_RECOVERY_RESET_ENDPOINT = `${envConfig.authBackendUrl}/reset-password-session`

const MAP_GLOBAL_ERRORS_REQUEST = {
  UserNotFoundException: values => ({
    key: i18nErrorKeys.USER_NOT_FOUND,
    values,
  }),
  invalid_scope: values => ({
    key: i18nErrorKeys.UNKNOWN,
    values,
  }),
  invalid_request: values => ({
    key: i18nErrorKeys.UNKNOWN,
    values,
  }),
  ReCaptchaException: values => ({
    key: i18nErrorKeys.RECAPTCHA_FAILED_ERROR,
    values,
  }),
}

export function* passwordRecoveryRequest(email, recaptcha, recaptchaAction) {
  const response = yield call(
    postRequest,
    PASSWORD_RECOVERY_REQUEST_ENDPOINT,
    {
      email,
      recaptcha,
      recaptchaAction,
    },
    { parseJSON: false, ignore401: true },
  )

  // Handle case where email is registered for both brand and media
  if (
    response.error &&
    response.type === 'status' &&
    response.errorData &&
    response.errorData.global_messages &&
    response.errorData.global_messages.UserFoundMultipleTimesException
  ) {
    return { needsScope: true }
  }

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_REQUEST,
  })

  return parsedResponse
}

export function* passwordRecoveryRequestWithScope(
  email,
  scope,
  recaptcha,
  recaptchaAction,
) {
  const response = yield call(postRequest, PASSWORD_RECOVERY_REQUEST_ENDPOINT, {
    email,
    scope,
    recaptcha,
    recaptchaAction,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_REQUEST,
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_RESET = {
  ResetPasswordRequestNotFoundException: values => ({
    key: i18nErrorKeys.PASSWORD_RESET_TOKEN_NOT_FOUND,
    values,
  }),
}

export function* passwordRecoveryCheckResetToken(token) {
  const response = yield call(
    getRequest,
    `${PASSWORD_RECOVERY_REQUEST_ENDPOINT}/${token}`,
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_RESET,
  })

  return parsedResponse
}

export function* passwordRecoveryResetPassword(token, password) {
  const response = yield call(
    postRequest,
    PASSWORD_RECOVERY_RESET_ENDPOINT,
    {
      token,
      password,
    },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_RESET,
  })

  return parsedResponse
}
