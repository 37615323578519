import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest, postRequest, deleteRequest, putRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'
import { i18nErrorKeys } from '../../app/common/validation'
import { STATISTICS_TABS } from './business'
import { prettifyConstant } from '../../app/common/routes'

const CAMPAIGNS_ENDPOINT = `${envConfig.usersBackendUrl}/brand/campaigns`

const campaignEndpointById = id =>
  `${envConfig.usersBackendUrl}/brand/campaigns/${id}`

const CAMPAIGNS_SUMMARY_ENDPOINT = `${envConfig.usersBackendUrl}/brand/campaigns/summary`

const campaignsSummaryByIdEndpoint = id =>
  `${envConfig.usersBackendUrl}/brand/campaigns/${id}/summary`

const campaignResourceTypeStatsEndpoint = (campaignId, resourceType) =>
  `${envConfig.searchBackendUrl}/statistics/campaign/${campaignId}${
    resourceType ? '/' : ''
  }${resourceType ? prettifyConstant(resourceType) : ''}`

const campaignOrderStatsEndpoint = (campaignId, orderId) =>
  `${envConfig.searchBackendUrl}/statistics/campaign/${campaignId}/order/${orderId}`

const compaignOrderListEndpoint = (campaignId, resourceType) =>
  `${envConfig.searchBackendUrl}/statistics/campaign/${campaignId}/${resourceType}/orders`

const campaignSEOURLsEndpoint = campaignId =>
  `${envConfig.searchBackendUrl}/statistics/campaign/${campaignId}/seo/urls`

const campaignSEOURLStatsEndpoint = (campaignId, seoUrl) =>
  `${
    envConfig.searchBackendUrl
  }/statistics/campaign/${campaignId}/seo/urls/${encodeURIComponent(seoUrl)}`

const publicationPreviewEndpoint = orderId =>
  `${envConfig.usersBackendUrl}/orders/${orderId}/preview`

export function* fetchCampaigns() {
  const response = yield call(getRequest, CAMPAIGNS_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

const MAP_GLOBAL_CREATE_CAMPAIGN = {
  CampaignAlreadyExistsException: values => ({
    key: i18nErrorKeys.CAMPAIGN_ALREADY_EXIST,
    values,
  }),
}

export function* createCampaign(name) {
  const response = yield call(
    postRequest,
    `${CAMPAIGNS_ENDPOINT}`,
    { name },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_CREATE_CAMPAIGN,
  })

  return parsedResponse
}

const MAP_GLOBAL_DELETE_CAMPAIGN = {
  CampaignNotFoundException: values => ({
    key: i18nErrorKeys.CAMPAIGN_NOT_FOUND,
    values,
  }),
  CampaignCanNotDeletedException: values => ({
    key: i18nErrorKeys.CAMPAIGN_CANNOT_BE_DELETED,
    values,
  }),
}

export function* deleteCampaign(campaignId) {
  const response = yield call(
    deleteRequest,
    campaignEndpointById(campaignId),
    {},
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_DELETE_CAMPAIGN,
  })

  return parsedResponse
}

export function* renameCampaign(campaignId, name) {
  const response = yield call(
    putRequest,
    campaignEndpointById(campaignId),
    { name },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchCampaignsSummary() {
  const response = yield call(getRequest, CAMPAIGNS_SUMMARY_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchCampaignsSummaryById(id) {
  const response = yield call(getRequest, campaignsSummaryByIdEndpoint(id), {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchCampaignResourceTypeStats(campaignId, resourceType) {
  const response = yield call(
    getRequest,
    campaignResourceTypeStatsEndpoint(
      campaignId,
      resourceType === STATISTICS_TABS.GLOBAL ? undefined : resourceType,
    ),
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchCampaignOrderStats(campaignId, orderId) {
  const response = yield call(
    getRequest,
    campaignOrderStatsEndpoint(campaignId, orderId),
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchCampaignOrderListByType(campaignId, resourceType) {
  const response = yield call(
    getRequest,
    compaignOrderListEndpoint(campaignId, prettifyConstant(resourceType)),
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchcampaignSEOURLs(campaignId) {
  const response = yield call(getRequest, campaignSEOURLsEndpoint(campaignId), {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchCampaignSEOURLStats(campaignId, seoUrl) {
  const response = yield call(
    getRequest,
    campaignSEOURLStatsEndpoint(campaignId, seoUrl),
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchPublicationPreview(orderId) {
  const response = yield call(getRequest, publicationPreviewEndpoint(orderId), {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}
