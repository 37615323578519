import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../../hocs/withFormErrors'
import FormattedFormErrors from '../../FormattedFormErrors'
import WarningMessage from '../../messages/WarningMessage'
import ModalSimpleContent from '../../modals/ModalSimpleContent'
import ModalHeader from '../../modals/ModalHeader'
import InputWithSubtitle from '../../form-elements/InputWithSubtitle'

const RenameCampaignModal = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,

  ...props
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal">
        <ModalSimpleContent
          actionText={i18n('action:rename')}
          isButtonLoading={isRequestPending}
        >
          <ModalHeader title={i18n('action:rename-campaign')} />
          <InputWithSubtitle
            type="text"
            name="name"
            widthBehaviour="full"
            inputLabel={i18n('noun:campaign-name')}
            placeholder={i18n('examples:campaign-name')}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            isTouched={touched.name}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.name && (
                <FormattedFormErrors errors={validationMessages.name} />
              )
            }
            isRequired
          />
          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  name: PropTypes.string.isRequired,
}

RenameCampaignModal.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,

  isRequestPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(RenameCampaignModal)
