import { compose, withPropsOnChange } from 'recompose'
import { STATISTICS_COLORS } from '../../services/campaigns/constants'
import withTimeSeriesGraph from './withTimeSeriesGraph'

const withTimeSeriesGraphStaticLegend = (
  seriesIteratorCreator,
  dataPointFn,
  { dataOutProp = 'data', dependentPropNames = [] },
) =>
  compose(
    withTimeSeriesGraph(seriesIteratorCreator, dataPointFn, {
      dataOutProp,
      dependentPropNames,
    }),

    withPropsOnChange([dataOutProp], ({ [dataOutProp]: data }) => ({
      [dataOutProp]:
        data &&
        data.map((data, i) => ({
          ...data,
          color: STATISTICS_COLORS[i % STATISTICS_COLORS.length],
        })),
    })),
  )

export default withTimeSeriesGraphStaticLegend
