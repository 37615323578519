import { remove } from 'reduken/hash'
import * as actionTypes from './action-types'
import { DOMAIN, HASH_KEY_NEEDS_SCOPE } from './constants'

export const passwordRecoveryRequest = (
  username,
  recaptcha,
  recaptchaAction,
) => ({
  type: actionTypes.REQUEST,
  payload: { username, recaptcha, recaptchaAction },
})

export const passwordRecoveryRequestWithScope = (
  username,
  scope,
  recaptcha,
  recaptchaAction,
) => ({
  type: actionTypes.REQUEST_WITH_SCOPE,
  payload: { username, scope, recaptcha, recaptchaAction },
})

export const passwordRecoveryCheckResetToken = token => ({
  type: actionTypes.CHECK_RESET_TOKEN,
  payload: { token },
})

export const passwordRecoveryResetPassword = (token, newPassword) => ({
  type: actionTypes.RESET_PASSWORD,
  payload: { token, newPassword },
})

export const clearNeedsScope = () => remove(DOMAIN, HASH_KEY_NEEDS_SCOPE)
