import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import IconText from '../texts/IconText'
import OrderTrackerTagView from './OrderTrackerTagView'
import PaperInfo from './../papers/PaperInfo'
import { ReactComponent as RadioChecked } from '../../styles/icons/radio-checked.svg'
import PreformattedText from '../texts/PreformattedText'

const OrderDetailBriefing = ({
  postDescription,
  sampleMessage,
  campaignObjective,
  i18n,
  showDeliveryHelp,
  showTrackerHelp,
  trackerTag,
  onCopyTrackerTagClick,
  hasSocialBroadcasting,
  hasPredelivery,
  words,
  urlSocialNetworks,
}) => {
  return (
    <OrderDetailSection title={i18n('sections:briefing-short')}>
      {!!postDescription && (
        <div className="order-detail-section-block">
          <h3 className="order-detail-section-subtitle">
            {i18n('order-detail:post-description')}
          </h3>
          <div className="order-detail-section-regular-text">
            <PreformattedText text={postDescription} />
          </div>
        </div>
      )}
      {!!sampleMessage && (
        <div className="order-detail-section-block">
          <h3 className="order-detail-section-subtitle">
            {i18n('checkout:sample-message')}
          </h3>
          <div className="order-detail-section-regular-text">
            <PreformattedText text={sampleMessage} />
          </div>
        </div>
      )}
      {!!urlSocialNetworks && urlSocialNetworks.length > 0 && (
        <div className="order-detail-section-block">
          <h3 className="order-detail-section-subtitle">
            {i18n('checkout:description-social-network')}
          </h3>
          <div className="order-detail-section-regular-text">
            <PreformattedText text={urlSocialNetworks.join('\n\n')} />
          </div>
        </div>
      )}
      {campaignObjective && (
        <div className="order-detail-section-block">
          <h3 className="order-detail-section-subtitle">
            {i18n('order-detail:campaign-objective')}
          </h3>
          <IconText
            text={campaignObjective}
            renderIcon={props => <RadioChecked {...props} />}
          />
        </div>
      )}
      {showDeliveryHelp && (
        <PaperInfo title={i18n('order-detail:delivery-help-title')} type="help">
          {hasPredelivery
            ? i18n('order-detail:delivery-help-predelivery', { words })
            : i18n('order-detail:delivery-help', { words })}
        </PaperInfo>
      )}
      {hasSocialBroadcasting && (
        <PaperInfo
          title={i18n('order-detail:broadcast-info-title')}
          type="default"
        >
          {i18n('order-detail:broadcast-info')}
        </PaperInfo>
      )}
      {showTrackerHelp && (
        <OrderTrackerTagView
          trackerTag={trackerTag}
          onCopyTrackerTagClick={onCopyTrackerTagClick}
        />
      )}
    </OrderDetailSection>
  )
}

OrderDetailBriefing.propTypes = {
  postDescription: PropTypes.string,
  campaignObjective: PropTypes.string,
  showDeliveryHelp: PropTypes.bool,
  showTrackerHelp: PropTypes.bool,
  hasSocialBroadcasting: PropTypes.bool,
  hasPredelivery: PropTypes.bool,
  words: PropTypes.number,
  onCopyTrackerTagClick: PropTypes.func,
  urlSocialNetworks: PropTypes.arrayOf(PropTypes.string),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailBriefing)
