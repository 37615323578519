import * as R from 'ramda'
import envConfig from '../../environments'
import { withProps, compose } from 'recompose'
import qs from 'query-string'
import { prettifyConstant } from '../common/routes'
import withEffect from '../../hocs/withEffect'

const makeDownloadUrlAll = (campaignId, tab) =>
  `${envConfig.authBackendUrl}/session/statistics/campaign/${campaignId}${
    tab ? '/' : ''
  }${tab ? prettifyConstant(tab) : ''}/download`

const makeDownloadUrlPublication = (campaignId, orderId) =>
  `${envConfig.authBackendUrl}/session/statistics/campaign/${campaignId}/order/${orderId}/download`

const makeDownloadUrlSEOUrl = (campaignId, seoUrl) =>
  `${
    envConfig.authBackendUrl
  }/session/statistics/campaign/${campaignId}/seo/urls/${encodeURIComponent(
    seoUrl,
  )}/download`

const withExportReportContents = queryParamsFromProps =>
  compose(
    withProps(props => {
      const downloadUrl = !R.isNil(props.orderId)
        ? makeDownloadUrlPublication(props.campaignId, props.orderId)
        : !R.isNil(props.seoUrl)
        ? makeDownloadUrlSEOUrl(props.campaignId, props.seoUrl)
        : makeDownloadUrlAll(
            props.campaignId,
            prettifyConstant(props.resourceType),
          )

      const queryParams = queryParamsFromProps
        ? qs.stringify(queryParamsFromProps(props), { arrayFormat: 'comma' })
        : ''

      return {
        reportDownloadPPTURL:
          queryParams.length > 0
            ? `${downloadUrl}/pptx?${queryParams}`
            : `${downloadUrl}/pptx`,
        reportDownloadPDFURL:
          queryParams.length > 0
            ? `${downloadUrl}/pdf?${queryParams}`
            : `${downloadUrl}/pdf`,
      }
    }),

    withEffect(
      ({
        reportDownloadPPTURL,
        reportDownloadPDFURL,
        setReportDownloadURLs,
      }) => {
        setReportDownloadURLs(reportDownloadPPTURL, reportDownloadPDFURL)
      },
      ({
        reportDownloadPPTURL,
        reportDownloadPDFURL,
        setReportDownloadURLs,
      }) => [reportDownloadPPTURL, reportDownloadPDFURL, setReportDownloadURLs],
    ),
  )

export default withExportReportContents
