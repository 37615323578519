import React from 'react'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import withTranslations from '../../../hocs/withTranslations'
import DashboardPanel from '../DashboardPanel'
import ActionCard from '../../cards/ActionCard'
import imgAffiliatesBig from '../../cards/images/affiliatesBig.png'
import imgAffiliatesSmall from '../../cards/images/affiliatesSmall.png'
import { ROUTE_MEDIA_AFFILIATES } from '../../../app/common/routes'

const AffiliatesPanel = ({ balance, i18n, hasTitle, isLoading }) => {
  return (
    <DashboardPanel size="one-third">
      <ActionCard
        messageSlot={
          <p className="action-card-message">
            {i18n('welcome-dashboard:invite-win')}
          </p>
        }
        linkText={i18n('action:get-affiliates')}
        linkUrl={ROUTE_MEDIA_AFFILIATES.linkTo()}
        imageBig={imgAffiliatesBig}
        imageSmall={imgAffiliatesSmall}
      />
    </DashboardPanel>
  )
}

AffiliatesPanel.defaultProps = {}

AffiliatesPanel.propTypes = {
  balance: CoobisPropTypes.money,
}

export default withTranslations(AffiliatesPanel)
