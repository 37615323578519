import {
  compose,
  withPropsOnChange,
  lifecycle,
  withHandlers,
  branch,
} from 'recompose'
import { connect } from 'react-redux'
import copy from 'clipboard-copy'
import {
  getMediaOrderDetailById,
  fetchMediaOrderDetail,
} from '../../services/orders'
import { FETCH_MEDIA_ORDER_DETAIL } from '../../services/orders/action-types'
import { withScope } from '../../services/auth/hocs'
import withOrderModalActions from '../common/withOrderModalActions'
import OrderDetailView from '../../ui/orders/OrderDetailView'
import { isNilOrEmpty } from '../utils'
import withSpinnerWithId from '../../hocs/withSpinnerWithId'
import { ROUTE_MEDIA_ORDER_BY_ID } from '../common/routes'
import withTranslations from '../../hocs/withTranslations'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withOrderDeliveryHistory from './withOrderDeliveryHistory'
import withOrderDetailTabs from './withOrderDetailTabs'
import orderDataToViewProps from './orderDataToViewProps'

const enhance = compose(
  withTranslations,
  withScope,

  withPropsOnChange(['match'], ({ match }) => ({
    orderId: Number(match.params.orderId) || 'no-order-id',
  })),

  withOrderDetailTabs((selectedTab, { orderId }) =>
    ROUTE_MEDIA_ORDER_BY_ID.linkTo({
      orderId,
      selectedTab,
    }),
  ),

  connect(
    (state, { orderId, scope, i18n }) => {
      const orderData = getMediaOrderDetailById(orderId, state)
      if (!orderData) return {}

      const viewProps = orderDataToViewProps(orderData, scope, i18n)
      return viewProps
    },
    { fetchOrderDetail: fetchMediaOrderDetail },
  ),

  lifecycle({
    componentDidMount() {
      this.props.fetchOrderDetail(this.props.orderId)
    },
  }),

  // Show global spinner only the first time it is loaded
  branch(
    ({ orderData }) => isNilOrEmpty(orderData),
    withSpinnerWithId(FETCH_MEDIA_ORDER_DETAIL, {
      idProp: 'orderId',
    }),
  ),

  withRoute404ErrorWithId(FETCH_MEDIA_ORDER_DETAIL, 'orderId'),
  withRouteServerErrorWithId(FETCH_MEDIA_ORDER_DETAIL, 'orderId'),

  withOrderModalActions,
  withHandlers({
    onActionClick: props => action => {
      props.handleOrderAction(props.orderId, action)
    },
    onCopyTrackerTagClick: props => _ => {
      copy(props.trackerTag)
    },
  }),

  withOrderDeliveryHistory,
)

export default enhance(OrderDetailView)
