import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import Paragraph from '../texts/Paragraph'
import PreformattedText from '../texts/PreformattedText'
import Rating from '../rating/Rating'

const OrderDetailComments = ({
  predeliveryText,
  deliveryText,
  rating,
  i18n,
}) => {
  return (
    <OrderDetailSection title={i18n('sections:comments')}>
      {predeliveryText && (
        <Paragraph title={i18n('sections:pre-delivery')}>
          <PreformattedText text={predeliveryText} />
        </Paragraph>
      )}
      {deliveryText && (
        <Paragraph title={i18n('sections:delivery')}>
          <PreformattedText text={deliveryText} />
        </Paragraph>
      )}
      {rating && (
        <Paragraph title={i18n('sections:rating')}>
          <Rating rating={rating} />
        </Paragraph>
      )}
    </OrderDetailSection>
  )
}

OrderDetailComments.propTypes = {
  predeliveryText: PropTypes.string,
  deliveryText: PropTypes.string,
  rating: PropTypes.number,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailComments)
