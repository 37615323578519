import React from 'react'
import PropTypes from 'prop-types'
import AlternativeGaugeBar from './AlternativeGaugeBar'
import IconButton from '../buttons/IconButton'
import { ReactComponent as ChevronLeft } from './../../styles/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from './../../styles/icons/chevron-right.svg'

const AlternativeGaugeWidget = ({
  titleLabel,
  totalLabel,
  items,
  color,
  isLoading,
  isPrevDisabled,
  isNextDisabled,
  onPrevClick,
  onNextClick,
}) => {
  return (
    <div className="alternative-gauge-widget">
      {titleLabel && (
        <div className="alternative-gauge-widget-title">{titleLabel}</div>
      )}
      <div className="alternative-gauge-widget-data">
        {items.map(item => (
          <AlternativeGaugeBar
            key={item.id}
            label={item.label}
            color={color}
            value={item.value}
          />
        ))}
      </div>
      <div className="alternative-gauge-widget-pagination">
        <IconButton
          hierarchy="secondary"
          variant="neutral"
          isDisabled={isPrevDisabled}
          isLoading={isLoading}
          onClick={onPrevClick}
        >
          <ChevronLeft className="icon rdx-button-icon" />
        </IconButton>
        {totalLabel && (
          <p className="alternative-gauge-widget-pagination-info">
            <span>{totalLabel}</span>
          </p>
        )}
        <IconButton
          hierarchy="secondary"
          variant="neutral"
          isDisabled={isNextDisabled}
          isLoading={isLoading}
          onClick={onNextClick}
        >
          <ChevronRight className="icon rdx-button-icon" />
        </IconButton>
      </div>
    </div>
  )
}

AlternativeGaugeWidget.propTypes = {
  titleLabel: PropTypes.string,
  totalLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  isPrevDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
}

export default AlternativeGaugeWidget
