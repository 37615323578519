import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import ChipBasic from '../chips/ChipBasic'
import Paragraph from '../texts/Paragraph'
import IconText from '../texts/IconText'
import { ReactComponent as RadioChecked } from '../../styles/icons/radio-checked.svg'

const OrderDetailLinks = ({ items, i18n }) => {
  return (
    <OrderDetailSection title={i18n('sections:links')}>
      {items.map((item, i) => (
        <div className="order-detail-link" key={i}>
          <ChipBasic title={i18n('checkout:link-url')} text={item.url} />
          {!!item.text && (
            <ChipBasic title={i18n('checkout:link-text')} text={item.text} />
          )}
          <Paragraph title={i18n('checkout:link-type')}>
            <IconText
              text={i18n(item.isFollow ? 'noun:do-follow' : 'noun:no-follow')}
              renderIcon={props => <RadioChecked {...props} />}
            />
          </Paragraph>
        </div>
      ))}
    </OrderDetailSection>
  )
}

OrderDetailLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string,
      isFollow: PropTypes.bool,
    }),
  ),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailLinks)
