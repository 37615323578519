import { compose, withState, withPropsOnChange } from 'recompose'
import * as R from 'ramda'
import withTranslations from '../../hocs/withTranslations'
import { ascendLocale, descendLocale } from '../utils'
import {
  mapOrderStatusToI18n,
  mapServiceTypeToResourceType,
  mapServiceTypeToOrderTypeI18n,
} from '../../services/orders/business'

export const SORT_ORDERS = {
  STATUS_DESC: 'STATUS_DESC',
  STATUS_ASC: 'STATUS_ASC',
  BRAND_DESC: 'BRAND_DESC',
  BRAND_ASC: 'BRAND_ASC',
  CAMPAIGN_DESC: 'CAMPAIGN_DESC',
  CAMPAIGN_ASC: 'CAMPAIGN_ASC',
  RESOURCE_DESC: 'RESOURCE_DESC',
  RESOURCE_ASC: 'RESOURCE_ASC',
  SERVICE_DESC: 'SERVICE_DESC',
  SERVICE_ASC: 'SERVICE_ASC',
  DATE_DESC: 'DATE_DESC',
  DATE_ASC: 'DATE_ASC',
  PRICE_DESC: 'PRICE_DESC',
  PRICE_ASC: 'PRICE_ASC',
}

const statusComparator = i18n =>
  R.pipe(
    R.prop('status'),
    R.flip(R.prop)(mapOrderStatusToI18n),
    i18n,
    R.toLower,
  )

const brandComparator = R.pipe(
  R.prop('brandName'),
  R.toLower,
)

const campaignComparator = R.pipe(
  R.path(['campaign', 'name']),
  R.toLower,
)

const resourceComparator = R.pipe(
  R.path(['resource', 'name']),
  R.toLower,
)

const serviceComparator = i18n =>
  R.pipe(
    R.prop('type'),
    type =>
      `${mapServiceTypeToResourceType[type]}-${i18n(
        mapServiceTypeToOrderTypeI18n[type],
      )}`,
    R.toLower,
  )

const remainingDaysComparator = R.prop('limitDateToAction')

const dateComparator = R.pipe(
  R.prop('statusDate'),
  date => +date,
)

const priceComparator = R.prop('price')

export const getBrandCurrentOrderSortBy = i18n => ({
  STATUS_DESC: descendLocale(statusComparator(i18n)),
  STATUS_ASC: ascendLocale(statusComparator(i18n)),
  RESOURCE_DESC: descendLocale(resourceComparator),
  RESOURCE_ASC: ascendLocale(resourceComparator),
  SERVICE_DESC: descendLocale(serviceComparator(i18n)),
  SERVICE_ASC: ascendLocale(serviceComparator(i18n)),
  CAMPAIGN_DESC: descendLocale(campaignComparator),
  CAMPAIGN_ASC: ascendLocale(campaignComparator),
  DATE_DESC: R.descend(remainingDaysComparator),
  DATE_ASC: R.ascend(remainingDaysComparator),
})

export const getMediaCurrentOrderSortBy = i18n => ({
  STATUS_DESC: descendLocale(statusComparator(i18n)),
  STATUS_ASC: ascendLocale(statusComparator(i18n)),
  BRAND_DESC: descendLocale(brandComparator),
  BRAND_ASC: ascendLocale(brandComparator),
  RESOURCE_DESC: descendLocale(resourceComparator),
  RESOURCE_ASC: ascendLocale(resourceComparator),
  SERVICE_DESC: descendLocale(serviceComparator(i18n)),
  SERVICE_ASC: ascendLocale(serviceComparator(i18n)),
  DATE_DESC: R.descend(remainingDaysComparator),
  DATE_ASC: R.ascend(remainingDaysComparator),
  PRICE_DESC: R.descend(priceComparator),
  PRICE_ASC: R.ascend(priceComparator),
})

export const getClosedOrderSortBy = i18n => ({
  STATUS_DESC: descendLocale(statusComparator(i18n)),
  STATUS_ASC: ascendLocale(statusComparator(i18n)),
  BRAND_DESC: descendLocale(brandComparator),
  BRAND_ASC: ascendLocale(brandComparator),
  RESOURCE_DESC: descendLocale(resourceComparator),
  RESOURCE_ASC: ascendLocale(resourceComparator),
  SERVICE_DESC: descendLocale(serviceComparator(i18n)),
  SERVICE_ASC: ascendLocale(serviceComparator(i18n)),
  DATE_DESC: R.descend(dateComparator),
  DATE_ASC: R.ascend(dateComparator),
  PRICE_DESC: R.descend(priceComparator),
  PRICE_ASC: R.ascend(priceComparator),
})

export const CURRENT_ORDERS_DEFAULT_SORT_TYPE = SORT_ORDERS.DATE_ASC

export const CLOSED_ORDERS_DEFAULT_SORT_TYPE = SORT_ORDERS.DATE_DESC

const withSortOrders = ({
  inProp,
  sortValueProp,
  onSortChangeProp,
  defaultSortType,
  getSortByFunction,
}) =>
  compose(
    withTranslations,

    withState(sortValueProp, onSortChangeProp, defaultSortType),

    withPropsOnChange(
      [inProp, sortValueProp, 'i18n'],
      ({ [inProp]: orders, [sortValueProp]: sortValue, i18n }) => ({
        [inProp]: !!orders
          ? R.sort(getSortByFunction(i18n)[sortValue], orders)
          : orders,
      }),
    ),
  )

export default withSortOrders
