import { compose, withPropsOnChange, branch } from 'recompose'
import withResourceURLParams from './withResourceURLParams'
import withEditResource from './withEditResource'
import { unprettifyConstant } from '../common/routes'
import { withReachedSocialResourceStep } from '../../services/resources/hocs'

const withSocialResource = currentStep =>
  compose(
    withResourceURLParams,
    withPropsOnChange(['match'], ({ match }) => ({
      channel: match.params.socialNetwork
        ? unprettifyConstant(match.params.socialNetwork)
        : 'no-social-network',
    })),
    branch(({ isEditing }) => isEditing, withEditResource),
    withReachedSocialResourceStep(currentStep),
  )

export default withSocialResource
