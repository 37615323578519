import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import ScrollToTop from './common/ScrollToTop'
import useOnline from '../hooks/useOnline'
import ModalPortal from './common/ModalPortal'
import OfflineMessage from '../ui/global-message/OfflineMessage'
import Snackbar from '../ui/messages/Snackbar'
import { withToast } from '../services/toasts/hocs'
import { Alert, AlertsContainer } from '../ui/messages/Alerts'
import { withAlerts } from '../services/alerts/hocs'
import RootRoutes from './RootRoutes'
import withTranslations from '../hocs/withTranslations'
import { withLanguageSelect } from '../services/me/hocs'
import { withScope } from '../services/auth/hocs'
import { AuthScope } from '../services/auth/business'
import BrandLayout from './brand/BrandLayout'
import MediaLayout from './media/MediaLayout'
import PublicLayout from './common/PublicLayout'
import useHideAllExcept from '../hooks/useHideAllExcept'
import * as R from 'ramda'

const App = ({
  hasToast,
  toast,
  closeToast,
  discardAlert,
  alerts,
  scope,
  language,
  setLanguage,
  i18n,
}) => {
  const isOnline = useOnline()

  const Layout =
    scope === AuthScope.BRAND
      ? BrandLayout
      : scope === AuthScope.MEDIA
      ? MediaLayout
      : PublicLayout

  // hideAllExcept allows to hide everything except one element (it used in report screenshots)
  // Example: /brand/campaigns/3345/web-branding?hideAllExcept=.chart-wrapper%20.clustered-bar-chart&height=300&width=600
  useHideAllExcept()

  const userAlerts = alerts
    .map(alert => {
      const [firstIdx] = Object.keys(alert.contents)
      return alert.contents.length <= 0 ? null : (
        <Alert
          key={alert.id}
          title={alert.contents[firstIdx].title}
          body={alert.contents[firstIdx].body}
          isVisible={alert.isVisible}
          onClose={() => discardAlert(alert.id)}
        />
      )
    })
    .filter(a => a)

  return (
    <>
      <Layout language={language} setLanguage={setLanguage}>
        <RootRoutes />
      </Layout>

      {!isOnline && (
        <ModalPortal>
          <OfflineMessage />
        </ModalPortal>
      )}

      {hasToast && (
        <Snackbar
          type={toast.type}
          title={i18n(toast.title)}
          description={i18n(toast.description, R.pathOr({}, ['values'], toast))}
          helpLink={
            R.path(['values', 'helpLink'], toast)
              ? {
                  url: i18n(toast.values.helpLink.url),
                  text: i18n(toast.values.helpLink.text),
                }
              : undefined
          }
          isVisible={toast.isVisible}
          hasIcon={true}
          onClose={closeToast}
        />
      )}

      {userAlerts.length > 0 && <AlertsContainer>{userAlerts}</AlertsContainer>}

      <ScrollToTop />
    </>
  )
}

App.propTypes = {
  hasToast: PropTypes.bool.isRequired,
  toast: PropTypes.shape({
    type: PropTypes.oneOf(['default', 'success', 'warning', 'error'])
      .isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
  }),
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      contents: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          body: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  closeToast: PropTypes.func.isRequired,
}

const enhance = compose(
  withTranslations,
  withToast,
  withScope,
  withLanguageSelect,
  withAlerts,
)

export default enhance(App)
