import { useEffect, useRef } from 'react'

const useRandomId = () => {
  const idRef = useRef()

  useEffect(() => {
    idRef.current = `${Math.random()}-${Math.random()}-${Math.random()}`
  }, [])

  return idRef.current
}

export default useRandomId
