import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import CampaignPreviewTwitter from '../social-previews/CampaignPreviewTwitter'
import { isNilOrEmpty } from '../../../app/utils'

const CampaignDetailTwitterPublication = ({
  previews,
  i18n,
  // Metrics values
  engagementRate,
  impressions,
  engagements,
  likes,
  retweets,
  comments,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        {engagementRate > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagementRate}
            label={i18n('metrics:engagement-rate')}
            isPercentage
          />
        )}
        {impressions > 0 && (
          <AnalyticsKpi
            iconType="target"
            value={impressions}
            label={i18n('metrics:impressions')}
            isTruncated
          />
        )}
        {engagements > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagements}
            label={i18n('metrics:engagements')}
            isTruncated
          />
        )}
        {likes > 0 && (
          <AnalyticsKpi
            iconType="like"
            value={likes}
            label={i18n('metrics:likes')}
            isTruncated
          />
        )}
        {retweets > 0 && (
          <AnalyticsKpi
            iconType="megaphone"
            value={retweets}
            label={i18n('metrics:retweets')}
            isTruncated
          />
        )}
        {comments > 0 && (
          <AnalyticsKpi
            iconType="comment"
            value={comments}
            label={i18n('metrics:comments')}
            isTruncated
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewTwitter items={previews} />
      ) : null}
    </>
  )
}

CampaignDetailTwitterPublication.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  engagementRate: PropTypes.number,
  impressions: PropTypes.number,
  engagements: PropTypes.number,
  likes: PropTypes.number,
  retweets: PropTypes.number,
  comments: PropTypes.number,
}

export default withTranslations(CampaignDetailTwitterPublication)
