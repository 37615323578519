import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../avatar/Avatar'
import previewFooterImage from './images/twitter-footer.png'

const PreviewBoxTwitter = ({ text, userName, avatar, userNick }) => {
  return (
    <div className="preview-box twitter">
      <div className="preview-header">
        <Avatar image={avatar} />
        <div className="user-info">
          <p className="user-name">{userName}</p>
          <p className="user-nick">{userNick}</p>
        </div>
      </div>
      <div className="preview-body">
        <p className="preview-description">{text}</p>
      </div>
      <div className="preview-footer">
        <img className="image" src={previewFooterImage} alt="preview" />
      </div>
    </div>
  )
}

PreviewBoxTwitter.propTypes = {
  text: PropTypes.string,
  userName: PropTypes.string,
  userNick: PropTypes.string,
  avatar: PropTypes.string,
}

PreviewBoxTwitter.defaultProps = {
  userName: 'User name',
  userNick: '@user_name',
}

export default PreviewBoxTwitter
