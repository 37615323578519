import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@redradix/components.tabs'

const ProfileTabs = ({ controllers, children, ...props }) => {
  return (
    <div className="order-detail-tabs">
      <Tabs controllers={controllers} controllersAlignment="center" {...props}>
        {children}
      </Tabs>
    </div>
  )
}

ProfileTabs.propTypes = {
  children: PropTypes.node,
  controllers: PropTypes.array,
}

export default ProfileTabs
