import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import { globalMessagesPropType } from '../../../hocs/withFormErrors'
import FormattedFormErrors from '../../FormattedFormErrors'
import MessageModalContent from '../../modals/MessageModalContent'
import WarningMessage from '../../messages/WarningMessage'
import sentRequestImage from '../images/sent-request-small.png'

const WithdrawRequestConfirmationModal = ({ globalMessages, i18n }) => {
  return (
    <MessageModalContent
      imageUrl={sentRequestImage}
      title={i18n('message:sent-request')}
    >
      <p>{i18n('message:receive-money-estimated-time')}</p>
      <p>{i18n('message:beware-paypal-fees')}</p>
      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}
    </MessageModalContent>
  )
}

WithdrawRequestConfirmationModal.propTypes = {
  // withFormErrors
  globalMessages: globalMessagesPropType,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(WithdrawRequestConfirmationModal)
