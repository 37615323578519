import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import Paragraph from './../texts/Paragraph'
import PreformattedText from '../texts/PreformattedText'
import OrderModalReviewDeliveryDownloads from './OrderModalReviewDeliveryDownloads'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const OrderModalReviewDelivery = ({
  mediaComment,
  onCorrectClick,
  onApproveClick,
  webUrl,
  facebookUrl,
  twitterUrl,
  instagram,
  socialNetworkUrl,
  instagramUrl,
  instagramFilename,
  i18n,
}) => {
  return (
    <div className="order-modal order-modal-compact-vertical">
      <ModalSimpleContent
        doubleActions
        action1Text={i18n('action:go-to-amend')}
        action1={onCorrectClick}
        action2Text={i18n('action:go-to-approve')}
        action2={onApproveClick}
        buttonVariant2="default"
      >
        <ModalHeader
          title={i18n('action:review-delivery')}
          tagline={i18n('order-modal:delivery-review-tagline')}
        />

        {!!mediaComment && (
          <Paragraph title={i18n('noun:media-comment')}>
            <PreformattedText text={mediaComment} />
          </Paragraph>
        )}

        <OrderModalReviewDeliveryDownloads
          webUrl={webUrl}
          facebookUrl={facebookUrl}
          twitterUrl={twitterUrl}
          instagram={instagram}
          socialNetworkUrl={socialNetworkUrl}
          instagramUrl={instagramUrl}
          instagramFilename={instagramFilename}
        />
      </ModalSimpleContent>
    </div>
  )
}

OrderModalReviewDelivery.propTypes = {
  mediaComment: PropTypes.string,
  webUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagram: CoobisPropTypes.urlFile,
  socialNetworkUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  instagramFilename: PropTypes.string,
  onApproveClick: PropTypes.func,
  onCorrectClick: PropTypes.func,
  // withTranslations
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderModalReviewDelivery)
