import React from 'react'
import PropTypes from 'prop-types'
import { MediaQueryDesk } from '../../shared/MediaQueries'
import Radio from '@redradix/components.radio'
import { Input } from '@redradix/components.input'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import Button from '@redradix/components.button'
import HackFormattedMoney from '../../HackFormattedMoney'
import FormField from '../../form-elements/FormField'
import FormattedFormErrors from '../../FormattedFormErrors'
import ModalTitle from '../../texts/ModalTitle'
import withTranslations from '../../../hocs/withTranslations'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../../hocs/withFormErrors'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import InputFile from '../../form-elements/InputFile'
import { ROUTE_MEDIA_PERSONAL_DATA } from '../../../app/common/routes'
import Tooltip from '@redradix/components.tooltip'
import TooltipLargeWrapper from '../../tooltips/tooltipLargeWrapper'
import TooltipInfoTitle from '../../texts/TooltipInfoTitle'
import TooltipSubtitle from '../../texts/TooltipSubtitle'
import TooltipInfoText from '../../texts/TooltipInfoText'
import TooltipTrigger from '../../tooltips/TooltipTrigger'
import HelpMessage from '../../messages/HelpMessage'
import WarningMessage from '../../messages/WarningMessage'
import ChipBasic from '../../chips/ChipBasic'
import fundsImage from '../images/funds-small.png'

const ALLOWED_INVOICE_EXTENSIONS = '.pdf'

const WithdrawFundsModal = ({
  withdrawAmount,
  vatRate,
  incomeTaxRates,
  requiresResidenceDocument,
  requiresTypeDocument,
  canChoosePaymentMethod,
  invoiceUploadRequired,
  totalAmount,
  i18n,

  // Formik
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  return (
    <form className="account-block-form" onSubmit={handleSubmit}>
      <div className="withdraw-funds-modal">
        <ModalTitle text={i18n('action:withdraw-funds')} />
        <div className="withdraw-funds-modal-wrapper">
          <div className="withdraw-funds-modal-column">
            <div className="withdraw-funds-modal-row">
              <div className="withdraw-funds-initial-amount-block">
                <ChipBasic
                  title={i18n('noun:amount-to-withdraw')}
                  text={<HackFormattedMoney value={withdrawAmount} />}
                />

                <p className="small-text">
                  {vatRate > 0 && (
                    <span>{i18n('withdraw:vat-rate', { rate: vatRate })}</span>
                  )}

                  {incomeTaxRates.length === 1 && incomeTaxRates[0] !== 0 && (
                    <span>
                      {i18n('withdraw:income-tax-rate', {
                        rate: incomeTaxRates[0],
                      })}
                    </span>
                  )}
                </p>
              </div>
              <>
                {incomeTaxRates.length > 1 && (
                  <div className="withdraw-funds-tax-rate-selection-block">
                    <FormField
                      block
                      isTouched={touched.rate}
                      errorText={
                        validationMessages.rate && (
                          <FormattedFormErrors
                            errors={validationMessages.rate}
                          />
                        )
                      }
                    >
                      {incomeTaxRates.map(rate => (
                        <Radio
                          key={rate}
                          name="rate"
                          checked={values.rate === rate}
                          value={rate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isDisabled={isRequestPending}
                          isTouched={touched.rate}
                        >
                          {i18n('withdraw:tax-rate-option', { rate })}
                        </Radio>
                      ))}
                    </FormField>
                    {requiresResidenceDocument && (
                      <p className="withdraw-funds-tax-rate-disclaimer">
                        {i18n('withdraw:residence-document-disclaimer', {
                          link: (
                            <Link to={ROUTE_MEDIA_PERSONAL_DATA.linkTo()}>
                              {i18n('withdraw:disclaimer-link')}
                            </Link>
                          ),
                        })}
                      </p>
                    )}

                    {requiresTypeDocument && (
                      <p className="withdraw-funds-tax-rate-disclaimer">
                        {i18n('withdraw:type-document-disclaimer', {
                          link: (
                            <Link
                              key="link"
                              to={ROUTE_MEDIA_PERSONAL_DATA.linkTo()}
                            >
                              {i18n('withdraw:disclaimer-link')}
                            </Link>
                          ),
                          incomeTaxRate: values.rate,
                          neededIncomeTaxRate: incomeTaxRates.find(
                            x => x !== values.rate,
                          ),
                        })}
                      </p>
                    )}
                  </div>
                )}
              </>
              {(canChoosePaymentMethod || invoiceUploadRequired) && (
                <div className="withdraw-funds-final-amount-block">
                  <p className="title">{i18n('noun:total')}</p>
                  <p className="amount">
                    <HackFormattedMoney value={totalAmount} />
                  </p>
                </div>
              )}
            </div>
            <MediaQueryDesk>
              <div className="withdraw-funds-modal-row">
                <picture>
                  <img src={fundsImage} alt="Decoration" />
                </picture>
              </div>
            </MediaQueryDesk>
          </div>
          <div className="withdraw-funds-modal-column">
            <div className="withdraw-funds-modal-row">
              {(canChoosePaymentMethod || invoiceUploadRequired) && (
                <div className="withdraw-funds-payment-data-form">
                  {canChoosePaymentMethod && (
                    <FormField
                      block
                      label={
                        invoiceUploadRequired
                          ? i18n('withdraw:payment-method-label-numbered', {
                              number: 1,
                            })
                          : i18n('withdraw:payment-method-label')
                      }
                      isTouched={touched.method}
                      errorText={
                        validationMessages.method && (
                          <FormattedFormErrors
                            errors={validationMessages.method}
                          />
                        )
                      }
                    >
                      <Radio
                        name="method"
                        checked={values.method === 'PAYPAL'}
                        value={'PAYPAL'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isRequestPending}
                        isTouched={touched.method}
                      >
                        {i18n('withdraw:label-paypal')}
                      </Radio>
                      <Radio
                        name="method"
                        checked={values.method === 'IBAN'}
                        value={'IBAN'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isRequestPending}
                        isTouched={touched.method}
                      >
                        {i18n('withdraw:label-iban')}
                      </Radio>
                    </FormField>
                  )}
                  {invoiceUploadRequired && (
                    <>
                      <Input
                        type="text"
                        name="invoiceNumber"
                        label={
                          <>
                            <span>
                              {i18n('withdraw:invoice-number-label', {
                                number: canChoosePaymentMethod ? 2 : 1,
                              })}
                            </span>
                            <Tooltip
                              position="right"
                              widthFixed
                              hasArrow={false}
                              renderTooltip={() => (
                                <TooltipLargeWrapper>
                                  <TooltipInfoTitle
                                    text={i18n(
                                      'withdraw:invoice-instructions-title',
                                    )}
                                  />
                                  <TooltipSubtitle
                                    text={i18n(
                                      'withdraw:invoice-instructions-detail-subtitle',
                                    )}
                                  />
                                  <TooltipInfoText
                                    text={i18n(
                                      'withdraw:invoice-instructions-detail-description-1',
                                    )}
                                  />
                                  <TooltipInfoText
                                    text={i18n(
                                      'withdraw:invoice-instructions-detail-description-2',
                                    )}
                                  />
                                  <TooltipInfoText
                                    text={i18n(
                                      'withdraw:invoice-instructions-detail-description-3',
                                    )}
                                  />
                                  <TooltipInfoText
                                    text={i18n(
                                      'withdraw:invoice-instructions-detail-description-4',
                                    )}
                                  />
                                  <TooltipInfoText
                                    text={i18n(
                                      'withdraw:invoice-instructions-detail-description-5',
                                    )}
                                  />
                                  <TooltipSubtitle
                                    text={i18n(
                                      'withdraw:invoice-instructions-taxes-subtitle',
                                    )}
                                  />
                                  <TooltipInfoText
                                    text={i18n(
                                      'withdraw:invoice-instructions-taxes-description-1',
                                    )}
                                  />
                                  <TooltipInfoText
                                    text={i18n(
                                      'withdraw:invoice-instructions-taxes-description-2',
                                    )}
                                  />
                                </TooltipLargeWrapper>
                              )}
                              renderTrigger={(
                                { open, close, triggerRef },
                                triggerClasses,
                              ) => (
                                <TooltipTrigger
                                  classNames={triggerClasses}
                                  onMouseOver={open}
                                  onMouseOut={close}
                                  ref={triggerRef}
                                />
                              )}
                            />
                          </>
                        }
                        placeholder={i18n(
                          'withdraw:invoice-number-placeholder',
                        )}
                        widthBehaviour="full"
                        value={values.invoiceNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isTouched={touched.invoiceNumber}
                        isDisabled={isRequestPending}
                        errorText={
                          validationMessages.invoiceNumber && (
                            <FormattedFormErrors
                              errors={validationMessages.invoiceNumber}
                            />
                          )
                        }
                      />

                      <InputFile
                        id="invoiceUpload"
                        name="invoiceUpload"
                        label={i18n('withdraw:invoice-upload-label', {
                          number: canChoosePaymentMethod ? 3 : 2,
                        })}
                        placeholder={i18n(
                          'withdraw:invoice-upload-placeholder',
                        )}
                        widthBehaviour="full"
                        fileName={R.path(['invoiceUpload', 'name'], values)}
                        isUploaded={false}
                        accept={ALLOWED_INVOICE_EXTENSIONS}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isTouched={touched.invoiceUpload}
                        isDisabled={isRequestPending}
                        errorText={
                          validationMessages.invoiceUpload && (
                            <FormattedFormErrors
                              errors={validationMessages.invoiceUpload}
                            />
                          )
                        }
                      />

                      <p className="small-text">
                        {i18n('withdraw:invoice-upload-disclaimer')}
                      </p>
                    </>
                  )}
                  <Button
                    type="submit"
                    hierarchy="secondary"
                    isLoading={isRequestPending}
                  >
                    {i18n('action:withdraw-funds')}
                  </Button>
                </div>
              )}

              {!canChoosePaymentMethod && !invoiceUploadRequired && (
                <>
                  <div className="withdraw-funds-final-amount-block">
                    <p className="title">{i18n('noun:total')}</p>
                    <p className="amount">
                      <HackFormattedMoney value={totalAmount} />
                    </p>
                  </div>
                  <div className="withdraw-funds-main-action">
                    <Button
                      type="submit"
                      hierarchy="secondary"
                      isLoading={isRequestPending}
                    >
                      {i18n('action:withdraw-funds')}
                    </Button>
                  </div>
                </>
              )}

              {globalMessages && (
                <WarningMessage
                  text={<FormattedFormErrors errors={globalMessages} />}
                />
              )}
            </div>
            <div className="withdraw-funds-modal-row">
              <HelpMessage
                iconType="help"
                phone="+34914149191"
                formattedPhone="(+34) 914 14 91 91"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  rate: PropTypes.number,
  method: PropTypes.oneOf(['IBAN', 'PAYPAL']),
  invoiceNumber: PropTypes.string,
  invoiceUpload: PropTypes.any,
}

WithdrawFundsModal.propTypes = {
  withdrawAmount: CoobisPropTypes.money,
  vatRate: PropTypes.number,
  incomeTaxRates: PropTypes.arrayOf(PropTypes.number),
  requiresResidenceDocument: PropTypes.bool,
  requiresTypeDocument: PropTypes.bool,
  canChoosePaymentMethod: PropTypes.bool,
  invoiceUploadRequired: PropTypes.bool,
  totalAmount: CoobisPropTypes.money,

  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(WithdrawFundsModal)
