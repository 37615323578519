import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import Textarea from './../form-elements/Textarea'
import Paragraph from './../texts/Paragraph'
import AnchorLink from './../texts/AnchorLink'
import Datepicker from '../form-elements/Datepicker'

const TIME_OPTIONS = Array(24)
  .fill()
  .map((_, i) => ({ hours: i, minutes: 0 }))

const OrderModalReviewPredeliveryCorrect = ({
  linkText,
  linkUrl,
  downloadFileName,
  downloadUrl,
  onCancelClick,
  isExpress,

  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  dateInfo,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal">
        <ModalSimpleContent
          doubleActions
          action1Text={i18n('action:cancel')}
          action1={onCancelClick}
          action2Text={i18n('action:amend')}
          buttonVariant2="default"
          isButton2Loading={isRequestPending}
          isButton2Submit
          isButtonDisabled={isRequestPending}
        >
          <ModalHeader title={i18n('action:amend-predelivery')} />

          {downloadFileName || downloadUrl ? (
            <Paragraph title={i18n('order-modal:predelivery-file')}>
              <AnchorLink
                text={downloadFileName}
                linkUrl={downloadUrl}
                download={downloadFileName}
                isNative
              />
            </Paragraph>
          ) : null}

          {linkText || linkUrl ? (
            <Paragraph title={i18n('order-modal:predelivery-link')}>
              <AnchorLink text={linkText} linkUrl={linkUrl} isNewTab isNative />
            </Paragraph>
          ) : null}

          <Textarea
            name="comments"
            label={i18n('action:add-comment-for-media')}
            placeholder={i18n('action:write-your-comment-here')}
            onBlur={handleBlur}
            onChange={handleChange}
            errorText={
              validationMessages.comments && (
                <FormattedFormErrors errors={validationMessages.comments} />
              )
            }
            isTouched={touched.comments}
            isDisabled={isRequestPending}
            value={values.comments}
            isRequired
          />

          {!isExpress ? (
            <>
              <Datepicker
                id="sendToReviewDate"
                name="sendToReviewDate"
                timeOptions={TIME_OPTIONS}
                value={values.sendToReviewDate}
                onChange={handleChange}
                onBlur={handleBlur}
                datepickerLabel={i18n('noun:submission-review-date')}
                datepickerSubtitle={i18n(
                  'description:submission-review-date-brand',
                )}
                modalTitle={i18n('checkout:choose-date-hour')}
                isTouched={touched.sendToReviewDate}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.sendToReviewDate && (
                    <FormattedFormErrors
                      errors={validationMessages.sendToReviewDate}
                    />
                  )
                }
                minDateTime={dateInfo.sendToReviewDate.min}
                maxDateTime={dateInfo.sendToReviewDate.max}
                placeholder={dateInfo.sendToReviewDate.default}
              />
              <Datepicker
                id="validateReviewDate"
                name="validateReviewDate"
                timeOptions={TIME_OPTIONS}
                value={values.validateReviewDate}
                onChange={handleChange}
                onBlur={handleBlur}
                datepickerLabel={i18n('noun:review-date')}
                datepickerSubtitle={i18n('description:review-date-brand')}
                modalTitle={i18n('checkout:choose-date-hour')}
                isTouched={touched.validateReviewDate}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.validateReviewDate && (
                    <FormattedFormErrors
                      errors={validationMessages.validateReviewDate}
                    />
                  )
                }
                minDateTime={dateInfo.validateReviewDate.min}
                maxDateTime={dateInfo.validateReviewDate.max}
                placeholder={dateInfo.validateReviewDate.default}
              />
              <Datepicker
                id="deliveryDate"
                name="deliveryDate"
                timeOptions={TIME_OPTIONS}
                value={values.deliveryDate}
                onChange={handleChange}
                onBlur={handleBlur}
                datepickerLabel={i18n('noun:delivery-date')}
                datepickerSubtitle={i18n('description:delivery-date-brand')}
                modalTitle={i18n('checkout:choose-date-hour')}
                isTouched={touched.deliveryDate}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.deliveryDate && (
                    <FormattedFormErrors
                      errors={validationMessages.deliveryDate}
                    />
                  )
                }
                minDateTime={dateInfo.deliveryDate.min}
                maxDateTime={dateInfo.deliveryDate.max}
                placeholder={dateInfo.deliveryDate.default}
              />
              <Datepicker
                id="approveDate"
                name="approveDate"
                timeOptions={TIME_OPTIONS}
                value={values.approveDate}
                onChange={handleChange}
                onBlur={handleBlur}
                datepickerLabel={i18n('noun:approval-date')}
                datepickerSubtitle={i18n('description:approval-date-brand')}
                modalTitle={i18n('checkout:choose-date-hour')}
                isTouched={touched.approveDate}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.approveDate && (
                    <FormattedFormErrors
                      errors={validationMessages.approveDate}
                    />
                  )
                }
                minDateTime={dateInfo.approveDate.min}
                maxDateTime={dateInfo.approveDate.max}
                placeholder={dateInfo.approveDate.default}
              />
            </>
          ) : null}
          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  comments: PropTypes.string.isRequired,
  sendToReviewDate: PropTypes.instanceOf(moment),
  validateReviewDate: PropTypes.instanceOf(moment),
  deliveryDate: PropTypes.instanceOf(moment),
  approveDate: PropTypes.instanceOf(moment),
}

const dateInfoPropType = PropTypes.shape({
  min: PropTypes.instanceOf(moment),
  max: PropTypes.instanceOf(moment),
  default: PropTypes.instanceOf(moment),
})

OrderModalReviewPredeliveryCorrect.propTypes = {
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  downloadFileName: PropTypes.string,
  downloadUrl: PropTypes.string,
  onCancelClick: PropTypes.func,
  isExpress: PropTypes.bool,
  dateInfo: PropTypes.shape({
    sendToReviewDate: dateInfoPropType.isRequired,
    validateReviewDate: dateInfoPropType.isRequired,
    deliveryDate: dateInfoPropType.isRequired,
    approveDate: dateInfoPropType.isRequired,
  }).isRequired,
  isRequestPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(OrderModalReviewPredeliveryCorrect)
