import React, { useMemo } from 'react'
import { ReactiveComponent } from '@appbaseio/reactivesearch'
import * as R from 'ramda'
import { getFiltersWithout } from './filterUtils'
import CheckboxWrapper from './CheckboxWrapper'

const booleanQueryFactory = (
  dataField,
  matchingTerm,
  negated,
  nestedField = undefined,
) => active => {
  if (!active) {
    return { value: 0 }
  }

  const query = {
    bool: {
      [negated ? 'must_not' : 'must']: {
        term: {
          [dataField]: matchingTerm,
        },
      },
    },
  }

  return nestedField
    ? {
        query: {
          nested: {
            path: nestedField,
            query,
          },
        },
        value: 1,
      }
    : {
        query,
        value: 1,
      }
}

const booleanAggregationQueryFactory = (
  dataField,
  matchingTerm,
  nestedField = undefined,
) => () => {
  const aggs = {
    [dataField]: {
      filters: {
        other_bucket_key: 'not_matching',
        filters: {
          matching: {
            term: {
              [dataField]: matchingTerm,
            },
          },
        },
      },
    },
  }

  return nestedField
    ? {
        size: 0,
        aggs: {
          [dataField]: {
            nested: { path: nestedField },
            aggs,
          },
        },
      }
    : {
        size: 0,
        aggs,
      }
}

const ReactiveCheckbox = ({
  componentId,
  dataField,
  nestedField,
  label,
  matchingTerm,
  negated,
}) => {
  const makeDefaultQuery = useMemo(
    () => booleanAggregationQueryFactory(dataField, matchingTerm, nestedField),
    [dataField, matchingTerm, nestedField],
  )

  const makeQuery = useMemo(
    () => booleanQueryFactory(dataField, matchingTerm, negated, nestedField),
    [dataField, matchingTerm, negated, nestedField],
  )

  return (
    <ReactiveComponent
      componentId={componentId}
      react={{ and: getFiltersWithout(componentId) }}
      URLParams={true}
      defaultQuery={makeDefaultQuery}
      render={props => {
        const matching = R.pathOr(
          undefined,
          nestedField
            ? [
                'aggregations',
                dataField,
                dataField,
                'buckets',
                negated ? 'not_matching' : 'matching',
                'doc_count',
              ]
            : [
                'aggregations',
                dataField,
                'buckets',
                negated ? 'not_matching' : 'matching',
                'doc_count',
              ],
          props,
        )

        return (
          <CheckboxWrapper
            {...props}
            makeQuery={makeQuery}
            label={label}
            isDisabled={matching === 0}
          />
        )
      }}
    />
  )
}

export default ReactiveCheckbox
