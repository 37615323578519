import development from './development'
import testing from './testing'
import preproduction from './preproduction'
import production from './production'

const envs = {
  'coobis-frontend.dev.antevenio.com': development,
  'coobis-frontend.test.antevenio.com': testing,
  'coobis-frontend.pre.antevenio.com': preproduction,
  'suite.coobis.com': production,
}

const host = window.location.host
let env = envs[host]

if (!env) {
  env = development
}

export default env
