import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import withTranslations from '../../../hocs/withTranslations'
import DashboardPanel from '../DashboardPanel'
import NoOrdersCard from '../../cards/NoOrdersCard'
import OrdersCurrentCard from '../../cards/OrdersCurrentCard'

const MediaOrdersPanel = ({
  i18n,
  orders,
  sortValue,
  onSortChange,
  onActionClick,
}) => {
  return (
    <DashboardPanel
      size={!orders ? 'two-thirds' : 'one-whole'}
      autoHeight={!orders ? false : true}
      title={
        orders
          ? i18n('noun:ongoing-orders')
          : i18n('message:has-no-ongoing-orders')
      }
    >
      {orders ? (
        <OrdersCurrentCard
          orders={orders}
          sortValue={sortValue}
          onSortChange={onSortChange}
          onActionClick={onActionClick}
        />
      ) : (
        <NoOrdersCard />
      )}
    </DashboardPanel>
  )
}

MediaOrdersPanel.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.currentOrderRow),
  sortValue: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
}

export default withTranslations(MediaOrdersPanel)
