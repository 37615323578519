import OrderModalReviewOrder from '../../ui/order-modals/OrderModalReviewOrder'
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withStateHandlers,
  branch,
  renderComponent,
} from 'recompose'
import { connect } from 'react-redux'
import { acceptOrderReview } from '../../services/orders'
import withRequestError from '../../hocs/withRequestError'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { ACCEPT_ORDER_REVIEW } from '../../services/orders/action-types'
import withFetchOrderIfNeeded from '../common/withFetchOrderIfNeeded'
import OrderModalReviewOrderAcceptWithChanges from './OrderModalReviewOrderAcceptWithChanges'
import OrderModalReviewOrderReject from './OrderModalReviewOrderReject'

const REVIEW_STEPS = {
  REVIEW: 'REVIEW',
  COUNTERBRIEFING: 'COUNTERBRIEFING',
  REJECT: 'REJECT',
}

const enhance = compose(
  withFetchOrderIfNeeded({ modalTitleI18nKey: 'action:accept-order' }),

  withPropsOnChange(['orderData'], ({ orderData }) => ({
    hasPredelivery: orderData.dates.preDelivery,
    isExpress: orderData.dates.express,

    acceptanceDate: orderData.dates.acceptDate,
    approvalDate: orderData.dates.approveDate,
    deliveryDate: orderData.dates.deliveryDate,
    reviewDeliveryDate: orderData.dates.sendToReviewDate,
    reviewDate: orderData.dates.validateReviewDate,

    briefingText: orderData.briefing.description,
    service: orderData.product.service,
  })),

  withStateHandlers(
    () => ({
      reviewStep: REVIEW_STEPS.REVIEW,
    }),
    {
      handleBack: _ => _ => ({
        reviewStep: REVIEW_STEPS.REVIEW,
      }),
      handleAcceptWithChangesOrderReview: _ => _ => ({
        reviewStep: REVIEW_STEPS.COUNTERBRIEFING,
      }),
      handleRejectOrderReview: _ => _ => ({
        reviewStep: REVIEW_STEPS.REJECT,
      }),
    },
  ),
  branch(
    ({ reviewStep }) => reviewStep === REVIEW_STEPS.COUNTERBRIEFING,
    renderComponent(OrderModalReviewOrderAcceptWithChanges),
  ),
  branch(
    ({ reviewStep }) => reviewStep === REVIEW_STEPS.REJECT,
    renderComponent(OrderModalReviewOrderReject),
  ),

  connect(
    null,
    { acceptOrderReview },
  ),

  withIsRequestPending(ACCEPT_ORDER_REVIEW),
  withRequestError(ACCEPT_ORDER_REVIEW),
  withDeleteRequestOnUnmount(ACCEPT_ORDER_REVIEW),

  withHandlers({
    handleAcceptOrderReview: props => _ => {
      props.acceptOrderReview(props.orderId)
    },
  }),

  withPropsOnChange(['requestError'], ({ requestError }) => ({
    globalMessages:
      !!requestError && !!requestError.globalMessages
        ? requestError.globalMessages
        : undefined,
  })),
)

export default enhance(OrderModalReviewOrder)
