import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import HackFormattedMoney from '../HackFormattedMoney'

const texts = {
  new: 'noun:new',
  sale: 'noun:sale',
  saledetail: 'noun:sale',
}

const CardLabel = ({ i18n, type, price, finalPrice }) => {
  const text = texts[type]
  return (
    <div className={`card-label card-label-${type}`}>
      <div className={`card-label-${type}-text`}>
        {i18n(text)}
        {finalPrice && type === 'saledetail' ? (
          <>
            {' : '}
            <HackFormattedMoney value={finalPrice} />
          </>
        ) : null}
      </div>
      {price && type !== 'new' ? (
        <div className="card-label-price">
          <HackFormattedMoney value={price} />
        </div>
      ) : null}
    </div>
  )
}

CardLabel.defaultProps = {
  type: 'new',
}

CardLabel.propTypes = {
  type: PropTypes.oneOf(['new', 'sale', 'saledetail']),
  price: CoobisPropTypes.money,
  finalPrice: CoobisPropTypes.money,
}

export default withTranslations(CardLabel)
