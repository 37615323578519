import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CardPlaceholder from './CardPlaceholder'
import emptyMessageImage from './images/emptyMessage.svg'

const NoFavsCard = ({ i18n }) => {
  return (
    <div className="no-favs-card">
      <CardPlaceholder>
        <div className="no-favs-card-image">
          <picture>
            <img src={emptyMessageImage} alt="Decoration" />
          </picture>
        </div>
        <div className="no-favs-card-texts">
          <p className="no-favs-card-title">{i18n('message:no-favourites')}</p>
          <p className="no-favs-card-description">
            {i18n('message:no-favourites-added-yet')}
          </p>
        </div>
      </CardPlaceholder>
    </div>
  )
}

NoFavsCard.propTypes = {
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(NoFavsCard)
