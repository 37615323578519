import React from 'react'
import PropTypes from 'prop-types'
import { Input as RdxInput } from '@redradix/components.input'

const InputSuffix = ({
  autoFocus,
  errorText,
  id,
  suffix,
  isTouched,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  widthBehaviour,
  isDisabled,
  label,
  value,
  ...props
}) => {
  return (
    <div className="input-suffix">
      {label && <p className="label">{label}</p>}
      <div className="input-box">
        <RdxInput
          {...props}
          autoFocus={autoFocus}
          errorText={errorText}
          label={false}
          id={id}
          isTouched={isTouched}
          isDisabled={isDisabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          value={value}
          widthBehaviour={widthBehaviour}
        />
        <span className="suffix">{suffix}</span>
      </div>
    </div>
  )
}

InputSuffix.propTypes = {
  /** Boolean that specifies whether an <input> should automatically get focus when the page loads */
  autoFocus: PropTypes.bool,
  /** String that defines the error text of the <input> */
  errorText: PropTypes.any,
  /** String that specifies the helper text of the <input> */
  id: PropTypes.string.isRequired,
  /** Boolean that defines whether the <input> is disabled */
  isTouched: PropTypes.bool,
  /** String with the text of the <label> */
  label: PropTypes.string,
  /** String that specifies the name of an <input> */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Adds a suffix */
  name: PropTypes.string.isRequired,
  /** Function that is triggered when the <input> field loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when the <input> is changed */
  onChange: PropTypes.func,
  /** Function that is triggered when the <input> gets focus */
  onFocus: PropTypes.func,
  /** String that describes the expected value of the <input> */
  placeholder: PropTypes.string,
  /** String or number that specifies the value of the <input> */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** String that defines the behaviour of the input width */
  widthBehaviour: PropTypes.oneOf(['fixed', 'full']),
}

export default InputSuffix
