import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

const FEATURED_RESOURCES_MAX_ITEMS = 3

const ENDPOINTS = {
  fetchCampaigns: `${envConfig.usersBackendUrl}/brand/dashboard/campaigns`,
  fetchOrders: `${envConfig.usersBackendUrl}/brand/dashboard/orders`,
  featuredResources: `${envConfig.searchBackendUrl}/resources/featured?limit=${FEATURED_RESOURCES_MAX_ITEMS}`,
}

export function* fetchCampaigns() {
  const response = yield call(getRequest, ENDPOINTS.fetchCampaigns, {
    parseJSON: true,
  })
  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchOrders() {
  const response = yield call(getRequest, ENDPOINTS.fetchOrders, {
    parseJSON: true,
  })
  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchFeaturedResources() {
  const response = yield call(getRequest, ENDPOINTS.featuredResources, {
    parseJSON: true,
  })
  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}
