import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import HackFormattedMoney from '../../HackFormattedMoney'
import Chip from '@redradix/components.chip'
import CardLabel from '../../../ui/cards/CardLabel'
import { FormattedDate } from 'react-intl'
import Button from '@redradix/components.button'
import { ReactComponent as Cart } from '../../../styles/icons/cart.svg'
import resourceToViewProps from '../../../app/common/resourceToViewProps'
import { useResourceServices } from '../../../app/common/useResourceServices'

const ResourceDetailServiceSocial = ({
  i,
  service,
  item,
  i18n,
  inCartItems,
  addItem,
  currency,
}) => {
  const isCartLoading = false
  const { handleCartClickService } = useResourceServices({
    item,
    addItem,
    i18n,
    service,
  })

  const { price, finalPrice, isInCart } = resourceToViewProps(
    item,
    false,
    service,
    inCartItems,
    currency,
  )
  return (
    <div className="resource-detail-service-list-item" key={i}>
      <div className="resource-detail-service-list-item-header">
        <h4 className="resource-detail-service-list-item-title">
          {service.title}
        </h4>
      </div>
      <div className="resource-detail-service-list-item-description">
        {service.description}
      </div>
      <div className="result-card-actions">
        <div className="result-card-action">
          {item.holidays.endAt ? (
            <p className="card-holidays-indicator">
              <span>{i18n('noun:vacations-until')} </span>
              <span>
                <FormattedDate format="coobis" value={item.holidays.endAt} />
              </span>
            </p>
          ) : (
            <>
              {finalPrice < price ? (
                <CardLabel
                  type="saledetail"
                  price={price}
                  finalPrice={finalPrice}
                />
              ) : (
                <Chip className="chip-basic">
                  <HackFormattedMoney value={finalPrice} />
                </Chip>
              )}

              <Button
                isDisabled={isInCart}
                isLoading={isCartLoading}
                onClick={handleCartClickService}
              >
                <Cart className="icon rdx-button-icon" />
                <span>{i18n(isInCart ? 'action:added' : 'action:add')}</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ResourceDetailServiceSocial.propTypes = {
  item: PropTypes.object,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceDetailServiceSocial)
