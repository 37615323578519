import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import ResultCard from '../cards/ResultCard'
import UserCard from '../cards/UserCard'
import { MediaQueryLap, MediaQueryPalm } from '../shared/MediaQueries'
import withTranslations from '../../hocs/withTranslations'
import resourceToViewProps from '../../app/common/resourceToViewProps'

const AGE_TO_I18N = {
  age18_24: 'age-group:age18_24',
  age25_34: 'age-group:age25_34',
  age35_44: 'age-group:age35_44',
  age45_54: 'age-group:age45_54',
  age55_64: 'age-group:age55_64',
  'age65+': 'age-group:age65+',
}

const Result = ({
  item,
  url,
  services,
  selectedService,
  currency,
  translateCategory,
  onCartClick,
  isCartLoading,
  onServiceChange,
  majorityAgeGroup,
  majorityAgeRate,
  isBroadcastChecked,
  onBroadcastChange,
  i18n,
  handleAddToFavorites,
  handleRemoveFavorite,
  isFavorite,
  inCartItems,
}) => {
  const categories = useMemo(
    () => item.categories.map(item => translateCategory(item.id)).join(' - '),
    [item.categories, translateCategory],
  )

  const {
    image,
    isNew,
    price,
    finalPrice,
    acceptsBroadcast,
    broadcastSocialNetworks,
    holidaysEndDate,
    isInCart,
  } = resourceToViewProps(
    item,
    isBroadcastChecked,
    selectedService,
    inCartItems,
    currency,
  )

  const {
    facebook: facebookUrl,
    twitter: twitterUrl,
    instagram: instagramUrl,
  } = item.socialNetworks || {}

  return (
    <div className="result">
      <MediaQueryPalm>
        <UserCard
          linkUrl={url}
          isNew={isNew}
          name={item.name}
          category={categories}
          image={image}
          type={item.type}
          followers={item.meta_followers}
          engagements={item.statistics.engagements.engagements}
          engagementRate={item.statistics.engagements.rate}
          price={price}
          finalPrice={finalPrice}
          services={services}
          selectedService={selectedService}
          onServiceChange={onServiceChange}
          onCartClick={onCartClick}
          isCartLoading={isCartLoading}
          acceptsBroadcast={acceptsBroadcast}
          broadcastSocialNetworks={broadcastSocialNetworks}
          isBroadcastChecked={isBroadcastChecked}
          onBroadcastChange={onBroadcastChange}
          isUserVerified={item.isVerified}
          isFavourited={isFavorite}
          onFavClick={isFavorite ? handleRemoveFavorite : handleAddToFavorites}
          holidaysEndDate={holidaysEndDate}
          isInCart={isInCart}
        />
      </MediaQueryPalm>
      <MediaQueryLap>
        <ResultCard
          linkUrl={url}
          isNew={isNew}
          name={item.name}
          url={item.url}
          category={categories}
          image={image}
          tooltipContainer=".main"
          type={item.type}
          followers={item.meta_followers}
          engagements={item.statistics.engagements.engagements}
          engagementRate={item.statistics.engagements.rate}
          acceptanceRate={item.acceptanceRate}
          rating={item.rating}
          valuations={item.totalRating}
          price={price}
          finalPrice={finalPrice}
          services={services}
          selectedService={selectedService}
          twitterFollowers={item.statistics.socialNetworks.twitter}
          twitterUrl={twitterUrl}
          facebookFollowers={item.statistics.socialNetworks.facebook}
          facebookUrl={facebookUrl}
          instagramFollowers={item.statistics.socialNetworks.instagram}
          instagramUrl={instagramUrl}
          domAuthority={item.statistics.seoMOZ.domainAuthority}
          pageAuthority={item.statistics.seoMOZ.pageAuthority}
          visitors={item.statistics.googleAnalytics.visitorsPerMonth}
          uniqueVisitors={
            item.statistics.googleAnalytics.uniqueVisitorsPerMonth
          }
          pageViews={item.statistics.googleAnalytics.pageViewsPerMonth}
          male={item.statistics.demographics.male}
          female={item.statistics.demographics.female}
          ageRate={majorityAgeRate}
          ageGroup={i18n(AGE_TO_I18N[majorityAgeGroup])}
          onServiceChange={onServiceChange}
          onCartClick={onCartClick}
          isCartLoading={isCartLoading}
          acceptsBroadcast={acceptsBroadcast}
          broadcastSocialNetworks={broadcastSocialNetworks}
          isFavourited={isFavorite}
          onFavClick={isFavorite ? handleRemoveFavorite : handleAddToFavorites}
          isBroadcastChecked={isBroadcastChecked}
          onBroadcastChange={onBroadcastChange}
          isUserVerified={item.isVerified}
          holidaysEndDate={holidaysEndDate}
          isInCart={isInCart}
        />
      </MediaQueryLap>
    </div>
  )
}

Result.propTypes = {
  item: PropTypes.object,
  services: PropTypes.arrayOf(PropTypes.object),
  selectedService: PropTypes.object,
  currency: CoobisPropTypes.currency,
  translateCategory: PropTypes.func.isRequired,
  onCartClick: PropTypes.func,
  isCartLoading: PropTypes.bool,
  onServiceChange: PropTypes.func,
  majorityAgeGroup: PropTypes.string,
  majorityAgeRate: PropTypes.number,
  isBroadcastChecked: PropTypes.bool,
  isFavorite: PropTypes.bool,
  onBroadcastChange: PropTypes.func,
  inCartItems: PropTypes.array,
}

export default withTranslations(Result)
