import React from 'react'
import PropTypes from 'prop-types'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import * as R from 'ramda'
import Button from '@redradix/components.button'
import { Input } from '@redradix/components.input'
import TabsTitle from '../texts/TabsTitle'
import withTranslations from '../../hocs/withTranslations'
import Textarea from '../form-elements/Textarea'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import { ReactComponent as Hashtag } from '../../styles/icons/hashtag.svg'
import InputFile from '../form-elements/InputFile'
import { MAX_SAMPLE_MESSAGE_CHARACTERS_TWITCH } from '../../services/checkout/business'

const ALLOWED_DOCUMENT_EXTENSIONS = '.jpg,.png,.pdf,.docx'

const BriefingTwitchForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  title,
  downloadFileLink,
}) => {
  return (
    <form className="briefing-form" onSubmit={handleSubmit}>
      <div className="tabs-header">
        <TabsTitle text={title} />
      </div>
      <Textarea
        name="description"
        label={i18n('checkout:post-description')}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('checkout:post-description-long')}
        isTouched={touched.description}
        isDisabled={isRequestPending}
        isRequired
        errorText={
          validationMessages.description && (
            <FormattedFormErrors errors={validationMessages.description} />
          )
        }
      />
      <Input
        type="text"
        name="hashtags"
        label={i18n('checkout:campaign-hashtags')}
        widthBehaviour="full"
        value={values.hashtags}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.hashtags}
        isDisabled={isRequestPending}
        renderLeftIcon={props => <Hashtag {...props} />}
        errorText={
          validationMessages.hashtags && (
            <FormattedFormErrors errors={validationMessages.hashtags} />
          )
        }
      />
      <Input
        type="text"
        name="url"
        label={i18n('checkout:url-streaming-description')}
        placeholder={i18n('checkout:url-streaming-description-example')}
        widthBehaviour="full"
        value={values.url}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.url}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.url && (
            <FormattedFormErrors errors={validationMessages.url} />
          )
        }
      />
      <InputFile
        label={i18n('checkout:images-videos-docs')}
        name="file"
        widthBehaviour="full"
        id="file"
        accept={ALLOWED_DOCUMENT_EXTENSIONS}
        linkText={i18n('action:download-file')}
        linkUrl={downloadFileLink}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('action:upload-document')}
        fileName={R.path(['file', 'name'], values)}
        isUploaded={R.path(['file', 'isUploaded'], values)}
        isTouched={touched.file}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.file && (
            <FormattedFormErrors errors={validationMessages.file} />
          )
        }
      />
      <Textarea
        name="sampleMessage"
        label={i18n('checkout:add-text-chat')}
        value={values.sampleMessage}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('checkout:text-example')}
        isTouched={touched.sampleMessage}
        isDisabled={isRequestPending}
        maxCharacters={MAX_SAMPLE_MESSAGE_CHARACTERS_TWITCH}
        errorText={
          validationMessages.sampleMessage && (
            <FormattedFormErrors errors={validationMessages.sampleMessage} />
          )
        }
      />
      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}
      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  description: PropTypes.string,
  hashtags: PropTypes.string,
  url: PropTypes.string,
  file: PropTypes.any,
  sampleMessage: PropTypes.string,
}

BriefingTwitchForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  downloadFileLink: PropTypes.string,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(BriefingTwitchForm)
