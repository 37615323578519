import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import ActionConfirmationModal from './ActionConfirmationModal'

const ConfirmSubmitModal = ({
  title,
  description,
  submitText,
  cancelText,
  onSubmit,
  onCancel,
  isSmallSized,
  isSubmitInProgress,
}) => (
  <Modal isSmallSized={isSmallSized} onClose={onCancel}>
    <ActionConfirmationModal
      title={title}
      description={description}
      mainActionText={submitText}
      mainAction={onSubmit}
      secondaryActionText={cancelText}
      secondaryAction={onCancel}
      isButton2Loading={isSubmitInProgress}
    />
  </Modal>
)

ConfirmSubmitModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isSmallSized: PropTypes.bool,
  isSubmitInProgress: PropTypes.bool.isRequired,
}

export default ConfirmSubmitModal
