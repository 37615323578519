import React from 'react'
import PropTypes from 'prop-types'
import { withState, withHandlers, compose, withProps } from 'recompose'
import cx from 'classnames'
import SearchFiltersHeader from './SearchFiltersHeader'
import SearchFiltersContent from './SearchFiltersContent'
import SearchResultsHeader from './SearchResultsHeader'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'
import useWindowSize from '../../hooks/useWindowSize'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { FETCH_SEARCH_EXPORT } from '../../services/search/action-types'

const SearchContainer = ({
  children,
  filtersSlot,
  actionsSlot,
  onClearFilters,
  searchExportUrl,
  handleSearchExportClick,
  isLoadingSearchExport,
  results,
  isFiltersOpen,
  mustMountFilters,
  hasMobileFilters,
  toggleFilters,
}) => {
  const filtersClasses = cx('search-filters', { 'is-open': isFiltersOpen })

  useLockBodyScroll(hasMobileFilters && isFiltersOpen)
  const { innerHeight } = useWindowSize()
  const styles = hasMobileFilters ? { height: `${innerHeight}px` } : undefined

  return (
    <div className="search">
      <div className={filtersClasses} style={styles}>
        {mustMountFilters && (
          <>
            <SearchFiltersHeader
              onClear={onClearFilters}
              onClose={toggleFilters}
            />
            <SearchFiltersContent onShow={toggleFilters} results={results}>
              {filtersSlot}
            </SearchFiltersContent>
          </>
        )}
      </div>
      <div className="search-results">
        <SearchResultsHeader
          onShowFilters={toggleFilters}
          results={results}
          searchExportUrl={searchExportUrl}
          handleSearchExportClick={handleSearchExportClick}
          isLoadingSearchExport={isLoadingSearchExport}
        >
          {actionsSlot}
        </SearchResultsHeader>
        <div className="search-results-content">{children}</div>
      </div>
    </div>
  )
}

SearchContainer.propTypes = {
  children: PropTypes.node,
  filtersSlot: PropTypes.node,
  actionsSlot: PropTypes.node,
  onClearFilters: PropTypes.func,
  searchExportUrl: PropTypes.string,
  handleSearchExportClick: PropTypes.func,
  isLoadingSearchExport: PropTypes.bool,
  results: PropTypes.number,
  isFiltersOpen: PropTypes.bool,
}

const decorator = compose(
  withProps(() => ({
    hasMobileFilters: window.matchMedia('(max-width:  43.6875em)').matches,
  })),

  // Only mount filters in mobile once filter side panel has been open at least
  // once. Keep them mounted so ReactiveSearch doesn't lose its internal state.
  withState(
    'mustMountFilters',
    'setMustMountFilters',
    props => !props.hasMobileFilters, // force mounting filters in desktop
  ),

  // Internal state for open status on filter side panel
  withState('isFiltersOpen', 'setOpenFilters', false),

  withIsRequestPending(FETCH_SEARCH_EXPORT, 'isLoadingSearchExport'),

  withHandlers({
    toggleFilters: props => () => {
      props.setOpenFilters(!props.isFiltersOpen)

      // Keep filters mounted
      if (!props.mustMountFilters) {
        props.setMustMountFilters(true)
      }
    },
  }),
)

export default decorator(SearchContainer)
