import {
  compose,
  withHandlers,
  withProps,
  lifecycle,
  withState,
} from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as Yup from 'yup'

import {
  needsScope,
  passwordRecoveryRequest,
  passwordRecoveryRequestWithScope,
  clearNeedsScope,
} from '../../services/password-recovery'
import PasswordRecoveryRequest from '../../ui/PasswordRecoveryRequest'
import {
  REQUEST,
  REQUEST_WITH_SCOPE,
} from '../../services/password-recovery/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { i18nValidation } from '../common/validation'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { AuthScope } from '../../services/auth/business'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { ROUTE_ROOT } from '../common/routes'
import useRecaptcha from '../../hooks/useRecaptcha'

const PasswordRecoverySchema = Yup.object().shape({
  email: Yup.string()
    .email(i18nValidation.invalidMail)
    .required(i18nValidation.required),
})

const handleActionWithRecaptcha = useRecaptcha()

const enhance = compose(
  withRouter,

  withHandlers({
    handleClose: props => _ => {
      props.history.push(
        props.location.state && props.location.state.from
          ? props.location.state.from
          : ROUTE_ROOT.linkTo(),
      )
    },
  }),

  connect(
    state => ({
      needsScope: needsScope(state),
    }),
    {
      handleRecovery: passwordRecoveryRequest,
      handleRecoveryWithScope: passwordRecoveryRequestWithScope,
    },
  ),

  // Save which scope we're currently requesting a reset with
  withState('lastChosenScope', 'setLastChosenScope', undefined),
  withHandlers({
    handleRecoveryWithScope: props => (username, scope) => {
      handleActionWithRecaptcha(
        'passwordResetWithScope',
        (recaptcha, action) => {
          props.setLastChosenScope(scope)
          props.handleRecoveryWithScope(username, scope, recaptcha, action)
        },
      )
    },
  }),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: false,

    mapPropsToValues: () => ({
      email: '',
    }),

    validationSchema: PasswordRecoverySchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      handleActionWithRecaptcha('passwordReset', (recaptcha, action) => {
        props.handleRecovery(values.email, recaptcha, action)
      })
    },
  }),

  withIsRequestPending(REQUEST, 'isRecoveryPending'),
  withIsRequestPending(REQUEST_WITH_SCOPE, 'isRecoveryWithScopePending'),
  // Specific isPending for each scope
  withProps(({ isRecoveryWithScopePending, lastChosenScope }) => ({
    isRecoveryAsMediaPending:
      isRecoveryWithScopePending && lastChosenScope === AuthScope.MEDIA,
    isRecoveryAsBrandPending:
      isRecoveryWithScopePending && lastChosenScope === AuthScope.BRAND,
  })),

  // Handle errors
  // withScope errors take precedence
  withRequestError(REQUEST, 'recoveryRequestError'),
  withRequestError(REQUEST_WITH_SCOPE, 'recoveryWithScopeRequestError'),
  withProps(({ recoveryRequestError, recoveryWithScopeRequestError }) => ({
    requestError: recoveryWithScopeRequestError || recoveryRequestError,
  })),
  withFormErrors(['email']),

  // Success in either request is enough
  withIsRequestSucceeded(REQUEST, 'isRecoverySucceeded'),
  withIsRequestSucceeded(REQUEST_WITH_SCOPE, 'isRecoveryWithScopeSucceeded'),
  withProps(({ isRecoverySucceeded, isRecoveryWithScopeSucceeded }) => ({
    isSucceeded: isRecoverySucceeded || isRecoveryWithScopeSucceeded,
  })),

  // Clear needs scope flag when navigating away
  connect(
    null,
    { clearNeedsScope },
  ),
  lifecycle({
    componentWillUnmount() {
      this.props.clearNeedsScope()
    },
  }),

  // Delete request statuses when unmounting, to clear any state
  withDeleteRequestOnUnmount(REQUEST),
  withDeleteRequestOnUnmount(REQUEST_WITH_SCOPE),
)

export default enhance(PasswordRecoveryRequest)
