import { compose, withHandlers } from 'recompose'
import withBillingDataModal from '../checkout/withBillingDataModal'
import { connect } from 'react-redux'
import {
  getIsBalanceRechargeModalOpen,
  openBalanceRechargeModal,
  closeBalanceRechargeModal,
} from '../../services/wallet'

const withBalanceRecharge = compose(
  connect(
    state => ({
      isBalanceRechargeModalOpen: getIsBalanceRechargeModalOpen(state),
    }),
    { openBalanceRechargeModal, closeBalanceRechargeModal },
  ),

  // After fill billing data open add funds modal
  withBillingDataModal(props => props.openBalanceRechargeModal()),

  // Main handler = open billing modal or add funds modal
  withHandlers({
    handleBalanceRecharge: props => () => {
      const {
        hasBillingData,
        openBillingDataModal,
        openBalanceRechargeModal,
      } = props

      if (!hasBillingData) {
        openBillingDataModal()
        return
      }

      openBalanceRechargeModal()
    },
  }),
)

export default withBalanceRecharge
