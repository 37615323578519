import React from 'react'
import PropTypes from 'prop-types'

const TabsTitle = props => {
  return <p className="tabs-title">{props.text}</p>
}

TabsTitle.propTypes = {
  text: PropTypes.string,
}

export default TabsTitle
