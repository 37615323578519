import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest, postRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

const TOUR_ENDPOINT = `${envConfig.usersBackendUrl}/user/tours`

const tourEndpointByTourName = tourName => `${TOUR_ENDPOINT}/${tourName}`

export function* fetchMyTours() {
  const response = yield call(getRequest, TOUR_ENDPOINT, { parseJSON: true })

  return parseResponseErrors(response)
}

export function* fetchMyToursByName(tourName) {
  const response = yield call(getRequest, tourEndpointByTourName(tourName), {
    parseJSON: true,
  })

  return parseResponseErrors(response)
}

export function* markTourAsViewed(tourName) {
  const response = yield call(
    postRequest,
    tourEndpointByTourName(tourName),
    { viewed: true },
    {
      parseJSON: true,
    },
  )

  return parseResponseErrors(response)
}
