import React, { useMemo, useCallback } from 'react'
import { compose } from 'recompose'
import { useResourceServices } from '../common/useResourceServices'
import * as R from 'ramda'
import FavCard from '../../ui/cards/FavCard'
import withTranslations from '../../hocs/withTranslations'
import { connect } from 'react-redux'
import withFavoriteHandlers from '../common/withFavoriteHandlers'
import { getMyFavorites } from '../../services/favorites/selectors'
import resourceToViewProps from '../common/resourceToViewProps'
import { getCategories } from '../../services/config'
import { getLanguage } from '../../services/me'
import withAddToCart from '../common/withAddToCart'
import {
  getResourceStatus,
  updateResourceStatus,
} from '../../services/resources'
import { getBrandCurrency } from '../../services/brand'

const FavCardEnhanced = ({
  item,
  addItem,
  isAddingItem,
  handleRemoveFavorite,
  categories,
  language,
  inCartItems,
  resourceStatus,
  updateResourceStatus,
  currency,
  i18n,
}) => {
  const {
    services,
    actualSelectedService,
    handleCartClick,
    handleServiceChange,
    isBroadcastChecked,
    handleBroadcastChange,
  } = useResourceServices({
    item,
    addItem,
    i18n,
    resourceStatus,
    updateResourceStatus,
  })

  const translateCategory = useCallback(
    id => R.pathOr(id, [id, 'translations', language], categories),
    [categories, language],
  )

  const resourceTranslatedCategories = useMemo(
    () => item.categories.map(item => translateCategory(item.id)).join(' - '),
    [item.categories, translateCategory],
  )

  // If inbetween loadings, sometimes results aren't updated yet so, even if
  // they should be filtered out, it still tries to render and fails because
  // no service actually matches the filtered-out prices
  if (actualSelectedService === undefined) {
    return null
  }

  const {
    image,
    url,
    isNew,
    price,
    finalPrice,
    holidaysEndDate,
    acceptsBroadcast,
    broadcastSocialNetworks,
    isInCart,
  } = resourceToViewProps(
    item,
    isBroadcastChecked,
    actualSelectedService,
    inCartItems,
    currency,
  )

  return (
    <FavCard
      category={resourceTranslatedCategories}
      channel={item.type}
      isCartLoading={isAddingItem}
      isUserVerified={item.isVerified}
      isFavourited={true}
      isNew={isNew}
      image={image}
      linkUrl={url}
      title={item.name}
      price={price}
      finalPrice={finalPrice}
      services={services}
      selectedService={actualSelectedService}
      onFavClick={handleRemoveFavorite}
      onCartClick={handleCartClick}
      onServiceChange={handleServiceChange}
      holidaysEndDate={holidaysEndDate}
      //
      acceptsBroadcast={acceptsBroadcast}
      isBroadcastChecked={isBroadcastChecked}
      onBroadcastChange={handleBroadcastChange}
      broadcastSocialNetworks={broadcastSocialNetworks}
      isInCart={isInCart}
    />
  )
}

const enhance = compose(
  withTranslations,

  connect(
    (state, { item }) => ({
      favorites: getMyFavorites(state),
      categories: getCategories(state),
      language: getLanguage(state),
      resourceStatus: getResourceStatus(item.id, state),
      currency: getBrandCurrency(state),
    }),
    {
      updateResourceStatus,
    },
  ),

  withAddToCart,

  withFavoriteHandlers({ resourceProp: 'item' }),
)

export default enhance(FavCardEnhanced)
