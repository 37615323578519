import { connect } from 'react-redux'
import { getIsRequestWithIdPending } from '../services/communication/selectors'

const withAreRequestsWithIdsPending = (
  requestType,
  {
    idProp = 'id',
    idPropSelector = data => data,
    outProp = 'areRequestsPending',
  } = {},
) =>
  connect((state, ownProps) => ({
    [outProp]: ownProps[idProp].map(idPropSelector).reduce(
      (obj, id) =>
        Object.assign(obj, {
          [id]: getIsRequestWithIdPending(requestType, id, state),
        }),
      {},
    ),
  }))

export default withAreRequestsWithIdsPending
