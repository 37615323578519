import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import { FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE } from '../../services/campaigns/action-types'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'
import {
  getCampaignOrdersListByType,
  fetchCampaignOrderListByType,
} from '../../services/campaigns'
import withIsLoadingWithId from '../../hocs/withIsLoadingWithId'
import withEffect from '../../hocs/withEffect'
import { isNilOrEmpty } from '../utils'
import withTranslations from '../../hocs/withTranslations'
import {
  prettifyConstant,
  ROUTE_BRAND_CAMPAIGNS_URL_STATS_BY_ID,
} from '../common/routes'

const withURLsSelector = compose(
  withTranslations,
  withRouter,

  withPropsOnChange(
    ['campaignId', 'resourceType'],
    ({ campaignId, resourceType }) => ({
      fetchId: `${campaignId}_${resourceType}`,
    }),
  ),

  withRoute404ErrorWithId(FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE, 'fetchId'),
  withRouteServerErrorWithId(FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE, 'fetchId'),

  connect(
    (state, { campaignId, resourceType }) => {
      return {
        urlsList: getCampaignOrdersListByType(campaignId, resourceType, state),
      }
    },
    {
      fetchCampaignOrderListByType,
    },
  ),

  withPropsOnChange(['urlsList'], ({ urlsList }) => {
    if (isNilOrEmpty(urlsList)) {
      return {}
    }

    return {
      selectorOptions: urlsList.map(url => ({
        label: url.seoUrl,
        value: url.seoUrl,
      })),
    }
  }),

  withPropsOnChange(
    ['match', 'selectorOptions'],
    ({ match, selectorOptions }) => {
      const seoUrl = decodeURIComponent(match.params.seoUrl)

      if (R.isNil(selectorOptions)) {
        return {}
      }

      if (R.isNil(seoUrl)) {
        return {
          selectorValue: selectorOptions[0],
        }
      }

      const selectorValue = selectorOptions.find(
        ({ value }) => value === seoUrl,
      )

      if (R.isNil(selectorValue)) {
        return {
          selectorValue: selectorOptions[0],
        }
      }

      return { selectorValue }
    },
  ),

  // Move to order detail when selecting an item on publications selector
  withHandlers({
    onSelectorChange: ({ history, campaignId, resourceType }) => ({
      value: seoUrl,
    }) => {
      history.push(
        ROUTE_BRAND_CAMPAIGNS_URL_STATS_BY_ID.linkTo({
          campaignId,
          resourceType: prettifyConstant(resourceType),
          seoUrl,
        }),
      )
    },
  }),

  withEffect(
    ({ fetchCampaignOrderListByType, campaignId, resourceType }) => {
      fetchCampaignOrderListByType(campaignId, resourceType)
    },
    ({ fetchCampaignOrderListByType, campaignId, resourceType }) => [
      fetchCampaignOrderListByType,
      campaignId,
      resourceType,
    ],
  ),

  withIsLoadingWithId(FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE, {
    idProp: 'fetchId',
    outProp: 'isLoadingPublicationsSelector',
  }),
)

export default withURLsSelector
