import React, { useState, useEffect, useCallback, useMemo } from 'react'
import * as R from 'ramda'
import RangeWidget from '../../ui/form-elements/RangeWidget'
import { debounce } from '@appbaseio/reactivecore/lib/utils/helper'

const DEBOUNCE_MSECS =
  Number(process.env.REACT_APP_SEARCH_DEBOUNCE_MSECS) || undefined

const RangeSlider = ({
  min,
  max,
  onChange,
  value,
  isPercentage,
  floatPrecision,
  formatOptions,
}) => {
  const [minValue, maxValue] = value || [min, max]

  // Internal values (not debounced)
  const [minInternalValue, setMinInternalValue] = useState(minValue)
  const [maxInternalValue, setMaxInternalValue] = useState(maxValue)

  // Force changing internal value if value prop changes
  useEffect(() => setMinInternalValue(minValue), [
    setMinInternalValue,
    minValue,
  ])

  useEffect(() => setMaxInternalValue(maxValue), [
    setMaxInternalValue,
    maxValue,
  ])

  // Debounced handlers
  const debouncedOnChange = useMemo(
    () =>
      debounce((min, max) => {
        setMinInternalValue(min)
        setMaxInternalValue(max)
        onChange(min, max)
      }, DEBOUNCE_MSECS),
    [onChange, setMinInternalValue, setMaxInternalValue],
  )

  const handleMinChange = useCallback(
    e => {
      const value = Number(e.target.value)
      const transformedValue = value / (isPercentage ? 100 : 1)

      setMinInternalValue(transformedValue)

      if (transformedValue > maxValue) {
        debouncedOnChange(maxValue, maxValue)
      } else if (transformedValue < min) {
        debouncedOnChange(min, maxValue)
      } else if (transformedValue > max) {
        debouncedOnChange(max, maxValue)
      } else {
        debouncedOnChange(transformedValue, maxValue)
      }
    },
    [isPercentage, maxValue, min, max, debouncedOnChange],
  )

  const handleMaxChange = useCallback(
    e => {
      const value = Number(e.target.value)
      const transformedValue = value / (isPercentage ? 100 : 1)

      setMaxInternalValue(transformedValue)

      if (transformedValue < minValue) {
        debouncedOnChange(minValue, minValue)
      } else if (transformedValue < min) {
        debouncedOnChange(minValue, min)
      } else if (transformedValue > max) {
        debouncedOnChange(minValue, max)
      } else {
        debouncedOnChange(minValue, transformedValue)
      }
    },
    [isPercentage, minValue, min, max, debouncedOnChange],
  )

  // Cannot render slider until we have min/max values
  if (R.isNil(min) || R.isNil(max)) {
    return null
  }

  return (
    <>
      <RangeWidget
        max={max * (isPercentage ? 100 : 1)}
        min={min * (isPercentage ? 100 : 1)}
        maxValue={maxInternalValue * (isPercentage ? 100 : 1)}
        minValue={minInternalValue * (isPercentage ? 100 : 1)}
        onSliderChange={({ values }) => {
          if (isPercentage) {
            onChange(...values.map(x => x / 100))
          } else {
            onChange(...values)
          }
        }}
        isDisabled={min === max}
        name="range_slider_name"
        minInputId="min_input_id"
        isMinInputTouched={false}
        onMinInputChange={handleMinChange}
        maxInputId="max_input_id"
        maxInputErrorText=""
        isMaxInputTouched={false}
        onMaxInputChange={handleMaxChange}
        floatPrecision={floatPrecision}
        formatOptions={formatOptions}
      />
    </>
  )
}

export default RangeSlider
