import { compose } from 'redux'
import { lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { periodicFetchWallet } from '../../services/wallet'

// Add wallet and balance for header
const withPeriodicFetchWallet = compose(
  connect(
    null,
    {
      periodicFetchWallet,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.periodicFetchWallet()
    },
  }),
)

export default withPeriodicFetchWallet
