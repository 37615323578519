import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import OrderCurrentHeadRow from './OrderCurrentHeadRow'
import OrderCurrentRow from './OrderCurrentRow'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import useScrollEnds from '../../hooks/useScrollEnds'
import { ROUTE_MEDIA_ORDER_BY_ID } from '../../app/common/routes'

const OrdersCurrentTable = ({
  orders,
  sortValue,
  onSortChange,
  onActionClick,
}) => {
  const { handleScroll, setScrollerRef, scrollEnds } = useScrollEnds({
    top: 20,
    bottom: 20,
  })

  const classes = cx('orders-current-table', {
    'has-top-gradient': !scrollEnds.top,
    'has-bottom-gradient': !scrollEnds.bottom,
  })
  return (
    <div className={classes}>
      <OrderCurrentHeadRow value={sortValue} onChange={onSortChange} />
      <div
        className="orders-current-table-content"
        ref={setScrollerRef}
        onScroll={handleScroll}
      >
        {orders.map(order => (
          <OrderCurrentRow
            key={order.id}
            status={order.status}
            type={order.type}
            brand={order.brandName}
            resource={order.resource.name}
            price={order.price}
            remainDays={order.remainDays}
            isExpress={order.dates.express}
            linkUrl={ROUTE_MEDIA_ORDER_BY_ID.linkTo({ orderId: order.id })}
            onActionClick={action => {
              onActionClick(order.id, action)
            }}
            hasPredelivery={order.dates.preDelivery}
          />
        ))}
      </div>
    </div>
  )
}

OrdersCurrentTable.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.currentOrderRow),
  sortValue: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
}

export default OrdersCurrentTable
