import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@redradix/components.checkbox'
import withTranslations from '../../hocs/withTranslations'
import IconSocial from '../icons/IconSocial'
import { RESOURCE_TYPES } from '../../services/resources/constants'

const CardSocialBroadcast = ({
  i18n,
  name,
  checked,
  errorText,
  id,
  isDisabled,
  isFocused,
  isTouched,
  onBlur,
  onChange,
  onClick,
  socialNetworks,
}) => {
  // Pendiente revisar el tipo del value con la próxima actualización de la librería
  return (
    <div className="card-social-broadcast">
      <Checkbox
        name={name}
        checked={checked}
        errorText={errorText}
        id={id}
        isFocused={isFocused}
        isTouched={isTouched}
        onBlur={onBlur}
        // When disable a checkbox it unchecks
        // isDisabled={isDisabled}
        onChange={isDisabled ? () => {} : onChange}
        onClick={onClick}
      >
        {i18n('action:broadcast')}
        {socialNetworks.map((network, i) => (
          <IconSocial type={network} key={i} />
        ))}
      </Checkbox>
    </div>
  )
}

CardSocialBroadcast.propTypes = {
  /** Boolean indicating whether the checkbox is checked */
  name: PropTypes.string,
  /** String that defines the input error message */
  checked: PropTypes.bool.isRequired,
  /** Property indicating the value of the component */
  errorText: PropTypes.string,
  /** String that defines the input id */
  id: PropTypes.string,
  /** Boolean indicating whether the checkbox should be rendered as disabled */
  isDisabled: PropTypes.bool,
  /** Boolean indicating whether the checkbox is focused */
  isFocused: PropTypes.bool,
  /** Boolean indicating whether the checkbox has been touched (interacted with) by the user */
  isTouched: PropTypes.bool,
  /** String that defines the input name */
  onBlur: PropTypes.func,
  /** Function that is triggered when the input is changing */
  onChange: PropTypes.func,
  /** Function that is triggered when the input is clicked */
  onClick: PropTypes.func,
  socialNetworks: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  ),
}

export default withTranslations(CardSocialBroadcast)
