import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import CampaignDetailPanel from '../CampaignDetailPanel'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import CampaignPreviewFacebook from '../social-previews/CampaignPreviewFacebook'
import PublicationsTableFacebook from '../tables/PublicationsTableFacebook'
import { isNilOrEmpty } from '../../../app/utils'

const CampaignDetailFacebookAll = ({
  previews,
  publicationsTableData,
  i18n,
  // Metrics values
  publications,
  fanpages,
  reach,
  engagements,
  clicks,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        <AnalyticsKpi
          iconType="megaphone"
          value={publications}
          label={i18n('metrics:publications')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="global"
          value={fanpages}
          label={i18n('metrics:fanpages')}
          isTruncated
        />
        {reach > 0 && (
          <AnalyticsKpi
            iconType="target"
            value={reach}
            label={i18n('metrics:reach')}
            isTruncated
          />
        )}
        {engagements > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagements}
            label={i18n('metrics:engagements')}
            isTruncated
          />
        )}
        {clicks > 0 && (
          <AnalyticsKpi
            iconType="click"
            value={clicks}
            label={i18n('metrics:clicks')}
            isTruncated
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewFacebook items={previews} />
      ) : null}
      <CampaignDetailPanel title={i18n('noun:publications-table')}>
        <PublicationsTableFacebook
          publications={publicationsTableData}
        ></PublicationsTableFacebook>
      </CampaignDetailPanel>
    </>
  )
}

CampaignDetailFacebookAll.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  publicationsTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      likes: PropTypes.number,
      engagements: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  publications: PropTypes.number,
  fanpages: PropTypes.number,
  reach: PropTypes.number,
  engagements: PropTypes.number,
  clicks: PropTypes.number,
}

export default withTranslations(CampaignDetailFacebookAll)
