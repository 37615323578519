import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import Textarea from './../form-elements/Textarea'
import ChipBasic from '../../ui/chips/ChipBasic'
import Paragraph from './../texts/Paragraph'
import PreformattedText from '../texts/PreformattedText'
import {
  SERVICE_TYPES,
  mapServiceTypeToBriefingMandatoryFieldI18n,
} from '../../services/orders/business'

const OrderModalReviewOrderAcceptWithChanges = ({
  reviewDeliveryDate,
  deliveryDate,
  hasPredelivery,
  isExpress,

  briefingText,
  service,

  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleBack,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal">
        <ModalSimpleContent
          doubleActions
          buttonHierarchy="tertiary"
          buttonVariant="default"
          action1Text={i18n('action:go-back')}
          buttonHierarchy1="primary"
          buttonVariant1="neutral"
          action1={handleBack}
          action2Text={i18n('action:propose-changes')}
          buttonHierarchy2="secondary"
          buttonVariant2="default"
          isButton2Loading={isRequestPending}
          isButton2Submit
          isButtonDisabled={isRequestPending}
        >
          <ModalHeader title={i18n('action:propose-changes')} />
          <Textarea
            name="comments"
            label={i18n('order-modal:order-changes-proposal')}
            placeholder={i18n('action:write-changes-here')}
            onBlur={handleBlur}
            onChange={handleChange}
            errorText={
              validationMessages.comments && (
                <FormattedFormErrors errors={validationMessages.comments} />
              )
            }
            isTouched={touched.comments}
            isDisabled={isRequestPending}
            value={values.comments}
            isRequired
          />
          {briefingText && (
            <Paragraph
              title={i18n(mapServiceTypeToBriefingMandatoryFieldI18n[service])}
            >
              <PreformattedText text={briefingText} />
            </Paragraph>
          )}
          {hasPredelivery && !isExpress ? (
            <ChipBasic
              title={i18n('noun:submission-review-date')}
              subtitle={i18n('description:submission-review-date-media')}
              text={reviewDeliveryDate}
            />
          ) : null}
          {!isExpress ? (
            <ChipBasic
              title={i18n('noun:delivery-date')}
              subtitle={i18n('description:delivery-date-media')}
              text={deliveryDate}
            />
          ) : null}
          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  comments: PropTypes.string,
}

OrderModalReviewOrderAcceptWithChanges.propTypes = {
  deliveryDate: PropTypes.string,
  reviewDeliveryDate: PropTypes.string,
  hasPredelivery: PropTypes.bool,
  isExpress: PropTypes.bool,

  briefingText: PropTypes.string,
  service: PropTypes.oneOf(Object.values(SERVICE_TYPES)),

  handleBack: PropTypes.func,

  handleAcceptOrderReview: PropTypes.func,
  handleRejectOrderReview: PropTypes.func,

  isAcceptingOrderReview: PropTypes.bool,
  isRejectingOrderReview: PropTypes.bool,

  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(OrderModalReviewOrderAcceptWithChanges)
