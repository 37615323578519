export const SERVICE = 'CHECKOUT'

export const DOMAIN = 'checkout'

export const HASH_KEY_CART = 'cart'

export const HASH_KEY_IS_CREATE_CAMPAIGN_MODAL_OPEN =
  'isCreateCampaignModalOpen'

export const HASH_KEY_IS_BILLING_DATA_MODAL_OPEN = 'isBillingDataModalOpen'

export const HASH_KEY_DATES_CONFIG = 'datesConfig'

export const HASH_KEY_COUPON = 'coupon'

export const REFRESH_CART_TIME = 30000
