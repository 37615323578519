import React from 'react'
import PropTypes from 'prop-types'
import FormattedFormError from './FormattedFormError'
import { i18nErrorMessagePropType } from '../hocs/withFormErrors'

const FormattedFormErrors = ({ errors }) =>
  errors.map((error, i) => (
    <FormattedFormError key={`${error.key}-${i}`} error={error} />
  ))

FormattedFormErrors.propTypes = {
  errors: PropTypes.arrayOf(i18nErrorMessagePropType).isRequired,
}

export default FormattedFormErrors
