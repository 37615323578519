export const SERVICE = 'DASHBOARD'

export const DOMAIN = 'dashboard'

export const HASH_KEY_DASHBOARD_CAMPAIGNS = 'campaigns'

export const HASH_KEY_DASHBOARD_ORDERS = 'orders'

export const HASH_KEY_FEATURED_RESOURCES = 'featuredResources'

export const REFRESH_DASHBOARD_TIME = 30000
