import * as R from 'ramda'
import { i18nValidationKeys, i18nErrorKeys } from '../app/common/validation'
import { formatBytes, isNilOrEmpty } from '../app/utils'
import moment from 'moment'

const parseBackendDate = date => new Date(date)

const parseBackendDateAlternate = ({ date, timezone_type, timezone }) => {
  // timezone_type has an unknown value
  // Asked backend, couldn't tell

  return moment(`${date}${timezone}`).toDate()
}

export const defaultMapValidation = {
  notEmpty: values => ({
    key: i18nValidationKeys.REQUIRED,
    values,
  }),

  minLength: values => ({
    key: i18nValidationKeys.MIN_LENGTH,
    values: {
      ...values,
      min: values.expected,
    },
  }),

  url: values => ({
    key: i18nValidationKeys.INVALID_URL,
    values,
  }),

  lessOrEqualThan: values => {
    if (values.field === 'file') {
      return {
        key: i18nValidationKeys.EXCEED_MAX_FILE_SIZE,
        values: { ...values, max: formatBytes(values.expected, 1) },
      }
    }

    // Value is a date, special case it
    if (values.message.includes('Timestamp')) {
      return {
        key: i18nValidationKeys.MAX_DATE,
        values: { ...values, max: parseBackendDate(values.expected) },
      }
    }

    // Backend now sends a different format for dates. Not sure if replaced
    // or they have two cases. Handle it anyways.
    if (values.expected.date) {
      return {
        key: i18nValidationKeys.MAX_DATE,
        values: {
          ...values,
          max: parseBackendDateAlternate(values.expected),
        },
      }
    }

    // Default case
    return {
      key: i18nValidationKeys.MAX,
      values: { ...values, max: values.expected },
    }
  },

  greaterOrEqualThan: values => {
    // Value is a date, special case it
    if (values.message.includes('Timestamp')) {
      return {
        key: i18nValidationKeys.MIN_DATE,
        values: { ...values, min: parseBackendDate(values.expected) },
      }
    }

    // Backend now sends a different format for dates. Not sure if replaced
    // or they have two cases. Handle it anyways.
    if (values.expected.date) {
      return {
        key: i18nValidationKeys.MIN_DATE,
        values: {
          ...values,
          min: parseBackendDateAlternate(values.expected),
        },
      }
    }

    // Default case
    return {
      key: i18nValidationKeys.MIN,
      values: { ...values, min: values.expected },
    }
  },

  lessThan: values => {
    // Value is a date, special case it
    if (values.message.includes('Timestamp')) {
      return {
        key: i18nValidationKeys.MAX_DATE,
        values: { ...values, max: parseBackendDate(values.expected) },
      }
    }

    // Backend now sends a different format for dates. Not sure if replaced
    // or they have two cases. Handle it anyways.
    if (values.expected.date) {
      return {
        key: i18nValidationKeys.MAX_DATE,
        values: {
          ...values,
          max: parseBackendDateAlternate(values.expected),
        },
      }
    }

    // Default case
    return {
      key: i18nValidationKeys.MAX,
      values: { ...values, max: values.expected },
    }
  },

  greaterThan: values => {
    // Value is a date, special case it
    if (values.message.includes('Timestamp')) {
      return {
        key: i18nValidationKeys.MIN_DATE,
        values: { ...values, min: parseBackendDate(values.expected) },
      }
    }

    // Backend now sends a different format for dates. Not sure if replaced
    // or they have two cases. Handle it anyways.
    if (values.expected.date) {
      return {
        key: i18nValidationKeys.MIN_DATE,
        values: {
          ...values,
          min: parseBackendDateAlternate(values.expected),
        },
      }
    }

    // Default case
    return {
      key: i18nValidationKeys.MIN,
      values: { ...values, min: values.expected },
    }
  },

  false: values => ({
    key: i18nValidationKeys.INVALID_VALUE,
    values,
  }),

  choice: values => {
    if (values.field === 'file') {
      return {
        key: i18nValidationKeys.INVALID_FILE_EXTENSION,
        values: { ...values, allowedFormats: values.expected.join(', ') },
      }
    }

    return {
      key: i18nValidationKeys.INVALID_VALUE,
      values,
    }
  },

  isInstanceOf: values => ({
    key: i18nValidationKeys.UNKNOWN,
    values,
  }),
}

export const defaultMapGlobal = {}

const mapUnknown = R.curry((key, values) => ({
  key: `errors:unknown-${key}`,
  values,
}))

export const parseErrorMessages = R.curry(
  (mapToI18n, errorMessages, mapUnknownKeys = true) =>
    Object.entries(errorMessages)
      .map(([key, values]) =>
        (mapToI18n[key] || (mapUnknownKeys ? mapUnknown(key) : () => null))(
          values,
        ),
      )
      .filter(m => m),
)

const backendPathToRamdaPath = R.pipe(
  R.replace(/([^[]+)/, '[$1]'),
  s => {
    const result = []

    let match
    while ((match = s.match(/^\[(.*?)\]/m)) !== null) {
      result.push(match[1])
      s = s.slice(match[0].length)
    }

    return result
  },
)

const addFieldToErrorMessages = (path, errorMessages) => {
  const keys = Object.keys(errorMessages)
  errorMessages[keys[keys.length - 1]].field = path
  return errorMessages
}

export const parseValidationMessages = (mapToI18n, validationMessages) =>
  R.pipe(
    R.toPairs,
    R.map(([path, errorMessages]) => {
      const ramdaPath = backendPathToRamdaPath(path)
      errorMessages = addFieldToErrorMessages(path, errorMessages)
      return [
        ramdaPath,
        parseErrorMessages(mapToI18n, errorMessages).map(errorMessage => ({
          ...errorMessage,
          values: {
            ...(errorMessage.values || {}),
            path,
            field: ramdaPath[ramdaPath.length - 1],
          },
        })),
      ]
    }),
    R.reduce(
      (acc, [path, errorMessages]) => R.assocPath(path, errorMessages, acc),
      {},
    ),
  )(validationMessages)

const MAP_GLOBAL_STATUS = {
  404: () => ({ key: i18nErrorKeys.NOT_FOUND, values: {} }),
  network: () => ({ key: i18nErrorKeys.UNKNOWN, values: {} }),
}

export const buildStatusErrorMessages = (response, mapStatus = {}) => {
  if (!response.status && !response.type) return undefined

  const messages = parseErrorMessages(
    { ...MAP_GLOBAL_STATUS, ...mapStatus },
    { [response.status || response.type]: {} },
    false,
  )

  if (messages.length) return messages

  return [{ key: i18nErrorKeys.UNKNOWN, values: {} }]
}

export const parseResponseErrors = (
  response,
  { mapValidation = {}, mapGlobal = {}, mapStatus = {} } = {},
) => {
  const validationMessages =
    response.errorData && response.errorData.validation_messages
      ? parseValidationMessages(
          { ...defaultMapValidation, ...mapValidation },
          response.errorData.validation_messages,
        )
      : undefined

  const globalMessages =
    response.errorData && response.errorData.global_messages
      ? parseErrorMessages(
          { ...defaultMapGlobal, ...mapGlobal },
          response.errorData.global_messages,
        )
      : undefined

  return {
    ...response,
    validationMessages,
    globalMessages:
      // Only show failover status messages if no other error is shown
      !isNilOrEmpty(globalMessages) || !isNilOrEmpty(validationMessages)
        ? globalMessages
        : buildStatusErrorMessages(response, mapStatus),
  }
}
