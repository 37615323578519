import { compose, branch, renderComponent, renderNothing } from 'recompose'
import { STATISTICS_TABS } from '../../services/campaigns/business'
import CampaignDetailGlobal from './CampaignDetailGlobal'
import CampaignDetailFacebookAll from './CampaignDetailFacebookAll'
import CampaignDetailTwitterAll from './CampaignDetailTwitterAll'
import CampaignDetailInstagramAll from './CampaignDetailInstagramAll'
import CampaignDetailWebBrandingAll from './CampaignDetailWebBrandingAll'
import CampaignDetailYoutubeAll from './CampaignDetailYoutubeAll'
import CampaignDetailTwitchAll from './CampaignDetailTwitchAll'
import CampaignDetailTiktokAll from './CampaignDetailTiktokAll'

const enhance = compose(
  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.GLOBAL,
    renderComponent(CampaignDetailGlobal),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.WEB_BRANDING,
    renderComponent(CampaignDetailWebBrandingAll),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.FACEBOOK,
    renderComponent(CampaignDetailFacebookAll),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.TWITTER,
    renderComponent(CampaignDetailTwitterAll),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.INSTAGRAM,
    renderComponent(CampaignDetailInstagramAll),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.YOUTUBE,
    renderComponent(CampaignDetailYoutubeAll),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.TWITCH,
    renderComponent(CampaignDetailTwitchAll),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.TIKTOK,
    renderComponent(CampaignDetailTiktokAll),
  ),
)

export default enhance(renderNothing)
