import { compose, withPropsOnChange } from 'recompose'
import * as Yup from 'yup'
import * as R from 'ramda'
import { connect } from 'react-redux'
import AccountDeleteForm from '../../ui/profile/AccountDeleteForm'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation } from '../common/validation'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { DELETE_USER } from '../../services/auth/action-types'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import { deleteUser } from '../../services/auth'
import withTranslations from '../../hocs/withTranslations'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const validationSchema = Yup.object().shape({
  reason: Yup.string().required(i18nValidation.required),
  text: Yup.string(),
})

const REASON_OPTION_OTHER = 'delete-reason:other'

const enhance = compose(
  withTranslations,

  connect(
    null,
    { deleteUser },
  ),

  withPropsOnChange(['reasonOptions'], ({ reasonOptions }) => ({
    reasonOptions: [...reasonOptions, REASON_OPTION_OTHER],
  })),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: false,

    mapPropsToValues: () => ({
      reason: '',
      text: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.deleteUser(
        props.scope,
        values.reason !== REASON_OPTION_OTHER ? values.reason : values.text,
      )
    },
  }),

  withIsRequestPending(DELETE_USER),
  withIsRequestSucceeded(DELETE_USER),
  withRequestError(DELETE_USER),
  withDeleteRequestOnUnmount(DELETE_USER),
  withFormErrors(['reason', 'text']),

  withPropsOnChange(['values', 'i18n'], ({ values, i18n }) => ({
    isTextareaOpen: values.reason === i18n(REASON_OPTION_OTHER),
  })),

  withPropsOnChange(
    ['i18n', 'reasonOptions', 'values'],
    ({ i18n, reasonOptions, values }) => ({
      reasonOptions: R.map(
        R.pipe(
          i18n,
          reason => ({
            label: reason,
            value: reason,
            isChecked: values.reason === reason,
          }),
        ),
        reasonOptions,
      ),
    }),
  ),
)

export default enhance(AccountDeleteForm)
