import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { FormattedDate } from 'react-intl'
import Card from './Card'
import affiliatesImg from './images/affiliates.svg'

const AffiliatesCard = ({ affiliates, i18n }) => {
  return (
    <Card isHighlighted>
      <div className="affiliates-card">
        <div className="affiliates-card-header">
          <div className="affiliates-card-image">
            <img src={affiliatesImg} alt="Decoration" />
          </div>
          <h3 className="affiliates-card-title">
            {i18n('noun:your-affiliates')}
          </h3>
          <p className="affiliates-card-subtitle">
            {i18n('affiliates:keep-sharing-link')}
          </p>
        </div>
        <div className="affiliates-card-contents">
          <div className="affiliates-card-list">
            <div className="affiliates-card-list-header">
              <p className="affiliates-card-list-column affiliates-card-list-item-type">
                {i18n('noun:type')}
              </p>
              <p className="affiliates-card-list-column affiliates-card-list-item-name">
                {i18n('label:name')}
              </p>
              <p className="affiliates-card-list-column affiliates-card-list-item-date">
                {i18n('noun:date')}
              </p>
            </div>
            {affiliates.map((affiliate, i) => (
              <div className="affiliates-card-list-item" key={i}>
                <p className="affiliates-card-list-column affiliates-card-list-item-type">
                  {affiliate.referenceType === 'brand'
                    ? `${i18n('noun:brand')}`
                    : `${i18n('noun:media')}`}
                </p>
                <p className="affiliates-card-list-column affiliates-card-list-item-name">
                  {affiliate.name} {affiliate.surname}
                </p>
                <p className="affiliates-card-list-column affiliates-card-list-item-date">
                  <FormattedDate format="coobis" value={affiliate.createdAt} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  )
}

AffiliatesCard.propTypes = {
  affiliates: PropTypes.arrayOf(
    PropTypes.shape({
      referenceType: PropTypes.oneOf(['media', 'brand']),
      name: PropTypes.string,
      surname: PropTypes.string,
      createdAt: PropTypes.instanceOf(Date).isRequired,
    }),
  ),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(AffiliatesCard)
