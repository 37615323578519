import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import * as R from 'ramda'
import InstagramServicesPrices from '../../ui/social-resource/InstagramServicesPrices'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation } from '../common/validation'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withFormErrors from '../../hocs/withFormErrors'
import { editSocialResourceServicesPrices } from '../../services/resources'
import { EDIT_SOCIAL_RESOURCE_SERVICES_PRICES } from '../../services/resources/action-types'
import { floatToMoney } from '../utils'
import { SERVICE_TYPES } from '../../services/orders/business'
import { calculateTotalPrice } from './util'

const validationSchema = Yup.object().shape({
  photoEnabled: Yup.boolean().required(i18nValidation.required),
  photoBasePrice: Yup.number().when('photoEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min),
  }),
  photoDiscount: Yup.number().when('photoEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min)
      .max(100, i18nValidation.max),
  }),

  videoEnabled: Yup.boolean().required(i18nValidation.required),
  videoBasePrice: Yup.number().when('videoEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min),
  }),
  videoDiscount: Yup.number().when('videoEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min)
      .max(100, i18nValidation.max),
  }),

  storyEnabled: Yup.boolean().required(i18nValidation.required),
  storyBasePrice: Yup.number().when('storyEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min),
  }),
  storyDiscount: Yup.number().when('storyEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min)
      .max(100, i18nValidation.max),
  }),
})

const hasPhotoTotalPriceError = ({ photoTotalPrice = 0 }, touched) =>
  photoTotalPrice < 10 && (touched.photoBasePrice || touched.photoDiscount)

const hasVideoTotalPriceError = ({ videoTotalPrice = 0 }, touched) =>
  videoTotalPrice < 10 && (touched.videoBasePrice || touched.videoDiscount)

const hasStoryTotalPriceError = ({ storyTotalPrice = 0 }, touched) =>
  storyTotalPrice < 10 && (touched.storyBasePrice || touched.storyDiscount)

const calculatePrices = values => ({
  photoTotalPrice: calculateTotalPrice(
    values.photoBasePrice,
    values.photoDiscount,
  ),
  videoTotalPrice: calculateTotalPrice(
    values.videoBasePrice,
    values.videoDiscount,
  ),
  storyTotalPrice: calculateTotalPrice(
    values.storyBasePrice,
    values.storyDiscount,
  ),
})

const hasCalculatedPricesErrors = values => {
  const calculatedValues = calculatePrices(values)
  const { photoEnabled, videoEnabled, storyEnabled } = values

  const touched = {
    photoBasePrice: true,
    photoDiscount: true,
    videoBasePrice: true,
    videoDiscount: true,
    storyBasePrice: true,
    storyDiscount: true,
  }

  return (
    (photoEnabled && hasPhotoTotalPriceError(calculatedValues, touched)) ||
    (videoEnabled && hasVideoTotalPriceError(calculatedValues, touched)) ||
    (storyEnabled && hasStoryTotalPriceError(calculatedValues, touched))
  )
}

const enhance = compose(
  connect(
    null,
    {
      editSocialResourceServicesPrices,
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ resourceData }) => ({
      photoBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.INSTAGRAM_PHOTO, 'basePrice'],
        resourceData,
      ),
      photoDiscount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.INSTAGRAM_PHOTO, 'discount'],
        resourceData,
      ),
      photoEnabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.INSTAGRAM_PHOTO, 'enabled'],
            resourceData,
          ),
      photoCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.INSTAGRAM_PHOTO, 'currency'],
        resourceData,
      ),
      videoBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.INSTAGRAM_VIDEO, 'basePrice'],
        resourceData,
      ),
      videoDiscount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.INSTAGRAM_VIDEO, 'discount'],
        resourceData,
      ),
      videoEnabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.INSTAGRAM_VIDEO, 'enabled'],
            resourceData,
          ),
      videoCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.INSTAGRAM_VIDEO, 'currency'],
        resourceData,
      ),
      storyBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.INSTAGRAM_STORY, 'basePrice'],
        resourceData,
      ),
      storyDiscount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.INSTAGRAM_STORY, 'discount'],
        resourceData,
      ),
      storyEnabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.INSTAGRAM_STORY, 'enabled'],
            resourceData,
          ),
      storyCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.INSTAGRAM_STORY, 'currency'],
        resourceData,
      ),
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      if (hasCalculatedPricesErrors(values)) {
        return
      }

      const { photoEnabled, videoEnabled, storyEnabled } = values

      const valuesToSend = [
        photoEnabled
          ? {
              serviceType: SERVICE_TYPES.INSTAGRAM_PHOTO,
              enabled: true,
              basePrice: floatToMoney(
                values.photoBasePrice,
                values.photoCurrency,
              ),
              discount: values.photoDiscount,
            }
          : { serviceType: SERVICE_TYPES.INSTAGRAM_PHOTO, enabled: false },
        videoEnabled
          ? {
              serviceType: SERVICE_TYPES.INSTAGRAM_VIDEO,
              enabled: true,
              basePrice: floatToMoney(
                values.videoBasePrice,
                values.videoCurrency,
              ),
              discount: values.videoDiscount,
            }
          : { serviceType: SERVICE_TYPES.INSTAGRAM_VIDEO, enabled: false },
        storyEnabled
          ? {
              serviceType: SERVICE_TYPES.INSTAGRAM_STORY,
              enabled: true,
              basePrice: floatToMoney(
                values.storyBasePrice,
                values.storyCurrency,
              ),
              discount: values.storyDiscount,
            }
          : {
              serviceType: SERVICE_TYPES.INSTAGRAM_STORY,
              enabled: false,
            },
      ]

      props.editSocialResourceServicesPrices(
        props.resourceId,
        props.channel,
        valuesToSend,
      )
    },
  }),

  withIsRequestPending(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withIsRequestSucceeded(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withRequestError(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withDeleteRequestOnUnmount(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),

  withFormErrors([
    'photoBasePrice',
    'photoDiscount',
    'photoEnabled',
    'videoBasePrice',
    'videoDiscount',
    'videoEnabled',
    'storyBasePrice',
    'storyDiscount',
    'storyEnabled',
  ]),

  // Change final prices when changes offer
  withPropsOnChange(['values'], ({ values }) => ({
    calculatedValues: calculatePrices(values),
  })),

  // Inject calculatedPrices errors
  withPropsOnChange(
    ['calculatedValues', 'validationMessages', 'touched', 'values'],
    ({ calculatedValues, validationMessages, touched, values }) => {
      const { photoEnabled, videoEnabled, storyEnabled } = values

      const showPhotoTotalPriceError = hasPhotoTotalPriceError(
        calculatedValues,
        touched,
      )

      const showVideoTotalPriceError = hasVideoTotalPriceError(
        calculatedValues,
        touched,
      )

      const showStoryTotalPriceError = hasStoryTotalPriceError(
        calculatedValues,
        touched,
      )

      return {
        validationMessages: {
          ...validationMessages,
          photoTotalPrice:
            showPhotoTotalPriceError && photoEnabled
              ? [i18nValidation.min({ path: 'photoTotalPrice', min: 10 })]
              : undefined,
          videoTotalPrice:
            showVideoTotalPriceError && videoEnabled
              ? [i18nValidation.min({ path: 'videoTotalPrice', min: 10 })]
              : undefined,
          storyTotalPrice:
            showStoryTotalPriceError && storyEnabled
              ? [i18nValidation.min({ path: 'storyTotalPrice', min: 10 })]
              : undefined,
        },
      }
    },
  ),
)

export default enhance(InstagramServicesPrices)
