import React from 'react'
import PropTypes from 'prop-types'

const FilterGroupColumn = ({ children }) => (
  <div className="filter-group-column-wrapper">{children}</div>
)

FilterGroupColumn.propTypes = {
  children: PropTypes.any,
}

export default FilterGroupColumn
