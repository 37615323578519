import * as actionTypes from './action-types'

export const fetchMyFavorites = () => ({
  type: actionTypes.FETCH_MY_FAVORITES,
})

export const addToFavorites = resource => ({
  type: actionTypes.ADD_TO_FAVORITES,
  payload: { resource },
})

export const removeFavorite = resourceId => ({
  type: actionTypes.REMOVE_FAVORITE,
  payload: { resourceId },
})
