import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as UserFallback } from '../../styles/icons/user.svg'
import IconSocial from '../icons/IconSocial'
import { RESOURCE_TYPES } from '../../services/resources/constants'

const Avatar = ({ image, channel, avatarName, iconBorder, url }) => {
  const [hasError, setHasError] = useState(false)

  const lastImage = useRef()

  useEffect(() => {
    if (image !== lastImage.current) {
      setHasError(false)
      lastImage.current = image
    }
  }, [image, setHasError, lastImage])

  return (
    <div className="avatar">
      <div className="avatar-wrapper">
        {!!image && !hasError ? (
          <img
            onError={() => {
              setHasError(true)
            }}
            src={image}
            alt={avatarName ? avatarName : 'Avatar Image'}
          />
        ) : (
          <UserFallback />
        )}
      </div>
      {channel ? (
        <div className="avatar-social">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <IconSocial type={channel} border={iconBorder} />
          </a>
        </div>
      ) : null}
    </div>
  )
}

Avatar.defaultProps = {
  iconBorder: false,
}

Avatar.propTypes = {
  image: PropTypes.string,
  channel: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  avatarName: PropTypes.string,
  iconBorder: PropTypes.bool,
}

export default Avatar
