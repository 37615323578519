import { withPropsOnChange } from 'recompose'
import * as R from 'ramda'

const withPublicationsTable = (rowFn, dependentPropNames = []) =>
  withPropsOnChange(
    ['stats', 'campaignId', ...dependentPropNames],
    ({ stats, campaignId, ...dependentProps }) => ({
      publicationsTableData: stats.orderStatsDetail.map(orderStatsDetail => ({
        id: orderStatsDetail.orderId,
        campaignId,
        name: orderStatsDetail.resource.name,
        date: new Date(orderStatsDetail.deliveryDate),
        price: orderStatsDetail.price,
        ...rowFn(orderStatsDetail, {
          stats,
          campaignId,
          ...R.pick(dependentPropNames, dependentProps),
        }),
      })),
    }),
  )

export default withPublicationsTable
