export const SERVICE = 'ORDERS'

export const DOMAIN = 'orders'

export const HASH_KEY_MEDIA_ORDERS_IN_PROGRESS_IDS = 'mediaOrdersInProgressIds'

export const HASH_KEY_MEDIA_ORDERS_FINISHED_IDS = 'mediaOrdersFinishedIds'

export const HASH_KEY_BRAND_ORDERS_IN_PROGRESS_IDS = 'brandOrdersInProgressIds'

export const HASH_KEY_ORDER_CANCEL_MODAL_ID = 'orderCancelModalId'

export const HASH_KEY_ORDER_REVIEW_COUNTERBRIEFING_MODAL_ID =
  'orderReviewCounterbriefingModalId'

export const HASH_KEY_ORDER_REVIEW_PREDELIVERY_MODAL_ID =
  'orderReviewPredeliveryModalId'

export const HASH_KEY_ORDER_REVIEW_DELIVERY_MODAL_ID =
  'orderReviewDeliveryModalId'

export const HASH_KEY_ORDER_REVIEW_MODAL_ID = 'orderReviewModalId'

export const HASH_KEY_ORDER_SEND_PREDELIVERY_MODAL_STATUS =
  'orderSendPredeliveryModalStatus'

export const HASH_KEY_ORDER_SEND_DELIVERY_MODAL_STATUS =
  'orderSendDeliveryModalStatus'

export const ENTITY_KEY_MEDIA_ORDERS_FINISHED = 'mediaOrdersFinished'

export const ENTITY_KEY_MEDIA_ORDERS_IN_PROGRESS = 'mediaOrdersInProgress'

export const ENTITY_KEY_MEDIA_ORDER_DETAILS = 'mediaOrderDetail'

export const ENTITY_KEY_BRAND_ORDERS_IN_PROGRESS = 'brandOrdersInProgress'

export const ENTITY_KEY_BRAND_ORDER_DETAILS = 'brandOrderDetail'

export const ENTITY_KEY_ORDER_HISTORY = 'orderHistory'

export const ENTITY_KEY_CURRENT_CAMPAIGN_ORDERS = 'currentCampaignOrders'

export const ENTITY_KEY_CANCELLED_CAMPAIGN_ORDERS = 'cancelledCampaignOrders'
