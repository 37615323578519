import * as actionTypes from './action-types'

export const fetchMyTours = () => ({
  type: actionTypes.FETCH_MY_TOURS,
})

export const markTourAsViewed = tourName => ({
  type: actionTypes.MARK_TOUR_AS_VIEWED,
  payload: { tourName },
})
