import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import CheckoutHeader from '../../ui/checkout/CheckoutHeader'

const Checkout = ({
  title,
  price,
  currentNavItem,
  reachedNavItem,
  hasContentTabs,
  children,
}) => {
  const contentClasses = cx('steps-layout-content', {
    'has-tabs': hasContentTabs,
  })
  return (
    <div className="steps-layout">
      <CheckoutHeader
        price={price}
        currentNavItem={currentNavItem}
        reachedNavItem={reachedNavItem}
      />
      <div className={contentClasses}>
        {title && (
          <h1 className="step-title">
            {currentNavItem && <span className="step">{currentNavItem}</span>}
            <span className="text">{title}</span>
          </h1>
        )}
        {children}
      </div>
    </div>
  )
}

Checkout.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  currentNavItem: PropTypes.number,
  reachedNavItem: PropTypes.number,
  hasContentTabs: PropTypes.bool,
  children: PropTypes.node,
}

export default Checkout
