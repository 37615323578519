import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import InputFileWithSubtitle from './../form-elements/InputFileWithSubtitle'
import Textarea from './../form-elements/Textarea'
import * as R from 'ramda'

const ALLOWED_IMAGE_EXTENSIONS =
  '.png,.jpg,.jpeg,.img,.gif,.pdf,.doc,.docx,.txt,.mpeg,.avi,.mp4,.flv,.mov'

const OrderModalSendDeliveryInstagramStory = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  dateInfo,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,

  ...props
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal">
        <ModalSimpleContent
          actionText={i18n('action:deliver')}
          isButtonLoading={isRequestPending}
        >
          <ModalHeader title={i18n('action:deliver-story')} />
          <InputFileWithSubtitle
            inputSubtitle={i18n('order-modal:publish-instagram-story')}
            inputLabel={i18n('order-modal:order-deliver-add-story-image')}
            name="storyFile"
            accept={ALLOWED_IMAGE_EXTENSIONS}
            widthBehaviour="full"
            linkText={i18n('action:download-file')}
            linkUrl=""
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={i18n('order-modal:story-url-example')}
            fileName={R.path(['storyFile', 'name'], values)}
            isUploaded={R.path(['storyFile', 'isUploaded'], values)}
            isTouched={touched.storyFile}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.storyFile && (
                <FormattedFormErrors errors={validationMessages.storyFile} />
              )
            }
            isRequired
          />
          <Textarea
            name="comments"
            label={i18n('action:add-comment-for-brand')}
            placeholder={i18n('action:write-your-comment-here')}
            onBlur={handleBlur}
            onChange={handleChange}
            errorText={
              validationMessages.comments && (
                <FormattedFormErrors errors={validationMessages.comments} />
              )
            }
            isTouched={touched.comments}
            isDisabled={isRequestPending}
            value={values.comments}
          />
          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  storyFile: PropTypes.any,
  comments: PropTypes.string.isRequired,
}

OrderModalSendDeliveryInstagramStory.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,

  isRequestPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(OrderModalSendDeliveryInstagramStory)
