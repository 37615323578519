import { compose } from 'recompose'
import * as Yup from 'yup'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import {
  BRIEFING_UPLOAD_CONDITIONS,
  BRIEFING_UPLOAD_CONDITIONS_REDUCED,
  briefingRequestType,
  BRIEFINGS_MAX_FILE_SIZE,
  MAX_SAMPLE_MESSAGE_CHARACTERS_INSTAGRAM,
} from '../../services/checkout/business'
import { i18nValidation, validateMaxFileSize } from '../common/validation'
import { prepareMultiValueInputToSave } from '../utils'
import withConditionOptions from './withConditionOptions'
import withPreviewModal from './withPreviewModal'
import withDownloadBriefingFileLink from './withDownloadBriefingFileLink'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withValuesCache from './withValuesCache'

const validationSchema = Yup.object().shape({
  description: Yup.string().required(i18nValidation.required),
  hashtags: Yup.string(),
  userMentions: Yup.string(),
  conditionId: Yup.string()
    .oneOf(BRIEFING_UPLOAD_CONDITIONS_REDUCED, i18nValidation.required)
    .required(i18nValidation.required),
  sampleMessage: Yup.string().max(
    MAX_SAMPLE_MESSAGE_CHARACTERS_INSTAGRAM,
    i18nValidation.maxLength,
  ),
  file: Yup.mixed()
    .nullable()
    .when('conditionId', {
      is: BRIEFING_UPLOAD_CONDITIONS.BRAND,
      then: Yup.mixed()
        .nullable()
        .required(i18nValidation.required),
    })
    .test(validateMaxFileSize(BRIEFINGS_MAX_FILE_SIZE)),
  isAdvertising: Yup.boolean().required(i18nValidation.required),
})

const withInstagramPostForm = serviceType =>
  compose(
    withEnhancedFormik({
      isInitialValid: true,

      enableReinitialize: true,

      mapPropsToValues: ({ cart: { briefings }, cachedValues }) => {
        const actualData = briefings[serviceType]
        const actualHashtags =
          actualData && actualData.hashtags
            ? actualData.hashtags
                .filter(Boolean)
                .map(v => '#' + v)
                .join(' ')
            : ''
        const actualMentions =
          actualData && actualData.userMentions
            ? actualData.userMentions
                .filter(Boolean)
                .map(v => '@' + v)
                .join(' ')
            : ''
        const actualFile =
          actualData && Array.isArray(actualData.files)
            ? actualData.files[0]
            : null

        return {
          description:
            actualData && actualData.description ? actualData.description : '',
          hashtags: actualHashtags,
          userMentions: actualMentions,
          conditionId:
            actualData && actualData.conditionId
              ? actualData.conditionId
              : BRIEFING_UPLOAD_CONDITIONS.MEDIA,
          sampleMessage:
            actualData && actualData.sampleMessage
              ? actualData.sampleMessage
              : '',
          file: actualFile,
          isAdvertising:
            actualData && actualData.isAdvertising
              ? actualData.isAdvertising
              : false,
          ...cachedValues,
        }
      },

      validationSchema,

      fileFields: ['file'],
      selectFields: ['conditionId'],

      validateOnBlur: true,
      validateOnChange: true,

      handleSubmit: (values, { props }) => {
        const valuesToSend = {
          ...values,
          hashtags: prepareMultiValueInputToSave(values.hashtags, '#'),
          userMentions: prepareMultiValueInputToSave(values.userMentions, '@'),
        }

        props.submitBriefing(valuesToSend)
      },
    }),
    withValuesCache(serviceType),

    withIsRequestPending(briefingRequestType(serviceType)),
    withIsRequestSucceeded(briefingRequestType(serviceType)),
    withRequestError(briefingRequestType(serviceType)),
    withDeleteRequestOnUnmount(briefingRequestType(serviceType)),
    withFormErrors([
      'description',
      'hashtags',
      'userMentions',
      'conditionId',
      'sampleMessage',
      'file',
      'isAdvertising',
    ]),

    withConditionOptions(BRIEFING_UPLOAD_CONDITIONS_REDUCED),
    withDownloadBriefingFileLink(serviceType),

    withPreviewModal,
  )

export default withInstagramPostForm
