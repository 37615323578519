import React from 'react'
import { compose, branch, withHandlers } from 'recompose'
import InternalErrorMessage from '../ui/global-message/InternalErrorMessage'
import { showSentryReportDialog } from '../services/sentry-error'
import Layout from '../ui/shared/Layout'
import { withLanguageSelect } from '../services/me/hocs'

const InternalErrorWithLayout = ({ language, setLanguage, ...props }) => (
  <Layout isPublic language={language} setLanguage={setLanguage}>
    <InternalErrorMessage {...props} />
  </Layout>
)

const enhance = compose(
  withLanguageSelect,

  branch(
    ({ eventId }) => !!eventId,
    withHandlers({
      onReport: ({ eventId, user }) => _ =>
        showSentryReportDialog(eventId, user),
    }),
  ),
)

export default enhance(InternalErrorWithLayout)
