import React from 'react'
import PropTypes from 'prop-types'
import AnchorButton from '../buttons/AnchorButton'
import BubblesCard from './BubblesCard'

const InitialCard = ({ linkText, linkUrl, imageBigUrl, imageSmallUrl }) => {
  return (
    <BubblesCard>
      <div className="search-card">
        <div className="search-card-image">
          <picture>
            <source media="(max-width: 969px)" srcSet={imageSmallUrl} />
            <source media="(min-width: 970px)" srcSet={imageBigUrl} />
            <img src={imageBigUrl} alt="Decoration" />
          </picture>
        </div>
        <div className="search-card-contents">
          <AnchorButton linkUrl={linkUrl}>{linkText}</AnchorButton>
        </div>
      </div>
    </BubblesCard>
  )
}

InitialCard.propTypes = {
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  imageBigUrl: PropTypes.string,
  imageSmallUrl: PropTypes.string,
}

export default InitialCard
