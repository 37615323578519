import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import ChipBasic from './../chips/ChipBasic'

const OrderDetailVideo = ({ i18n, minLength, maxLength }) => {
  return (
    <OrderDetailSection title={i18n('sections:video-length')}>
      <ChipBasic
        title={i18n('order-detail:min-duration')}
        text={`${minLength} min`}
        isInline
      />
      <ChipBasic
        title={i18n('order-detail:max-duration')}
        text={`${maxLength} min`}
        isInline
      />
    </OrderDetailSection>
  )
}

OrderDetailVideo.propTypes = {
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailVideo)
