import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import ResourceDetailContainer from './ResourceDetailContainer'
import StripCardKpi from '../../cards/StripCardKpi'
import ResourceDetailSection from './ResourceDetailSection'
import ResourceDetailLinks from './ResourceDetailLinks'
import ResourceDetailServicesWeb from './ResourceDetailServicesWeb'
import ResourceDetailServicesSocial from './ResourceDetailServicesSocial'
import MetricsCard from '../../cards/MetricsCard'
import ChartCard from '../../cards/ChartCard'
import imgAnalytics from '../images/logo-analytics.jpg'
import { RESOURCE_TYPES } from '../../../services/resources/constants'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'

const ResourceDetailWeb = ({
  item,
  type,
  image,
  name,
  location,
  category,
  description,
  webUrl,
  facebookUrl,
  twitterUrl,
  instagramUrl,
  youtubeUrl,
  twitchUrl,
  tiktokUrl,
  facebookFollowers,
  twitterFollowers,
  visits,
  users,
  pageViews,
  DAmozScore,
  PAmozScore,
  rating,
  valuations,
  interactions,
  engagement,
  followers,
  acceptance,
  isNew,
  services,
  ageChartSlot,
  genderChartSlot,
  countryChartSlot,
  onReport,
  i18n,
  isResourceLoaded,
  isUserVerified,
  handleAddToFavorites,
  handleRemoveFavorite,
  isFavorite,
  backLinkAction,
  ...props
}) => {
  const titleLogoUrl = type === RESOURCE_TYPES.WEB ? imgAnalytics : undefined
  return (
    <ResourceDetailContainer
      name={name}
      image={image}
      type={type}
      location={location}
      category={category}
      rating={rating}
      valuations={valuations}
      description={description}
      isNew={isNew}
      isLoading={!isResourceLoaded}
      isUserVerified={isUserVerified}
      isFavourited={isFavorite}
      onFavClick={isFavorite ? handleRemoveFavorite : handleAddToFavorites}
      onReport={onReport}
      backLinkAction={backLinkAction}
    >
      <StripCardKpi
        type={type}
        interactions={interactions}
        engagement={engagement}
        acceptance={acceptance}
        followers={followers}
      />
      <ResourceDetailLinks
        webUrl={webUrl}
        facebookUrl={facebookUrl}
        twitterUrl={twitterUrl}
        instagramUrl={instagramUrl}
        youtubeUrl={youtubeUrl}
        twitchUrl={twitchUrl}
        tiktokUrl={tiktokUrl}
      />

      {type === RESOURCE_TYPES.WEB && (
        <>
          <ResourceDetailSection titleText={i18n('sections:web-traffic')}>
            <MetricsCard
              facebookFollowers={facebookFollowers}
              twitterFollowers={twitterFollowers}
              DAmozScore={DAmozScore}
              PAmozScore={PAmozScore}
              visits={visits}
              users={users}
              pageViews={pageViews}
            />
          </ResourceDetailSection>
          <ResourceDetailServicesWeb
            {...props}
            services={services}
            item={item}
          />
        </>
      )}

      {type !== RESOURCE_TYPES.WEB && (
        <>
          <ResourceDetailServicesSocial
            {...props}
            services={services}
            item={item}
          />
        </>
      )}

      <ResourceDetailSection titleLogoUrl={titleLogoUrl}>
        {ageChartSlot && (
          <ChartCard title={i18n('charts:age-audience')}>
            {ageChartSlot}
          </ChartCard>
        )}
        {genderChartSlot && (
          <ChartCard title={i18n('charts:gender-audience')}>
            {genderChartSlot}
          </ChartCard>
        )}
        {countryChartSlot && (
          <ChartCard title={i18n('charts:country-density')}>
            {countryChartSlot}
          </ChartCard>
        )}
      </ResourceDetailSection>
    </ResourceDetailContainer>
  )
}

ResourceDetailWeb.propTypes = {
  item: PropTypes.object,
  image: PropTypes.string,
  name: PropTypes.string,
  // First rendered time gets react-router location prop
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  category: PropTypes.string,
  description: PropTypes.string,
  webUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  facebookFollowers: PropTypes.number,
  twitterFollowers: PropTypes.number,
  visits: PropTypes.number,
  users: PropTypes.number,
  pageViews: PropTypes.number,
  DAmozScore: PropTypes.number,
  PAmozScore: PropTypes.number,
  rating: PropTypes.number,
  valuations: PropTypes.number,
  interactions: PropTypes.number,
  engagement: PropTypes.number,
  acceptance: PropTypes.number,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      price: CoobisPropTypes.price,
    }),
  ),
  analyticsGenderData: PropTypes.array,
  analyticsGenderLegend: PropTypes.array,
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFavorite: PropTypes.bool,
  ageChartSlot: PropTypes.node,
  genderChartSlot: PropTypes.node,
  countryChartSlot: PropTypes.node,
  onReport: PropTypes.func,
  backLinkAction: PropTypes.func,
  i18n: PropTypes.func.isRequired,
  currency: CoobisPropTypes.currency,
}

export default withTranslations(ResourceDetailWeb)
