import * as hash from 'reduken/hash'
import { createSelector } from 'reselect'
import * as R from 'ramda'
import {
  DOMAIN,
  HASH_KEY_BALANCE,
  HASH_KEY_MOVEMENTS,
  HASH_KEY_TOTAL_INCOME,
  HASH_KEY_BALANCE_RECHARGE_MODAL,
  HASH_KEY_RECHARGE_DISCOUNT_COUPON,
  HASH_KEY_IS_WITHDRAW_FUNDS_MODAL_OPEN,
  HASH_KEY_IS_BILLING_DATA_DISCLAIMER_MODAL_OPEN,
  HASH_KEY_WITHDRAW_FUNDS_DATA,
  HASH_KEY_IS_WITHDRAW_SUCCESS_MODAL_OPEN,
} from './constants'
import { moneyToFloat } from '../../app/utils'

export const getBalance = createSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_BALANCE),
  balance => balance,
)

export const getTotalIncome = createSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_TOTAL_INCOME),
  totalCurrency => totalCurrency,
)

export const getWalletMovements = createSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_MOVEMENTS),
  R.identity,
)

export const getIsBalanceRechargeModalOpen = createSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_BALANCE_RECHARGE_MODAL),
  R.identity,
)

export const getIsWithdrawSuccessModalOpen = createSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_IS_WITHDRAW_SUCCESS_MODAL_OPEN),
  R.identity,
)

export const getAppliedRechargeDiscountCouponData = createSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_RECHARGE_DISCOUNT_COUPON),
  R.identity,
)

export const getIsWithdrawFundsModalOpen = hash.getFromPath(
  DOMAIN,
  HASH_KEY_IS_WITHDRAW_FUNDS_MODAL_OPEN,
)

export const getIsBillingDataDisclaimerModalOpen = hash.getFromPath(
  DOMAIN,
  HASH_KEY_IS_BILLING_DATA_DISCLAIMER_MODAL_OPEN,
)

export const getWithdrawFundsData = createSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_WITHDRAW_FUNDS_DATA),
  withdrawFundsData => {
    if (!withdrawFundsData) {
      return {}
    }

    const withdrawalMethods = R.pipe(
      R.when(x => !Array.isArray(x), R.values),
      R.filter(x => !x.missingInfo),
      R.pluck('id'),
    )(withdrawFundsData.withdrawalMethods)

    const withdrawalTypes = R.pipe(
      R.map(x => ({
        amount: x.amount,
        vatRate: moneyToFloat(x.vatRate),
        incomeTaxRate: moneyToFloat(x.incomeTaxRate.rate),
        finalAmount: x.finalAmount,
        requiresResidenceDocument:
          x.incomeTaxRate.requires === 'RESIDENCE_DOCUMENT_UPLOAD',
        requiresTypeDocument:
          x.incomeTaxRate.requires === 'TYPE_DOCUMENT_UPLOAD',
        originalFinalAmount: x.amount,
        originalIncomeTaxRate: x.incomeTaxRate.rate,
      })),
      R.sort(R.ascend(R.prop('vatRate'))),
    )(withdrawFundsData.withdrawalTypes)

    const incomeTaxRates = R.pipe(
      R.pluck('incomeTaxRate'),
      R.pluck('rate'),
      R.map(moneyToFloat),
    )(withdrawFundsData.withdrawalTypes)

    return {
      withdrawalMethods,
      withdrawalTypes,
      incomeTaxRates,
      canChoosePaymentMethod: withdrawalMethods.length > 1,
      invoiceUploadRequired: withdrawFundsData.invoiceUploadRequired,
    }
  },
)
