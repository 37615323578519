import React from 'react'
import HeaderLogo from '../header/HeaderLogo'
import GlobalMessage from './GlobalMessage'
import withTranslations from '../../hocs/withTranslations'
import imgOfflineBig from './images/offlineBig.png'
import imgOfflineSmall from './images/offlineSmall.png'

const OfflineMessage = ({ i18n }) => {
  return (
    <div className="overlay">
      <HeaderLogo />
      <main className="main">
        <div className="wrapper">
          <GlobalMessage
            message={i18n('error:offline')}
            imageBig={imgOfflineBig}
            imageSmall={imgOfflineSmall}
          />
        </div>
      </main>
    </div>
  )
}

OfflineMessage.propTypes = {}

export default withTranslations(OfflineMessage)
