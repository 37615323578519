import React from 'react'
import PropTypes from 'prop-types'

const FilterGroup = ({ title, children }) => (
  <section className="filter-group">
    {title && <h4 className="filter-group-title">{title}</h4>}
    {children}
  </section>
)

FilterGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
}

export default FilterGroup
