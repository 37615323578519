import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import { MediaQueryLap, MediaQueryPalm } from '../../shared/MediaQueries'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import ChartCard from '../../cards/ChartCard'
import ClusteredBarChart from '../../charts/ClusteredBarChart'
import PieChart from '../../charts/PieChart'
import MapChart from '../../charts/MapChart'
import AlternativeGaugeWidget from '../../charts/AlternativeGaugeWidget'
import CampaignPreviewYoutube from '../social-previews/CampaignPreviewYoutube'
import { isNilOrEmpty } from '../../../app/utils'
import {
  sumChartValues,
  sumChartWomenValues,
  sumChartMenValues,
  containsNotNullValuesChartCard,
} from '../../../app/common/statistics-utils'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'

const CampaignDetailYoutubePublication = ({
  previews,
  countryData = [],

  countryWidgetData,
  countryWidgetTotal,
  isPrevCountryDisabled,
  isNextCountryDisabled,
  onPrevCountryHandler,
  onNextCountryHandler,

  i18n,
  // Metrics values
  engagementRate,
  likes,
  views,
  comments,
  averageVisualization,
  ageData,
  genderData,
}) => {
  const sumChartAgeRangeValues =
    sumChartMenValues(ageData) + sumChartWomenValues(ageData)
  return (
    <>
      <div className="analytics-kpi-group">
        {engagementRate > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagementRate}
            label={i18n('metrics:engagement-rate')}
            isPercentage
          />
        )}
        {likes > 0 && (
          <AnalyticsKpi
            iconType="like"
            value={likes}
            label={i18n('metrics:likes')}
            isTruncated
          />
        )}
        {views > 0 && (
          <AnalyticsKpi
            iconType="eye"
            value={views}
            label={i18n('metrics:views')}
            isTruncated
          />
        )}
        {comments > 0 && (
          <AnalyticsKpi
            iconType="comment"
            value={comments}
            label={i18n('metrics:comments')}
            isTruncated
          />
        )}
        {averageVisualization > 0 && (
          <AnalyticsKpi
            iconType="clock"
            value={averageVisualization}
            label={i18n('metrics:average-visualization')}
            isTime
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewYoutube items={previews} />
      ) : null}
      <div className="campaign-detail-section-row">
        {sumChartAgeRangeValues > 0 && (
          <div className="campaign-detail-section-cell two-thirds">
            <ChartCard title={i18n('metrics:age-audience')}>
              <ClusteredBarChart data={ageData} />
            </ChartCard>
          </div>
        )}
        {sumChartValues(genderData) > 0 && (
          <div className="campaign-detail-section-cell one-third">
            <ChartCard title={i18n('metrics:gender-audience')} isPie>
              <PieChart data={genderData} />
            </ChartCard>
          </div>
        )}
      </div>
      {containsNotNullValuesChartCard(countryData) && (
        <div className="campaign-detail-section-row">
          <div className="campaign-detail-section-cell one-whole">
            <ChartCard title={i18n('metrics:country-audience')}>
              <MediaQueryPalm>
                <AlternativeGaugeWidget
                  totalLabel={i18n('countries-chart:count', {
                    shown: countryWidgetData.length,
                    total: countryWidgetTotal,
                  })}
                  items={countryWidgetData}
                  color="#3e17b3"
                  isPrevDisabled={isPrevCountryDisabled}
                  isNextDisabled={isNextCountryDisabled}
                  onPrevClick={onPrevCountryHandler}
                  onNextClick={onNextCountryHandler}
                />
              </MediaQueryPalm>
              <MediaQueryLap>
                <MapChart
                  hasGaugeWidget
                  data={countryData}
                  totalCountries={countryWidgetTotal}
                  gaugeWidgetData={countryWidgetData}
                  isPrevPaginationDisabled={isPrevCountryDisabled}
                  isNextPaginationDisabled={isNextCountryDisabled}
                  onPrevPaginationClick={onPrevCountryHandler}
                  onNextPaginationClick={onNextCountryHandler}
                />
              </MediaQueryLap>
            </ChartCard>
          </div>
        </div>
      )}
    </>
  )
}

CampaignDetailYoutubePublication.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  countryData: CoobisPropTypes.demographicData,

  countryWidgetData: CoobisPropTypes.demographicData,
  countryWidgetTotal: PropTypes.number,
  isPrevCountryDisabled: PropTypes.bool,
  isNextCountryDisabled: PropTypes.bool,
  onPrevCountryHandler: PropTypes.func,
  onNextCountryHandler: PropTypes.func,

  i18n: PropTypes.func.isRequired,
  // Metrics values
  engagementRate: PropTypes.number,
  likes: PropTypes.number,
  views: PropTypes.number,
  comments: PropTypes.number,
  averageVisualization: PropTypes.number,
  ageData: PropTypes.array,
  genderData: PropTypes.array,
}

export default withTranslations(CampaignDetailYoutubePublication)
