import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@redradix/components.input'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import Subtitle from '../texts/Subtitle'
import { getSocialProfileUrlPlaceholder } from '../../app/resources/util'
import { RESOURCE_TYPES } from '../../services/resources/constants'

const WebResourcesSocialMediaForm = ({
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  values,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  return (
    <form className="basic-form" onSubmit={handleSubmit}>
      <Subtitle text={i18n('web-resources:social-media-description')} />
      <Input
        type="text"
        name="facebook"
        label={i18n('noun:facebook')}
        placeholder={getSocialProfileUrlPlaceholder(
          RESOURCE_TYPES.FACEBOOK,
          i18n,
        )}
        value={values.facebook}
        widthBehaviour="full"
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.facebook}
        isDisabled={isRequestPending}
        helperText={i18n('web-resources:facebook-url-warning')}
        errorText={
          validationMessages.facebook && (
            <FormattedFormErrors errors={validationMessages.facebook} />
          )
        }
      />
      <Input
        type="text"
        name="twitter"
        label={i18n('noun:twitter')}
        placeholder={getSocialProfileUrlPlaceholder(
          RESOURCE_TYPES.TWITTER,
          i18n,
        )}
        value={values.twitter}
        widthBehaviour="full"
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.twitter}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.twitter && (
            <FormattedFormErrors errors={validationMessages.twitter} />
          )
        }
      />
      <Input
        type="text"
        name="instagram"
        label={i18n('noun:instagram')}
        placeholder={getSocialProfileUrlPlaceholder(
          RESOURCE_TYPES.INSTAGRAM,
          i18n,
        )}
        value={values.instagram}
        widthBehaviour="full"
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.instagram}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.instagram && (
            <FormattedFormErrors errors={validationMessages.instagram} />
          )
        }
      />
      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}
      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
}

WebResourcesSocialMediaForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(WebResourcesSocialMediaForm)
