import { connect } from 'react-redux'
import { getIsRequestWithIdSucceeded } from '../services/communication/selectors'

const withIsRequestWithIdSucceeded = (
  requestType,
  { idProp = 'id', outProp = 'isRequestSucceeded' } = {},
) =>
  connect((state, ownProps) => ({
    [outProp]: getIsRequestWithIdSucceeded(
      requestType,
      ownProps[idProp],
      state,
    ),
  }))

export default withIsRequestWithIdSucceeded
