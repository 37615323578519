import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  fetchCampaignSEOURLStats,
  getCampaignSEOURLStats,
} from '../../services/campaigns'
import { FETCH_CAMPAIGN_SEO_URL_STATS } from '../../services/campaigns/action-types'
import withIsLoadingWithId from '../../hocs/withIsLoadingWithId'
import withEffect from '../../hocs/withEffect'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'

const withCampaignSEOURLStats = compose(
  withPropsOnChange(['match'], ({ match }) => ({
    seoUrl: decodeURIComponent(match.params.seoUrl),
  })),

  withPropsOnChange(['campaignId', 'seoUrl'], ({ campaignId, seoUrl }) => ({
    fetchId: `${campaignId}_${seoUrl}`,
  })),

  withRoute404ErrorWithId(FETCH_CAMPAIGN_SEO_URL_STATS, 'fetchId'),
  withRouteServerErrorWithId(FETCH_CAMPAIGN_SEO_URL_STATS, 'fetchId'),

  connect(
    (state, { campaignId, seoUrl }) => ({
      stats: getCampaignSEOURLStats(campaignId, seoUrl, state),
    }),
    {
      fetchCampaignSEOURLStats,
    },
  ),

  withEffect(
    ({ fetchCampaignSEOURLStats, campaignId, seoUrl }) => {
      fetchCampaignSEOURLStats(campaignId, seoUrl)
    },
    ({ fetchCampaignSEOURLStats, campaignId, seoUrl }) => [
      fetchCampaignSEOURLStats,
      campaignId,
      seoUrl,
    ],
  ),

  withIsLoadingWithId(FETCH_CAMPAIGN_SEO_URL_STATS, {
    idProp: 'fetchId',
    outProp: 'isLoadingSEOURLStats',
  }),
)

export default withCampaignSEOURLStats
