import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import AnchorLink from '../texts/AnchorLink'

const CampaignDetailPanel = ({
  title,
  linkText,
  linkUrl,
  size,
  autoHeight,
  isGlobal,
  children,
}) => {
  const classes = cx(`campaign-detail-panel size-${size}`, {
    'has-auto-height': autoHeight,
    'is-global': isGlobal,
  })
  return (
    <section className={classes}>
      {title && (
        <header className="campaign-detail-panel-header">
          <h2 className="campaign-detail-panel-title">{title}</h2>
          {linkText && <AnchorLink text={linkText} linkUrl={linkUrl} />}
        </header>
      )}
      <div className="campaign-detail-panel-contents">{children}</div>
    </section>
  )
}

CampaignDetailPanel.defaultProps = {
  size: 'one-whole',
  autoHeight: false,
}

CampaignDetailPanel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  size: PropTypes.oneOf(['one-whole', 'one-half', 'one-third', 'two-thirds']),
  autoHeight: PropTypes.bool.isRequired,
  isGlobal: PropTypes.bool,
}

export default CampaignDetailPanel
