import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@redradix/components.input'
import Button from '@redradix/components.button'
import Radio from '@redradix/components.radio'
import DeleteButton from '../buttons/DeleteButton'
import moment from 'moment'
import FormattedFormErrors from '../FormattedFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import Select from '../form-elements/Select'
import SelectSearchable from '../form-elements/SelectSearchable/SelectSearchable'
import FormFieldRow from '../form-elements/FormFieldRow'
import Textarea from '../form-elements/Textarea'
import Dropdown from '../dropdown/Dropdown'
import Subtitle from '../texts/Subtitle'
import Datepicker from '../form-elements/Datepicker'
import ProfilePictureDraggable from '../form-elements/ProfilePictureDraggable'
import { DESCRIPTION_MAX_CHARACTERS } from '../../services/resources/constants'
import {
  SOCIAL_NETWORK_RESOURCE_TYPES,
  mapResourceTypeToI18N,
} from '../../services/resources/constants'
import FormField from '../form-elements/FormField'
import { getSocialProfileUrlPlaceholder } from '../../app/resources/util'
import { fixChannelUrl } from '../../app/utils'

const SocialResourceGeneralData = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  countryOptions,
  categoryOptions,
  languageOptions,
  photoURL,
  onPhotoUpload,
  onPhotoUploadDrop,
  setLoadedPhotoURL,
  setErroredPhotoURL,
  isPhotoErrored,
  photoError,
  onScreenshotClick,
  isScreenshotRequestPending,
  isUploadResourceImageRequestPending,
  isLoadingPhoto,
  onClearHolidays,
  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  isClearHolidaysLoading,
  channel,
  isVipSocialNetworks,
  isDisabled,
}) => {
  return (
    <form className="basic-form" onSubmit={handleSubmit}>
      <Input
        type="text"
        name="name"
        label={i18n('label:name')}
        placeholder={i18n('label:name')}
        widthBehaviour="full"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.name}
        isDisabled={isRequestPending || isDisabled || !isVipSocialNetworks}
        isRequired
        errorText={
          validationMessages.name && (
            <FormattedFormErrors errors={validationMessages.name} />
          )
        }
      />
      <Input
        type="text"
        name="url"
        label={i18n('social-resource:channel-url', {
          channelName: i18n(mapResourceTypeToI18N[channel]),
        })}
        placeholder={getSocialProfileUrlPlaceholder(channel, i18n)}
        widthBehaviour="full"
        value={values.url}
        onChange={e => {
          e.target.value = fixChannelUrl(e.target.value, channel)
          return handleChange(e)
        }}
        onBlur={handleBlur}
        isTouched={touched.url}
        isDisabled={isRequestPending || isDisabled || !isVipSocialNetworks}
        isRequired
        errorText={
          validationMessages.url && (
            <FormattedFormErrors errors={validationMessages.url} />
          )
        }
      />
      <FormFieldRow>
        <SelectSearchable
          name="countryId"
          label={i18n('noun:country')}
          placeholder={i18n('web-resources:select-country')}
          options={countryOptions}
          value={values.countryId}
          isTouched={touched.countryId}
          isDisabled={isRequestPending || isDisabled}
          isRequired
          onChange={item =>
            handleChange({ target: { name: 'countryId', value: item.value } })
          }
          onBlur={_ => handleBlur({ target: { name: 'countryId' } })}
          errorText={
            validationMessages.countryId && (
              <FormattedFormErrors errors={validationMessages.countryId} />
            )
          }
        />
        <Select
          name="languageId"
          label={i18n('noun:language')}
          placeholder={i18n('web-resources:select-language')}
          options={languageOptions}
          value={values.languageId}
          isTouched={touched.languageId}
          isDisabled={isRequestPending || isDisabled}
          isRequired
          onChange={item =>
            handleChange({ target: { name: 'languageId', value: item.value } })
          }
          onBlur={_ => handleBlur({ target: { name: 'languageId' } })}
          errorText={
            validationMessages.languageId && (
              <FormattedFormErrors errors={validationMessages.languageId} />
            )
          }
        />
      </FormFieldRow>
      <FormFieldRow>
        <SelectSearchable
          isRequired
          label={i18n('noun:main-category')}
          placeholder={i18n('web-resources:select-category')}
          name="categoryId"
          options={categoryOptions}
          value={values.categoryId}
          isTouched={touched.categoryId}
          isDisabled={isRequestPending || isDisabled}
          onChange={item =>
            handleChange({
              target: { name: 'categoryId', value: item.value },
            })
          }
          onBlur={_ => handleBlur({ target: { name: 'categoryId' } })}
          errorText={
            validationMessages.categoryId && (
              <FormattedFormErrors errors={validationMessages.categoryId} />
            )
          }
        />
        <SelectSearchable
          label={i18n('noun:secondary-category')}
          name="secondaryCategoryId"
          placeholder={i18n('web-resources:select-category')}
          options={categoryOptions}
          value={values.secondaryCategoryId}
          isTouched={touched.secondaryCategoryId}
          isDisabled={isRequestPending || isDisabled}
          onChange={item =>
            handleChange({
              target: { name: 'secondaryCategoryId', value: item.value },
            })
          }
          onBlur={_ => handleBlur({ target: { name: 'secondaryCategoryId' } })}
          errorText={
            validationMessages.secondaryCategoryId && (
              <FormattedFormErrors
                errors={validationMessages.secondaryCategoryId}
              />
            )
          }
        />
      </FormFieldRow>
      <Textarea
        name="description"
        label={i18n('web-resources:web-description')}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('web-resources:web-description-long', {
          maxCharacters: DESCRIPTION_MAX_CHARACTERS,
        })}
        isTouched={touched.description}
        isDisabled={isRequestPending || isDisabled}
        isRequired
        errorText={
          validationMessages.description && (
            <FormattedFormErrors errors={validationMessages.description} />
          )
        }
        maxCharacters={DESCRIPTION_MAX_CHARACTERS}
      />
      <FormField
        label={i18n('web-resources:advertising-notice')}
        block
        isTouched={touched.marksSponsoredContent}
        errorText={
          validationMessages.marksSponsoredContent && (
            <FormattedFormErrors
              errors={validationMessages.marksSponsoredContent}
            />
          )
        }
      >
        <Radio
          name="marksSponsoredContent"
          id="advertisingNoticeAlways"
          checked={values.marksSponsoredContent}
          value={true}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.advertisingNoticeAlways}
          isDisabled={isRequestPending || isDisabled}
        >
          <span className="text">
            {i18n('web-resources:advertising-notice-always')}
          </span>
        </Radio>
        <Radio
          name="marksSponsoredContent"
          id="advertisingNoticeSometimes"
          checked={!values.marksSponsoredContent}
          value={false}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.advertisingNoticeSometimes}
          isDisabled={isRequestPending || isDisabled}
        >
          <span className="text">
            {i18n('web-resources:advertising-notice-sometimes')}
          </span>
        </Radio>
      </FormField>
      <ProfilePictureDraggable
        label={i18n('profile:profile-image')}
        onUpload={onPhotoUpload}
        onUploadDrop={onPhotoUploadDrop}
        photoURL={photoURL}
        setLoadedURL={setLoadedPhotoURL}
        setErroredURL={setErroredPhotoURL}
        photoErrored={isPhotoErrored}
        errorText={photoError}
        onScreenshotClick={onScreenshotClick}
        isCapturingScreenshot={isScreenshotRequestPending}
        isUploadingPhoto={isUploadResourceImageRequestPending}
        isLoadingPhoto={isLoadingPhoto}
        isDisabled={isRequestPending || isDisabled}
        isScreenshotCaptureDisabled={!values.url || values.url.length === 0}
      />
      <Dropdown buttonText={i18n('resources:vacation-mode')}>
        <Subtitle text={i18n('resources:vacation-mode-description')} />
        <FormFieldRow>
          <Datepicker
            id="holidaysBeginAt"
            name="holidaysBeginAt"
            value={values.holidaysBeginAt}
            onChange={handleChange}
            onBlur={handleBlur}
            datepickerLabel={i18n('noun:vacation-start-date')}
            modalTitle={i18n('noun:vacation-start-date')}
            placeholder={i18n('noun:vacation-start-date')}
            isTouched={touched.holidaysBeginAt}
            isDisabled={isRequestPending || isDisabled}
            errorText={
              validationMessages.holidaysBeginAt && (
                <FormattedFormErrors
                  errors={validationMessages.holidaysBeginAt}
                />
              )
            }
          />
          <Datepicker
            id="holidaysEndAt"
            name="holidaysEndAt"
            value={values.holidaysEndAt}
            onChange={handleChange}
            onBlur={handleBlur}
            datepickerLabel={i18n('noun:vacation-end-date')}
            modalTitle={i18n('noun:vacation-end-date')}
            placeholder={i18n('noun:vacation-end-date')}
            isTouched={touched.holidaysEndAt}
            isDisabled={isRequestPending || isDisabled}
            errorText={
              validationMessages.holidaysEndAt && (
                <FormattedFormErrors
                  errors={validationMessages.holidaysEndAt}
                />
              )
            }
          />
        </FormFieldRow>
        <DeleteButton
          buttonText={i18n('resources:action-clear-vacations')}
          onDelete={onClearHolidays}
          isDisabled={
            isRequestPending ||
            isDisabled ||
            (values.holidaysBeginAt === null && values.holidaysEndAt === null)
          }
          isLoading={isClearHolidaysLoading}
        />
      </Dropdown>
      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="form-actions">
        <Button
          type="submit"
          isLoading={isRequestPending}
          isDisabled={
            isScreenshotRequestPending ||
            isUploadResourceImageRequestPending ||
            isDisabled
          }
        >
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  name: PropTypes.string,
  url: PropTypes.string,
  countryId: CoobisPropTypes.makeSelectOption(PropTypes.string),
  languageId: CoobisPropTypes.makeSelectOption(PropTypes.string),
  categoryId: CoobisPropTypes.makeSelectOption(PropTypes.string),
  secondaryCategoryId: CoobisPropTypes.makeSelectOption(PropTypes.string),
  description: PropTypes.string,
  marksSponsoredContent: PropTypes.bool,
  holidaysBeginAt: PropTypes.instanceOf(moment),
  holidaysEndAt: PropTypes.instanceOf(moment),
}

SocialResourceGeneralData.propTypes = {
  channel: PropTypes.oneOf(SOCIAL_NETWORK_RESOURCE_TYPES),
  isDisabled: PropTypes.bool.isRequired,
  isVipSocialNetworks: PropTypes.bool.isRequired,
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  countryOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  languageOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  categoryOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  photoURL: PropTypes.string,
  setLoadedPhotoURL: PropTypes.func,
  setErroredPhotoURL: PropTypes.func,
  isPhotoErrored: PropTypes.bool,
  photoError: PropTypes.string,
  onScreenshotClick: PropTypes.func,
  onPhotoUpload: PropTypes.func,
  onPhotoUploadDrop: PropTypes.func,
  isScreenshotRequestPending: PropTypes.bool,
  isUploadResourceImageRequestPending: PropTypes.bool,
  isLoadingPhoto: PropTypes.bool,
  isClearHolidaysLoading: PropTypes.bool,
  onClearHolidays: PropTypes.func,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(SocialResourceGeneralData)
