import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import CartItem from '../../ui/cart/CartItem'
import { deleteItem } from '../../services/checkout'
import withIsRequestWithIdPending from '../../hocs/withIsRequestWithIdPending'
import { DELETE_ITEM } from '../../services/checkout/action-types'
import { resourceAvatarURL } from '../../services/resources/business'

const enhance = compose(
  connect(
    null,
    (dispatch, ownProps) => ({
      onDelete: () =>
        dispatch(deleteItem(ownProps.cartItem.id, ownProps.cartItem.service)),
    }),
  ),

  withProps(({ cartItem }) => ({
    ...cartItem,
    photoURL: resourceAvatarURL(cartItem.id),
    price: cartItem.finalPrice,
    cartItemRequestId: `${cartItem.id}-${cartItem.service}`,
  })),

  withIsRequestWithIdPending(DELETE_ITEM, {
    idProp: 'cartItemRequestId',
    outProp: 'isDeleting',
  }),
)

export default enhance(CartItem)
