import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  fetchPublicationPreviews,
  getOrderPreviews,
} from '../../services/campaigns'
import { isNilOrEmpty } from '../utils'
import withEffect from '../../hocs/withEffect'
import withIsRequestWithIdPending from '../../hocs/withIsRequestWithIdPending'
import { FETCH_PUBLICATION_PREVIEWS } from '../../services/campaigns/action-types'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'

const withPublicationPreviews = compose(
  withPropsOnChange(['ordersList', 'orderId'], ({ ordersList, orderId }) => {
    if (orderId) {
      return { orderIds: [Number(orderId)] }
    }

    if (isNilOrEmpty(ordersList)) return

    return { orderIds: ordersList.map(order => order.orderId) }
  }),

  connect(
    (state, { orderIds }) => ({
      previews: !isNilOrEmpty(orderIds)
        ? getOrderPreviews(orderIds, state)
        : null,
    }),
    { fetchPublicationPreviews },
  ),

  withPropsOnChange(
    ['campaignId', 'resourceType'],
    ({ campaignId, resourceType }) => ({
      fetchId: `${campaignId}_${resourceType}`,
    }),
  ),

  withEffect(
    ({ fetchPublicationPreviews, orderIds, fetchId }) => {
      if (!isNilOrEmpty(orderIds)) {
        fetchPublicationPreviews(orderIds, fetchId)
      }
    },
    ({ fetchPublicationPreviews, orderIds, fetchId }) => [
      fetchPublicationPreviews,
      orderIds,
      fetchId,
    ],
  ),

  withIsRequestWithIdPending(FETCH_PUBLICATION_PREVIEWS, {
    idProp: 'fetchId',
    outProp: 'isLoadingPreviews',
  }),

  withRoute404ErrorWithId(FETCH_PUBLICATION_PREVIEWS, 'fetchId'),
  withRouteServerErrorWithId(FETCH_PUBLICATION_PREVIEWS, 'fetchId'),
)

export default withPublicationPreviews
