import React from 'react'
import { compose, withPropsOnChange, lifecycle, withHandlers } from 'recompose'

export const ORDER_DETAIL_TABS = ['instructions', 'history', 'delivery']

const withOrderDetailTabs = tabPathFromProps =>
  compose(
    withPropsOnChange(['match'], ({ match }) => ({
      selectedIndex: match.params.selectedTab
        ? ORDER_DETAIL_TABS.indexOf(match.params.selectedTab)
        : 0,
    })),

    withPropsOnChange(['i18n'], ({ i18n }) => ({
      controllers: ORDER_DETAIL_TABS.map(tab => ({
        name: <span>{i18n(`order-detail-tab:${tab}`)}</span>,
      })),
    })),

    lifecycle({
      componentDidMount() {
        const { history, match } = this.props

        // Always has the selected tab in URL
        if (!match.params.selectedTab) {
          history.replace(tabPathFromProps(ORDER_DETAIL_TABS[0], this.props))
        }
      },
    }),

    withHandlers({
      handleTabChange: props => tabIndex => {
        // Use replace instead of push because push breaks the go back button behavior
        props.history.replace(
          tabPathFromProps(ORDER_DETAIL_TABS[tabIndex], props),
        )
      },
    }),
  )

export default withOrderDetailTabs
