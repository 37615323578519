import { isNil } from 'ramda'
import { formatBytes } from '../utils'

const parseErrors = (error, i18n) => {
  const fileErrorType = error.errorData.validation_messages.file
  return !isNil(fileErrorType.choice)
    ? i18n('errors:invalid-file-extension', {
        allowedFormats: fileErrorType.choice.expected.join(', '),
      })
    : !isNil(fileErrorType.lessOrEqualThan)
    ? i18n('errors:exceed-max-file-size', {
        max: formatBytes(fileErrorType.lessOrEqualThan.expected, 1),
      })
    : undefined
}

export const getErrorTextFromUploadError = (error, defaultText, i18n) => {
  return error && error.status === 422 ? parseErrors(error, i18n) : defaultText
}
