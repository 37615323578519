import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as IconArrowLeft } from '../../styles/icons/arrow-left.svg'
import IconButton from '../buttons/IconButton'

const GlobalContent = ({
  title,
  children,
  hasPanelBackground,
  isMobileFullWidth,
  isTopSpaced,
  backLinkAction,
}) => {
  const classes = cx('global-content', {
    'has-panel-background': hasPanelBackground,
    'is-mobile-full-width': isMobileFullWidth,
    'is-top-spaced': isTopSpaced,
  })
  return (
    <div className={classes}>
      {backLinkAction && (
        <IconButton
          hierarchy="secondary"
          variant="neutral"
          className="back-link"
          onClick={backLinkAction}
        >
          <IconArrowLeft />
        </IconButton>
      )}
      {title && (
        <header className="global-content-header">
          <h1 className="global-content-title">{title}</h1>
        </header>
      )}
      <div className="global-content-wrapper">{children}</div>
    </div>
  )
}

GlobalContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  hasPanelBackground: PropTypes.bool,
  isMobileFullWidth: PropTypes.bool,
  isTopSpaced: PropTypes.bool,
  backLinkAction: PropTypes.func,
}

export default GlobalContent
