import * as R from 'ramda'
import * as Yup from 'yup'
import { i18nValidation } from '../../app/common/validation'

export const billingDataSchema = ({ translatedCountries }) => {
  const regexp = /^[a-zA-Z0-9]*$/

  return Yup.object().shape({
    invoiceEmail: Yup.string()
      .email(i18nValidation.invalidMail)
      .required(i18nValidation.required),
    businessName: Yup.string().required(i18nValidation.required),
    identityDocument: Yup.string()
      .matches(regexp, i18nValidation.cif)
      .required(i18nValidation.required)
      .max(20, i18nValidation.maxLength),
    country: Yup.mixed()
      .oneOf(translatedCountries.map(R.prop('id')), i18nValidation.required)
      .required(i18nValidation.required),
    state: Yup.mixed()
      .oneOf(
        R.pipe(
          R.map(R.prop('provinces')),
          R.mergeAll,
          R.keys,
        )(translatedCountries),
        i18nValidation.required,
      )
      .required(i18nValidation.required),
    city: Yup.string().required(i18nValidation.required),
    address: Yup.string().required(i18nValidation.required),
    postalCode: Yup.string().required(i18nValidation.required),
    currency: Yup.mixed().test(
      'empty',
      i18nValidation.required,
      currency => currency && currency.length,
    ),
  })
}
