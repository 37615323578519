import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { ConnectedRouter } from 'connected-react-router'
import { QueryParamProvider } from 'use-query-params'
import { StripeProvider } from 'react-stripe-elements'
import envConfig from '../environments'
import { setupI18n } from '../libs/i18n'
import { getLanguage } from '../services/me'
import { CUSTOM_FORMATS } from '../config/formats'

const AppProvider = ({ language, history, children }) => {
  const { messages, locale } = setupI18n(language)

  return (
    <StripeProvider apiKey={envConfig.stripeKey}>
      <IntlProvider
        messages={messages}
        locale={locale}
        textComponent={React.Fragment}
        formats={CUSTOM_FORMATS}
      >
        <ConnectedRouter history={history}>
          <QueryParamProvider history={history}>{children}</QueryParamProvider>
        </ConnectedRouter>
      </IntlProvider>
    </StripeProvider>
  )
}

AppProvider.propTypes = {
  language: PropTypes.string,
  history: PropTypes.object.isRequired,
  children: PropTypes.node,
}

const enhance = connect(state => ({
  language: getLanguage(state),
}))

export default enhance(AppProvider)
