import React from 'react'
import Button from '@redradix/components.button'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import confirmImage from './images/confirm-info.png'

const BrandBillingConfirmModal = ({ onConfirm, onBack, i18n }) => (
  <div className="billing-confirm-form">
    <picture>
      <img src={confirmImage} alt="Info" />
    </picture>
    <p className="form-title">{i18n('profile:confirm-billing-form-title')}</p>
    <p className="form-description">
      {i18n('profile:confirm-billing-form-description')}
    </p>
    <Button type="button" hierarchy="secondary" onClick={onConfirm}>
      {i18n('action:save')}
    </Button>
    <Button
      type="button"
      hierarchy="secondary"
      variant="alternate"
      onClick={onBack}
    >
      {i18n('action:back')}
    </Button>
  </div>
)

BrandBillingConfirmModal.propTypes = {
  onConfirm: PropTypes.func,
  onBack: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(BrandBillingConfirmModal)
