import * as hash from 'reduken/hash'
import * as entities from 'reduken/entities'
import {
  DOMAIN,
  HASH_KEY_CAMPAIGNS,
  HASH_KEY_LAST_CREATED_CAMPAIGN_ID,
  HASH_KEY_CAMPAIGNS_SUMMARY,
  ENTITY_KEY_CAMPAIGN_RESOURCE_TYPE_STATS,
  ENTITY_KEY_CAMPAIGN_SUMMARY,
  ENTITY_KEY_CAMPAIGN_ORDERS_BY_TYPE,
  ENTITY_KEY_CAMPAIGN_ORDERS_STATS,
  ENTITY_KEY_CAMPAIGN_SEO_URL_STATS,
  ENTITY_KEY_PUBLICATION_PREVIEWS,
} from './constants'
import { createDeepEqualSelector } from '../../app/utils'

export const getCampaigns = hash.getFromPath(DOMAIN, HASH_KEY_CAMPAIGNS)

export const getLastCreatedCampaignId = hash.getFromPath(
  DOMAIN,
  HASH_KEY_LAST_CREATED_CAMPAIGN_ID,
)

export const getCampaignsSummary = hash.getFromPath(
  DOMAIN,
  HASH_KEY_CAMPAIGNS_SUMMARY,
)

export const getCampaignSummaryById = entities.getOne(
  ENTITY_KEY_CAMPAIGN_SUMMARY,
)

export const getOrderPreviews = createDeepEqualSelector(
  entities.getSome(ENTITY_KEY_PUBLICATION_PREVIEWS),
  previews => previews,
)

export const getCampaignResourceTypeStats = (campaignId, resourceType, state) =>
  entities.getOne(
    ENTITY_KEY_CAMPAIGN_RESOURCE_TYPE_STATS,
    `${campaignId}_${resourceType}`,
    state,
  )

export const getCampaignOrderStats = (campaignId, orderId, state) =>
  entities.getOne(
    ENTITY_KEY_CAMPAIGN_ORDERS_STATS,
    `${campaignId}_${orderId}`,
    state,
  )

export const getCampaignSEOURLStats = (campaignId, seoUrl, state) =>
  entities.getOne(
    ENTITY_KEY_CAMPAIGN_SEO_URL_STATS,
    `${campaignId}_${seoUrl}`,
    state,
  )

export const getCampaignOrdersListByType = (campaignId, resourceType, state) =>
  entities.getOne(
    ENTITY_KEY_CAMPAIGN_ORDERS_BY_TYPE,
    `${campaignId}_${resourceType}`,
    state,
  )
