import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import IconSocial from '../icons/IconSocial'
import { RESOURCE_TYPES } from '../../services/resources/constants'

const OrderType = ({ i18n, icon, i18nKey }) => {
  return (
    <div className="order-type">
      <div className="order-type-icon">
        <IconSocial type={icon} />
      </div>
      <span className="order-type-text">{i18n(i18nKey)}</span>
    </div>
  )
}

OrderType.propTypes = {
  icon: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  i18nKey: PropTypes.string.isRequired,
}

export default withTranslations(OrderType)
