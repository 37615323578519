import React from 'react'
import PropTypes from 'prop-types'
import GlobalContent from '../shared/GlobalContent'
import OrderDetailHeader from './OrderDetailHeader'
import OrderDetailContent from './OrderDetailContent'

const OrderDetail = ({ heading, hasActions, actionsSlot, children }) => {
  return (
    <GlobalContent isMobileFullWidth>
      <div className="order-detail">
        <OrderDetailHeader
          heading={heading}
          hasActions={hasActions}
          actionsSlot={actionsSlot}
        />
        <OrderDetailContent>{children}</OrderDetailContent>
      </div>
    </GlobalContent>
  )
}

OrderDetail.propTypes = {
  heading: PropTypes.string,
  hasActions: PropTypes.bool,
  actionsSlot: PropTypes.node,
  children: PropTypes.node,
}

export default OrderDetail
