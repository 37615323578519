import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withTranslations from '../../hocs/withTranslations'

const Textarea = ({
  onBlur,
  onChange,
  name,
  placeholder,
  value,
  isDisabled,
  errorText,
  helperText,
  isTouched,
  isRequired,
  label,
  i18n,
  maxCharacters,
}) => {
  const showError = isTouched && Boolean(errorText)

  const internalRemainingCharacters = useMemo(() => {
    const remaining = maxCharacters - value.length
    return remaining < 0 ? 0 : remaining
  }, [value, maxCharacters])

  return (
    <div className={cx('textarea-field', { 'has-error': showError })}>
      {label && (
        <div className="label">
          {label}
          {isRequired && <span className="rdx-icon--require">*</span>}
        </div>
      )}
      <textarea
        className="textarea"
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        disabled={isDisabled}
      />
      {maxCharacters && (
        <div className="remaining-characters">
          {i18n('noun:remaining-characters', {
            remaining: internalRemainingCharacters,
          })}
        </div>
      )}
      {helperText && !showError && (
        <div className="rdx-input-field-helper">{helperText}</div>
      )}
      {showError && !isDisabled && (
        <div className="rdx-input-field-helper">{errorText}</div>
      )}
    </div>
  )
}

Textarea.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  isRequired: PropTypes.bool,
  errorText: PropTypes.node,
  maxCharacters: PropTypes.number,
}

Textarea.defaultProps = {
  value: '',
}

export default withTranslations(Textarea)
