import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { globalMessagesPropType } from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import ChipBasic from '../../ui/chips/ChipBasic'
import Paragraph from '../texts/Paragraph'
import PreformattedText from '../texts/PreformattedText'
import { mapServiceTypeToBriefingMandatoryFieldI18n } from '../../services/orders/business'
import { SERVICE_TYPES } from '../../services/orders/business'
import moment from 'moment'
import { renderDateTimeValue } from '../../app/utils'

const OrderModalReviewOrder = ({
  acceptanceDate,
  reviewDeliveryDate,
  deliveryDate,
  hasPredelivery,
  isExpress,
  briefingText,

  i18n,
  i18nDate,
  i18nTime,
  globalMessages,
  isRequestPending,

  handleAcceptOrderReview,
  handleAcceptWithChangesOrderReview,
  handleRejectOrderReview,

  service,
}) => {
  return (
    <div className="order-modal">
      <ModalSimpleContent
        tripleActions
        action1Text={i18n('action:process-rejection')}
        buttonHierarchy1="tertiary"
        buttonVariant1="default"
        action1={handleRejectOrderReview}
        action2Text={i18n('action:propose-changes')}
        buttonHierarchy2="tertiary"
        buttonVariant2="default"
        action2={handleAcceptWithChangesOrderReview}
        action3Text={i18n('action:accept')}
        buttonHierarchy3="secondary"
        buttonVariant3="default"
        isButton3Loading={isRequestPending}
        action3={handleAcceptOrderReview}
        isButtonDisabled={isRequestPending}
      >
        <ModalHeader
          title={i18n('action:accept-order')}
          tagline={i18n('order-modal:order-accept-tagline')}
        />
        {!isExpress ? (
          <>
            <ChipBasic
              title={i18n('noun:acceptance-date')}
              subtitle={i18n('description:acceptance-date-media')}
              text={renderDateTimeValue(
                i18nDate,
                i18nTime,
                moment(acceptanceDate),
              )}
            />
            {hasPredelivery ? (
              <ChipBasic
                title={i18n('noun:submission-review-date')}
                subtitle={i18n('description:submission-review-date-media')}
                text={renderDateTimeValue(
                  i18nDate,
                  i18nTime,
                  moment(reviewDeliveryDate),
                )}
              />
            ) : null}
            <ChipBasic
              title={i18n('noun:delivery-date')}
              subtitle={i18n('description:delivery-date-media')}
              text={renderDateTimeValue(
                i18nDate,
                i18nTime,
                moment(deliveryDate),
              )}
            />
          </>
        ) : null}
        {briefingText && (
          <Paragraph
            title={i18n(mapServiceTypeToBriefingMandatoryFieldI18n[service])}
          >
            <PreformattedText text={briefingText} />
          </Paragraph>
        )}
        {globalMessages && (
          <WarningMessage
            text={<FormattedFormErrors errors={globalMessages} />}
          />
        )}
      </ModalSimpleContent>
    </div>
  )
}

const FORM_VALUES_PROPTYPES = {
  comments: PropTypes.string,
}

OrderModalReviewOrder.propTypes = {
  briefingText: PropTypes.string,
  acceptanceDate: PropTypes.string,
  approvalDate: PropTypes.string,
  deliveryDate: PropTypes.string,
  reviewDeliveryDate: PropTypes.string,
  reviewDate: PropTypes.string,
  hasPredelivery: PropTypes.bool,
  isExpress: PropTypes.bool,

  isRequestPending: PropTypes.bool.isRequired,

  handleAcceptOrderReview: PropTypes.func,
  handleAcceptWithChangesOrderReview: PropTypes.func,
  handleRejectOrderReview: PropTypes.func,

  // withTranslations
  i18n: PropTypes.func.isRequired,
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
  service: PropTypes.oneOf(Object.values(SERVICE_TYPES)),
}

export default withTranslations(OrderModalReviewOrder)
