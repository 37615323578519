import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import DashboardPanel from './DashboardPanel'
import CampaignCard from '../cards/CampaignCard'
import CardPlaceholder from '../cards/CardPlaceholder'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  ROUTE_BRAND_CAMPAIGNS,
  ROUTE_BRAND_CAMPAIGNS_BY_ID,
  ROUTE_BRAND_RESOURCES_SEARCH,
} from '../../app/common/routes'
import Spinner from '../loaders/Spinner'
import { MediaQueryLap, MediaQueryPalm } from '../shared/MediaQueries'
import Slider from 'react-slick'

const MAX_CAMPAIGN_CARDS = 4

const CampaignCards = ({ campaigns, hasEmptyPlaceholders, i18n }) => {
  const campaignCards = !!campaigns
    ? campaigns.slice(0, MAX_CAMPAIGN_CARDS).map(campaign => (
        <CampaignCard
          key={campaign.id}
          linkUrl={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
            campaignId: campaign.id,
          })}
          {...campaign}
        />
      ))
    : []

  const emptyPlaceholders = hasEmptyPlaceholders
    ? Array(MAX_CAMPAIGN_CARDS - 1)
        .fill()
        .map((_, i) => <CardPlaceholder key={`empty-placeholder-${i}`} />)
    : []

  const allCards = [
    ...campaignCards,
    <CardPlaceholder
      key="cta-placeholder"
      linkUrl={ROUTE_BRAND_RESOURCES_SEARCH.linkTo()}
      actionText={i18n('dashboard:cta-campaign')}
    />,
    ...emptyPlaceholders,
  ]

  return allCards.slice(0, MAX_CAMPAIGN_CARDS)
}

CampaignCards.propTypes = {
  campaigns: PropTypes.arrayOf(CoobisPropTypes.campaign),
}

const SLIDER_SETTINGS = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const CampaignCardsMobile = ({ campaigns, i18n }) => {
  return (
    <Slider {...SLIDER_SETTINGS}>
      {// We call the inner component as a function, not as a component,
      // because otherwise react-slick thinks CampaignCards itself is a slide
      CampaignCards({ campaigns, i18n })}
    </Slider>
  )
}

CampaignCardsMobile.propTypes = {
  campaigns: PropTypes.arrayOf(CoobisPropTypes.campaign),
}

const CampaignsPanel = ({ campaigns, i18n, isLoading }) => {
  return (
    <DashboardPanel
      title={i18n('noun:campaigns')}
      linkText={i18n('dashboard:go-to-campaigns')}
      linkUrl={ROUTE_BRAND_CAMPAIGNS.linkTo()}
      size="two-thirds"
    >
      <div className="campaigns-panel-content">
        {isLoading ? (
          <>
            <CardPlaceholder>
              <Spinner size={60} />
            </CardPlaceholder>
            <MediaQueryLap>
              <CardPlaceholder>
                <Spinner size={60} />
              </CardPlaceholder>
              <CardPlaceholder>
                <Spinner size={60} />
              </CardPlaceholder>
              <CardPlaceholder>
                <Spinner size={60} />
              </CardPlaceholder>
            </MediaQueryLap>
          </>
        ) : (
          <>
            <MediaQueryPalm>
              <CampaignCardsMobile campaigns={campaigns} i18n={i18n} />
            </MediaQueryPalm>
            <MediaQueryLap>
              <CampaignCards
                hasEmptyPlaceholders
                campaigns={campaigns}
                i18n={i18n}
              />
            </MediaQueryLap>
          </>
        )}
      </div>
    </DashboardPanel>
  )
}

CampaignsPanel.defaultProps = {}

CampaignsPanel.propTypes = {
  campaigns: PropTypes.arrayOf(CoobisPropTypes.campaign),
  isLoading: PropTypes.bool,
}

export default withTranslations(CampaignsPanel)
