import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalPortal from './ModalPortal'

const CLASS_IS_MODAL_OPEN = 'is-modal-open'

// This renders a modal into the modalRootId and prevents scrolling the webpage
const ModalPortalPreventScroll = ({ modalRootId, children }) => {
  useEffect(() => {
    if (document.documentElement.classList.contains(CLASS_IS_MODAL_OPEN)) {
      return
    }

    document.documentElement.classList.add(CLASS_IS_MODAL_OPEN)

    return () => {
      document.documentElement.classList.remove(CLASS_IS_MODAL_OPEN)
    }
  }, [])

  return <ModalPortal modalRootId={modalRootId}>{children}</ModalPortal>
}

ModalPortalPreventScroll.propTypes = {
  modalRootId: PropTypes.string,
}

export default ModalPortalPreventScroll
