import React from 'react'
import PropTypes from 'prop-types'

const BarData = ({ label, data, barColor }) => {
  const width = `${data}%`
  return (
    <div className="bar-data">
      <div className="bar-data-label">
        {label} {data}/100
      </div>
      <div className="bar-data-bar">
        <div className="bar-data-bar-wrapper">
          <span style={{ width: width, background: barColor }} />
        </div>
      </div>
    </div>
  )
}

BarData.defaultProps = {
  barColor: '#3e17b3',
}

BarData.propTypes = {
  label: PropTypes.string,
  data: PropTypes.number,
  barColor: PropTypes.string,
}

export default BarData
