import * as actionTypes from './action-types'

export const fetchBrand = () => ({
  type: actionTypes.FETCH_BRAND,
})

export const editBrandData = data => ({
  type: actionTypes.EDIT_BRAND_DATA,
  payload: { data },
})

export const editBrandBillingData = data => ({
  type: actionTypes.EDIT_BRAND_BILLING_DATA,
  payload: { data },
})

export const registerBrand = data => ({
  type: actionTypes.REGISTER_BRAND,
  payload: { data },
})

export const fetchBrandStatus = () => ({
  type: actionTypes.FETCH_BRAND_STATUS,
})

export const periodicFetchBrandStatus = () => ({
  type: actionTypes.PERIODIC_FETCH_BRAND_STATUS,
})
