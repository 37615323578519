import SearchContainer from '../../ui/search/SearchContainer'
import { withRouter } from 'react-router-dom'
import {
  compose,
  withProps,
  branch,
  renderComponent,
  withState,
  lifecycle,
  renderNothing,
  withHandlers,
} from 'recompose'
import withResultsCount from './withResultsCount'
import withCurrentSearch from './withCurrentSearch'
import { FILTERS } from './filterUtils'
import { connect as connectSearch } from '@appbaseio/reactivesearch/lib/utils'
import { connect } from 'react-redux'
import Error500Message from '../../ui/global-message/Error500Message'
import { logout } from '../../services/auth'
import { fetchSearchExport } from '../../services/search'
import { SEARCH_EXPORT_ENDPOINT } from '../../services/search/api'
import { withIsVIPBrand } from '../../services/brand/hocs'

const clearFilters = (location, history) => () => {
  const params = new URLSearchParams(window.location.search)

  for (const filter of Object.keys(FILTERS)) {
    params.delete(filter)
  }

  history.replace(`${location.pathname}?${params.toString()}`, location.state)
}

const withSearchError = compose(
  withState('has401Error', 'setHas401Error', false),
  withState('hasGenericError', 'setHasGenericError', false),

  connectSearch((state, ownProps) => {
    if (ownProps.has401Error || ownProps.hasGenericError) {
      return {}
    }

    const errors = Object.values(state.error)

    const any401Error = errors.some(x => x.status === 401)
    const anyGenericError = errors.length > 0

    return { any401Error, anyGenericError }
  }, {}),

  connect(
    null,
    { logout, fetchSearchExport },
  ),

  lifecycle({
    componentDidUpdate() {
      if (this.props.any401Error) {
        this.props.logout()
        this.props.setHas401Error(true)
      } else if (this.props.anyGenericError) {
        this.props.setHasGenericError(true)
      }
    },
  }),

  branch(({ has401Error }) => has401Error, renderNothing),
  branch(
    ({ hasGenericError }) => hasGenericError,
    renderComponent(Error500Message),
  ),
)

const enhance = compose(
  withRouter,
  withSearchError,
  withResultsCount,
  withCurrentSearch,
  withIsVIPBrand,
  withProps(({ resultsCount, location, history, isVIPBrand }) => ({
    results: resultsCount,
    searchExportUrl: isVIPBrand ? SEARCH_EXPORT_ENDPOINT : undefined,
    onClearFilters: clearFilters(location, history),
  })),
  withHandlers({
    handleSearchExportClick: props => e => {
      e.preventDefault()
      props.fetchSearchExport(props.currentSearch)
    },
  }),
)

export default enhance(SearchContainer)
