import { compose, withProps, withState, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { UPLOAD, DELETE } from '../../services/photo/action-types'
import { uploadPhoto, deletePhoto } from '../../services/photo'
import ProfilePictureForm from '../../ui/profile/ProfilePictureForm'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { getScope } from '../../services/auth'
import { getPhotoURL } from '../../services/photo/selectors'
import withHasRequestError from '../../hocs/withHasRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withRequestError from '../../hocs/withRequestError'

const enhance = compose(
  connect(
    state => ({
      scope: getScope(state),
      photoURL: getPhotoURL(state),
    }),
    { uploadPhoto, deletePhoto },
  ),

  // Show spinner when upload and when delete
  withIsRequestPending(UPLOAD, 'isUploadingPhoto'),
  withIsRequestPending(DELETE, 'isDeletingPhoto'),
  withHasRequestError(UPLOAD, 'hasErrorUploadingPhoto'),
  withHasRequestError(DELETE, 'hasErrorDeletingPhoto'),
  withRequestError(UPLOAD, 'errorUploadingPhoto'),
  withDeleteRequestOnUnmount(UPLOAD),
  withDeleteRequestOnUnmount(DELETE),

  // To show fallback when photo errors out
  withState('erroredURL', 'setErroredURL', false),
  withState('loadedURL', 'setLoadedURL', false),

  withProps(({ loadedURL, erroredURL, photoURL }) => ({
    isLoaded: loadedURL === photoURL,
    photoErrored: erroredURL === photoURL,
  })),

  withHandlers({
    handleUpload: props => ev => {
      if (ev.target.files.length > 0) {
        props.uploadPhoto(props.scope, ev.target.files[0])
        // Need this in order to upload without reload the page
        ev.target.value = ''
      }
    },

    handleDelete: props => ev => {
      props.deletePhoto(props.scope)
    },
  }),
)

export default enhance(ProfilePictureForm)
