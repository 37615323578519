import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../avatar/Avatar'
import previewBarImage from './images/instagram-icons-bar.png'
import previewFooterImage from './images/instagram-footer.png'
import { ReactComponent as Camera } from '../../styles/icons/cam.svg'

const PreviewBoxInstagram = ({
  previewImage,
  text,
  userName,
  avatar,
  isVideo,
}) => {
  return (
    <div className="preview-box instagram">
      <div className="preview-header">
        <Avatar image={avatar} />
        <div className="user-info">
          <p className="user-name">{userName}</p>
        </div>
      </div>
      <div className="preview-body">
        <div className="preview-image-wrapper">
          {isVideo && <Camera className="icon-camera" />}
          <img className="preview-image" src={previewImage} alt="preview" />
        </div>
        <img
          className="instagram-icons-image"
          src={previewBarImage}
          alt="instagram icons"
        />
        <p className="preview-description">{text}</p>
      </div>
      <div className="preview-footer">
        <img className="image" src={previewFooterImage} alt="preview" />
      </div>
    </div>
  )
}

PreviewBoxInstagram.propTypes = {
  text: PropTypes.string,
  userName: PropTypes.string,
  previewImage: PropTypes.string,
  avatar: PropTypes.string,
  isVideo: PropTypes.bool,
}

PreviewBoxInstagram.defaultProps = {
  userName: 'User name',
  userNick: '@user_name',
}

export default PreviewBoxInstagram
