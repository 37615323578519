import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import Chip from '@redradix/components.chip'

const ChipAnchor = ({ subtitle, text, title, url, isInline, isSelected }) => {
  const classes = cx('chip', {
    'is-inline': isInline,
    'is-selected': isSelected,
  })
  return (
    <div className={classes}>
      {(title || subtitle) && (
        <div className="chip-header">
          {title && <p className="chip-title">{title}</p>}
          {subtitle && <p className="chip-subtitle">{subtitle}</p>}
        </div>
      )}
      <Link className="chip-anchor" to={url} title={text}>
        <Chip className="chip-basic">{text}</Chip>
      </Link>
    </div>
  )
}

ChipAnchor.propTypes = {
  subtitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  isInline: PropTypes.bool,
  isSelected: PropTypes.bool,
}

export default ChipAnchor
