import { DOMAIN, HASH_KEY_CURRENT_SEARCH } from './constants'
import * as actionTypes from './action-types'

import { set } from 'reduken/hash'

export const saveCurrentSearch = query => {
  return set(DOMAIN, HASH_KEY_CURRENT_SEARCH, query)
}

export const fetchSearchExport = search => ({
  type: actionTypes.FETCH_SEARCH_EXPORT,
  payload: { search },
})

export const fetchCartExport = cartItems => ({
  type: actionTypes.FETCH_CART_EXPORT,
  payload: { cartItems },
})
