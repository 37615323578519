import React from 'react'
import imgCoobisLogo from './coobis-logo.png'

const HeaderLogo = () => {
  return (
    <header className="global-header">
      <div className="wrapper">
        <div className="global-header-content">
          <img src={imgCoobisLogo} alt="Coobis logo" width="auto" />
        </div>
      </div>
    </header>
  )
}

export default HeaderLogo
