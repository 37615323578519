import { useCallback } from 'react'

const useNumberFormat = (formatOptions, i18nNumber) => {
  const format = useCallback(
    value => {
      if (formatOptions.style === 'percent') {
        return i18nNumber(Number(value) / 100, formatOptions)
      }

      return i18nNumber(Number(value), formatOptions)
    },
    [formatOptions, i18nNumber],
  )

  // Copied from https://stackoverflow.com/a/45309230
  const removeFormatting = useCallback(
    val => {
      const example = i18nNumber('1.1')
      const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g')
      const cleaned = val.replace(cleanPattern, '')
      const normalized = cleaned.replace(example.charAt(1), '.')

      return parseFloat(normalized)
    },
    [i18nNumber],
  )

  return { format, removeFormatting }
}

export default useNumberFormat
