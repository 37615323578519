import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as AlertTriangle } from '../../styles/icons/alert-triangle.svg'
import { ReactComponent as FillAlertTriangle } from '../../styles/icons/fill-alert-triangle.svg'
import { ReactComponent as Checkmark } from '../../styles/icons/checkmark.svg'
import { ReactComponent as FillCheckmark } from '../../styles/icons/fill-checkmark.svg'
import { ReactComponent as Phone } from '../../styles/icons/phone.svg'

const IconIndicator = ({ type }) => {
  const classes = cx('icon-indicator', {
    [`is-type-${type}`]: type,
  })

  return (
    <div className={classes}>
      {(function() {
        switch (type) {
          case 'warning':
            return <AlertTriangle className="icon" />
          case 'fill-warning':
            return <FillAlertTriangle className="icon" />
          case 'success':
            return <Checkmark className="icon" />
          case 'fill-success':
            return <FillCheckmark className="icon" />
          case 'help':
            return <Phone className="icon" />
          default:
            return null
        }
      })()}
    </div>
  )
}

IconIndicator.propTypes = {
  type: PropTypes.oneOf([
    'warning',
    'success',
    'fill-success',
    'fill-warning',
    'help',
  ]).isRequired,
}

export default IconIndicator
