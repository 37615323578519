import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import withTranslations from '../../../hocs/withTranslations'
import WelcomeDashboardWrapper from '../WelcomeDashboardWrapper'
import MediaOrdersPanel from './MediaOrdersPanel'
import AffiliatesPanel from './AffiliatesPanel'
import OrdersClosedPanel from './OrdersClosedPanel'
import { isNilOrEmpty } from '../../../app/utils'
import Tour from '../../tour/Tour'
import { getMediaSteps } from '../../../app/common/tourSteps'
import { TOUR_WELCOME_MEDIA } from '../../../services/tour/constants'

const MediaDashboard = ({
  username,
  i18n,
  currentOrders,
  closedOrders,
  hasClosedOrders,
  closedCurrentPage,
  closedTotalPages,
  closedOnPageClick,
  onSearchChange,
  searchValue,
  currentSortValue,
  closedSortValue,
  onCurrentSortChange,
  onClosedSortChange,
  onCurrentActionClick,
  isWelcomeMediaTourViewed,
  markTourAsViewed,
}) => {
  return (
    <WelcomeDashboardWrapper
      title={i18n('welcome-dashboard:message', { username })}
    >
      {!isWelcomeMediaTourViewed ? (
        <Tour
          steps={getMediaSteps(i18n)}
          markTourAsViewed={markTourAsViewed}
          tourName={TOUR_WELCOME_MEDIA}
        />
      ) : null}
      <MediaOrdersPanel
        orders={!isNilOrEmpty(currentOrders) ? currentOrders : null}
        sortValue={currentSortValue}
        onSortChange={onCurrentSortChange}
        onActionClick={onCurrentActionClick}
      />
      {isNilOrEmpty(currentOrders) ? <AffiliatesPanel /> : null}
      {hasClosedOrders ? (
        <OrdersClosedPanel
          orders={closedOrders}
          currentPage={closedCurrentPage}
          totalPages={closedTotalPages}
          onPageClick={closedOnPageClick}
          onSearchChange={onSearchChange}
          searchValue={searchValue}
          sortValue={closedSortValue}
          onSortChange={onClosedSortChange}
        />
      ) : null}
    </WelcomeDashboardWrapper>
  )
}

MediaDashboard.propTypes = {
  username: PropTypes.string,
  currentOrders: PropTypes.arrayOf(CoobisPropTypes.currentOrderRow),
  closedOrders: PropTypes.arrayOf(CoobisPropTypes.closedOrderRow),
  hasClosedOrders: PropTypes.bool.isRequired,
  closedCurrentPage: PropTypes.number,
  closedTotalPages: PropTypes.number,
  closedOnPageClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  currentSortValue: PropTypes.string,
  closedSortValue: PropTypes.string,
  onCurrentSortChange: PropTypes.func.isRequired,
  onClosedSortChange: PropTypes.func.isRequired,
  onCurrentActionClick: PropTypes.func.isRequired,
  isWelcomeMediaTourViewed: PropTypes.bool,
  markTourAsViewed: PropTypes.func,
}

export default withTranslations(MediaDashboard)
