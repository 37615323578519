import React from 'react'
import Button from '@redradix/components.button'
import Radio from '@redradix/components.radio'
import Textarea from '../form-elements/Textarea'
import PropTypes from 'prop-types'
import IconIndicator from '../indicators/IconIndicator'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const AccountDeleteForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  isRequestSucceeded,
  reasonOptions,
  isTextareaOpen,
}) => {
  return (
    <form className="delete-account-form" onSubmit={handleSubmit}>
      <p className="form-title">{i18n('profile:delete-form-title')}</p>
      <p className="form-description">
        {i18n('profile:delete-form-description')}
      </p>
      {reasonOptions.map(({ label, value, isChecked }) => (
        <Radio
          name="reason"
          checked={isChecked}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          isDisabled={isRequestPending || isRequestSucceeded}
          key={value}
        >
          {label}
        </Radio>
      ))}

      {validationMessages.reason && (
        <WarningMessage
          text={<FormattedFormErrors errors={validationMessages.reason} />}
        />
      )}

      {isTextareaOpen && (
        <Textarea
          name="text"
          placeholder={i18n('profile:delete-form-placeholder')}
          value={values.text}
          onBlur={handleBlur}
          onChange={handleChange}
          isDisabled={isRequestPending || isRequestSucceeded}
        />
      )}

      {validationMessages.text && (
        <WarningMessage
          text={<FormattedFormErrors errors={validationMessages.text} />}
        />
      )}

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="button-container">
        {isRequestSucceeded ? (
          <IconIndicator type={'success'} />
        ) : (
          <Button
            type="submit"
            hierarchy="secondary"
            isLoading={isRequestPending}
          >
            {i18n('action:send')}
          </Button>
        )}
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  reason: PropTypes.string.isRequired,
  text: PropTypes.string,
}

AccountDeleteForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  isTextareaOpen: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(AccountDeleteForm)
