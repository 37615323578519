import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const ChartLegend = ({ items, isVertical, isRowed }) => {
  const classes = cx('chart-legend', {
    'is-vertical': isVertical,
    'is-rowed': isRowed,
  })
  return (
    <ul className={classes}>
      {items.map((item, i) => (
        <li className="chart-legend-item" key={i}>
          {item.value && (isVertical || isRowed) && (
            <span className="chart-legend-value">{item.value}</span>
          )}
          <span className="chart-legend-label-wrapper">
            <span
              className="chart-legend-indicator"
              style={{ backgroundColor: `${item.color}` }}
            ></span>
            <span className="chart-legend-label" title={item.label}>
              {item.label}
            </span>
          </span>
        </li>
      ))}
    </ul>
  )
}

ChartLegend.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  isVertical: PropTypes.bool,
  isRowed: PropTypes.bool,
}

export default ChartLegend
