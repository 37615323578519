import { compose, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import {
  fetchSocialProfiles,
  getSocialProfiles,
} from '../../services/resources'
import { FETCH_SOCIAL_PROFILES } from '../../services/resources/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withHasRequestError from '../../hocs/withHasRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRouteServerError from '../../hocs/withRouteServerError'

const withProfileSelectModal = socialService =>
  compose(
    withRouter,

    connect(
      (state, ownProps) => {
        const fetchResourceId = !!ownProps.lastTemporaryResourceId
          ? ownProps.lastTemporaryResourceId
          : ownProps.resourceId

        const socialProfiles = getSocialProfiles(
          socialService,
          fetchResourceId,
          state,
        )

        const isSynchronizeModalOpen =
          !!ownProps.hasJustConnected &&
          !!socialProfiles &&
          socialProfiles.length > 0

        return {
          socialProfiles,
          isSynchronizeModalOpen,
        }
      },
      (dispatch, ownProps) => ({
        fetchSocialProfiles: fetchResourceId =>
          dispatch(fetchSocialProfiles(fetchResourceId, socialService)),
      }),
    ),

    withIsRequestPending(FETCH_SOCIAL_PROFILES, 'isRequestingSocialProfiles'),
    withHasRequestError(FETCH_SOCIAL_PROFILES, 'hasSocialProfilesRequestError'),
    withIsRequestSucceeded(
      FETCH_SOCIAL_PROFILES,
      'isSocialProfilesRequestSucceeded',
    ),
    withDeleteRequestOnUnmount(FETCH_SOCIAL_PROFILES),
    withRouteServerError(FETCH_SOCIAL_PROFILES),

    withHandlers({
      maybeFetchProfiles: props => _ => {
        const {
          hasJustConnected,
          resourceId,
          temporaryResourceId,
          isRequestingSocialProfiles,
          isSocialProfilesRequestSucceeded,
          hasSocialProfilesRequestError,
        } = props

        const fetchResourceId = !!temporaryResourceId
          ? temporaryResourceId
          : resourceId

        if (
          !!hasJustConnected &&
          !!fetchResourceId &&
          !isRequestingSocialProfiles &&
          !isSocialProfilesRequestSucceeded &&
          !hasSocialProfilesRequestError
        ) {
          props.fetchSocialProfiles(fetchResourceId)
        }
      },

      onSynchronizeModalClose: props => _ => {
        const query = queryString.parse(props.location.search)

        delete query.temporaryResourceId
        delete query.hasJustConnected

        const queryWithoutTemporaryResourceId = queryString.stringify(query)

        props.history.replace({
          ...props.location,
          search: queryWithoutTemporaryResourceId,
        })
      },
    }),

    lifecycle({
      componentDidMount() {
        this.props.maybeFetchProfiles()
      },

      componentDidUpdate(prevProps) {
        this.props.maybeFetchProfiles()
      },
    }),
  )

export default withProfileSelectModal
