import React from 'react'
import PropTypes from 'prop-types'
import { NumberPagination } from '@redradix/components.pagination'
import AnchorLinkButton from '../texts/AnchorLinkButton'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import DashboardPanel from '../dashboard/DashboardPanel'
import CampaignListCard from '../cards/CampaignListCard'
import SearchInput from '../form-elements/SearchInput'
import { ROUTE_BRAND_CAMPAIGNS_BY_ID } from '../../app/common/routes'
import EmptyResult from '../search/EmptyResult'
import { isNilOrEmpty } from '../../app/utils'

const CampaignsList = ({
  campaigns,
  onCreateClick,
  searchValue,
  onSearchChange,
  currentPage,
  totalPages,
  onPageClick,
  i18n,
}) => {
  return (
    <DashboardPanel autoHeight title={i18n('noun:campaigns')}>
      <div className="campaigns-list-section">
        <div className="campaigns-list-section-actions">
          <SearchInput
            id="searchInput"
            name="searchInput"
            onChange={onSearchChange}
            value={searchValue}
            placeholder={i18n('action:search')}
          />
          <AnchorLinkButton
            type="button"
            onClick={onCreateClick}
            text={i18n('action:create-campaign')}
          />
        </div>
        {!isNilOrEmpty(campaigns) ? (
          <>
            {campaigns.map(campaign => (
              <CampaignListCard
                key={campaign.id}
                name={campaign.name}
                reach={campaign.reach}
                engagement={campaign.engagement}
                orders={campaign.orders}
                pendingOrders={campaign.ordersPending}
                linkUrl={ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
                  campaignId: campaign.id,
                })}
              />
            ))}
            {totalPages > 1 ? (
              <NumberPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageClick={onPageClick}
              />
            ) : null}
          </>
        ) : (
          <EmptyResult />
        )}
      </div>
    </DashboardPanel>
  )
}

CampaignsList.propTypes = {
  campaigns: PropTypes.arrayOf(CoobisPropTypes.campaign),
  onCreateCampaignClick: PropTypes.func,
  searchValue: PropTypes.string,
  onSearchChange: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPageClick: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(CampaignsList)
