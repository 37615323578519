import React from 'react'
import PropTypes from 'prop-types'
import { NumberPagination } from '@redradix/components.pagination'
import withTranslations from '../../../hocs/withTranslations'
import DashboardPanel from '../DashboardPanel'
import OrdersClosedCard from '../../cards/OrdersClosedCard'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import LoadingCard from '../../cards/LoadingCard'
import SearchInput from '../../form-elements/SearchInput'

const OrdersPanel = ({
  orders,
  isLoading,
  i18n,
  currentPage,
  totalPages,
  onPageClick,
  onSearchChange,
  searchValue,
  sortValue,
  onSortChange,
}) => {
  return (
    <DashboardPanel title={i18n('noun:closed-orders')} autoHeight>
      <div className="orders-closed-panel">
        <SearchInput
          id="searchInput"
          name="searchInput"
          onChange={onSearchChange}
          value={searchValue}
          placeholder={i18n('action:search')}
        />
        {isLoading ? (
          <LoadingCard />
        ) : (
          <OrdersClosedCard
            orders={orders}
            sortValue={sortValue}
            onSortChange={onSortChange}
          />
        )}
        {totalPages > 1 ? (
          <NumberPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageClick={onPageClick}
          />
        ) : null}
      </div>
    </DashboardPanel>
  )
}

OrdersPanel.defaultProps = {}

OrdersPanel.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.closedOrderRow),
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPageClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  sortValue: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
}

export default withTranslations(OrdersPanel)
