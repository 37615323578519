import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { withState, withHandlers, compose } from 'recompose'
import { Link } from 'react-router-dom'
import withTranslations from '../../hocs/withTranslations'
import cx from 'classnames'
import NavItem from './NavItem'
import LogoutItem from './LogoutItem'
import AddFunds from '../add-funds/AddFunds'
import Avatar from '../avatar/Avatar'
import {
  ROUTE_BRAND_PERSONAL_DATA,
  ROUTE_MEDIA_PERSONAL_DATA,
} from '../../app/common/routes'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'
import useWindowSize from '../../hooks/useWindowSize'
import { AuthScope } from '../../services/auth/business'

const MainNavigation = ({
  navItems,
  current,
  isOpen,
  toggleMenu,
  balance,
  onLogout,
  userAvatar,
  userName,
  userProfile,
  i18n,
  isPublic,
  scope,
  isMedia,
  onBalanceAction,
}) => {
  const classes = cx('main-navigation', { 'is-open': !!isOpen })
  const hasMobileMenu = window.matchMedia('(max-width: 60.5625em)').matches

  const handleLogout = useCallback(() => {
    onLogout && onLogout()
    toggleMenu && toggleMenu()
  }, [onLogout, toggleMenu])

  useLockBodyScroll(hasMobileMenu && isOpen)

  const { innerHeight } = useWindowSize()

  const styles = hasMobileMenu ? { height: `${innerHeight}px` } : undefined

  return (
    <div className={classes} style={styles}>
      <button className="main-navigation-toggle" onClick={toggleMenu}>
        <svg
          className="menuicon"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
        >
          <title>Toggle Menu</title>
          <g>
            <line
              className="menuicon__bar"
              x1="13"
              y1="16.5"
              x2="37"
              y2="16.5"
            />
            <line
              className="menuicon__bar"
              x1="13"
              y1="24.5"
              x2="37"
              y2="24.5"
            />
            <line
              className="menuicon__bar"
              x1="13"
              y1="24.5"
              x2="37"
              y2="24.5"
            />
            <line
              className="menuicon__bar"
              x1="13"
              y1="32.5"
              x2="37"
              y2="32.5"
            />
          </g>
        </svg>
      </button>

      <div className="splash" />
      <nav className="main-navigation-list">
        {navItems && (
          <ul className="main-navigation-menu">
            {navItems.map((item, i) =>
              current === item.i18nKey ? (
                <NavItem
                  key={i}
                  url={item.url}
                  i18nKey={item.i18nKey}
                  isMobile={item.isMobile}
                  onClick={toggleMenu}
                  isActive
                />
              ) : (
                <NavItem
                  key={i}
                  url={item.url}
                  i18nKey={item.i18nKey}
                  isMobile={item.isMobile}
                  onClick={toggleMenu}
                />
              ),
            )}
          </ul>
        )}
      </nav>
      <AddFunds
        balance={balance}
        isMedia={isMedia}
        onClick={() => {
          toggleMenu()
          onBalanceAction()
        }}
      />
      {!isPublic && (
        <div className="main-navigation-user">
          <Link
            className="main-navigation-user-info"
            to={
              scope === AuthScope.BRAND
                ? ROUTE_BRAND_PERSONAL_DATA.linkTo()
                : scope === AuthScope.MEDIA
                ? ROUTE_MEDIA_PERSONAL_DATA.linkTo()
                : undefined
            }
            onClick={toggleMenu}
          >
            <Avatar image={userAvatar} />
            <div className="user-dropdown-info">
              <span className="user-dropdown-name">{userName}</span>
              <span className="user-dropdown-profile">{i18n(userProfile)}</span>
            </div>
          </Link>
          <LogoutItem onLogout={handleLogout} />
        </div>
      )}
    </div>
  )
}

MainNavigation.defaultProps = {
  isPublic: false,
}

MainNavigation.propTypes = {
  navItems: PropTypes.arrayOf(CoobisPropTypes.navItem),
  current: PropTypes.string,
  balance: CoobisPropTypes.money,
  onLogout: PropTypes.func,
  userName: PropTypes.string,
  userAvatar: PropTypes.string,
  userProfile: PropTypes.string,
  isPublic: PropTypes.bool,
  isMedia: PropTypes.bool,
  onBalanceAction: PropTypes.func,
}

const decorator = compose(
  withState('isOpen', 'setOpenMenu', false),
  withHandlers({
    toggleMenu: props => () => {
      props.setOpenMenu(!props.isOpen)
    },
  }),
)

export default decorator(withTranslations(MainNavigation))
