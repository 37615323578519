import { compose, withPropsOnChange } from 'recompose'
import withCountryWidget from './withCountryWidget'

const withCountryChart = ({ dataPath }) =>
  compose(
    withCountryWidget({ dataPath }),
    withPropsOnChange(['countryWidgetData'], ({ countryWidgetData }) => {
      return { countryData: countryWidgetData }
    }),
  )

export default withCountryChart
