import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { safelyRender } from '../../../src/app/utils'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import withTranslations from '../../hocs/withTranslations'
import { ReactComponent as IconDefault } from '../../styles/icons/ellipsis.svg'

const ButtonDropdown = ({ content, i18n, renderIcon }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const classes = cx('button-dropdown', {
    'is-collapsed': isCollapsed,
  })

  // Toggle collapsed state when clicking inside
  const toggleState = useCallback(
    () => setIsCollapsed(isCollapsed => !isCollapsed),
    [],
  )

  // Close dropdown when clicking outside
  const dropdownRef = useRef()
  const collapse = useCallback(() => setIsCollapsed(true), [])
  useOnClickOutside(dropdownRef, collapse)

  return (
    <div className={classes} ref={dropdownRef}>
      <button className="button-dropdown-button" onClick={toggleState}>
        {safelyRender(renderIcon, { className: 'button-dropdown-icon' }) || (
          <IconDefault className="button-dropdown-icon" />
        )}
      </button>
      <div className="button-dropdown-content">
        {content.map((btn, i) => {
          const btnState = btn.isWarning ? 'is-warning' : ''
          const btnDisabled = btn.isDisabled ? 'is-disabled' : ''
          return (
            <button
              key={i}
              className={`button-dropdown-button ${btnState} ${btnDisabled}`}
              onClick={e => {
                btn.action()
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {safelyRender(btn.buttonIcon, {
                className: 'icon',
              })}
              {i18n(btn.actionText)}
            </button>
          )
        })}
      </div>
    </div>
  )
}

ButtonDropdown.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  // withTranslations
  i18n: PropTypes.func.isRequired,
  renderIcon: PropTypes.func,
}

export default withTranslations(ButtonDropdown)
