import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import {
  getRequest,
  putRequest,
  postRequest,
  postFormRequest,
} from '../api-call'
import { parseResponseErrors } from '../error-handling'
import { MAP_GLOBAL_DISCOUNT_COUPON } from '../checkout/api'
import { i18nErrorKeys } from '../../app/common/validation'

const WITHDRAW_FUNDS_ENDPOINT = `${envConfig.usersBackendUrl}/media/wallet/withdrawal`

const UPLOAD_INVOICE_ENDPOINT = `${envConfig.usersBackendUrl}/media/wallet/invoices`

const walletEndpoint = scope => `${envConfig.usersBackendUrl}/${scope}/wallet`

const walletMovementsEndpoint = (scope, page) =>
  `${envConfig.usersBackendUrl}/${scope}/wallet/movements?page=${page}`

const RECHARGE_FUNDS_ENDPOINT = `${envConfig.usersBackendUrl}/brand/wallet/recharge`

export function* fetchBalance(scope) {
  const response = yield call(getRequest, walletEndpoint(scope), {
    parseJSON: true,
  })
  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchWalletMovements(scope, page) {
  const response = yield call(
    getRequest,
    walletMovementsEndpoint(scope, page),
    {
      parseJSON: true,
    },
  )
  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* validateRechargeDiscountCoupon(amount, coupon) {
  const response = yield call(
    putRequest,
    RECHARGE_FUNDS_ENDPOINT,
    { amount, couponCode: coupon },
    {
      parseJSON: true,
    },
  )
  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_DISCOUNT_COUPON,
  })
  return parsedResponse
}

export function* fetchWithdrawFundsData() {
  const response = yield call(getRequest, WITHDRAW_FUNDS_ENDPOINT, {
    parseJSON: true,
  })
  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export const MAP_GLOBAL_WITHDRAW_FUNDS = {
  WithdrawalAmountMismatchException: values => ({
    key: i18nErrorKeys.WITHDRAW_WRONG_AMOUNT,
    values,
  }),
  MediaInvoiceAlreadyExistsException: values => ({
    key: i18nErrorKeys.WITHDRAW_INVOICE_ALREADY_EXISTS,
    values: {
      identityDocument: values.value.identityDocument,
      invoiceNumber: values.value.invoiceNumber,
    },
  }),
  MediaInvoiceFinalAmountMismatchException: values => ({
    key: i18nErrorKeys.WITHDRAW_WRONG_FINAL_AMOUNT,
    values,
  }),
  MediaInvoiceTaxesAmountMismatchException: values => ({
    key: i18nErrorKeys.WITHDRAW_WRONG_TAXES_AMOUNT,
    values,
  }),
}

export function* withdrawFunds(data) {
  const response = yield call(postRequest, WITHDRAW_FUNDS_ENDPOINT, data, {
    parseJSON: true,
  })
  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_WITHDRAW_FUNDS,
  })

  return parsedResponse
}

export function* uploadInvoiceFile(invoice) {
  const response = yield call(
    postFormRequest,
    UPLOAD_INVOICE_ENDPOINT,
    { invoice },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

const MAP_GLOBAL_RECHARGE_FUNDS = {
  PaymentAmountException: values => ({
    key: i18nErrorKeys.PAYMENT_WRONG_AMOUNT,
    values,
  }),
}

export function* rechargeFunds(data) {
  const response = yield call(postRequest, RECHARGE_FUNDS_ENDPOINT, data, {
    parseJSON: true,
  })
  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: { ...MAP_GLOBAL_DISCOUNT_COUPON, MAP_GLOBAL_RECHARGE_FUNDS },
  })

  return parsedResponse
}
