import React from 'react'
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose'
import * as Yup from 'yup'
import BriefingWebFormView from '../../ui/checkout/BriefingWebForm'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import {
  BRIEFING_WEB_GOALS,
  briefingRequestType,
  BRIEFINGS_MAX_FILE_SIZE,
} from '../../services/checkout/business'
import { i18nValidation, validateMaxFileSize } from '../common/validation'
import withTranslations from '../../hocs/withTranslations'
import { SERVICE_TYPES } from '../../services/orders/business'
import { submitWebBriefing } from '../../services/checkout'
import { connect } from 'react-redux'
import { withCart } from '../../services/checkout/hocs'
import withDownloadBriefingFileLink from './withDownloadBriefingFileLink'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import Modal from '../../ui/modals/Modal'
import ListInformationModal from '../../ui/modals/ListInformationModal'
import withValuesCache from './withValuesCache'
import { isNilOrEmpty } from '../utils'

const BriefingWebForm = props => {
  const {
    i18n,
    isNotAcceptingFollowLinksModalOpen,
    closeNotAcceptingFollowLinksModal,
    servicesNotAcceptingFollowLinks,
  } = props

  return (
    <>
      <BriefingWebFormView {...props} />

      {isNotAcceptingFollowLinksModalOpen && (
        <Modal onClose={closeNotAcceptingFollowLinksModal}>
          <ListInformationModal
            title={i18n('noun:media-information')}
            description1={i18n('message:refuse-follow-link', {
              webs: servicesNotAcceptingFollowLinks.length,
            })}
            listItems={servicesNotAcceptingFollowLinks}
            description2={i18n('message:continue-no-follow-link')}
            onClose={closeNotAcceptingFollowLinksModal}
            onContinue={props.submitForm}
            isRequesting={props.isRequestPending}
          />
        </Modal>
      )}
    </>
  )
}

const linkSchema = Yup.object().shape({
  url: Yup.string().url(i18nValidation.invalidURL),
  text: Yup.string(),
  isFollow: Yup.boolean(),
})

const validationSchema = Yup.object().shape({
  goal: Yup.string()
    .oneOf(Object.values(BRIEFING_WEB_GOALS), i18nValidation.required)
    .required(i18nValidation.required),
  description: Yup.string().required(i18nValidation.required),
  file: Yup.mixed()
    .nullable()
    .test(validateMaxFileSize(BRIEFINGS_MAX_FILE_SIZE)),
  links: Yup.array(linkSchema),
  tips: Yup.array(Yup.string().nullable()).nullable(),
  html: Yup.object().shape({
    titleTag: Yup.string(),
    descriptionTag: Yup.string(),
    slug: Yup.string().url(i18nValidation.invalidURL),
  }),
})

const withCheckDoFollowLinks = compose(
  withPropsOnChange(['cart'], ({ cart }) => ({
    servicesNotAcceptingFollowLinks:
      cart && cart.items
        ? cart.items
            .filter(
              item =>
                item.service === SERVICE_TYPES.WEB_POST &&
                !item.acceptsFollowLinks,
            )
            .map(item => item.name)
        : [],
  })),

  withStateHandlers(
    {
      isNotAcceptingFollowLinksModalOpen: undefined,
    },
    {
      openNotAcceptingFollowLinksModal: _ => _ => ({
        isNotAcceptingFollowLinksModalOpen: true,
      }),
      closeNotAcceptingFollowLinksModal: _ => _ => ({
        isNotAcceptingFollowLinksModalOpen: false,
      }),
    },
  ),

  withHandlers({
    // Wrap Formik's handleSubmit to check for DoFollow links modal
    handleSubmit: props => e => {
      e.preventDefault()
      e.stopPropagation()

      if (!props.isValid) {
        props.handleSubmit(e) // still call handleSubmit to show error messages
        return
      }

      const hasDoFollowLinks =
        props.values.links.filter(link => link.isFollow).length > 0

      if (
        props.servicesNotAcceptingFollowLinks.length > 0 &&
        hasDoFollowLinks &&
        props.values.goal === BRIEFING_WEB_GOALS.SEO
      ) {
        props.openNotAcceptingFollowLinksModal()
      } else {
        props.handleSubmit(e)
      }
    },
  }),
)

const enhance = compose(
  withTranslations,
  withCart,

  withPropsOnChange(['i18n'], ({ i18n }) => ({
    title: i18n('service-type:web-post-without-words'),
  })),

  connect(
    null,
    { submitBriefing: submitWebBriefing },
  ),
  withDownloadBriefingFileLink(SERVICE_TYPES.WEB_POST),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ cart: { briefings }, cachedValues }) => {
      const actualData = briefings[SERVICE_TYPES.WEB_POST]
      const actualFile =
        actualData && Array.isArray(actualData.files)
          ? actualData.files[0]
          : null

      const { titleTag, descriptionTag, slug } = actualData
        ? actualData.html
        : {}

      return {
        goal:
          actualData && actualData.goal
            ? actualData.goal
            : BRIEFING_WEB_GOALS.BRANDING,
        description:
          actualData && actualData.description ? actualData.description : '',
        file: actualFile,
        links:
          actualData && actualData.links && actualData.links.length > 0
            ? actualData.links
            : [
                {
                  url: '',
                  text: '',
                  isFollow: true,
                },
              ],
        tips: actualData && actualData.tips ? actualData.tips : [],
        html: {
          titleTag: titleTag || '',
          descriptionTag: descriptionTag || '',
          slug: slug || '',
        },
        ...cachedValues,
      }
    },

    validationSchema,

    fileFields: ['file'],

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const formatedValues = {
        ...values,
        // Remove empty links
        links: values.links.filter(link => !isNilOrEmpty(link.url)),
        // Remove empty tips
        tips: values.tips.filter(tip => !isNilOrEmpty(tip)),
        // Send null instead of ""
        html: {
          titleTag: !!values.html.titleTag ? values.html.titleTag : null,
          descriptionTag: !!values.html.descriptionTag
            ? values.html.descriptionTag
            : null,
          slug: !!values.html.slug ? values.html.slug : null,
        },
      }

      // Branding goal forces links to no-follow
      const valuesToSend =
        formatedValues.goal === BRIEFING_WEB_GOALS.BRANDING
          ? {
              ...formatedValues,
              links: formatedValues.links.map(link => ({
                ...link,
                isFollow: false,
              })),
            }
          : formatedValues

      props.submitBriefing(valuesToSend)
    },
  }),
  withValuesCache(SERVICE_TYPES.WEB_POST),

  withCheckDoFollowLinks,

  // Add link button
  withHandlers({
    onAddLink: props => _ => {
      props.setFieldValue(
        'links',
        [
          ...props.values.links,
          {
            url: '',
            text: '',
            isFollow: true,
          },
        ],
        true,
      )
    },
  }),

  // Make links isFollow boolean values
  withHandlers({
    handleChange: props => (...args) => {
      const fieldName = args[0].target.name

      if (fieldName.startsWith('links[') && fieldName.endsWith('].isFollow')) {
        return props.handleChange(
          {
            target: {
              name: fieldName,
              value: args[0].target.value === 'true' ? true : false,
            },
          },
          ...args.slice(1),
        )
      }

      return props.handleChange(...args)
    },
  }),

  withIsRequestPending(briefingRequestType(SERVICE_TYPES.WEB_POST)),
  withIsRequestSucceeded(briefingRequestType(SERVICE_TYPES.WEB_POST)),
  withRequestError(briefingRequestType(SERVICE_TYPES.WEB_POST)),
  withDeleteRequestOnUnmount(briefingRequestType(SERVICE_TYPES.WEB_POST)),
  withFormErrors(['goal', 'description', 'file', 'links', 'tips', 'html']),
)

export default enhance(BriefingWebForm)
