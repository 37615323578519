import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest, putRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

const fetchAlertsEndpoint = language =>
  `${envConfig.usersBackendUrl}/alerts/unread?lang=${language}`
const markAlertAsReadEndpoint = alertId =>
  `${envConfig.usersBackendUrl}/alerts/${alertId}/read`

export function* fetchAlerts(lang) {
  const response = yield call(getRequest, fetchAlertsEndpoint(lang), {
    parseJSON: true,
  })
  const parsedResonse = parseResponseErrors(response)
  return parsedResonse
}

export function* markAlertAsRead(alertId) {
  const response = yield call(putRequest, markAlertAsReadEndpoint(alertId), {
    parseJSON: true,
  })
  const parsedResonse = parseResponseErrors(response)
  return parsedResonse
}
