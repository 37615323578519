import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailTwitterAll from '../../ui/campaigns/views/CampaignDetailTwitterAll'
import withPublicationsTable from './withPublicationsTable'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  tweets: stats.kpis.totalOrders,
  profiles: stats.kpis.totalResources,
  impressions: stats.kpis.totalImpressions,
  engagements: stats.kpis.totalEngagements,
}))

const withTwitterPublicationsTable = withPublicationsTable(
  orderStatsDetail => ({
    engagements: orderStatsDetail.kpis.engagements,
    impressions: orderStatsDetail.kpis.impressions,
    likes: orderStatsDetail.kpis.likes,
  }),
)

const enhance = compose(
  withKPIs,
  withTwitterPublicationsTable,
  withExportReportContents(),
)

export default enhance(CampaignDetailTwitterAll)
