import { remove } from 'reduken/hash'
import * as actionTypes from './action-types'
import { DOMAIN, HASH_KEY_NEEDS_SCOPE } from './constants'

export const login = (username, password, recaptcha, recaptchaAction) => ({
  type: actionTypes.LOGIN,
  payload: { username, password, recaptcha, recaptchaAction },
})

export const loginWithScope = (
  username,
  password,
  scope,
  recaptcha,
  recaptchaAction,
) => ({
  type: actionTypes.LOGIN_WITH_SCOPE,
  payload: { username, password, scope, recaptcha, recaptchaAction },
})

export const logout = () => ({
  type: actionTypes.LOGOUT,
})

export const checkToken = () => ({
  type: actionTypes.CHECK_TOKEN,
})

export const refreshToken = () => ({
  type: actionTypes.REFRESH_TOKEN,
})

export const newToken = (token, scope, expiresIn) => ({
  type: actionTypes.NEW_TOKEN,
  payload: { token, scope, expiresIn },
})

export const deleteToken = () => ({
  type: actionTypes.DELETE_TOKEN,
})

export const logoutComplete = () => ({
  type: actionTypes.LOGOUT_COMPLETE,
})

export const clearNeedsScope = () => remove(DOMAIN, HASH_KEY_NEEDS_SCOPE)

export const changePassword = (
  scope,
  oldPassword,
  password,
  confirmPassword,
) => ({
  type: actionTypes.CHANGE_PASSWORD,
  payload: {
    scope,
    oldPassword,
    password,
    confirmPassword,
  },
})

export const deleteUser = (scope, reason) => ({
  type: actionTypes.DELETE_USER,
  payload: {
    scope,
    reason,
  },
})
