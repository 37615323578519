import React from 'react'
import PropTypes from 'prop-types'
import OrderClosedHeadRow from './OrderClosedHeadRow'
import OrderClosedRow from './OrderClosedRow'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { ROUTE_MEDIA_ORDER_BY_ID } from '../../app/common/routes'

const OrdersClosedTable = ({ orders, sortValue, onSortChange }) => {
  return (
    <div className="orders-closed-table">
      <OrderClosedHeadRow value={sortValue} onChange={onSortChange} />
      <div className="orders-closed-table-content">
        {orders.map(order => (
          <OrderClosedRow
            key={order.id}
            status={order.status}
            type={order.type}
            brand={order.brandName}
            resource={order.resource.name}
            price={order.price}
            date={order.statusDate}
            linkUrl={ROUTE_MEDIA_ORDER_BY_ID.linkTo({ orderId: order.id })}
          />
        ))}
      </div>
    </div>
  )
}

OrdersClosedTable.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.closedOrderRow),
  sortValue: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
}

export default OrdersClosedTable
