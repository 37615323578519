import ResourceListPanel from '../../ui/resources/ResourceListPanel'
import { compose, lifecycle, withHandlers, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  fetchResources,
  getResources,
  getResourcesMeta,
  deleteResource,
  updateResourceStats,
} from '../../services/resources'
import {
  FETCH_RESOURCES,
  DELETE_RESOURCE,
  UPDATE_RESOURCE_STATS,
} from '../../services/resources/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withAreRequestsWithIdsPending from '../../hocs/withAreRequestsWithIdsPending'
import {
  ROUTE_MEDIA_CREATE_RESOURCES_DISTRIBUTOR,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID,
  ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA,
  prettifyConstant,
} from '../common/routes'
import withRouteServerError from '../../hocs/withRouteServerError'
import {
  RESOURCE_TYPES,
  RESOURCES_PER_PAGE,
} from '../../services/resources/constants'
import withIsRequestWithIdPending from '../../hocs/withIsRequestWithIdPending'
import withIsRequestWithIdSucceeded from '../../hocs/withIsRequestWithIdSucceeded'
import withRequestWithIdError from '../../hocs/withRequestWithIdError'
import withFormErrors from '../../hocs/withFormErrors'

const enhance = compose(
  connect(
    state => ({
      resources: getResources(state),
      resourcesMeta: getResourcesMeta(state),
    }),
    {
      fetchResources,
      deleteResource,
      updateResourceStats,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchResources(1, RESOURCES_PER_PAGE)
    },
  }),

  withStateHandlers(
    {
      isDeleteResourceModalOpen: false,
      // We need all resource data for i18n values (see view)
      resourceToDelete: null,
      // Needed for request control
      resourceIdToDelete: null,
    },
    {
      openDeleteResourceModal: _ => resource => ({
        isDeleteResourceModalOpen: true,
        resourceToDelete: resource,
        resourceIdToDelete: resource.id,
      }),
      closeDeleteResourceModal: _ => () => ({
        isDeleteResourceModalOpen: false,
        resourceToDelete: null,
        resourceIdToDelete: null,
      }),
    },
  ),

  withHandlers({
    redirectToCreateResource: ({ history }) => () =>
      history.push(ROUTE_MEDIA_CREATE_RESOURCES_DISTRIBUTOR.linkTo()),

    onEditResource: ({ history }) => resource => () => {
      const url =
        resource.type === RESOURCE_TYPES.WEB
          ? ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID.linkTo({
              id: resource.id,
            })
          : ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA.linkTo({
              id: resource.id,
              socialNetwork: prettifyConstant(resource.type),
            })

      history.push(url)
    },

    onDeleteResource: ({ resourceIdToDelete, deleteResource }) => () => {
      deleteResource(resourceIdToDelete)
    },
    onUpdateResourceStats: ({ updateResourceStats }) => id => e => {
      updateResourceStats(id)
    },
  }),

  // Delete resource control
  withIsRequestWithIdPending(DELETE_RESOURCE, {
    idProp: 'resourceIdToDelete',
    outProp: 'isDeletingResource',
  }),
  withIsRequestWithIdSucceeded(DELETE_RESOURCE, {
    idProp: 'resourceIdToDelete',
    outProp: 'isResourceDeleted',
  }),
  withRequestWithIdError(DELETE_RESOURCE, {
    idProp: 'resourceIdToDelete',
    outProp: 'deleteResourceErrors',
  }),
  withAreRequestsWithIdsPending(UPDATE_RESOURCE_STATS, {
    idProp: 'resources',
    idPropSelector: resource => resource.id,
    outProp: 'areResourceStatsUpdating',
  }),
  withFormErrors([], {
    serverErrorsProp: 'deleteResourceErrors',
    globalMessagesProp: 'deleteResourceGlobalErrors',
  }),

  lifecycle({
    componentDidUpdate(prev) {
      const { isResourceDeleted, closeDeleteResourceModal } = this.props

      if (prev.isResourceDeleted === false && isResourceDeleted === true) {
        closeDeleteResourceModal()
      }
    },
  }),

  withIsRequestPending(FETCH_RESOURCES, 'isLoading'),
  withRouteServerError(FETCH_RESOURCES),
)

export default enhance(ResourceListPanel)
