import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailWebBrandingAll from '../../ui/campaigns/views/CampaignDetailWebBrandingAll'
import withTranslations from '../../hocs/withTranslations'
import { withTranslatedCountries } from '../../services/config/hocs'
import withTimeSeriesGraphDynamicLegend from './withTimeSeriesGraphDynamicLegend'
import withPublicationsTable from './withPublicationsTable'
import withAgeAudience from './withAgeAudience'
import withGenderAudience from './withGenderAudience'
import withCountryChart from '../common/withCountryChart'
import withCountryWidget from '../common/withCountryWidget'
import withCityWidget from '../common/withCityWidget'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  webPublications: stats.kpis.totalOrders,
  totalVisits: stats.kpis.totalVisits,
  totalUniqueVisitors: stats.kpis.totalUniqueVisitors,
  engagements: stats.kpis.totalEngagements,
  averagePageTime: stats.kpis.timeOnPageAverage,
  averageScrollPercentage: stats.kpis.scrollingPercentAverage,
  averageBounceRate: stats.kpis.bouncePercentAverage,
}))

const withEngagementsGraph = withTimeSeriesGraphDynamicLegend(
  stats => stats.orderStatsDetail,
  orderStatsDetail => ({
    id: orderStatsDetail.orderId,
    date: orderStatsDetail.deliveryDate,
    name: orderStatsDetail.resource.name,
    items: orderStatsDetail.series.engagements,
  }),
  {
    dataOutProp: 'engagementData',
    legendOptionsOutProp: 'engagementLegendOptions',
    onLegendDeleteOutProp: 'onEngagementLegendDelete',
    onLegendOptionChangeOutProp: 'onEngagementLegendOptionChange',
  },
)

const withWebBrandingPublicationsTable = withPublicationsTable(
  orderStatsDetail => ({
    visits: orderStatsDetail.kpis.visits,
    engagements: orderStatsDetail.kpis.engagements,
    uniqueUsers: orderStatsDetail.kpis.uniqueVisitors,
  }),
)

const enhance = compose(
  withTranslations,
  withTranslatedCountries(),

  withKPIs,
  withAgeAudience,
  withGenderAudience,
  withCountryChart({
    dataPath: ['stats', 'stats', 'countryDemographics'],
  }),
  withCountryWidget({
    dataPath: ['stats', 'stats', 'countryDemographics'],
  }),
  withCityWidget({
    dataPath: ['stats', 'stats', 'cityDemographics'],
  }),
  withEngagementsGraph,
  withWebBrandingPublicationsTable,
  withExportReportContents(({ engagementData }) => ({
    engagementsGraphIds: engagementData.map(({ id }) => id),
  })),
)

export default enhance(CampaignDetailWebBrandingAll)
