import { useState, useCallback } from 'react'
import { isNilOrEmpty } from '../utils'
import * as R from 'ramda'
import {
  mapServiceTypeToI18n,
  mapServiceTypeToResourceType,
} from '../../services/orders/business'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import { SERVICE_TYPES } from '../../services/orders/business'

const getServiceI18N = serviceType => {
  const known = mapServiceTypeToI18n[serviceType]

  if (known) return known

  return `service-type:${serviceType}`
}

const hasServiceBroadcast = serviceType =>
  mapServiceTypeToResourceType[serviceType] === RESOURCE_TYPES.WEB

const hasServiceWords = serviceType =>
  mapServiceTypeToResourceType[serviceType] === RESOURCE_TYPES.WEB

const getDefaultService = R.pipe(
  R.filter(service => !service.isDisabled),
  R.reduce(
    R.minBy(
      service =>
        (service.service === SERVICE_TYPES.WEB_ONLY_POST ? 1000 : 1) *
        service.finalPrice.EUR,
    ),
    { finalPrice: { EUR: Infinity, USD: Infinity } },
  ),
  R.pick(['service', 'words']),
)

export const useResourceServices = ({
  item,
  addItem,
  i18n,
  service,
  resourceStatus,
  updateResourceStatus,
}) => {
  const prices = item.prices.map(price => ({
    ...price,
    label: i18n(getServiceI18N(price.service), { words: price.words }),
    value: `${price.service}-${price.words}`,
    isDisabled: false,
  }))

  const services = service
    ? prices.filter(price => price.service === service.service)
    : prices

  // Services selection
  const [selectedService, setSelectedService] = useState(() => {
    const defaultService = getDefaultService(services)
    const selectedWordsFromState = R.path(
      [defaultService.service, 'words'],
      resourceStatus,
    )
    if (selectedWordsFromState) {
      defaultService.words = selectedWordsFromState
    }
    return defaultService
  })

  const handleServiceChange = useCallback(
    value => {
      setSelectedService({
        service: value.service,
        words: value.words,
      })
      updateResourceStatus(item.id, { [value.service]: { words: value.words } })
    },
    [setSelectedService, updateResourceStatus, item],
  )

  const actualSelectedService = services.find(
    service =>
      !service.isDisabled &&
      service.service === selectedService.service &&
      service.words === selectedService.words,
  )

  // If inbetween loadings, sometimes results aren't updated yet so, even if
  // they should be filtered out, it still tries to render and fails because
  // no service actually matches the filtered-out prices
  if (actualSelectedService === undefined) {
    // Set service to default service (if any) to accommodate for changing price
    // filters that might still apply after results arrive
    const defaultService = getDefaultService(services)

    if (!isNilOrEmpty(defaultService)) {
      setSelectedService(defaultService)
    }
  }

  const isBroadcastCheckedFromState = R.pathEq(
    [selectedService.service, 'isBroadcastChecked'],
    true,
    resourceStatus,
  )

  // Broadcast checkbox
  const [isBroadcastChecked, setIsBroadcastChecked] = useState(
    isBroadcastCheckedFromState,
  )

  const handleBroadcastChange = useCallback(
    e => {
      setIsBroadcastChecked(e.target.checked)
      updateResourceStatus(item.id, {
        [selectedService.service]: { isBroadcastChecked: e.target.checked },
      })
    },
    [setIsBroadcastChecked, updateResourceStatus, item, selectedService],
  )

  // Add to cart
  const handleCartClick = useCallback(
    _ => {
      addItem(
        item.id,
        selectedService.service,
        hasServiceWords(selectedService.service)
          ? selectedService.words
          : undefined,
        hasServiceBroadcast(selectedService.service)
          ? isBroadcastChecked
          : undefined,
      )
    },
    [
      addItem,
      item.id,
      selectedService.service,
      selectedService.words,
      isBroadcastChecked,
    ],
  )

  const handleCartClickService = useCallback(
    _ => {
      addItem(
        item.id,
        actualSelectedService.service,
        hasServiceWords(actualSelectedService.service)
          ? actualSelectedService.words
          : undefined,
        hasServiceBroadcast(actualSelectedService.service)
          ? isBroadcastChecked
          : undefined,
      )
    },
    [
      addItem,
      item.id,
      actualSelectedService.service,
      actualSelectedService.words,
      isBroadcastChecked,
    ],
  )

  return {
    services,
    actualSelectedService,
    handleCartClick,
    handleServiceChange,
    isBroadcastChecked,
    handleBroadcastChange,
    handleCartClickService,
  }
}
