import withEffect from '../../hocs/withEffect'
import { compose, withProps, lifecycle } from 'recompose'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import { briefingRequestType } from '../../services/checkout/business'
import { omit } from 'ramda'

// Preserve form values if tab changes
// See CheckoutBriefing.jsx

const isSuccededProp = 'withValuesCache/briefingSubmitSucceeded'

const withValuesCache = serviceType =>
  compose(
    withIsRequestSucceeded(briefingRequestType(serviceType), isSuccededProp),

    withEffect(
      ({ values, onValuesChange }) => {
        onValuesChange(values)
      },
      ({ values, onValuesChange }) => [values, onValuesChange],
    ),

    // Erase cached vales after successfully submit a briefing
    lifecycle({
      componentDidUpdate(prev) {
        const { [isSuccededProp]: isSucceded, onValuesChange } = this.props

        if (!prev[isSuccededProp] && isSucceded) {
          onValuesChange({})
        }
      },
    }),

    withProps(omit(isSuccededProp)),
  )

export default withValuesCache
