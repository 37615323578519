import React from 'react'
import PropTypes from 'prop-types'

const OrderDetailContent = ({ children }) => {
  return <div className="order-detail-content">{children}</div>
}

OrderDetailContent.propTypes = {
  children: PropTypes.node,
}

export default OrderDetailContent
