import OrderModalSendDeliverySocialNetwork from '../../ui/order-modals/OrderModalSendDeliverySocialNetwork'
import { compose } from 'recompose'
import * as Yup from 'yup'
import * as R from 'ramda'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import {
  i18nValidation,
  getPostUrlRegexpForServiceType,
} from '../common/validation'
import { isNilOrEmpty } from '../utils'
import { connect } from 'react-redux'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { SEND_ORDER_DELIVERY_SOCIAL } from '../../services/orders/action-types'
import { sendOrderDeliverySocial } from '../../services/orders'

const validationSchema = props => {
  const serviceType = R.path(['orderData', 'product', 'service'], props)
  const regexp = getPostUrlRegexpForServiceType(serviceType)

  return Yup.object().shape({
    url: Yup.string()
      .matches(regexp, i18nValidation.invalidURL)
      .required(i18nValidation.required),
    comments: Yup.string(),
  })
}

const enhance = compose(
  connect(
    null,
    { sendOrderDeliverySocial },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      url: '',
      comments: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSubmit = R.filter(R.complement(isNilOrEmpty), values)
      props.sendOrderDeliverySocial(props.orderId, props.isFix, valuesToSubmit)
    },
  }),

  withIsRequestPending(SEND_ORDER_DELIVERY_SOCIAL),
  withIsRequestSucceeded(SEND_ORDER_DELIVERY_SOCIAL),
  withRequestError(SEND_ORDER_DELIVERY_SOCIAL),
  withDeleteRequestOnUnmount(SEND_ORDER_DELIVERY_SOCIAL),

  withFormErrors(['url', 'comments']),
)

export default enhance(OrderModalSendDeliverySocialNetwork)
