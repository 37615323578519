import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import CampaignPreviewTiktok from '../social-previews/CampaignPreviewTiktok'
import { isNilOrEmpty } from '../../../app/utils'

const CampaignDetailTiktokPublication = ({
  previews,
  i18n,
  // Metrics values
  views,
  likes,
  engagements,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        {views > 0 && (
          <AnalyticsKpi
            iconType="eye"
            value={views}
            label={i18n('metrics:total-views')}
            isTruncated
          />
        )}
        {likes > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={likes}
            label={i18n('metrics:likes')}
            isTruncated
          />
        )}
        {engagements > 0 && (
          <AnalyticsKpi
            iconType="click"
            value={engagements}
            label={i18n('metrics:engagements')}
            isTruncated
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewTiktok items={previews} />
      ) : null}
    </>
  )
}

CampaignDetailTiktokPublication.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  views: PropTypes.number,
  likes: PropTypes.number,
  engagements: PropTypes.number,
}

export default withTranslations(CampaignDetailTiktokPublication)
