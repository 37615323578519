import { compose } from 'recompose'
import * as Yup from 'yup'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation, validateMaxFileSize } from '../common/validation'
import {
  splitByWhitespace,
  prepareMultiValueInputToSave,
  trimUnnecessarySpaces,
  formatTimeObject,
} from '../utils'
import {
  briefingRequestType,
  BRIEFINGS_MAX_FILE_SIZE,
} from '../../services/checkout/business'
import withDownloadBriefingFileLink from './withDownloadBriefingFileLink'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withValuesCache from './withValuesCache'

const durationSchema = Yup.object().shape({
  minutes: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  seconds: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .min(0, i18nValidation.min)
    .max(59, i18nValidation.max),
})

const calculateLength = ({ minutes = 0, seconds = 0 }) =>
  Number(seconds) + Number(minutes) * 60

const withYoutubeForm = ({
  serviceType,

  minDuration,
  maxDuration,
} = {}) => {
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(i18nValidation.required),
    titleWords: Yup.string(),
    descriptionWords: Yup.string(),
    hashtags: Yup.string(),
    url: Yup.string().url(i18nValidation.invalidURL),
    minVideoLength: durationSchema
      .test(
        'lower than maxVideoLength',
        i18nValidation.lowerThanMaxDuration,
        function(value) {
          const { maxVideoLength } = this.parent
          return calculateLength(value) <= calculateLength(maxVideoLength)
        },
      )
      .test(
        'higher than minDuration',
        values =>
          i18nValidation.min({ ...values, min: formatTimeObject(minDuration) }),
        function(value) {
          if (!minDuration) return true

          return calculateLength(value) >= calculateLength(minDuration)
        },
      ),
    maxVideoLength: durationSchema
      .test(
        'higher than minVideoLength',
        i18nValidation.higherThanMinDuration,
        function(value) {
          const { minVideoLength } = this.parent
          return calculateLength(value) >= calculateLength(minVideoLength)
        },
      )
      .test(
        'higher than minDuration',
        values =>
          i18nValidation.min({ ...values, min: formatTimeObject(minDuration) }),
        function(value) {
          if (!minDuration) return true

          return calculateLength(value) >= calculateLength(minDuration)
        },
      )
      .test(
        'lower than maxDuration',
        values =>
          i18nValidation.max({ ...values, max: formatTimeObject(maxDuration) }),
        function(value) {
          if (!maxDuration) return true

          return calculateLength(value) <= calculateLength(maxDuration)
        },
      ),
    file: Yup.mixed()
      .nullable()
      .test(validateMaxFileSize(BRIEFINGS_MAX_FILE_SIZE)),
    urlSocialNetworks: Yup.string(),
    isAdvertising: Yup.boolean().required(i18nValidation.required),
  })

  return compose(
    withEnhancedFormik({
      isInitialValid: true,

      enableReinitialize: true,

      mapPropsToValues: ({ cart: { briefings }, cachedValues }) => {
        const actualData = briefings[serviceType]
        const actualTitleWords =
          actualData && actualData.titleWords
            ? actualData.titleWords.filter(Boolean).join(', ')
            : ''
        const actualDescriptionWords =
          actualData && actualData.descriptionWords
            ? actualData.descriptionWords.filter(Boolean).join(', ')
            : ''
        const actualHashtags =
          actualData && actualData.hashtags
            ? actualData.hashtags
                .filter(Boolean)
                .map(v => '#' + v)
                .join(' ')
            : ''
        const actualFile =
          actualData && Array.isArray(actualData.files)
            ? actualData.files[0]
            : null

        const actualUrlSocialNetworks =
          actualData && actualData.urlSocialNetworks
            ? actualData.urlSocialNetworks.join('\n')
            : ''

        const minVideoLength =
          actualData && actualData.minVideoLength
            ? {
                minutes: Number(actualData.minVideoLength.minutes),
                seconds: Number(actualData.minVideoLength.seconds),
              }
            : minDuration
            ? minDuration
            : {
                minutes: 0,
                seconds: 0,
              }

        const maxVideoLength =
          actualData && actualData.maxVideoLength
            ? {
                minutes: Number(actualData.maxVideoLength.minutes),
                seconds: Number(actualData.maxVideoLength.seconds),
              }
            : maxDuration
            ? maxDuration
            : {
                minutes: 0,
                seconds: 0,
              }

        return {
          description:
            actualData && actualData.description ? actualData.description : '',
          titleWords: actualTitleWords,
          descriptionWords: actualDescriptionWords,
          hashtags: actualHashtags,
          url: actualData && actualData.url ? actualData.url : '',
          minVideoLength,
          maxVideoLength,
          file: actualFile,
          urlSocialNetworks: actualUrlSocialNetworks,
          isAdvertising:
            actualData && actualData.isAdvertising
              ? actualData.isAdvertising
              : false,
          ...cachedValues,
        }
      },

      validationSchema,

      fileFields: ['file'],

      validateOnBlur: true,
      validateOnChange: true,

      handleSubmit: (values, { props }) => {
        const valuesToSend = {
          ...values,
          titleWords: trimUnnecessarySpaces(values.titleWords),
          descriptionWords: trimUnnecessarySpaces(values.descriptionWords),
          hashtags: prepareMultiValueInputToSave(values.hashtags, '#'),
          urlSocialNetworks: !!values.urlSocialNetworks
            ? splitByWhitespace(values.urlSocialNetworks)
            : [],
        }

        props.submitBriefing(valuesToSend)
      },
    }),
    withValuesCache(serviceType),

    withIsRequestPending(briefingRequestType(serviceType)),
    withIsRequestSucceeded(briefingRequestType(serviceType)),
    withRequestError(briefingRequestType(serviceType)),
    withDeleteRequestOnUnmount(briefingRequestType(serviceType)),
    withFormErrors([
      'description',
      'titleWords',
      'descriptionWords',
      'hashtags',
      'url',
      'minVideoLength',
      'maxVideoLength',
      'file',
      'urlSocialNetworks',
      'isAdvertising',
    ]),

    withDownloadBriefingFileLink(serviceType),
  )
}

export default withYoutubeForm
