import * as actionTypes from './action-types'

export const fetchCampaigns = () => ({
  type: actionTypes.FETCH_CAMPAIGNS,
})

export const createCampaign = name => ({
  type: actionTypes.CREATE_CAMPAIGN,
  payload: { name },
})

export const deleteCampaign = campaignId => ({
  type: actionTypes.DELETE_CAMPAIGN,
  payload: { campaignId },
})

export const renameCampaign = (campaignId, name) => ({
  type: actionTypes.RENAME_CAMPAIGN,
  payload: { campaignId, name },
})

export const fetchCampaignsSummary = () => ({
  type: actionTypes.FETCH_CAMPAIGNS_SUMMARY,
})

export const fetchCampaignSummaryById = id => ({
  type: actionTypes.FETCH_CAMPAIGN_SUMMARY_BY_ID,
  payload: { id },
})

export const periodicFetchCampaignsSummary = () => ({
  type: actionTypes.PERIODIC_FETCH_CAMPAIGNS_SUMMARY,
})

export const cancelPeriodicFetchCampaignsSummary = () => ({
  type: actionTypes.CANCEL_PERIODIC_FETCH_CAMPAIGNS_SUMMARY,
})

export const fetchPublicationPreviews = (orderIds, fetchId) => ({
  type: actionTypes.FETCH_PUBLICATION_PREVIEWS,
  payload: { orderIds, fetchId },
})

// Statistics

export const fetchCampaignResourceTypeStats = (campaignId, resourceType) => ({
  type: actionTypes.FETCH_CAMPAIGN_RESOURCE_TYPE_STATS,
  payload: { campaignId, resourceType },
})

export const fetchCampaignOrderListByType = (campaignId, resourceType) => ({
  type: actionTypes.FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE,
  payload: { campaignId, resourceType },
})

export const fetchCampaignOrderStats = (campaignId, orderId) => ({
  type: actionTypes.FETCH_CAMPAIGN_ORDER_STATS,
  payload: { campaignId, orderId },
})

export const fetchCampaignSEOURLStats = (campaignId, seoUrl) => ({
  type: actionTypes.FETCH_CAMPAIGN_SEO_URL_STATS,
  payload: { campaignId, seoUrl },
})
