import { getFromPath } from 'reduken/hash'

import {
  DOMAIN,
  HASH_KEY_TOKEN,
  HASH_KEY_SCOPE,
  HASH_KEY_NEEDS_SCOPE,
} from './constants'

export const getToken = getFromPath(DOMAIN, HASH_KEY_TOKEN)

export const getScope = getFromPath(DOMAIN, HASH_KEY_SCOPE)

export const needsScope = getFromPath(DOMAIN, HASH_KEY_NEEDS_SCOPE)
