import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CampaignDetailSlider from '../CampaignDetailSlider'
import CampaignDetailSlide from '../CampaignDetailSlide'
import usePermanentScript from '../../../hooks/usePermanentScript'

const CampaignPreviewFacebook = ({ items }) => {
  usePermanentScript(
    'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v5.0',
  )

  useEffect(() => {
    if (window.FB && window.FB.XFBML) {
      window.FB.XFBML.parse()
    }
  }, [items])

  return (
    <CampaignDetailSlider>
      {items.map((item, i) => (
        <CampaignDetailSlide type="facebook" key={i}>
          <div dangerouslySetInnerHTML={{ __html: item }}></div>
        </CampaignDetailSlide>
      ))}
    </CampaignDetailSlider>
  )
}

CampaignPreviewFacebook.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
}

export default CampaignPreviewFacebook
