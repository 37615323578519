import { getFromPath } from 'reduken/hash'
import {
  DOMAIN,
  HASH_KEY_CURRENT_LOCATION,
  HASH_KEY_LAST_LOCATION,
} from './constants'

export const getCurrentLocation = getFromPath(DOMAIN, HASH_KEY_CURRENT_LOCATION)

export const getLastLocation = getFromPath(DOMAIN, HASH_KEY_LAST_LOCATION)
