export const STATISTICS_TABS = {
  GLOBAL: 'GLOBAL',
  WEB_BRANDING: 'WEB_BRANDING',
  WEB_SEO: 'WEB_SEO',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  INSTAGRAM: 'INSTAGRAM',
  YOUTUBE: 'YOUTUBE',
  TWITCH: 'TWITCH',
  TIKTOK: 'TIKTOK',
}

export const STATISTICS_PUBLICATION_SELECTOR_ALL_VALUE = 'ALL_PUBLICATIONS'
