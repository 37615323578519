import { getFromPath } from 'reduken/hash'
import { createSelector } from 'reselect'
import {
  DOMAIN,
  HASH_KEY_INFO,
  HASH_KEY_LANGUAGE,
  LOCALSTORAGE_KEY_LANGUAGE,
} from './constants'
import { findMatchingLocale } from '../../libs/i18n'
import { getScope } from '../auth/selectors'
import { AuthScope } from '../auth/business'
import { getMediaInfo } from '../media/selectors'
import { HASH_KEY_BRAND_INFO, DOMAIN as BRAND_DOMAIN } from '../brand/constants'

export const getMyInfo = getFromPath(DOMAIN, HASH_KEY_INFO)

export const getLanguage = createSelector(
  getScope,
  getFromPath(BRAND_DOMAIN, HASH_KEY_BRAND_INFO), // not using selector to avoid circular dependency
  getMediaInfo,
  getFromPath(DOMAIN, HASH_KEY_LANGUAGE),
  (scope, brandInfo, mediaInfo, reduxLanguage) => {
    const userData =
      scope === AuthScope.BRAND
        ? brandInfo
        : scope === AuthScope.MEDIA
        ? mediaInfo
        : undefined

    if (userData && userData.language) {
      return userData.language
    }

    return (
      reduxLanguage ||
      localStorage[LOCALSTORAGE_KEY_LANGUAGE] ||
      findMatchingLocale()
    )
  },
)
