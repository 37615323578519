import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import InputWithSubtitle from './../form-elements/InputWithSubtitle'
import InputFile from './../form-elements/InputFile'
import Textarea from './../form-elements/Textarea'
import * as R from 'ramda'
import { SERVICE_TYPES } from '../../services/orders/business'

const ALLOWED_DOCUMENT_EXTENSIONS =
  '.png,.jpg,.jpeg,.img,.gif,.pdf,.doc,.docx,.txt,.mpeg,.avi,.mp4,.flv,.mov'
const ALLOWED_DOCUMENT_EXTENSIONS_TIKTOK = '.mp4,.mov'

const OrderModalSendPredelivery = ({
  fileIsUploaded,
  fileName,
  fileLinkUrl,

  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  serviceType,
  ...props
}) => {
  const isTiktok =
    serviceType === SERVICE_TYPES.TIKTOK_SHORT ||
    serviceType === SERVICE_TYPES.TIKTOK_LONG
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal">
        <ModalSimpleContent
          actionText={i18n('action:send-to-review')}
          isButtonLoading={isRequestPending}
        >
          <ModalHeader
            title={i18n('action:send-to-review')}
            tagline={
              isTiktok
                ? i18n('order-modal:order-review-tagline-tiktok')
                : i18n('order-modal:order-review-tagline')
            }
          />
          <InputFile
            label={i18n('action:attach-content-article')}
            name="file"
            accept={
              isTiktok
                ? ALLOWED_DOCUMENT_EXTENSIONS_TIKTOK
                : ALLOWED_DOCUMENT_EXTENSIONS
            }
            widthBehaviour="full"
            linkText={i18n('action:upload-document')}
            linkUrl={fileLinkUrl}
            value={values.file}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={
              isTiktok
                ? i18n('description:document-type-video')
                : i18n('description:document-type')
            }
            fileName={R.path(['file', 'name'], values)}
            isUploaded={R.path(['file', 'isUploaded'], values)}
            isTouched={touched.file}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.file && (
                <FormattedFormErrors errors={validationMessages.file} />
              )
            }
          />
          <InputWithSubtitle
            type="url"
            name="url"
            widthBehaviour="full"
            inputLabel={i18n('action:add-link-cloud-service')}
            placeholder="Wetransfer, Google Drive, Dropbox..."
            value={values.url}
            onBlur={handleBlur}
            onChange={handleChange}
            isTouched={touched.url}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.url && (
                <FormattedFormErrors errors={validationMessages.url} />
              )
            }
          />
          <Textarea
            name="comments"
            label={i18n('action:add-comment-for-brand')}
            placeholder={i18n('action:write-your-comment-here')}
            onBlur={handleBlur}
            onChange={handleChange}
            errorText={
              validationMessages.comments && (
                <FormattedFormErrors errors={validationMessages.comments} />
              )
            }
            isTouched={touched.comments}
            isDisabled={isRequestPending}
            value={values.comments}
          />
          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  file: PropTypes.any,
  url: PropTypes.string,
  comments: PropTypes.string.isRequired,
}

OrderModalSendPredelivery.propTypes = {
  fileIsUploaded: PropTypes.bool,
  fileName: PropTypes.string,
  fileLinkUrl: PropTypes.string,

  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,

  isRequestPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(OrderModalSendPredelivery)
