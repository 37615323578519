import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import * as R from 'ramda'
import { getCart } from '../../services/checkout'
import { isNilOrEmpty } from '../utils'
import withIsRequestWithIdPending from '../../hocs/withIsRequestWithIdPending'
import { addItem } from '../../services/checkout/actions'
import { ADD_ITEM } from '../../services/checkout/action-types'

const withAddToCart = compose(
  connect(
    // Add item in cart (if found) to props
    (state, ownProps) => {
      const cart = getCart(state)

      if (R.isNil(cart) || isNilOrEmpty(cart.items)) {
        return { inCartItems: [] }
      }
      const itemId = ownProps.item ? ownProps.item.id : null
      return {
        inCartItems: cart.items.filter(item => item.id === itemId),
      }
    },

    // Add item to cart
    {
      addItem,
    },
  ),

  // Add item spinner
  withPropsOnChange(['item'], ({ item }) => {
    const itemId = item ? item.id : null
    return {
      itemId: itemId,
    }
  }),

  withIsRequestWithIdPending(ADD_ITEM, {
    idProp: 'itemId',
    outProp: 'isAddingItem',
  }),
)

export default withAddToCart
