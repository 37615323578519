import React from 'react'
import PropTypes from 'prop-types'

const OrderDetailHeader = ({ heading, hasActions, actionsSlot }) => {
  return (
    <div className="order-detail-header">
      <h1 className="order-detail-heading">{heading}</h1>
      {hasActions && actionsSlot}
    </div>
  )
}

OrderDetailHeader.propTypes = {
  heading: PropTypes.string,
  hasActions: PropTypes.bool,
  actionsSlot: PropTypes.node,
}

export default OrderDetailHeader
