import { useState, useEffect } from 'react'

export default function useOnline() {
  const [isOnline, setOnline] = useState(window.navigator.onLine)

  useEffect(() => {
    const onlineCallback = () => setOnline(true)
    const offlineCallback = () => setOnline(false)

    window.addEventListener('offline', offlineCallback)
    window.addEventListener('online', onlineCallback)

    return () => {
      window.removeEventListener('offline', offlineCallback)
      window.removeEventListener('online', onlineCallback)
    }
  }, [setOnline])

  return isOnline
}
