import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailYoutubePublication from '../../ui/campaigns/views/CampaignDetailYoutubePublication'
import withTranslations from '../../hocs/withTranslations'
import { withTranslatedCountries } from '../../services/config/hocs'
import withAgeAudience from './withAgeAudience'
import withGenderAudience from './withGenderAudience'
import withCountryWidget from '../common/withCountryWidget'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  engagementRate: stats.kpis.engagementRate,
  likes: stats.kpis.likes,
  views: stats.kpis.views,
  comments: stats.kpis.comments,
  averageVisualization: stats.kpis.averagePlayTime,
}))

const enhance = compose(
  withTranslations,
  withTranslatedCountries(),

  withKPIs,
  withAgeAudience,
  withGenderAudience,
  withCountryWidget({
    dataPath: ['stats', 'stats', 'countryDemographics'],
  }),
  withExportReportContents(),
)

export default enhance(CampaignDetailYoutubePublication)
