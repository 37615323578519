import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import { FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE } from '../../services/campaigns/action-types'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'
import {
  getCampaignOrdersListByType,
  fetchCampaignOrderListByType,
} from '../../services/campaigns'
import withIsLoadingWithId from '../../hocs/withIsLoadingWithId'
import withEffect from '../../hocs/withEffect'
import { isNilOrEmpty } from '../utils'
import {
  STATISTICS_PUBLICATION_SELECTOR_ALL_VALUE,
  STATISTICS_TABS,
} from '../../services/campaigns/business'
import withTranslations from '../../hocs/withTranslations'
import {
  ROUTE_BRAND_CAMPAIGNS_ORDER_STATS_BY_ID,
  prettifyConstant,
  ROUTE_BRAND_CAMPAIGNS_BY_ID,
} from '../common/routes'
import { DATE_FORMAT_OPTIONS } from '../../config/formats'

const mapResourceTypeToSelectorAllLabel = {
  [STATISTICS_TABS.GLOBAL]: 'publications-selector:all-global',
  [STATISTICS_TABS.WEB_BRANDING]: 'publications-selector:all-web',
  [STATISTICS_TABS.WEB_SEO]: 'publications-selector:all-web',
  [STATISTICS_TABS.FACEBOOK]: 'publications-selector:all-facebook',
  [STATISTICS_TABS.TWITTER]: 'publications-selector:all-twitter',
  [STATISTICS_TABS.INSTAGRAM]: 'publications-selector:all-instagram',
  [STATISTICS_TABS.YOUTUBE]: 'publications-selector:all-youtube',
  [STATISTICS_TABS.TWITCH]: 'publications-selector:all-twitch',
  [STATISTICS_TABS.TIKTOK]: 'publications-selector:all-tiktok',
}

const withPublicationsSelector = compose(
  withTranslations,
  withRouter,

  withPropsOnChange(
    ['campaignId', 'resourceType'],
    ({ campaignId, resourceType }) => ({
      fetchId: `${campaignId}_${resourceType}`,
    }),
  ),

  withRoute404ErrorWithId(FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE, 'fetchId'),
  withRouteServerErrorWithId(FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE, 'fetchId'),

  connect(
    (state, { campaignId, resourceType }) => {
      return {
        ordersList: getCampaignOrdersListByType(
          campaignId,
          resourceType,
          state,
        ),
      }
    },
    {
      fetchCampaignOrderListByType,
    },
  ),

  withPropsOnChange(
    ['ordersList', 'resourceType', 'i18n', 'i18nDate'],
    ({ ordersList, resourceType, i18n, i18nDate }) => {
      if (isNilOrEmpty(ordersList)) {
        return {}
      }

      return {
        selectorOptions: [
          {
            label: i18n(mapResourceTypeToSelectorAllLabel[resourceType]),
            value: STATISTICS_PUBLICATION_SELECTOR_ALL_VALUE,
          },
          ...ordersList.map(order => ({
            label: `${order.resource.name} - ${i18nDate(
              order.deliveryDate,
              DATE_FORMAT_OPTIONS,
            )}`,
            value: order.orderId,
          })),
        ],
      }
    },
  ),

  withPropsOnChange(
    ['orderId', 'selectorOptions', 'resourceType', 'i18n'],
    ({ orderId, selectorOptions, resourceType, i18n }) => {
      if (R.isNil(selectorOptions)) {
        return {}
      }

      if (R.isNil(orderId)) {
        return {
          selectorValue: selectorOptions[0],
        }
      }

      const selectorValue = selectorOptions.find(
        ({ value }) => value === Number(orderId),
      )

      if (R.isNil(selectorValue)) {
        return {
          selectorValue: selectorOptions[0],
        }
      }

      return { selectorValue }
    },
  ),

  // Move to order detail when selecting an item on publications selector
  withHandlers({
    onSelectorChange: ({ history, campaignId, resourceType }) => ({
      value: orderId,
    }) => {
      orderId === STATISTICS_PUBLICATION_SELECTOR_ALL_VALUE
        ? history.push(
            ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
              campaignId,
              resourceType: prettifyConstant(resourceType),
            }),
          )
        : history.push(
            ROUTE_BRAND_CAMPAIGNS_ORDER_STATS_BY_ID.linkTo({
              campaignId,
              resourceType: prettifyConstant(resourceType),
              orderId,
            }),
          )
    },
  }),

  withEffect(
    ({ fetchCampaignOrderListByType, campaignId, resourceType }) => {
      fetchCampaignOrderListByType(campaignId, resourceType)
    },
    ({ fetchCampaignOrderListByType, campaignId, resourceType }) => [
      fetchCampaignOrderListByType,
      campaignId,
      resourceType,
    ],
  ),

  withIsLoadingWithId(FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE, {
    idProp: 'fetchId',
    outProp: 'isLoadingPublicationsSelector',
  }),
)

export default withPublicationsSelector
