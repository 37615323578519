import { compose, branch, withProps, renderComponent } from 'recompose'
import * as R from 'ramda'
import withRequestError from './withHasRequestError'
import Error404Message from '../ui/global-message/Error404Message'

const OUT_PROP = 'withRoute404Error/requestError'

const withRoute404Error = requestType =>
  compose(
    withRequestError(requestType, OUT_PROP),
    branch(
      ownProps => ownProps[OUT_PROP] && ownProps[OUT_PROP].status === 404,
      renderComponent(Error404Message),
    ),
    withProps(R.dissoc(OUT_PROP)),
  )

export default withRoute404Error
