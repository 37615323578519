import React from 'react'
import PropTypes from 'prop-types'
import Button from '@redradix/components.button'

const DeleteButton = ({ buttonText, onDelete, isLoading, isDisabled }) => {
  return (
    <Button
      className="delete-button"
      type="button"
      hierarchy="tertiary"
      variant="destruct"
      widthBehaviour="adaptative"
      heightBehaviour="adaptative"
      onClick={onDelete}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      <p>{buttonText}</p>
    </Button>
  )
}

DeleteButton.propTypes = {
  buttonText: PropTypes.string,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default DeleteButton
