import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  BRAND_ORDER_ACTIONS,
  MEDIA_ORDER_ACTIONS,
} from '../../services/orders/business'
import {
  openOrderCancelModal,
  openOrderReviewCounterbriefingModal,
  openOrderReviewPredeliveryModal,
  openOrderReviewDeliveryModal,
  openOrderReviewModal,
  openOrderSendPredeliveryModal,
  openOrderFixPredeliveryModal,
  openOrderSendDeliveryModal,
  openOrderFixDeliveryModal,
} from '../../services/orders'
import { ROUTE_MEDIA_ORDER_BY_ID } from './routes'

const withOrderModalActions = compose(
  connect(
    null,
    {
      openOrderCancelModal,
      openOrderReviewCounterbriefingModal,
      openOrderReviewPredeliveryModal,
      openOrderReviewDeliveryModal,
      openOrderReviewModal,
      openOrderSendPredeliveryModal,
      openOrderFixPredeliveryModal,
      openOrderSendDeliveryModal,
      openOrderFixDeliveryModal,
    },
  ),

  withHandlers({
    handleOrderAction: props => (orderId, action) => {
      switch (action) {
        // Brand
        case BRAND_ORDER_ACTIONS.CANCEL: {
          props.openOrderCancelModal(orderId)
          return
        }

        case BRAND_ORDER_ACTIONS.REVIEW_COUNTERBRIEFING: {
          props.openOrderReviewCounterbriefingModal(orderId)
          return
        }

        case BRAND_ORDER_ACTIONS.REVIEW_PREDELIVERY: {
          props.openOrderReviewPredeliveryModal(orderId)
          return
        }

        case BRAND_ORDER_ACTIONS.REVIEW_DELIVERY: {
          props.openOrderReviewDeliveryModal(orderId)
          return
        }

        // Media
        case MEDIA_ORDER_ACTIONS.REVIEW_ORDER: {
          // If we're not or order detail, go to it
          if (!ROUTE_MEDIA_ORDER_BY_ID.regexp.test(window.location.pathname)) {
            props.history.push(ROUTE_MEDIA_ORDER_BY_ID.linkTo({ orderId }))
          }
          // We're on order detail, open review order modal
          else {
            props.openOrderReviewModal(orderId)
          }
          return
        }

        case MEDIA_ORDER_ACTIONS.SEND_PREDELIVERY: {
          props.openOrderSendPredeliveryModal(orderId)
          return
        }

        case MEDIA_ORDER_ACTIONS.FIX_PREDELIVERY: {
          props.openOrderFixPredeliveryModal(orderId)
          return
        }

        case MEDIA_ORDER_ACTIONS.SEND_DELIVERY: {
          props.openOrderSendDeliveryModal(orderId)
          return
        }

        case MEDIA_ORDER_ACTIONS.FIX_DELIVERY: {
          props.openOrderFixDeliveryModal(orderId)
          return
        }

        default: {
          throw new Error(`Unknown action ${action}`)
        }
      }
    },
  }),
)

export default withOrderModalActions
