import { compose, withProps } from 'recompose'
import * as R from 'ramda'
import withIsRequestSucceeded from './withIsRequestSucceeded'
import withHasRequestError from './withHasRequestError'

const IS_SUCCEEDED_PROP = 'withIsLoading/isRequestSucceeded'
const HAS_ERROR_PROP = 'withIsLoading/hasRequestError'

const withIsLoading = (requestType, outProp = 'isLoading') =>
  compose(
    withIsRequestSucceeded(requestType, IS_SUCCEEDED_PROP),
    withHasRequestError(requestType, HAS_ERROR_PROP),
    withProps(
      R.pipe(
        ownProps => ({
          ...ownProps,
          [outProp]: !ownProps[IS_SUCCEEDED_PROP] && !ownProps[HAS_ERROR_PROP],
        }),
        R.omit([IS_SUCCEEDED_PROP, HAS_ERROR_PROP]),
      ),
    ),
  )

export default withIsLoading
