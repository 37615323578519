import React from 'react'

const RecaptchaTermsConditions = ({ i18n }) =>
  !!process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY && (
    <div className="recaptcha-terms-conditions">
      {i18n('recaptcha-terms-conditions', {
        linkPrivacy: (
          <a
            key="lpgr"
            rel="noopener noreferrer"
            target="_blank"
            href="https://policies.google.com/privacy"
          >
            {i18n('noun:google-privacy')}
          </a>
        ),
        linkTerms: (
          <a
            key="ltgr"
            rel="noopener noreferrer"
            target="_blank"
            href="https://policies.google.com/terms"
          >
            {i18n('noun:google-terms')}
          </a>
        ),
      })}
    </div>
  )

export default RecaptchaTermsConditions
