import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CardKpi from './CardKpi'
import Card from './Card'
import { ReactComponent as Target } from '../../styles/icons/target.svg'
import { ReactComponent as Click } from '../../styles/icons/click.svg'
import { ReactComponent as Heart } from '../../styles/icons/heart.svg'
import { ReactComponent as Megaphone } from '../../styles/icons/megaphone.svg'
import { ReactComponent as Eye } from '../../styles/icons/eye.svg'
import { ReactComponent as Clock } from '../../styles/icons/clock-fill.svg'
import { ReactComponent as Scroll } from '../../styles/icons/scroll.svg'
import { ReactComponent as Ball } from '../../styles/icons/ball.svg'
import { ReactComponent as Follower } from '../../styles/icons/follower.svg'
import { ReactComponent as User } from '../../styles/icons/user-fill.svg'
import { ReactComponent as Global } from '../../styles/icons/global.svg'
import { ReactComponent as Maximum } from '../../styles/icons/maximum.svg'
import { ReactComponent as Influencer } from '../../styles/icons/influencer.svg'
import { ReactComponent as Like } from '../../styles/icons/like-fill.svg'
import { ReactComponent as Comment } from '../../styles/icons/comment-fill.svg'
import { ReactComponent as Da } from '../../styles/icons/da.svg'
import { ReactComponent as Pa } from '../../styles/icons/pa.svg'
import { ReactComponent as Upload } from '../../styles/icons/upload.svg'

const AnalyticsKPI = ({
  iconType,
  label,
  infoText,
  value,
  hasInfo,
  hasFixedWidthInfo,
  isTruncated,
  isPercentage,
  isTime,
}) => {
  let Icon
  if (iconType === 'target') {
    Icon = <Target className="icon" />
  } else if (iconType === 'click') {
    Icon = <Click className="icon" />
  } else if (iconType === 'heart') {
    Icon = <Heart className="icon" />
  } else if (iconType === 'megaphone') {
    Icon = <Megaphone className="icon" />
  } else if (iconType === 'eye') {
    Icon = <Eye className="icon" />
  } else if (iconType === 'clock') {
    Icon = <Clock className="icon" />
  } else if (iconType === 'scroll') {
    Icon = <Scroll className="icon" />
  } else if (iconType === 'ball') {
    Icon = <Ball className="icon" />
  } else if (iconType === 'follower') {
    Icon = <Follower className="icon" />
  } else if (iconType === 'user') {
    Icon = <User className="icon" />
  } else if (iconType === 'global') {
    Icon = <Global className="icon" />
  } else if (iconType === 'maximum') {
    Icon = <Maximum className="icon" />
  } else if (iconType === 'influencer') {
    Icon = <Influencer className="icon" />
  } else if (iconType === 'like') {
    Icon = <Like className="icon" />
  } else if (iconType === 'comment') {
    Icon = <Comment className="icon" />
  } else if (iconType === 'da') {
    Icon = <Da className="icon" />
  } else if (iconType === 'pa') {
    Icon = <Pa className="icon" />
  } else if (iconType === 'upload') {
    Icon = <Upload className="icon" />
  }
  return (
    <div className="analytics-kpi-card">
      <Card>
        <CardKpi
          className="card-kpi-followers"
          figure={value}
          label={label}
          infoText={infoText}
          hasInfo={hasInfo}
          hasFixedWidthInfo={hasFixedWidthInfo}
          isTruncated={isTruncated}
          isPercentage={isPercentage}
          isTime={isTime}
        >
          {Icon}
        </CardKpi>
      </Card>
    </div>
  )
}

AnalyticsKPI.propTypes = {
  iconType: PropTypes.oneOf([
    'target',
    'click',
    'heart',
    'megaphone',
    'eye',
    'clock',
    'scroll',
    'ball',
    'follower',
    'user',
    'global',
    'maximum',
    'influencer',
    'like',
    'comment',
    'da',
    'pa',
  ]),
  label: PropTypes.string,
  infoText: PropTypes.string,
  value: PropTypes.number,
  hasInfo: PropTypes.bool,
  hasFixedWidthInfo: PropTypes.bool,
  isTruncated: PropTypes.bool,
  isPercentage: PropTypes.bool,
  isTime: PropTypes.bool,
}

export default withTranslations(AnalyticsKPI)
