import React from 'react'
import PropTypes from 'prop-types'
import { PasswordInput } from '@redradix/components.input'
import Button from '@redradix/components.button'
import withTranslations from '../../hocs/withTranslations'
import FormattedFormErrors from '../FormattedFormErrors'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import IconIndicator from '../indicators/IconIndicator'
import WarningMessage from '../messages/WarningMessage'
import { PASSWORD_MAX_LENGTH } from '../../config/constants'

const ChangePasswordForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  dirty,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  isRequestSucceeded,
}) => (
  <form className="account-block-form is-password" onSubmit={handleSubmit}>
    <PasswordInput
      name="oldPassword"
      label={i18n('label:current-password')}
      placeholder={i18n('label:current-password')}
      widthBehaviour="full"
      value={values.oldPassword}
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.oldPassword}
      isDisabled={isRequestPending}
      maxLength={PASSWORD_MAX_LENGTH}
      errorText={
        validationMessages.oldPassword && (
          <FormattedFormErrors errors={validationMessages.oldPassword} />
        )
      }
      autoComplete="current-password"
    />

    <PasswordInput
      name="password"
      label={i18n('label:new-password')}
      placeholder={i18n('label:new-password')}
      widthBehaviour="full"
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.password}
      isDisabled={isRequestPending}
      maxLength={PASSWORD_MAX_LENGTH}
      errorText={
        validationMessages.password && (
          <FormattedFormErrors errors={validationMessages.password} />
        )
      }
      autoComplete="new-password"
    />

    <PasswordInput
      name="confirmPassword"
      label={i18n('label:confirm-password')}
      placeholder={i18n('label:confirm-password')}
      widthBehaviour="full"
      value={values.confirmPassword}
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.confirmPassword}
      isDisabled={isRequestPending}
      maxLength={PASSWORD_MAX_LENGTH}
      errorText={
        validationMessages.confirmPassword && (
          <FormattedFormErrors errors={validationMessages.confirmPassword} />
        )
      }
      autoComplete="new-password"
    />

    {isRequestSucceeded && !dirty ? (
      <IconIndicator type={'success'} />
    ) : (
      <Button
        type="submit"
        hierarchy="secondary"
        onSubmit={handleSubmit}
        isLoading={isRequestPending}
      >
        {i18n('action:save')}
      </Button>
    )}

    {globalMessages && (
      <WarningMessage text={<FormattedFormErrors errors={globalMessages} />} />
    )}
  </form>
)

const FORM_VALUES_PROPTYPES = {
  oldPassword: PropTypes.string,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
}

ChangePasswordForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(ChangePasswordForm)
