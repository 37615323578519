import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import OrderStatus from '../../orders/OrderStatus'
import OrderType from '../../orders/OrderType'
import withTranslations from '../../../hocs/withTranslations'
import {
  mapServiceTypeToOrderTypeI18n,
  mapServiceTypeToResourceType,
  mapServiceTypeToI18nWithoutWords,
  ORDER_STATUS,
} from '../../../services/orders/business'
import { SERVICE_TYPES } from '../../../services/orders/business'

const CancelledOrdersRow = props => {
  const { linkUrl, status, type, resource, date, i18n } = props
  return (
    <Link to={linkUrl} className="order-row">
      <div className="order-row-item orders-cancelled-table-status">
        <OrderStatus status={status} />
      </div>
      <div
        className="order-row-item orders-cancelled-table-resource"
        title={resource}
      >
        {resource}
      </div>
      <div
        className="order-row-item orders-cancelled-table-service"
        title={i18n(mapServiceTypeToI18nWithoutWords[type])}
      >
        <OrderType
          i18nKey={mapServiceTypeToOrderTypeI18n[type]}
          icon={mapServiceTypeToResourceType[type]}
        />
      </div>
      <div className="order-row-item orders-cancelled-table-date">
        <FormattedDate format="coobis" value={date} />
      </div>
    </Link>
  )
}
CancelledOrdersRow.propTypes = {
  linkUrl: PropTypes.string,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)),
  type: PropTypes.oneOf(Object.values(SERVICE_TYPES)),
  resource: PropTypes.string,
  date: PropTypes.instanceOf(Date),
}

export default withTranslations(CancelledOrdersRow)
