import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import AnchorLink from '../texts/AnchorLink'

const DashboardPanel = ({
  title,
  linkText,
  linkUrl,
  size,
  autoHeight,
  children,
  className,
  isGlobal,
}) => {
  const classes = cx(`${className}-panel size-${size}`, {
    'has-auto-height': autoHeight,
    'is-global': isGlobal,
  })
  return (
    <section className={classes}>
      {title && (
        <header className={className + '-panel-header'}>
          <h2 className={className + '-panel-title'}>{title}</h2>
          {linkText && <AnchorLink text={linkText} linkUrl={linkUrl} />}
        </header>
      )}
      <div className="dashboard-panel-contents">{children}</div>
    </section>
  )
}

DashboardPanel.defaultProps = {
  size: 'one-whole',
  autoHeight: false,
  className: 'dashboard',
}

DashboardPanel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  size: PropTypes.oneOf([
    'auto',
    'one-whole',
    'one-half',
    'one-third',
    'two-thirds',
  ]),
  autoHeight: PropTypes.bool.isRequired,
}

export default DashboardPanel
