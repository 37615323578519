import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import DashboardPanel from './DashboardPanel'
import OrdersCard from '../cards/OrdersCard'
import SearchCard from '../cards/SearchCard'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import LoadingCard from '../cards/LoadingCard'
import { isNilOrEmpty } from '../../app/utils'

const OrdersPanel = ({ orders, isLoading, i18n }) => {
  return (
    <DashboardPanel title={i18n('noun:pending-orders')} size="two-thirds">
      {isLoading ? (
        <LoadingCard />
      ) : !isNilOrEmpty(orders) ? (
        <OrdersCard orders={orders} />
      ) : (
        <SearchCard />
      )}
    </DashboardPanel>
  )
}

OrdersPanel.defaultProps = {}

OrdersPanel.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.orderRow),
  isLoading: PropTypes.bool,
}

export default withTranslations(OrdersPanel)
