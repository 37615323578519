import { combineReducers } from 'redux'
import { enableBatching } from 'reduken'
import reducers from './reducers'
import { LOGOUT_COMPLETE } from '../services/auth/action-types'

const rootReducer = (initialState = {}, history) => {
  const appReducer = enableBatching(combineReducers(reducers(history)))

  // root reducer wrapped to wipe Redux state on logout
  //
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  return (state, action) => {
    if (action.type === LOGOUT_COMPLETE) {
      return {
        ...initialState,
        // preserve connected-react-router state, or it crashes
        router: state.router,
      }
    }

    return appReducer(state, action)
  }
}

export default rootReducer
