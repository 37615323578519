export default {
  env: 'production',
  authBackendUrl: 'https://auth-backend.coobis.com',
  usersBackendUrl: 'https://users-backend.coobis.com',
  searchBackendUrl: 'https://search-backend.coobis.com',
  universalBackendUrl: 'https://universal-backend.coobis.com',
  socialBackendUrl: 'https://social-backend.coobis.com',
  affiliateLink: (userId, locale) =>
    `https://www.coobis.com/${locale}/b/${userId}`,
  stripeKey:
    'pk_live_51K1oE4Bjjw7KEUdB1qSWqyqKFp777nGWO376j9OrPHDuFyFwWtXx7xmkLJfluHNqjwnEpM3FKUSmpUfBHZpfQOQM00SzYkHaa9',
  hasDebug: false,
  bannerIframeUrl: 'https://trackcontrol.com/banner/banner.html',
}
