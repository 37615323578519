import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import Autocomplete from '@redradix/components.autocomplete'
import WarningMessage from '../messages/WarningMessage'
import { ReactComponent as Close } from '../../styles/icons/close.svg'

const DynamicChartLegend = ({
  maxItems,
  items,
  options,
  onDelete,
  onOptionChange,
  i18n,
}) => {
  const dummyInputForBlurRef = useRef()

  return (
    <div className="chart-legend-dynamic">
      <div className="chart-legend">
        {items.map((item, i) => (
          <div
            className="chart-legend-item chart-legend-item--chip"
            key={item.id}
          >
            <span className="chart-legend-label-wrapper">
              <span
                className="chart-legend-indicator"
                style={{ backgroundColor: `${item.color}` }}
              ></span>
              <span className="chart-legend-label" title={item.label}>
                <span className="chart-legend-label-text">{item.label}</span>
              </span>
              <button
                className="chart-legend-item-delete-button"
                onClick={() => onDelete(item.id)}
              >
                <Close className="icon" />
              </button>
            </span>
          </div>
        ))}
        <Autocomplete
          value={null}
          options={options}
          placeholder={i18n('action:add-publication')}
          noOptionsMessage={() =>
            i18n('dynamic-chart-legend:no-more-publications')
          }
          onChange={(...args) => {
            onOptionChange(...args)

            if (dummyInputForBlurRef.current) {
              dummyInputForBlurRef.current.focus()
            }
          }}
          isDisabled={items.length >= maxItems}
        />
        {/* For blurring autocomplete onChange */}
        <input
          style={{ width: '0px', border: 'none', padding: '0' }}
          ref={dummyInputForBlurRef}
        />
      </div>

      {items.length >= maxItems && (
        <WarningMessage
          text={i18n('dynamic-chart-legend:max-items-reached', {
            maxItems: maxItems,
          })}
          iconType="fill-warning"
        />
      )}
    </div>
  )
}

DynamicChartLegend.propTypes = {
  maxItems: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      isDisabled: PropTypes.bool,
      isFixed: PropTypes.bool,
    }),
  ),
  onOptionChange: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withTranslations(DynamicChartLegend)
