import * as actionTypes from './action-types'
import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  startRequest,
  endRequestSuccess,
  endRequestError,
  startRequestWithId,
  endRequestWithIdError,
  endRequestWithIdSuccess,
} from '../communication/actions'
import { updateEntities, updateEntity, mergeEntity } from 'reduken/entities'
import * as hash from 'reduken/hash'
import * as api from './api'
import { entitiesArrayToKeyedObject, isFile } from '../../app/utils'
import {
  DOMAIN,
  HASH_KEY_MEDIA_ORDERS_FINISHED_IDS,
  HASH_KEY_MEDIA_ORDERS_IN_PROGRESS_IDS,
  HASH_KEY_ORDER_CANCEL_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_COUNTERBRIEFING_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_PREDELIVERY_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_DELIVERY_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_MODAL_ID,
  HASH_KEY_ORDER_SEND_PREDELIVERY_MODAL_STATUS,
  HASH_KEY_ORDER_SEND_DELIVERY_MODAL_STATUS,
  ENTITY_KEY_MEDIA_ORDERS_IN_PROGRESS,
  ENTITY_KEY_MEDIA_ORDERS_FINISHED,
  ENTITY_KEY_ORDER_HISTORY,
  ENTITY_KEY_MEDIA_ORDER_DETAILS,
  ENTITY_KEY_BRAND_ORDER_DETAILS,
  ENTITY_KEY_BRAND_ORDERS_IN_PROGRESS,
  HASH_KEY_BRAND_ORDERS_IN_PROGRESS_IDS,
  ENTITY_KEY_CURRENT_CAMPAIGN_ORDERS,
  ENTITY_KEY_CANCELLED_CAMPAIGN_ORDERS,
} from './constants'
import { addSuccessToast } from '../toasts'
import {
  closeOrderModal,
  periodicFetchBrandOrdersInProgress,
  periodicFetchCurrentCampaignOrders,
  periodicFetchCancelledCampaignOrders,
} from './actions'
import {
  ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID,
  ROUTE_BRAND_CAMPAIGNS,
  ROUTE_BRAND_DASHBOARD,
  ROUTE_MEDIA_ORDER_BY_ID,
  ROUTE_MEDIA_DASHBOARD,
  ROUTE_BRAND_CAMPAIGNS_BY_ID,
} from '../../app/common/routes'
import { fetchDashboardOrdersSaga } from '../dashboard/sagas'
import { fetchCampaignsSummarySaga } from '../campaigns/sagas'
import repeatingFetchEffect from '../repeating-fetch-effect'
import { REFRESH_DASHBOARD_TIME } from '../dashboard/constants'
import { HISTORY_ACTIONS } from './business'

function* fetchMediaOrdersInProgressSaga() {
  yield put(startRequest(actionTypes.FETCH_MEDIA_ORDERS_IN_PROGRESS))

  const response = yield call(api.fetchMediaOrdersInProgress)

  if (response.error) {
    yield put(
      endRequestError(
        actionTypes.FETCH_MEDIA_ORDERS_IN_PROGRESS,
        response.error,
      ),
    )
    return
  }

  yield put(
    updateEntities({
      [ENTITY_KEY_MEDIA_ORDERS_IN_PROGRESS]: entitiesArrayToKeyedObject(
        response.data,
      ),
    }),
  )

  yield put(
    hash.set(
      DOMAIN,
      HASH_KEY_MEDIA_ORDERS_IN_PROGRESS_IDS,
      response.data.map(({ id }) => id),
    ),
  )

  yield put(endRequestSuccess(actionTypes.FETCH_MEDIA_ORDERS_IN_PROGRESS))
}

function* fetchMediaOrdersFinishedSaga() {
  yield put(startRequest(actionTypes.FETCH_MEDIA_ORDERS_FINISHED))

  const response = yield call(api.fetchMediaOrdersFinished)

  if (response.error) {
    yield put(
      endRequestError(actionTypes.FETCH_MEDIA_ORDERS_FINISHED, response),
    )
    return
  }

  yield put(
    updateEntities({
      [ENTITY_KEY_MEDIA_ORDERS_FINISHED]: entitiesArrayToKeyedObject(
        response.data,
      ),
    }),
  )

  yield put(
    hash.set(
      DOMAIN,
      HASH_KEY_MEDIA_ORDERS_FINISHED_IDS,
      response.data.map(({ id }) => id),
    ),
  )

  yield put(endRequestSuccess(actionTypes.FETCH_MEDIA_ORDERS_FINISHED))
}

function* fetchMediaOrderDetailSaga({ payload: { id } }) {
  yield put(startRequestWithId(actionTypes.FETCH_MEDIA_ORDER_DETAIL, id))

  const response = yield call(api.fetchMediaOrderDetail, id)

  if (response.error) {
    yield put(
      endRequestWithIdError(actionTypes.FETCH_MEDIA_ORDER_DETAIL, id, response),
    )
    return
  }

  yield put(updateEntity(ENTITY_KEY_MEDIA_ORDER_DETAILS, id, response.data))

  yield put(endRequestWithIdSuccess(actionTypes.FETCH_MEDIA_ORDER_DETAIL, id))
}

function* fetchBrandOrdersInProgressSaga() {
  yield put(startRequest(actionTypes.FETCH_BRAND_ORDERS_IN_PROGRESS))

  const response = yield call(api.fetchBrandOrdersInProgress)

  if (response.error) {
    yield put(
      endRequestError(
        actionTypes.FETCH_BRAND_ORDERS_IN_PROGRESS,
        response.error,
      ),
    )
    return
  }

  yield put(
    updateEntities({
      [ENTITY_KEY_BRAND_ORDERS_IN_PROGRESS]: entitiesArrayToKeyedObject(
        response.data,
      ),
    }),
  )

  yield put(
    hash.set(
      DOMAIN,
      HASH_KEY_BRAND_ORDERS_IN_PROGRESS_IDS,
      response.data.map(({ id }) => id),
    ),
  )

  yield put(endRequestSuccess(actionTypes.FETCH_BRAND_ORDERS_IN_PROGRESS))
}

function* fetchBrandOrderDetailSaga({ payload: { id } }) {
  yield put(startRequestWithId(actionTypes.FETCH_BRAND_ORDER_DETAIL, id))

  const response = yield call(api.fetchBrandOrderDetail, id)

  if (response.error) {
    yield put(
      endRequestWithIdError(actionTypes.FETCH_BRAND_ORDER_DETAIL, id, response),
    )
    return
  }

  yield put(updateEntity(ENTITY_KEY_BRAND_ORDER_DETAILS, id, response.data))

  yield put(endRequestWithIdSuccess(actionTypes.FETCH_BRAND_ORDER_DETAIL, id))
}

function* fetchOrderHistorySaga({ payload: { id } }) {
  yield put(startRequestWithId(actionTypes.FETCH_ORDER_HISTORY, id))

  const response = yield call(api.fetchOrderHistory, id)

  if (response.error) {
    yield put(
      endRequestWithIdError(actionTypes.FETCH_ORDER_HISTORY, id, response),
    )
    return
  }

  yield put(updateEntity(ENTITY_KEY_ORDER_HISTORY, id, response.data))

  yield put(endRequestWithIdSuccess(actionTypes.FETCH_ORDER_HISTORY, id))
}

function* changeOrderCampaignSaga({ payload: { campaignId, orderId } }) {
  yield put(startRequestWithId(actionTypes.CHANGE_ORDER_CAMPAIGN, orderId))

  const response = yield call(api.changeOrderCampaign, campaignId, orderId)

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.CHANGE_ORDER_CAMPAIGN,
        orderId,
        response,
      ),
    )
    return
  }

  yield put(mergeEntity(ENTITY_KEY_BRAND_ORDER_DETAILS, orderId, response.data))

  yield put(endRequestWithIdSuccess(actionTypes.CHANGE_ORDER_CAMPAIGN, orderId))
}

function* fetchCurrentCampaignOrdersSaga({ payload: { campaignId } }) {
  yield put(
    startRequestWithId(actionTypes.FETCH_CURRENT_CAMPAIGN_ORDERS, campaignId),
  )

  const response = yield call(api.fetchCurrentCampaignOrders, campaignId)

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.FETCH_CURRENT_CAMPAIGN_ORDERS,
        campaignId,
        response,
      ),
    )
    return
  }

  yield put(
    updateEntities({
      [ENTITY_KEY_CURRENT_CAMPAIGN_ORDERS]: {
        [String(campaignId)]: entitiesArrayToKeyedObject(response.data),
      },
    }),
  )

  yield put(
    endRequestWithIdSuccess(
      actionTypes.FETCH_CURRENT_CAMPAIGN_ORDERS,
      campaignId,
    ),
  )
}

function* fetchCancelledCampaignOrdersSaga({ payload: { campaignId } }) {
  yield put(
    startRequestWithId(actionTypes.FETCH_CANCELLED_CAMPAIGN_ORDERS, campaignId),
  )

  const response = yield call(api.fetchCancelledCampaignOrders, campaignId)

  if (response.error) {
    yield put(
      endRequestWithIdError(
        actionTypes.FETCH_CANCELLED_CAMPAIGN_ORDERS,
        campaignId,
        response,
      ),
    )
    return
  }

  yield put(
    updateEntities({
      [ENTITY_KEY_CANCELLED_CAMPAIGN_ORDERS]: {
        [String(campaignId)]: entitiesArrayToKeyedObject(response.data),
      },
    }),
  )

  yield put(
    endRequestWithIdSuccess(
      actionTypes.FETCH_CANCELLED_CAMPAIGN_ORDERS,
      campaignId,
    ),
  )
}

// Order modal sagas

function* closeOrderModalSaga() {
  yield all([
    put(hash.remove(DOMAIN, HASH_KEY_ORDER_CANCEL_MODAL_ID)),
    put(hash.remove(DOMAIN, HASH_KEY_ORDER_REVIEW_COUNTERBRIEFING_MODAL_ID)),
    put(hash.remove(DOMAIN, HASH_KEY_ORDER_REVIEW_PREDELIVERY_MODAL_ID)),
    put(hash.remove(DOMAIN, HASH_KEY_ORDER_REVIEW_DELIVERY_MODAL_ID)),
    put(hash.remove(DOMAIN, HASH_KEY_ORDER_REVIEW_MODAL_ID)),
    put(hash.remove(DOMAIN, HASH_KEY_ORDER_SEND_PREDELIVERY_MODAL_STATUS)),
    put(hash.remove(DOMAIN, HASH_KEY_ORDER_SEND_DELIVERY_MODAL_STATUS)),
  ])
}

// Brand actions

function* cancelOrderSaga({ payload: { orderId, comments } }) {
  yield put(startRequest(actionTypes.CANCEL_ORDER))

  const response = yield call(api.cancelOrder, orderId, comments)

  if (response.error) {
    yield put(endRequestError(actionTypes.CANCEL_ORDER, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:brand-cancel-title',
      'order-actions-success-toast:brand-cancel-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.CANCEL_ORDER))
}

function* acceptOrderCounterbriefing({ payload: { orderId } }) {
  yield put(startRequest(actionTypes.ACCEPT_ORDER_COUNTERBRIEFING))
  const response = yield call(api.acceptCounterbriefing, orderId)

  if (response.error) {
    yield put(
      endRequestError(actionTypes.ACCEPT_ORDER_COUNTERBRIEFING, response),
    )
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:brand-accept-counterbriefing-title',
      'order-actions-success-toast:brand-accept-counterbriefing-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.ACCEPT_ORDER_COUNTERBRIEFING))
}

function* rejectOrderCounterbriefing({ payload: { orderId } }) {
  yield put(startRequest(actionTypes.REJECT_ORDER_COUNTERBRIEFING))

  const response = yield call(api.rejectCounterbriefing, orderId)

  if (response.error) {
    yield put(
      endRequestError(actionTypes.REJECT_ORDER_COUNTERBRIEFING, response),
    )
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:brand-reject-counterbriefing-title',
      'order-actions-success-toast:brand-reject-counterbriefing-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.REJECT_ORDER_COUNTERBRIEFING))
}

function* approveOrderPredeliverySaga({ payload: { orderId, comments } }) {
  yield put(startRequest(actionTypes.APPROVE_ORDER_PREDELIVERY))

  const response = yield call(api.acceptOrderPreDelivery, orderId, comments)

  if (response.error) {
    yield put(endRequestError(actionTypes.APPROVE_ORDER_PREDELIVERY, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:brand-accept-predelivery-title',
      'order-actions-success-toast:brand-accept-predelivery-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.APPROVE_ORDER_PREDELIVERY))
}

function* correctOrderPredeliverySaga({ payload: { orderId, data } }) {
  yield put(startRequest(actionTypes.CORRECT_ORDER_PREDELIVERY))

  const response = yield call(api.rejectOrderPreDelivery, orderId, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.CORRECT_ORDER_PREDELIVERY, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:brand-correct-predelivery-title',
      'order-actions-success-toast:brand-correct-predelivery-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.CORRECT_ORDER_PREDELIVERY))
}

function* approveOrderDeliverySaga({ payload: { orderId, data } }) {
  yield put(startRequest(actionTypes.APPROVE_ORDER_DELIVERY))

  const response = yield call(api.acceptOrderDelivery, orderId, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.APPROVE_ORDER_DELIVERY, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:brand-accept-delivery-title',
      'order-actions-success-toast:brand-accept-delivery-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.APPROVE_ORDER_DELIVERY))
}

function* correctOrderDeliverySaga({ payload: { orderId, data } }) {
  yield put(startRequest(actionTypes.CORRECT_ORDER_DELIVERY))

  const response = yield call(api.rejectOrderDelivery, orderId, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.CORRECT_ORDER_DELIVERY, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:brand-correct-delivery-title',
      'order-actions-success-toast:brand-correct-delivery-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.CORRECT_ORDER_DELIVERY))
}

// Media actions

function* acceptOrderReviewSaga({ payload: { orderId } }) {
  yield put(startRequest(actionTypes.ACCEPT_ORDER_REVIEW))

  const response = yield call(api.acceptOrder, orderId)

  if (response.error) {
    yield put(endRequestError(actionTypes.ACCEPT_ORDER_REVIEW, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:media-accept-title',
      'order-actions-success-toast:media-accept-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.ACCEPT_ORDER_REVIEW))
}

function* rejectOrderReviewSaga({ payload: { orderId, comments } }) {
  yield put(startRequest(actionTypes.REJECT_ORDER_REVIEW))

  const response = yield call(api.rejectOrder, orderId, comments)

  if (response.error) {
    yield put(endRequestError(actionTypes.REJECT_ORDER_REVIEW, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:media-reject-title',
      'order-actions-success-toast:media-reject-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.REJECT_ORDER_REVIEW))
}

function* acceptOrderReviewWithChangesSaga({ payload: { orderId, comments } }) {
  yield put(startRequest(actionTypes.ACCEPT_ORDER_REVIEW_WITH_CHANGES))

  const response = yield call(
    api.acceptOrderReviewWithChanges,
    orderId,
    comments,
  )

  if (response.error) {
    yield put(
      endRequestError(actionTypes.ACCEPT_ORDER_REVIEW_WITH_CHANGES, response),
    )
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:media-accept-with-changes-title',
      'order-actions-success-toast:media-accept-with-changes-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.ACCEPT_ORDER_REVIEW_WITH_CHANGES))
}

function* uploadOrderFile(orderId, action, file) {
  const response = yield call(api.uploadOrderFile, orderId, action, file)

  if (response.error) {
    return { uploadErrors: response }
  }

  return { uploadedFile: response.data }
}

function* sendOrderPredeliverySaga({ payload: { orderId, isFix, data } }) {
  yield put(startRequest(actionTypes.SEND_ORDER_PREDELIVERY))

  const { file, ...submitData } = data

  if (isFile(file)) {
    const { uploadedFile, uploadErrors } = yield call(
      uploadOrderFile,
      orderId,
      HISTORY_ACTIONS.SEND_REVIEW,
      file,
    )

    if (uploadErrors) {
      yield put(
        endRequestError(actionTypes.SEND_ORDER_PREDELIVERY, uploadErrors),
      )
      return
    }

    submitData.urlFile = uploadedFile
  }

  const response = isFix
    ? yield call(api.fixOrderPreDelivery, orderId, submitData)
    : yield call(api.sendOrderPreDelivery, orderId, submitData)

  if (response.error) {
    yield put(endRequestError(actionTypes.SEND_ORDER_PREDELIVERY, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:media-send-predelivery-title',
      'order-actions-success-toast:media-send-predelivery-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.SEND_ORDER_PREDELIVERY))
}

function* sendOrderDeliveryWebSaga({ payload: { orderId, isFix, data } }) {
  yield put(startRequest(actionTypes.SEND_ORDER_DELIVERY_WEB))

  const { instagram, ...submitData } = data

  if (isFile(instagram)) {
    const { uploadedFile, uploadErrors } = yield call(
      uploadOrderFile,
      orderId,
      HISTORY_ACTIONS.SEND_DELIVERY,
      instagram,
    )

    if (uploadErrors) {
      yield put(
        endRequestError(actionTypes.SEND_ORDER_DELIVERY_WEB, uploadErrors),
      )
      return
    }

    submitData.instagram = uploadedFile
  }

  const response = isFix
    ? yield call(api.fixOrderDelivery, orderId, submitData)
    : yield call(api.sendOrderDelivery, orderId, submitData)

  if (response.error) {
    yield put(endRequestError(actionTypes.SEND_ORDER_DELIVERY_WEB, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:media-send-delivery-title',
      'order-actions-success-toast:media-send-delivery-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.SEND_ORDER_DELIVERY_WEB))
}

function* sendOrderDeliverySocialSaga({ payload: { orderId, isFix, data } }) {
  yield put(startRequest(actionTypes.SEND_ORDER_DELIVERY_SOCIAL))

  const response = isFix
    ? yield call(api.fixOrderDelivery, orderId, data)
    : yield call(api.sendOrderDelivery, orderId, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.SEND_ORDER_DELIVERY_SOCIAL, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:media-send-delivery-title',
      'order-actions-success-toast:media-send-delivery-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.SEND_ORDER_DELIVERY_SOCIAL))
}

function* sendOrderDeliveryStorySaga({ payload: { orderId, isFix, data } }) {
  yield put(startRequest(actionTypes.SEND_ORDER_DELIVERY_STORY))

  const { storyFile, ...submitData } = data

  if (isFile(storyFile)) {
    const { uploadedFile, uploadErrors } = yield call(
      uploadOrderFile,
      orderId,
      HISTORY_ACTIONS.SEND_DELIVERY,
      storyFile,
    )

    if (uploadErrors) {
      yield put(
        endRequestError(actionTypes.SEND_ORDER_DELIVERY_STORY, uploadErrors),
      )
      return
    }

    submitData.urlFile = uploadedFile
  }

  const response = isFix
    ? yield call(api.fixOrderDelivery, orderId, submitData)
    : yield call(api.sendOrderDelivery, orderId, submitData)

  if (response.error) {
    yield put(endRequestError(actionTypes.SEND_ORDER_DELIVERY_STORY, response))
    return
  }

  yield call(updateOrderByIdForCurrentScreenSaga, orderId)
  yield put(closeOrderModal())
  yield put(
    addSuccessToast(
      'order-actions-success-toast:media-send-delivery-title',
      'order-actions-success-toast:media-send-delivery-description',
    ),
  )

  yield put(endRequestSuccess(actionTypes.SEND_ORDER_DELIVERY_STORY))
}

//

function* updateOrderByIdForCurrentScreenSaga(orderId) {
  // Brand - Dashboard (orders with actions)
  const brandDashboardMatch = ROUTE_BRAND_DASHBOARD.match(
    window.location.pathname,
    {
      exact: true,
      strict: false,
    },
  )

  if (brandDashboardMatch !== null) {
    yield call(fetchDashboardOrdersSaga)
  }

  // Brand - Campaigns (orders in progress)
  const brandCampaignsListMatch = ROUTE_BRAND_CAMPAIGNS.match(
    window.location.pathname,
    {
      exact: true,
      strict: false,
    },
  )

  if (brandCampaignsListMatch !== null) {
    yield all([
      call(fetchBrandOrdersInProgressSaga),
      call(fetchCampaignsSummarySaga),
    ])
  }

  // Brand - Campaign By Id (reports)
  const brandCampaignById = ROUTE_BRAND_CAMPAIGNS_BY_ID.match(
    window.location.pathname,
    {
      exact: true,
      strict: false,
    },
  )

  const brandCampaignByIdGlobalDetails =
    brandCampaignById && brandCampaignById.params.resourceType === 'global'

  if (brandCampaignByIdGlobalDetails) {
    const campaignId = brandCampaignById.params.campaignId
    yield call(fetchCurrentCampaignOrdersSaga, { payload: { campaignId } })
    yield call(fetchCancelledCampaignOrdersSaga, { payload: { campaignId } })
  }

  // Brand - Order detail
  const brandOrderDetailMatch = ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.match(
    window.location.pathname,
    {
      exact: true,
      strict: false,
    },
  )

  // Brand - Order detail instructions / delivery
  const isInBrandOrderDetailInstructions =
    brandOrderDetailMatch !== null &&
    (!brandOrderDetailMatch.params.selectedTab ||
      brandOrderDetailMatch.params.selectedTab === 'instructions' ||
      brandOrderDetailMatch.params.selectedTab === 'delivery')

  if (isInBrandOrderDetailInstructions) {
    yield call(fetchBrandOrderDetailSaga, { payload: { id: orderId } })
  }

  // Brand - Order detail history
  const isInBrandOrderDetailHistory =
    brandOrderDetailMatch !== null &&
    brandOrderDetailMatch.params.selectedTab === 'history'

  if (isInBrandOrderDetailHistory) {
    yield call(fetchOrderHistorySaga, { payload: { id: orderId } })
  }

  // Media - Dashboard (orders in progress + closed)
  const mediaDashboardMatch = ROUTE_MEDIA_DASHBOARD.match(
    window.location.pathname,
    {
      exact: true,
      strict: false,
    },
  )

  if (mediaDashboardMatch !== null) {
    yield call(fetchMediaOrdersInProgressSaga)
    yield call(fetchMediaOrdersFinishedSaga)
  }

  // Media - Order detail
  const mediaOrderDetailMatch = ROUTE_MEDIA_ORDER_BY_ID.match(
    window.location.pathname,
    {
      exact: true,
      strict: false,
    },
  )

  // Media - Order detail instructions / delivery
  const isInMediaOrderDetailInstructions =
    mediaOrderDetailMatch !== null &&
    (!mediaOrderDetailMatch.params.selectedTab ||
      mediaOrderDetailMatch.params.selectedTab === 'instructions' ||
      mediaOrderDetailMatch.params.selectedTab === 'delivery')

  if (isInMediaOrderDetailInstructions) {
    yield call(fetchMediaOrderDetailSaga, { payload: { id: orderId } })
  }

  // Media - Order detail history
  const isInMediaOrderDetailHistory =
    mediaOrderDetailMatch !== null &&
    mediaOrderDetailMatch.params.selectedTab === 'history'

  if (isInMediaOrderDetailHistory) {
    yield call(fetchOrderHistorySaga, { payload: { id: orderId } })
  }
}

export default function*() {
  yield all([
    takeLatest(
      actionTypes.FETCH_MEDIA_ORDERS_IN_PROGRESS,
      fetchMediaOrdersInProgressSaga,
    ),
    takeLatest(
      actionTypes.FETCH_MEDIA_ORDERS_FINISHED,
      fetchMediaOrdersFinishedSaga,
    ),
    takeLatest(actionTypes.FETCH_MEDIA_ORDER_DETAIL, fetchMediaOrderDetailSaga),
    takeLatest(
      actionTypes.FETCH_BRAND_ORDERS_IN_PROGRESS,
      fetchBrandOrdersInProgressSaga,
    ),
    repeatingFetchEffect({
      fetchSaga: fetchBrandOrdersInProgressSaga,
      intervalMillisecs: REFRESH_DASHBOARD_TIME,
      startAction: periodicFetchBrandOrdersInProgress(),
      requestActionType: actionTypes.FETCH_BRAND_ORDERS_IN_PROGRESS,
      cancelActionType:
        actionTypes.CANCEL_PERIODIC_FETCH_BRAND_ORDERS_IN_PROGRESS,
    }),
    takeLatest(actionTypes.FETCH_BRAND_ORDER_DETAIL, fetchBrandOrderDetailSaga),
    takeLatest(actionTypes.FETCH_ORDER_HISTORY, fetchOrderHistorySaga),
    takeLatest(actionTypes.CHANGE_ORDER_CAMPAIGN, changeOrderCampaignSaga),
    takeLatest(
      actionTypes.FETCH_CURRENT_CAMPAIGN_ORDERS,
      fetchCurrentCampaignOrdersSaga,
    ),
    repeatingFetchEffect({
      fetchSaga: fetchCurrentCampaignOrdersSaga,
      intervalMillisecs: REFRESH_DASHBOARD_TIME,
      startAction: periodicFetchCurrentCampaignOrders(),
      requestActionType: actionTypes.FETCH_CURRENT_CAMPAIGN_ORDERS,
      cancelActionType:
        actionTypes.CANCEL_PERIODIC_FETCH_CURRENT_CAMPAIGN_ORDERS,
    }),
    takeLatest(
      actionTypes.FETCH_CANCELLED_CAMPAIGN_ORDERS,
      fetchCancelledCampaignOrdersSaga,
    ),
    repeatingFetchEffect({
      fetchSaga: fetchCancelledCampaignOrdersSaga,
      intervalMillisecs: REFRESH_DASHBOARD_TIME,
      startAction: periodicFetchCancelledCampaignOrders(),
      requestActionType: actionTypes.FETCH_CANCELLED_CAMPAIGN_ORDERS,
      cancelActionType:
        actionTypes.CANCEL_PERIODIC_FETCH_CANCELLED_CAMPAIGN_ORDERS,
    }),
    // Order modal actions
    takeLatest(actionTypes.CLOSE_ORDER_MODAL, closeOrderModalSaga),
    // Brand actions
    takeLatest(actionTypes.CANCEL_ORDER, cancelOrderSaga),
    takeLatest(
      actionTypes.ACCEPT_ORDER_COUNTERBRIEFING,
      acceptOrderCounterbriefing,
    ),
    takeLatest(
      actionTypes.REJECT_ORDER_COUNTERBRIEFING,
      rejectOrderCounterbriefing,
    ),
    takeLatest(
      actionTypes.APPROVE_ORDER_PREDELIVERY,
      approveOrderPredeliverySaga,
    ),
    takeLatest(
      actionTypes.CORRECT_ORDER_PREDELIVERY,
      correctOrderPredeliverySaga,
    ),
    takeLatest(actionTypes.APPROVE_ORDER_DELIVERY, approveOrderDeliverySaga),
    takeLatest(actionTypes.CORRECT_ORDER_DELIVERY, correctOrderDeliverySaga),
    // Media actions
    takeLatest(actionTypes.ACCEPT_ORDER_REVIEW, acceptOrderReviewSaga),
    takeLatest(actionTypes.REJECT_ORDER_REVIEW, rejectOrderReviewSaga),
    takeLatest(
      actionTypes.ACCEPT_ORDER_REVIEW_WITH_CHANGES,
      acceptOrderReviewWithChangesSaga,
    ),
    takeLatest(actionTypes.SEND_ORDER_PREDELIVERY, sendOrderPredeliverySaga),
    takeLatest(actionTypes.SEND_ORDER_DELIVERY_WEB, sendOrderDeliveryWebSaga),
    takeLatest(
      actionTypes.SEND_ORDER_DELIVERY_SOCIAL,
      sendOrderDeliverySocialSaga,
    ),
    takeLatest(
      actionTypes.SEND_ORDER_DELIVERY_STORY,
      sendOrderDeliveryStorySaga,
    ),
  ])
}
