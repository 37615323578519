import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBodyCell,
  TableBodyRow,
  TableHeaderCell,
  TableHeaderRow,
} from '@redradix/components.table'
import Button from '@redradix/components.button'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const TableGoogleSynchronizeData = ({
  tableData,
  onSelect,
  i18n,
  i18nNumber,
}) => {
  return (
    <div className="basic-table">
      <Table
        tableHeader={
          <TableHeaderRow>
            <TableHeaderCell>{i18n('noun:profile')}</TableHeaderCell>
            <TableHeaderCell>{i18n('noun:url')}</TableHeaderCell>
            <TableHeaderCell>{i18n('metrics:visits')}</TableHeaderCell>
            <TableHeaderCell>{i18n('metrics:unique-users')}</TableHeaderCell>
            <TableHeaderCell>{i18n('metrics:page-views')}</TableHeaderCell>
            <TableHeaderCell>{i18n('action:select')}</TableHeaderCell>
          </TableHeaderRow>
        }
      >
        {tableData.map(item => (
          <TableBodyRow key={item.id}>
            <TableBodyCell>
              <strong>{item.profile}</strong>
            </TableBodyCell>
            <TableBodyCell>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.url}
              </a>
            </TableBodyCell>
            <TableBodyCell>
              {isNaN(item.visits) ? '?' : i18nNumber(item.visits)}
            </TableBodyCell>
            <TableBodyCell>
              {isNaN(item.users) ? '?' : i18nNumber(item.users)}
            </TableBodyCell>
            <TableBodyCell>
              {isNaN(item.webs) ? '?' : i18nNumber(item.webs)}
            </TableBodyCell>
            <TableBodyCell>
              <Button
                hierarchy="secondary"
                onClick={() => {
                  onSelect(item.id)
                }}
              >
                {i18n('action:choose-profile')}
              </Button>
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </Table>
    </div>
  )
}

TableGoogleSynchronizeData.propTypes = {
  tableData: PropTypes.arrayOf(CoobisPropTypes.googleAnalyticsProfile),
  onSelect: PropTypes.func.isRequired,
}

export default withTranslations(TableGoogleSynchronizeData)
