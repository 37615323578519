import * as Yup from 'yup'
import {
  i18nValidation,
  testRelativeDateMinHours,
  testRelativeDateMaxHours,
} from '../../app/common/validation'

export const generalDataValidationSchema = Yup.object().shape({
  express: Yup.boolean().required(i18nValidation.required),
  campaign: Yup.string()
    .nullable()
    .required(i18nValidation.required),
  brand: Yup.string().required(i18nValidation.required),
  url: Yup.string().required(i18nValidation.required),
  preDelivery: Yup.boolean().required(i18nValidation.required),

  acceptDate: Yup.date()
    .nullable()
    .when('express', {
      is: false,
      then: Yup.date()
        .nullable()
        .test(
          testRelativeDateMinHours(
            Yup.ref('$now'),
            undefined,
            Yup.ref('$acceptMinHours'),
            i18nValidation.minRelativeToNow,
          ),
        )
        .test(
          testRelativeDateMaxHours(
            Yup.ref('$now'),
            undefined,
            Yup.ref('$acceptMaxHours'),
            i18nValidation.maxRelativeToNow,
          ),
        ),
      otherwise: Yup.date().nullable(),
    }),
  sendToReviewDate: Yup.date()
    .nullable()
    .when(['express', 'preDelivery'], {
      is: (express, preDelivery) => !express && preDelivery,
      then: Yup.date()
        .nullable()
        .test(
          testRelativeDateMinHours(
            Yup.ref('acceptDate'),
            Yup.ref('$acceptDateDefault'),
            Yup.ref('$sendToReviewMinHours'),
            i18nValidation.minRelativeToAcceptanceDate,
          ),
        )
        .test(
          testRelativeDateMaxHours(
            Yup.ref('acceptDate'),
            Yup.ref('$acceptDateDefault'),
            Yup.ref('$sendToReviewMaxHours'),
            i18nValidation.maxRelativeToAcceptanceDate,
          ),
        ),
      otherwise: Yup.date().nullable(),
    }),
  validateReviewDate: Yup.date()
    .nullable()
    .when(['express', 'preDelivery'], {
      is: (express, preDelivery) => !express && preDelivery,
      then: Yup.date()
        .nullable()
        .test(
          testRelativeDateMinHours(
            Yup.ref('sendToReviewDate'),
            Yup.ref('$sendToReviewDateDefault'),
            Yup.ref('$validateReviewMinHours'),
            i18nValidation.minRelativeToSendToReviewDate,
          ),
        )
        .test(
          testRelativeDateMaxHours(
            Yup.ref('sendToReviewDate'),
            Yup.ref('$sendToReviewDateDefault'),
            Yup.ref('$validateReviewMaxHours'),
            i18nValidation.maxRelativeToSendToReviewDate,
          ),
        ),
      otherwise: Yup.date().nullable(),
    }),
  deliveryDate: Yup.date()
    .nullable()
    .when('express', {
      is: false,
      then: Yup.date()
        .nullable()
        .when('preDelivery', {
          is: true,
          then: Yup.date()
            .nullable()
            .test(
              testRelativeDateMinHours(
                Yup.ref('validateReviewDate'),
                Yup.ref('$validateReviewDateDefault'),
                Yup.ref('$deliveryMinHours'),
                i18nValidation.minRelativeToValidateReviewDate,
              ),
            )
            .test(
              testRelativeDateMaxHours(
                Yup.ref('validateReviewDate'),
                Yup.ref('$validateReviewDateDefault'),
                Yup.ref('$deliveryMaxHours'),
                i18nValidation.maxRelativeToValidateReviewDate,
              ),
            ),
          otherwise: Yup.date()
            .nullable()
            .test(
              testRelativeDateMinHours(
                Yup.ref('acceptDate'),
                Yup.ref('$acceptDateDefault'),
                Yup.ref('$deliveryMinHours'),
                i18nValidation.minRelativeToAcceptanceDate,
              ),
            )
            .test(
              testRelativeDateMaxHours(
                Yup.ref('acceptDate'),
                Yup.ref('$acceptDateDefault'),
                Yup.ref('$deliveryMaxHours'),
                i18nValidation.maxRelativeToAcceptanceDate,
              ),
            ),
        }),
      otherwise: Yup.date().nullable(),
    }),
  approveDate: Yup.date().when('express', {
    is: false,
    then: Yup.date()
      .nullable()
      .test(
        testRelativeDateMinHours(
          Yup.ref('deliveryDate'),
          Yup.ref('$deliveryDateDefault'),
          Yup.ref('$approveMinHours'),
          i18nValidation.minRelativeToDeliveryDate,
        ),
      )
      .test(
        testRelativeDateMaxHours(
          Yup.ref('deliveryDate'),
          Yup.ref('$deliveryDateDefault'),
          Yup.ref('$approveMaxHours'),
          i18nValidation.maxRelativeToDeliveryDate,
        ),
      ),
    otherwise: Yup.date().nullable(),
  }),
})

export const getYupHoursContext = (dateInfo, datesConfig) => ({
  now: dateInfo.now.toDate(),
  ...(!!dateInfo.acceptDate
    ? {
        acceptMinHours: datesConfig.acceptDate.minHours,
        acceptMaxHours: datesConfig.acceptDate.maxHours,
        acceptDateDefault: !!dateInfo.acceptDate.default
          ? dateInfo.acceptDate.default.toDate()
          : null,
      }
    : {}),
  ...(!!dateInfo.sendToReviewDate
    ? {
        sendToReviewMinHours: datesConfig.sendToReviewDate.minHours,
        sendToReviewMaxHours: datesConfig.sendToReviewDate.maxHours,
        sendToReviewDateDefault: !!dateInfo.sendToReviewDate.default
          ? dateInfo.sendToReviewDate.default.toDate()
          : null,
      }
    : {}),
  ...(!!dateInfo.validateReviewDate
    ? {
        validateReviewMinHours: datesConfig.validateReviewDate.minHours,
        validateReviewMaxHours: datesConfig.validateReviewDate.maxHours,
        validateReviewDateDefault: !!dateInfo.validateReviewDate.default
          ? dateInfo.validateReviewDate.default.toDate()
          : null,
      }
    : {}),
  ...(!!dateInfo.deliveryDate
    ? {
        deliveryMinHours: datesConfig.deliveryDate.minHours,
        deliveryMaxHours: datesConfig.deliveryDate.maxHours,
        deliveryDateDefault: !!dateInfo.deliveryDate.default
          ? dateInfo.deliveryDate.default.toDate()
          : null,
      }
    : {}),
  ...(!!dateInfo.approveDate
    ? {
        approveMinHours: datesConfig.approveDate.minHours,
        approveMaxHours: datesConfig.approveDate.maxHours,
        approveDateDefault: !!dateInfo.approveDate.default
          ? dateInfo.approveDate.default.toDate()
          : null,
      }
    : {}),
})
