import {
  compose,
  withProps,
  lifecycle,
  withState,
  withHandlers,
} from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as Yup from 'yup'

import {
  needsScope,
  login,
  loginWithScope,
  clearNeedsScope,
} from '../../services/auth'
import Login from '../../ui/Login'
import { LOGIN, LOGIN_WITH_SCOPE } from '../../services/auth/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { i18nValidation } from '../common/validation'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import { AuthScope } from '../../services/auth/business'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import useRecaptcha from '../../hooks/useRecaptcha'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18nValidation.invalidMail)
    .required(i18nValidation.required),
  password: Yup.string().required(i18nValidation.required),
})

const handleActionWithRecaptcha = useRecaptcha()

const enhance = compose(
  withRouter,

  connect(
    state => ({
      needsScope: needsScope(state),
    }),
    {
      handleLogin: login,
      handleLoginWithScope: loginWithScope,
    },
  ),

  // Save which scope we're currently logging-in with
  withState('lastChosenScope', 'setLastChosenScope', undefined),
  withHandlers({
    handleLoginWithScope: props => (username, password, scope) => {
      handleActionWithRecaptcha('loginWithScope', (recaptcha, action) => {
        props.setLastChosenScope(scope)
        props.handleLoginWithScope(username, password, scope, recaptcha, action)
      })
    },
  }),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: false,

    mapPropsToValues: () => ({
      email: '',
      password: '',
      recaptcha: '',
    }),

    validationSchema: LoginSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      handleActionWithRecaptcha('login', (recaptcha, action) => {
        props.handleLogin(values.email, values.password, recaptcha, action)
      })
    },
  }),

  withIsRequestPending(LOGIN, 'isLoginPending'),
  withIsRequestPending(LOGIN_WITH_SCOPE, 'isLoginWithScopePending'),
  // Specific isPending for each scope
  withProps(({ isLoginWithScopePending, lastChosenScope }) => ({
    isLoginAsMediaPending:
      isLoginWithScopePending && lastChosenScope === AuthScope.MEDIA,
    isLoginAsBrandPending:
      isLoginWithScopePending && lastChosenScope === AuthScope.BRAND,
  })),

  // Handle errors
  // withScope errors take precedence
  withRequestError(LOGIN, 'loginRequestError'),
  withRequestError(LOGIN_WITH_SCOPE, 'loginWithScopeRequestError'),
  withProps(({ loginRequestError, loginWithScopeRequestError }) => ({
    requestError: loginWithScopeRequestError || loginRequestError,
  })),
  withFormErrors(['email', 'password']),

  // Clear needs scope flag when navigating away
  connect(
    null,
    { clearNeedsScope },
  ),
  lifecycle({
    componentWillUnmount() {
      this.props.clearNeedsScope()
    },
  }),

  withDeleteRequestOnUnmount(LOGIN),
  withDeleteRequestOnUnmount(LOGIN_WITH_SCOPE),
)

export default enhance(Login)
