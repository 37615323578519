import * as hash from 'reduken/hash'
import * as actionTypes from './action-types'
import {
  DOMAIN,
  HASH_KEY_ORDER_CANCEL_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_COUNTERBRIEFING_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_PREDELIVERY_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_DELIVERY_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_MODAL_ID,
  HASH_KEY_ORDER_SEND_PREDELIVERY_MODAL_STATUS,
  HASH_KEY_ORDER_SEND_DELIVERY_MODAL_STATUS,
} from './constants'

export const fetchMediaOrdersInProgress = () => ({
  type: actionTypes.FETCH_MEDIA_ORDERS_IN_PROGRESS,
})

export const fetchMediaOrdersFinished = () => ({
  type: actionTypes.FETCH_MEDIA_ORDERS_FINISHED,
})

export const fetchMediaOrderDetail = id => ({
  type: actionTypes.FETCH_MEDIA_ORDER_DETAIL,
  payload: { id },
})

export const fetchBrandOrdersInProgress = () => ({
  type: actionTypes.FETCH_BRAND_ORDERS_IN_PROGRESS,
})

export const periodicFetchBrandOrdersInProgress = () => ({
  type: actionTypes.PERIODIC_FETCH_BRAND_ORDERS_IN_PROGRESS,
})

export const cancelPeriodicFetchBrandOrdersInProgress = () => ({
  type: actionTypes.CANCEL_PERIODIC_FETCH_BRAND_ORDERS_IN_PROGRESS,
})

export const fetchBrandOrderDetail = id => ({
  type: actionTypes.FETCH_BRAND_ORDER_DETAIL,
  payload: { id },
})

export const fetchOrderHistory = id => ({
  type: actionTypes.FETCH_ORDER_HISTORY,
  payload: { id },
})

export const changeOrderCampaign = (campaignId, orderId) => ({
  type: actionTypes.CHANGE_ORDER_CAMPAIGN,
  payload: { campaignId, orderId },
})

export const fetchCurrentCampaignOrders = campaignId => ({
  type: actionTypes.FETCH_CURRENT_CAMPAIGN_ORDERS,
  payload: { campaignId },
})

export const periodicFetchCurrentCampaignOrders = campaignId => ({
  type: actionTypes.PERIODIC_FETCH_CURRENT_CAMPAIGN_ORDERS,
  payload: { campaignId },
})

export const cancelPeriodicFetchCurrentCampaignOrders = campaignId => ({
  type: actionTypes.CANCEL_PERIODIC_FETCH_CURRENT_CAMPAIGN_ORDERS,
  payload: { campaignId },
})

export const fetchCancelledCampaignOrders = campaignId => ({
  type: actionTypes.FETCH_CANCELLED_CAMPAIGN_ORDERS,
  payload: { campaignId },
})

export const periodicFetchCancelledCampaignOrders = campaignId => ({
  type: actionTypes.PERIODIC_FETCH_CANCELLED_CAMPAIGN_ORDERS,
  payload: { campaignId },
})

export const cancelPeriodicFetchCancelledCampaignOrders = campaignId => ({
  type: actionTypes.CANCEL_PERIODIC_FETCH_CANCELLED_CAMPAIGN_ORDERS,
  payload: { campaignId },
})

// Order modals handling
export const openOrderCancelModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_CANCEL_MODAL_ID, orderId)

export const openOrderReviewCounterbriefingModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_REVIEW_COUNTERBRIEFING_MODAL_ID, orderId)

export const openOrderReviewPredeliveryModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_REVIEW_PREDELIVERY_MODAL_ID, orderId)

export const openOrderReviewDeliveryModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_REVIEW_DELIVERY_MODAL_ID, orderId)

export const openOrderReviewModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_REVIEW_MODAL_ID, orderId)

export const openOrderSendPredeliveryModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_SEND_PREDELIVERY_MODAL_STATUS, {
    orderId,
    isFix: false,
  })

export const openOrderFixPredeliveryModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_SEND_PREDELIVERY_MODAL_STATUS, {
    orderId,
    isFix: true,
  })

export const openOrderSendDeliveryModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_SEND_DELIVERY_MODAL_STATUS, {
    orderId,
    isFix: false,
  })

export const openOrderFixDeliveryModal = orderId =>
  hash.set(DOMAIN, HASH_KEY_ORDER_SEND_DELIVERY_MODAL_STATUS, {
    orderId,
    isFix: true,
  })

export const closeOrderModal = () => ({
  type: actionTypes.CLOSE_ORDER_MODAL,
})

// Order modals actions

// Brand
export const cancelOrder = (orderId, comments) => ({
  type: actionTypes.CANCEL_ORDER,
  payload: { orderId, comments },
})

export const acceptOrderCounterbriefing = orderId => ({
  type: actionTypes.ACCEPT_ORDER_COUNTERBRIEFING,
  payload: { orderId },
})

export const rejectOrderCounterbriefing = orderId => ({
  type: actionTypes.REJECT_ORDER_COUNTERBRIEFING,
  payload: { orderId },
})

export const approveOrderPredelivery = (orderId, comments) => ({
  type: actionTypes.APPROVE_ORDER_PREDELIVERY,
  payload: { orderId, comments },
})

export const correctOrderPredelivery = (orderId, data) => ({
  type: actionTypes.CORRECT_ORDER_PREDELIVERY,
  payload: { orderId, data },
})

export const approveOrderDelivery = (orderId, data) => ({
  type: actionTypes.APPROVE_ORDER_DELIVERY,
  payload: { orderId, data },
})

export const correctOrderDelivery = (orderId, data) => ({
  type: actionTypes.CORRECT_ORDER_DELIVERY,
  payload: { orderId, data },
})

// Media

export const acceptOrderReview = orderId => ({
  type: actionTypes.ACCEPT_ORDER_REVIEW,
  payload: { orderId },
})

export const rejectOrderReview = (orderId, comments) => ({
  type: actionTypes.REJECT_ORDER_REVIEW,
  payload: { orderId, comments },
})

export const acceptOrderReviewWithChanges = (orderId, comments) => ({
  type: actionTypes.ACCEPT_ORDER_REVIEW_WITH_CHANGES,
  payload: { orderId, comments },
})

export const sendOrderPredelivery = (orderId, isFix, data) => ({
  type: actionTypes.SEND_ORDER_PREDELIVERY,
  payload: { orderId, isFix, data },
})

export const sendOrderDeliveryWeb = (orderId, isFix, data) => ({
  type: actionTypes.SEND_ORDER_DELIVERY_WEB,
  payload: { orderId, isFix, data },
})

export const sendOrderDeliverySocial = (orderId, isFix, data) => ({
  type: actionTypes.SEND_ORDER_DELIVERY_SOCIAL,
  payload: { orderId, isFix, data },
})

export const sendOrderDeliveryStory = (orderId, isFix, data) => ({
  type: actionTypes.SEND_ORDER_DELIVERY_STORY,
  payload: { orderId, isFix, data },
})
