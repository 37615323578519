import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import Select from '../form-elements/Select'
import Button from '@redradix/components.button'
import IconIndicator from '../indicators/IconIndicator'

const OrderDetailCampaign = ({
  campaigns,
  selectedCampaign,
  errorText,
  isSelectorTouched,
  isLoading,
  onSelectorFocus,
  onSelectorBlur,
  onSelectorChange,
  onSelectorOpen,
  onSelectorClose,
  handleSubmit,
  showSaveCampaignButton,
  hasSucceeded,
  i18n,
}) => {
  return (
    <OrderDetailSection title={i18n('sections:campaign')}>
      <form onSubmit={handleSubmit} className="selector-widget">
        <Select
          id="simple_select_id"
          label={i18n('noun:campaign')}
          options={campaigns}
          placeholder={i18n('action:select-campaign')}
          errorText={errorText}
          isTouched={isSelectorTouched}
          isDisabled={isLoading}
          onBlur={onSelectorBlur}
          onChange={onSelectorChange}
          onClose={onSelectorClose}
          onFocus={onSelectorFocus}
          onOpen={onSelectorOpen}
          value={selectedCampaign}
        />

        {showSaveCampaignButton ? (
          hasSucceeded ? (
            <IconIndicator type={'success'} />
          ) : (
            <Button type="submit" isLoading={isLoading} hierarchy="secondary">
              {i18n('action:save')}
            </Button>
          )
        ) : null}
      </form>
    </OrderDetailSection>
  )
}

OrderDetailCampaign.propTypes = {
  campaigns: PropTypes.array,
  selectedCampaign: PropTypes.object,
  errorText: PropTypes.string,
  isSelectorTouched: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelectorFocus: PropTypes.func,
  onSelectorBlur: PropTypes.func,
  onSelectorChange: PropTypes.func,
  onSelectorOpen: PropTypes.func,
  onSelectorClose: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailCampaign)
