import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import { MediaQueryDesk } from '../shared/MediaQueries'
import WelcomeDashboardWrapper from './WelcomeDashboardWrapper'
import SearchPanel from './SearchPanel'
import BalancePanel from './BalancePanel'
import FeaturePanel from './FeaturePanel'
import Tour from '../tour/Tour'
import { getBrandSteps } from '../../app/common/tourSteps'
import { TOUR_WELCOME_BRAND } from '../../services/tour/constants'

const WelcomeDashboard = ({
  username,
  balance,
  isLoadingBalance,
  handleBalanceRecharge,
  i18n,
  isWelcomeBrandTourViewed,
  markTourAsViewed,
}) => (
  <WelcomeDashboardWrapper
    title={i18n('welcome-dashboard:message', { username })}
  >
    {!isWelcomeBrandTourViewed ? (
      <Tour
        steps={getBrandSteps(i18n)}
        markTourAsViewed={markTourAsViewed}
        tourName={TOUR_WELCOME_BRAND}
      />
    ) : null}
    <SearchPanel />
    <BalancePanel
      balance={balance}
      isLoading={isLoadingBalance}
      onBalanceAction={handleBalanceRecharge}
    />
    <MediaQueryDesk>
      <FeaturePanel />
    </MediaQueryDesk>
  </WelcomeDashboardWrapper>
)

WelcomeDashboard.propTypes = {
  username: PropTypes.string,
  balance: CoobisPropTypes.money,
  isLoadingBalance: PropTypes.bool.isRequired,
}

export default withTranslations(WelcomeDashboard)
