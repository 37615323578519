import { connect } from 'react-redux'
import { getCurrentSearch } from '../../services/search'

const withCurrentSearch = connect(
  state => ({
    currentSearch: getCurrentSearch(state),
  }),
  {},
)

export default withCurrentSearch
