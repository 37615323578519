// Initialize airbnb's rheostat and react-dates globally at the same time
// Otherwise their ThemeProvider gets overriden and there are crashes
// Thank you Airbnb!
//
// See: https://github.com/airbnb/rheostat/issues/179#issuecomment-403536047

// We don't need default Rheostat CSS
//import 'rheostat/css/rheostat.css'
import 'react-dates/lib/css/_datepicker.css'

import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import cssInterface from 'react-with-styles-interface-css'
import RheostatDefaultTheme from 'rheostat/lib/themes/DefaultTheme'
import ReactDatesDefaultTheme from 'react-dates/lib/theme/DefaultTheme'

let initialized = false

if (!initialized) {
  ThemedStyleSheet.registerInterface(cssInterface)
  ThemedStyleSheet.registerTheme({
    ...RheostatDefaultTheme,
    ...ReactDatesDefaultTheme,
  })

  initialized = true
}
