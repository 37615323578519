import { all, put, call, select, takeEvery } from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import * as actionTypes from './action-types'
import * as api from './api'
import * as R from 'ramda'
import {
  DOMAIN,
  HASH_KEY_CATEGORIES,
  HASH_KEY_COUNTRIES,
  HASH_KEY_CURRENCIES,
} from './constants'
import {
  startRequest,
  endRequestError,
  endRequestSuccess,
} from '../communication/actions'
import { entitiesArrayToKeyedObject } from '../../app/utils'
import { getIsRequestPending } from '../communication'
import { getCategories, getCountries } from './selectors'

function* fetchCategoriesSaga() {
  // Do not fetch if already fetched/fetching
  const isFetchingCategories = yield select(
    getIsRequestPending(actionTypes.FETCH_CATEGORIES),
  )

  const hasCategories = !!(yield select(getCategories))

  if (hasCategories || isFetchingCategories) {
    return
  }

  // Needs to fetch
  yield put(startRequest(actionTypes.FETCH_CATEGORIES))

  const response = yield call(api.fetchCategories)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_CATEGORIES, response))
    return
  }

  const keyedCategories = entitiesArrayToKeyedObject(response.data)

  yield put(hash.set(DOMAIN, HASH_KEY_CATEGORIES, keyedCategories))

  yield put(endRequestSuccess(actionTypes.FETCH_CATEGORIES))
}

function* fetchCountriesSaga() {
  // Do not fetch if already fetched/fetching
  const isFetchingCountries = yield select(
    getIsRequestPending(actionTypes.FETCH_COUNTRIES),
  )

  const hasCountries = !!(yield select(getCountries))

  if (hasCountries || isFetchingCountries) {
    return
  }

  // Needs to fetch
  yield put(startRequest(actionTypes.FETCH_COUNTRIES))

  const response = yield call(api.fetchCountries)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_COUNTRIES, response))
    return
  }

  const keyedCountries = R.map(
    item => ({
      ...item,
      provinces: entitiesArrayToKeyedObject(item.provinces),
    }),
    entitiesArrayToKeyedObject(response.data),
  )
  yield put(hash.set(DOMAIN, HASH_KEY_COUNTRIES, keyedCountries))

  yield put(endRequestSuccess(actionTypes.FETCH_COUNTRIES))
}

function* fetchCurrenciesSaga() {
  yield put(startRequest(actionTypes.FETCH_CURRENCIES))

  const response = yield call(api.fetchCurrencies)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_CURRENCIES, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_CURRENCIES, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_CURRENCIES))
}

export default function*() {
  yield all([
    takeEvery(actionTypes.FETCH_CATEGORIES, fetchCategoriesSaga),
    takeEvery(actionTypes.FETCH_COUNTRIES, fetchCountriesSaga),
    takeEvery(actionTypes.FETCH_CURRENCIES, fetchCurrenciesSaga),
  ])
}
