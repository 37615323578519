import React from 'react'
import PropTypes from 'prop-types'

const Subtitle = props => {
  return <p className="subtitle">{props.text}</p>
}

Subtitle.propTypes = {
  text: PropTypes.string,
}

export default Subtitle
