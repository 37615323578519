import moment from 'moment'
import * as R from 'ramda'
import { toMomentOnlyHours } from '../app/utils'

const generateDateDefaultMinMax = (compareTo, config) => ({
  default: compareTo.clone().add(config.defaultHours, 'hour'),
  min: compareTo.clone().add(config.minHours, 'hour'),
  max: compareTo.clone().add(config.maxHours, 'hour'),
})

export const getDateInfoFromDateConfig = (
  hasPredelivery,
  dateValues,
  dateConfig,
) => {
  // Accept date
  // Round up to nearest o'clock hour
  const startDate = toMomentOnlyHours(moment().add(1, 'hour'))

  const acceptDate = generateDateDefaultMinMax(startDate, dateConfig.acceptDate)

  const deliveryDateCompareTo = !!dateValues.acceptDate
    ? dateValues.acceptDate
    : acceptDate.default

  // If predelivery is disabled, we have predelivery dates, which include
  // delivery dates too
  if (hasPredelivery) {
    const predeliveryDateInfo = getPredeliveryDateInfoFromDateConfig(
      deliveryDateCompareTo,
      dateValues,
      dateConfig,
    )

    return {
      now: startDate,
      acceptDate,
      ...R.omit(['now'], predeliveryDateInfo),
    }
  }
  // If predelivery is disabled, we only have delivery dates
  else {
    const deliveryDateInfo = getDeliveryDateInfoFromDateConfig(
      deliveryDateCompareTo,
      dateValues,
      dateConfig,
    )

    return {
      now: startDate,
      acceptDate,
      sendToReviewDate: {
        default: null,
        min: null,
        max: null,
      },
      validateReviewDate: {
        default: null,
        min: null,
        max: null,
      },
      ...R.omit(['now'], deliveryDateInfo),
    }
  }
}

export const getPredeliveryDateInfoFromDateConfig = (
  startDate,
  dateValues,
  dateConfig,
) => {
  // If missing, now rounded up to nearest o'clock hour
  const startDateOrDefault =
    startDate || toMomentOnlyHours(moment().add(1, 'hour'))

  const sendToReviewDateInfo = generateDateDefaultMinMax(
    startDateOrDefault,
    dateConfig.sendToReviewDate,
  )

  // Validate review date
  const validateReviewDateCompareTo = !!dateValues.sendToReviewDate
    ? dateValues.sendToReviewDate
    : sendToReviewDateInfo.default

  const validateReviewDateInfo = generateDateDefaultMinMax(
    validateReviewDateCompareTo,
    dateConfig.validateReviewDate,
  )

  // Delivery date
  const deliveryDateCompareTo = !!dateValues.validateReviewDate
    ? dateValues.validateReviewDate
    : validateReviewDateInfo.default

  const deliveryDatesInfo = getDeliveryDateInfoFromDateConfig(
    deliveryDateCompareTo,
    dateValues,
    dateConfig,
  )

  return {
    now: startDateOrDefault,
    sendToReviewDate: sendToReviewDateInfo,
    validateReviewDate: validateReviewDateInfo,
    ...R.omit(['now'], deliveryDatesInfo),
  }
}

export const getDeliveryDateInfoFromDateConfig = (
  startDate,
  dateValues,
  dateConfig,
) => {
  // If missing, now rounded up to nearest o'clock hour
  const startDateOrDefault =
    startDate || toMomentOnlyHours(moment().add(1, 'hour'))

  const deliveryDateInfo = generateDateDefaultMinMax(
    startDateOrDefault,
    dateConfig.deliveryDate,
  )

  // Approve date
  const approveDateCompareTo = !!dateValues.deliveryDate
    ? dateValues.deliveryDate
    : deliveryDateInfo.default

  const approveDateInfo = generateDateDefaultMinMax(
    approveDateCompareTo,
    dateConfig.approveDate,
  )

  return {
    now: startDateOrDefault,
    deliveryDate: deliveryDateInfo,
    approveDate: approveDateInfo,
  }
}
