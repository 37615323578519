import { compose, lifecycle, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import * as R from 'ramda'
import WithdrawFundsModal from '../../ui/profile/modals/WithdrawFundsModal'
import {
  fetchWithdrawFundsData,
  getWithdrawFundsData,
  withdrawFunds,
} from '../../services/wallet'
import withSpinner from '../../hocs/withSpinner'
import {
  FETCH_WITHDRAW_FUNDS_DATA,
  WITHDRAW_FUNDS,
} from '../../services/wallet/action-types'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withFormErrors from '../../hocs/withFormErrors'
import { validateYupSchema, yupToFormErrors } from 'formik'
import { i18nValidation } from '../common/validation'
import enhanceModalSpinner from '../common/enhanceModalSpinner'

const validationSchema = ({
  selectedWithdrawalInfo,
  withdrawalMethods,
  invoiceUploadRequired,
}) =>
  Yup.object().shape({
    rate: Yup.number()
      .required(i18nValidation.required)
      .oneOf([selectedWithdrawalInfo.incomeTaxRate], i18nValidation.required),

    method: Yup.string()
      .required(i18nValidation.required)
      .oneOf(withdrawalMethods, i18nValidation.required),

    invoiceNumber: invoiceUploadRequired
      ? Yup.string().required(i18nValidation.required)
      : undefined,

    invoiceUpload: invoiceUploadRequired
      ? Yup.mixed()
          .nullable()
          .required(i18nValidation.required)
      : undefined,
  })

const getSelectedWithdrawalInfo = ({ withdrawalTypes, values }) => {
  const selectedWithdrawalType = withdrawalTypes.find(
    x => x.incomeTaxRate === values.rate,
  )

  return {
    incomeTaxRate: selectedWithdrawalType.incomeTaxRate,
    withdrawAmount: selectedWithdrawalType.amount,
    vatRate: selectedWithdrawalType.vatRate,
    requiresResidenceDocument: selectedWithdrawalType.requiresResidenceDocument,
    requiresTypeDocument: selectedWithdrawalType.requiresTypeDocument,
    totalAmount: selectedWithdrawalType.finalAmount,
    originalTotalAmount: selectedWithdrawalType.originalFinalAmount,
    originalIncomeTaxRate: selectedWithdrawalType.originalIncomeTaxRate,
  }
}

const enhance = compose(
  connect(
    state => getWithdrawFundsData(state),
    {
      fetchWithdrawFundsData,
      withdrawFunds,
    },
  ),

  lifecycle({
    componentDidMount() {
      this.props.fetchWithdrawFundsData()
    },
  }),

  withSpinner(FETCH_WITHDRAW_FUNDS_DATA, {
    enhanceSpinner: enhanceModalSpinner('action:withdraw-funds'),
  }),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({
      canChoosePaymentMethod,
      invoiceUploadRequired,
      withdrawalMethods,
      withdrawalTypes,
    }) => {
      return {
        rate: (
          R.findLast(
            x => !x.requiresResidenceDocument && !x.requiresTypeDocument,
            withdrawalTypes,
          ) || withdrawalTypes[0]
        ).incomeTaxRate,
        method: canChoosePaymentMethod ? 'PAYPAL' : withdrawalMethods[0],
        invoiceNumber: invoiceUploadRequired ? '' : undefined,
        invoiceUpload: invoiceUploadRequired ? null : undefined,
      }
    },

    fileFields: ['invoiceUpload'],

    validate: (
      values,
      { withdrawalTypes, withdrawalMethods, invoiceUploadRequired },
    ) => {
      const selectedWithdrawalInfo = getSelectedWithdrawalInfo({
        values,
        withdrawalTypes,
      })

      try {
        validateYupSchema(
          values,
          validationSchema({
            selectedWithdrawalInfo,
            withdrawalMethods,
            invoiceUploadRequired,
          }),
          true,
        )
      } catch (err) {
        return yupToFormErrors(err)
      }

      return {}
    },

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const selectedWithdrawalInfo = getSelectedWithdrawalInfo({
        values,
        withdrawalTypes: props.withdrawalTypes,
      })

      // Do not submit if residence or type document are required
      if (
        selectedWithdrawalInfo.requiresResidenceDocument ||
        selectedWithdrawalInfo.requiresTypeDocument
      ) {
        return
      }

      const valuesToSend = {
        amount: selectedWithdrawalInfo.originalTotalAmount,
        rate: selectedWithdrawalInfo.originalIncomeTaxRate,
        method: values.method,
        invoiceNumber: props.invoiceUploadRequired
          ? values.invoiceNumber
          : undefined,
        invoiceUpload: props.invoiceUploadRequired
          ? values.invoiceUpload
          : undefined,
      }

      props.withdrawFunds(valuesToSend)
    },
  }),

  // Make "rate" field a numeric value
  withHandlers({
    handleChange: props => (...args) => {
      const fieldName = args[0].target.name

      if (fieldName === 'rate') {
        return props.handleChange(
          {
            target: {
              name: fieldName,
              value: Number(args[0].target.value),
            },
          },
          ...args.slice(1),
        )
      }

      return props.handleChange(...args)
    },
  }),

  withIsRequestPending(WITHDRAW_FUNDS),
  withIsRequestSucceeded(WITHDRAW_FUNDS),
  withRequestError(WITHDRAW_FUNDS),
  withDeleteRequestOnUnmount(WITHDRAW_FUNDS),
  withFormErrors(['rate', 'method', 'invoiceNumber', 'invoiceUpload']),

  withPropsOnChange(['withdrawalTypes', 'values'], getSelectedWithdrawalInfo),
)

export default enhance(WithdrawFundsModal)
