import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Close } from '../../styles/icons/close.svg'
import cx from 'classnames'
import useOnClickOutside from '../../hooks/useOnClickOutside'

const Modal = ({
  children,
  onClose,
  isGrey,
  isDarkThemed,
  isSmallSized,
  closeOnClickOutside,
}) => {
  const modalRef = useRef()

  useOnClickOutside(modalRef, closeOnClickOutside ? onClose : undefined)

  useEffect(() => {
    const handleEscape = e => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keydown', handleEscape)

    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [onClose])

  const modalClasses = cx('modal', {
    'is-grey': isGrey,
    'is-dark-themed': isDarkThemed,
    'is-small-sized': isSmallSized,
  })

  return (
    <div className="modal-overlay">
      <div ref={modalRef} className={modalClasses}>
        <div className="modal-content">{children}</div>
        <Close className="modal-close-button" onClick={onClose} />
      </div>
    </div>
  )
}

Modal.defaultProps = {
  closeOnClickOutside: true,
}

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  isGrey: PropTypes.bool,
  isDarkThemed: PropTypes.bool,
  isSmallSized: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
}

export default Modal
