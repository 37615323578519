import { compose, lifecycle } from 'recompose'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation } from '../common/validation'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { changePassword } from '../../services/auth'
import { CHANGE_PASSWORD } from '../../services/auth/action-types'
import withRequestError from '../../hocs/withRequestError'
import ChangePasswordForm from '../../ui/profile/ChangePasswordForm'
import { withScope } from '../../services/auth/hocs'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required(i18nValidation.required),
  password: Yup.string()
    .required(i18nValidation.required)
    .min(8, i18nValidation.minLength),
  confirmPassword: Yup.string()
    .required(i18nValidation.required)
    .oneOf([Yup.ref('password')], i18nValidation.passwordsDoNotMatch),
})

const withChangePasswordForm = compose(
  withScope,

  connect(
    null,
    { changePassword },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: false,

    mapPropsToValues: () => ({
      oldPassword: '',
      password: '',
      confirmPassword: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.changePassword(
        props.scope,
        values.oldPassword,
        values.password,
        values.confirmPassword,
      )
    },
  }),

  withIsRequestPending(CHANGE_PASSWORD),
  withIsRequestSucceeded(CHANGE_PASSWORD),
  withRequestError(CHANGE_PASSWORD),
  withDeleteRequestOnUnmount(CHANGE_PASSWORD),
  withFormErrors(['oldPassword', 'password', 'confirmPassword']),

  lifecycle({
    componentDidUpdate(prev) {
      const { isRequestSucceeded, resetForm } = this.props

      if (prev.isRequestSucceeded === false && isRequestSucceeded === true) {
        resetForm()
      }
    },
  }),
)

export default withChangePasswordForm(ChangePasswordForm)
