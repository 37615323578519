import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@redradix/components.input'

const InputNumber = ({
  errorText,
  id,
  isDisabled,
  isReadonly,
  isRequired,
  isTouched,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  value,
  label,
  min,
  max,
  step,
  suffix,
}) => {
  return (
    <div className="input-number">
      {(!isNaN(value) || placeholder) && (
        <Input
          appearance="box"
          behaviour="static"
          widthBehaviour="full"
          type="number"
          id={id}
          label={label}
          name={name}
          placeholder={placeholder}
          value={value}
          errorText={errorText}
          isDisabled={isDisabled}
          isReadonly={isReadonly}
          isRequired={isRequired}
          isTouched={isTouched}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          min={min}
          max={max}
          step={step}
        />
      )}
      {suffix && <span className="input-number-suffix">{suffix}</span>}
    </div>
  )
}

InputNumber.propTypes = {
  /** String that defines the error text of the <input> */
  errorText: PropTypes.string,
  /** String that defines a unique identifier of the <input> */
  id: PropTypes.string.isRequired,
  /** Boolean that defines whether the <input> is disabled */
  isDisabled: PropTypes.bool,
  /** Boolean that defines whether the <input> can be modified */
  isReadonly: PropTypes.bool,
  /** Boolean that defines whether the <input> is required */
  isRequired: PropTypes.bool,
  /** Boolean that specifies whether the <input> field has been modified */
  isTouched: PropTypes.bool,
  /** String that specifies the label of the <input> */
  label: PropTypes.string,
  /** String that specifies the name of an <input> */
  name: PropTypes.string.isRequired,
  /** Function that is triggered when the <input> field loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when the <input> is changed */
  onChange: PropTypes.func,
  /** Function that is triggered when the <input> gets focus */
  onFocus: PropTypes.func,
  /** String that describes the expected value of the <input> */
  placeholder: PropTypes.string,
  /** String that specifies the value of the <input> */
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  suffix: PropTypes.string,
}

export default InputNumber
