import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import Card from './Card'
import CardKpi from './CardKpi'
import { Link } from 'react-router-dom'
import { ReactComponent as Target } from '../../styles/icons/target.svg'
import { ReactComponent as Heart } from '../../styles/icons/heart.svg'
import { ReactComponent as Megaphone } from '../../styles/icons/megaphone.svg'

const CampaignCard = ({
  i18n,
  linkUrl,
  name,
  reach,
  engagement,
  orders,
  ordersPending,
  onCardClick,
}) => {
  return (
    <Card isHighlighted isInteractive onClick={onCardClick}>
      <Link to={linkUrl} className="campaign-card">
        <p className="campaign-card-title" title={name}>
          {name}
        </p>
        <div className="card-kpi-group">
          <CardKpi
            figure={reach}
            label={i18n('noun:reach')}
            imageBg="#eef0ff"
            isTruncated
          >
            <Target className="icon" style={{ fill: '#8b74d1' }} />
          </CardKpi>
          <CardKpi
            figure={engagement}
            label={i18n('noun:engagement')}
            imageBg="#fedee9"
            isTruncated
          >
            <Heart className="icon" style={{ fill: '#fa5992' }} />
          </CardKpi>
          <CardKpi
            figure={orders}
            label={i18n('noun:orders')}
            imageBg="#fff7df"
            notifications={ordersPending}
            isTruncated
          >
            <Megaphone
              className="icon"
              style={{ fill: '#ffd960', width: '1.25rem', height: '1.25rem' }}
            />
          </CardKpi>
        </div>
      </Link>
    </Card>
  )
}

CampaignCard.propTypes = {
  linkUrl: PropTypes.string,
  name: PropTypes.string,
  reach: PropTypes.number,
  engagement: PropTypes.number,
  orders: PropTypes.number,
  ordersPending: PropTypes.number,
  onCardClick: PropTypes.func,
}

export default withTranslations(CampaignCard)
