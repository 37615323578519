import { compose, withPropsOnChange, withProps } from 'recompose'
import * as R from 'ramda'
import { withTranslatedCountries } from '../services/config/hocs'

const TRANSLATED_COUNTRIES_PROP = 'withCountrySelectField/translatedCountries'

const withCountrySelectField = ({
  valuesProp = 'values',
  optionsProp = 'countryOptions',
  valueField = 'country',
} = {}) =>
  compose(
    withTranslatedCountries({ outProp: TRANSLATED_COUNTRIES_PROP }),

    withPropsOnChange([TRANSLATED_COUNTRIES_PROP], props => ({
      [optionsProp]: R.pipe(
        R.map(item => ({
          value: item.id,
          label: item.label,
          isDisabled: false,
        })),
        R.sort((a, b) => a.label.localeCompare(b.label)),
      )(props[TRANSLATED_COUNTRIES_PROP]),
    })),

    withPropsOnChange([TRANSLATED_COUNTRIES_PROP, valuesProp], props => {
      const values = props[valuesProp]

      const countryValue = values[valueField]
        ? {
            isDisabled: false,
            value: values[valueField],
            label: R.pipe(
              R.find(R.propEq('id', values[valueField])),
              R.prop('label'),
            )(props[TRANSLATED_COUNTRIES_PROP]),
          }
        : null

      return {
        [valuesProp]: {
          ...values,
          [valueField]: countryValue,
        },
      }
    }),

    withProps(R.dissoc(TRANSLATED_COUNTRIES_PROP)),
  )

export default withCountrySelectField
