import React from 'react'
import { compose, branch, renderComponent, withProps } from 'recompose'
import * as R from 'ramda'
import { connect } from 'react-redux'
import withSpinner from '../../hocs/withSpinner'
import withRouteServerError from '../../hocs/withRouteServerError'
import {
  withDashboard,
  withFeaturedResources,
} from '../../services/dashboard/hocs'
import {
  FETCH_DASHBOARD_CAMPAIGNS,
  FETCH_DASHBOARD_ORDERS,
  FETCH_FEATURED_RESOURCES,
} from '../../services/dashboard/action-types'
import { withWallet } from '../../services/wallet/hocs'
import { FETCH_WALLET } from '../../services/wallet/action-types'
import withIsLoading from '../../hocs/withIsLoading'
import BrandDashboard from '../../ui/dashboard/BrandDashboard'
import WelcomeDashboard from '../../ui/dashboard/WelcomeDashboard'
import { isNilOrEmpty } from '../utils'
import { getBrandInfo } from '../../services/brand'
import withBalanceRecharge from '../wallet/withBalanceRecharge'
import { withFetchFavorites } from '../../services/favorites/hocs'
import { FETCH_MY_FAVORITES } from '../../services/favorites/action-types'
import FavCardEnhanced from './FavCard'
import RecommendedCard from './RecommendedCard'
import { withNotifications } from '../../services/notifications/hocs'
import {
  getIsWelcomeBrandTourViewed,
  markTourAsViewed,
} from '../../services/tour'

const renderFavCard = item => <FavCardEnhanced item={item} key={item.id} />

const renderRecommendedCard = resource => (
  <RecommendedCard item={resource} key={resource.id} />
)

const enhance = compose(
  // Inject dashboard data
  withWallet,
  connect(
    state => ({
      brandInfo: getBrandInfo(state),
      isWelcomeBrandTourViewed: getIsWelcomeBrandTourViewed(state),
    }),
    {
      markTourAsViewed,
    },
  ),
  withProps(({ brandInfo }) => ({ username: brandInfo.name })),
  withDashboard,
  withFeaturedResources,
  withFetchFavorites,
  withNotifications,

  // Inject `is*Loading` props
  withProps(({ balance }) => ({ isLoadingBalance: isNilOrEmpty(balance) })),
  withIsLoading(FETCH_DASHBOARD_ORDERS, 'isLoadingOrders'),
  withIsLoading(FETCH_DASHBOARD_CAMPAIGNS, 'isLoadingCampaigns'),
  withIsLoading(FETCH_MY_FAVORITES, 'isLoadingFavorites'),
  withIsLoading(FETCH_FEATURED_RESOURCES, 'isLoadingFeaturedResources'),

  // Show global spinner only the first time it is loaded
  branch(
    ({ campaigns, orders, favorites, featuredResources }) =>
      R.isNil(campaigns) &&
      R.isNil(orders) &&
      R.isNil(favorites) &&
      R.isNil(featuredResources),
    compose(
      withSpinner(FETCH_DASHBOARD_CAMPAIGNS),
      withSpinner(FETCH_DASHBOARD_ORDERS),
      withSpinner(FETCH_MY_FAVORITES),
      withSpinner(FETCH_FEATURED_RESOURCES),
    ),
  ),

  // Add funds logic
  withBalanceRecharge,

  // If there is no data, show welcome dashboard
  branch(
    ({ campaigns, orders }) => R.isEmpty(campaigns) && R.isEmpty(orders),
    renderComponent(WelcomeDashboard),
  ),

  // Show errors if fetches failed
  withRouteServerError(FETCH_DASHBOARD_CAMPAIGNS),
  withRouteServerError(FETCH_DASHBOARD_ORDERS),
  withRouteServerError(FETCH_MY_FAVORITES),
  withRouteServerError(FETCH_FEATURED_RESOURCES),
  withRouteServerError(FETCH_WALLET),

  // Map orders to format expected in /ui/
  withProps(({ orders }) => ({
    orders: !!orders
      ? orders.map(order => ({
          ...order,
          date: order.createdAt,
        }))
      : undefined,
  })),

  withProps({ renderFavCard, renderRecommendedCard }),
)

export default enhance(BrandDashboard)
