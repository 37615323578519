import { compose, withPropsOnChange } from 'recompose'

const withValidationMessagePromotedToGlobalMessage = (
  field,
  validationKey,
  globalMessage,
) =>
  compose(
    withPropsOnChange(
      ['validationMessages', 'globalMessages'],
      ({ validationMessages, globalMessages }) => {
        const [validationMessage] = validationMessages[field]
          ? validationMessages[field].filter(v => v.key === validationKey)
          : []
        return !validationMessage
          ? {}
          : {
              globalMessages: [
                globalMessage(validationMessage.values),
                ...(globalMessages || []),
              ],
            }
      },
    ),
  )

export default withValidationMessagePromotedToGlobalMessage
