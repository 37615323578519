import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as AlertTriangle } from '../../styles/icons/alert-triangle.svg'
import { ReactComponent as Close } from '../../styles/icons/close.svg'

export const Alert = ({ title, body, isVisible, onClose }) => {
  const classes = cx('alert', {
    'is-type-warning': true,
    'is-visible': isVisible,
  })
  return (
    <div className={classes}>
      <p className="alert-title">
        <AlertTriangle className="icon" />
        <span className="text">{title}</span>
      </p>
      <div
        className="alert-body"
        dangerouslySetInnerHTML={{ __html: body }}
      ></div>
      <button className="alert-close-button" onClick={onClose}>
        {<Close />}
      </button>
    </div>
  )
}

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export const AlertsContainer = ({ children }) => {
  const classes = cx('alerts-container', {})
  return <div className={classes}>{children}</div>
}
