import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@redradix/components.input'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import Select from '../form-elements/Select'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import IconIndicator from '../indicators/IconIndicator'
import WarningMessage from '../messages/WarningMessage'

const BrandDataForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  languageOptions,
  hasSucceeded,
}) => (
  <form className="account-block-form" onSubmit={handleSubmit}>
    <Input
      type="text"
      name="name"
      label={i18n('label:name')}
      placeholder={i18n('label:name')}
      widthBehaviour="full"
      value={values.name}
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.name}
      isDisabled={isRequestPending}
      errorText={
        validationMessages.name && (
          <FormattedFormErrors errors={validationMessages.name} />
        )
      }
    />

    <Input
      type="text"
      name="surname"
      label={i18n('label:surname')}
      placeholder={i18n('label:surname')}
      widthBehaviour="full"
      value={values.surname}
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.surname}
      isDisabled={isRequestPending}
      errorText={
        validationMessages.surname && (
          <FormattedFormErrors errors={validationMessages.surname} />
        )
      }
    />

    <Input
      type="email"
      name="email"
      label={i18n('label:email')}
      placeholder={i18n('label:email')}
      widthBehaviour="full"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.email}
      isDisabled={isRequestPending}
      errorText={
        validationMessages.email && (
          <FormattedFormErrors errors={validationMessages.email} />
        )
      }
    />

    <Input
      type="text"
      name="phone"
      label={i18n('label:phone')}
      placeholder={i18n('label:phone')}
      widthBehaviour="full"
      value={values.phone}
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.phone}
      isDisabled={isRequestPending}
      errorText={
        validationMessages.phone && (
          <FormattedFormErrors errors={validationMessages.phone} />
        )
      }
    />

    <Input
      type="text"
      name="website"
      label={i18n('label:website')}
      placeholder={i18n('label:website')}
      widthBehaviour="full"
      value={values.website}
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.website}
      isDisabled={isRequestPending}
      errorText={
        validationMessages.website && (
          <FormattedFormErrors errors={validationMessages.website} />
        )
      }
    />

    <Select
      name="language"
      label={i18n('label:language')}
      placeholder={i18n('label:language')}
      options={languageOptions}
      value={values.language}
      isTouched={touched.language}
      isDisabled={isRequestPending}
      onChange={item =>
        handleChange({ target: { name: 'language', value: item.value } })
      }
      onBlur={_ => handleBlur({ target: { name: 'language' } })}
      errorText={
        validationMessages.language && (
          <FormattedFormErrors errors={validationMessages.language} />
        )
      }
    />

    {globalMessages && (
      <WarningMessage text={<FormattedFormErrors errors={globalMessages} />} />
    )}

    <div className="button-container">
      {hasSucceeded ? (
        <IconIndicator type={'success'} />
      ) : (
        <Button
          type="submit"
          hierarchy="secondary"
          isLoading={isRequestPending}
        >
          {i18n('action:save')}
        </Button>
      )}
    </div>
  </form>
)

const FORM_VALUES_PROPTYPES = {
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string,
  phone: PropTypes.string,
  language: CoobisPropTypes.makeSelectOption(PropTypes.string),
}

BrandDataForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  hasSucceeded: PropTypes.bool.isRequired,
  languageOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.string),
  ).isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(BrandDataForm)
