import * as Yup from 'yup'
import * as R from 'ramda'
import IBAN from 'iban'
import { isNilOrEmpty } from '../../app/utils'
import { i18nValidation } from '../../app/common/validation'

export const PROFESSIONAL_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  COMPANY: 'COMPANY',
}

export const PROFESSIONAL_TYPES_I18N = {
  [PROFESSIONAL_TYPES.INDIVIDUAL]: 'professional-types:individual',
  [PROFESSIONAL_TYPES.SELF_EMPLOYED]: 'professional-types:selfEmployed',
  [PROFESSIONAL_TYPES.COMPANY]: 'professional-types:company',
}

export const PROFESSIONAL_TYPES_ORDER = [
  PROFESSIONAL_TYPES.INDIVIDUAL,
  PROFESSIONAL_TYPES.SELF_EMPLOYED,
  PROFESSIONAL_TYPES.COMPANY,
]

const COMMON_FIELDS = [
  'type',
  'identityDocument',
  'address',
  'postalCode',
  'city',
  'state',
  'country',
  'paypal',
  'currency',
]

const INDIVIDUAL_OR_SELF_EMPLOYED_FIELDS = [
  'identityDocumentUpload',
  'name',
  'surname',
]

const COMPANY_FIELDS = ['businessName']

export const COUNTRIES_WITH_IBAN = ['ES', 'FR', 'IT', 'AD']

// Gets which fields apply given the current values
export const getApplicableFormFields = (countriesConfig, values) => [
  ...COMMON_FIELDS,
  ...(values.type === PROFESSIONAL_TYPES.INDIVIDUAL ||
  values.type === PROFESSIONAL_TYPES.SELF_EMPLOYED
    ? INDIVIDUAL_OR_SELF_EMPLOYED_FIELDS
    : []),
  ...(values.type === PROFESSIONAL_TYPES.COMPANY ? COMPANY_FIELDS : []),
  ...(getTypeDocumentApplicable(values) ? ['typeDocumentUpload'] : []),
  ...(getResidenceDocumentApplicable(countriesConfig, values)
    ? ['residenceDocumentUpload']
    : []),
  ...(COUNTRIES_WITH_IBAN.includes(values.country) ? ['iban'] : []),
]

// Returns whether type document applies
export const getTypeDocumentApplicable = values =>
  values.country === 'ES' && values.type === PROFESSIONAL_TYPES.SELF_EMPLOYED

export const getResidenceDocumentApplicable = (countriesConfig, values) =>
  values.country &&
  countriesConfig[values.country] &&
  countriesConfig[values.country].doubleTaxationAvoidanceAgreementWithSpain

export const needsPaypalOrIBAN = (values, touched) => {
  const isApplicableCountry = COUNTRIES_WITH_IBAN.includes(values.country)
  const noValues = isNilOrEmpty(values.iban) && isNilOrEmpty(values.paypal)
  const oneFieldTouched = touched.iban || touched.paypal

  return isApplicableCountry && (noValues && oneFieldTouched)
}

const commonValidationSchemaFields = countries => ({
  type: Yup.string()
    .oneOf(PROFESSIONAL_TYPES_ORDER, i18nValidation.required)
    .required(i18nValidation.required),

  identityDocument: Yup.string().required(i18nValidation.required),

  address: Yup.string().required(i18nValidation.required),

  postalCode: Yup.string().required(i18nValidation.required),

  city: Yup.string().required(i18nValidation.required),

  state: Yup.string()
    .nullable()
    .oneOf(
      R.pipe(
        R.map(R.prop('provinces')),
        R.values,
        R.mergeAll,
        R.keys,
      )(countries),
      i18nValidation.required,
    )
    .required(i18nValidation.required),

  country: Yup.string()
    .nullable()
    .oneOf(Object.keys(countries), i18nValidation.required)
    .required(i18nValidation.required),

  paypal: Yup.string()
    .nullable()
    .when('country', {
      is: country => !COUNTRIES_WITH_IBAN.includes(country),
      then: Yup.string()
        .nullable()
        .email(i18nValidation.invalidMail)
        .required(i18nValidation.required),
      otherwise: Yup.string()
        .nullable()
        .email(i18nValidation.invalidMail),
    }),

  iban: Yup.string()
    .nullable()
    .when('country', {
      is: country => COUNTRIES_WITH_IBAN.includes(country),
      then: Yup.string()
        .nullable()
        .test('test iban', i18nValidation.invalidIBAN, value =>
          R.isNil(value) ? true : IBAN.isValid(value),
        ),
    }),

  residenceDocumentUpload: Yup.mixed().nullable(),

  currency: Yup.mixed().test(
    'empty',
    i18nValidation.required,
    currency => currency && currency.length,
  ),
})

const individualOrSelfEmployedValidationSchemaFields = {
  identityDocumentUpload: Yup.mixed()
    .nullable()
    .required(i18nValidation.required),

  name: Yup.string().required(i18nValidation.required),

  surname: Yup.string().required(i18nValidation.required),
}

const individualValidationSchema = countries =>
  Yup.object().shape({
    ...commonValidationSchemaFields(countries),
    ...individualOrSelfEmployedValidationSchemaFields,
  })

const selfEmployedValidationSchema = countries =>
  Yup.object().shape({
    ...commonValidationSchemaFields(countries),
    ...individualOrSelfEmployedValidationSchemaFields,

    typeDocumentUpload: Yup.mixed().nullable(),
  })

const companyValidationSchema = countries =>
  Yup.object().shape({
    ...commonValidationSchemaFields(countries),

    businessName: Yup.string().required(i18nValidation.required),
  })

export const billingDataSchema = ({ countries }) =>
  Yup.object().when([], (_, { value }) => {
    if (value.type === PROFESSIONAL_TYPES.INDIVIDUAL) {
      return individualValidationSchema(countries)
    }

    if (value.type === PROFESSIONAL_TYPES.SELF_EMPLOYED) {
      return selfEmployedValidationSchema(countries)
    }

    if (value.type === PROFESSIONAL_TYPES.COMPANY) {
      return companyValidationSchema(countries)
    }

    // Fallback
    return individualValidationSchema(countries)
  })
