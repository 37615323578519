import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { safelyRender, formatBytes } from '../../../src/app/utils'
import { ReactComponent as IconDefaultDownload } from '../../styles/icons/download.svg'
import { ReactComponent as IconDefaultFile } from '../../styles/icons/file-pdf.svg'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const AnchorDownload = ({
  onClick,
  renderDownloadIcon,
  renderFileIcon,
  target,
  isDisabled,
  ...props
}) => {
  const { extraTxt, href, txt, titleTxt } = getFileProps(props)
  const iconClass = 'anchor-download-icon'
  const classes = cx('anchor-download', { 'is-disabled': isDisabled })
  return (
    <a
      className={classes}
      href={href}
      onClick={onClick}
      title={titleTxt}
      target={target}
      download
      disabled={isDisabled}
    >
      {safelyRender(renderFileIcon, { className: iconClass }) || (
        <IconDefaultFile className={iconClass} />
      )}
      <div>
        <p className="anchor-download-text">{txt}</p>
        <p className="anchor-download-extra-text">{extraTxt}</p>
      </div>
      {safelyRender(renderDownloadIcon, { className: iconClass }) || (
        <IconDefaultDownload className={iconClass} />
      )}
    </a>
  )
}

const getFileProps = ({ urlFile, extraText, linkUrl, text, title, i18n }) =>
  urlFile
    ? {
        extraTxt: formatBytes(urlFile.uploadedFile.fileSize),
        href: urlFile.url,
        txt: urlFile.uploadedFile.clientFileName,
        titleTxt: i18n('message:download-document', {
          documentTitle: urlFile.uploadedFile.clientFileName,
        }),
      }
    : { extraTxt: extraText, href: linkUrl, txt: text, titleTxt: title }

AnchorDownload.defaultProps = {
  target: '_self',
  onClick: () => {},
}

AnchorDownload.propTypes = {
  urlFile: CoobisPropTypes.urlFile,
  extraText: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  renderFileIcon: PropTypes.func,
  renderDownloadIcon: PropTypes.func,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  i18n: PropTypes.func,
}

export default AnchorDownload
