import React from 'react'
import PropTypes from 'prop-types'
import OrdersCurrentTable from '../orders/OrdersCurrentTable'
import Card from './Card'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const OrdersCurrentCard = ({
  orders,
  sortValue,
  onSortChange,
  onActionClick,
}) => {
  return (
    <Card isHighlighted>
      <div className="orders-current-card">
        <OrdersCurrentTable
          orders={orders}
          sortValue={sortValue}
          onSortChange={onSortChange}
          onActionClick={onActionClick}
        />
      </div>
    </Card>
  )
}

OrdersCurrentCard.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.currentOrderRow),
  sortValue: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
}

export default OrdersCurrentCard
