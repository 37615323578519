import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import OrderPendingHeadRow from './OrderPendingHeadRow'
import OrderPendingRow from './OrderPendingRow'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID } from '../../app/common/routes'
import useScrollEnds from '../../hooks/useScrollEnds'

const OrdersPendingTable = props => {
  const { orders } = props

  const { handleScroll, setScrollerRef, scrollEnds } = useScrollEnds({
    top: 20,
    bottom: 20,
  })

  const classes = cx('orders-pending-table', {
    'has-top-gradient': !scrollEnds.top,
    'has-bottom-gradient': !scrollEnds.bottom,
  })

  return (
    <div className={classes}>
      <OrderPendingHeadRow />
      <div
        className="orders-pending-table-content"
        ref={setScrollerRef}
        onScroll={handleScroll}
      >
        {orders.map(order => (
          <OrderPendingRow
            key={order.id}
            status={order.status}
            type={order.type}
            campaign={order.campaign.name}
            resource={order.resource.name}
            date={order.date}
            linkUrl={ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.linkTo({
              campaignId: order.campaign.id,
              orderId: order.id,
            })}
          />
        ))}
      </div>
    </div>
  )
}

OrdersPendingTable.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.orderRow),
}

export default OrdersPendingTable
