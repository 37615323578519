import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ReactComponent as HelpIcon } from '../../styles/icons/info.svg'

const HelpLink = ({ url, text, hasIcon }) => {
  return (
    <div className="help-link">
      <Link to={{ pathname: url }} target="_blank">
        {text ? text : url}
        <HelpIcon className="icon" />
      </Link>
    </div>
  )
}

HelpLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string,
  hasIcon: PropTypes.bool,
}

export default HelpLink
