import { compose, renderComponent, withProps } from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import withReviewSteps from './withReviewSteps'
import OrderModalReviewDeliveryApprove from './OrderModalReviewDeliveryApprove'
import OrderModalReviewDeliveryCorrect from './OrderModalReviewDeliveryCorrect'
import { closeOrderModal } from '../../services/orders'
import OrderModalReviewDelivery from '../../ui/order-modals/OrderModalReviewDelivery'
import withFetchOrderIfNeeded from '../common/withFetchOrderIfNeeded'
import { SERVICE_TYPES } from '../../services/orders/business'

const getDeliveryDataByServiceType = orderData => {
  const serviceType = R.path(['product', 'service'], orderData)

  switch (serviceType) {
    case SERVICE_TYPES.WEB_ONLY_POST:
    case SERVICE_TYPES.WEB_POST: {
      return {
        webUrl: R.path(['deliveryPayload', 'url'], orderData),
        facebookUrl: R.path(['deliveryPayload', 'facebook'], orderData),
        twitterUrl: R.path(['deliveryPayload', 'twitter'], orderData),
        instagram: R.path(['deliveryPayload', 'instagram'], orderData),
      }
    }

    case SERVICE_TYPES.INSTAGRAM_STORY: {
      return {
        instagramUrl: R.path(['deliveryPayload', 'urlFile', 'url'], orderData),
        instagramFilename: R.path(
          ['deliveryPayload', 'urlFile', 'uploadedFile', 'clientFileName'],
          orderData,
        ),
      }
    }

    case SERVICE_TYPES.FACEBOOK:
    case SERVICE_TYPES.TWITTER:
    case SERVICE_TYPES.INSTAGRAM_PHOTO:
    case SERVICE_TYPES.INSTAGRAM_VIDEO:
    case SERVICE_TYPES.YOUTUBE_MENTION:
    case SERVICE_TYPES.YOUTUBE_DEDICATED:
    case SERVICE_TYPES.TWITCH:
    case SERVICE_TYPES.TIKTOK_SHORT:
    case SERVICE_TYPES.TIKTOK_LONG: {
      return {
        socialNetworkUrl: R.path(['deliveryPayload', 'url'], orderData),
      }
    }

    default: {
      throw new Error(`Unknown service type ${serviceType}`)
    }
  }
}

const enhance = compose(
  withFetchOrderIfNeeded({ modalTitleI18nKey: 'action:review-delivery' }),

  withProps(({ orderData }) => {
    const deliveryData = getDeliveryDataByServiceType(orderData)

    return {
      mediaComment: R.path(['deliveryPayload', 'comments'], orderData),
      ...deliveryData,
    }
  }),

  connect(
    null,
    {
      onCancelClick: closeOrderModal,
    },
  ),

  withReviewSteps(
    renderComponent(OrderModalReviewDeliveryApprove),
    renderComponent(OrderModalReviewDeliveryCorrect),
  ),
)

export default enhance(OrderModalReviewDelivery)
