import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import SocialResourceHeader from './SocialResourceHeader'
import Button from '@redradix/components.button'
import { IconSocial, LogoSocial } from '../icons/IconSocial'
import image from './images/social-resource-image.png'
import HelpLink from '../../ui/messages/HelpLink'

import {
  RESOURCE_TYPES,
  SOCIAL_NETWORK_RESOURCE_TYPES,
  mapResourceTypeToI18N,
  mapResourceTypeToSocialConnectionI18N,
  mapResourceTypeToSocialConnectionProviderI18N,
} from '../../services/resources/constants'

const mapSocialNetworkToButtonClassname = {
  [RESOURCE_TYPES.FACEBOOK]: 'facebook-button',
  [RESOURCE_TYPES.TWITTER]: 'twitter-button',
  [RESOURCE_TYPES.INSTAGRAM]: 'facebook-button',
  [RESOURCE_TYPES.YOUTUBE]: 'youtube-button',
  [RESOURCE_TYPES.TWITCH]: 'twitch-button',
  [RESOURCE_TYPES.TIKTOK]: 'tiktok-button',
}

const helpLinks = {
  [RESOURCE_TYPES.INSTAGRAM]: {
    url: 'web-resources:instagram-business-help-link',
    text: 'web-resources:instagram-business-help-link-text',
    hasIcon: true,
  },
}

const SocialResource = ({
  title,
  currentNavItem,
  reachedNavItem,
  resourceId,
  hasSocialHeader,
  isConnectionDisabled,
  channel,
  isConnectionPending,
  isConnected,
  onClickConnect,
  children,
  i18n,
}) => (
  <div className="steps-layout">
    <SocialResourceHeader
      currentNavItem={currentNavItem}
      reachedNavItem={reachedNavItem}
      resourceId={resourceId}
      socialNetwork={channel}
    />
    <div className="steps-layout-content">
      {title && (
        <h1 className="step-title">
          {currentNavItem && <span className="step">{currentNavItem}</span>}
          <span className="text">{title}</span>
        </h1>
      )}
      {hasSocialHeader && (
        <SocialHeader
          channel={channel}
          logoIncludesName={channel === RESOURCE_TYPES.YOUTUBE}
          socialConnection={
            channel === RESOURCE_TYPES.TIKTOK
              ? undefined
              : {
                  isConnected,
                  isConnectionPending,
                  isConnectionDisabled,
                  onClickConnect,
                  helpLink: helpLinks[channel],
                }
          }
          i18n={i18n}
        />
      )}
      {children}
    </div>
  </div>
)

const SocialHeader = ({
  channel,
  logoIncludesName,
  socialConnection,
  i18n,
}) => {
  const channelName = i18n(mapResourceTypeToI18N[channel])
  return (
    <div className="steps-layout-content-social-header">
      <div className="steps-layout-content-social-header-data">
        <p className="social-name">
          {logoIncludesName && <LogoSocial type={channel} />}
          {!logoIncludesName && (
            <>
              <IconSocial type={channel} />
              <span>{channelName}</span>
            </>
          )}
        </p>
        {socialConnection && (
          <SocialConnection
            channel={channel}
            {...socialConnection}
            i18n={i18n}
          />
        )}
      </div>
    </div>
  )
}

const helpLinkProp = PropTypes.shape({
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hasIcon: PropTypes.bool.isRequired,
})

SocialHeader.propTypes = {
  channel: PropTypes.string.isRequired,
  logoIncludesName: PropTypes.bool.isRequired,
  socialConnection: PropTypes.shape({
    isConnected: PropTypes.bool.isRequired,
    isConnectionPending: PropTypes.bool.isRequired,
    isConnectionDisabled: PropTypes.bool.isRequired,
    onClickConnect: PropTypes.func.isRequired,
    helpLink: helpLinkProp,
  }),
}

const SocialConnection = ({
  channel,
  isConnected,
  isConnectionPending,
  isConnectionDisabled,
  onClickConnect,
  helpLink,
  i18n,
}) => {
  const connectionName = i18n(mapResourceTypeToSocialConnectionI18N[channel])
  const providerName = i18n(
    mapResourceTypeToSocialConnectionProviderI18N[channel],
  )
  return (
    <>
      <img src={image} className="social-image" alt="social" />
      <p className="social-title modal-title">
        {i18n(
          isConnected
            ? 'social-resource:connection-info-already-connected'
            : 'social-resource:connection-info',
          { connectionName },
        )}
      </p>
      <div className="social-actions">
        <Button
          heightBehaviour="adaptative"
          type="button"
          isLoading={isConnectionPending}
          isDisabled={isConnectionDisabled}
          className={mapSocialNetworkToButtonClassname[channel]}
          onClick={onClickConnect}
        >
          {isConnected
            ? i18n('social-resource:update-connected-account')
            : i18n('social-resource:connect-provider', {
                providerName,
              })}
        </Button>
      </div>
      {helpLink && (
        <HelpLink
          url={i18n(helpLink.url)}
          text={i18n(helpLink.text)}
          hasIcon={helpLink.hasIcon}
        />
      )}
    </>
  )
}

SocialConnection.propTypes = {
  channel: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isConnectionPending: PropTypes.bool.isRequired,
  isConnectionDisabled: PropTypes.bool.isRequired,
  onClickConnect: PropTypes.func.isRequired,
  helpLink: helpLinkProp,
}

SocialResource.propTypes = {
  title: PropTypes.string,
  currentNavItem: PropTypes.number,
  reachedNavItem: PropTypes.number,
  resourceId: PropTypes.string,
  hasSocialHeader: PropTypes.bool,
  isConnectionDisabled: PropTypes.bool,
  isConnected: PropTypes.bool,
  channel: PropTypes.oneOf(SOCIAL_NETWORK_RESOURCE_TYPES),
  children: PropTypes.node,
  isConnectionPending: PropTypes.bool,
  onClickConnect: PropTypes.func,
}

export default withTranslations(SocialResource)
