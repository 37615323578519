import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import Spinner from '@redradix/components.spinner'
import { ReactComponent as Spin } from '../../styles/icons/spinner.svg'

const AnchorButton = props => {
  const {
    children,
    isDisabled,
    isLoading,
    linkUrl,
    title,
    hierarchy,
    variant,
    heightBehaviour,
    widthBehaviour,
    onClick,
    isIconButton,
    isNative,
    download,
    className,
  } = props
  const classes = cx(
    `rdx-button rdx-button-${hierarchy}--${variant} has-height-${heightBehaviour} has-width-${widthBehaviour}`,
    className,
    {
      'is-loading': isLoading,
      'is-disabled': isDisabled,
      'icon-button': isIconButton,
    },
  )

  const content = (
    <>
      {children}
      {isLoading && !isDisabled && variant === 'default' ? (
        <Spinner isLoading>
          <Spin className="icon rdx-button-icon" />
        </Spinner>
      ) : null}
    </>
  )

  return isNative ? (
    <a
      className={classes}
      href={linkUrl}
      title={title}
      onClick={onClick}
      download={download}
    >
      {content}
    </a>
  ) : (
    <Link className={classes} to={linkUrl} title={title} onClick={onClick}>
      {content}
    </Link>
  )
}

AnchorButton.defaultProps = {
  heightBehaviour: 'fixed',
  hierarchy: 'primary',
  variant: 'default',
  widthBehaviour: 'adaptative',
}

AnchorButton.propTypes = {
  /** Content wrapped by the <Button> */
  children: PropTypes.node.isRequired,
  /** String that sets if the <Button> has to grow with the content on Y axis */
  heightBehaviour: PropTypes.oneOf(['adaptative', 'fixed']),
  /** String that defines the category of the <Button> */
  hierarchy: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /** Boolean indicating whether the <Button> should be rendered as disabled */
  isDisabled: PropTypes.bool,
  /** Boolean indicating whether the <Button> should be rendered as loading */
  isLoading: PropTypes.bool,
  /** String that shows the title of the <Button> as a native browser tooltip */
  title: PropTypes.string,
  /** String that defines the variant of the <Button> that influences the styles */
  variant: PropTypes.oneOf(['default', 'alternate', 'destruct', 'success']),
  /** String that sets if the <Button> has to grow with the content on X axis */
  widthBehaviour: PropTypes.oneOf(['adaptative', 'fixed', 'full']),
  linkUrl: PropTypes.string,
  onClick: PropTypes.func,
  isIconButton: PropTypes.bool,
  isNative: PropTypes.bool,
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
}

export default AnchorButton
