import React from 'react'
import PropTypes from 'prop-types'

const ProfileDataSection = ({ title, description, children }) => {
  return (
    <section className="profile-data-section">
      {(title || description) && (
        <header className="profile-data-section-header">
          {title && <h2 className="profile-data-section-title">{title}</h2>}
          {description && (
            <p className="profile-data-section-description">{description}</p>
          )}
        </header>
      )}
      <div className="profile-data-section-contents">{children}</div>
    </section>
  )
}

ProfileDataSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
}

export default ProfileDataSection
