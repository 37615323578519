import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import Button from '@redradix/components.button'
import { safelyRender } from '../../../src/app/utils'
import { ReactComponent as IconDefault } from '../../styles/icons/picture.svg'

const ResourcePlaceholder = ({ onCardClick, renderIcon, i18n }) => {
  return (
    <div className="resource-placeholder">
      <div className="resource-placeholder-image">
        {safelyRender(renderIcon, { className: 'icon' }) || (
          <IconDefault className="icon" />
        )}
      </div>
      <h3 className="resource-placeholder-title">
        {i18n('resources:placeholder-card-title')}
      </h3>
      <div className="resource-placeholder-subtitle">
        {i18n('resources:placeholder-card-subtitle')}
      </div>
      <div className="resource-placeholder-action">
        <Button
          type="button"
          hierarchy="tertiary"
          variant="default"
          widthBehaviour="adaptative"
          heightBehaviour="fixed"
          onClick={onCardClick}
        >
          {i18n('action:add-new-resource')}
        </Button>
      </div>
    </div>
  )
}

ResourcePlaceholder.propTypes = {
  onCardClick: PropTypes.func,
  renderIcon: PropTypes.func,
  // withTranslations
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourcePlaceholder)
