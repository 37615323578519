import { compose, withHandlers, withProps, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import OrderModalReviewCounterbriefing from '../../ui/order-modals/OrderModalReviewCounterbriefing'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import {
  acceptOrderCounterbriefing,
  rejectOrderCounterbriefing,
} from '../../services/orders'
import {
  ACCEPT_ORDER_COUNTERBRIEFING,
  REJECT_ORDER_COUNTERBRIEFING,
} from '../../services/orders/action-types'
import { deleteRequest } from '../../services/communication'
import withFetchOrderIfNeeded from '../common/withFetchOrderIfNeeded'

const enhance = compose(
  withFetchOrderIfNeeded({
    modalTitleI18nKey: 'action:review-counterbriefing',
  }),

  withProps(({ orderData }) => ({
    text: orderData.briefingChangesPayload
      ? orderData.briefingChangesPayload.comments
      : '',
  })),

  connect(
    null,
    {
      acceptOrderCounterbriefing,
      rejectOrderCounterbriefing,
      deleteRequest,
    },
  ),

  withIsRequestPending(ACCEPT_ORDER_COUNTERBRIEFING, 'isAcceptRequestPending'),
  withIsRequestSucceeded(
    ACCEPT_ORDER_COUNTERBRIEFING,
    'isAcceptRequestSucceeded',
  ),
  withRequestError(ACCEPT_ORDER_COUNTERBRIEFING, 'acceptRequestError'),
  withDeleteRequestOnUnmount(ACCEPT_ORDER_COUNTERBRIEFING),

  withIsRequestPending(REJECT_ORDER_COUNTERBRIEFING, 'isRejectRequestPending'),
  withIsRequestSucceeded(
    REJECT_ORDER_COUNTERBRIEFING,
    'isRejectRequestSucceeded',
  ),
  withRequestError(REJECT_ORDER_COUNTERBRIEFING, 'rejectRequestError'),
  withDeleteRequestOnUnmount(REJECT_ORDER_COUNTERBRIEFING),

  withPropsOnChange(
    ['acceptRequestError', 'rejectRequestError'],
    ({ acceptRequestError, rejectRequestError }) => ({
      globalMessages:
        !!acceptRequestError && !!acceptRequestError.globalMessages
          ? acceptRequestError.globalMessages
          : !!rejectRequestError && !!rejectRequestError.globalMessages
          ? rejectRequestError.globalMessages
          : undefined,
    }),
  ),

  withHandlers({
    onAccept: props => _ => {
      props.deleteRequest(ACCEPT_ORDER_COUNTERBRIEFING)
      props.deleteRequest(REJECT_ORDER_COUNTERBRIEFING)
      props.acceptOrderCounterbriefing(props.orderId)
    },
    onReject: props => _ => {
      props.deleteRequest(ACCEPT_ORDER_COUNTERBRIEFING)
      props.deleteRequest(REJECT_ORDER_COUNTERBRIEFING)
      props.rejectOrderCounterbriefing(props.orderId)
    },
  }),
)

export default enhance(OrderModalReviewCounterbriefing)
