import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import Button from '@redradix/components.button'
import Checkbox from '@redradix/components.checkbox'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  BRIEFING_UPLOAD_CONDITIONS,
  BRIEFING_UPLOAD_CONDITIONS_NORMAL,
  MAX_SAMPLE_MESSAGE_CHARACTERS_TWITTER,
} from '../../services/checkout/business'
import TabsTitle from '../texts/TabsTitle'
import AnchorLinkButton from '../texts/AnchorLinkButton'
import withTranslations from '../../hocs/withTranslations'

import Input from '../form-elements/Input'
import Textarea from '../form-elements/Textarea'

import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import Select from '../form-elements/Select'
import InputFile from '../form-elements/InputFile'
import Modal from '../modals/Modal'
import PreviewModalContent from '../modals/PreviewModalContent'
import PreviewBoxTwitter from '../previews/PreviewBoxTwitter'

const ALLOWED_DOCUMENT_EXTENSIONS = '.jpg,.png,.pdf,.docx'

const BriefingTwitterForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,

  title,
  conditionOptions,
  onClickPreview,
  onClickClosePreview,
  isPreviewModalAvailable,
  isPreviewModalOpen,
  downloadFileLink,
}) => {
  return (
    <form className="briefing-form" onSubmit={handleSubmit}>
      <div className="tabs-header">
        <TabsTitle text={title} />
        <AnchorLinkButton
          small
          onClick={onClickPreview}
          type="button"
          text={i18n('noun:preview')}
          isDisabled={!isPreviewModalAvailable}
        />
      </div>
      <Textarea
        name="description"
        label={i18n('checkout:post-description')}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('checkout:post-description-long')}
        isTouched={touched.description}
        isDisabled={isRequestPending}
        isRequired
        errorText={
          validationMessages.description && (
            <FormattedFormErrors errors={validationMessages.description} />
          )
        }
      />
      <Input
        type="text"
        name="hashtags"
        label={i18n('checkout:campaign-hashtags')}
        widthBehaviour="full"
        value={values.hashtags}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.hashtags}
        isDisabled={isRequestPending}
        isMulti={true}
        multiPrefix="#"
        errorText={
          validationMessages.hashtags && (
            <FormattedFormErrors errors={validationMessages.hashtags} />
          )
        }
      />
      <Checkbox
        name="isAdvertising"
        checked={values.isAdvertising}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.isAdvertising}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.isAdvertising && (
            <FormattedFormErrors errors={validationMessages.isAdvertising} />
          )
        }
      >
        <span className="text">{i18n('checkout:advertising-warning')}</span>
      </Checkbox>
      <Input
        type="text"
        name="userMentions"
        label={i18n('checkout:mention-user')}
        widthBehaviour="full"
        value={values.userMentions}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.userMentions}
        isDisabled={isRequestPending}
        isMulti={true}
        multiPrefix="@"
        errorText={
          validationMessages.userMentions && (
            <FormattedFormErrors errors={validationMessages.userMentions} />
          )
        }
      />
      <Input
        type="text"
        name="url"
        label={i18n('checkout:link-url')}
        placeholder={i18n('checkout:link-url')}
        widthBehaviour="full"
        value={values.url}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.url}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.url && (
            <FormattedFormErrors errors={validationMessages.url} />
          )
        }
      />
      <Select
        name="conditionId"
        label={i18n('checkout:image-condition')}
        options={conditionOptions}
        value={values.conditionId}
        isTouched={touched.conditionId}
        isDisabled={isRequestPending}
        onChange={item =>
          handleChange({ target: { name: 'conditionId', value: item.value } })
        }
        onBlur={_ => handleBlur({ target: { name: 'conditionId' } })}
        errorText={
          validationMessages.conditionId && (
            <FormattedFormErrors errors={validationMessages.conditionId} />
          )
        }
      />
      <Textarea
        name="sampleMessage"
        label={i18n('checkout:sample-message')}
        value={values.sampleMessage}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('checkout:sample-message')}
        isTouched={touched.sampleMessage}
        isDisabled={isRequestPending}
        maxCharacters={MAX_SAMPLE_MESSAGE_CHARACTERS_TWITTER}
        errorText={
          validationMessages.sampleMessage && (
            <FormattedFormErrors errors={validationMessages.sampleMessage} />
          )
        }
      />
      <InputFile
        label={i18n('checkout:images-videos-docs')}
        name="file"
        widthBehaviour="full"
        id="file"
        accept={ALLOWED_DOCUMENT_EXTENSIONS}
        linkText={i18n('action:download-file')}
        linkUrl={downloadFileLink}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('action:upload-document')}
        fileName={R.path(['file', 'name'], values)}
        isUploaded={R.path(['file', 'isUploaded'], values)}
        isTouched={touched.file}
        isDisabled={isRequestPending}
        isRequired={
          values.conditionId.value === BRIEFING_UPLOAD_CONDITIONS.BRAND
        }
        errorText={
          validationMessages.file && (
            <FormattedFormErrors errors={validationMessages.file} />
          )
        }
      />

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}
      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
      {isPreviewModalOpen && (
        <Modal onClose={onClickClosePreview} isGrey>
          <PreviewModalContent>
            <PreviewBoxTwitter
              avatar="https://i.pravatar.cc/200"
              text={values.sampleMessage}
            />
          </PreviewModalContent>
        </Modal>
      )}
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  description: PropTypes.string,
  hashtags: PropTypes.string,
  userMentions: PropTypes.string,
  url: PropTypes.string,
  conditionId: CoobisPropTypes.makeSelectOption(
    PropTypes.oneOf(BRIEFING_UPLOAD_CONDITIONS_NORMAL),
  ),
  sampleMessage: PropTypes.string,
  file: PropTypes.any,
  isAdvertising: PropTypes.bool,
}

BriefingTwitterForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClickPreview: PropTypes.func,
  onClickClosePreview: PropTypes.func,
  isPreviewModalAvailable: PropTypes.bool,
  isPreviewModalOpen: PropTypes.bool,
  downloadFileLink: PropTypes.string,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(BriefingTwitterForm)
