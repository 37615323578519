import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Checkbox from '@redradix/components.checkbox'
import { Input } from '@redradix/components.input'
import Button from '@redradix/components.button'
import Tooltip from '@redradix/components.tooltip'
import FormattedFormErrors from '../FormattedFormErrors'
import { ReactComponent as Rocket } from './../../styles/icons/rocket.svg'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import WarningMessage from '../messages/WarningMessage'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import withTranslations from '../../hocs/withTranslations'
import Select from '../form-elements/Select'
import Datepicker from '../form-elements/Datepicker'
import PaperCheckbox from '../papers/PaperCheckbox'

const TIME_OPTIONS = Array(24)
  .fill()
  .map((_, i) => ({ hours: i, minutes: 0 }))

const CheckoutGeneralDataForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  campaignOptions,
  isVIPBrand,
  dateInfo,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  return (
    <form className="general-data-form" onSubmit={handleSubmit}>
      {isVIPBrand && (
        <PaperCheckbox
          id="express"
          name="express"
          checked={values.express}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.express}
          isDisabled={isRequestPending}
          errorText={
            validationMessages.express && (
              <FormattedFormErrors errors={validationMessages.express} />
            )
          }
          renderLabelIcon={props => <Rocket {...props} />}
        >
          <span className="text">{i18n('checkout:express-order')}</span>
        </PaperCheckbox>
      )}

      <Select
        name="campaign"
        label={i18n('noun:campaign')}
        placeholder={i18n('noun:campaign')}
        options={campaignOptions}
        value={values.campaign}
        isTouched={touched.campaign}
        isDisabled={isRequestPending}
        onChange={item =>
          handleChange({ target: { name: 'campaign', value: item.value } })
        }
        onBlur={_ => handleBlur({ target: { name: 'campaign' } })}
        errorText={
          validationMessages.campaign && (
            <FormattedFormErrors errors={validationMessages.campaign} />
          )
        }
        isRequired
      />

      <Input
        type="text"
        name="brand"
        label={i18n('checkout:product-or-brand-short')}
        placeholder={i18n('checkout:product-or-brand-long')}
        widthBehaviour="full"
        value={values.brand}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.brand}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.brand && (
            <FormattedFormErrors errors={validationMessages.brand} />
          )
        }
        isRequired
      />

      <Input
        type="text"
        name="url"
        label={i18n('checkout:web-or-brand-short')}
        placeholder={i18n('checkout:web-or-brand-long')}
        widthBehaviour="full"
        value={values.url}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.url}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.url && (
            <FormattedFormErrors errors={validationMessages.url} />
          )
        }
        isRequired
      />

      <Checkbox
        name="preDelivery"
        checked={values.preDelivery}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.preDelivery}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.preDelivery && (
            <FormattedFormErrors errors={validationMessages.preDelivery} />
          )
        }
      >
        <span className="text">{i18n('checkout:pre-delivery-service')}</span>

        <Tooltip
          position="bottom"
          widthFixed
          renderTooltip={() => (
            <TooltipRegularText
              text={i18n('checkout:pre-delivery-service-description')}
            />
          )}
          renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
            <TooltipTrigger
              classNames={triggerClasses}
              onMouseOver={open}
              onMouseOut={close}
              ref={triggerRef}
            />
          )}
        />
      </Checkbox>

      {!values.express && (
        <>
          <Datepicker
            id="acceptDate"
            name="acceptDate"
            timeOptions={TIME_OPTIONS}
            value={values.acceptDate}
            onChange={handleChange}
            onBlur={handleBlur}
            datepickerLabel={i18n('noun:acceptance-date')}
            datepickerSubtitle={i18n('description:acceptance-date-brand')}
            modalTitle={i18n('checkout:choose-date-hour')}
            isTouched={touched.acceptDate}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.acceptDate && (
                <FormattedFormErrors errors={validationMessages.acceptDate} />
              )
            }
            minDateTime={dateInfo.acceptDate.min}
            maxDateTime={dateInfo.acceptDate.max}
            placeholder={dateInfo.acceptDate.default}
          />

          {values.preDelivery && (
            <>
              <Datepicker
                id="sendToReviewDate"
                name="sendToReviewDate"
                timeOptions={TIME_OPTIONS}
                value={values.sendToReviewDate}
                onChange={handleChange}
                onBlur={handleBlur}
                datepickerLabel={i18n('noun:submission-review-date')}
                datepickerSubtitle={i18n(
                  'description:submission-review-date-brand',
                )}
                modalTitle={i18n('checkout:choose-date-hour')}
                isTouched={touched.sendToReviewDate}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.sendToReviewDate && (
                    <FormattedFormErrors
                      errors={validationMessages.sendToReviewDate}
                    />
                  )
                }
                minDateTime={dateInfo.sendToReviewDate.min}
                maxDateTime={dateInfo.sendToReviewDate.max}
                placeholder={dateInfo.sendToReviewDate.default}
              />

              <Datepicker
                id="validateReviewDate"
                name="validateReviewDate"
                timeOptions={TIME_OPTIONS}
                value={values.validateReviewDate}
                onChange={handleChange}
                onBlur={handleBlur}
                datepickerLabel={i18n('noun:review-date')}
                datepickerSubtitle={i18n('description:review-date-brand')}
                modalTitle={i18n('checkout:choose-date-hour')}
                isTouched={touched.validateReviewDate}
                isDisabled={isRequestPending}
                position="bottom"
                errorText={
                  validationMessages.validateReviewDate && (
                    <FormattedFormErrors
                      errors={validationMessages.validateReviewDate}
                    />
                  )
                }
                minDateTime={dateInfo.validateReviewDate.min}
                maxDateTime={dateInfo.validateReviewDate.max}
                placeholder={dateInfo.validateReviewDate.default}
              />
            </>
          )}

          <Datepicker
            id="deliveryDate"
            name="deliveryDate"
            timeOptions={TIME_OPTIONS}
            value={values.deliveryDate}
            onChange={handleChange}
            onBlur={handleBlur}
            datepickerLabel={i18n('noun:delivery-date')}
            datepickerSubtitle={i18n('description:delivery-date-brand')}
            modalTitle={i18n('checkout:choose-date-hour')}
            isTouched={touched.deliveryDate}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.deliveryDate && (
                <FormattedFormErrors errors={validationMessages.deliveryDate} />
              )
            }
            minDateTime={dateInfo.deliveryDate.min}
            maxDateTime={dateInfo.deliveryDate.max}
            placeholder={dateInfo.deliveryDate.default}
          />

          <Datepicker
            id="approveDate"
            name="approveDate"
            timeOptions={TIME_OPTIONS}
            value={values.approveDate}
            onChange={handleChange}
            onBlur={handleBlur}
            datepickerLabel={i18n('noun:approval-date')}
            datepickerSubtitle={i18n('description:approval-date-brand')}
            modalTitle={i18n('checkout:choose-date-hour')}
            isTouched={touched.approveDate}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.approveDate && (
                <FormattedFormErrors errors={validationMessages.approveDate} />
              )
            }
            minDateTime={dateInfo.approveDate.min}
            maxDateTime={dateInfo.approveDate.max}
            placeholder={dateInfo.approveDate.default}
          />
        </>
      )}

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  express: PropTypes.bool,
  campaign: CoobisPropTypes.makeSelectOption(PropTypes.number),
  brand: PropTypes.string,
  url: PropTypes.string,
  preDelivery: PropTypes.bool,

  acceptDate: PropTypes.any,
  sendToReviewDate: PropTypes.any,
  validateReviewDate: PropTypes.any,
  deliveryDate: PropTypes.any,
  approveDate: PropTypes.any,
}

const dateInfoPropType = PropTypes.shape({
  min: PropTypes.instanceOf(moment),
  max: PropTypes.instanceOf(moment),
  default: PropTypes.instanceOf(moment),
})

CheckoutGeneralDataForm.propTypes = {
  campaignOptions: PropTypes.arrayOf(
    CoobisPropTypes.makeSelectOption(PropTypes.number),
  ).isRequired,
  isVIPBrand: PropTypes.bool,
  dateInfo: PropTypes.shape({
    acceptDate: dateInfoPropType.isRequired,
    sendToReviewDate: dateInfoPropType.isRequired,
    validateReviewDate: dateInfoPropType.isRequired,
    deliveryDate: dateInfoPropType.isRequired,
    approveDate: dateInfoPropType.isRequired,
  }).isRequired,
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(CheckoutGeneralDataForm)
