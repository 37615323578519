import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as BellIcon } from '../../styles/icons/bell.svg'

const AlertsIndicator = ({ onClick, numberAlerts }) => {
  return (
    <button className="alerts-indicator" onClick={onClick}>
      <BellIcon className="alerts-indicator-icon" />
      {numberAlerts > 0 ? (
        <span className="alerts-indicator-number">{numberAlerts}</span>
      ) : null}
    </button>
  )
}

AlertsIndicator.propTypes = {
  onClick: PropTypes.func,
  numberAlerts: PropTypes.number,
}

export default AlertsIndicator
