import { compose, withPropsOnChange, withStateHandlers } from 'recompose'
import CampaignDetailYoutubeAll from '../../ui/campaigns/views/CampaignDetailYoutubeAll'
import {
  mapServiceTypeToOrderTypeI18n,
  SERVICE_TYPES,
} from '../../services/orders/business'
import withTranslations from '../../hocs/withTranslations'
import withPublicationsTable from './withPublicationsTable'
import { getUserBarChartData } from '../common/statistics-utils'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  publications: stats.kpis.totalOrders,
  influencers: stats.kpis.totalResources,
  engagements: stats.kpis.totalEngagements,
  views: stats.kpis.totalViews,
  averageVisualization: stats.kpis.averagePlayTime,
}))

const withYoutubePublicationsTable = compose(
  withTranslations,

  withPublicationsTable(
    (orderStatsDetail, { i18n }) => ({
      service: i18n(
        mapServiceTypeToOrderTypeI18n[orderStatsDetail.serviceType],
      ),
      engagements: orderStatsDetail.kpis.engagements,
      views: orderStatsDetail.kpis.views,
      likes: orderStatsDetail.kpis.likes,
    }),
    ['i18n'],
  ),
)

const withStatsSelectors = compose(
  withPropsOnChange(['i18n'], ({ i18n }) => ({
    services: [
      {
        label: i18n(
          mapServiceTypeToOrderTypeI18n[SERVICE_TYPES.YOUTUBE_MENTION],
        ),
        value: SERVICE_TYPES.YOUTUBE_MENTION,
      },
      {
        label: i18n(
          mapServiceTypeToOrderTypeI18n[SERVICE_TYPES.YOUTUBE_DEDICATED],
        ),
        value: SERVICE_TYPES.YOUTUBE_DEDICATED,
      },
    ],
  })),

  withPropsOnChange(['stats'], ({ stats: { stats } }) => ({
    hasMentionsFullData:
      ((stats.viewsByResourceMentionVideo &&
        stats.viewsByResourceMentionVideo.length > 0) ||
        (stats.engagementsByResourceMentionVideo &&
          stats.engagementsByResourceMentionVideo.length > 0)) &&
      ((stats.viewsByResourceDedicatedVideo &&
        stats.viewsByResourceDedicatedVideo.length > 0) ||
        (stats.engagementsByResourceDedicatedVideo &&
          stats.engagementsByResourceDedicatedVideo.length > 0)),
  })),

  withStateHandlers(
    () => ({
      viewsByResourceFilter: SERVICE_TYPES.YOUTUBE_MENTION,
      engagementsByResourceFilter: SERVICE_TYPES.YOUTUBE_MENTION,
    }),
    {
      onViewsServiceChange: _ => ({ value }) => ({
        viewsByResourceFilter: value,
      }),
      onEngagementsServiceChange: _ => ({ value }) => ({
        engagementsByResourceFilter: value,
      }),
    },
  ),

  withPropsOnChange(
    ['services', 'viewsByResourceFilter'],
    ({ services, viewsByResourceFilter }) => ({
      viewsSelectorValue: services.find(
        ({ value }) => value === viewsByResourceFilter,
      ),
    }),
  ),

  withPropsOnChange(
    ['services', 'engagementsByResourceFilter'],
    ({ services, engagementsByResourceFilter }) => ({
      engagementsSelectorValue: services.find(
        ({ value }) => value === engagementsByResourceFilter,
      ),
    }),
  ),

  withPropsOnChange(
    ['viewsByResourceFilter', 'stats', 'i18n'],
    ({ viewsByResourceFilter, stats, i18n }) => ({
      viewsData: getUserBarChartData(
        viewsByResourceFilter === SERVICE_TYPES.YOUTUBE_MENTION
          ? stats.stats.viewsByResourceMentionVideo
          : stats.stats.viewsByResourceDedicatedVideo,
        i18n,
      ),
    }),
  ),

  withPropsOnChange(
    ['engagementsByResourceFilter', 'stats', 'i18n'],
    ({ engagementsByResourceFilter, stats, i18n }) => ({
      engagementsData: getUserBarChartData(
        engagementsByResourceFilter === SERVICE_TYPES.YOUTUBE_MENTION
          ? stats.stats.engagementsByResourceMentionVideo
          : stats.stats.engagementsByResourceDedicatedVideo,
        i18n,
      ),
    }),
  ),
  withExportReportContents(),
)

const enhance = compose(
  withKPIs,
  withYoutubePublicationsTable,
  withStatsSelectors,
)

export default enhance(CampaignDetailYoutubeAll)
