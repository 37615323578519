import { compose, lifecycle, withPropsOnChange, branch } from 'recompose'
import * as R from 'ramda'
import { connect } from 'react-redux'
import CheckoutGeneralDataForm from '../../ui/checkout/CheckoutGeneralDataForm'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import {
  SUBMIT_GENERAL_DATA,
  FETCH_DATES_CONFIG,
} from '../../services/checkout/action-types'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import {
  withCampaigns,
  withCampaignsSelectOptions,
  withSelectCampaignAfterCreation,
} from '../../services/campaigns/hocs'
import withSpinner from '../../hocs/withSpinner'
import { FETCH_CAMPAIGNS } from '../../services/campaigns/action-types'
import { momentToISO8601WithTimezone } from '../utils'

import { withIsVIPBrand } from '../../services/brand/hocs'
import { withDatesConfig, withCart } from '../../services/checkout/hocs'
import { submitGeneralData } from '../../services/checkout'
import { validateYupSchema, yupToFormErrors } from 'formik'
import {
  cartToGeneralDataValues,
  DATE_FIELDS,
} from '../../services/checkout/business'
import {
  generalDataValidationSchema,
  getYupHoursContext,
} from '../../services/checkout/validation'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { getDateInfoFromDateConfig } from '../../services/order-dates'

const enhance = compose(
  withCart,
  withIsVIPBrand,
  withDatesConfig,
  withCampaigns,

  lifecycle({
    componentDidMount() {
      this.props.fetchCampaigns()
    },
  }),

  // Show spinner only if campaigns haven't been loaded yet
  branch(({ campaigns }) => R.isNil(campaigns), withSpinner(FETCH_CAMPAIGNS)),
  withSpinner(FETCH_DATES_CONFIG),

  connect(
    null,
    { submitGeneralData },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: cartToGeneralDataValues,

    selectFields: ['campaign'],
    dateFields: DATE_FIELDS,

    validate: (values, { datesConfig }) => {
      const dateInfo = getDateInfoFromDateConfig(
        values.preDelivery,
        R.pick(DATE_FIELDS, values),
        datesConfig,
      )

      const context = !values.express
        ? getYupHoursContext(dateInfo, datesConfig)
        : undefined

      try {
        validateYupSchema(values, generalDataValidationSchema, true, context)
      } catch (err) {
        return yupToFormErrors(err)
      }

      return {}
    },

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const dateInfo = getDateInfoFromDateConfig(
        values.preDelivery,
        R.pick(DATE_FIELDS, values),
        props.datesConfig,
      )

      const valuesToSend = R.pipe(
        // Add default dates for missing dates
        R.mergeLeft(
          R.mapObjIndexed(
            (value, field) => (!values[field] ? value.default : values[field]),
            dateInfo,
          ),
        ),

        // Do net send predelivery dates if predelivery isn't selected
        !values.preDelivery
          ? R.omit(['sendToReviewDate', 'validateReviewDate'])
          : R.identity,

        // Do not send any date if order is express
        values.express ? R.omit(DATE_FIELDS) : R.identity,

        // Send dates to backend including timezones
        R.evolve({
          acceptDate: momentToISO8601WithTimezone,
          deliveryDate: momentToISO8601WithTimezone,
          approveDate: momentToISO8601WithTimezone,
          sendToReviewDate: momentToISO8601WithTimezone,
          validateReviewDate: momentToISO8601WithTimezone,
        }),
      )(values)

      props.submitGeneralData(valuesToSend)
    },
  }),

  withIsRequestPending(SUBMIT_GENERAL_DATA),
  withIsRequestSucceeded(SUBMIT_GENERAL_DATA),
  withRequestError(SUBMIT_GENERAL_DATA),
  withDeleteRequestOnUnmount(SUBMIT_GENERAL_DATA),
  withFormErrors([
    'express',
    'campaign',
    'brand',
    'url',
    'preDelivery',
    ...DATE_FIELDS,
  ]),

  withSelectCampaignAfterCreation,
  withCampaignsSelectOptions,

  withPropsOnChange(['values', 'datesConfig'], ({ values, datesConfig }) => ({
    dateInfo: getDateInfoFromDateConfig(
      values.preDelivery,
      R.pick(DATE_FIELDS, values),
      datesConfig,
    ),
  })),
)

export default enhance(CheckoutGeneralDataForm)
