export const FILTERS = {
  searchbox: 'searchbox',
  channels: 'channels',
  price: 'price',
  language: 'language',
  country: 'country',
  categories: 'categories',
  monthlyUsers: 'monthlyUsers',
  pageAuthority: 'pageAuthority',
  domainAuthority: 'domainAuthority',
  facebookFollowers: 'facebookFollowers',
  twitterFollowers: 'twitterFollowers',
  instagramFollowers: 'instagramFollowers',
  youtubeFollowers: 'youtubeFollowers',
  twitchFollowers: 'twitchFollowers',
  tiktokFollowers: 'tiktokFollowers',
  maleAudience: 'maleAudience',
  femaleAudience: 'femaleAudience',
  age: 'age',
  locationAudience: 'locationAudience',
  engagement: 'engagement',
  acceptanceRate: 'acceptanceRate',
  withDiscount: 'withDiscount',
  acceptsBroadcast: 'acceptsBroadcast',
  acceptsFollowLinks: 'acceptsFollowLinks',
  rejectsWritingArticles: 'rejectsWritingArticles',
  alwaysMarksSponsoredContent: 'alwaysMarksSponsoredContent',
  mayMarkSponsoredContent: 'mayMarkSponsoredContent',
  didntWorkWithThem: 'didntWorkWithThem',
  haveAcceptedMyOrders: 'haveAcceptedMyOrders',
  rating: 'rating',
  isVerified: 'isVerified',
}

export const getFiltersWithout = excludedFilter =>
  Object.values(FILTERS).filter(filter => filter !== excludedFilter)
