import * as hash from 'reduken/hash'
import { createSelector } from 'reselect'
import { DOMAIN, HASH_KEY_PHOTO_REFRESH_KEY } from './constants'
import { getScope } from '../auth'
import envConfig from '../../environments'

// Refresh key to force photo to always be up to date (via query params)
export const getPhotoRefreshKey = hash.getFromPath(
  DOMAIN,
  HASH_KEY_PHOTO_REFRESH_KEY,
)

export const getPhotoURL = createSelector(
  getScope,
  getPhotoRefreshKey,
  (scope, photoRefreshKey) =>
    `${envConfig.authBackendUrl}/session/${scope}/photo?${photoRefreshKey}`,
)
