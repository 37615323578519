import { all, put, takeLatest } from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import * as actionTypes from './action-types'
import { DOMAIN, HASH_KEY_REQUEST_STATUS, REQUEST_STATUS } from './constants'

function* markStartRequestSaga({ payload: { requestType } }) {
  yield put(
    hash.set(DOMAIN, `${HASH_KEY_REQUEST_STATUS}_${requestType}`, {
      status: REQUEST_STATUS.PENDING,
    }),
  )
}

function* requestSuccessSaga({ payload: { requestType } }) {
  yield put(
    hash.set(DOMAIN, `${HASH_KEY_REQUEST_STATUS}_${requestType}`, {
      status: REQUEST_STATUS.SUCCESS,
    }),
  )
}

function* requestErrorSaga({ payload: { requestType, error } }) {
  yield put(
    hash.set(DOMAIN, `${HASH_KEY_REQUEST_STATUS}_${requestType}`, {
      status: REQUEST_STATUS.WARNING,
      error,
    }),
  )
}

export default function*() {
  yield all([
    takeLatest(actionTypes.START, markStartRequestSaga),
    takeLatest(actionTypes.SUCCESS, requestSuccessSaga),
    takeLatest(actionTypes.ERROR, requestErrorSaga),
  ])
}
