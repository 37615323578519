import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DayPickerSingleDateController } from 'react-dates'
import * as R from 'ramda'
import cx from 'classnames'
import Button from '@redradix/components.button'
import '../../fixInitializeAirbnbComponents'
import Select from '../form-elements/Select'
import Input from './Input'
import { ReactComponent as Calendar } from './../../styles/icons/calendar.svg'
import { ReactComponent as Clock } from './../../styles/icons/clock.svg'
import ModalTitle from '../texts/ModalTitle'
import withTranslations from '../../hocs/withTranslations'
import { MediaQueryPalm } from '../shared/MediaQueries'
import { ReactComponent as Close } from '../../styles/icons/close.svg'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import {
  isNilOrEmpty,
  toMomentOnlyDate,
  renderDateTimeValue,
  renderDateValue,
} from '../../app/utils'
import { TIME_FORMAT_OPTIONS } from '../../config/formats'

const onlyTime = (timeOptions, moment) =>
  timeOptions
    ? timeOptions.find(
        x =>
          x.moment.get('hour') === moment.get('hour') &&
          x.moment.get('minute') === moment.get('minute'),
      ) || null
    : null

const Datepicker = ({
  datepickerLabel,
  datepickerSubtitle,
  errorText,
  modalTitle,
  placeholder,
  id,
  name,
  onChange,
  onBlur,
  value,
  isTouched,
  isDisabled,
  i18n,
  i18nDate,
  i18nTime,
  position,
  timeOptions,
  minDateTime,
  maxDateTime,
}) => {
  // Internal datepicker values (while changing but before saving)
  const [date, setDate] = useState(() =>
    !isNilOrEmpty(value) ? toMomentOnlyDate(value) : null,
  )

  // Handle date/time validity from min/max datetime
  const isDateOutsideRange = useCallback(
    date => {
      const dateWithoutTime = date.clone().startOf('day')

      return (
        (!R.isNil(minDateTime) &&
          dateWithoutTime.isBefore(minDateTime.clone().startOf('day'))) ||
        (!R.isNil(maxDateTime) &&
          dateWithoutTime.isAfter(maxDateTime.clone().startOf('day')))
      )
    },
    [minDateTime, maxDateTime],
  )

  const isTimeOutsideRange = useCallback(
    time => {
      if (!timeOptions) {
        return false
      }

      const { hours, minutes } = time

      if (R.isNil(date) && !(placeholder instanceof moment)) {
        return true
      }

      const dateWithTime = moment(date || placeholder)
        .clone()
        .set({ hour: hours, minute: minutes, second: 0, millisecond: 0 })

      return (
        (!R.isNil(minDateTime) && dateWithTime.isBefore(minDateTime)) ||
        (!R.isNil(maxDateTime) && dateWithTime.isAfter(maxDateTime))
      )
    },
    [date, placeholder, minDateTime, maxDateTime, timeOptions],
  )

  // Generate options for time select, localized to user's i18n
  const memoizedTimeOptions = useMemo(
    () =>
      timeOptions
        ? timeOptions.map(x => {
            const value = `${x.hours
              .toString()
              .padStart(2, '0')}:${x.minutes.toString().padStart(2, '0')}`

            const momentValue = moment(value, 'HH:mm')

            return {
              ...x,
              value,
              moment: momentValue,
              label: i18nTime(momentValue.toDate(), TIME_FORMAT_OPTIONS),
              isDisabled: isTimeOutsideRange(x),
            }
          })
        : undefined,
    [isTimeOutsideRange, timeOptions, i18nTime],
  )

  // Internal datepicker values (while changing but before saving)
  const [time, setTime] = useState(() =>
    !isNilOrEmpty(value) ? onlyTime(memoizedTimeOptions, value) : null,
  )
  const setInternalValues = useCallback(
    value => {
      if (value === null) {
        if (date !== null) {
          setDate(null)
        }

        if (time !== null) {
          setTime(null)
        }

        return
      }

      const newDate = toMomentOnlyDate(value)

      if (isNilOrEmpty(date) || !date.isSame(newDate)) {
        setDate(newDate)
      }

      const newTime = onlyTime(memoizedTimeOptions, value)

      if (isNilOrEmpty(time) || !R.equals(time, newTime)) {
        setTime(newTime)
      }
    },
    [date, time, memoizedTimeOptions, setDate, setTime],
  )

  // Update datepicker internal values if external value changes
  const lastValue = useRef(value)
  useEffect(() => {
    if (value === lastValue.current) {
      return
    }

    setInternalValues(value)

    lastValue.current = value
  }, [value, lastValue, setInternalValues])

  // Manage open/closed state
  const [isOpen, setIsOpen] = useState(false)
  const closeRef = useRef()

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true)
    }
  }

  const handleClose = () => {
    setIsOpen(false)

    // Simulate blur
    onBlur({ target: { name } })

    // Reset internal values to external value
    setInternalValues(value)
  }

  useOnClickOutside(isOpen ? closeRef : {}, handleClose)

  // Change handlers
  const onDateChange = date => {
    setDate(date)
  }

  const onTimeChange = time => {
    setTime(time)
  }

  // Save handler
  const onSubmit = () => {
    const actualDate = date || placeholder
    const actualTime = timeOptions
      ? !R.isNil(time)
        ? time.moment
        : placeholder
      : moment().set({ hour: 0, minute: 0 })

    const datetime = actualDate.clone().set({
      hour: actualTime.get('hour'),
      minute: actualTime.get('minute'),
      second: 0,
      millisecond: 0,
    })

    onChange({ target: { name, value: datetime } })

    handleClose()
  }

  // Presentational
  const renderedPlaceholder =
    placeholder instanceof moment
      ? renderDateTimeValue(i18nDate, i18nTime, placeholder)
      : placeholder

  const datepickerClassnames = cx('datepicker-box', {
    [`${position}-position`]: position,
  })

  return (
    <div
      className="datepicker-wrapper"
      onClick={!isDisabled ? handleOpen : undefined}
      ref={closeRef}
    >
      <label className="datepicker-label">{datepickerLabel}</label>
      {datepickerSubtitle && (
        <p className="datepicker-subtitle">{datepickerSubtitle}</p>
      )}
      <Input
        id={id}
        name={name}
        value={
          timeOptions
            ? renderDateTimeValue(i18nDate, i18nTime, value)
            : renderDateValue(i18nDate, value)
        }
        placeholder={renderedPlaceholder}
        appearance="box"
        behaviour="static"
        widthBehaviour="full"
        type="text"
        isTouched={isTouched}
        isDisabled={isDisabled}
        errorText={errorText}
        renderRightIcon={props => <Calendar {...props} />}
        readOnly
      />
      {isOpen && !isDisabled && (
        <div className={datepickerClassnames}>
          <MediaQueryPalm>
            <Close className="modal-close-button" onClick={handleClose} />
            <ModalTitle text={modalTitle} />
          </MediaQueryPalm>
          <DayPickerSingleDateController
            isOutsideRange={isDateOutsideRange}
            date={
              !R.isNil(date)
                ? date
                : placeholder instanceof moment
                ? toMomentOnlyDate(placeholder)
                : undefined
            }
            onDateChange={onDateChange}
            numberOfMonths={1}
            noBorder
            focused={isOpen}
          />
          <div className="datepicker-extra-block">
            {timeOptions ? (
              <div className="select-wrapper">
                <Select
                  placeholder={i18n('datepicker:select-time')}
                  options={memoizedTimeOptions}
                  value={
                    !R.isNil(time)
                      ? time
                      : placeholder instanceof moment
                      ? onlyTime(memoizedTimeOptions, placeholder)
                      : undefined
                  }
                  onChange={onTimeChange}
                />
                <Clock className="select-icon" />
              </div>
            ) : null}
            <Button
              type="button"
              hierarchy="primary"
              onClick={onSubmit}
              isDisabled={
                !date || (timeOptions && !time)
                  ? !(placeholder instanceof moment)
                  : isDateOutsideRange(date) || isTimeOutsideRange(time)
              }
            >
              {i18n('action:save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

Datepicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  datepickerLabel: PropTypes.string.isRequired,
  datepickerSubtitle: PropTypes.string,
  errorText: PropTypes.object,
  modalTitle: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.instanceOf(moment),
  isTouched: PropTypes.bool,
  isDisabled: PropTypes.bool,
  position: PropTypes.oneOf(['', 'top', 'bottom']),
  timeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      hours: PropTypes.number.isRequired,
      minutes: PropTypes.number.isRequired,
    }),
  ),
  minDateTime: PropTypes.any,
  maxDateTime: PropTypes.any,
}

export default withTranslations(Datepicker)
