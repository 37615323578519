import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { isNilOrEmpty } from '../../app/utils'
import OrdersClosedTable from '../orders/OrdersClosedTable'
import Card from './Card'
import EmptyResult from '../search/EmptyResult'

const OrdersClosedCard = ({ orders, sortValue, onSortChange }) => {
  return (
    <Card isHighlighted isContained>
      <div className="orders-closed-card">
        {isNilOrEmpty(orders) ? (
          <EmptyResult />
        ) : (
          <OrdersClosedTable
            orders={orders}
            sortValue={sortValue}
            onSortChange={onSortChange}
          />
        )}
      </div>
    </Card>
  )
}

OrdersClosedCard.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.closedOrderRow),
  sortValue: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
}

export default OrdersClosedCard
