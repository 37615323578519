import { compose, withPropsOnChange } from 'recompose'
import * as Yup from 'yup'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import {
  BRIEFING_UPLOAD_CONDITIONS,
  BRIEFING_UPLOAD_CONDITIONS_NORMAL,
  briefingRequestType,
  BRIEFINGS_MAX_FILE_SIZE,
  MAX_SAMPLE_MESSAGE_CHARACTERS_FACEBOOK,
} from '../../services/checkout/business'
import { i18nValidation, validateMaxFileSize } from '../common/validation'
import BriefingFacebookForm from '../../ui/checkout/BriefingFacebookForm'
import { trimUnnecessarySpaces, prepareMultiValueInputToSave } from '../utils'
import withConditionOptions from './withConditionOptions'
import withTranslations from '../../hocs/withTranslations'
import withPreviewModal from './withPreviewModal'
import { SERVICE_TYPES } from '../../services/orders/business'
import { connect } from 'react-redux'
import { submitFacebookBriefing } from '../../services/checkout'
import { withCart } from '../../services/checkout/hocs'
import withDownloadBriefingFileLink from './withDownloadBriefingFileLink'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withValuesCache from './withValuesCache'

const validationSchema = Yup.object().shape({
  description: Yup.string().required(i18nValidation.required),
  hashtags: Yup.string(),
  userMentions: Yup.string(),
  url: Yup.string().url(i18nValidation.invalidURL),
  words: Yup.string(),
  conditionId: Yup.string()
    .oneOf(BRIEFING_UPLOAD_CONDITIONS_NORMAL, i18nValidation.required)
    .required(i18nValidation.required),
  sampleMessage: Yup.string().max(
    MAX_SAMPLE_MESSAGE_CHARACTERS_FACEBOOK,
    i18nValidation.maxLength,
  ),
  file: Yup.mixed()
    .nullable()
    .when('conditionId', {
      is: BRIEFING_UPLOAD_CONDITIONS.BRAND,
      then: Yup.mixed()
        .nullable()
        .required(i18nValidation.required),
    })
    .test(validateMaxFileSize(BRIEFINGS_MAX_FILE_SIZE)),
  isAdvertising: Yup.boolean().required(i18nValidation.required),
})

const enhance = compose(
  withTranslations,
  withCart,

  withPropsOnChange(['i18n'], ({ i18n }) => ({
    title: i18n('service-type:facebook-post'),
  })),

  connect(
    null,
    { submitBriefing: submitFacebookBriefing },
  ),
  withDownloadBriefingFileLink(SERVICE_TYPES.FACEBOOK),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ cart: { briefings }, cachedValues }) => {
      const actualData = briefings[SERVICE_TYPES.FACEBOOK]
      const actualHashtags =
        actualData && actualData.hashtags
          ? actualData.hashtags
              .filter(Boolean)
              .map(v => '#' + v)
              .join(' ')
          : ''
      const actualMentions =
        actualData && actualData.userMentions
          ? actualData.userMentions
              .filter(Boolean)
              .map(v => '@' + v)
              .join(' ')
          : ''
      const actualWords =
        actualData && actualData.words
          ? actualData.words.filter(Boolean).join(', ')
          : ''
      const actualFile =
        actualData && Array.isArray(actualData.files)
          ? actualData.files[0]
          : null

      return {
        description:
          actualData && actualData.description ? actualData.description : '',
        hashtags: actualHashtags,
        userMentions: actualMentions,
        url: actualData && actualData.url ? actualData.url : '',
        words: actualWords,
        conditionId:
          actualData && actualData.conditionId
            ? actualData.conditionId
            : BRIEFING_UPLOAD_CONDITIONS.NONE,
        sampleMessage:
          actualData && actualData.sampleMessage
            ? actualData.sampleMessage
            : '',
        file: actualFile,
        isAdvertising:
          actualData && actualData.isAdvertising
            ? actualData.isAdvertising
            : false,
        ...cachedValues,
      }
    },

    validationSchema,

    fileFields: ['file'],
    selectFields: ['conditionId'],

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSend = {
        ...values,
        hashtags: prepareMultiValueInputToSave(values.hashtags, '#'),
        userMentions: prepareMultiValueInputToSave(values.userMentions, '@'),
        words: trimUnnecessarySpaces(values.words),
      }

      props.submitBriefing(valuesToSend)
    },
  }),
  withValuesCache(SERVICE_TYPES.FACEBOOK),

  withIsRequestPending(briefingRequestType(SERVICE_TYPES.FACEBOOK)),
  withIsRequestSucceeded(briefingRequestType(SERVICE_TYPES.FACEBOOK)),
  withRequestError(briefingRequestType(SERVICE_TYPES.FACEBOOK)),
  withDeleteRequestOnUnmount(briefingRequestType(SERVICE_TYPES.FACEBOOK)),
  withFormErrors([
    'description',
    'hashtags',
    'userMentions',
    'url',
    'words',
    'conditionId',
    'sampleMessage',
    'file',
    'isAdvertising',
  ]),

  withConditionOptions(BRIEFING_UPLOAD_CONDITIONS_NORMAL),

  withPreviewModal,
)

export default enhance(BriefingFacebookForm)
