export const SERVICE = 'campaigns'

export const DOMAIN = 'campaigns'

export const HASH_KEY_CAMPAIGNS = 'campaigns'

export const HASH_KEY_LAST_CREATED_CAMPAIGN_ID = 'lastCreatedCampaignId'

export const HASH_KEY_CAMPAIGNS_SUMMARY = 'campaignsSummary'

export const ENTITY_KEY_CAMPAIGN_SUMMARY = 'campaignSummary'

export const ENTITY_KEY_CAMPAIGN_RESOURCE_TYPE_STATS =
  'campaignResourceTypeStats'

export const ENTITY_KEY_CAMPAIGN_ORDERS_BY_TYPE = 'campaignOrdersByType'

export const ENTITY_KEY_CAMPAIGN_ORDERS_STATS = 'campaignOrderStats'

export const ENTITY_KEY_CAMPAIGN_SEO_URL_STATS = 'campaignSEOURLStats'

export const ENTITY_KEY_PUBLICATION_PREVIEWS = 'publicationPreviews'

export const STATISTICS_COLORS = [
  '#CF2BB7',
  '#FFD900',
  '#080F9C',
  '#06C991',
  '#C5B9E8',
  '#FFB242',
  '#0000FF',
  '#9D02D7',
  '#FC8772',
  '#EC5C94',
]

export const DEFAULT_MIN_LEGEND_ITEMS = 3

export const MAX_LEGEND_ITEMS = 6
