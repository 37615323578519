import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

// This renders a modal into the div#modal-root (see index.html)
const ModalPortal = ({ modalRootId = 'modal-root', children }) => {
  const target = document.getElementById(modalRootId)
  return createPortal(children, target)
}

ModalPortal.propTypes = {
  modalRootId: PropTypes.string,
  children: PropTypes.node,
}

export default ModalPortal
