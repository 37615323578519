import * as actionTypes from './action-types'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import {
  REFRESH_NOTIFICATION_TIME,
  DOMAIN,
  HASH_KEY_UNREAD_NOTIFICATIONS,
  HASH_KEY_UNREAD_NOTIFICATIONS_TOTAL,
} from './constants'
import {
  startRequest,
  endRequestError,
  endRequestSuccess,
  startRequestWithId,
  endRequestWithIdError,
  endRequestWithIdSuccess,
} from '../communication/actions'
import * as api from './api'
import repeatingFetchEffect from '../repeating-fetch-effect'
import { periodicFetchUnreadNotifications } from './actions'

const isNotificationNotFoundException = ({ errorData }) =>
  errorData &&
  errorData.detail &&
  errorData.detail === 'NotificationsNotFoundException'

function* fetchUnreadNotifications() {
  yield put(startRequest(actionTypes.FETCH_UNREAD_NOTIFICATIONS))

  const response = yield call(api.fetchUnreadNotifications)

  if (response.error) {
    // Ignore no notifications exception
    if (isNotificationNotFoundException(response)) {
      yield put(hash.set(DOMAIN, HASH_KEY_UNREAD_NOTIFICATIONS, []))
      yield put(endRequestSuccess(actionTypes.FETCH_UNREAD_NOTIFICATIONS))
      return
    }

    yield put(endRequestError(actionTypes.FETCH_UNREAD_NOTIFICATIONS, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_UNREAD_NOTIFICATIONS, response.data.data))
  yield put(
    hash.set(
      DOMAIN,
      HASH_KEY_UNREAD_NOTIFICATIONS_TOTAL,
      response.data.meta.total,
    ),
  )
  yield put(endRequestSuccess(actionTypes.FETCH_UNREAD_NOTIFICATIONS))
}

function* readNotificationSaga({ payload: { notification } }) {
  yield put(startRequestWithId(actionTypes.READ_NOTIFICATION, notification.id))

  // We have to read all the notifications associated with the same order
  // as the specific notification that the user is reading.
  const response = yield call(
    api.readAllOrderNotifications,
    notification.orderId,
  )

  if (response.error) {
    // Ignore no notifications exception
    if (isNotificationNotFoundException(response)) {
      yield put(hash.set(DOMAIN, HASH_KEY_UNREAD_NOTIFICATIONS, []))
      yield put(
        endRequestWithIdSuccess(actionTypes.READ_NOTIFICATION, notification.id),
      )
      return
    }

    yield put(
      endRequestWithIdError(
        actionTypes.READ_NOTIFICATION,
        notification.id,
        response,
      ),
    )
    return
  }

  // Refresh unread notifications
  yield call(fetchUnreadNotifications)
  yield put(
    endRequestWithIdSuccess(actionTypes.READ_NOTIFICATION, notification.id),
  )
}

function* readAllNotificationsSaga() {
  yield put(startRequest(actionTypes.READ_ALL_NOTIFICATIONS))

  const response = yield call(api.readAllNotifications)

  if (response.error) {
    // Ignore no notifications exception
    if (isNotificationNotFoundException(response)) {
      yield put(hash.set(DOMAIN, HASH_KEY_UNREAD_NOTIFICATIONS, []))
      yield put(endRequestSuccess(actionTypes.READ_ALL_NOTIFICATIONS))
      return
    }

    yield put(endRequestError(actionTypes.READ_ALL_NOTIFICATIONS, response))
    return
  }

  // Refresh unread notifications
  yield call(fetchUnreadNotifications)
  yield put(endRequestSuccess(actionTypes.READ_ALL_NOTIFICATIONS))
}

export default function*() {
  yield all([
    repeatingFetchEffect({
      fetchSaga: fetchUnreadNotifications,
      intervalMillisecs: REFRESH_NOTIFICATION_TIME,
      startAction: periodicFetchUnreadNotifications(),
      requestActionType: actionTypes.FETCH_UNREAD_NOTIFICATIONS,
    }),
    takeLatest(actionTypes.READ_NOTIFICATION, readNotificationSaga),
    takeLatest(actionTypes.READ_ALL_NOTIFICATIONS, readAllNotificationsSaga),
  ])
}
