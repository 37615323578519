import { compose, withPropsOnChange } from 'recompose'
import * as Yup from 'yup'
import * as R from 'ramda'
import { connect } from 'react-redux'
import OrderModalCancel from '../../ui/order-modals/OrderModalCancel'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withFormErrors from '../../hocs/withFormErrors'
import { CANCEL_ORDER } from '../../services/orders/action-types'
import { cancelOrder } from '../../services/orders'
import { isNilOrEmpty } from '../utils'
import withFetchOrderIfNeeded from '../common/withFetchOrderIfNeeded'
import { i18nValidation } from '../common/validation'

export const validationSchema = () =>
  Yup.object().shape({
    comments: Yup.string().required(i18nValidation.required),
  })

const enhance = compose(
  withFetchOrderIfNeeded({ modalTitleI18nKey: 'action:cancel-order' }),

  withPropsOnChange(['orderData'], ({ orderData }) => ({
    sourceName: orderData.product.name,
  })),

  connect(
    null,
    { cancelOrder },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      comments: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSubmit = R.filter(R.complement(isNilOrEmpty), values)
      props.cancelOrder(props.orderId, valuesToSubmit.comments)
    },
  }),

  withIsRequestPending(CANCEL_ORDER),
  withIsRequestSucceeded(CANCEL_ORDER),
  withRequestError(CANCEL_ORDER),
  withDeleteRequestOnUnmount(CANCEL_ORDER),
  withFormErrors(['comments']),
)

export default enhance(OrderModalCancel)
