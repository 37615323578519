import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'

const GlobalMessage = ({
  message,
  imageBig,
  imageSmall,
  onReport,
  children,
  i18n,
}) => {
  return (
    <div className="global-message">
      <div className="global-message-image">
        <picture>
          <source media="(max-width: 799px)" srcSet={imageSmall} />
          <source media="(min-width: 800px)" srcSet={imageBig} />
          <img src={imageBig} alt="Decoration" />
        </picture>
      </div>
      <div className="global-message-content">
        <div className="global-message-text">{message}</div>
        {onReport ? (
          <button className="global-message-report" onClick={onReport}>
            {i18n('message:help-us')}
          </button>
        ) : null}
        {children}
      </div>
    </div>
  )
}

GlobalMessage.propTypes = {
  message: PropTypes.string.isRequired,
  imageBig: PropTypes.string,
  imageSmall: PropTypes.string,
  onReport: PropTypes.func,
  children: PropTypes.node,
}

export default withTranslations(GlobalMessage)
