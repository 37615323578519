import { SERVICE } from './constants'

export const FETCH_CAMPAIGNS = `${SERVICE}/FETCH_CAMPAIGNS`

export const CREATE_CAMPAIGN = `${SERVICE}/CREATE_CAMPAIGN`

export const DELETE_CAMPAIGN = `${SERVICE}/DELETE_CAMPAIGN`

export const RENAME_CAMPAIGN = `${SERVICE}/RENAME_CAMPAIGN`

export const FETCH_CAMPAIGNS_SUMMARY = `${SERVICE}/FETCH_CAMPAIGNS_SUMMARY`

export const FETCH_CAMPAIGN_SUMMARY_BY_ID = `${SERVICE}/FETCH_CAMPAIGN_SUMMARY_BY_ID`

export const PERIODIC_FETCH_CAMPAIGNS_SUMMARY = `${SERVICE}/PERIODIC_FETCH_CAMPAIGNS_SUMMARY`

export const CANCEL_PERIODIC_FETCH_CAMPAIGNS_SUMMARY = `${SERVICE}/CANCEL_PERIODIC_FETCH_CAMPAIGNS_SUMMARY`

export const FETCH_PUBLICATION_PREVIEWS = `${SERVICE}/FETCH_PUBLICATION_PREVIEWS`

// Statistics

export const FETCH_CAMPAIGN_RESOURCE_TYPE_STATS = `${SERVICE}/FETCH_CAMPAIGN_RESOURCE_TYPE_STATS`

export const FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE = `${SERVICE}/FETCH_CAMPAIGN_ORDER_LIST_BY_TYPE`

export const FETCH_CAMPAIGN_ORDER_STATS = `${SERVICE}/FETCH_CAMPAIGN_ORDER_STATS`

export const FETCH_CAMPAIGN_SEO_URL_STATS = `${SERVICE}/FETCH_CAMPAIGN_SEO_URL_STATS`
