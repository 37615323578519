import { compose, withProps, withState } from 'recompose'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import * as R from 'ramda'
import withFormErrors from '../../hocs/withFormErrors'
import BrandBillingForm from '../../ui/profile/BrandBillingForm'
import { connect } from 'react-redux'
import { editBrandBillingData } from '../../services/brand'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withRequestError from '../../hocs/withRequestError'
import { EDIT_BRAND_BILLING_DATA } from '../../services/brand/action-types'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withCleanFormSuccess from '../../hocs/withCleanFormSuccess'
import withCountryStateSelectFields from '../../hocs/withCountryStateSelectFields'
import {
  withTranslatedCountries,
  withCountries,
} from '../../services/config/hocs'
import { billingDataSchema } from '../../services/brand/business'
import withCurrencySelectField from '../../hocs/withCurrencySelectField'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { getBrandStatus } from '../../services/brand'

const enhance = compose(
  withProps(({ brandInfo: { billing } }) => ({
    isIdentityDocumentDisabled:
      billing.identityDocument !== '' && !R.isNil(billing.identityDocument),
  })),

  connect(
    null,
    { editBrandBillingData },
  ),

  withState('isConfirmModalOpen', 'setConfirmModalOpen', false),

  withTranslatedCountries(),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ brandInfo: { billing } }) => ({
      invoiceEmail: billing.invoiceEmail || '',
      businessName: billing.businessName || '',
      identityDocument: billing.identityDocument || '',
      country: billing.country,
      state: billing.state,
      city: billing.city || '',
      address: billing.address || '',
      postalCode: billing.postalCode || '',
      currency: R.pathOr(null, ['currency', 'isoCode'], billing),
    }),

    selectFields: ['country', 'state', 'currency'],

    validationSchema: billingDataSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const sendingValues = { ...values }

      // Since we cannot modify an already set identity document,  delete it from
      // the data we're sending
      if (props.isIdentityDocumentDisabled) {
        delete sendingValues.identityDocument
      }

      // Show confirmation panel when saving identity document for the first time
      else {
        if (!props.isConfirmModalOpen) {
          props.setConfirmModalOpen(true)
          return
        }
      }

      // Send values
      props.setConfirmModalOpen(false)
      props.editBrandBillingData(sendingValues)
    },
  }),

  withCountries,

  withIsRequestPending(EDIT_BRAND_BILLING_DATA),
  withIsRequestSucceeded(EDIT_BRAND_BILLING_DATA),
  withRequestError(EDIT_BRAND_BILLING_DATA),
  withDeleteRequestOnUnmount(EDIT_BRAND_BILLING_DATA),
  withFormErrors([
    'invoiceEmail',
    'businessName',
    'identityDocument',
    'country',
    'state',
    'city',
    'address',
    'postalCode',
    'currency',
  ]),

  withCountryStateSelectFields(),
  withCleanFormSuccess(),
  withCurrencySelectField,
  connect(state => ({
    hasWorkInProgressOrders: getBrandStatus(state).ordersInProgress > 0,
  })),
)

export default enhance(BrandBillingForm)
