import OrderModalSendDeliveryInstagramStory from '../../ui/order-modals/OrderModalSendDeliveryInstagramStory'
import { compose } from 'recompose'
import * as Yup from 'yup'
import * as R from 'ramda'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import { validateMaxFileSize, i18nValidation } from '../common/validation'
import { BRIEFINGS_MAX_FILE_SIZE } from '../../services/checkout/business'
import { isNilOrEmpty } from '../utils'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { SEND_ORDER_DELIVERY_STORY } from '../../services/orders/action-types'
import { connect } from 'react-redux'
import { sendOrderDeliveryStory } from '../../services/orders'

const validationSchema = Yup.object().shape({
  storyFile: Yup.mixed()
    .nullable()
    .test(validateMaxFileSize(BRIEFINGS_MAX_FILE_SIZE))
    .required(i18nValidation.required),
  comments: Yup.string(),
})

const enhance = compose(
  connect(
    null,
    { sendOrderDeliveryStory },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      storyFile: null,
      comments: '',
    }),

    fileFields: ['storyFile'],

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSubmit = R.filter(R.complement(isNilOrEmpty), values)
      props.sendOrderDeliveryStory(props.orderId, props.isFix, valuesToSubmit)
    },
  }),

  withIsRequestPending(SEND_ORDER_DELIVERY_STORY),
  withIsRequestSucceeded(SEND_ORDER_DELIVERY_STORY),
  withRequestError(SEND_ORDER_DELIVERY_STORY),
  withDeleteRequestOnUnmount(SEND_ORDER_DELIVERY_STORY),

  withFormErrors(['storyFile', 'comments']),
)

export default enhance(OrderModalSendDeliveryInstagramStory)
