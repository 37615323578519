import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import CoobisLogo from './coobis-logo.png'
import CoobisLogoWhite from './coobis-logo-white.png'
import MainNavigation from '../navigation/MainNavigation'
//import AlertsIndicator from '../alerts/AlertsIndicator'
import AddFunds from '../add-funds/AddFunds'
import UserDropdown from '../user-dropdown/UserDropdown'
import NotificationsDropdowm from '../dropdown/NotificationsDropdown'
import CartIndicator from '../cart/CartIndicator'
import { ROUTE_ROOT } from '../../app/common/routes'
import { AuthScope } from '../../services/auth/business'

const Header = ({
  navItems,
  current,
  notifications,
  numberAlerts,
  balance,
  onBalanceAction,
  userName,
  userAvatar,
  userProfile,
  onLogout,
  cartItems,
  onCartClick,
  isMedia,
  scope,
  location,
  isMarkAllLoading,
  onMarkAllClick,
  onNotificationClick,
}) => {
  const classes = cx('global-header', { 'is-media': isMedia })
  return (
    <header className={classes}>
      <div className="wrapper">
        <div className="global-header-content">
          <div className="global-header-content-left">
            <Link className="global-header-logo" to={ROUTE_ROOT.linkTo()}>
              <img
                src={isMedia ? CoobisLogoWhite : CoobisLogo}
                alt="Coobis logo"
              />
            </Link>
            <MainNavigation
              navItems={navItems}
              current={current}
              balance={balance}
              userName={userName}
              userAvatar={userAvatar}
              userProfile={userProfile}
              onLogout={onLogout}
              isPublic={false}
              scope={scope}
              isMedia={isMedia}
              onBalanceAction={onBalanceAction}
            />
          </div>
          <div className="global-header-content-right">
            <div className="global-header-funds">
              <AddFunds
                balance={balance}
                isMedia={isMedia}
                onClick={onBalanceAction}
              />
            </div>
            <div className="global-header-user">
              <UserDropdown
                userName={userName}
                userAvatar={userAvatar}
                userProfile={userProfile}
                scope={scope}
                onLogout={onLogout}
                location={location}
              />
            </div>
            <div className="global-header-alerts">
              <NotificationsDropdowm
                notifications={notifications}
                numberAlerts={numberAlerts}
                isMarkAllLoading={isMarkAllLoading}
                onMarkAllClick={onMarkAllClick}
                onNotificationClick={onNotificationClick}
              />
            </div>
            {cartItems ? (
              <div className="global-header-cart">
                <CartIndicator cartItems={cartItems} onClick={onCartClick} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  navItems: PropTypes.arrayOf(CoobisPropTypes.navItem),
  current: PropTypes.string,
  notifications: PropTypes.array,
  numberAlerts: PropTypes.number,
  balance: CoobisPropTypes.money,
  onBalanceAction: PropTypes.func,
  isMedia: PropTypes.bool,
  userName: PropTypes.string,
  userAvatar: PropTypes.string,
  userProfile: PropTypes.string,
  onLogout: PropTypes.func,
  cartItems: PropTypes.number,
  onCartClick: PropTypes.func,
  scope: PropTypes.oneOf([AuthScope.BRAND, AuthScope.MEDIA]),
}

export default Header
