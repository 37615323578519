import { compose, withPropsOnChange } from 'recompose'
import * as Yup from 'yup'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation, validateMaxFileSize } from '../common/validation'
import { prepareMultiValueInputToSave } from '../utils'
import BriefingTwitchForm from '../../ui/checkout/BriefingTwitchForm'
import withTranslations from '../../hocs/withTranslations'
import { SERVICE_TYPES } from '../../services/orders/business'
import { submitTwitchBriefing } from '../../services/checkout'
import { connect } from 'react-redux'
import {
  briefingRequestType,
  BRIEFINGS_MAX_FILE_SIZE,
  MAX_SAMPLE_MESSAGE_CHARACTERS_TWITCH,
} from '../../services/checkout/business'
import { withCart } from '../../services/checkout/hocs'
import withDownloadBriefingFileLink from './withDownloadBriefingFileLink'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withValuesCache from './withValuesCache'

const validationSchema = Yup.object().shape({
  description: Yup.string().required(i18nValidation.required),
  hashtags: Yup.string(),
  url: Yup.string().url(i18nValidation.invalidURL),
  file: Yup.mixed()
    .nullable()
    .test(validateMaxFileSize(BRIEFINGS_MAX_FILE_SIZE)),
  sampleMessage: Yup.string().max(
    MAX_SAMPLE_MESSAGE_CHARACTERS_TWITCH,
    i18nValidation.maxLength,
  ),
})

const enhance = compose(
  withTranslations,
  withCart,

  withPropsOnChange(['i18n'], ({ i18n }) => ({
    title: i18n('service-type:twitch'),
  })),

  connect(
    null,
    { submitBriefing: submitTwitchBriefing },
  ),
  withDownloadBriefingFileLink(SERVICE_TYPES.TWITCH),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ cart: { briefings }, cachedValues }) => {
      const actualData = briefings[SERVICE_TYPES.TWITCH]
      const actualHashtags =
        actualData && actualData.hashtags
          ? actualData.hashtags
              .filter(Boolean)
              .map(v => '#' + v)
              .join(' ')
          : ''
      const actualFile =
        actualData && Array.isArray(actualData.files)
          ? actualData.files[0]
          : null

      return {
        description:
          actualData && actualData.description ? actualData.description : '',
        hashtags: actualHashtags,
        url: actualData && actualData.url ? actualData.url : '',
        file: actualFile,
        sampleMessage:
          actualData && actualData.sampleMessage
            ? actualData.sampleMessage
            : '',
        ...cachedValues,
      }
    },

    validationSchema,

    fileFields: ['file'],

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSend = {
        ...values,
        hashtags: prepareMultiValueInputToSave(values.hashtags, '#'),
      }

      props.submitBriefing(valuesToSend)
    },
  }),
  withValuesCache(SERVICE_TYPES.TWITCH),

  withIsRequestPending(briefingRequestType(SERVICE_TYPES.TWITCH)),
  withIsRequestSucceeded(briefingRequestType(SERVICE_TYPES.TWITCH)),
  withRequestError(briefingRequestType(SERVICE_TYPES.TWITCH)),
  withDeleteRequestOnUnmount(briefingRequestType(SERVICE_TYPES.TWITCH)),
  withFormErrors(['description', 'hashtags', 'url', 'file', 'sampleMessage']),
)

export default enhance(BriefingTwitchForm)
