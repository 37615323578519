import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import InputWithSubtitle from './../form-elements/InputWithSubtitle'
import InputFileWithSubtitle from '../form-elements/InputFileWithSubtitle'
import Textarea from './../form-elements/Textarea'
import SocialHeading from '../../ui/texts/SocialHeading'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import { getSocialPostUrlPlaceholder } from '../../app/resources/util'

import * as R from 'ramda'
const ALLOWED_IMAGE_EXTENSIONS =
  '.png,.jpg,.jpeg,.img,.gif,.pdf,.doc,.docx,.txt,.mpeg,.avi,.mp4,.flv,.mov'

const OrderModalSendDeliveryWeb = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,

  hasFacebookBroadcast,
  hasTwitterBroadcast,
  hasInstagramBroadcast,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal order-modal-compact-vertical">
        <ModalSimpleContent
          actionText={i18n('action:deliver')}
          isButtonLoading={isRequestPending}
        >
          <ModalHeader title={i18n('action:deliver-url')} />

          {hasFacebookBroadcast ||
          hasTwitterBroadcast ||
          hasInstagramBroadcast ? (
            <SocialHeading
              type={RESOURCE_TYPES.WEB}
              text={i18n('social-resource:post-url')}
            />
          ) : null}
          <InputWithSubtitle
            type="url"
            name="url"
            widthBehaviour="full"
            inputLabel={i18n('order-modal:order-deliver-add-url')}
            placeholder={i18n('example:web-sponsorship')}
            value={values.url}
            onBlur={handleBlur}
            onChange={handleChange}
            isTouched={touched.url}
            isDisabled={isRequestPending}
            errorText={
              validationMessages.url && (
                <FormattedFormErrors errors={validationMessages.url} />
              )
            }
            isRequired
          />

          {hasFacebookBroadcast ? (
            <>
              <SocialHeading
                type={RESOURCE_TYPES.FACEBOOK}
                text={i18n('social-resource:facebook-url')}
              />
              <InputWithSubtitle
                type="url"
                name="facebook"
                widthBehaviour="full"
                inputLabel={i18n('order-modal:order-deliver-add-url')}
                placeholder={getSocialPostUrlPlaceholder(
                  RESOURCE_TYPES.FACEBOOK,
                  i18n,
                )}
                value={values.facebook}
                onBlur={handleBlur}
                onChange={handleChange}
                isTouched={touched.facebook}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.facebook && (
                    <FormattedFormErrors errors={validationMessages.facebook} />
                  )
                }
                isRequired
              />
            </>
          ) : null}

          {hasTwitterBroadcast ? (
            <>
              <SocialHeading
                type={RESOURCE_TYPES.TWITTER}
                text={i18n('social-resource:twitter-url')}
              />
              <InputWithSubtitle
                type="url"
                name="twitter"
                widthBehaviour="full"
                inputLabel={i18n('order-modal:order-deliver-add-url')}
                placeholder={getSocialPostUrlPlaceholder(
                  RESOURCE_TYPES.TWITTER,
                  i18n,
                )}
                value={values.twitter}
                onBlur={handleBlur}
                onChange={handleChange}
                isTouched={touched.twitter}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.twitter && (
                    <FormattedFormErrors errors={validationMessages.twitter} />
                  )
                }
                isRequired
              />
            </>
          ) : null}

          {hasInstagramBroadcast ? (
            <>
              <SocialHeading
                type={RESOURCE_TYPES.INSTAGRAM}
                text={i18n('service-type:instagram-story')}
              />
              <InputFileWithSubtitle
                id="instagram"
                inputSubtitle={i18n('order-modal:publish-instagram-story')}
                inputLabel={i18n('order-modal:order-deliver-add-story-image')}
                name="instagram"
                accept={ALLOWED_IMAGE_EXTENSIONS}
                widthBehaviour="full"
                linkText={i18n('action:download-file')}
                linkUrl=""
                onBlur={handleBlur}
                onChange={handleChange}
                fileName={R.path(['instagram', 'name'], values)}
                isUploaded={R.path(['instagram', 'isUploaded'], values)}
                isTouched={touched.instagram}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.instagram && (
                    <FormattedFormErrors
                      errors={validationMessages.instagram}
                    />
                  )
                }
                isRequired
              />
            </>
          ) : null}

          <Textarea
            name="comments"
            label={i18n('action:add-comment-for-brand')}
            placeholder={i18n('action:write-your-comment-here')}
            onBlur={handleBlur}
            onChange={handleChange}
            errorText={
              validationMessages.comments && (
                <FormattedFormErrors errors={validationMessages.comments} />
              )
            }
            isTouched={touched.comments}
            isDisabled={isRequestPending}
            value={values.comments}
          />
          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  url: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.any,
  comments: PropTypes.string,
}

OrderModalSendDeliveryWeb.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,

  hasFacebookBroadcast: PropTypes.bool,
  hasTwitterBroadcast: PropTypes.bool,
  hasInstagramBroadcast: PropTypes.bool,

  isRequestPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(OrderModalSendDeliveryWeb)
