import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import { MediaQueryLap } from '../shared/MediaQueries'
import ProfileMovementsHeadRow from './ProfileMovementsHeadRow'
import ProfileMovementsRow from './ProfileMovementsRow'
import Spinner from '../loaders/Spinner'
import { isNilOrEmpty } from '../../app/utils'

const ProfileMovementsList = ({ movements, isLoading, i18n }) => {
  return (
    <section className="profile-movements-list">
      <MediaQueryLap>
        <ProfileMovementsHeadRow />
      </MediaQueryLap>
      {isLoading && isNilOrEmpty(movements) ? (
        <Spinner size={60} text={i18n('status:loading-movements')} />
      ) : (
        <>
          {movements.map(movement => (
            <ProfileMovementsRow key={movement.id} {...movement} />
          ))}
        </>
      )}
    </section>
  )
}

ProfileMovementsList.propTypes = {
  movements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      date: PropTypes.instanceOf(Date).isRequired,
      concept: PropTypes.string,
      resource: PropTypes.string,
      amount: CoobisPropTypes.money.isRequired,
      balance: CoobisPropTypes.money,
      downloadUrl: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  i18n: PropTypes.func,
}

export default withTranslations(ProfileMovementsList)
