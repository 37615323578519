import { compose, withStateHandlers, withPropsOnChange } from 'recompose'
import * as R from 'ramda'

const withPreviewModal = compose(
  withStateHandlers(
    {
      isPreviewModalOpen: false,
    },
    {
      onClickPreview: _ => _ => ({
        isPreviewModalOpen: true,
      }),
      onClickClosePreview: _ => _ => ({
        isPreviewModalOpen: false,
      }),
    },
  ),

  withPropsOnChange(['values'], ({ values }) => ({
    isPreviewModalAvailable:
      !R.isNil(values.sampleMessage) && values.sampleMessage.length > 0,
  })),
)

export default withPreviewModal
