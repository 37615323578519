import { compose, withProps, lifecycle } from 'recompose'
import { withCountries, withCategories } from '../config/hocs'
import { getReachedWebNavItem, getReachedSocialNavItem } from './business'
import {
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID,
  ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES,
  ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA,
  prettifyConstant,
} from '../../app/common/routes'
import { WEB_RESOURCE_SECTIONS, SOCIAL_RESOURCE_SECTIONS } from './constants'
import { getLastTemporaryImage } from './selectors'
import { connect } from 'react-redux'

const WEB_NAV_ITEM_ROUTES = id => ({
  [WEB_RESOURCE_SECTIONS.GENERAL_DATA]: ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID.linkTo(
    { id },
  ),
  [WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS]: ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID.linkTo(
    { id },
  ),
  [WEB_RESOURCE_SECTIONS.ANALYTICS]: ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID.linkTo(
    { id },
  ),
  [WEB_RESOURCE_SECTIONS.SERVICES_PRICES]: ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID.linkTo(
    { id },
  ),
})

export const withReachedWebResourceStep = currentNavItem =>
  compose(
    withCountries,
    withCategories,
    withProps(props => ({
      reachedNavItem: getReachedWebNavItem(props),
    })),
    lifecycle({
      componentDidMount() {
        const { reachedNavItem, history, resourceId } = this.props

        if (reachedNavItem < currentNavItem) {
          history.replace(WEB_NAV_ITEM_ROUTES(resourceId)[reachedNavItem])
        }
      },
    }),
  )

const SOCIAL_NAV_ITEM_ROUTES = (id, socialNetwork) => ({
  [SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA]: ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA.linkTo(
    { id, socialNetwork },
  ),
  [SOCIAL_RESOURCE_SECTIONS.SERVICES_PRICES]: ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES.linkTo(
    { id, socialNetwork },
  ),
})

export const withReachedSocialResourceStep = currentNavItem =>
  compose(
    withCountries,
    withCategories,
    withProps(props => ({
      reachedNavItem: getReachedSocialNavItem(props),
    })),
    lifecycle({
      componentDidMount() {
        const { reachedNavItem, history, resourceId, channel } = this.props

        if (reachedNavItem < currentNavItem) {
          history.replace(
            SOCIAL_NAV_ITEM_ROUTES(resourceId, prettifyConstant(channel))[
              reachedNavItem
            ],
          )
        }
      },
    }),
  )

export const withUploadedImage = connect(state => ({
  lastTemporaryImage: getLastTemporaryImage(state),
}))
