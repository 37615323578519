import { compose, withProps } from 'recompose'
import * as R from 'ramda'
import withIsRequestWithIdSucceeded from './withIsRequestWithIdSucceeded'
import withHasRequestWithIdError from './withHasRequestWithIdError'

const IS_SUCCEEDED_PROP = 'withIsLoadingWithId/isRequestSucceeded'
const HAS_ERROR_PROP = 'withIsLoadingWithId/hasRequestError'

const withIsLoadingWithId = (
  requestType,
  { idProp = 'id', outProp = 'isLoading' } = {},
) =>
  compose(
    withIsRequestWithIdSucceeded(requestType, {
      idProp,
      outProp: IS_SUCCEEDED_PROP,
    }),
    withHasRequestWithIdError(requestType, {
      idProp,
      outProp: HAS_ERROR_PROP,
    }),
    withProps(
      R.pipe(
        ownProps => ({
          ...ownProps,
          [outProp]: !ownProps[IS_SUCCEEDED_PROP] && !ownProps[HAS_ERROR_PROP],
        }),
        R.omit([IS_SUCCEEDED_PROP, HAS_ERROR_PROP]),
      ),
    ),
  )

export default withIsLoadingWithId
