import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailTwitchAll from '../../ui/campaigns/views/CampaignDetailTwitchAll'
import withTimeSeriesGraphDynamicLegend from './withTimeSeriesGraphDynamicLegend'
import withPublicationsTable from './withPublicationsTable'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  publications: stats.kpis.totalOrders,
  followers: stats.kpis.totalFollowers,
  views: stats.kpis.totalViews,
  viewers: stats.kpis.totalViewers,
}))

const withTwitchPublicationsTable = withPublicationsTable(orderStatsDetail => ({
  views: orderStatsDetail.kpis.views,
  viewers: orderStatsDetail.kpis.viewers,
}))

const withViewersGraph = withTimeSeriesGraphDynamicLegend(
  stats => stats.orderStatsDetail,
  orderStatsDetail => ({
    id: orderStatsDetail.orderId,
    date: orderStatsDetail.deliveryDate,
    name: orderStatsDetail.resource.name,
    items: orderStatsDetail.series.viewers,
  }),
  {
    dataOutProp: 'viewersData',
    legendOptionsOutProp: 'viewersLegendOptions',
    onLegendDeleteOutProp: 'onViewersLegendDelete',
    onLegendOptionChangeOutProp: 'onViewersLegendOptionChange',
  },
)

const enhance = compose(
  withKPIs,
  withTwitchPublicationsTable,
  withViewersGraph,
  withExportReportContents(({ viewersData }) => ({
    viewersGraphIds: viewersData.map(({ id }) => id),
  })),
)

export default enhance(CampaignDetailTwitchAll)
