import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { CardNumberElement } from 'react-stripe-elements'
import Tooltip from '@redradix/components.tooltip'
import { ReactComponent as CardAmerican } from '../../styles/icons/card-american.svg'
import { ReactComponent as CardMastercard } from '../../styles/icons/card-mastercard.svg'
import { ReactComponent as CardVisa } from '../../styles/icons/card-visa.svg'
import { ReactComponent as CardUnknown } from '../../styles/icons/card.svg'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import withTranslations from '../../hocs/withTranslations'

const getCardBrandElement = brand => {
  switch (brand) {
    case 'visa':
      return CardVisa
    case 'mastercard':
      return CardMastercard
    case 'amex':
      return CardAmerican

    case 'discover':
    case 'diners':
    case 'jcb':
    case 'unionpay':
    case 'unknown':
    default:
      return CardUnknown
  }
}

const InputCardAccount = ({
  label,
  placeholder,
  brand,
  errorText,
  isDisabled,
  onBlur,
  onChange,
  onFocus,
  onReady,
  i18n,
}) => {
  const CardBrand = getCardBrandElement(brand)

  const classes = cx('rdx-input-field input-card-account', {
    'has-label': label,
    'has-error': errorText,
  })

  const stripeStyle = {
    base: {
      color: '#282828',
      fontFamily: 'Rubik, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',

      '::placeholder': {
        color: `#999ab1`,
      },
    },
  }

  return (
    <div className={classes}>
      <div className="rdx-input-field-wrapper">
        <label className="rdx-input-field-label">
          <span className="label-text">{label}</span>
          <Tooltip
            position="right"
            renderTooltip={() => (
              <TooltipRegularText
                text={i18n('payment:tooltip-card', {
                  contactEmail: (
                    <a
                      href={`mailto:${i18n(
                        'payment:tooltip-card-contact-email',
                      )}`}
                    >
                      {i18n('payment:tooltip-card-contact-email')}
                    </a>
                  ),
                })}
              />
            )}
            renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
              <TooltipTrigger
                classNames={triggerClasses}
                onMouseOver={open}
                onMouseOut={close}
                ref={triggerRef}
              />
            )}
            hasArrow
            widthFixed
          />
          <CardNumberElement
            placeholder={placeholder}
            disabled={isDisabled}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onReady={onReady}
            style={stripeStyle}
          />
        </label>
        <div className="icon-card-wrapper">
          <CardBrand className="icon-card" />
        </div>
      </div>
      <div className="rdx-input-field-helper">{errorText}</div>
    </div>
  )
}

InputCardAccount.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  brand: PropTypes.oneOf([
    'visa',
    'mastercard',
    'amex',
    'discover',
    'diners',
    'jcb',
    'unionpay',
    'unknown',
  ]),
  errorText: PropTypes.node,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onReady: PropTypes.func,
}

export default withTranslations(InputCardAccount)
