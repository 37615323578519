import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import GlobalContent from '../shared/GlobalContent'

const ProfileContainer = ({ i18n, children }) => (
  <GlobalContent
    hasPanelBackground
    isTopSpaced
    title={i18n('profile:my-account')}
  >
    {children}
  </GlobalContent>
)

ProfileContainer.propTypes = {
  i18n: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default withTranslations(ProfileContainer)
