import React from 'react'
import withTranslations from '../../hocs/withTranslations'
import ModalHeader from '../../ui/modals/ModalHeader'

const enhanceModalSpinner = modalTitleI18nKey => BaseComponent =>
  withTranslations(props => (
    <div className="modal-loading-wrapper">
      {!!modalTitleI18nKey && (
        <ModalHeader title={props.i18n(modalTitleI18nKey)} />
      )}

      <div className="modal-loader-wrapper">
        <BaseComponent {...props} />
      </div>
    </div>
  ))

export default enhanceModalSpinner
