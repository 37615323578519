import React from 'react'
import PropTypes from 'prop-types'

const ResourceDetailSection = ({
  titleText,
  titleLogoUrl,
  titleLogoAltText,
  children,
}) => {
  return (
    <section className="resource-detail-section">
      {(titleText || titleLogoUrl) && (
        <header className="resource-detail-section-header">
          {titleText ? (
            <h3 className="resource-detail-section-title">{titleText}</h3>
          ) : null}
          {titleLogoUrl && (
            <div className="resource-detail-section-logo">
              <img src={titleLogoUrl} alt={titleLogoAltText} />
            </div>
          )}
        </header>
      )}
      <div className="resource-detail-section-contents">{children}</div>
    </section>
  )
}

ResourceDetailSection.propTypes = {
  titleText: PropTypes.string,
  titleLogoUrl: PropTypes.string,
  titleLogoAltText: PropTypes.string,
  children: PropTypes.node,
}

export default ResourceDetailSection
