import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as Yup from 'yup'

import { registerBrand } from '../../services/brand'
import RegisterBrand from '../../ui/RegisterBrand'
import { REGISTER_BRAND } from '../../services/brand/action-types'
import { BasicRegisterDataSchema, i18nValidation } from '../common/validation'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const RegisterBrandSchema = BasicRegisterDataSchema.concat(
  Yup.object().shape({
    website: Yup.string()
      .url(i18nValidation.invalidURL)
      .required(i18nValidation.required),
  }),
)

const enhance = compose(
  connect(
    null,
    { registerBrand },
  ),

  withEnhancedFormik({
    enableReinitialize: false,

    mapPropsToValues: () => ({
      name: 'John',
      surname: 'Doe',
      email: 'john.doe@redradix.com',
      website: 'https://www.redradix.com',
      phone: '910000000',
      password: 'redradix',
      confirmPassword: 'redradix',
      acceptance: false,
    }),

    validationSchema: RegisterBrandSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.registerBrand({
        ...values,
        acceptance: values.acceptance ? 1 : 0,

        language: 'es',
        origin: 'FB',
      })
    },
  }),

  withIsRequestPending(REGISTER_BRAND),
  withRequestError(REGISTER_BRAND),
  withDeleteRequestOnUnmount(REGISTER_BRAND),
  withFormErrors([
    'name',
    'surname',
    'email',
    'website',
    'phone',
    'password',
    'confirmPassword',
    'acceptance',
  ]),
)

export default enhance(RegisterBrand)
