import * as Sentry from '@sentry/browser'
import { getMyInfo } from './me'

const IS_SENTRY_ENABLED = !!process.env.REACT_APP_SENTRY_DSN

// Init Sentry if available
if (IS_SENTRY_ENABLED) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: window.location.host,
  })

  console.log('Sentry initialized')
}

export function reportSentry(captureFn, state, extraInfo = undefined) {
  // Immediately resolve Promise if Sentry is not enabled
  if (!IS_SENTRY_ENABLED) {
    return Promise.resolve({ eventId: undefined, user: undefined })
  }

  return new Promise((resolve, reject) => {
    let loggedUserInfo

    try {
      loggedUserInfo = getMyInfo(state)
    } catch (e) {
      console.error('Error getting user info for Sentry', e)
    }

    try {
      Sentry.withScope(scope => {
        let user

        if (loggedUserInfo !== undefined) {
          user = {
            ...loggedUserInfo,
            username: `${loggedUserInfo.name} ${loggedUserInfo.surname}`,
          }

          scope.setUser(user)
        }

        if (extraInfo !== undefined) {
          scope.setExtras(extraInfo)
        }

        const reportData = { ...captureFn(), user }

        console.log('Reported to Sentry:', reportData)

        resolve(reportData)
      })
    } catch (e) {
      console.error('Error while reporting to Sentry', e)
      reject(e)
    }
  })
}

export const reportSentryException = (
  exception,
  state,
  extraInfo = undefined,
) =>
  reportSentry(
    () => {
      const eventId = Sentry.captureException(exception)
      return { eventId, exception }
    },
    state,
    extraInfo,
  )

export const reportSentryError500 = (errorData, state) =>
  reportSentry(
    () => {
      const eventId = Sentry.captureMessage(
        `Error ${errorData.status}: ${errorData.method} ${errorData.url}`,
      )
      return { eventId, errorData }
    },
    state,
    errorData,
  )

export const showSentryReportDialog = (eventId, user) => {
  Sentry.showReportDialog({
    eventId,
    user: {
      ...user,
      name: getUserFullName(user),
    },
  })
}

const getUserFullName = user => {
  if (!user) return undefined
  if (user.name && user.surname) return `${user.name} ${user.surname}`

  return undefined
}
