import { compose, lifecycle, branch } from 'recompose'
import { connect } from 'react-redux'
import { getWalletMovements, fetchWalletMovements } from '../../services/wallet'
import withSpinner from '../../hocs/withSpinner'
import { FETCH_MOVEMENTS } from '../../services/wallet/action-types'
import * as R from 'ramda'
import withTranslations from '../../hocs/withTranslations'
import { withScope } from '../../services/auth/hocs'
import withIsLoading from '../../hocs/withIsLoading'
import withRouteServerError from '../../hocs/withRouteServerError'
import withRoute404Error from '../../hocs/withRoute404Error'
import { prettifyConstant } from '../common/routes'

const withWalletMovements = compose(
  withTranslations,
  withScope,

  connect(
    (state, { i18n, scope }) => {
      const walletMovements = getWalletMovements(state)
      if (!walletMovements || !walletMovements.data) return {}

      return {
        movements: walletMovements.data.map(movement => {
          const commissionRole = R.path(
            ['details', 'affiliate', 'role'],
            movement,
          )
          var walletMovementI18NKey = ''
          if (movement.type) {
            const movementType = prettifyConstant(movement.type)
            const baseWalletMovementI18nKey = `wallet-movements-${scope}:${movementType}`
            walletMovementI18NKey = commissionRole
              ? `${baseWalletMovementI18nKey}-${commissionRole}`
              : baseWalletMovementI18nKey
          }

          return {
            id: movement.id,
            date: new Date(movement.date),
            concept: movement.type
              ? i18n(walletMovementI18NKey)
              : movement.details.text,
            resource: R.path(['details', 'product', 'name'], movement),
            amount: movement.amount,
            balance: movement.balance,
            downloadUrl: R.path(['invoice', 'url'], movement),
          }
        }),

        movementsMeta: walletMovements.meta,
      }
    },
    { fetchWalletMovements },
  ),

  lifecycle({
    componentDidMount() {
      const { fetchWalletMovements } = this.props
      fetchWalletMovements(1)
    },
  }),

  branch(
    ({ movements, movementsMeta }) => !movements || !movementsMeta,
    withSpinner(FETCH_MOVEMENTS),
  ),

  withIsLoading(FETCH_MOVEMENTS, 'isLoadingMovements'),
  withRouteServerError(FETCH_MOVEMENTS),
  withRoute404Error(FETCH_MOVEMENTS),
)

export default withWalletMovements
