import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, branch, withPropsOnChange } from 'recompose'
import CampaignDetailView from '../../ui/campaigns/CampaignDetail'
import CampaignDetailContents from './CampaignDetailContents'
import withCampaignResourceTypeStats from './withCampaignResourceTypeStats'
import withPublicationsSelector from './withPublicationsSelector'
import { STATISTICS_TABS } from '../../services/campaigns/business'
import withCampaignModals from './withCampaignModals'
import withIsRequestWithIdPending from '../../hocs/withIsRequestWithIdPending'
import {
  FETCH_CURRENT_CAMPAIGN_ORDERS,
  FETCH_CANCELLED_CAMPAIGN_ORDERS,
} from '../../services/orders/action-types'
import withEffect from '../../hocs/withEffect'
import {
  fetchCurrentCampaignOrders,
  periodicFetchCurrentCampaignOrders,
  cancelPeriodicFetchCurrentCampaignOrders,
  fetchCancelledCampaignOrders,
  periodicFetchCancelledCampaignOrders,
  cancelPeriodicFetchCancelledCampaignOrders,
} from '../../services/orders'
import withURLsSelector from './withURLsSelector'
import {
  ROUTE_BRAND_CAMPAIGNS_URL_STATS_BY_ID,
  prettifyConstant,
} from '../common/routes'
import withPublicationPreviews from './withPublicationPreviews'

const CampaignDetail = ({
  campaignId,
  campaignName,
  resourceType,
  selectorOptions,
  selectorValue,
  selectorErrorText,
  onSelectorChange,
  isCampaignDeletable,
  isLoading,
  onEditCampaign,
  onDeleteCampaign,
  onBackClick,
  stats,
  availableStats,
  downloadPPTURL,
  downloadPDFURL,
  ...props
}) => {
  const hasHiddenStats = availableStats.length === 0

  return (
    <CampaignDetailView
      campaignId={campaignId}
      heading={campaignName}
      type={resourceType}
      selectorOptions={selectorOptions}
      selectorValue={selectorValue}
      selectorErrorText={selectorErrorText}
      onSelectorChange={onSelectorChange}
      isCampaignDeletable={isCampaignDeletable}
      onEditCampaign={onEditCampaign}
      onDeleteCampaign={onDeleteCampaign}
      onBackClick={onBackClick}
      isLoading={isLoading}
      availableStats={availableStats}
      hasHiddenStats={hasHiddenStats}
      downloadPPTURL={downloadPPTURL}
      downloadPDFURL={downloadPDFURL}
    >
      {!isLoading && (
        <CampaignDetailContents
          campaignId={campaignId}
          resourceType={resourceType}
          stats={stats}
          hasHiddenStats={hasHiddenStats}
          {...props}
        />
      )}
    </CampaignDetailView>
  )
}

const withGlobalCurrentOrders = compose(
  connect(
    null,
    {
      fetchCurrentCampaignOrders,
      periodicFetchCurrentCampaignOrders,
      cancelPeriodicFetchCurrentCampaignOrders,
    },
  ),

  withEffect(
    ({
      periodicFetchCurrentCampaignOrders,
      cancelPeriodicFetchCurrentCampaignOrders,
      campaignId,
    }) => {
      periodicFetchCurrentCampaignOrders(campaignId)
      cancelPeriodicFetchCurrentCampaignOrders(campaignId)
      return () => cancelPeriodicFetchCurrentCampaignOrders(campaignId)
    },
    ({
      periodicFetchCurrentCampaignOrders,
      cancelPeriodicFetchCurrentCampaignOrders,
      campaignId,
    }) => [
      periodicFetchCurrentCampaignOrders,
      cancelPeriodicFetchCurrentCampaignOrders,
      campaignId,
    ],
  ),

  withIsRequestWithIdPending(FETCH_CURRENT_CAMPAIGN_ORDERS, {
    idProp: 'campaignId',
    outProp: 'isLoadingCurrentOrders',
  }),
)

const withGlobalCancelledOrders = compose(
  connect(
    null,
    {
      fetchCancelledCampaignOrders,
      periodicFetchCancelledCampaignOrders,
      cancelPeriodicFetchCancelledCampaignOrders,
    },
  ),

  withEffect(
    ({
      periodicFetchCancelledCampaignOrders,
      cancelPeriodicFetchCancelledCampaignOrders,
      campaignId,
    }) => {
      periodicFetchCancelledCampaignOrders(campaignId)
      cancelPeriodicFetchCancelledCampaignOrders(campaignId)
      return () => cancelPeriodicFetchCancelledCampaignOrders(campaignId)
    },
    ({
      periodicFetchCancelledCampaignOrders,
      cancelPeriodicFetchCancelledCampaignOrders,
      campaignId,
    }) => [
      periodicFetchCancelledCampaignOrders,
      cancelPeriodicFetchCancelledCampaignOrders,
      campaignId,
    ],
  ),

  withIsRequestWithIdPending(FETCH_CANCELLED_CAMPAIGN_ORDERS, {
    idProp: 'campaignId',
    outProp: 'isLoadingCancelledOrders',
  }),
)

// Redirect to first SEO URL if type is SEO
const seoEnhance = compose(
  withRouter,
  withPropsOnChange([], () => ({ isLoading: true })),
  withURLsSelector,
  withEffect(
    ({ selectorOptions, history, campaignId }) => {
      if (!selectorOptions) {
        return
      }

      history.replace(
        ROUTE_BRAND_CAMPAIGNS_URL_STATS_BY_ID.linkTo({
          campaignId,
          resourceType: prettifyConstant(STATISTICS_TABS.WEB_SEO),
          seoUrl: selectorOptions[0].value,
        }),
      )
    },
    ({ selectorOptions, history, campaignId }) => [
      selectorOptions,
      history,
      campaignId,
    ],
  ),
)

const restEnhance = compose(
  withCampaignResourceTypeStats,

  branch(
    ({ resourceType }) =>
      !R.isNil(resourceType) && resourceType !== STATISTICS_TABS.GLOBAL,
    withPublicationsSelector,
  ),

  withPublicationPreviews,

  withPropsOnChange(
    [
      'isLoadingResourceStats',
      'isLoadingPublicationsSelector',
      'isLoadingPreviews',
    ],
    ({
      isLoadingResourceStats,
      isLoadingPublicationsSelector,
      isLoadingPreviews,
    }) => ({
      isLoading:
        isLoadingResourceStats ||
        isLoadingPublicationsSelector ||
        isLoadingPreviews,
    }),
  ),

  branch(
    ({ resourceType }) =>
      R.isNil(resourceType) || resourceType === STATISTICS_TABS.GLOBAL,
    compose(
      withGlobalCurrentOrders,
      withGlobalCancelledOrders,
    ),
  ),

  withIsRequestWithIdPending(FETCH_CURRENT_CAMPAIGN_ORDERS, {
    idProp: 'campaignId',
    outProp: 'isLoadingCurrentOrders',
  }),

  withIsRequestWithIdPending(FETCH_CANCELLED_CAMPAIGN_ORDERS, {
    idProp: 'campaignId',
    outProp: 'isLoadingCancelledOrders',
  }),
)

const enhance = compose(
  withCampaignModals,
  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.WEB_SEO,
    seoEnhance,
    restEnhance,
  ),
)

export default enhance(CampaignDetail)
