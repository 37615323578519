import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Chip from '@redradix/components.chip'
import { ReactComponent as IconClose } from '../../styles/icons/close.svg'

const ChipBasic = ({ subtitle, text, title, isInline, onRemove }) => {
  const classes = cx('chip', {
    'is-inline': isInline,
  })
  return (
    <div className={classes}>
      {(title || subtitle) && (
        <div className="chip-header">
          <p className="chip-title">{title}</p>
          <p className="chip-subtitle">{subtitle}</p>
        </div>
      )}
      {onRemove ? (
        <Chip
          className="chip-basic"
          renderRemoveIcon={props => (
            <IconClose className="icon rdx-chip-icon" {...props} />
          )}
          onRemove={onRemove}
        >
          {text}
        </Chip>
      ) : (
        <Chip className="chip-basic">{text}</Chip>
      )}
    </div>
  )
}

ChipBasic.propTypes = {
  subtitle: PropTypes.string,
  text: PropTypes.node.isRequired,
  title: PropTypes.string,
  isInline: PropTypes.bool,
  onRemove: PropTypes.func,
}

export default ChipBasic
