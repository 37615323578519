import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Error404Message from '../../ui/global-message/Error404Message'
import MediaDashboard from './MediaDashboard'
import { withEnsureMyInfo } from '../../services/me/hocs'
import withRouteServerError from '../../hocs/withRouteServerError'
import { compose, branch } from 'recompose'
import { FETCH_WALLET } from '../../services/wallet/action-types'
import {
  ROUTE_MEDIA_DASHBOARD,
  ROUTE_ROOT,
  ROUTE_MEDIA_PROFILE,
  ROUTE_MEDIA_PERSONAL_DATA,
  ROUTE_MEDIA_RESOURCES,
  ROUTE_MEDIA_CREATE_RESOURCES_DISTRIBUTOR,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID,
  ROUTE_MEDIA_ORDER_BY_ID,
  ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA,
  ROUTE_MEDIA_CREATE_WEB_RESOURCES,
  ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE,
  ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES,
  ROUTE_MEDIA_WALLET,
  ROUTE_MEDIA_AFFILIATES,
} from '../common/routes'
import withFetchConfig from '../../hocs/withFetchConfig'
import { FETCH_MEDIA } from '../../services/media/action-types'
import withEffect from '../../hocs/withEffect'
import withSpinner from '../../hocs/withSpinner'
import {
  fetchMedia,
  getMediaInfo,
  periodicFetchMediaStatus,
} from '../../services/media'
import MediaProfile from './MediaProfile'
import ResourcesListPanel from '../resources/ResourcesListPanel'
import ResourceDistributor from '../resources/ResourceDistributor'
import WebResourcesGeneralData from '../resources/WebResourcesGeneralData'
import WebResourcesSocialMedia from '../resources/WebResourcesSocialMedia'
import WebResourcesAnalytics from '../resources/WebResourcesAnalytics'
import WebResourcesServicesPrices from '../resources/WebResourcesServicesPrices'
import SocialResourceGeneralData from '../resources/SocialResourceGeneralData'
import OrderDetailMedia from '../orders/OrderDetailMedia'
import SocialResourceServicesPrices from '../resources/SocialResourceServicesPrices'
import { withFetchNotifications } from '../../services/notifications/hocs'
import Affiliates from './Affiliates'
import { fetchMyTours, getIsWelcomeMediaTourViewed } from '../../services/tour'
import { withFetchAlerts } from '../../services/alerts/hocs'

const MediaRoutes = props => (
  <Switch>
    <Route exact path={ROUTE_MEDIA_DASHBOARD.path} component={MediaDashboard} />

    {/* PROFILE */}

    <Route
      exact
      path={ROUTE_MEDIA_PERSONAL_DATA.path}
      component={MediaProfile}
    />

    {/* WALLET AND MOVEMENTS */}

    <Route exact path={ROUTE_MEDIA_WALLET.path} component={MediaProfile} />

    {/* RESOURCES */}

    <Route
      exact
      path={ROUTE_MEDIA_RESOURCES.path}
      component={ResourcesListPanel}
    />

    <Route
      exact
      path={ROUTE_MEDIA_CREATE_RESOURCES_DISTRIBUTOR.path}
      component={ResourceDistributor}
    />

    <Route
      exact
      path={ROUTE_MEDIA_CREATE_WEB_RESOURCES.path}
      component={WebResourcesGeneralData}
    />

    <Route
      exact
      path={ROUTE_MEDIA_CREATE_SOCIAL_RESOURCES_BY_TYPE.path}
      component={SocialResourceGeneralData}
    />

    {/* Web resources */}

    <Route
      exact
      path={ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID.path}
      component={WebResourcesGeneralData}
    />

    <Route
      exact
      path={ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID.path}
      component={WebResourcesAnalytics}
    />

    <Route
      exact
      path={ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID.path}
      component={WebResourcesSocialMedia}
    />

    <Route
      exact
      path={ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID.path}
      component={WebResourcesServicesPrices}
    />

    {/* Social resources */}

    <Route
      exact
      path={ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_GENERAL_DATA.path}
      component={SocialResourceGeneralData}
    />

    <Route
      exact
      path={ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES.path}
      component={SocialResourceServicesPrices}
    />

    {/* ORDERS */}

    <Route
      exact
      path={ROUTE_MEDIA_ORDER_BY_ID.path}
      component={OrderDetailMedia}
    />

    {/* AFFILIATES */}

    <Route exact path={ROUTE_MEDIA_AFFILIATES.path} component={Affiliates} />

    {/* GENERIC */}

    {/* Root redirects to dashboard */}
    <Route
      exact
      path={ROUTE_ROOT.path}
      render={_ => <Redirect to={ROUTE_MEDIA_DASHBOARD.path} />}
    />

    {/* Profile root (which has no dedicated page) redirects to personal data */}
    <Route
      exact
      path={ROUTE_MEDIA_PROFILE.path}
      render={_ => <Redirect to={ROUTE_MEDIA_PERSONAL_DATA.path} />}
    />

    {/* If none matched, 404 */}
    <Route component={Error404Message} />
  </Switch>
)

const enhance = compose(
  connect(
    state => ({
      mediaInfo: getMediaInfo(state),
      isWelcomeMediaTourViewed: getIsWelcomeMediaTourViewed(state),
    }),
    { fetchMedia, fetchMyTours, periodicFetchMediaStatus },
  ),

  withEffect(
    ({ fetchMedia, fetchMyTours, periodicFetchMediaStatus }) => {
      fetchMedia()
      fetchMyTours()
      periodicFetchMediaStatus()
    },
    ({ fetchMedia, fetchMyTours, periodicFetchMediaStatus }) => [
      fetchMedia,
      fetchMyTours,
      periodicFetchMediaStatus,
    ],
  ),

  withFetchConfig,
  withFetchNotifications,
  withFetchAlerts,
  withEnsureMyInfo,
  withRouteServerError(FETCH_WALLET),

  branch(({ mediaInfo }) => !mediaInfo, withSpinner(FETCH_MEDIA)),
  withRouteServerError(FETCH_MEDIA),
)

export default enhance(MediaRoutes)
