import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
  getDashboardCampaigns,
  repeatingFetchDashboardCampaigns,
  cancelRepeatingFetchDashboardCampaigns,
  repeatingFetchDashboardOrders,
  cancelRepeatingFetchDashboardOrders,
  getDashboardOrders,
  fetchFeaturedResources,
  getFeaturedResources,
} from './'
import withEffect from '../../hocs/withEffect'

export const withDashboard = compose(
  connect(
    state => ({
      campaigns: getDashboardCampaigns(state),
      orders: getDashboardOrders(state),
    }),
    {
      repeatingFetchDashboardCampaigns,
      cancelRepeatingFetchDashboardCampaigns,
      repeatingFetchDashboardOrders,
      cancelRepeatingFetchDashboardOrders,
    },
  ),

  withEffect(
    ({
      repeatingFetchDashboardCampaigns,
      cancelRepeatingFetchDashboardCampaigns,
      repeatingFetchDashboardOrders,
      cancelRepeatingFetchDashboardOrders,
    }) => {
      // Periodically fetch dashboard data
      repeatingFetchDashboardCampaigns()
      repeatingFetchDashboardOrders()

      // Stop fetching when cleaning effect
      return () => {
        cancelRepeatingFetchDashboardCampaigns()
        cancelRepeatingFetchDashboardOrders()
      }
    },
    ({
      repeatingFetchDashboardCampaigns,
      cancelRepeatingFetchDashboardCampaigns,
      repeatingFetchDashboardOrders,
      cancelRepeatingFetchDashboardOrders,
    }) => [
      repeatingFetchDashboardCampaigns,
      cancelRepeatingFetchDashboardCampaigns,
      repeatingFetchDashboardOrders,
      cancelRepeatingFetchDashboardOrders,
    ],
  ),
)

export const withFeaturedResources = compose(
  connect(
    state => ({
      featuredResources: getFeaturedResources(state),
    }),
    { fetchFeaturedResources },
  ),

  withEffect(
    ({ fetchFeaturedResources }) => {
      fetchFeaturedResources()
    },
    ({ fetchFeaturedResources }) => [fetchFeaturedResources],
  ),
)
