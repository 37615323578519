import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import { FormattedMessage } from 'react-intl'
import Button from '@redradix/components.button'
import HackFormattedMoney from '../HackFormattedMoney'
import imageBig from './images/balanceBig.jpg'
import imageSmall from './images/balanceSmall.jpg'

const MovementsAddFunds = ({
  balanceAmount,
  isAddFundsDisabled,
  onAddFunds,
  i18n,
}) => {
  return (
    <div className="profile-movements-funds">
      <div className="profile-movements-funds-contents">
        <div className="profile-movements-funds-image">
          <picture>
            <source media="(max-width: 969px)" srcSet={imageSmall} />
            <source media="(min-width: 970px)" srcSet={imageBig} />
            <img src={imageBig} alt="Decoration" />
          </picture>
        </div>
        <p className="profile-movements-funds-message">
          <FormattedMessage
            id="message:current-balance"
            defaultMessage="message:current-balance"
            values={{
              balance: (
                <span className="message-amount">
                  <HackFormattedMoney
                    value={balanceAmount}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              ),
            }}
          />
        </p>
        <div className="profile-movements-funds-action">
          <Button
            type="button"
            hierarchy="secondary"
            isDisabled={isAddFundsDisabled}
            onClick={onAddFunds}
          >
            {i18n('action:add-funds')}
          </Button>
        </div>
      </div>
    </div>
  )
}

MovementsAddFunds.propTypes = {
  balanceAmount: CoobisPropTypes.money.isRequired,
  isAddFundsDisabled: PropTypes.bool,
  onAddFunds: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(MovementsAddFunds)
