import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest, postRequest, patchRequest } from '../api-call'
import { i18nErrorKeys, i18nValidationKeys } from '../../app/common/validation'
import { parseResponseErrors } from '../error-handling'

const BRANDS_ENDPOINT = `${envConfig.usersBackendUrl}/brands`
const BRAND_ENDPOINT = `${envConfig.usersBackendUrl}/brand`
const BRAND_BILLINGS_ENDPOINT = `${envConfig.usersBackendUrl}/brand/billing`
const BRAND_STATUS_ENDPOINT = `${envConfig.usersBackendUrl}/brand/status`

const MAP_GLOBAL_ERRORS_FETCH_BRAND = {
  BrandNotFoundException: values => ({
    key: i18nErrorKeys.BRAND_NOT_FOUND,
    values,
  }),
}

export function* fetchBrand() {
  const response = yield call(getRequest, `${BRAND_ENDPOINT}`, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_FETCH_BRAND,
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_EDIT_BRAND = {
  BrandNotFoundException: values => ({
    key: i18nErrorKeys.BRAND_NOT_FOUND,
    values,
  }),
  BrandAlreadyExistsException: values => ({
    key: i18nErrorKeys.BRAND_EXISTS,
    values,
  }),
}

export function* editBrandData(data) {
  const response = yield call(patchRequest, `${BRAND_ENDPOINT}`, data, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_EDIT_BRAND,
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_EDIT_BILLINGS_BRAND = {
  BrandNotFoundException: values => ({
    key: i18nErrorKeys.BRAND_NOT_FOUND,
    values,
  }),
  BrandIdentityDocumentCannotBeChangedException: values => ({
    key: i18nErrorKeys.IDENTITY_DOCUMENT_CANNOT_BE_CHANGED,
    values,
  }),
  BrandCurrencyCannotChangeWithPendingPaymentsException: values => ({
    key: i18nErrorKeys.CURRENCY_CANNOT_CHANGE_WITH_PENDING_PAYMENTS,
    values,
  }),
}

const MAP_VALIDATION_ERRORS_EDIT_BILLINGS_BRAND = {
  vatNumber: values => ({
    key: i18nValidationKeys.INVALID_IDENTY_DOCUMENT_NUMBER,
    values,
  }),
  regexp: values => ({
    key: i18nValidationKeys.INVALID_IDENTY_DOCUMENT_NUMBER,
    values,
  }),
}

export function* editBrandBillingData(data) {
  const response = yield call(
    patchRequest,
    `${BRAND_BILLINGS_ENDPOINT}`,
    data,
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_EDIT_BILLINGS_BRAND,
    mapValidation: MAP_VALIDATION_ERRORS_EDIT_BILLINGS_BRAND,
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_REGISTER_BRAND = {
  BrandAlreadyExistsException: values => ({
    key: i18nErrorKeys.BRAND_EXISTS,
    values,
  }),
}

export function* registerBrand(data) {
  const response = yield call(postRequest, `${BRANDS_ENDPOINT}`, data, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_REGISTER_BRAND,
  })

  return parsedResponse
}

export function* fetchBrandStatus() {
  const response = yield call(getRequest, BRAND_STATUS_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_FETCH_BRAND,
  })

  return parsedResponse
}
