import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const CampaignDetailSlide = ({ type, children }) => {
  const classes = cx('glide__slide', {
    [`glide__slide--${type}`]: type,
  })
  return (
    <div className={classes}>
      <div className="slide-content">{children}</div>
    </div>
  )
}

CampaignDetailSlide.propTypes = {
  type: PropTypes.oneOf([
    'facebook',
    'twitter',
    'instagram',
    'youtube',
    'twitch',
    'tiktok',
  ]).isRequired,
  children: PropTypes.any,
}

export default CampaignDetailSlide
