import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'

const BreadcrumbItem = ({
  text,
  listNumber,
  url,
  isActive,
  isVisited,
  isDisabled,
  onClick,
}) => {
  const classes = cx('breadcrumb-navigation-item', {
    'is-active': isActive,
    'is-visited': isVisited,
    'is-disabled': isDisabled,
  })
  return (
    <li className={classes}>
      <Link className={classes} to={url} onClick={onClick}>
        {listNumber && (
          <span className="breadcrumb-navigation-list-number">
            {`${listNumber.toString()}. `}
          </span>
        )}
        {text}
      </Link>
    </li>
  )
}

BreadcrumbItem.propTypes = {
  text: PropTypes.string,
  listNumber: PropTypes.number,
  url: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isVisited: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default BreadcrumbItem
