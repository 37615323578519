import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ReactComponent as Add } from '../../styles/icons/add.svg'

const CardPlaceholder = ({ actionText, linkUrl, children }) => {
  return (
    <div className="card-placeholder">
      {linkUrl && (
        <Link to={linkUrl} className="card-placeholder-action">
          <span className="card-placeholder-action-contents">
            <span className="text">{actionText}</span>
            <span className="image">
              <Add className="icon" />
            </span>
          </span>
        </Link>
      )}
      {children}
    </div>
  )
}

CardPlaceholder.propTypes = {
  actionText: PropTypes.string,
  linkUrl: PropTypes.string,
  children: PropTypes.node,
}

export default CardPlaceholder
