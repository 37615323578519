import React from 'react'
import { FormattedNumberParts, injectIntl } from 'react-intl'
import CoobisPropTypes from '../app/common/coobis-prop-types'

const FormattedCurrency = ({ intl, value }) => (
  <FormattedNumberParts style={`currency`} currency={value.isoCode}>
    {parts => {
      const currencyString = parts
        .map(p => (p.type === 'currency' ? p.value : null))
        .filter(p => p)
        .join('')
      const Text = intl.textComponent
      return <Text>{currencyString}</Text>
    }}
  </FormattedNumberParts>
)

FormattedCurrency.propTypes = {
  value: CoobisPropTypes.currency.isRequired,
}

export default injectIntl(FormattedCurrency)
