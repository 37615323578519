import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailWebBrandingPublication from '../../ui/campaigns/views/CampaignDetailWebBrandingPublication'
import withTranslations from '../../hocs/withTranslations'
import { withTranslatedCountries } from '../../services/config/hocs'
import withTimeSeriesGraphStaticLegend from './withTimeSeriesGraphStaticLegend'
import withAgeAudience from './withAgeAudience'
import withGenderAudience from './withGenderAudience'
import withCountryChart from '../common/withCountryChart'
import withCountryWidget from '../common/withCountryWidget'
import withCityWidget from '../common/withCityWidget'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  totalVisits: stats.kpis.visits,
  totalUniqueVisitors: stats.kpis.uniqueVisitors,
  engagements: stats.kpis.engagements,
  averagePageTime: stats.kpis.timeOnPageAverage,
  averageScrollPercentage: stats.kpis.scrollingPercentAverage,
  averageBounceRate: stats.kpis.bouncePercentAverage,
}))

const withEngagementsGraph = withTimeSeriesGraphStaticLegend(
  stats => [stats.series],
  (series, { stats }) => ({
    id: stats.orderId,
    name: stats.resource.name,
    items: series.engagements,
  }),
  {
    dataOutProp: 'engagementData',
  },
)

const enhance = compose(
  withTranslations,
  withTranslatedCountries(),

  withKPIs,
  withAgeAudience,
  withGenderAudience,
  withCountryChart({
    dataPath: ['stats', 'stats', 'countryDemographics'],
  }),
  withCountryWidget({
    dataPath: ['stats', 'stats', 'countryDemographics'],
  }),
  withCityWidget({
    dataPath: ['stats', 'stats', 'cityDemographics'],
  }),
  withEngagementsGraph,
  withExportReportContents(),
)

export default enhance(CampaignDetailWebBrandingPublication)
