import React from 'react'
import { compose, branch, withPropsOnChange, lifecycle } from 'recompose'
import CampaignDetailView from '../../ui/campaigns/CampaignDetail'
import withCampaignModals from './withCampaignModals'
import withPublicationsSelector from './withPublicationsSelector'
import { STATISTICS_TABS } from '../../services/campaigns/business'
import * as R from 'ramda'
import withCampaignOrderStats from './withCampaignOrderStats'
import CampaignPublicationDetailContents from './CampaignPublicationDetailContents'
import { prettifyConstant, ROUTE_BRAND_CAMPAIGNS_BY_ID } from '../common/routes'
import { SERVICE_TYPES } from '../../services/orders/business'
import withURLsSelector from './withURLsSelector'
import withCampaignSEOURLStats from './withCampaignSEOURLStats'
import withPublicationPreviews from './withPublicationPreviews'

const ALLOWED_SERVICE_TYPES_BY_STATISTICS_TAB = {
  [STATISTICS_TABS.WEB_BRANDING]: [
    SERVICE_TYPES.WEB_POST,
    SERVICE_TYPES.WEB_ONLY_POST,
  ],
  [STATISTICS_TABS.WEB_SEO]: [
    SERVICE_TYPES.WEB_POST,
    SERVICE_TYPES.WEB_ONLY_POST,
  ],
  [STATISTICS_TABS.FACEBOOK]: [SERVICE_TYPES.FACEBOOK],
  [STATISTICS_TABS.TWITTER]: [SERVICE_TYPES.TWITTER],
  [STATISTICS_TABS.INSTAGRAM]: [
    SERVICE_TYPES.INSTAGRAM_PHOTO,
    SERVICE_TYPES.INSTAGRAM_VIDEO,
    SERVICE_TYPES.INSTAGRAM_STORY,
  ],
  [STATISTICS_TABS.YOUTUBE]: [
    SERVICE_TYPES.YOUTUBE_MENTION,
    SERVICE_TYPES.YOUTUBE_DEDICATED,
  ],
  [STATISTICS_TABS.TWITCH]: [SERVICE_TYPES.TWITCH],
  [STATISTICS_TABS.TIKTOK]: [
    SERVICE_TYPES.TIKTOK_SHORT,
    SERVICE_TYPES.TIKTOK_LONG,
  ],
}
const CampaignPublicationDetail = ({
  campaignId,
  campaignName,
  resourceType,
  selectorOptions,
  selectorValue,
  selectorErrorText,
  onSelectorChange,
  isCampaignDeletable,
  isLoading,
  onEditCampaign,
  onDeleteCampaign,
  onBackClick,
  stats,
  availableStats,
  downloadPPTURL,
  downloadPDFURL,
  ...props
}) => (
  <CampaignDetailView
    campaignId={campaignId}
    heading={campaignName}
    type={resourceType}
    selectorOptions={selectorOptions}
    selectorValue={selectorValue}
    selectorErrorText={selectorErrorText}
    onSelectorChange={onSelectorChange}
    isCampaignDeletable={isCampaignDeletable}
    onEditCampaign={onEditCampaign}
    onDeleteCampaign={onDeleteCampaign}
    onBackClick={onBackClick}
    isLoading={isLoading}
    availableStats={availableStats}
    downloadPPTURL={downloadPPTURL}
    downloadPDFURL={downloadPDFURL}
  >
    {!isLoading && (
      <CampaignPublicationDetailContents
        campaignId={campaignId}
        resourceType={resourceType}
        stats={stats}
        {...props}
      />
    )}
  </CampaignDetailView>
)

const seoEnhance = compose(
  withCampaignSEOURLStats,

  withURLsSelector,

  withPropsOnChange(
    ['isLoadingSEOURLStats', 'isLoadingPublicationsSelector'],
    ({ isLoadingSEOURLStats, isLoadingPublicationsSelector }) => ({
      isLoading: isLoadingSEOURLStats || isLoadingPublicationsSelector,
    }),
  ),
)

const restEnhance = compose(
  withCampaignOrderStats,

  branch(
    ({ resourceType }) =>
      !R.isNil(resourceType) && resourceType !== STATISTICS_TABS.GLOBAL,
    withPublicationsSelector,
  ),

  withPublicationPreviews,

  withPropsOnChange(
    [
      'isLoadingResourceStats',
      'isLoadingPublicationsSelector',
      'isLoadingPreviews',
    ],
    ({
      isLoadingResourceStats,
      isLoadingPublicationsSelector,
      isLoadingPreviews,
    }) => ({
      isLoading:
        isLoadingResourceStats ||
        isLoadingPublicationsSelector ||
        isLoadingPreviews,
    }),
  ),

  // Move to "all" section if current order has wrong type in URL
  lifecycle({
    componentDidUpdate(prev) {
      const { resourceType, stats, history, campaignId } = this.props

      if (
        !!stats &&
        !ALLOWED_SERVICE_TYPES_BY_STATISTICS_TAB[resourceType].includes(
          stats.serviceType,
        )
      ) {
        history.replace(
          ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
            resourceType: prettifyConstant(resourceType),
            campaignId,
          }),
        )
      }
    },
  }),
)

const enhance = compose(
  withCampaignModals,

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.WEB_SEO,
    seoEnhance,
    restEnhance,
  ),
)

export default enhance(CampaignPublicationDetail)
