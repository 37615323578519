import { withPropsOnChange } from 'recompose'
import { PAYMENT_METHODS } from '../../services/checkout/business'

// Add totalCartPrice prop with sum of all prices
const withTotalCartPrice = withPropsOnChange(['cart'], ({ cart }) => {
  if (!cart || !cart.items || !cart.paymentTypes) {
    return {}
  }

  const walletPayment = cart.paymentTypes.find(
    ({ paymentMethod }) => paymentMethod === PAYMENT_METHODS.WALLET_BALANCE,
  )

  if (!walletPayment) {
    return {}
  }

  return {
    totalCartPrice: walletPayment.cartItemsSubtotal,
  }
})

export default withTotalCartPrice
