import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import { ROUTE_BRAND_RESOURCES_SEARCH } from '../../app/common/routes'
import Dashboard from '../dashboard/Dashboard'
import CampaignsDashboardBannerIframe from './CampaignsDashboardBannerIframe'
import DashboardPanel from '../dashboard/DashboardPanel'
import Spinner from '../loaders/Spinner'
import InitialCard from '../cards/InitialCard'
import CampaignsPendingOrdersPanel from './CampaignsPendingOrdersPanel'
import CampaignsList from './CampaignsList'
import initialImage from './images/search-media.png'

const CampaignsDashboard = ({
  hadOrders,
  hadCampaigns,
  campaigns,
  orders,
  onCreateCampaignClick,
  orderSearchValue,
  onOrderSearchChange,
  campaignSearchValue,
  onCampaignSearchChange,
  onOrderActionClick,
  ordersCurrentPage,
  ordersTotalPages,
  onOrdersPageClick,
  onOrdersSortChange,
  ordersSortValue,
  campaignsCurrentPage,
  campaignsTotalPages,
  onCampaignsPageClick,
  isLoading,
  i18n,
  hadBanner,
}) => {
  return (
    <>
      {hadBanner ? <CampaignsDashboardBannerIframe /> : ''}
      <Dashboard>
        {isLoading ? (
          <Spinner size={60} text={i18n('status:loading-campaigns')} />
        ) : hadCampaigns ? (
          <>
            <CampaignsPendingOrdersPanel
              orders={orders}
              searchValue={orderSearchValue}
              onSearchChange={onOrderSearchChange}
              onActionClick={onOrderActionClick}
              currentPage={ordersCurrentPage}
              totalPages={ordersTotalPages}
              onPageClick={onOrdersPageClick}
              onSortChange={onOrdersSortChange}
              sortValue={ordersSortValue}
              hadOrders={hadOrders}
            />
            <CampaignsList
              campaigns={campaigns}
              onCreateClick={onCreateCampaignClick}
              searchValue={campaignSearchValue}
              onSearchChange={onCampaignSearchChange}
              currentPage={campaignsCurrentPage}
              totalPages={campaignsTotalPages}
              onPageClick={onCampaignsPageClick}
            />
          </>
        ) : (
          <DashboardPanel title={i18n('message:no-campaigns')}>
            <InitialCard
              linkUrl={ROUTE_BRAND_RESOURCES_SEARCH.linkTo()}
              linkText={i18n('action:search-media-influencers')}
              imageBigUrl={initialImage}
              imageSmallUrl={initialImage}
            />
          </DashboardPanel>
        )}
      </Dashboard>
    </>
  )
}

CampaignsDashboard.propTypes = {
  hadOrders: PropTypes.bool,
  hadCampaigns: PropTypes.bool,
  orders: PropTypes.arrayOf(CoobisPropTypes.orderRow),
  campaigns: PropTypes.arrayOf(CoobisPropTypes.campaign),
  isLoading: PropTypes.bool,
  onCreateCampaignClick: PropTypes.func,
  orderSearchValue: PropTypes.string,
  campaignSearchValue: PropTypes.string,
  onOrderSearchChange: PropTypes.func,
  onCampaignSearchChange: PropTypes.func,
  onOrderActionClick: PropTypes.func,
  ordersCurrentPage: PropTypes.number,
  ordersTotalPages: PropTypes.number,
  onOrdersPageClick: PropTypes.func,
  onOrdersSortChange: PropTypes.func,
  ordersSortValue: PropTypes.string,
  campaignsCurrentPage: PropTypes.number,
  campaignsTotalPages: PropTypes.number,
  onCampaignsPageClick: PropTypes.func,
  i18n: PropTypes.func.isRequired,
  hadBanner: PropTypes.bool,
}

export default withTranslations(CampaignsDashboard)
