import { compose, withPropsOnChange } from 'recompose'
import * as R from 'ramda'
import { withCurrencies } from '../services/config/hocs'

const withCurrencySelectField = compose(
  withCurrencies,
  withPropsOnChange(['values', 'currencies'], ({ values, currencies }) => {
    const currencyOptions = R.pipe(
      R.map(currency => ({
        value: currency.isoCode,
        label: `${currency.symbol} (${currency.isoCode})`,
        isDisabled: false,
      })),
      R.filter(
        currency =>
          currency.value === 'EUR' ||
          (!R.isNil(values.country) && values.country.value === 'EUR'), //COOBIS-2404 should be USD
      ),
    )(currencies)
    const currencyValue = values.currency
      ? R.pipe(R.find(item => item.value === values.currency))(currencyOptions)
      : null
    return {
      values: { ...values, currency: currencyValue },
      currencyOptions,
    }
  }),
)

export default withCurrencySelectField
