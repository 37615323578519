import React from 'react'
import PropTypes from 'prop-types'
import CampaignDetailNavigation from './CampaignDetailNavigation'
import ButtonDropdown from '../dropdown/ButtonDropdown'
import { ReactComponent as IconDots } from '../../styles/icons/ellipsis.svg'
import { ReactComponent as IconTrash } from '../../styles/icons/delete.svg'
import { ReactComponent as IconEdit } from '../../styles/icons/edit.svg'

const CampaignDetailHeader = ({
  heading,
  navigationSlot,
  isCampaignDeletable,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="campaign-detail-header">
      <div className="wrapper">
        <div className="campaign-detail-header-data">
          <div className="campaign-detail-heading-wrapper">
            <h1 className="campaign-detail-heading">{heading}</h1>
            <div className="campaign-detail-header-actions">
              {isCampaignDeletable ? (
                <ButtonDropdown
                  content={[
                    {
                      buttonIcon: props => <IconEdit {...props} />,
                      isWarning: false,
                      action: onEdit,
                      actionText: 'action:rename-campaign',
                    },
                    {
                      buttonIcon: props => <IconTrash {...props} />,
                      isWarning: true,
                      action: onDelete,
                      actionText: 'action:delete-campaign',
                    },
                  ]}
                  renderIcon={props => <IconDots {...props} />}
                />
              ) : (
                <ButtonDropdown
                  content={[
                    {
                      buttonIcon: props => <IconEdit {...props} />,
                      isWarning: false,
                      action: onEdit,
                      actionText: 'action:rename-campaign',
                    },
                    {
                      buttonIcon: props => <IconTrash {...props} />,
                      isWarning: false,
                      action: onDelete,
                      actionText: 'message:cannot-delete-has-orders',
                      isDisabled: true,
                    },
                  ]}
                  renderIcon={props => <IconDots {...props} />}
                />
              )}
            </div>
          </div>
        </div>
        <CampaignDetailNavigation>{navigationSlot}</CampaignDetailNavigation>
      </div>
    </div>
  )
}

CampaignDetailHeader.propTypes = {
  heading: PropTypes.string,
  navigationSlot: PropTypes.node,
  isCampaignDeletable: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default CampaignDetailHeader
