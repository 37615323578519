import React from 'react'
import PropTypes from 'prop-types'
import Loading from '@redradix/components.loading'

const Spinner = ({ text, size }) => (
  <Loading text={text} size={size}>
    <svg
      viewBox="0 0 148 148"
      aria-labelledby="loading-title loading-description"
      role="img"
    >
      <title id="loading-title">Loading</title>
      <desc id="loading-description">{text}</desc>
      <circle cx="74" cy="74" r="65" />
    </svg>
  </Loading>
)

Spinner.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number,
}

export default Spinner
