import React from 'react'
import HackFormattedNumber from './HackFormattedNumber'
import CoobisPropTypes from '../app/common/coobis-prop-types'
import { moneyToFloat } from '../app/utils'

const HackFormattedMoney = ({
  value,
  minimumFractionDigits,
  maximumFractionDigits,
  ...props
}) => (
  <HackFormattedNumber
    value={moneyToFloat(value)}
    currency={value.currency.isoCode}
    minimumFractionDigits={
      minimumFractionDigits
        ? minimumFractionDigits
        : moneyToFloat(value) % 1 === 0
        ? 0
        : 2
    }
    maximumFractionDigits={
      maximumFractionDigits
        ? maximumFractionDigits
        : moneyToFloat(value) % 1 === 0
        ? 0
        : 2
    }
    style={`currency`}
    {...props}
  />
)

HackFormattedMoney.propTypes =
  HackFormattedNumber.propTypes &
  {
    value: CoobisPropTypes.money.isRequired,
  }

export default HackFormattedMoney
