import { compose, withProps, withPropsOnChange } from 'recompose'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { connect } from 'react-redux'
import { editMediaBillingData } from '../../services/media'
import withRequestError from '../../hocs/withRequestError'
import { EDIT_MEDIA_BILLING_DATA } from '../../services/media/action-types'
import * as R from 'ramda'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withFormErrors from '../../hocs/withFormErrors'
import { i18nValidation } from '../common/validation'
import withTranslations from '../../hocs/withTranslations'
import {
  PROFESSIONAL_TYPES,
  PROFESSIONAL_TYPES_ORDER,
  PROFESSIONAL_TYPES_I18N,
  needsPaypalOrIBAN,
  getApplicableFormFields,
  getTypeDocumentApplicable,
  getResidenceDocumentApplicable,
  billingDataSchema,
} from '../../services/media/business'
import MediaBillingForm from '../../ui/profile/MediaBillingForm'
import withCleanFormSuccess from '../../hocs/withCleanFormSuccess'
import withCountryStateSelectFields from '../../hocs/withCountryStateSelectFields'
import { withCountries } from '../../services/config/hocs'
import withCurrencySelectField from '../../hocs/withCurrencySelectField'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withConfirmSubmitModal from '../../hocs/withConfirmSubmitModal'
import { getBalance } from '../../services/wallet'
import { getMediaStatus } from '../../services/media'
import { moneyToFloat } from '../utils'
import { withMyInfo } from '../../services/me/hocs'

const enhance = compose(
  withProps(({ mediaInfo: { billing } }) => ({
    hasIdentityDocument:
      billing.identityDocument !== '' && !R.isNil(billing.identityDocument),
    hasCountry: billing.country !== '' && !R.isNil(billing.country),
  })),
  withTranslations,
  withMyInfo,
  withCountries,
  connect(
    null,
    {
      editMediaBillingData,
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ mediaInfo: { billing } }) => ({
      type: R.propOr('', 'type', billing),
      country: R.propOr('', 'country', billing),
      state: R.propOr('', 'state', billing),
      currency: R.pathOr(null, ['currency', 'isoCode'], billing),
      name: R.propOr('', 'name', billing),
      surname: R.propOr('', 'surname', billing),
      surname2: R.propOr('', 'surname2', billing),
      identityDocument: R.propOr('', 'identityDocument', billing),
      businessName: R.propOr('', 'businessName', billing),
      city: R.propOr('', 'city', billing),
      address: R.propOr('', 'address', billing),
      postalCode: R.propOr('', 'postalCode', billing),
      iban: R.propOr('', 'iban', billing),
      paypal: R.propOr('', 'paypal', billing),

      identityDocumentUpload: billing.identityDocumentUpload,
      typeDocumentUpload: billing.typeDocumentUpload,
      residenceDocumentUpload: billing.residenceDocumentUpload,
    }),

    fileFields: [
      'identityDocumentUpload',
      'typeDocumentUpload',
      'residenceDocumentUpload',
    ],

    selectFields: ['type', 'country', 'state', 'currency'],

    validationSchema: billingDataSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      if (needsPaypalOrIBAN(values, { iban: true, paypal: true })) {
        return
      }

      const applicableValues = R.pick(
        getApplicableFormFields(props.countries, values),
        values,
      )
      props.editMediaBillingData(applicableValues)
    },
  }),

  withIsRequestPending(EDIT_MEDIA_BILLING_DATA),
  withIsRequestSucceeded(EDIT_MEDIA_BILLING_DATA),
  withRequestError(EDIT_MEDIA_BILLING_DATA),
  withDeleteRequestOnUnmount(EDIT_MEDIA_BILLING_DATA),
  withFormErrors(props =>
    getApplicableFormFields(props.countries, props.values),
  ),
  withCleanFormSuccess({
    fields: ownProps =>
      getApplicableFormFields(ownProps.countries, ownProps.values),
  }),

  withPropsOnChange(['values', 'countries'], ({ values, countries }) => {
    return {
      isCompany: values.type === PROFESSIONAL_TYPES.COMPANY,
      isSelfEmployedOrIndividual:
        values.type === PROFESSIONAL_TYPES.INDIVIDUAL ||
        values.type === PROFESSIONAL_TYPES.SELF_EMPLOYED,
      billingCountry: values.country,
      requiresResidenceDocument: getResidenceDocumentApplicable(
        countries,
        values,
      ),
      requiresTypeDocument: getTypeDocumentApplicable(values),
    }
  }),

  // Show global message if one of IBAN or Paypal is required
  withPropsOnChange(
    ['values', 'globalMessages', 'touched'],
    ({ values, globalMessages = [], touched }) => {
      if (needsPaypalOrIBAN(values, touched)) {
        return {
          globalMessages: [
            i18nValidation.requireIBANOrPaypal(),
            ...(globalMessages || []),
          ],
        }
      }

      return {}
    },
  ),

  withCountryStateSelectFields(),

  withPropsOnChange(['values', 'i18n'], ({ values, i18n }) => {
    const professionalTypeOptions = R.pipe(
      R.map(professionalType => ({
        value: professionalType,
        label: i18n(PROFESSIONAL_TYPES_I18N[professionalType]),
        isDisabled: false,
      })),
    )(PROFESSIONAL_TYPES_ORDER)

    const professionalTypeValue = values.type
      ? R.pipe(R.find(item => item.value === values.type))(
          professionalTypeOptions,
        )
      : null

    return {
      values: {
        ...values,
        type: professionalTypeValue,
      },
      professionalTypeOptions,
    }
  }),

  withCurrencySelectField,
  connect(state => ({
    hasPendingBalance: moneyToFloat(getBalance(state)) > 0,
    hasWorkInProgressOrders: getMediaStatus(state).ordersInProgress > 0,
  })),

  withPropsOnChange(['touchedSinceSubmit'], ({ touchedSinceSubmit }) => ({
    isConfirmRequired: touchedSinceSubmit.currency,
  })),

  withConfirmSubmitModal({
    title: 'billing-data:alert-currency-change',
    description: 'billing-data:alert-currency-will-change-with-consecuences',
    submitText: 'action:continue',
    cancelText: 'action:cancel',
    isSmallSized: false,
    isConfirmRequiredProp: 'isConfirmRequired',
  }),
)

export default enhance(MediaBillingForm)
