import * as actionTypes from './action-types'
import { set } from 'reduken/hash'
import * as api from './api'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
} from '../communication/actions'
import {
  REFRESH_DASHBOARD_TIME,
  HASH_KEY_DASHBOARD_CAMPAIGNS,
  DOMAIN,
  HASH_KEY_DASHBOARD_ORDERS,
  HASH_KEY_FEATURED_RESOURCES,
} from './constants'
import {
  repeatingFetchDashboardCampaigns,
  repeatingFetchDashboardOrders,
} from './actions'
import repeatingFetchEffect from '../repeating-fetch-effect'

function* fetchDashboardCampaignsSaga() {
  yield put(startRequest(actionTypes.FETCH_DASHBOARD_CAMPAIGNS))

  const response = yield call(api.fetchCampaigns)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_DASHBOARD_CAMPAIGNS, response))
    return
  }

  if (!response.data) {
    yield put(
      endRequestError(
        actionTypes.FETCH_DASHBOARD_CAMPAIGNS,
        new Error('Missing Campaigns'),
      ),
    )
    return
  }

  yield put(set(DOMAIN, HASH_KEY_DASHBOARD_CAMPAIGNS, response.data))
  yield put(endRequestSuccess(actionTypes.FETCH_DASHBOARD_CAMPAIGNS))
}

export function* fetchDashboardOrdersSaga() {
  yield put(startRequest(actionTypes.FETCH_DASHBOARD_ORDERS))

  const response = yield call(api.fetchOrders)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_DASHBOARD_ORDERS, response))
    return
  }

  if (!response.data) {
    yield put(
      endRequestError(
        actionTypes.FETCH_DASHBOARD_ORDERS,
        new Error('Missing Orders'),
      ),
    )
    return
  }

  const orders = response.data.map(order => ({
    ...order,
    createdAt: new Date(order.createdAt),
  }))

  yield put(set(DOMAIN, HASH_KEY_DASHBOARD_ORDERS, orders))
  yield put(endRequestSuccess(actionTypes.FETCH_DASHBOARD_ORDERS))
}

function* fetchFeaturedResourcesSaga() {
  yield put(startRequest(actionTypes.FETCH_FEATURED_RESOURCES))

  const response = yield call(api.fetchFeaturedResources)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_FEATURED_RESOURCES, response))
    return
  }

  if (!response.data || !response.data.data) {
    yield put(
      endRequestError(
        actionTypes.FETCH_FEATURED_RESOURCES,
        new Error('Missing featured resources'),
      ),
    )
    return
  }

  yield put(set(DOMAIN, HASH_KEY_FEATURED_RESOURCES, response.data.data))
  yield put(endRequestSuccess(actionTypes.FETCH_FEATURED_RESOURCES))
}

export default function*() {
  yield all([
    repeatingFetchEffect({
      fetchSaga: fetchDashboardCampaignsSaga,
      intervalMillisecs: REFRESH_DASHBOARD_TIME,
      startAction: repeatingFetchDashboardCampaigns(),
      requestActionType: actionTypes.FETCH_DASHBOARD_CAMPAIGNS,
      cancelActionType: actionTypes.CANCEL_REPEATING_FETCH_DASHBOARD_CAMPAIGNS,
    }),
    repeatingFetchEffect({
      fetchSaga: fetchDashboardOrdersSaga,
      intervalMillisecs: REFRESH_DASHBOARD_TIME,
      startAction: repeatingFetchDashboardOrders(),
      requestActionType: actionTypes.FETCH_DASHBOARD_ORDERS,
      cancelActionType: actionTypes.CANCEL_REPEATING_FETCH_DASHBOARD_ORDERS,
    }),
    takeLatest(
      actionTypes.FETCH_FEATURED_RESOURCES,
      fetchFeaturedResourcesSaga,
    ),
  ])
}
