export const SERVICE = 'COMMUNICATION'

export const DOMAIN = 'communication'

export const HASH_KEY_REQUEST_STATUS = 'requestStatus'

export const REQUEST_STATUS = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

export const REQUEST_EMPTY_STATUS = {
  status: REQUEST_STATUS.NONE,
}
