import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../../hocs/withFormErrors'
import withTranslations from '../../../hocs/withTranslations'
import { MediaQueryLap } from '../../shared/MediaQueries'
import { FormattedMessage } from 'react-intl'
import ModalTitle from '../../texts/ModalTitle'
import WarningMessage from '../../messages/WarningMessage'
import InputCardAccount from '../../form-elements/InputCardAccount'
import InputCardCvc from '../../form-elements/InputCardCvc'
import InputCardExpirationDate from '../../form-elements/InputCardExpirationDate'
import { Input } from '@redradix/components.input'
import NumberFormat from 'react-number-format'
import Button from '@redradix/components.button'
import SuccessMessage from '../../messages/SuccessMessage'
import FormattedFormErrors from '../../FormattedFormErrors'
import HackFormattedMoney from '../../HackFormattedMoney'
import HelpMessage from '../../messages/HelpMessage'
import FormattedNumberInput from '../../../app/common/FormattedNumberInput'
import useNumberFormat from '../../../hooks/useNumberFormat'
import fundsImage from '../images/funds-small.png'
import { moneyToFloat } from '../../../app/utils'

const AddFundsModal = ({
  // FORM VALUES
  values,
  handleChange,
  handleBlur,
  onNewAmountBlur,
  touched,

  // Payment
  onSubmit,
  isRequestPending,

  // Card payment
  cardBrand,
  onCardNumberChange,
  onCardNumberBlur,
  cardNumberError,
  onCardExpirationChange,
  onCardExpirationBlur,
  cardExpirationError,
  onCardCVCChange,
  onCardCVCBlur,
  cardCVCError,

  // Discount coupon
  discountCoupon,
  onDiscountCouponChange,
  onCouponSubmit,
  isValidatingCoupon,
  successCode,
  errorCode,

  // Order summary
  total,
  totalGross,
  totalVat,
  totalDiscount,
  vatPercentage,

  // withFormErrors
  globalMessages,
  validationMessages,

  formatOptions = {},

  i18n,
  i18nNumber,
}) => {
  const { format, removeFormatting } = useNumberFormat(
    formatOptions,
    i18nNumber,
  )

  const onNewAmountChange = useCallback(
    ({ floatValue }) =>
      handleChange({
        target: { name: 'newAmount', value: floatValue },
      }),
    [handleChange],
  )

  return (
    <div className="modal-flex-content add-funds-modal">
      <div className="modal-flex-content-wrapper">
        <ModalTitle text={i18n('action:add-funds')} />
        <form id="couponForm" onSubmit={onCouponSubmit} />
        <form className="basic-modal-form" onSubmit={onSubmit} noValidate>
          <div className="card-form">
            <div className="card-form-row">
              <NumberFormat
                label={i18n('label:recharge-amount')}
                value={values.newAmount}
                format={format}
                removeFormatting={removeFormatting}
                onValueChange={onNewAmountChange}
                customInput={FormattedNumberInput}
                // Magically cascade props to customInput
                id="newAmount"
                name="newAmount"
                errorText={
                  validationMessages.newAmount && (
                    <FormattedFormErrors
                      errors={validationMessages.newAmount}
                    />
                  )
                }
                isDisabled={isRequestPending}
                isRequired={true}
                isTouched={touched.newAmount}
                onBlur={onNewAmountBlur}
              />
              <Input
                type="text"
                name="concept"
                label={i18n('label:recharge-concept')}
                placeholder={i18n('add-funds:concept-placeholder')}
                widthBehaviour="full"
                value={values.concept}
                onChange={handleChange}
                onBlur={handleBlur}
                isTouched={touched.concept}
                isDisabled={isRequestPending}
                isRequired={true}
                errorText={
                  validationMessages.concept && (
                    <FormattedFormErrors errors={validationMessages.concept} />
                  )
                }
              />
            </div>
            <div className="card-form-row">
              <InputCardAccount
                label={i18n('checkout:card-number')}
                placeholder={i18n('checkout:card-number-placeholder')}
                brand={cardBrand}
                errorText={cardNumberError}
                isDisabled={isRequestPending}
                onChange={onCardNumberChange}
                onBlur={onCardNumberBlur}
              />
              <InputCardExpirationDate
                label={i18n('checkout:expiration-date')}
                placeholder={i18n('checkout:expiration-date-placeholder')}
                errorText={cardExpirationError}
                isDisabled={isRequestPending}
                onChange={onCardExpirationChange}
                onBlur={onCardExpirationBlur}
              />
            </div>
            {/* Card form row */}
            <div className="card-form-row">
              <InputCardCvc
                label={i18n('checkout:card-security-code')}
                placeholder={i18n('checkout:card-security-code-placeholder')}
                errorText={cardCVCError}
                isDisabled={isRequestPending}
                onChange={onCardCVCChange}
                onBlur={onCardCVCBlur}
              />
              <div className="coupon-code-field-wrapper">
                <div className="coupon-code-field">
                  <Input
                    type="text"
                    name="discountCoupon"
                    id="discountCoupon"
                    label={i18n('checkout:discount-coupon')}
                    placeholder={i18n('checkout:discount-coupon-placeholder')}
                    widthBehaviour="full"
                    value={discountCoupon}
                    onChange={onDiscountCouponChange}
                    isDisabled={isValidatingCoupon || isRequestPending}
                    form="couponForm"
                  />
                  <Button
                    type="submit"
                    isLoading={isValidatingCoupon}
                    isDisabled={
                      !discountCoupon ||
                      discountCoupon.length === 0 ||
                      isRequestPending
                    }
                    widthBehaviour="full"
                    hierarchy="secondary"
                    onClick={onCouponSubmit}
                    form="couponForm"
                  >
                    {i18n('action:apply')}
                  </Button>
                </div>
                {/* Coupon code field */}
                {successCode && (
                  <SuccessMessage iconType="fill-success">
                    <FormattedMessage
                      id="checkout:discount-applied"
                      defaultMessage="checkout:discount-applied"
                      values={{
                        couponDetails: (
                          <strong>
                            {i18n(
                              'checkout:discount-applied-detail',
                              successCode,
                            )}
                          </strong>
                        ),

                        couponLimits: (
                          <strong>
                            {i18n(
                              'checkout:discount-applied-limits',
                              successCode,
                            )}
                          </strong>
                        ),
                        br: <br />,
                      }}
                    />
                  </SuccessMessage>
                )}
                {errorCode && (
                  <WarningMessage
                    iconType="fill-warning"
                    text={<FormattedFormErrors errors={errorCode} />}
                  />
                )}
              </div>
            </div>

            {globalMessages && (
              <WarningMessage
                text={<FormattedFormErrors errors={globalMessages} />}
              />
            )}
          </div>
          {(totalGross && moneyToFloat(totalGross)) ||
          totalDiscount ||
          vatPercentage > 0 ? (
            <div className="add-funds-request-summary">
              <p className="add-funds-request-summary-title">Resumen</p>
              <div className="add-funds-request-summary-wrapper">
                <div className="add-funds-request-summary-breakdown">
                  <div className="add-funds-request-summary-breakdown-item">
                    <span>{i18n('cart:total-order')}</span>
                    <span className="amount">
                      <HackFormattedMoney value={totalGross} />
                    </span>
                  </div>
                  {totalDiscount ? (
                    <div className="add-funds-request-summary-breakdown-item with-discount">
                      <span>{i18n('checkout:discount-coupon')}</span>
                      <span className="amount">
                        -
                        <HackFormattedMoney value={totalDiscount} />
                      </span>
                    </div>
                  ) : null}
                  {vatPercentage > 0 ? (
                    <div className="add-funds-request-summary-breakdown-item">
                      <span>
                        {i18n('cart:vat')} ({vatPercentage}%)
                      </span>
                      <span className="amount">
                        <HackFormattedMoney value={totalVat} />
                      </span>
                    </div>
                  ) : null}
                </div>
                <MediaQueryLap>
                  <div className="add-funds-request-summary-decoration">
                    <picture>
                      <img src={fundsImage} alt="Decoration" />
                    </picture>
                  </div>
                </MediaQueryLap>
                <div className="add-funds-request-summary-total-amount">
                  <span>{i18n('cart:total')}</span>
                  <span className="amount">
                    <HackFormattedMoney value={total} />
                  </span>
                  <Button
                    type="submit"
                    isLoading={isRequestPending}
                    isDisabled={isValidatingCoupon}
                  >
                    {i18n('action:add-funds')}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
      <div className="modal-footer">
        <HelpMessage
          iconType="help"
          phone="+34914149191"
          formattedPhone="(+34) 914 14 91 91"
        />
      </div>
    </div>
  )
}

const FORM_VALUES_PROPTYPES = {
  newAmount: PropTypes.string,
  concept: PropTypes.string,
}

AddFundsModal.propTypes = {
  // Payment
  onSubmit: PropTypes.func,
  isRequestPending: PropTypes.bool.isRequired,

  // Card payment
  cardBrand: PropTypes.oneOf([
    'visa',
    'mastercard',
    'amex',
    'discover',
    'diners',
    'jcb',
    'unionpay',
    'unknown',
  ]),
  onCardNumberChange: PropTypes.func,
  onCardNumberBlur: PropTypes.func,
  cardNumberError: PropTypes.string,
  onCardExpirationChange: PropTypes.func,
  onCardExpirationBlur: PropTypes.func,
  cardExpirationError: PropTypes.string,
  onCardCVCChange: PropTypes.func,
  onCardCVCBlur: PropTypes.func,
  cardCVCError: PropTypes.string,

  // Discount coupon
  discountCoupon: PropTypes.string,
  onDiscountCouponChange: PropTypes.func,
  onCouponSubmit: PropTypes.func,
  isValidatingCoupon: PropTypes.bool,
  successCode: PropTypes.any,
  errorCode: PropTypes.any,

  // Order summary
  total: CoobisPropTypes.money,
  totalGross: CoobisPropTypes.money,
  totalVat: CoobisPropTypes.money,
  totalDiscount: CoobisPropTypes.money,
  vatPercentage: PropTypes.number,

  // withFormErrors
  globalMessages: globalMessagesPropType,
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),

  //withTranslations
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(AddFundsModal)
