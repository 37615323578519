import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import Paragraph from '../texts/Paragraph'
import AnchorDownload from '../texts/AnchorDownload'
import AnchorLink from '../texts/AnchorLink'
import { isNilOrEmpty, formatBytes } from '../../app/utils'

const OrderDetailPreDelivery = ({
  fileUrl,
  url,
  fileTitle,
  fileSize,
  isDisabled,
  i18n,
}) => {
  const documentTitle = `${fileTitle}`
  return (
    <OrderDetailSection title={i18n('sections:pre-delivery')}>
      {!isNilOrEmpty(fileUrl) ? (
        <Paragraph>
          <AnchorDownload
            extraText={formatBytes(fileSize)}
            linkUrl={fileUrl}
            text={fileTitle}
            title={i18n('message:download-document', { documentTitle })}
            isDisabled={isDisabled}
          />
        </Paragraph>
      ) : null}

      {!isNilOrEmpty(url) ? (
        <Paragraph title={i18n('order-detail:predelivery-link-file')}>
          <AnchorLink
            text={url}
            linkUrl={url}
            isDisabled={isDisabled}
            isNewTab
            isNative
          />
        </Paragraph>
      ) : null}
    </OrderDetailSection>
  )
}

OrderDetailPreDelivery.propTypes = {
  fileUrl: PropTypes.string,
  url: PropTypes.string,
  fileTitle: PropTypes.string,
  fileSize: PropTypes.string,
  isDisabled: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailPreDelivery)
