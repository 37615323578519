import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import {
  getRequest,
  postRequest,
  patchRequest,
  postFormRequest,
} from '../api-call'
import { i18nErrorKeys, i18nValidationKeys } from '../../app/common/validation'
import { parseResponseErrors } from '../error-handling'
import { formatBytes } from '../../app/utils'
const MEDIA_ENDPOINT = `${envConfig.usersBackendUrl}/media`
const MEDIA_BILLING_ENDPOINT = `${envConfig.usersBackendUrl}/media/billing`
const MEDIA_IDENTITY_DOCUMENT_ENDPOINT = `${envConfig.usersBackendUrl}/media/billing/identity-document`
const MEDIA_TYPE_DOCUMENT_ENDPOINT = `${envConfig.usersBackendUrl}/media/billing/type-document`
const MEDIA_RESIDENCE_DOCUMENT_ENDPOINT = `${envConfig.usersBackendUrl}/media/billing/residence-document`
const MEDIA_STATUS_ENDPOINT = `${envConfig.usersBackendUrl}/media/status`

const MAP_GLOBAL_ERRORS_FETCH_MEDIA = {
  MediaNotFoundException: values => ({
    key: i18nErrorKeys.MEDIA_NOT_FOUND,
    values,
  }),
}

export function* fetchMedia() {
  const response = yield call(getRequest, MEDIA_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_FETCH_MEDIA,
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_EDIT_MEDIA = {
  MediaNotFoundException: values => ({
    key: i18nErrorKeys.MEDIA_NOT_FOUND,
    values,
  }),
  MediaAlreadyExistsException: values => ({
    key: i18nErrorKeys.MEDIA_EXISTS,
    values,
  }),
}

export function* editMediaData(data) {
  const response = yield call(patchRequest, MEDIA_ENDPOINT, data, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_EDIT_MEDIA,
  })

  return parsedResponse
}

export const MAP_GLOBAL_UPLOAD_MEDIA_IDENTITY_DOCUMENT = {
  UploadedTemporalDocumentTooBigForUpload: () => ({
    key: i18nValidationKeys.IDENTITY_DOCUMENT_TOO_BIG_FOR_UPLOAD,
  }),
  UploadedTemporalDocumentMaxSizeException: ({
    value: { fileSize },
    expected: maxSize,
  }) => ({
    key: i18nValidationKeys.IDENTITY_DOCUMENT_EXCEED_MAX_FILE_SIZE,
    values: {
      fileSize: formatBytes(fileSize, 1),
      maxSize: formatBytes(maxSize, 1),
    },
  }),
  UploadedTemporalDocumentMimeTypeException: ({
    expected: allowedFormats,
  }) => ({
    key: i18nValidationKeys.IDENTITY_DOCUMENT_FORMAT_NOT_ALLOWED,
    values: { allowedFormats: allowedFormats.join(', ') },
  }),
}

export function* uploadIdentityDocument(identityDocument) {
  if (!identityDocument) return

  const response = yield call(
    postFormRequest,
    MEDIA_IDENTITY_DOCUMENT_ENDPOINT,
    { identityDocument },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_UPLOAD_MEDIA_IDENTITY_DOCUMENT,
    mapStatus: {
      network:
        MAP_GLOBAL_UPLOAD_MEDIA_IDENTITY_DOCUMENT.UploadedTemporalDocumentTooBigForUpload,
    },
  })

  return parsedResponse
}

export const MAP_GLOBAL_UPLOAD_MEDIA_TYPE_DOCUMENT = {
  UploadedTemporalDocumentTooBigForUpload: () => ({
    key: i18nValidationKeys.TYPE_DOCUMENT_TOO_BIG_FOR_UPLOAD,
  }),
  UploadedTemporalDocumentMaxSizeException: ({
    value: { fileSize },
    expected: maxSize,
  }) => ({
    key: i18nValidationKeys.TYPE_DOCUMENT_EXCEED_MAX_FILE_SIZE,
    values: {
      fileSize: formatBytes(fileSize, 1),
      maxSize: formatBytes(maxSize, 1),
    },
  }),
  UploadedTemporalDocumentMimeTypeException: ({
    expected: allowedFormats,
  }) => ({
    key: i18nValidationKeys.TYPE_DOCUMENT_FORMAT_NOT_ALLOWED,
    values: { allowedFormats: allowedFormats.join(', ') },
  }),
}

export function* uploadTypeDocument(typeDocument) {
  if (!typeDocument) return

  const response = yield call(
    postFormRequest,
    MEDIA_TYPE_DOCUMENT_ENDPOINT,
    { typeDocument },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_UPLOAD_MEDIA_TYPE_DOCUMENT,
    mapStatus: {
      network:
        MAP_GLOBAL_UPLOAD_MEDIA_TYPE_DOCUMENT.UploadedTemporalDocumentTooBigForUpload,
    },
  })

  return parsedResponse
}

export const MAP_GLOBAL_UPLOAD_MEDIA_RESIDENCE_DOCUMENT = {
  UploadedTemporalDocumentTooBigForUpload: () => ({
    key: i18nValidationKeys.RESIDENCE_DOCUMENT_TOO_BIG_FOR_UPLOAD,
  }),
  UploadedTemporalDocumentMaxSizeException: ({
    value: { fileSize },
    expected: maxSize,
  }) => ({
    key: i18nValidationKeys.RESIDENCE_DOCUMENT_EXCEED_MAX_FILE_SIZE,
    values: {
      fileSize: formatBytes(fileSize, 1),
      maxSize: formatBytes(maxSize, 1),
    },
  }),
  UploadedTemporalDocumentMimeTypeException: ({
    expected: allowedFormats,
  }) => ({
    key: i18nValidationKeys.RESIDENCE_DOCUMENT_FORMAT_NOT_ALLOWED,
    values: { allowedFormats: allowedFormats.join(', ') },
  }),
}

export function* uploadResidenceDocument(residenceDocument) {
  if (!residenceDocument) return

  const response = yield call(
    postFormRequest,
    MEDIA_RESIDENCE_DOCUMENT_ENDPOINT,
    { residenceDocument },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_UPLOAD_MEDIA_RESIDENCE_DOCUMENT,
    mapStatus: {
      network:
        MAP_GLOBAL_UPLOAD_MEDIA_RESIDENCE_DOCUMENT.UploadedTemporalDocumentTooBigForUpload,
    },
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_EDIT_BILLINGS_MEDIA = {
  MediaNotFoundException: values => ({
    key: i18nErrorKeys.MEDIA_NOT_FOUND,
    values,
  }),
}

const MAP_VALIDATION_ERRORS_EDIT_BILLINGS_MEDIA = {
  nif: values => ({
    key: i18nValidationKeys.INVALID_IDENTY_DOCUMENT_NUMBER,
    values,
  }),
  nie: values => ({
    key: i18nValidationKeys.INVALID_IDENTY_DOCUMENT_NUMBER,
    values,
  }),
  cif: values => ({
    key: i18nValidationKeys.INVALID_IDENTY_DOCUMENT_NUMBER,
    values,
  }),
  vatNumber: values => ({
    key: i18nValidationKeys.INVALID_IDENTY_DOCUMENT_NUMBER,
    values,
  }),
  postalCode: values => ({
    key: i18nValidationKeys.INVALID_POSTAL_CODE,
    values,
  }),
}

export function* editMediaBillingData(data) {
  const response = yield call(patchRequest, MEDIA_BILLING_ENDPOINT, data, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_EDIT_BILLINGS_MEDIA,
    mapValidation: MAP_VALIDATION_ERRORS_EDIT_BILLINGS_MEDIA,
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_REGISTER_MEDIA = {
  MediaAlreadyExistsException: values => ({
    key: i18nErrorKeys.MEDIA_EXISTS,
    values,
  }),
}

export function* registerMedia(data) {
  const response = yield call(postRequest, `${MEDIA_ENDPOINT}`, data, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_REGISTER_MEDIA,
  })

  return parsedResponse
}

export function* fetchMediaStatus() {
  const response = yield call(getRequest, MEDIA_STATUS_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_FETCH_MEDIA,
  })

  return parsedResponse
}
