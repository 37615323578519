import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../avatar/Avatar'
import previewFooterImage from './images/facebook-footer.png'

const PreviewBoxFacebook = ({ text, userName, date, avatar, previewImage }) => {
  return (
    <div className="preview-box facebook">
      <div className="preview-header">
        <Avatar image={avatar} />
        <div className="user-info">
          <p className="user-name">{userName}</p>
          <p className="date">{date}</p>
        </div>
      </div>
      <div className="preview-body">
        <p className="preview-description">{text}</p>
        <div className="preview-image-wrapper">
          <img className="preview-image" src={previewImage} alt="preview" />
        </div>
      </div>
      <div className="preview-footer">
        <img className="image" src={previewFooterImage} alt="preview" />
      </div>
    </div>
  )
}

PreviewBoxFacebook.propTypes = {
  text: PropTypes.string,
  userName: PropTypes.string,
  date: PropTypes.string,
  avatar: PropTypes.string,
  previewImage: PropTypes.string,
}

PreviewBoxFacebook.defaultProps = {
  userName: 'User name',
  date: '4 de junio a las 19:34',
}

export default PreviewBoxFacebook
