import React, { useCallback } from 'react'
import envConfig from '../../environments'
import { getToken } from '../../services/auth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ReactiveBase, DataSearch } from '@appbaseio/reactivesearch'
import { FILTERS } from './filterUtils'
import SearchContainer from './SearchContainer'
import SearchFilters from './SearchFilters'
import { transformElasticSearchResponse } from './SearchFilters'
import SearchContent from './SearchContent'
import { ReactComponent as SearchIcon } from '../../styles/icons/search.svg'
import { compose } from 'redux'
import withSpinner from '../../hocs/withSpinner'
import {
  FETCH_CATEGORIES,
  FETCH_COUNTRIES,
} from '../../services/config/action-types'
import withTranslations from '../../hocs/withTranslations'

const DEBOUNCE_MSECS =
  Number(process.env.REACT_APP_SEARCH_DEBOUNCE_MSECS) || undefined

const Search = ({ token, history, i18n }) => {
  const setSearchParams = useCallback(
    url => {
      const base = window.location.href.split('?')[0]
      history.replace(url.replace(base, '') || '?')
    },
    [history],
  )

  const customQuery = function(text) {
    return {
      query: {
        bool: {
          must: [
            {
              bool: {
                should: [
                  {
                    simple_query_string: {
                      query: text,
                      fields: ['name^5', 'description'],
                      default_operator: 'and',
                      analyze_wildcard: true,
                      analyzer: 'standard',
                    },
                  },
                  {
                    wildcard: {
                      url: {
                        value: '*' + text + '*',
                      },
                    },
                  },
                ],
                minimum_should_match: '1',
              },
            },
          ],
        },
      },
    }
  }

  return (
    <ReactiveBase
      app="coobis_resources"
      url={envConfig.searchBackendUrl}
      headers={{
        Authorization: `${token}`,
      }}
      className="search-wrapper"
      setSearchParams={setSearchParams}
      transformResponse={transformElasticSearchResponse}
      theme={{
        typography: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
        },
      }}
    >
      <SearchContainer
        actionsSlot={
          <DataSearch
            className="search-field"
            componentId={FILTERS.searchbox}
            dataField={['name^5', 'description']}
            placeholder={i18n('action:search')}
            autosuggest={false}
            URLParams={true}
            icon={<SearchIcon />}
            iconPosition="right"
            debounce={DEBOUNCE_MSECS}
            customQuery={customQuery}
          />
        }
        filtersSlot={<SearchFilters />}
      >
        <SearchContent />
      </SearchContainer>
    </ReactiveBase>
  )
}

const enhance = compose(
  withRouter,
  withTranslations,
  withSpinner(FETCH_CATEGORIES),
  withSpinner(FETCH_COUNTRIES),
  connect(state => ({ token: getToken(state) })),
)

export default enhance(Search)
