import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailGlobal from '../../ui/campaigns/views/CampaignDetailGlobal'
import { connect } from 'react-redux'
import {
  getCurrentOrdersForCampaign,
  getCancelledOrdersForCampaign,
} from '../../services/orders'
import withExportReportContents from './withExportReportContents'
import withPublicationsTable from './withPublicationsTable'
import { SERVICE_TYPES } from '../../services/orders/business'
import withOrders from './withOrders'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  totalPublications: stats.kpis.totalOrders,
  totalReach: stats.kpis.totalReach,
}))

const withCurrentOrders = compose(
  connect((state, props) => ({
    orders: getCurrentOrdersForCampaign(props.campaignId, state),
  })),
  withOrders({ inProp: 'orders' }),
)

const withCancelledOrders = compose(
  connect((state, props) => ({
    cancelledOrders: getCancelledOrdersForCampaign(props.campaignId, state),
  })),
)

const getPublicationstableColumns = orderStatsDetail => {
  if (!orderStatsDetail.kpis) {
    return {}
  }

  switch (orderStatsDetail.serviceType) {
    case SERVICE_TYPES.WEB_ONLY_POST:
    case SERVICE_TYPES.WEB_POST: {
      return {
        visits: orderStatsDetail.kpis.visits,
        engagements: orderStatsDetail.kpis.engagements,
      }
    }

    case SERVICE_TYPES.FACEBOOK:
    case SERVICE_TYPES.INSTAGRAM_PHOTO:
    case SERVICE_TYPES.INSTAGRAM_VIDEO:
    case SERVICE_TYPES.INSTAGRAM_STORY: {
      return {
        engagements: orderStatsDetail.kpis.engagements,
        impressions: orderStatsDetail.kpis.reach,
        likes: orderStatsDetail.kpis.likes,
      }
    }

    case SERVICE_TYPES.TWITTER: {
      return {
        engagements: orderStatsDetail.kpis.engagements,
        impressions: orderStatsDetail.kpis.impressions,
        likes: orderStatsDetail.kpis.likes,
      }
    }

    case SERVICE_TYPES.YOUTUBE_MENTION:
    case SERVICE_TYPES.YOUTUBE_DEDICATED: {
      return {
        engagements: orderStatsDetail.kpis.engagements,
        impressions: orderStatsDetail.kpis.views,
        likes: orderStatsDetail.kpis.likes,
      }
    }

    case SERVICE_TYPES.TWITCH: {
      return {
        engagements: orderStatsDetail.kpis.averageViewers,
        impressions: orderStatsDetail.kpis.views,
      }
    }

    case SERVICE_TYPES.TIKTOK_SHORT:
    case SERVICE_TYPES.TIKTOK_LONG: {
      return {
        impressions: orderStatsDetail.kpis.views,
        engagements: orderStatsDetail.kpis.engagements,
        likes: orderStatsDetail.kpis.followers,
      }
    }

    default: {
      throw new Error(`Unknown serviceType ${orderStatsDetail.serviceType}`)
    }
  }
}

const enhance = compose(
  withCurrentOrders,
  withCancelledOrders,
  withKPIs,
  withPublicationsTable(getPublicationstableColumns),
  withExportReportContents(),
)

export default enhance(CampaignDetailGlobal)
