import { ReactComponent as IconTour1 } from '../../styles/icons/tour-card1.svg'
import { ReactComponent as IconTour2 } from '../../styles/icons/tour-card2.svg'
import { ReactComponent as IconTour3 } from '../../styles/icons/tour-card3.svg'
import { ReactComponent as IconTour4 } from '../../styles/icons/tour-card4.svg'
import { ReactComponent as IconTour5 } from '../../styles/icons/tour-card5.svg'
import React from 'react'

export const getBrandSteps = i18n => {
  return [
    {
      target: '.main-navigation-item[data-i18nKey="noun:dashboard"]',
      content: {
        title: i18n('tour:dashboard-title'),
        texts: [i18n('tour:dashboard-text1'), i18n('tour:dashboard-text2')],
        icon: <IconTour1 />,
      },
    },
    {
      target: '.main-navigation-item[data-i18nKey="noun:media-influencers"]',
      content: {
        title: i18n('tour:media-title'),
        texts: [i18n('tour:media-text')],
        icon: <IconTour2 />,
      },
    },
    {
      target: '.main-navigation-item[data-i18nKey="noun:campaigns"]',
      content: {
        title: i18n('tour:campaigns-title'),
        titleIcon: i18n('noun:new'),
        texts: [i18n('tour:campaigns-text')],
        icon: <IconTour3 />,
      },
    },
    {
      target: '.global-header-funds button',
      content: {
        title: i18n('tour:recharge-title'),
        texts: [i18n('tour:recharge-text')],
        icon: <IconTour4 />,
      },
    },
    {
      target: '.user-dropdown-button .avatar',
      content: {
        title: i18n('tour:profile-title'),
        texts: [i18n('tour:profile-text1'), i18n('tour:profile-text2')],
        icon: <IconTour5 />,
      },
    },
  ]
}

export const getMediaSteps = i18n => {
  return [
    {
      target: '.main-navigation-item[data-i18nKey="noun:orders"]',
      content: {
        title: i18n('tour:orders-title'),
        texts: [i18n('tour:orders-text')],
        icon: <IconTour3 />,
      },
    },
    {
      target: '.main-navigation-item[data-i18nKey="noun:resources"]',
      content: {
        title: i18n('tour:resources-title'),
        texts: [i18n('tour:resources-text')],
        icon: <IconTour1 />,
      },
    },
    {
      target: '.global-header-funds button',
      content: {
        title: i18n('tour:withdrawal-title'),
        texts: [i18n('tour:withdrawal-text')],
        icon: <IconTour4 />,
      },
    },
    {
      target: '.user-dropdown-button .avatar',
      content: {
        title: i18n('tour:profile-title'),
        texts: [i18n('tour:profile-text1'), i18n('tour:profile-text2')],
        icon: <IconTour5 />,
      },
    },
  ]
}
