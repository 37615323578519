import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { compose } from 'recompose'
import * as Yup from 'yup'
import { i18nValidation } from '../common/validation'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { connect } from 'react-redux'
import { editBrandData } from '../../services/brand'
import { EDIT_BRAND_DATA } from '../../services/brand/action-types'
import withRequestError from '../../hocs/withRequestError'
import BrandDataForm from '../../ui/profile/BrandDataForm'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withLanguageSelectField, {
  LANGUAGES_WITH_LOCALE,
} from '../../hocs/withLanguageSelectField'
import withCleanFormSuccess from '../../hocs/withCleanFormSuccess'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18nValidation.required),
  surname: Yup.string().required(i18nValidation.required),
  email: Yup.string()
    .email(i18nValidation.invalidMail)
    .required(i18nValidation.required),
  phone: Yup.string().required(i18nValidation.required),
  website: Yup.string()
    .url(i18nValidation.invalidURL)
    .required(i18nValidation.required),
  language: Yup.mixed()
    .oneOf(LANGUAGES_WITH_LOCALE, i18nValidation.required)
    .required(i18nValidation.required),
})

const enhance = compose(
  connect(
    null,
    { editBrandData },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ brandInfo }) => ({
      name: brandInfo.name || '',
      surname: brandInfo.surname || '',
      email: brandInfo.email || '',
      website: brandInfo.website || '',
      phone: brandInfo.phone || '',
      language: brandInfo.language,
    }),

    selectFields: ['language'],

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.editBrandData(values)
    },
  }),

  withIsRequestPending(EDIT_BRAND_DATA),
  withIsRequestSucceeded(EDIT_BRAND_DATA),
  withRequestError(EDIT_BRAND_DATA),
  withDeleteRequestOnUnmount(EDIT_BRAND_DATA),
  withFormErrors(['name', 'surname', 'email', 'website', 'phone', 'language']),

  withLanguageSelectField(LANGUAGES_WITH_LOCALE),
  withCleanFormSuccess(),
)

export default enhance(BrandDataForm)
