import * as R from 'ramda'
import { compose, withPropsOnChange } from 'recompose'

const withTimeSeriesGraph = (
  seriesIteratorCreator,
  dataPointFn,
  { dataOutProp = 'data', dependentPropNames = [] },
) =>
  compose(
    withPropsOnChange(
      ['stats', ...dependentPropNames],
      ({ stats, ...props }) => ({
        [dataOutProp]: seriesIteratorCreator(stats).map(seriesContainer =>
          dataPointFn(seriesContainer, {
            stats,
            ...R.pick(dependentPropNames, props),
          }),
        ),
      }),
    ),
  )

export default withTimeSeriesGraph
