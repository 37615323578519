import { getFromPath } from 'reduken/hash'
import { DOMAIN, HASH_KEY_MEDIA_INFO, HASH_KEY_MEDIA_STATUS } from './constants'
import { createSelector } from 'reselect'

export const getMediaInfo = getFromPath(DOMAIN, HASH_KEY_MEDIA_INFO)
export const getMediaStatus = getFromPath(DOMAIN, HASH_KEY_MEDIA_STATUS)
export const getMediaCurrency = createSelector(
  getMediaInfo,
  mediaInfo => mediaInfo.billing.currency,
)
