import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import ResourceDetailSection from './ResourceDetailSection'
import ResourceDetailServiceWeb from './ResourceDetailServiceWeb'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'

const ResourceDetailServicesWeb = ({ item, i18n, cart, addItem, ...props }) => {
  const servicesResource = props.services.reduce(
    (inService, { service, title, description }) => {
      const isInService = inService.filter(
        element => element.service === service,
      )
      if (isInService.length === 0)
        inService.push({
          service: service,
          title: title,
          description: description,
        })
      return inService
    },
    [],
  )
  return (
    <ResourceDetailSection titleText={i18n('sections:services')}>
      <div className="resource-detail-service-list">
        {servicesResource.map((service, i) => {
          return (
            <ResourceDetailServiceWeb
              key={i}
              service={service}
              i={i}
              item={item}
              addItem={addItem}
              {...props}
            />
          )
        })}
      </div>
    </ResourceDetailSection>
  )
}

ResourceDetailServicesWeb.propTypes = {
  item: PropTypes.object,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      price: CoobisPropTypes.price,
      finalPrice: CoobisPropTypes.price,
    }),
  ),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceDetailServicesWeb)
