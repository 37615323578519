import * as actionTypes from './action-types'

export const fetchUnreadNotifications = () => ({
  type: actionTypes.FETCH_UNREAD_NOTIFICATIONS,
})

export const periodicFetchUnreadNotifications = () => ({
  type: actionTypes.PERIODIC_FETCH_UNREAD_NOTIFICATIONS,
})

export const readNotification = notification => ({
  type: actionTypes.READ_NOTIFICATION,
  payload: { notification },
})

export const readAllNotifications = () => ({
  type: actionTypes.READ_ALL_NOTIFICATIONS,
})
