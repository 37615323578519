import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import RatingButton from './RatingButton'

const InteractiveRating = ({ value, isDisabled, onRatingClick }) => {
  const [hoverIndex, setHoverIndex] = useState(null)

  const classes = cx('rating', {
    'is-disabled': isDisabled,
  })

  return (
    <div className={classes}>
      <div className="rating-stars" onMouseLeave={() => setHoverIndex(null)}>
        {[...Array(5)].map((_, i) => (
          <RatingButton
            isDisabled={isDisabled}
            isSelected={(isDisabled || hoverIndex === null) && value >= i + 1}
            isHovered={!isDisabled && hoverIndex >= i + 1}
            onClick={() => onRatingClick(i + 1)}
            onMouseEnter={() => setHoverIndex(i + 1)}
          />
        ))}
      </div>
    </div>
  )
}

InteractiveRating.propTypes = {
  value: PropTypes.number,
  isDisabled: PropTypes.bool,
  onRatingClick: PropTypes.func,
}

export default InteractiveRating
