import React from 'react'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
  WEB_RESOURCE_SECTIONS,
  SOCIAL_SERVICES,
} from '../../services/resources/constants'
import { editWebResourceAnalytics } from '../../services/resources'
import WebResourcesGoogleAnalyticsForm from '../../ui/web-resources/WebResourcesGoogleAnalytics'
import WebResources from '../../ui/web-resources/WebResources'
import withTranslations from '../../hocs/withTranslations'
import { EDIT_WEB_RESOURCE_ANALYTICS } from '../../services/resources/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation } from '../common/validation'
import withFormErrors from '../../hocs/withFormErrors'
import withValidationMessagePromotedToGlobalMessage from './withValidationMessagePromotedToGlobalMessage'
import withRequestError from '../../hocs/withRequestError'
import { webResourcesAnalyticsSchema } from '../../services/resources/validation'
import { getWebResourcesAnalyticsData } from '../../services/resources/business'
import { withReachedWebResourceStep } from '../../services/resources/hocs'
import withWebResource from './withWebResource'
import { isNilOrEmpty } from '../utils'
import withSocialConnect from './withSocialConnect'
import withTemporaryResourceId from './withTemporaryResourceId'
import withProfileSelectModal from './withProfileSelectModal'
import queryString from 'query-string'

const WebResourcesAnalytics = ({ i18n, ...props }) => (
  <WebResources
    title={i18n('sections:google-analytics-long')}
    currentNavItem={WEB_RESOURCE_SECTIONS.ANALYTICS}
    reachedNavItem={props.reachedNavItem}
    isEditing={props.isEditing}
    resourceId={props.resourceId}
  >
    <WebResourcesGoogleAnalyticsForm {...props} />
  </WebResources>
)

const withProfileSelectValues = compose(
  withPropsOnChange(
    ['isSelectingProfile', 'values'],
    ({ isSelectingProfile, values }) => ({
      hasJustSynchronized:
        isSelectingProfile && !isNilOrEmpty(values.profileId),
    }),
  ),

  withHandlers({
    onProfileSelect: props => profileId => {
      const profileData = props.socialProfiles.find(
        profile => profile.profileId === profileId,
      )

      if (!profileData) {
        throw new Error('Could not find selected profile')
      }

      props.setFieldValue('profileId', profileId, true)

      props.setFieldValue(
        'pageviews',
        profileData['ga:pageviews']
          ? Number(profileData['ga:pageviews'])
          : undefined,
        true,
      )
      props.setFieldTouched('pageviews', true)

      props.setFieldValue(
        'visits',
        profileData['ga:visits'] ? Number(profileData['ga:visits']) : undefined,
        true,
      )
      props.setFieldTouched('visits', true)

      props.setFieldValue(
        'visitors',
        profileData['ga:visitors']
          ? Number(profileData['ga:visitors'])
          : undefined,
        true,
      )
      props.setFieldTouched('visitors', true)

      props.onSynchronizeModalClose()
    },

    // When manually changing data (if VIP) remove the selected profile
    handleChange: props => (...args) => {
      props.setFieldValue('profileId', null, true)
      props.handleChange(...args)
    },
  }),

  // Calculate infoItems
  withPropsOnChange(
    ['values', 'socialProfiles', 'i18n'],
    ({ values, socialProfiles, i18n }) => {
      if (!values.profileId || !socialProfiles) {
        return { infoItems: [] }
      }

      const profileData = socialProfiles.find(
        profile => profile.profileId === values.profileId,
      )

      if (!profileData) {
        return { infoItems: [] }
      }

      return {
        infoItems: [
          {
            label: i18n('noun:profile'),
            value: profileData.profileName,
          },
        ],
      }
    },
  ),
)

const withAnalyticsForm = compose(
  connect(
    null,
    {
      editWebResourceAnalytics,
    },
  ),

  withIsRequestPending(EDIT_WEB_RESOURCE_ANALYTICS),
  withRequestError(EDIT_WEB_RESOURCE_ANALYTICS),
  withIsRequestSucceeded(EDIT_WEB_RESOURCE_ANALYTICS),
  withDeleteRequestOnUnmount(EDIT_WEB_RESOURCE_ANALYTICS),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: getWebResourcesAnalyticsData,

    validationSchema: webResourcesAnalyticsSchema,

    validateOnBlur: false,
    validateOnChange: false,

    handleSubmit: (values, { props }) => {
      const valuesToSend = props.isVipAnalytics
        ? R.pipe(
            R.pick(['pageviews', 'visits', 'visitors']),
            R.mapObjIndexed((value, key) => Number(value) || value),
          )(values)
        : {
            temporaryResourceId: props.lastTemporaryResourceId,
            profileId: values.profileId,
          }
      props.editWebResourceAnalytics(props.resourceId, valuesToSend)
    },
  }),

  withFormErrors(['profileId', 'pageviews', 'visits', 'visitors'], {
    formikValidateOnSubmit: true,
  }),

  withProfileSelectValues,
  withValidationMessagePromotedToGlobalMessage(
    'profileId',
    'errors:required',
    i18nValidation.requireGoogleAnalyticsProfile,
  ),
)

const enhance = compose(
  withTranslations,

  withWebResource(WEB_RESOURCE_SECTIONS.ANALYTICS),

  withPropsOnChange(['resourceData'], ({ resourceData }) => ({
    isVipAnalytics: resourceData.isVipAnalytics,
  })),

  withTemporaryResourceId,

  withReachedWebResourceStep(WEB_RESOURCE_SECTIONS.ANALYTICS),

  // Analytics connection
  withSocialConnect(),
  withPropsOnChange(
    ['requestSocialConnect'],
    ({ requestSocialConnect, ...props }) => ({
      onSynchronizeClick: () => {
        const query = queryString.parse(props.location.search)
        delete query.temporaryResourceId
        delete query.hasJustConnected
        const queryWithoutTemporaryResourceId = queryString.stringify(query)

        props.history.replace({
          ...props.location,
          search: queryWithoutTemporaryResourceId,
        })
        requestSocialConnect()
      },
      //onSynchronizeClick: requestSocialConnect,
    }),
  ),
  withProfileSelectModal(SOCIAL_SERVICES.GOOGLE),
  withPropsOnChange(
    ['isRequestingSocialConnect', 'isRequestingSocialProfiles'],
    ({ isRequestingSocialConnect, isRequestingSocialProfiles }) => ({
      isSynchronizeRequestPending:
        isRequestingSocialConnect || isRequestingSocialProfiles,
    }),
  ),
  withPropsOnChange(['socialProfiles'], ({ socialProfiles }) => ({
    tableData: !!socialProfiles
      ? socialProfiles.map(profile => ({
          id: profile.profileId,
          profile: profile.profileName,
          url: profile.profileWebsiteUrl,
          visits: profile['ga:visits']
            ? Number(profile['ga:visits'])
            : undefined,
          users: profile['ga:visitors']
            ? Number(profile['ga:visitors'])
            : undefined,
          webs: profile['ga:pageviews']
            ? Number(profile['ga:pageviews'])
            : undefined,
        }))
      : [],
  })),

  withAnalyticsForm,
)

export default enhance(WebResourcesAnalytics)
