import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'
import withTranslations from '../../../hocs/withTranslations'
import { isNilOrEmpty } from '../../../app/utils'
import { ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID } from '../../../app/common/routes'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import CampaignDetailPanel from '../CampaignDetailPanel'
import Card from '../../cards/Card'
import CancelledOrdersHeadRow from '../tables/CancelledOrdersHeadRow'
import CancelledOrdersRow from '../tables/CancelledOrdersRow'
import PublicationsTableGlobal from '../tables/PublicationsTableGlobal'
import CampaignsPendingOrdersPanel from '../CampaignsPendingOrdersPanel'
import EmptyResult from '../../shared/EmptyResult'
import useScrollEnds from '../../../hooks/useScrollEnds'
import ImageEmptySmall from '../images/emptyResultSmall.png'
import ImageEmptyBig from '../images/emptyResultBig.png'
import LoadingCard from '../../cards/LoadingCard'

const CampaignDetailGlobal = ({
  campaignId,
  publicationsTableData,
  hasHiddenStats,
  i18n,
  // Metrics values
  totalPublications,
  totalReach,
  // Table data
  orders,
  hadOrders,
  onOrderSearchChange,
  orderSearchValue,
  onOrdersSortChange,
  ordersSortValue,
  onOrderActionClick,
  cancelledOrders,
  isLoadingCurrentOrders,
  isLoadingCancelledOrders,
}) => {
  const { handleScroll, setScrollerRef, scrollEnds } = useScrollEnds({
    top: 20,
    bottom: 20,
  })

  const cancelledOrdersTableClasses = cx('orders-cancelled-table', {
    'has-top-gradient': !scrollEnds.top,
    'has-bottom-gradient': !scrollEnds.bottom,
  })

  return (
    <>
      {!hasHiddenStats && (
        <div className="analytics-kpi-group">
          <AnalyticsKpi
            iconType="megaphone"
            value={totalPublications}
            label={i18n('metrics:total-publications')}
            isTruncated
          />
          {totalReach > 0 ? (
            <AnalyticsKpi
              iconType="heart"
              value={totalReach}
              label={i18n('metrics:total-reach')}
              isTruncated
            />
          ) : null}
        </div>
      )}

      {!hasHiddenStats && (
        <CampaignDetailPanel title={i18n('noun:publications-table')} isGlobal>
          <PublicationsTableGlobal
            publications={publicationsTableData}
          ></PublicationsTableGlobal>
        </CampaignDetailPanel>
      )}

      <CampaignsPendingOrdersPanel
        title={i18n('noun:ongoing-orders')}
        orders={orders}
        hadOrders={hadOrders}
        searchValue={orderSearchValue}
        onSearchChange={onOrderSearchChange}
        sortValue={ordersSortValue}
        onSortChange={onOrdersSortChange}
        onActionClick={onOrderActionClick}
        isLoading={isLoadingCurrentOrders}
        className="campaign-detail"
        isGlobal="true"
      />

      <CampaignDetailPanel
        title={i18n('noun:rejected-cancelled-orders')}
        isGlobal
      >
        {!isLoadingCancelledOrders || !isNilOrEmpty(cancelledOrders) ? (
          <Card isContained={!isNilOrEmpty(cancelledOrders)}>
            {!isNilOrEmpty(cancelledOrders) ? (
              <div className="orders-card">
                <div
                  className={cancelledOrdersTableClasses}
                  ref={setScrollerRef}
                  onScroll={handleScroll}
                >
                  <CancelledOrdersHeadRow />
                  <div className="orders-cancelled-table-content">
                    {cancelledOrders.map(order => (
                      <CancelledOrdersRow
                        key={order.id}
                        status={order.status}
                        type={order.service}
                        resource={order.resource.name}
                        price={order.price}
                        date={order.createdAt}
                        linkUrl={ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.linkTo({
                          orderId: order.id,
                          campaignId,
                        })}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <EmptyResult
                imageSmall={ImageEmptySmall}
                imageBig={ImageEmptyBig}
                subtitle={i18n('message:no-results-subtitle-simple')}
                title={i18n('message:no-results-title')}
              />
            )}
          </Card>
        ) : (
          <LoadingCard />
        )}
      </CampaignDetailPanel>
    </>
  )
}

CampaignDetailGlobal.propTypes = {
  campaignId: PropTypes.number,
  publicationsTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      resourceType: PropTypes.string,
      name: PropTypes.string,
      visits: PropTypes.number,
      engagements: PropTypes.number,
      impressions: PropTypes.number,
      likes: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  hasHiddenStats: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
  // Metrics values
  totalPublications: PropTypes.number,
  totalReach: PropTypes.number,
  // Orders Table
  orders: PropTypes.arrayOf(CoobisPropTypes.orderRow),
  onOrderSearchChange: PropTypes.func,
  orderSearchValue: PropTypes.string,
  hadOrders: PropTypes.bool,
  onOrdersSortChange: PropTypes.func,
  ordersSortValue: PropTypes.string,
  onOrderActionClick: PropTypes.func,
  // Cancelled Orders Table
  cancelledOrders: PropTypes.arrayOf(CoobisPropTypes.closedOrderRow),
}

export default withTranslations(CampaignDetailGlobal)
