import React from 'react'
import MediaQuery from 'react-responsive'
import {
  LapStart,
  DeskStart,
  DeskWideStart,
  PalmEnd,
  LapEnd,
  DeskEnd,
} from '../constants'

// Lower than 700px
export const MediaQueryPalm = props => (
  <MediaQuery maxWidth={PalmEnd}>{props.children}</MediaQuery>
)

// Lower than 970px
export const MediaQueryPortable = props => (
  <MediaQuery maxWidth={LapEnd}>{props.children}</MediaQuery>
)

// From 700px
export const MediaQueryLap = props => (
  <MediaQuery minWidth={LapStart}>{props.children}</MediaQuery>
)

// From 970px
export const MediaQueryDesk = props => (
  <MediaQuery minWidth={DeskStart}>{props.children}</MediaQuery>
)

// From 1200px
export const MediaQueryDeskWide = props => (
  <MediaQuery minWidth={DeskWideStart}>{props.children}</MediaQuery>
)

// From 700px to 970px
export const MediaQueryLapOnly = props => (
  <MediaQuery minWidth={LapStart} maxWidth={LapEnd}>
    {props.children}
  </MediaQuery>
)

// From 970px to 1200px
export const MediaQueryDeskOnly = props => (
  <MediaQuery minWidth={DeskStart} maxWidth={DeskEnd}>
    {props.children}
  </MediaQuery>
)
