import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@redradix/components.input'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import withTranslations from '../../hocs/withTranslations'
import InfoBlock from '../blocks/InfoBlock'
import image from './images/google-analytics.png'
import Modal from '../../app/common/Modal'
import TableGoogleSynchronizeData from '../tables/TableGoogleSynchronizeData'
import SuccessMessage from '../messages/SuccessMessage'
import InfoPiece from '../texts/InfoPiece'
import Panel from '../panels/Panel'
import FormFieldRow from '../form-elements/FormFieldRow'
import WarningMessage from '../messages/WarningMessage'

const WebResourcesGoogleAnalytics = ({
  handleChange,
  handleBlur,
  touched,
  values,
  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
  isVipAnalytics,
  infoItems,
  handleSubmit,

  hasJustSynchronized,
  isSynchronizeRequestPending,
  onSynchronizeClick,

  isSynchronizeModalOpen,
  onSynchronizeModalClose,
  tableData,
  onProfileSelect,
}) => {
  return (
    <form className="google-analytics" onSubmit={handleSubmit}>
      <div className="image">
        <img src={image} alt="Google Analytics" />
      </div>
      <div className="content">
        <InfoBlock title={i18n('web-resources:link-google-analytics')}>
          <p className="info-block-description">
            {i18n('web-resources:link-google-analytics-description1')}
          </p>
          <p className="info-block-description">
            {i18n('web-resources:link-google-analytics-description2')}
          </p>
        </InfoBlock>
        <div className="button-group">
          <Button
            isLoading={isSynchronizeRequestPending}
            isDisabled={isRequestPending}
            hierarchy="secondary"
            onClick={onSynchronizeClick}
          >
            {i18n('action:synchronize-data')}
          </Button>
          {isSynchronizeModalOpen && (
            <Modal onClose={onSynchronizeModalClose}>
              <TableGoogleSynchronizeData
                tableData={tableData}
                onSelect={onProfileSelect}
              />
            </Modal>
          )}
        </div>
        {hasJustSynchronized && (
          <SuccessMessage iconType="success">
            <p className="small-text">
              {i18n('action:synchronization-success')}
            </p>
            {infoItems.map(item => (
              <InfoPiece
                inline
                label={`${item.label}:`}
                value={item.value}
                key={item.label}
              />
            ))}
          </SuccessMessage>
        )}
        <Panel dark>
          <FormFieldRow>
            <Input
              type="text"
              name="visits"
              label={i18n('label:visits-month')}
              widthBehaviour="full"
              min={0}
              step={1}
              value={values.visits !== undefined ? values.visits : '?'}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={isVipAnalytics ? true : touched.visits}
              isDisabled={
                !isVipAnalytics ||
                isRequestPending ||
                isSynchronizeRequestPending
              }
              errorText={
                validationMessages.visits && (
                  <FormattedFormErrors errors={validationMessages.visits} />
                )
              }
            />
            <Input
              type="text"
              name="pageviews"
              label={i18n('label:viewed-webs-month')}
              widthBehaviour="full"
              min={0}
              step={1}
              value={values.pageviews !== undefined ? values.pageviews : '?'}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={isVipAnalytics ? true : touched.pageviews}
              isDisabled={
                !isVipAnalytics ||
                isRequestPending ||
                isSynchronizeRequestPending
              }
              errorText={
                validationMessages.pageviews && (
                  <FormattedFormErrors errors={validationMessages.pageviews} />
                )
              }
            />
          </FormFieldRow>
          <FormFieldRow>
            <Input
              type="text"
              name="visitors"
              label={i18n('label:unique-users-month')}
              widthBehaviour="full"
              min={0}
              step={1}
              value={values.visitors !== undefined ? values.visitors : '?'}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={isVipAnalytics ? true : touched.visitors}
              isDisabled={
                !isVipAnalytics ||
                isRequestPending ||
                isSynchronizeRequestPending
              }
              errorText={
                validationMessages.visitors && (
                  <FormattedFormErrors errors={validationMessages.visitors} />
                )
              }
            />
          </FormFieldRow>
        </Panel>
        {globalMessages && (
          <WarningMessage
            text={<FormattedFormErrors errors={globalMessages} />}
          />
        )}
        <div className="actions">
          <Button
            type="submit"
            isLoading={isRequestPending}
            isDisabled={isSynchronizeRequestPending}
          >
            {i18n('action:continue')}
          </Button>
        </div>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  visits: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number]),
  pageviews: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number]),
  visitors: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number]),
}

WebResourcesGoogleAnalytics.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  isVipAnalytics: PropTypes.bool.isRequired,
  infoItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),

  hasJustSynchronized: PropTypes.bool.isRequired,
  isSynchronizeRequestPending: PropTypes.bool.isRequired,
  onSynchronizeClick: PropTypes.func.isRequired,

  isSynchronizeModalOpen: PropTypes.bool.isRequired,
  onSynchronizeModalClose: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(CoobisPropTypes.googleAnalyticsProfile),
  onProfileSelect: PropTypes.func.isRequired,

  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(WebResourcesGoogleAnalytics)
