import React from 'react'
import { compose, withProps, branch, renderComponent } from 'recompose'
import * as R from 'ramda'
import Spinner from '../ui/loaders/Spinner'
import withIsLoading from './withIsLoading'
import withTranslations from './withTranslations'

const OUT_PROP = 'withSpinner/isLoading'

const withSpinner = (
  requestType,
  { i18nKey = 'status:loading', enhanceSpinner = x => x } = {},
) => {
  const EnhancedSpinner = enhanceSpinner(({ i18n }) => (
    <Spinner text={i18n(i18nKey)} />
  ))

  return compose(
    withTranslations,
    withIsLoading(requestType, OUT_PROP),
    branch(ownProps => ownProps[OUT_PROP], renderComponent(EnhancedSpinner)),
    withProps(R.dissoc(OUT_PROP)),
  )
}

export default withSpinner
