import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { getOrderHistoryById, fetchOrderHistory } from '../../services/orders'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'
import { FETCH_ORDER_HISTORY } from '../../services/orders/action-types'
import { isNilOrEmpty } from '../utils'
import * as R from 'ramda'
import moment from 'moment'
import { AuthScope } from '../../services/auth/business'
import { HISTORY_ACTIONS } from '../../services/orders/business'
import withIsRequestWithIdSucceeded from '../../hocs/withIsRequestWithIdSucceeded'

const HISTORY_TAB_INDEX = 1

const HISTORY_ACTION_TO_I18N_KEY = {
  [AuthScope.BRAND]: {
    [HISTORY_ACTIONS.REQUEST]: 'order-detail-history-brand:request',

    [HISTORY_ACTIONS.REQUEST_BRIEFING]:
      'order-detail-history-brand:request-briefing',
    [HISTORY_ACTIONS.ACCEPT_BRIEFING]:
      'order-detail-history-brand:accept-briefing',
    [HISTORY_ACTIONS.REJECT_BRIEFING]:
      'order-detail-history-brand:reject-briefing',

    [HISTORY_ACTIONS.ACCEPT]: 'order-detail-history-brand:accept',
    [HISTORY_ACTIONS.REJECT]: 'order-detail-history-brand:reject',
    [HISTORY_ACTIONS.CANCEL]: 'order-detail-history-brand:cancel',

    [HISTORY_ACTIONS.SEND_REVIEW]: 'order-detail-history-brand:send-review',
    [HISTORY_ACTIONS.REJECT_REVIEW]: 'order-detail-history-brand:reject-review',
    [HISTORY_ACTIONS.FIX_REVIEW]: 'order-detail-history-brand:fix-review',
    [HISTORY_ACTIONS.ACCEPT_REVIEW]: 'order-detail-history-brand:accept-review',

    [HISTORY_ACTIONS.SEND_DELIVERY]: 'order-detail-history-brand:send-delivery',
    [HISTORY_ACTIONS.REJECT_DELIVERY]:
      'order-detail-history-brand:reject-delivery',
    [HISTORY_ACTIONS.FIX_DELIVERY]: 'order-detail-history-brand:fix-delivery',
    [HISTORY_ACTIONS.APPROVE_DELIVERY]:
      'order-detail-history-brand:approve-delivery',
    [HISTORY_ACTIONS.REACTIVATE_TO_REQUEST]:
      'order-detail-history-brand:reactivate',
    [HISTORY_ACTIONS.REACTIVATE_TO_ACCEPT]:
      'order-detail-history-brand:reactivate',
    [HISTORY_ACTIONS.REACTIVATE_TO_PENDING]:
      'order-detail-history-brand:reactivate',
    [HISTORY_ACTIONS.REACTIVATE_TO_REVIEW]:
      'order-detail-history-brand:reactivate',
  },
  [AuthScope.MEDIA]: {
    [HISTORY_ACTIONS.REQUEST]: 'order-detail-history-media:request',

    [HISTORY_ACTIONS.REQUEST_BRIEFING]:
      'order-detail-history-media:request-briefing',
    [HISTORY_ACTIONS.ACCEPT_BRIEFING]:
      'order-detail-history-media:accept-briefing',
    [HISTORY_ACTIONS.REJECT_BRIEFING]:
      'order-detail-history-media:reject-briefing',

    [HISTORY_ACTIONS.ACCEPT]: 'order-detail-history-media:accept',
    [HISTORY_ACTIONS.REJECT]: 'order-detail-history-media:reject',
    [HISTORY_ACTIONS.CANCEL]: 'order-detail-history-media:cancel',

    [HISTORY_ACTIONS.SEND_REVIEW]: 'order-detail-history-media:send-review',
    [HISTORY_ACTIONS.REJECT_REVIEW]: 'order-detail-history-media:reject-review',
    [HISTORY_ACTIONS.FIX_REVIEW]: 'order-detail-history-media:fix-review',
    [HISTORY_ACTIONS.ACCEPT_REVIEW]: 'order-detail-history-media:accept-review',

    [HISTORY_ACTIONS.SEND_DELIVERY]: 'order-detail-history-media:send-delivery',
    [HISTORY_ACTIONS.REJECT_DELIVERY]:
      'order-detail-history-media:reject-delivery',
    [HISTORY_ACTIONS.FIX_DELIVERY]: 'order-detail-history-media:fix-delivery',
    [HISTORY_ACTIONS.APPROVE_DELIVERY]:
      'order-detail-history-media:approve-delivery',
    [HISTORY_ACTIONS.REACTIVATE_TO_REQUEST]:
      'order-detail-history-media:reactivate',
    [HISTORY_ACTIONS.REACTIVATE_TO_ACCEPT]:
      'order-detail-history-media:reactivate',
    [HISTORY_ACTIONS.REACTIVATE_TO_PENDING]:
      'order-detail-history-media:reactivate',
    [HISTORY_ACTIONS.REACTIVATE_TO_REVIEW]:
      'order-detail-history-media:reactivate',
  },
}

const HISTORY_ACTION_TO_ACTION_USER = {
  [HISTORY_ACTIONS.REQUEST]: AuthScope.BRAND,

  [HISTORY_ACTIONS.REQUEST_BRIEFING]: AuthScope.MEDIA,
  [HISTORY_ACTIONS.ACCEPT_BRIEFING]: AuthScope.BRAND,
  [HISTORY_ACTIONS.REJECT_BRIEFING]: AuthScope.BRAND,

  [HISTORY_ACTIONS.ACCEPT]: AuthScope.MEDIA,
  [HISTORY_ACTIONS.REJECT]: AuthScope.MEDIA,
  [HISTORY_ACTIONS.CANCEL]: AuthScope.BRAND,

  [HISTORY_ACTIONS.SEND_REVIEW]: AuthScope.MEDIA,
  [HISTORY_ACTIONS.REJECT_REVIEW]: AuthScope.BRAND,
  [HISTORY_ACTIONS.FIX_REVIEW]: AuthScope.MEDIA,
  [HISTORY_ACTIONS.ACCEPT_REVIEW]: AuthScope.BRAND,

  [HISTORY_ACTIONS.SEND_DELIVERY]: AuthScope.MEDIA,
  [HISTORY_ACTIONS.REJECT_DELIVERY]: AuthScope.BRAND,
  [HISTORY_ACTIONS.FIX_DELIVERY]: AuthScope.MEDIA,
  [HISTORY_ACTIONS.APPROVE_DELIVERY]: AuthScope.BRAND,
}

const withOrderDeliveryHistory = compose(
  connect(
    (state, { orderId, scope }) => {
      const orderHistory = getOrderHistoryById(orderId, state)
      if (isNilOrEmpty(orderHistory)) return []

      const parsedOrderHistory = orderHistory.map(entry => {
        let username =
          scope === AuthScope.MEDIA ? entry.brandName : entry.mediaName

        const actionUser = HISTORY_ACTION_TO_ACTION_USER[entry.request.action]
        let photoURL =
          actionUser === AuthScope.MEDIA ? entry.mediaPhoto : entry.brandPhoto

        if (entry.request.userId === '1-system') {
          username = entry.userName
          photoURL = entry.userPhoto
        }

        return {
          id: entry.id,
          username,
          photoURL,
          date: moment(entry.createdAt),
          actionText: HISTORY_ACTION_TO_I18N_KEY[scope][entry.request.action],
          messageSlot: R.path(['request', 'payload', 'comments'], entry),
        }
      })

      return {
        orderHistory: parsedOrderHistory,
      }
    },
    { fetchOrderHistory },
  ),

  // Fetch when each time the user enters in history tab
  lifecycle({
    componentDidMount() {
      const { selectedIndex, fetchOrderHistory, orderId } = this.props

      if (selectedIndex === HISTORY_TAB_INDEX) {
        fetchOrderHistory(orderId)
      }
    },
    componentDidUpdate(prev) {
      const { selectedIndex, fetchOrderHistory, orderId } = this.props

      if (
        prev.selectedIndex !== selectedIndex &&
        selectedIndex === HISTORY_TAB_INDEX
      ) {
        fetchOrderHistory(orderId)
      }
    },
  }),

  withIsRequestWithIdSucceeded(FETCH_ORDER_HISTORY, {
    idProp: 'orderId',
    outProp: 'isHistoryLoaded',
  }),
  withRoute404ErrorWithId(FETCH_ORDER_HISTORY, 'orderId'),
  withRouteServerErrorWithId(FETCH_ORDER_HISTORY, 'orderId'),
)

export default withOrderDeliveryHistory
