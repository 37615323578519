import { getDomain, getOne } from 'reduken/entities'
import * as hash from 'reduken/hash'
import { createSelector } from 'reselect'
import {
  ENTITY_KEY_RESOURCES,
  HASH_KEY_RESOURCES_META,
  DOMAIN,
  HASH_KEY_LAST_TEMPORARY_IMAGE,
  ENTITY_KEY_RESOURCE_DETAILS,
  getSocialProfilesDomain,
  getSocialDataDomain,
  ENTITY_KEY_RESOURCE_STATUS,
} from './constants'

export const getResources = createSelector(
  getDomain(ENTITY_KEY_RESOURCES),
  allResources => Object.values(allResources),
)

export const getResourcesMeta = hash.getFromPath(
  DOMAIN,
  HASH_KEY_RESOURCES_META,
)

export const getResourceById = getOne(ENTITY_KEY_RESOURCES)

export const getLastTemporaryImage = hash.getFromPath(
  DOMAIN,
  HASH_KEY_LAST_TEMPORARY_IMAGE,
)

export const getSocialProfiles = (socialService, id, state) =>
  hash.getFromPath(getSocialProfilesDomain(socialService), id, state)

export const getSocialData = (socialService, id, state) =>
  hash.getFromPath(getSocialDataDomain(socialService), id, state)

export const getResourceDetails = getOne(ENTITY_KEY_RESOURCE_DETAILS)

export const getResourceStatus = getOne(ENTITY_KEY_RESOURCE_STATUS)
