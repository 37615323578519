import * as actionTypes from './action-types'

export const repeatingFetchDashboardCampaigns = () => ({
  type: actionTypes.REPEATING_FETCH_DASHBOARD_CAMPAIGNS,
})

export const repeatingFetchDashboardOrders = () => ({
  type: actionTypes.REPEATING_FETCH_DASHBOARD_ORDERS,
})

export const cancelRepeatingFetchDashboardCampaigns = () => ({
  type: actionTypes.CANCEL_REPEATING_FETCH_DASHBOARD_CAMPAIGNS,
})

export const cancelRepeatingFetchDashboardOrders = () => ({
  type: actionTypes.CANCEL_REPEATING_FETCH_DASHBOARD_ORDERS,
})

export const fetchFeaturedResources = () => ({
  type: actionTypes.FETCH_FEATURED_RESOURCES,
})
