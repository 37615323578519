import React, { useMemo, useCallback, useEffect } from 'react'
import { ReactiveComponent } from '@appbaseio/reactivesearch'
import * as R from 'ramda'
import { getFiltersWithout } from './filterUtils'
import RegularCheckbox from '../../ui/form-elements/RegularCheckbox'

const multiRangeBooleanQueryFactory = options => activeFilters => {
  const activeQueries = activeFilters.map(filter => {
    const { dataField, nestedField, rangeFilter } = options[filter]

    const query = {
      range: {
        [dataField]: rangeFilter,
      },
    }

    return nestedField
      ? {
          nested: {
            path: nestedField,
            query,
          },
        }
      : query
  })

  return {
    query: {
      bool: {
        should: activeQueries,
      },
    },
    value: activeFilters,
  }
}

const multiRangeBooleanAggregationQueryFactory = options => () => {
  const aggs = Object.entries(options)
    .map(([filter, { dataField, nestedField, rangeFilter }]) => {
      const aggs = {
        [filter]: {
          filters: {
            other_bucket_key: 'false',
            filters: {
              true: {
                range: {
                  [dataField]: rangeFilter,
                },
              },
            },
          },
        },
      }

      return nestedField
        ? {
            [filter]: {
              nested: { path: nestedField },
              aggs,
            },
          }
        : aggs
    })
    .reduce((acc, x) => ({ ...acc, ...x }), {})

  return {
    size: 0,
    aggs,
  }
}

const MultiCheckboxWrapper = ({
  options,
  value,
  setQuery,
  makeQuery,
  aggregations,
}) => {
  value = value || []

  const valueBools = Object.keys(options).map(filter => value.includes(filter))

  const handleChange = useCallback(
    (key, checked) => {
      const newValue = !checked
        ? value.filter(filter => filter !== key)
        : [...value, key]

      setQuery(makeQuery(newValue))
    },
    // eslint-disable-next-line
    [makeQuery, setQuery, ...valueBools], // we only care about value contents
  )

  // Update values to match URLParams
  useEffect(() => {
    handleChange(value)
    // eslint-disable-next-line
  }, [handleChange, ...valueBools]) // we only care about value contents

  return Object.entries(options).map(
    ([filter, { label, dataField, nestedField }]) => {
      const matching = R.pathOr(
        undefined,
        nestedField
          ? [filter, filter, 'buckets', 'true', 'doc_count']
          : [filter, 'buckets', 'true', 'doc_count'],
        aggregations,
      )

      const checked = value.includes(filter)

      return (
        <RegularCheckbox
          key={filter}
          label={label}
          checked={checked}
          onChange={e => handleChange(filter, e.target.checked)}
          isDisabled={!checked && matching === 0}
        />
      )
    },
  )
}

const ReactiveRangeMultiCheckbox = ({ componentId, options }) => {
  const makeDefaultQuery = useMemo(
    () => multiRangeBooleanAggregationQueryFactory(options),
    [options],
  )

  const makeQuery = useMemo(() => multiRangeBooleanQueryFactory(options), [
    options,
  ])

  return (
    <ReactiveComponent
      componentId={componentId}
      react={{ and: getFiltersWithout(componentId) }}
      URLParams={true}
      defaultQuery={makeDefaultQuery}
      render={props => (
        <MultiCheckboxWrapper
          {...props}
          options={options}
          makeQuery={makeQuery}
        />
      )}
    />
  )
}

export default ReactiveRangeMultiCheckbox
