import React from 'react'
import PropTypes from 'prop-types'

const Dashboard = ({ title, children }) => {
  return (
    <div className="main-dashboard">
      {title && <h1 className="main-dashboard-title">{title}</h1>}
      <div className="main-dashboard-content">{children}</div>
    </div>
  )
}

Dashboard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default Dashboard
