import { compose, withPropsOnChange, withProps } from 'recompose'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import * as R from 'ramda'
import OrderModalSendPredelivery from '../../ui/order-modals/OrderModalSendPredelivery'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { validateMaxFileSize, i18nValidation } from '../common/validation'
import {
  PREDELIVERY_MAX_FILE_SIZE,
  PREDELIVERY_TIKTOK_MAX_FILE_SIZE,
} from '../../services/checkout/business'
import { sendOrderPredelivery } from '../../services/orders'
import { isNilOrEmpty } from '../utils'
import { SEND_ORDER_PREDELIVERY } from '../../services/orders/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withFetchOrderIfNeeded from '../common/withFetchOrderIfNeeded'
import { yupToFormErrors, validateYupSchema } from 'formik'
import { SERVICE_TYPES } from '../../services/orders/business'

const validationSchema = maxFileSize =>
  Yup.object().shape({
    file: Yup.mixed()
      .nullable()
      .test(validateMaxFileSize(maxFileSize)),
    url: Yup.string().url(i18nValidation.invalidURL),
    comments: Yup.string(),
  })

const needsFileOrUrl = (values, touched) => {
  const noValues =
    isNilOrEmpty(values.url) && (isNilOrEmpty(values.file) || !values.file)
  const oneFieldTouched = touched.url || touched.file

  return noValues && oneFieldTouched
}

const enhance = compose(
  withFetchOrderIfNeeded({ modalTitleI18nKey: 'action:send-predelivery' }),
  connect(
    null,
    { sendOrderPredelivery },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      file: null,
      url: '',
      comments: '',
    }),

    fileFields: ['file'],

    validate: (values, { orderData }) => {
      const serviceType = R.path(['product', 'service'], orderData)
      const isTiktok =
        serviceType === SERVICE_TYPES.TIKTOK_SHORT ||
        serviceType === SERVICE_TYPES.TIKTOK_LONG

      try {
        validateYupSchema(
          values,
          validationSchema(
            isTiktok
              ? PREDELIVERY_TIKTOK_MAX_FILE_SIZE
              : PREDELIVERY_MAX_FILE_SIZE,
          ),
          true,
        )
      } catch (err) {
        return yupToFormErrors(err)
      }

      return {}
    },

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      if (needsFileOrUrl(values, { file: true, url: true })) {
        return
      }

      props.sendOrderPredelivery(props.orderId, props.isFix, values)
    },
  }),

  withIsRequestPending(SEND_ORDER_PREDELIVERY),
  withIsRequestSucceeded(SEND_ORDER_PREDELIVERY),
  withRequestError(SEND_ORDER_PREDELIVERY),
  withDeleteRequestOnUnmount(SEND_ORDER_PREDELIVERY),

  // See https://www.notion.so/Propuesta-API-9ebe54f09289488fbe3fd7e3dbab4bc9?p=5fd7977b768845afbc14a4bd9394204e
  withProps(({ requestError }) => {
    if (!requestError || !requestError.validationMessages) {
      return {}
    }

    const hasFileOrUrlFileError =
      requestError.validationMessages.urlOrUrlFile &&
      !!requestError.validationMessages.urlOrUrlFile.find(
        x => x.key === 'errors:unknown-oneIsNotEmpty',
      )

    if (!hasFileOrUrlFileError) {
      return {}
    }

    return {
      requestError: {
        ...requestError,
        validationMessages: {
          ...requestError.validationMessages,
          urlOrUrlFile: R.pipe(
            R.filter(x => x.key !== 'errors:unknown-oneIsNotEmpty'),
            R.when(x => x.length === 0, R.always(undefined)),
          )(requestError.validationMessages.urlOrUrlFile),
        },
        globalMessages: [
          i18nValidation.requireFileOrUrl(),
          ...(requestError.globalMessages || []),
        ],
      },
    }
  }),
  withProps(props => ({
    serviceType: R.path(['orderData', 'product', 'service'], props),
  })),
  withFormErrors(['file', 'url', 'comments']),

  // Show global message if one of file or url is missing
  withPropsOnChange(
    ['values', 'globalMessages', 'touched'],
    ({ values, globalMessages = [], touched }) => {
      if (needsFileOrUrl(values, touched)) {
        return {
          globalMessages: [
            i18nValidation.requireFileOrUrl(),
            ...(globalMessages || []),
          ],
        }
      }

      return {}
    },
  ),
)

export default enhance(OrderModalSendPredelivery)
