import React from 'react'
import PropTypes from 'prop-types'

const URL_REGEX = /\b([a-zA-Z0-9]+:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)\b/

const PreformattedLine = ({ line }) => {
  const components = []

  let remainingLine = line
  let match

  while (
    remainingLine.length > 0 &&
    (match = remainingLine.match(URL_REGEX)) !== null
  ) {
    const url = match[0]
    const previousText = remainingLine.slice(0, match.index)
    remainingLine = remainingLine.slice(match.index + match[0].length)

    if (previousText.length > 0) {
      components.push(previousText)
    }

    components.push(
      <a key={components.length} href={url}>
        {url}
      </a>,
    )
  }

  return (
    <>
      {components}
      {remainingLine}
    </>
  )
}

const PreformattedText = ({ text }) =>
  text.split(/(?:\r?\n){2,}/).map((paragraph, i) => (
    <p key={i} className="preformatted-paragraph">
      {paragraph.split(/(?:\r?\n)/).map((line, j, paragraphLines) => (
        <React.Fragment key={j}>
          <PreformattedLine line={line} />
          {j < paragraphLines.length - 1 ? <br /> : null}
        </React.Fragment>
      ))}
    </p>
  ))

PreformattedText.propTypes = {
  text: PropTypes.string,
}

export default PreformattedText
