import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  fetchCampaignOrderStats,
  getCampaignOrderStats,
} from '../../services/campaigns'
import { FETCH_CAMPAIGN_ORDER_STATS } from '../../services/campaigns/action-types'
import withIsLoadingWithId from '../../hocs/withIsLoadingWithId'
import withEffect from '../../hocs/withEffect'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'

const withCampaignOrderStats = compose(
  withPropsOnChange(['campaignId', 'orderId'], ({ campaignId, orderId }) => ({
    fetchId: `${campaignId}_${orderId}`,
  })),

  withRoute404ErrorWithId(FETCH_CAMPAIGN_ORDER_STATS, 'fetchId'),
  withRouteServerErrorWithId(FETCH_CAMPAIGN_ORDER_STATS, 'fetchId'),

  connect(
    (state, { campaignId, orderId }) => ({
      stats: getCampaignOrderStats(campaignId, orderId, state),
    }),
    {
      fetchCampaignOrderStats,
    },
  ),

  withEffect(
    ({ fetchCampaignOrderStats, campaignId, orderId }) => {
      fetchCampaignOrderStats(campaignId, orderId)
    },
    ({ fetchCampaignOrderStats, campaignId, orderId }) => [
      fetchCampaignOrderStats,
      campaignId,
      orderId,
    ],
  ),

  withIsLoadingWithId(FETCH_CAMPAIGN_ORDER_STATS, {
    idProp: 'fetchId',
    outProp: 'isLoadingResourceStats',
  }),
)

export default withCampaignOrderStats
