import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Truncate from 'react-truncate'
import withTranslations from '../../hocs/withTranslations'
import { FormattedDate } from 'react-intl'
import Button from '@redradix/components.button'
import IconSocial from '../icons/IconSocial'
import CardLabel from './CardLabel'
import Select from '../form-elements/Select'
import CardSocialBroadcast from './CardSocialBroadcast'
import CardBadge from '../cards/CardBadge'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import { ReactComponent as UserFallback } from '../../styles/icons/user.svg'
import { ReactComponent as Cart } from './../../styles/icons/cart.svg'
import { ReactComponent as Fav } from './../../styles/icons/fav.svg'
import { ReactComponent as FavFilled } from './../../styles/icons/fav-filled.svg'
import HackFormattedMoney from '../HackFormattedMoney'
import { moneyToFloat } from '../../app/utils'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const preventNavigate = e => {
  e.preventDefault()
  e.stopPropagation()
}

const FavCard = ({
  category,
  channel,
  finalPrice,
  isCartDisabled,
  isCartLoading,
  isUserVerified,
  isFavourited,
  isNew,
  acceptsBroadcast,
  broadcastSocialNetworks,
  isBroadcastChecked,
  image,
  i18n,
  linkUrl,
  onCartClick,
  onFavClick,
  onServiceChange,
  onBroadcastChange,
  price,
  selectedService,
  services,
  title,
  holidaysEndDate,
  isInCart,
}) => {
  const [hasError, setHasError] = useState(false)

  const lastImage = useRef()

  useEffect(() => {
    if (image !== lastImage.current) {
      setHasError(false)
      lastImage.current = image
    }
  }, [image, setHasError, lastImage])

  return (
    <div className="fav-card">
      <Link className="fav-card-link" to={linkUrl}>
        {(moneyToFloat(finalPrice) < moneyToFloat(price) || isNew) && (
          <div className="fav-card-extra">
            {moneyToFloat(finalPrice) < moneyToFloat(price) ? (
              <CardLabel type="sale" />
            ) : null}
            {isNew ? <CardLabel type="new" /> : null}
          </div>
        )}
        <button
          className="fav-card-extra-action"
          title={i18n('noun:favourites')}
          onClick={onFavClick}
        >
          {isFavourited ? (
            <FavFilled className="icon rdx-button-icon filled-icon" />
          ) : (
            <Fav className="icon rdx-button-icon" />
          )}
        </button>
        <div className="fav-card-image">
          {!!image && !hasError ? (
            <img
              onError={() => {
                setHasError(true)
              }}
              src={image}
              alt={title}
            />
          ) : (
            <UserFallback className="fav-card-image-fallback" />
          )}
        </div>
        <div className="fav-card-heading">
          {channel ? (
            <div className="fav-card-social">
              <IconSocial type={channel} />
            </div>
          ) : null}
          <h2 className="fav-card-title">
            <Truncate lines={2} ellipsis="...">
              {title}
            </Truncate>

            {isUserVerified && <CardBadge isVerified={isUserVerified} />}
          </h2>
          <div className="fav-card-category">{category}</div>
        </div>
      </Link>
      <div className="fav-card-footer">
        {acceptsBroadcast && !holidaysEndDate ? (
          <div className="fav-card-social-check">
            <CardSocialBroadcast
              socialNetworks={broadcastSocialNetworks}
              checked={isBroadcastChecked}
              onChange={onBroadcastChange}
            />
          </div>
        ) : null}
        <div className="fav-card-prices">
          {moneyToFloat(finalPrice) < moneyToFloat(price) ? (
            <span className="fav-card-prices-initial">
              <HackFormattedMoney value={price} />
            </span>
          ) : null}
          <span className="fav-card-prices-final">
            <HackFormattedMoney value={finalPrice} />
          </span>
        </div>
        <div className="fav-card-action" onClick={preventNavigate}>
          {holidaysEndDate ? (
            <p className="card-holidays-indicator">
              <span>{i18n('noun:vacations-until')} </span>
              <span>
                <FormattedDate format="coobis" value={holidaysEndDate} />
              </span>
            </p>
          ) : (
            <>
              {services.length > 1 && (
                <Select
                  options={services}
                  value={selectedService}
                  isDisabled={isCartDisabled}
                  onChange={onServiceChange}
                />
              )}
              <Button
                isDisabled={isCartDisabled || isInCart}
                isLoading={isCartLoading}
                onClick={onCartClick}
              >
                <Cart className="icon rdx-button-icon" />
                <span>{i18n(isInCart ? 'action:added' : 'action:add')}</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

FavCard.propTypes = {
  category: PropTypes.string,
  channel: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  finalPrice: CoobisPropTypes.money,
  isCartDisabled: PropTypes.bool,
  isCartLoading: PropTypes.bool,
  isUserVerified: PropTypes.bool,
  isFavourited: PropTypes.bool,
  isNew: PropTypes.bool,
  acceptsBroadcast: PropTypes.bool,
  isBroadcastChecked: PropTypes.bool,
  image: PropTypes.string,
  linkUrl: PropTypes.string,
  onCartClick: PropTypes.func,
  onFavClick: PropTypes.func,
  onServiceChange: PropTypes.func,
  onBroadcastChange: PropTypes.func,
  price: CoobisPropTypes.money,
  selectedService: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
    }),
  ),
  broadcastSocialNetworks: PropTypes.arrayOf(
    PropTypes.oneOf([RESOURCE_TYPES.FACEBOOK, RESOURCE_TYPES.TWITTER]),
  ),
  title: PropTypes.string.isRequired,
  holidaysEndDate: PropTypes.object,
  isInCart: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(FavCard)
