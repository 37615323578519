import * as actionTypes from './action-types'
import * as hash from 'reduken/hash'
import {
  DOMAIN,
  HASH_KEY_IS_CREATE_CAMPAIGN_MODAL_OPEN,
  HASH_KEY_IS_BILLING_DATA_MODAL_OPEN,
  HASH_KEY_COUPON,
} from './constants'
import { SERVICE_TYPES } from '../orders/business'
import { PAYMENT_METHODS } from './business'
import { deleteRequest } from '../communication'

export const repeatingFetchCart = () => ({
  type: actionTypes.REPEATING_FETCH_CART,
})

export const deleteCart = () => ({
  type: actionTypes.DELETE_CART,
})

export const addItem = (id, service, words, broadcast) => ({
  type: actionTypes.ADD_ITEM,
  payload: { id, service, words, broadcast },
})

export const deleteItem = (id, service) => ({
  type: actionTypes.DELETE_ITEM,
  payload: { id, service },
})

export const fetchDatesConfig = () => ({
  type: actionTypes.FETCH_DATES_CONFIG,
})

export const openCreateCampaignModal = () =>
  hash.set(DOMAIN, HASH_KEY_IS_CREATE_CAMPAIGN_MODAL_OPEN, true)

export const closeCreateCampaignModal = () =>
  hash.set(DOMAIN, HASH_KEY_IS_CREATE_CAMPAIGN_MODAL_OPEN, false)

export const openBillingDataModal = () =>
  hash.set(DOMAIN, HASH_KEY_IS_BILLING_DATA_MODAL_OPEN, true)

export const closeBillingDataModal = () =>
  hash.set(DOMAIN, HASH_KEY_IS_BILLING_DATA_MODAL_OPEN, false)

export const submitGeneralData = data => ({
  type: actionTypes.SUBMIT_GENERAL_DATA,
  payload: { data },
})

const submitBriefing = serviceType => data => ({
  type: actionTypes.SUBMIT_BRIEFING,
  payload: { serviceType, data },
})

export const submitFacebookBriefing = submitBriefing(SERVICE_TYPES.FACEBOOK)
export const submitInstagramPhotoBriefing = submitBriefing(
  SERVICE_TYPES.INSTAGRAM_PHOTO,
)
export const submitInstagramStoryBriefing = submitBriefing(
  SERVICE_TYPES.INSTAGRAM_STORY,
)
export const submitInstagramVideoBriefing = submitBriefing(
  SERVICE_TYPES.INSTAGRAM_VIDEO,
)
export const submitTwitchBriefing = submitBriefing(SERVICE_TYPES.TWITCH)
export const submitTwitterBriefing = submitBriefing(SERVICE_TYPES.TWITTER)
export const submitWebBriefing = submitBriefing(SERVICE_TYPES.WEB_POST)
export const submitWebOnlyPostBriefing = submitBriefing(
  SERVICE_TYPES.WEB_ONLY_POST,
)
export const submitYoutubeDedicatedBriefing = submitBriefing(
  SERVICE_TYPES.YOUTUBE_DEDICATED,
)
export const submitYoutubeMentionBriefing = submitBriefing(
  SERVICE_TYPES.YOUTUBE_MENTION,
)
export const submitTiktokShortBriefing = submitBriefing(
  SERVICE_TYPES.TIKTOK_SHORT,
)
export const submitTiktokLongBriefing = submitBriefing(
  SERVICE_TYPES.TIKTOK_LONG,
)

export const validateDiscountCoupon = data => ({
  type: actionTypes.VALIDATE_DISCOUNT_COUPON,
  payload: { data },
})

export const removeAppliedCoupon = () => hash.remove(DOMAIN, HASH_KEY_COUPON)

export const submitPaymentWithWallet = (couponCode, cartTotalPayment) => ({
  type: actionTypes.SUBMIT_PAYMENT,
  payload: {
    paymentMethod: PAYMENT_METHODS.WALLET_BALANCE,
    couponCode,
    cartTotalPayment,
  },
})

export const submitPaymentWithCard = (
  couponCode,
  cartTotalPayment,
  stripe,
) => ({
  type: actionTypes.SUBMIT_PAYMENT,
  payload: {
    paymentMethod: PAYMENT_METHODS.CREDIT_CARD,
    couponCode,
    cartTotalPayment,
    stripe,
  },
})

export const clearSubmitPaymentRequest = () =>
  deleteRequest(actionTypes.SUBMIT_PAYMENT)
