import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Paragraph = ({ alignment, children, title }) => {
  const classes = cx('paragraph', {
    'is-left': alignment === 'left',
    'is-center': alignment === 'center',
  })
  return (
    <div className={classes}>
      {title && <h5 className="paragraph-title">{title}</h5>}
      <div className="paragraph-text">{children}</div>
    </div>
  )
}

Paragraph.defaultProps = {
  alignment: 'left',
}

Paragraph.propTypes = {
  alignment: PropTypes.oneOf(['left', 'center']),
  children: PropTypes.node,
  title: PropTypes.string,
}

export default Paragraph
