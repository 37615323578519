import * as hash from 'reduken/hash'
import {
  DOMAIN,
  HASH_KEY_TOURS,
  TOUR_WELCOME_BRAND,
  TOUR_WELCOME_MEDIA,
} from './constants'
import { createSelector } from 'reselect'
import { createDeepEqualSelector } from '../../app/utils'
import * as R from 'ramda'

export const getTours = createDeepEqualSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_TOURS),
  tours =>
    !R.isNil(tours) ? (!R.isEmpty(tours) ? Object.values(tours) : []) : null,
)

const getWelcomeMediaTour = tours =>
  tours && tours.find(tour => tour.tourName === TOUR_WELCOME_MEDIA)
export const getIsWelcomeMediaTourViewed = createSelector(
  getTours,
  tours => {
    if (R.isNil(tours)) return false
    const welcomeTour = getWelcomeMediaTour(tours)
    return welcomeTour ? welcomeTour.viewed : true
  },
)

const getWelcomeBrandTour = tours =>
  tours && tours.find(tour => tour.tourName === TOUR_WELCOME_BRAND)
export const getIsWelcomeBrandTourViewed = createSelector(
  getTours,
  tours => {
    if (R.isNil(tours)) return false
    const welcomeTour = getWelcomeBrandTour(tours)
    return welcomeTour ? welcomeTour.viewed : true
  },
)
