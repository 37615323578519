import React from 'react'
import PropTypes from 'prop-types'

const GaugeBar = ({ labelText, color, total, percent, hasLabel }) => {
  return (
    <div className="gauge-bar">
      {hasLabel && (
        <p className="gauge-bar-label">{`${labelText} ${percent} / ${total}`}</p>
      )}
      <div className="gauge-bar-total-bar">
        <div
          className="gauge-bar-percent-bar"
          style={{ backgroundColor: `${color}`, width: `${percent}%` }}
        />
      </div>
    </div>
  )
}

GaugeBar.propTypes = {
  labelText: PropTypes.string,
  color: PropTypes.string,
  total: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  hasLabel: PropTypes.bool,
}

export default GaugeBar
