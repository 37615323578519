import React from 'react'
import PropTypes from 'prop-types'
import Header from '../header/Header'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { AuthScope } from '../../services/auth/business'
import cx from 'classnames'
import HeaderPublic from '../header/HeaderPublic'
import LOCALES from '../../config/locales'

const LANGUAGES = LOCALES.map(item => item.locale)

const Layout = ({
  children,
  navItems,
  current,
  userName,
  userAvatar,
  userProfile,
  notifications,
  isMarkAllLoading,
  onMarkAllClick,
  onNotificationClick,
  numberAlerts,
  balance,
  onBalanceAction,
  cartItems,
  onCartClick,
  isPublic,
  onLogout,
  isMedia,
  scope,
  location,
  isLight,
  language,
  setLanguage,
}) => (
  <div className={cx('App app-wrapper', { 'is-light': isLight })}>
    {isPublic ? (
      <HeaderPublic language={language} setLanguage={setLanguage} />
    ) : (
      <Header
        navItems={navItems}
        current={current}
        userName={userName}
        userAvatar={userAvatar}
        userProfile={userProfile}
        notifications={notifications}
        isMarkAllLoading={isMarkAllLoading}
        onMarkAllClick={onMarkAllClick}
        onNotificationClick={onNotificationClick}
        numberAlerts={numberAlerts}
        cartItems={cartItems}
        onCartClick={onCartClick}
        balance={balance}
        onBalanceAction={onBalanceAction}
        onLogout={onLogout}
        isMedia={isMedia}
        scope={scope}
        location={location}
      />
    )}
    <main className="main wrapper">{children}</main>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node,
  navItems: PropTypes.arrayOf(CoobisPropTypes.navItem),
  current: PropTypes.string,
  onAlertsClick: PropTypes.func,
  numberAlerts: PropTypes.number,
  balance: CoobisPropTypes.money,
  onBalanceAction: PropTypes.func,
  userName: PropTypes.string,
  userAvatar: PropTypes.string,
  userProfile: PropTypes.string,
  onLogout: PropTypes.func,
  cartItems: PropTypes.number,
  onCartClick: PropTypes.func,
  isPublic: PropTypes.bool,
  isMedia: PropTypes.bool,
  isLight: PropTypes.bool,
  scope: PropTypes.oneOf([AuthScope.BRAND, AuthScope.MEDIA]),
  language: PropTypes.oneOf(LANGUAGES),
  setLanguage: PropTypes.func,
}

export default withTranslations(Layout)
