import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@redradix/components.input'
import { ReactComponent as Search } from '../../styles/icons/search.svg'

const SearchInput = ({
  autoFocus,
  errorText,
  id,
  isDisabled,
  isTouched,
  name,
  onBlur,
  onChange,
  onFocus,
  onRightIconClick,
  placeholder,
  value,
}) => {
  return (
    <Input
      appearance="box"
      behaviour="static"
      widthBehaviour="full"
      heightBehaviour="fixed"
      type="search"
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      errorText={errorText}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      isTouched={isTouched}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onRightIconClick={onRightIconClick}
      renderRightIcon={props => <Search {...props} />}
    />
  )
}

SearchInput.propTypes = {
  /** Boolean that specifies whether an <input> should automatically get focus when the page loads */
  autoFocus: PropTypes.bool,
  /** String that defines the error text of the <input> */
  errorText: PropTypes.string,
  /** String that defines a unique identifier of the <input> */
  id: PropTypes.string.isRequired,
  /** Boolean that defines whether the <input> is disabled */
  isDisabled: PropTypes.bool,
  /** Boolean that specifies whether the <input> field has been modified */
  isTouched: PropTypes.bool,
  /** String that specifies the name of an <input> */
  name: PropTypes.string.isRequired,
  /** Function that is triggered when the <input> field loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when the <input> is changed */
  onChange: PropTypes.func,
  /** Function that is triggered when the <input> gets focus */
  onFocus: PropTypes.func,
  /** Function that is triggered when the right icon is clicked */
  onRightIconClick: PropTypes.func,
  /** String that describes the expected value of the <input> */
  placeholder: PropTypes.string,
  /** String that specifies the value of the <input> */
  value: PropTypes.string,
}

export default SearchInput
