import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import withTranslations from '../../hocs/withTranslations'
import { COOBIS_PERCENT_OPTIONS_NO_MIN_DECIMALS } from '../../config/formats'
import AlternativeGaugeWidget from './AlternativeGaugeWidget'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

am4core.useTheme(am4themes_animated)

const createChart = (element, data) => {
  const chart = am4core.create(element, am4maps.MapChart)

  chart.mouseWheelBehavior = 'none'
  chart.geodata = am4geodata_worldLow
  chart.projection = new am4maps.projections.Miller()

  const polygonSeries = new am4maps.MapPolygonSeries()
  chart.series.push(polygonSeries)

  polygonSeries.heatRules.push({
    property: 'fill',
    target: polygonSeries.mapPolygons.template,
    min: am4core.color('#eef0ff'),
    max: am4core.color('#3e17b3'),
  })
  polygonSeries.useGeodata = true
  polygonSeries.data = data

  // Configure series
  const polygonTemplate = polygonSeries.mapPolygons.template
  polygonTemplate.tooltipText = '{label}: {formattedValue}'
  polygonTemplate.fill = am4core.color('#eef0ff')

  // Create hover state and set alternative fill color
  const hs = polygonTemplate.states.create('hover')
  hs.properties.fill = am4core.color('#a9b3ff')

  // excludes Antarctica
  polygonSeries.exclude = ['AQ']

  return chart
}

const MapChart = ({
  data,

  hasGaugeWidget,
  gaugeWidgetData,
  totalCountries,
  isPrevPaginationDisabled,
  isNextPaginationDisabled,
  onPrevPaginationClick,
  onNextPaginationClick,

  hasCityWidget,
  cityWidgetData,
  totalCities,
  isPrevCityDisabled,
  isNextCityDisabled,
  onPrevCityHandler,
  onNextCityHandler,

  i18n,
  i18nNumber,
}) => {
  const chart = useRef()
  const chartElementRef = useRef()
  const wrapperClasses = cx(`map-chart-wrapper`, {
    'has-gauge-widget': hasGaugeWidget,
    'has-city-widget': hasCityWidget,
  })

  // Destroy chart on unmount
  useEffect(() => {
    chart.current = createChart(
      chartElementRef.current,
      data.map(item => ({
        ...item,
        label: item.label || item.id,
        formattedValue: i18nNumber(
          item.value,
          COOBIS_PERCENT_OPTIONS_NO_MIN_DECIMALS,
        ),
      })),
    )

    return () => {
      if (chart.current) {
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [data, i18nNumber])

  return (
    <div className={wrapperClasses}>
      <>
        <div className="map-chart-box">
          <div ref={chartElementRef} className="map-chart"></div>
          <div className="heat-legend-wrapper">
            <div className="heat-legend"></div>
            <div className="markers">
              <span className="marker">{i18n('noun:low')}</span>
              <span className="marker">{i18n('noun:high')}</span>
            </div>
          </div>
        </div>
        {hasGaugeWidget && (
          <AlternativeGaugeWidget
            titleLabel={hasCityWidget && i18n('countries-widget:title')}
            totalLabel={i18n('countries-chart:count', {
              shown: gaugeWidgetData.length,
              total: totalCountries,
            })}
            items={gaugeWidgetData}
            color="#3e17b3"
            isPrevDisabled={isPrevPaginationDisabled}
            isNextDisabled={isNextPaginationDisabled}
            onPrevClick={onPrevPaginationClick}
            onNextClick={onNextPaginationClick}
          />
        )}
        {hasCityWidget && (
          <AlternativeGaugeWidget
            titleLabel={i18n('cities-widget:title')}
            totalLabel={i18n('cities-chart:count', {
              shown: cityWidgetData.length,
              total: totalCities,
            })}
            items={cityWidgetData}
            color="#3e17b3"
            isPrevDisabled={isPrevCityDisabled}
            isNextDisabled={isNextCityDisabled}
            onPrevClick={onPrevCityHandler}
            onNextClick={onNextCityHandler}
          />
        )}
      </>
    </div>
  )
}

MapChart.propTypes = {
  data: CoobisPropTypes.demographicData,

  hasGaugeWidget: PropTypes.bool,
  gaugeWidgetData: CoobisPropTypes.demographicData,
  totalCountries: PropTypes.number,
  isPrevPaginationDisabled: PropTypes.bool,
  isNextPaginationDisabled: PropTypes.bool,
  onPrevPaginationClick: PropTypes.func,
  onNextPaginationClick: PropTypes.func,

  hasCityWidget: PropTypes.bool,
  cityWidgetData: CoobisPropTypes.demographicData,
  totalCities: PropTypes.number,
  isPrevCityDisabled: PropTypes.bool,
  isNextCityDisabled: PropTypes.bool,
  onPrevCityHandler: PropTypes.func,
  onNextCityHandler: PropTypes.func,
}

export default withTranslations(MapChart)
