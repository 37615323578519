import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import { MediaQueryLap, MediaQueryPalm } from '../../shared/MediaQueries'
import CampaignDetailPanel from '../CampaignDetailPanel'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import ChartCard from '../../cards/ChartCard'
import ClusteredBarChart from '../../charts/ClusteredBarChart'
import PieChart from '../../charts/PieChart'
import MultilineChart from '../../charts/MultilineChart'
import MapChart from '../../charts/MapChart'
import AlternativeGaugeWidget from '../../charts/AlternativeGaugeWidget'
import PublicationsTableWebBranding from '../tables/PublicationsTableWebBranding'
import { MAX_LEGEND_ITEMS } from '../../../services/campaigns/constants'
import CoobisPropTypes from '../../../app/common/coobis-prop-types'

const CampaignDetailWebBrandingAll = ({
  publicationsTableData,
  countryData,

  countryWidgetData,
  countryWidgetTotal,
  isPrevCountryDisabled,
  isNextCountryDisabled,
  onPrevCountryHandler,
  onNextCountryHandler,

  cityWidgetData,
  cityWidgetTotal,
  isPrevCityDisabled,
  isNextCityDisabled,
  onPrevCityHandler,
  onNextCityHandler,

  i18n,
  // MetricsValues
  webPublications,
  totalVisits,
  totalUniqueVisitors,
  engagements,
  averagePageTime,
  averageScrollPercentage,
  averageBounceRate,
  ageData,
  genderData,
  engagementData,
  onEngagementLegendDelete,
  engagementLegendOptions,
  onEngagementLegendOptionChange,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        <AnalyticsKpi
          iconType="megaphone"
          value={webPublications}
          label={i18n('metrics:web-publications')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="eye"
          value={totalVisits}
          label={i18n('metrics:total-visits')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="target"
          value={totalUniqueVisitors}
          label={i18n('metrics:total-unique-visitors')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="heart"
          value={engagements}
          label={i18n('metrics:engagements')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="clock"
          value={averagePageTime}
          label={i18n('metrics:average-page-time')}
          isTime
        />
        <AnalyticsKpi
          iconType="scroll"
          value={averageScrollPercentage}
          label={i18n('metrics:average-scroll-percentage')}
          isPercentage
        />
        <AnalyticsKpi
          iconType="ball"
          value={averageBounceRate}
          label={i18n('metrics:average-bounce-rate')}
          isPercentage
        />
      </div>
      <div className="campaign-detail-section-row">
        <div className="age-audience campaign-detail-section-cell two-thirds">
          <ChartCard title={i18n('metrics:age-audience')}>
            <ClusteredBarChart data={ageData} />
          </ChartCard>
        </div>
        <div className="gender-audience campaign-detail-section-cell one-third">
          <ChartCard title={i18n('metrics:gender-audience')} isPie>
            <PieChart data={genderData} />
          </ChartCard>
        </div>
      </div>
      <div className="campaign-detail-section-row">
        <div className="engagements campaign-detail-section-cell one-whole">
          <ChartCard title={i18n('metrics:engagements')}>
            <MultilineChart
              data={engagementData}
              onLegendDelete={onEngagementLegendDelete}
              legendOptions={engagementLegendOptions}
              onLegendOptionChange={onEngagementLegendOptionChange}
              maxLegendItems={MAX_LEGEND_ITEMS}
            />
          </ChartCard>
        </div>
      </div>
      <div className="campaign-detail-section-row">
        <div className="country-audience campaign-detail-section-cell one-whole">
          <ChartCard title={i18n('metrics:country-audience')}>
            <MediaQueryPalm>
              <AlternativeGaugeWidget
                totalLabel={i18n('countries-chart:count', {
                  shown: countryWidgetData.length,
                  total: countryWidgetTotal,
                })}
                items={countryWidgetData}
                color="#3e17b3"
                isPrevDisabled={isPrevCountryDisabled}
                isNextDisabled={isNextCountryDisabled}
                onPrevClick={onPrevCountryHandler}
                onNextClick={onNextCountryHandler}
              />
              <AlternativeGaugeWidget
                titleLabel={i18n('cities-widget:title')}
                totalLabel={i18n('cities-chart:count', {
                  shown: cityWidgetData.length,
                  total: cityWidgetTotal,
                })}
                items={cityWidgetData}
                color="#3e17b3"
                isPrevDisabled={isPrevCityDisabled}
                isNextDisabled={isNextCityDisabled}
                onPrevClick={onPrevCityHandler}
                onNextClick={onNextCityHandler}
              />
            </MediaQueryPalm>
            <MediaQueryLap>
              <MapChart
                data={countryData}
                hasGaugeWidget
                gaugeWidgetData={countryWidgetData}
                isPrevPaginationDisabled={isPrevCountryDisabled}
                isNextPaginationDisabled={isNextCountryDisabled}
                onPrevPaginationClick={onPrevCountryHandler}
                onNextPaginationClick={onNextCountryHandler}
                totalCountries={countryWidgetTotal}
                hasCityWidget
                cityWidgetData={cityWidgetData}
                isPrevCityDisabled={isPrevCityDisabled}
                isNextCityDisabled={isNextCityDisabled}
                onPrevCityHandler={onPrevCityHandler}
                onNextCityHandler={onNextCityHandler}
                totalCities={cityWidgetTotal}
              />
            </MediaQueryLap>
          </ChartCard>
        </div>
      </div>
      <CampaignDetailPanel title={i18n('noun:publications-table')}>
        <PublicationsTableWebBranding
          publications={publicationsTableData}
        ></PublicationsTableWebBranding>
      </CampaignDetailPanel>
    </>
  )
}

CampaignDetailWebBrandingAll.propTypes = {
  publicationsTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      visits: PropTypes.number,
      engagements: PropTypes.number,
      uniqueUsers: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  countryData: CoobisPropTypes.demographicData,

  countryWidgetData: CoobisPropTypes.demographicData,
  countryWidgetTotal: PropTypes.number,
  isPrevCountryDisabled: PropTypes.bool,
  isNextCountryDisabled: PropTypes.bool,
  onPrevCountryHandler: PropTypes.func,
  onNextCountryHandler: PropTypes.func,

  cityWidgetData: CoobisPropTypes.demographicData,
  cityWidgetTotal: PropTypes.number,
  isPrevCityDisabled: PropTypes.bool,
  isNextCityDisabled: PropTypes.bool,
  onPrevCityHandler: PropTypes.func,
  onNextCityHandler: PropTypes.func,

  i18n: PropTypes.func.isRequired,
  // Metrics values
  webPublications: PropTypes.number,
  totalVisits: PropTypes.number,
  totalUniqueVisitors: PropTypes.number,
  engagements: PropTypes.number,
  averagePageTime: PropTypes.number,
  averageScrollPercentage: PropTypes.number,
  averageBounceRate: PropTypes.number,
  ageData: PropTypes.array,
  genderData: PropTypes.array,
  engagementData: PropTypes.array,
  onEngagementLegendDelete: PropTypes.func,
  engagementLegendOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
      isFixed: PropTypes.bool,
    }),
  ),
  onEngagementLegendOptionChange: PropTypes.func,
}

export default withTranslations(CampaignDetailWebBrandingAll)
