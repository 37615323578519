import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import DeleteButton from '../buttons/DeleteButton'
import AnchorButton from '../buttons/AnchorButton'
import { ReactComponent as Close } from '../../styles/icons/close.svg'
import withTranslations from '../../hocs/withTranslations'
import { ROUTE_BRAND_CHECKOUT } from '../../app/common/routes'
import HackFormattedMoney from '../HackFormattedMoney'
import { isNilOrEmpty } from '../../app/utils'

const Cart = ({
  children,
  isClosed,
  handleClose,
  handleEmpty,
  handleCheckoutClick,
  cartExportUrl,
  handleCartExportClick,
  total,
  itemsNumber,
  hasCheckoutButton,
  i18n,
}) => {
  const classes = cx('cart', { 'is-closed': isClosed })
  return (
    <div className={classes}>
      <div className="cart-container">
        <Close className="close-icon" onClick={handleClose} />
        <div className="cart-header">
          <p className="cart-title">
            {i18n('cart:title')} ({itemsNumber})
          </p>
          {cartExportUrl && (
            <a
              href={cartExportUrl}
              onClick={handleCartExportClick}
              className="cart-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n('cart:action-export')}
            </a>
          )}
        </div>
        {children}
        {children && (
          <DeleteButton
            buttonText={i18n('cart:action-empty')}
            onDelete={handleEmpty}
          />
        )}
      </div>
      <div className="cart-resume">
        <div className="total-price">
          <span>{i18n('cart:total')}</span>
          <span>
            {!isNilOrEmpty(total) ? <HackFormattedMoney value={total} /> : null}
          </span>
        </div>
        {hasCheckoutButton && (
          <AnchorButton
            linkUrl={ROUTE_BRAND_CHECKOUT.linkTo()}
            onClick={handleCheckoutClick}
            hierarchy="primary"
            widthBehaviour="full"
            heightBehaviour="fixed"
          >
            <p>{i18n('cart:action-checkout')}</p>
          </AnchorButton>
        )}
      </div>
    </div>
  )
}

Cart.propTypes = {
  isClosed: PropTypes.bool,
  handleClose: PropTypes.func,
  handleEmpty: PropTypes.func,
  handleCheckoutClick: PropTypes.func,
  cartExportUrl: PropTypes.string,
  handleCartExportClick: PropTypes.func,
  total: PropTypes.number,
  itemsNumber: PropTypes.number,
  hasCheckoutButton: PropTypes.bool,
}

export default withTranslations(Cart)
