import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const FormField = ({
  label,
  children,
  isRequired,
  errorText,
  isTouched,
  helperText,
  block,
}) => {
  const showError = isTouched && Boolean(errorText)
  const className = cx('form-field', {
    block: block,
    'has-error': showError,
  })
  return (
    <div className={className}>
      {label && (
        <div className="label">
          {label}
          {isRequired && <span className="rdx-icon--require">*</span>}
        </div>
      )}
      <div className="field-content">{children}</div>
      {helperText && !showError && (
        <div className="rdx-input-field-helper">{helperText}</div>
      )}
      {showError && <div className="rdx-input-field-helper">{errorText}</div>}
    </div>
  )
}

FormField.propTypes = {
  children: PropTypes.node,
  label: PropTypes.any,
  isRequired: PropTypes.bool,
  errorText: PropTypes.node,
  isTouched: PropTypes.bool,
  helperText: PropTypes.string,
}

export default FormField
