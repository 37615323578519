import React from 'react'
import imageBig from './images/noOrdersBig.png'
import BubblesCard from './BubblesCard'

const NoOrdersCard = () => {
  return (
    <BubblesCard>
      <div className="no-oders-card">
        <div className="no-oders-card-image">
          <picture>
            <img src={imageBig} alt="Decoration" />
          </picture>
        </div>
      </div>
    </BubblesCard>
  )
}

NoOrdersCard.propTypes = {}

export default NoOrdersCard
