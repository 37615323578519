import { compose, withPropsOnChange, withStateHandlers } from 'recompose'
import * as R from 'ramda'
import {
  STATISTICS_COLORS,
  DEFAULT_MIN_LEGEND_ITEMS,
} from '../../services/campaigns/constants'
import withTimeSeriesGraph from './withTimeSeriesGraph'
import {
  getChartMinAndMaxDates,
  getSeriesMaxInitialDay,
} from '../common/statistics-utils'
import { DATE_FORMAT_OPTIONS } from '../../config/formats'
import withTranslations from '../../hocs/withTranslations'

const withTimeSeriesGraphDynamicLegend = (
  seriesIteratorCreator,
  dataPointFn,
  {
    dataOutProp = 'data',
    legendOptionsOutProp = 'legendOptions',
    onLegendDeleteOutProp = 'onLegendDelete',
    onLegendOptionChangeOutProp = 'onLegendOptionChange',
    dependentPropNames = [],
  },
) =>
  compose(
    withTimeSeriesGraph(seriesIteratorCreator, dataPointFn, {
      dataOutProp,
      dependentPropNames,
    }),

    withStateHandlers(
      ({ [dataOutProp]: data }) => {
        const allSeriesDates = getChartMinAndMaxDates(
          data,
          serie => serie.items,
        )

        const minDate = new Date(allSeriesDates.minDate)
        const seriesMaxInitialDay = getSeriesMaxInitialDay(minDate)

        const selectedLegendItems = R.pipe(
          R.map(item => ({
            ...item,
            // Filter items includes in the initial zoom
            items: R.filter(
              item => +new Date(item.date) < +seriesMaxInitialDay,
              item.items,
            ),
          })),
          // Sort items with the highest value
          R.sort(
            R.descend(
              R.pipe(
                R.prop('items'),
                R.pluck('value'),
                R.reduce(R.max, -Infinity),
              ),
            ),
          ),
          R.take(DEFAULT_MIN_LEGEND_ITEMS),
          item =>
            item.map(({ id }, i) => [id, { id, color: STATISTICS_COLORS[i] }]),
        )(data)

        return {
          selectedLegendItems: R.fromPairs(selectedLegendItems),
          availableColors: STATISTICS_COLORS.slice(selectedLegendItems.length),
        }
      },

      {
        [onLegendDeleteOutProp]: ({
          selectedLegendItems,
          availableColors,
        }) => id => ({
          selectedLegendItems: R.assoc(id, undefined, selectedLegendItems),
          availableColors: STATISTICS_COLORS.filter(
            color =>
              availableColors.includes(color) ||
              color === selectedLegendItems[id].color,
          ),
        }),

        [onLegendOptionChangeOutProp]: ({
          selectedLegendItems,
          availableColors,
        }) => ({ value }) => ({
          selectedLegendItems: R.assoc(
            value,
            { id: value, color: availableColors[0] },
            selectedLegendItems,
          ),
          availableColors: availableColors.slice(1),
        }),
      },
    ),

    withTranslations,
    withPropsOnChange(
      [dataOutProp, 'selectedLegendItems', 'i18nDate'],
      ({ [dataOutProp]: data, selectedLegendItems, i18nDate }) => ({
        [dataOutProp]: data
          .filter(({ id }) => !R.isNil(selectedLegendItems[id]))
          .map(data => ({
            ...data,
            color: selectedLegendItems[data.id].color,
          })),

        [legendOptionsOutProp]: data
          .filter(({ id }) => R.isNil(selectedLegendItems[id]))
          .map(({ id, name, date }) => ({
            label: date
              ? `${name} - ${i18nDate(date, DATE_FORMAT_OPTIONS)}`
              : name,
            value: id,
          })),
      }),
    ),
  )

export default withTimeSeriesGraphDynamicLegend
