import { compose, renderComponent, withProps } from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import withReviewSteps from './withReviewSteps'
import OrderModalReviewPredelivery from '../../ui/order-modals/OrderModalReviewPredelivery'
import OrderModalReviewPredeliveryApprove from './OrderModalReviewPredeliveryApprove'
import OrderModalReviewPredeliveryCorrect from './OrderModalReviewPredeliveryCorrect'
import { closeOrderModal } from '../../services/orders'
import withFetchOrderIfNeeded from '../common/withFetchOrderIfNeeded'

const enhance = compose(
  withFetchOrderIfNeeded({ modalTitleI18nKey: 'action:review-predelivery' }),

  withProps(({ orderData }) => ({
    mediaComment: R.path(['predeliveryPayload', 'comments'], orderData),

    // if Coobis download
    downloadUrl: R.path(['predeliveryPayload', 'urlFile', 'url'], orderData),
    downloadFileName: R.path(
      ['predeliveryPayload', 'urlFile', 'uploadedFile', 'clientFileName'],
      orderData,
    ),

    // If Dropbox/Wetransfer/...
    linkUrl: R.path(['predeliveryPayload', 'url'], orderData),
    linkText: R.path(['predeliveryPayload', 'url'], orderData),

    isExpress: R.path(['dates', 'express'], orderData),
  })),

  connect(
    null,
    {
      onCancelClick: closeOrderModal,
    },
  ),

  withReviewSteps(
    renderComponent(OrderModalReviewPredeliveryApprove),
    renderComponent(OrderModalReviewPredeliveryCorrect),
  ),
)

export default enhance(OrderModalReviewPredelivery)
