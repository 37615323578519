import React, { useRef } from 'react'
import {
  compose,
  withStateHandlers,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CartView from '../../ui/cart/Cart'
import { withCart } from '../../services/checkout/hocs'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { DELETE_CART } from '../../services/checkout/action-types'
import CartItem from './CartItem'
import withTotalCartPrice from '../checkout/withTotalCartPrice'
import {
  ROUTE_BRAND_CHECKOUT_MATCH,
  ROUTE_BRAND_CHECKOUT,
} from '../common/routes'
import { withIsVIPBrand } from '../../services/brand/hocs'
import { fetchCartExport } from '../../services/search'
import { RESOURCE_SERVICES_EXPORT_ENDPOINT } from '../../services/search/api'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import ActionConfirmationModal from '../../ui/modals/ActionConfirmationModal'
import Modal from '../../ui/modals/Modal'
import withTranslations from '../../hocs/withTranslations'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import ListInformationModal from '../../ui/modals/ListInformationModal'
import { SERVICE_TYPES } from '../../services/orders/business'

const Cart = ({
  i18n,
  history,
  cart,
  totalCartPrice,
  isOpen,
  handleClose,
  deleteCart,
  isDeletingCart,
  location,
  isVIPBrand,

  isDeleteCartModalOpen,
  openDeleteCartModal,
  closeDeleteCartModal,

  handleCartExportClick,

  servicesNotAcceptingWritingArticles,
  isNotAcceptingWritingArticlesModalOpen,
  closeNotAcceptingWritingArticlesModal,

  handleCheckoutClick,
}) => {
  const cartCloseRef = useRef()

  useOnClickOutside(cartCloseRef, handleClose)

  return (
    <div ref={cartCloseRef}>
      <CartView
        isClosed={!isOpen}
        handleClose={handleClose}
        handleEmpty={openDeleteCartModal}
        cartExportUrl={
          isVIPBrand ? RESOURCE_SERVICES_EXPORT_ENDPOINT : undefined
        }
        handleCartExportClick={handleCartExportClick}
        total={totalCartPrice}
        itemsNumber={cart && cart.items ? cart.items.length : undefined}
        hasCheckoutButton={!ROUTE_BRAND_CHECKOUT_MATCH.test(location.pathname)}
        handleCheckoutClick={handleCheckoutClick}
      >
        {cart && cart.items
          ? cart.items.map(cartItem => (
              <CartItem
                key={`${cartItem.id}-${cartItem.service}-${cartItem.words}-${cartItem.broadcast}`}
                cartItem={cartItem}
              />
            ))
          : undefined}
      </CartView>

      {isDeleteCartModalOpen && (
        <Modal onClose={closeDeleteCartModal}>
          <ActionConfirmationModal
            title={i18n('message:empty-cart-title')}
            description={i18n('message:empty-cart-description')}
            mainActionText={i18n('action:delete')}
            mainAction={deleteCart}
            secondaryAction={closeDeleteCartModal}
            isButton2Loading={isDeletingCart}
          />
        </Modal>
      )}

      {isNotAcceptingWritingArticlesModalOpen && (
        <Modal onClose={closeNotAcceptingWritingArticlesModal}>
          <ListInformationModal
            title={i18n('noun:media-information')}
            description1={i18n('message:webs-refuse-article', {
              webs: servicesNotAcceptingWritingArticles.length,
            })}
            listItems={servicesNotAcceptingWritingArticles}
            description2={i18n('message:include-article')}
            onClose={closeNotAcceptingWritingArticlesModal}
            onContinue={_ => {
              history.push(ROUTE_BRAND_CHECKOUT.linkTo())
              closeNotAcceptingWritingArticlesModal()
              handleClose()
            }}
          />
        </Modal>
      )}
    </div>
  )
}

const enhance = compose(
  withTranslations,
  withRouter,
  withCart,
  withTotalCartPrice,
  withIsVIPBrand,

  withIsRequestPending(DELETE_CART, 'isDeletingCart'),
  withIsRequestSucceeded(DELETE_CART, 'hasDeletedCart'),
  withDeleteRequestOnUnmount(DELETE_CART),

  withStateHandlers(
    {
      isDeleteCartModalOpen: false,
      isNotAcceptingWritingArticlesModalOpen: undefined,
    },
    {
      openDeleteCartModal: _ => _ => ({
        isDeleteCartModalOpen: true,
      }),
      closeDeleteCartModal: _ => _ => ({
        isDeleteCartModalOpen: false,
      }),
      openNotAcceptingWritingArticlesModal: _ => _ => ({
        isNotAcceptingWritingArticlesModalOpen: true,
      }),
      closeNotAcceptingWritingArticlesModal: _ => _ => ({
        isNotAcceptingWritingArticlesModalOpen: false,
      }),
    },
  ),

  withPropsOnChange(['cart'], ({ cart }) => ({
    servicesNotAcceptingWritingArticles:
      cart && cart.items
        ? cart.items
            .filter(
              item =>
                item.service === SERVICE_TYPES.WEB_POST &&
                !item.acceptsWritingArticles,
            )
            .map(item => item.name)
        : [],
  })),
  connect(
    null,
    { fetchCartExport },
  ),
  withHandlers({
    handleCheckoutClick: props => e => {
      if (props.servicesNotAcceptingWritingArticles.length > 0) {
        e.preventDefault()
        e.stopPropagation()

        props.openNotAcceptingWritingArticlesModal()
      } else {
        props.handleClose()
      }
    },
    handleCartExportClick: props => e => {
      e.preventDefault()
      props.fetchCartExport(props.cart.items)
    },
  }),

  lifecycle({
    componentDidUpdate(prev) {
      const { hasDeletedCart, closeDeleteCartModal } = this.props

      if (prev.hasDeletedCart === false && hasDeletedCart === true) {
        closeDeleteCartModal()
      }
    },
  }),
)

export default enhance(Cart)
