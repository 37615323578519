import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

const CATEGORIES_ENDPOINT = `${envConfig.universalBackendUrl}/categories`
const COUNTRIES_ENDPOINT = `${envConfig.universalBackendUrl}/countries-provinces`
const CURRENCIES_ENDPOINT = `${envConfig.universalBackendUrl}/currencies`

export function* fetchCategories() {
  const response = yield call(getRequest, CATEGORIES_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchCountries() {
  const response = yield call(getRequest, COUNTRIES_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchCurrencies() {
  const response = yield call(getRequest, CURRENCIES_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}
