import React from 'react'
import PropTypes from 'prop-types'

const PrivacyLinks = ({ privacyText, privacyLink, termsText, termsLink }) => {
  return (
    <div className="privacy-links">
      <ul>
        <li>
          <a href={privacyLink} target="_blank" rel="noopener noreferrer">
            {privacyText}
          </a>
        </li>
        <li>
          <a href={termsLink} target="_blank" rel="noopener noreferrer">
            {termsText}
          </a>
        </li>
      </ul>
    </div>
  )
}

PrivacyLinks.propTypes = {
  privacyText: PropTypes.string,
  privacyLink: PropTypes.string,
  termsText: PropTypes.string,
  termsLink: PropTypes.string,
}

export default PrivacyLinks
