import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../avatar/Avatar'
import { renderDateTimeValue } from '../../app/utils'
import withTranslations from '../../hocs/withTranslations'

const HistoryItem = ({
  username,
  children,
  photoURL,
  date,
  titleSlot,
  i18nDate,
  i18nTime,
}) => {
  return (
    <div className="history-item">
      <Avatar image={photoURL} avatarName={username} />
      <div className="history-item-content">
        <div className="history-item-header">
          <div className="history-item-title">{titleSlot}</div>
          <div className="history-item-date">
            {renderDateTimeValue(i18nDate, i18nTime, date)}
          </div>
        </div>
        {children && <div className="history-item-body">{children}</div>}
      </div>
    </div>
  )
}

HistoryItem.propTypes = {
  photoUrl: PropTypes.string,
  date: PropTypes.string,
  username: PropTypes.string,
  titleSlot: PropTypes.node,
  children: PropTypes.node,
}

export default withTranslations(HistoryItem)
