import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import HackFormattedMoney from '../HackFormattedMoney'
import Spinner from '@redradix/components.spinner'
import Button from '@redradix/components.button'
import { ReactComponent as Spin } from '../../styles/icons/spinner.svg'
import { isNilOrEmpty, moneyToFloat } from '../../app/utils'

const AddFunds = ({ balance, i18n, onClick, isMedia }) => {
  return (
    <div className="add-funds">
      <div className="add-funds-actual">
        {isNilOrEmpty(balance) ? (
          <Spinner isLoading>
            <Spin className="add-funds-icon" />
          </Spinner>
        ) : (
          <HackFormattedMoney value={balance} />
        )}
      </div>
      {balance && (
        <Button
          onClick={onClick}
          hierarchy="tertiary"
          isDisabled={moneyToFloat(balance) === 0 && isMedia || !isMedia}
        >
          {isMedia ? i18n('action:withdraw-funds') : i18n('action:add-funds')}
        </Button>
      )}
    </div>
  )
}

AddFunds.propTypes = {
  balance: CoobisPropTypes.money,
  onClick: PropTypes.func,
  isMedia: PropTypes.bool,
}

export default withTranslations(AddFunds)
