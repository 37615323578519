import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const WelcomeDashboardWrapper = ({
  title,
  children,
  hasBoldTitle,
  isTopSpaced,
}) => {
  const classes = cx('welcome-dashboard', {
    'is-top-spaced': isTopSpaced,
  })
  const titleClasses = cx('welcome-dashboard-title', {
    'is-bold': hasBoldTitle,
  })
  return (
    <div className={classes}>
      {title && <h1 className={titleClasses}>{title}</h1>}
      <div className="welcome-dashboard-content">{children}</div>
    </div>
  )
}

WelcomeDashboardWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isTopSpaced: PropTypes.bool,
  hasBoldTitle: PropTypes.bool,
}

export default WelcomeDashboardWrapper
