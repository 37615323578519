export default {
  env: 'testing',
  authBackendUrl: 'https://auth-backend.coobis.test.antevenio.com',
  usersBackendUrl: 'https://users-backend.coobis.test.antevenio.com',
  searchBackendUrl: 'https://search-backend.coobis.test.antevenio.com',
  universalBackendUrl: 'https://universal-backend.coobis.test.antevenio.com',
  socialBackendUrl: 'https://social-backend.coobis.test.antevenio.com',
  affiliateLink: (userId, locale) =>
    `https://coobis.test.antevenio.com/${locale}/b/${userId}`,
  stripeKey:
    'pk_test_51K1oE4Bjjw7KEUdBroQFtACAn4yc9FI3E0HK0d3Sny01ZN1RRzJA25wgdiKW2rJYeqN9L8WxyF9Qe02e1T2A5vto00JXcugt6d',
  hasDebug: false,
  bannerIframeUrl: 'https://tracker.coobis.test.antevenio.com/banner/banner.html',
}
