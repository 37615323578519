import { compose, withPropsOnChange, withProps } from 'recompose'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { getCategories } from '../services/config'
import withTranslations from './withTranslations'

const CATEGORIES_PROP = 'withCategoriesSelectField/categories'

const withCategoriesSelectField = ({
  valuesProp = 'values',
  categoryOptionsProp = 'categoryOptions',
  categoryValueField = 'category',
} = {}) =>
  compose(
    withTranslations,

    connect(state => ({
      [CATEGORIES_PROP]: getCategories(state),
    })),

    withPropsOnChange(
      [CATEGORIES_PROP, 'i18n'],
      ({ i18n, locale, ...props }) => ({
        [categoryOptionsProp]: R.pipe(
          R.values,
          R.map(item => ({
            value: item.id,
            label: R.pathOr(item.id, ['translations', locale], item),
            isDisabled: false,
          })),
          R.sort((a, b) => a.label.localeCompare(b.label)),
        )(props[CATEGORIES_PROP]),
      }),
    ),

    withPropsOnChange([categoryOptionsProp, valuesProp, 'i18n'], props => {
      const values = props[valuesProp]

      const categoryValue = values[categoryValueField]
        ? R.find(
            R.propEq('value', values[categoryValueField]),
            props[categoryOptionsProp],
          )
        : null

      return {
        [valuesProp]: {
          ...values,
          [categoryValueField]: categoryValue,
        },
      }
    }),

    withProps(R.dissoc(CATEGORIES_PROP)),
  )

export default withCategoriesSelectField
