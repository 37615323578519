import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Input } from '@redradix/components.input'
import { ReactComponent as Upload } from '../../styles/icons/upload.svg'
import { ReactComponent as Delete } from '../../styles/icons/delete.svg'
import useRandomId from '../../hooks/useRandomId'

const handleStopPropagation = e => e.stopPropagation()

const InputFile = ({
  errorText,
  fileName,
  id: userId,
  isTouched,
  label,
  linkText,
  linkUrl,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  widthBehaviour,
  isUploaded,
  accept,
  ...props
}) => {
  // Required id
  const randomId = useRandomId()
  const id = userId || randomId

  const inputFileRef = useRef()

  const triggerFileInputClick = useCallback(
    () => inputFileRef.current.click(),
    [inputFileRef],
  )

  const handleChange = useCallback(
    e => {
      // Do not trigger onChange when cancelling the file selection
      // Needed to maintain the current select file (after all, it's cancel, not
      // clear)
      if (e.target.files.length > 0) {
        onChange(e)
      }

      // Need this in order to upload without reload the page
      e.target.value = ''

      onBlur(e)
    },
    [onChange, onBlur],
  )

  const handleClear = useCallback(
    e => {
      e.stopPropagation()

      onChange({
        target: { name, files: [null] },
      })

      onBlur({
        target: { name },
      })

      e.target.value = ''
    },

    [onChange, onBlur, name],
  )

  const classes = cx('input-file', {
    'has-error': !!errorText,
    'has-uploaded-file': isUploaded,
  })

  return (
    <div className={classes} onClick={triggerFileInputClick}>
      <input
        type="file"
        id={id}
        name={name}
        className="hidden-input-file"
        ref={inputFileRef}
        onChange={handleChange}
        accept={accept}
        hidden
      />
      <Input
        {...props}
        errorText={errorText}
        label={label}
        placeholder={placeholder}
        value={fileName}
        disabled
        renderRightIcon={props =>
          fileName ? (
            <Delete {...props} onClick={handleClear} />
          ) : (
            <Upload {...props} />
          )
        }
        widthBehaviour={widthBehaviour}
        isTouched={isTouched}
      />
      {isUploaded && (
        <a
          href={linkUrl}
          className="anchor-link"
          title={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleStopPropagation}
        >
          {linkText}
        </a>
      )}
    </div>
  )
}

InputFile.propTypes = {
  linkText: PropTypes.string,
  /** String that defines the error text of the <input> */
  errorText: PropTypes.node,
  /** String that defines the file title loaded */
  fileName: PropTypes.string,
  /** String that specifies the helper text of the <input> */
  id: PropTypes.string,
  /** String with the text of the <label> */
  label: PropTypes.string,
  /** String that specifies the name of an <input> */
  name: PropTypes.string.isRequired,
  /** Function that is triggered when the <input> field loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when the <input> is changed */
  onChange: PropTypes.func,
  /** String that describes the expected value of the <input> */
  placeholder: PropTypes.string,
  /** String or number that specifies the value of the <input> */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** String that defines the behaviour of the input width */
  widthBehaviour: PropTypes.oneOf(['fixed', 'full']),
  /** String that defined the accepted files in input file */
  accept: PropTypes.string,
  isUploaded: PropTypes.bool,
  isTouched: PropTypes.bool,
}

export default InputFile
