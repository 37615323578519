import React from 'react'
import PropTypes from 'prop-types'

import { AuthScope } from '../services/auth/business'
import { Input } from '@redradix/components.input'
import Button from '@redradix/components.button'
import AnchorLink from './texts/AnchorLink'
import FormattedFormErrors from './FormattedFormErrors'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../hocs/withFormErrors'
import CoobisPropTypes from '../app/common/coobis-prop-types'
import withTranslations from '../hocs/withTranslations'
import { ROUTE_ROOT } from '../app/common/routes'
import WarningMessage from './messages/WarningMessage'
import RecaptchaTermsConditions from './RecaptchaTermsConditions'

const PasswordRecoveryRequest = ({
  needsScope,
  handleRecoveryWithScope,
  handleClose,
  isRecoveryPending,
  isRecoveryAsMediaPending,
  isRecoveryAsBrandPending,
  isSucceeded,
  // withTranslations
  i18n,
  // withFormErrors
  validationMessages,
  globalMessages,
  // withFormik
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
}) => {
  let content

  if (needsScope) {
    const handleScope = scope => e => {
      e.preventDefault()
      handleRecoveryWithScope(values.email, scope)
    }

    content = (
      <>
        <div className="action-group">
          <div className="action">
            <Button
              onClick={handleScope(AuthScope.BRAND)}
              isDisabled={isRecoveryAsMediaPending}
              isLoading={isRecoveryAsBrandPending}
            >
              {i18n('password-recovery:as-brand')}
            </Button>
          </div>
          <div className="action">
            <Button
              onClick={handleScope(AuthScope.MEDIA)}
              isDisabled={isRecoveryAsBrandPending}
              isLoading={isRecoveryAsMediaPending}
            >
              {i18n('password-recovery:as-media')}
            </Button>
          </div>
        </div>
      </>
    )
  } else if (isSucceeded) {
    content = (
      <>
        <p>{i18n('password-recovery:request-succeeded')}</p>
        <p>
          <AnchorLink
            linkUrl={ROUTE_ROOT.linkTo()}
            text={i18n('password-reset:action-close')}
          />
        </p>
      </>
    )
  } else {
    content = (
      <>
        <Input
          type="email"
          name="email"
          widthBehaviour="full"
          label={i18n('label:email')}
          placeholder={i18n('label:email')}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isRecoveryPending}
          isTouched={touched.email}
          errorText={
            validationMessages.email && (
              <FormattedFormErrors errors={validationMessages.email} />
            )
          }
        />

        {globalMessages && (
          <WarningMessage
            text={<FormattedFormErrors errors={globalMessages} />}
          />
        )}

        <Button
          type="submit"
          widthBehaviour="full"
          hierarchy="tertiary"
          isLoading={isRecoveryPending}
        >
          {i18n('password-recovery:action-password-recovery')}
        </Button>

        <RecaptchaTermsConditions i18n={i18n} />

        <p>
          <AnchorLink
            linkUrl={ROUTE_ROOT.linkTo()}
            text={i18n('password-reset:action-close')}
          />
        </p>
      </>
    )
  }

  return (
    <div className="login-form-wrapper is-public">
      <form onSubmit={handleSubmit} className="public-form" noValidate>
        <h2>{i18n('password-recovery:password-recovery')}</h2>

        {content}
      </form>
    </div>
  )
}

const FORM_VALUES_PROPTYPES = {
  email: PropTypes.string,
}

PasswordRecoveryRequest.propTypes = {
  handleRecoveryWithScope: PropTypes.func.isRequired,
  needsScope: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isRecoveryPending: PropTypes.bool.isRequired,
  isRecoveryAsMediaPending: PropTypes.bool.isRequired,
  isRecoveryAsBrandPending: PropTypes.bool.isRequired,
  isSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(PasswordRecoveryRequest)
