import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CardPlaceholder from './CardPlaceholder'
import noAffiliatesImg from './images/noAffiliates.png'

const NoAffiliatesCard = ({ i18n }) => {
  return (
    <div className="no-affiliates-card">
      <CardPlaceholder>
        <div className="no-affiliates-card-image">
          <picture>
            <img src={noAffiliatesImg} alt="Decoration" />
          </picture>
        </div>
        <div className="no-affiliates-card-texts">
          <p className="no-affiliates-card-title">
            {i18n('affiliates:no-affiliates')}
          </p>
          <p className="no-affiliates-card-description">
            {i18n('affiliates:start-sharing-link')}
          </p>
        </div>
      </CardPlaceholder>
    </div>
  )
}

NoAffiliatesCard.propTypes = {
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(NoAffiliatesCard)
