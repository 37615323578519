import { buffers } from 'redux-saga'
import { race, take, actionChannel, put, delay } from 'redux-saga/effects'
import * as hash from 'reduken/hash'
import * as actionTypes from './action-types'
import {
  DOMAIN,
  HASH_KEY_TOAST_DATA,
  TOAST_AUTO_CLOSE_MSECS,
  TOAST_TRANSITION_MSECS,
} from './constants'

export default function* addToastTaskSaga() {
  // Create a channel so we don't miss any ADD_TOAST actions
  const toastChannel = yield actionChannel(
    actionTypes.ADD_TOAST,
    // Allow dynamic buffer, since it can overflow if there are multiple toasts in a row
    buffers.expanding(),
  )

  while (true) {
    const {
      payload: { type, title, description, values },
    } = yield take(toastChannel)

    // Show toast
    yield put(
      hash.set(DOMAIN, HASH_KEY_TOAST_DATA, {
        type,
        title,
        description,
        values,
        isVisible: false,
      }),
    )

    // Wait for toast to mount to start animating
    yield delay(TOAST_TRANSITION_MSECS)

    // Start open animation
    yield put(
      hash.merge(DOMAIN, HASH_KEY_TOAST_DATA, {
        isVisible: true,
      }),
    )

    // Wait for 5 seconds or until user clicks close button
    yield race([
      take(actionTypes.CLOSE_TOAST),
      delay(
        TOAST_TRANSITION_MSECS /* Wait for animation to finish */ +
          TOAST_AUTO_CLOSE_MSECS,
      ),
    ])

    // Start close animation
    yield put(
      hash.merge(DOMAIN, HASH_KEY_TOAST_DATA, {
        isVisible: false,
      }),
    )

    // Wait for close animation to finish
    yield delay(TOAST_TRANSITION_MSECS)

    // Hide toast
    yield put(hash.remove(DOMAIN, HASH_KEY_TOAST_DATA))
  }
}
