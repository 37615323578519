import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import Paragraph from './../texts/Paragraph'
import AnchorLink from './../texts/AnchorLink'
import PreformattedText from '../texts/PreformattedText'

const OrderModalReviewPredelivery = ({
  mediaComment,
  linkText,
  linkUrl,
  downloadFileName,
  downloadUrl,
  onCorrectClick,
  onApproveClick,
  i18n,
}) => {
  return (
    <div className="order-modal">
      <ModalSimpleContent
        doubleActions
        action1Text={i18n('action:go-to-amend')}
        action1={onCorrectClick}
        action2Text={i18n('action:go-to-approve')}
        action2={onApproveClick}
        buttonVariant2="default"
      >
        <ModalHeader
          title={i18n('action:review-predelivery')}
          tagline={i18n('order-modal:predelivery-review-tagline')}
        />

        {!!mediaComment && (
          <Paragraph title={i18n('noun:media-comment')}>
            <PreformattedText text={mediaComment} />
          </Paragraph>
        )}

        {downloadFileName || downloadUrl ? (
          <Paragraph title={i18n('order-modal:predelivery-file')}>
            <AnchorLink
              text={downloadFileName}
              linkUrl={downloadUrl}
              download={downloadFileName}
              isNative
            />
          </Paragraph>
        ) : null}

        {linkText || linkUrl ? (
          <Paragraph title={i18n('order-modal:predelivery-link')}>
            <AnchorLink text={linkText} linkUrl={linkUrl} isNewTab isNative />
          </Paragraph>
        ) : null}
      </ModalSimpleContent>
    </div>
  )
}

OrderModalReviewPredelivery.propTypes = {
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  downloadFileName: PropTypes.string,
  downloadUrl: PropTypes.string,
  onApproveClick: PropTypes.func,
  onCorrectClick: PropTypes.func,
  mediaComment: PropTypes.string,
  // withTranslations
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderModalReviewPredelivery)
