import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

const FETCH_MEDIA_AFFILIATES_ENDPOINT = `${envConfig.usersBackendUrl}/media/affiliates`

export function* fetchAffiliates() {
  const response = yield call(getRequest, FETCH_MEDIA_AFFILIATES_ENDPOINT, {
    parseJSON: true,
  })
  const parsedResponse = parseResponseErrors(response)
  return parsedResponse
}
