import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { postFormRequest, deleteRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'
import { i18nErrorKeys } from '../../app/common/validation'
import { AuthScope } from '../auth/business'

const photoEndpoint = scope => `${envConfig.usersBackendUrl}/${scope}/photo`

const MAP_GLOBAL_ERRORS_UPLOAD_PHOTO = {
  [AuthScope.BRAND]: {
    BrandNotFoundException: values => ({
      key: i18nErrorKeys.BRAND_NOT_FOUND,
      values,
    }),
  },
  [AuthScope.MEDIA]: {
    MediaNotFoundException: values => ({
      key: i18nErrorKeys.MEDIA_NOT_FOUND,
      values,
    }),
  },
}

export function* uploadPhoto(scope, file) {
  const response = yield call(
    postFormRequest,
    photoEndpoint(scope),
    { photo: file },
    { parseJSON: false },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_UPLOAD_PHOTO[scope],
  })

  return parsedResponse
}

const MAP_GLOBAL_ERRORS_DELETE_PHOTO = {
  [AuthScope.BRAND]: {
    BrandNotFoundException: values => ({
      key: i18nErrorKeys.BRAND_NOT_FOUND,
      values,
    }),
    BrandPhotoNotFoundException: values => ({
      key: i18nErrorKeys.PHOTO_NOT_FOUND,
      values,
    }),
  },
  [AuthScope.MEDIA]: {
    MediaNotFoundException: values => ({
      key: i18nErrorKeys.MEDIA_NOT_FOUND,
      values,
    }),
    MediaPhotoNotFoundException: values => ({
      key: i18nErrorKeys.PHOTO_NOT_FOUND,
      values,
    }),
  },
}

export function* deletePhoto(scope) {
  const response = yield call(deleteRequest, photoEndpoint(scope), undefined, {
    parseJSON: false,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ERRORS_DELETE_PHOTO[scope],
  })

  return parsedResponse
}
