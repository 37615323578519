import { compose, withProps, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import copy from 'clipboard-copy'
import {
  MEDIA_AFFILIATES_COMISSION,
  BRAND_AFFILIATES_COMISSION,
} from '../../services/affiliates/constants'
import { getMyInfo, getLanguage } from '../../services/me'
import Affiliates from '../../ui/affiliates/Affiliates'
import envConfig from '../../environments'
import {
  getTwitterShareLink,
  getFacebookShareLink,
} from '../../services/affiliates/business'
import withTranslations from '../../hocs/withTranslations'
import { withFetchAffiliates } from '../../services/affiliates/hocs'
import { getMyAffiliates } from '../../services/affiliates'

const enhance = compose(
  connect(state => {
    const myInfo = getMyInfo(state)
    const language = getLanguage(state)
    const affiliates = getMyAffiliates(state)
    if (!myInfo || !language) {
      return {}
    }
    return {
      userId: myInfo.id,
      affiliates: affiliates,
      language,
    }
  }),
  withFetchAffiliates,
  withProps(props => ({
    affiliates: props.affiliates.map(affiliate => ({
      ...affiliate,
      createdAt: new Date(affiliate.createdAt),
    })),
  })),

  withPropsOnChange([], () => ({
    mediaComission: MEDIA_AFFILIATES_COMISSION,
    brandComission: BRAND_AFFILIATES_COMISSION,
  })),

  withPropsOnChange(['userId', 'language'], ({ userId, language }) => ({
    sharingLinkText:
      !R.isNil(userId) && !R.isNil(language)
        ? envConfig.affiliateLink(userId, language)
        : '',
  })),

  withTranslations,
  withPropsOnChange(
    ['sharingLinkText', 'i18n'],
    ({ sharingLinkText, i18n }) => ({
      facebookLinkUrl: getFacebookShareLink(sharingLinkText),
      twitterLinkUrl: getTwitterShareLink(
        sharingLinkText,
        i18n('twitter-share:text'),
      ),
    }),
  ),

  withHandlers({
    onCopyClick: props => _ => {
      copy(props.sharingLinkText)
    },
  }),
)

export default enhance(Affiliates)
