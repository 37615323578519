import React from 'react'
import envConfig from '../../environments'

const CampaignsDashboardBannerIframe = () => {
  return (
    <iframe
      className="campaigns-dashboard-content-banner-iframe"
      src={envConfig.bannerIframeUrl}
      title="banner"
    ></iframe>
  )
}

export default CampaignsDashboardBannerIframe
