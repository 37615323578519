import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@redradix/components.checkbox'
import { safelyRender } from '../../../src/app/utils'
import cx from 'classnames'

const PaperCheckbox = ({
  checked,
  children,
  id,
  isDisabled,
  isFocused,
  isTouched,
  name,
  onBlur,
  onChange,
  onClick,
  value,
  renderLabelIcon,
  errorText,
  helperText,
}) => {
  const showError = isTouched && Boolean(errorText)
  return (
    <div className={cx('paper', { 'has-error': showError })}>
      <Checkbox
        checked={checked}
        id={id}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isTouched={isTouched}
        key={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        value={value}
        errorText={errorText}
      >
        {safelyRender(renderLabelIcon, {
          className: 'rdx-icon',
        })}
        <div className="paper-text">{children}</div>
        {helperText && !showError && (
          <div className="rdx-input-field-helper">{helperText}</div>
        )}
        {showError && <div className="rdx-input-field-helper">{errorText}</div>}
      </Checkbox>
    </div>
  )
}

PaperCheckbox.propTypes = {
  /** Boolean indicating whether the checkbox is checked */
  checked: PropTypes.bool.isRequired,
  /** Content wrapped for the label text */
  children: PropTypes.node,
  /** String that defines the checkbox id */
  id: PropTypes.string.isRequired,
  /** Boolean indicating whether the checkbox should be rendered as disabled */
  isDisabled: PropTypes.bool,
  /** Boolean indicating whether the checkbox is focused */
  isFocused: PropTypes.bool,
  /** Boolean indicating whether the checkbox should be rendered as touched */
  isTouched: PropTypes.bool,
  /** String that defines the checkbox name */
  name: PropTypes.string.isRequired,
  /** Function that is triggered when the checkbox is blured */
  onBlur: PropTypes.func,
  /** Function that is triggered when the checkbox is changing */
  onChange: PropTypes.func,
  /** Function that is triggered when the checkbox is clicked */
  onClick: PropTypes.func,
  /** Property indicating the value of the component */
  value: PropTypes.any,
  /** Property indicating the value of the component */
  errorText: PropTypes.string,
  /** String that defines the helper text  */
  helperText: PropTypes.string,
}

export default PaperCheckbox
