import { all, takeLatest, select, put } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import { set } from 'reduken/hash'
import { getCurrentLocation } from './selectors'
import {
  DOMAIN,
  HASH_KEY_CURRENT_LOCATION,
  HASH_KEY_LAST_LOCATION,
} from './constants'

function* locationChangeSaga({ payload: { location, action } }) {
  const lastCurrentLocation = yield select(getCurrentLocation)

  switch (action) {
    case 'POP':
    case 'PUSH': {
      yield put(set(DOMAIN, HASH_KEY_CURRENT_LOCATION, location))
      yield put(set(DOMAIN, HASH_KEY_LAST_LOCATION, lastCurrentLocation))
      return
    }

    case 'REPLACE': {
      yield put(set(DOMAIN, HASH_KEY_CURRENT_LOCATION, location))
      return
    }

    default: {
      throw new Error('Unreachable')
    }
  }
}

export default function*() {
  yield all([takeLatest(LOCATION_CHANGE, locationChangeSaga)])
}
