import { compose, withStateHandlers, branch } from 'recompose'

const REVIEW_STEPS = {
  REVIEW: 'REVIEW',
  APPROVE: 'APPROVE',
  CORRECT: 'CORRECT',
}

const withReviewSteps = (approveBranch, correctBranch) =>
  compose(
    withStateHandlers(
      () => ({
        reviewStep: REVIEW_STEPS.REVIEW,
      }),
      {
        onApproveClick: _ => _ => ({
          reviewStep: REVIEW_STEPS.APPROVE,
        }),
        onCorrectClick: _ => _ => ({
          reviewStep: REVIEW_STEPS.CORRECT,
        }),
      },
    ),

    branch(
      ({ reviewStep }) => reviewStep === REVIEW_STEPS.APPROVE,
      approveBranch,
    ),

    branch(
      ({ reviewStep }) => reviewStep === REVIEW_STEPS.CORRECT,
      correctBranch,
    ),
  )

export default withReviewSteps
