import { connect } from 'react-redux'
import { getRequestWithIdError } from '../services/communication/selectors'

const withRequestWithIdError = (
  requestType,
  { idProp = 'id', outProp = 'requestError' } = {},
) =>
  connect((state, ownProps) => ({
    [outProp]: getRequestWithIdError(requestType, ownProps[idProp], state),
  }))

export default withRequestWithIdError
