import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import CampaignPreviewFacebook from '../social-previews/CampaignPreviewFacebook'
import { isNilOrEmpty } from '../../../app/utils'

const CampaignDetailFacebookPublication = ({
  previews,
  i18n,
  // Metrics values
  engagementRate,
  reach,
  engagements,
  clicks,
  likes,
  comments,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        {engagementRate > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagementRate}
            label={i18n('metrics:engagement-rate')}
            isPercentage
          />
        )}
        {reach > 0 && (
          <AnalyticsKpi
            iconType="target"
            value={reach}
            label={i18n('metrics:reach')}
            isTruncated
          />
        )}
        {engagements > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagements}
            label={i18n('metrics:engagements')}
            isTruncated
          />
        )}
        {clicks > 0 && (
          <AnalyticsKpi
            iconType="click"
            value={clicks}
            label={i18n('metrics:clicks')}
            isTruncated
          />
        )}
        {likes > 0 && (
          <AnalyticsKpi
            iconType="like"
            value={likes}
            label={i18n('metrics:likes')}
            isTruncated
          />
        )}
        {comments > 0 && (
          <AnalyticsKpi
            iconType="comment"
            value={comments}
            label={i18n('metrics:comments')}
            isTruncated
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewFacebook items={previews} />
      ) : null}
    </>
  )
}

CampaignDetailFacebookPublication.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  engagementRate: PropTypes.number,
  reach: PropTypes.number,
  engagements: PropTypes.number,
  clicks: PropTypes.number,
  likes: PropTypes.number,
  comments: PropTypes.number,
}

export default withTranslations(CampaignDetailFacebookPublication)
