import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { SelectSimple } from '@redradix/components.select'
import { ReactComponent as ChevronDown } from '../../styles/icons/chevron-down.svg'
import { ReactComponent as ChevronUp } from '../../styles/icons/chevron-up.svg'

const CampaignSelector = ({
  options,
  errorText,
  value,
  isDisabled,
  isTouched,
  onBlur,
  onChange,
  onFocus,
  i18n,
}) => {
  return (
    <div className="campaign-selector">
      <SelectSimple
        id="campaign_selector"
        name="campaign_selector"
        options={options}
        errorText={errorText}
        isDisabled={isDisabled}
        isTouched={isTouched}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        renderDownIcon={props => <ChevronDown {...props} />}
        renderUpIcon={props => <ChevronUp {...props} />}
        value={value}
        placeholder={i18n('action:select-publication')}
      />
    </div>
  )
}

CampaignSelector.propTypes = {
  /** Array of pairs label, value with the options of the <select> */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
      imgPath: PropTypes.string,
      imgAlt: PropTypes.string,
    }),
  ),
  /** Object that defines the selected value of the <select> */
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /** String that defines the error text of the <select> */
  errorText: PropTypes.node,
  /** Boolean that defines whether the <select> is disabled */
  isDisabled: PropTypes.bool,
  /** Boolean that specifies whether the <select> field has been modified */
  isTouched: PropTypes.bool,
  /** Function that is triggered when the component loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when changes in the selection occurs */
  onChange: PropTypes.func,
  /** Function that is triggered when the component gets focus */
  onFocus: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(CampaignSelector)
