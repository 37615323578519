import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailTwitchPublication from '../../ui/campaigns/views/CampaignDetailTwitchPublication'
import withExportReportContents from './withExportReportContents'

const enhance = compose(
  withPropsOnChange(['stats'], ({ stats }) => ({
    averageViewers: stats.kpis.averageViewers,
    followers: stats.kpis.followers,
    maxViewers: stats.kpis.maxViewers,
    viewers: stats.kpis.viewers,
  })),
  withExportReportContents(),
)

export default enhance(CampaignDetailTwitchPublication)
