import React, { useState, useEffect } from 'react'
import {
  compose,
  withPropsOnChange,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose'
import { connect } from 'react-redux'
import { usePagination } from '@redradix/components.pagination'
import * as R from 'ramda'
import CampaignsDashboard from '../../ui/campaigns/CampaignsDashboard'
import {
  getBrandOrdersInProgress,
  fetchBrandOrdersInProgress,
  periodicFetchBrandOrdersInProgress,
  cancelPeriodicFetchBrandOrdersInProgress,
} from '../../services/orders'
import {
  getCampaignsSummary,
  fetchCampaignsSummary,
  getLastCreatedCampaignId,
  periodicFetchCampaignsSummary,
  cancelPeriodicFetchCampaignsSummary,
} from '../../services/campaigns'
import withEffect from '../../hocs/withEffect'
import { FETCH_CAMPAIGNS_SUMMARY } from '../../services/campaigns/action-types'
import withIsLoading from '../../hocs/withIsLoading'
import { FETCH_BRAND_ORDERS_IN_PROGRESS } from '../../services/orders/action-types'
import withOrders from './withOrders'
import { simplifyString } from '../utils'
import { openCreateCampaignModal } from '../../services/checkout'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import { SUBMIT_PAYMENT } from '../../services/checkout/action-types'
const ORDERS_PER_PAGE = 10
const CAMPAIGNS_PER_PAGE = 10

const CampaignsDashboardWithPagination = ({
  orders,
  campaigns,
  hadOrders,
  hadCampaigns,
  hadBanner,
  ...props
}) => {
  // Orders pagination
  const [ordersPage, setOrdersPage] = useState(1)

  const totalOrderItems = !!orders ? orders.length : 0

  const ordersPaginationProps = usePagination({
    page: ordersPage,
    onPageChange: setOrdersPage,
    totalItems: totalOrderItems,
    itemsPerPage: ORDERS_PER_PAGE,
  })

  // Limit page to total number of pages
  useEffect(() => {
    if (ordersPage > ordersPaginationProps.lastPage) {
      setOrdersPage(ordersPaginationProps.lastPage)
    } else if (ordersPage < 1 && totalOrderItems > 0) {
      setOrdersPage(1)
    }
  }, [
    ordersPaginationProps.lastPage,
    ordersPage,
    setOrdersPage,
    totalOrderItems,
  ])

  // Only show orders in current page
  const paginatedOrders = !!orders
    ? orders.slice(
        ordersPaginationProps.firstItemInPage - 1,
        ordersPaginationProps.lastItemInPage,
      )
    : orders

  // Campaigns pagination
  const [campaignsPage, setCampaignsPage] = useState(1)

  const totalCampaignItems = !!campaigns ? campaigns.length : 0

  const campaignsPaginationProps = usePagination({
    page: campaignsPage,
    onPageChange: setCampaignsPage,
    totalItems: totalCampaignItems,
    itemsPerPage: CAMPAIGNS_PER_PAGE,
  })

  // Limit page to total number of pages
  useEffect(() => {
    if (campaignsPage > campaignsPaginationProps.lastPage) {
      setCampaignsPage(campaignsPaginationProps.lastPage)
    } else if (campaignsPage < 1 && totalCampaignItems > 0) {
      setCampaignsPage(1)
    }
  }, [
    campaignsPaginationProps.lastPage,
    campaignsPage,
    setCampaignsPage,
    totalCampaignItems,
  ])

  // Only show orders in current page
  const paginatedCampaigns = !!campaigns
    ? campaigns.slice(
        campaignsPaginationProps.firstItemInPage - 1,
        campaignsPaginationProps.lastItemInPage,
      )
    : campaigns

  return (
    <CampaignsDashboard
      {...props}
      // Orders
      orders={paginatedOrders}
      ordersCurrentPage={
        // Limit page to [1, last page]
        Math.max(Math.min(ordersPage, ordersPaginationProps.lastPage), 1)
      }
      ordersTotalPages={ordersPaginationProps.lastPage}
      onOrdersPageClick={ordersPaginationProps.onPageClick}
      hadOrders={hadOrders}
      // Campaigns
      campaigns={paginatedCampaigns}
      campaignsCurrentPage={
        // Limit page to [1, last page]
        Math.max(Math.min(campaignsPage, campaignsPaginationProps.lastPage), 1)
      }
      campaignsTotalPages={campaignsPaginationProps.lastPage}
      onCampaignsPageClick={campaignsPaginationProps.onPageClick}
      hadCampaigns={hadCampaigns}
      hadBanner={hadBanner}
    />
  )
}

const withCurrentOrders = compose(withOrders({ inProp: 'orders' }))

const pendingCampaignsComparator = R.descend(R.prop('ordersPending'))

const recentCampaignsComparator = R.descend(R.prop('id'))

const withCampaignsSummary = compose(
  // Filter campaigns
  withState('campaignSearchValue', 'setCampaignSearchValue', ''),
  withIsRequestSucceeded(SUBMIT_PAYMENT, 'hadBanner'),
  withHandlers({
    onCampaignSearchChange: props => e => {
      props.setCampaignSearchValue(e.target.value)
    },
  }),

  withPropsOnChange(
    ['campaigns', 'campaignSearchValue'],
    ({ campaigns, campaignSearchValue }) => {
      const simpleSearchValue = simplifyString(campaignSearchValue)

      return {
        campaigns:
          !!campaigns && campaignSearchValue !== ''
            ? R.filter(
                campaign =>
                  // Campaign name
                  simplifyString(campaign.name).includes(simpleSearchValue) ||
                  // Resource names
                  campaign.resourceNames
                    .map(simplifyString)
                    .some(val => val.includes(simpleSearchValue)),
                campaigns,
              )
            : campaigns,
      }
    },
  ),

  // Sort campaigns
  withPropsOnChange(['campaigns'], ({ campaigns }) => ({
    campaigns: !!campaigns
      ? R.sortWith(
          [pendingCampaignsComparator, recentCampaignsComparator],
          campaigns,
        )
      : [],
  })),
)

const withCreateCampaigns = compose(
  connect(
    state => ({
      lastCreatedCampaignId: getLastCreatedCampaignId(state),
    }),
    { onCreateCampaignClick: openCreateCampaignModal },
  ),

  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        prevProps.lastCreatedCampaignId !== this.props.lastCreatedCampaignId
      ) {
        this.props.fetchCampaignsSummary()
      }
    },
  }),
)

const enhance = compose(
  connect(
    state => {
      const orders = getBrandOrdersInProgress(state)
      const campaigns = getCampaignsSummary(state)

      return {
        orders,
        campaigns,
        /**
         * hadOrders and hadCampaigns have 3 states
         * - true: orders/campaigns loaded and not empty (no spinner)
         * - false: orders/campaigns loaded but empty (no spinner)
         * - null: orders/campaigns not loaded (spinner)
         */
        hadOrders: R.isNil(orders) ? null : !R.isEmpty(orders),
        hadCampaigns: R.isNil(campaigns) ? null : !R.isEmpty(campaigns),
      }
    },
    {
      fetchBrandOrdersInProgress,
      fetchCampaignsSummary,
      periodicFetchBrandOrdersInProgress,
      periodicFetchCampaignsSummary,
      cancelPeriodicFetchBrandOrdersInProgress,
      cancelPeriodicFetchCampaignsSummary,
    },
  ),

  withEffect(
    ({
      periodicFetchBrandOrdersInProgress,
      periodicFetchCampaignsSummary,
      cancelPeriodicFetchBrandOrdersInProgress,
      cancelPeriodicFetchCampaignsSummary,
    }) => {
      periodicFetchBrandOrdersInProgress()
      periodicFetchCampaignsSummary()

      return () => {
        cancelPeriodicFetchBrandOrdersInProgress()
        cancelPeriodicFetchCampaignsSummary()
      }
    },
    ({
      periodicFetchBrandOrdersInProgress,
      periodicFetchCampaignsSummary,
      cancelPeriodicFetchBrandOrdersInProgress,
      cancelPeriodicFetchCampaignsSummary,
    }) => [
      periodicFetchBrandOrdersInProgress,
      periodicFetchCampaignsSummary,
      cancelPeriodicFetchBrandOrdersInProgress,
      cancelPeriodicFetchCampaignsSummary,
    ],
  ),

  withIsLoading(FETCH_BRAND_ORDERS_IN_PROGRESS, 'isLoadingOrders'),
  withIsLoading(FETCH_CAMPAIGNS_SUMMARY, 'isLoadingCampaigns'),
  withPropsOnChange(
    ['isLoadingOrders', 'isLoadingCampaigns', 'hadOrders', 'hadCampaigns'],
    ({ isLoadingOrders, isLoadingCampaigns, hadOrders, hadCampaigns }) => ({
      isLoading:
        (isLoadingOrders || isLoadingCampaigns) &&
        // For avoiding show spinner on each periodic fetch
        (R.isNil(hadOrders) || R.isNil(hadCampaigns)),
    }),
  ),

  withCurrentOrders,
  withCampaignsSummary,
  withCreateCampaigns,
)

export default enhance(CampaignsDashboardWithPagination)
