import { SERVICE } from './constants'

export const LOGIN = `${SERVICE}/LOGIN`
export const LOGIN_WITH_SCOPE = `${SERVICE}/LOGIN_WITH_SCOPE`
export const LOGOUT = `${SERVICE}/LOGOUT`
export const CHECK_TOKEN = `${SERVICE}/CHECK_TOKEN`
export const REFRESH_TOKEN = `${SERVICE}/REFRESH_TOKEN`
export const NEW_TOKEN = `${SERVICE}/NEW_TOKEN`
export const DELETE_TOKEN = `${SERVICE}/DELETE_TOKEN`
export const LOGOUT_COMPLETE = `${SERVICE}/LOGOUT_COMPLETE`
export const CHANGE_PASSWORD = `${SERVICE}/CHANGE_PASSWORD`
export const DELETE_USER = `${SERVICE}/DELETE_USER`
