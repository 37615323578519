import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getLastLocation } from '../../services/navigation/selectors'
import { ROUTE_BRAND_RESOURCES_MATCH } from './routes'

const ScrollToTop = ({ children, location, lastLocation }) => {
  const lastLocationPathname = lastLocation ? lastLocation.pathname : undefined

  useEffect(() => {
    // Do not scroll if we're only adding URL params in resource search
    if (
      ROUTE_BRAND_RESOURCES_MATCH.test(lastLocationPathname) &&
      ROUTE_BRAND_RESOURCES_MATCH.test(location.pathname)
    ) {
      return
    }

    window.scrollTo(0, 0)
  }, [location.pathname, lastLocationPathname])

  return <>{children}</>
}

const enhance = compose(
  withRouter,
  connect(state => ({
    lastLocation: getLastLocation(state),
  })),
)

export default enhance(ScrollToTop)
