import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { ROUTE_MEDIA_CREATE_RESOURCES_DISTRIBUTOR } from '../../app/common/routes'
import GlobalContent from '../shared/GlobalContent'
import Spinner from '../loaders/Spinner'
import InitialCard from '../cards/InitialCard'
import ResourcePlaceholder from '../cards/ResourcePlaceholder'
import ResourceCard from '../cards/ResourceCard'
import initialImage from './images/addResource.png'
import { isNilOrEmpty, toMomentOnlyDate } from '../../app/utils'
import { resourceAvatarURL } from '../../services/resources/business'
import ActionConfirmationModal from '../../ui/modals/ActionConfirmationModal'
import Modal from '../../ui/modals/Modal'
import FormattedFormErrors from '../FormattedFormErrors'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import { NumberPagination } from '@redradix/components.pagination'
import { usePagination } from '@redradix/components.pagination'

const ResourceListPanel = ({
  resources,
  resourcesMeta,
  fetchResources,
  isLoading,
  onEditResource,
  onDeleteResource,
  onUpdateResourceStats,
  areResourceStatsUpdating,
  i18n,

  isDeleteResourceModalOpen,
  openDeleteResourceModal,
  closeDeleteResourceModal,
  isDeletingResource,

  redirectToCreateResource,
  deleteResourceGlobalErrors,

  resourceToDelete,
}) => {
  const onDelete = useCallback(
    resource => () => openDeleteResourceModal(resource),
    [openDeleteResourceModal],
  )

  const isUpdatingStats = id => areResourceStatsUpdating[id]
  const hasError =
    deleteResourceGlobalErrors && deleteResourceGlobalErrors.length > 0

  const [resourcesPage, setResourcesPage] = useState(1)
  const { total: totalItems, limit: itemsPerPage } = resourcesMeta || {}
  const pagination = usePagination({
    page: resourcesPage,
    onPageChange: page => {
      fetchResources(page, itemsPerPage)
      setResourcesPage(page)
    },
    totalItems,
    itemsPerPage,
  })

  return (
    <GlobalContent title={i18n('noun:resources')} isTopSpaced>
      {isLoading ? (
        <Spinner size={60} text={i18n('status:loading-resources')} />
      ) : !isNilOrEmpty(resources) ? (
        <>
          <div className="resource-list">
            <ResourcePlaceholder onCardClick={redirectToCreateResource} />
            {resources.map(resource => (
              <ResourceCard
                key={resource.id}
                name={resource.name}
                actionText="Action"
                date={toMomentOnlyDate(resource.createdAt).format('DD.MM.YYYY')}
                image={resourceAvatarURL(resource.id)}
                type={resource.type}
                isUserVerified={resource.isVerified}
                onEdit={onEditResource(resource)}
                onDelete={onDelete(resource)}
                onUpdateStats={onUpdateResourceStats(resource.id)}
                isUpdatingStats={isUpdatingStats(resource.id)}
              />
            ))}
          </div>
          {resourcesMeta && (
            <NumberPagination
              currentPage={resourcesPage}
              totalPages={resourcesMeta.totalPages}
              onPageClick={pagination.onPageClick}
            />
          )}
        </>
      ) : (
        <InitialCard
          linkUrl={ROUTE_MEDIA_CREATE_RESOURCES_DISTRIBUTOR.linkTo()}
          linkText={i18n('action:add-first-resource')}
          imageBigUrl={initialImage}
          imageSmallUrl={initialImage}
        />
      )}

      {isDeleteResourceModalOpen && (
        <Modal onClose={closeDeleteResourceModal}>
          <ActionConfirmationModal
            title={
              resourceToDelete && resourceToDelete.workInProgressOrders > 0
                ? i18n(
                    'message:delete-resource-pending-orders-title',
                    resourceToDelete,
                  )
                : i18n('message:delete-resource-title')
            }
            description={
              resourceToDelete && resourceToDelete.workInProgressOrders > 0
                ? i18n(
                    'message:delete-resource-pending-orders-subtitle',
                    resourceToDelete,
                  )
                : i18n('message:delete-resource-subtitle')
            }
            mainActionText={i18n('action:delete')}
            mainAction={onDeleteResource}
            secondaryAction={closeDeleteResourceModal}
            isButton2Loading={isDeletingResource}
            hasError={hasError}
            errorText={
              hasError && (
                <FormattedFormErrors errors={deleteResourceGlobalErrors} />
              )
            }
          />
        </Modal>
      )}
    </GlobalContent>
  )
}

ResourceListPanel.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.string,
      type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
      isUserVerified: PropTypes.bool,
    }),
  ),
  isLoading: PropTypes.bool,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  onUpdateResourceStats: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceListPanel)
