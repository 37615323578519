import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { getCampaigns, fetchCampaigns, createCampaign } from './'
import { compose, withPropsOnChange, withHandlers, lifecycle } from 'recompose'
import withTranslations from '../../hocs/withTranslations'
import { ReactComponent as AddIcon } from '../../styles/icons/add.svg'
import { isNilOrEmpty } from '../../app/utils'
import { openCreateCampaignModal } from '../../services/checkout'
import { getLastCreatedCampaignId } from './selectors'

export const withCampaigns = connect(
  state => ({
    campaigns: getCampaigns(state),
  }),
  {
    fetchCampaigns,
    createCampaign,
  },
)

const CREATE_CAMPAIGN_SELECT_VALUE = 1010

export const withCampaignsSelectOptions = compose(
  withTranslations,

  withPropsOnChange(['campaigns', 'i18n'], ({ campaigns, i18n }) => ({
    campaignOptions: [
      {
        label: i18n('checkout-general-data:create-campaign'),
        value: CREATE_CAMPAIGN_SELECT_VALUE,
        isDisabled: false,
        renderIcon: props => <AddIcon {...props} />,
        isLink: true,
      },

      ...(!!campaigns
        ? campaigns.map(campaign => ({
            label: campaign.name,
            value: campaign.id,
            isDisabled: false,
          }))
        : []),
    ],
  })),

  withPropsOnChange(['values', 'campaigns'], ({ values, campaigns }) => {
    if (R.isNil(values.campaign)) {
      return {}
    }

    const selectedCampaign = campaigns.find(({ id }) => id === values.campaign)

    if (isNilOrEmpty(selectedCampaign)) {
      throw new Error(
        `Could not find selected campaign with id ${values.campaign}`,
      )
    }

    return {
      values: {
        ...values,
        campaign: {
          label: selectedCampaign.name,
          value: selectedCampaign.id,
          isDisabled: false,
        },
      },
    }
  }),

  connect(
    null,
    { openCreateCampaignModal },
  ),

  // Proxy handleChange to detect selection of "Create campaign" option
  withHandlers({
    handleChange: props => (...args) => {
      const e = args[0]

      // Only handle 'campaign' field, when selecting the "Create campaign" option
      if (
        e.target.name === 'campaign' &&
        e.target.value === CREATE_CAMPAIGN_SELECT_VALUE
      ) {
        // Open create campaign modal
        props.openCreateCampaignModal()

        // Don't select new value
        return
      }

      // Otherwise, handle normally
      return props.handleChange(...args)
    },
  }),
)

export const withSelectCampaignAfterCreation = compose(
  connect(state => ({
    lastCreatedCampaignId: getLastCreatedCampaignId(state),
  })),

  lifecycle({
    componentDidUpdate(prevProps) {
      // If last-created-campaign-id does not match previous known value, it
      // means a new campaign has been created
      if (
        prevProps.lastCreatedCampaignId !== this.props.lastCreatedCampaignId
      ) {
        // Select new created campaign
        this.props.setFieldValue(
          'campaign',
          this.props.lastCreatedCampaignId,
          true,
        )
        this.props.setFieldTouched('campaign', true, true)
      }
    },
  }),
)
