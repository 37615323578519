import { withStateHandlers } from 'recompose'

const withCardPaymentHandlers = withStateHandlers(
  () => ({
    cardBrand: 'unknown',
    cardNumberError: undefined,
    cardNumberComplete: false,
    cardExpirationError: undefined,
    cardExpirationComplete: false,
    cardCVCError: undefined,
    cardCVCComplete: false,
  }),
  {
    onCardNumberChange: _ => ({ brand, complete, error }) => ({
      cardBrand: brand,
      cardNumberError: error ? error.message : undefined,
      cardNumberComplete: complete,
    }),

    onCardNumberBlur: (
      { cardNumberError, cardNumberComplete },
      { i18n },
    ) => _ => ({
      cardNumberError:
        cardNumberError ||
        (!cardNumberComplete
          ? i18n('errors:required', { path: 'cardNumber' })
          : undefined),
    }),

    onCardExpirationChange: _ => ({ complete, error }) => ({
      cardExpirationError: error ? error.message : undefined,
      cardExpirationComplete: complete,
    }),

    onCardExpirationBlur: (
      { cardExpirationError, cardExpirationComplete },
      { i18n },
    ) => _ => ({
      cardExpirationError:
        cardExpirationError ||
        (!cardExpirationComplete
          ? i18n('errors:required', { path: 'cardExpiration' })
          : undefined),
    }),

    onCardCVCChange: _ => ({ complete, error }) => ({
      cardCVCError: error ? error.message : undefined,
      cardCVCComplete: complete,
    }),

    onCardCVCBlur: ({ cardCVCError, cardCVCComplete }, { i18n }) => _ => ({
      cardCVCError:
        cardCVCError ||
        (!cardCVCComplete
          ? i18n('errors:required', { path: 'cardCVC' })
          : undefined),
    }),

    showRequiredErrors: (state, { i18n }) => _ => ({
      cardNumberError:
        state.cardNumberError ||
        (!state.cardNumberComplete
          ? i18n('errors:required', { path: 'cardNumber' })
          : undefined),
      cardExpirationError:
        state.cardExpirationError ||
        (!state.cardExpirationComplete
          ? i18n('errors:required', { path: 'cardExpiration' })
          : undefined),
      cardCVCError:
        state.cardCVCError ||
        (!state.cardCVCComplete
          ? i18n('errors:required', { path: 'cardCVC' })
          : undefined),
    }),
  },
)

export default withCardPaymentHandlers
