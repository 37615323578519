import { compose, branch, renderComponent, withProps } from 'recompose'
import { SERVICE_TYPES } from '../../services/orders/business'
import * as R from 'ramda'
import OrderModalSendDeliveryWeb from './OrderModalSendDeliveryWeb'
import OrderModalSendDeliveryInstagramStory from './OrderModalSendDeliveryInstagramStory'
import OrderModalSendDeliverySocialNetwork from './OrderModalSendDeliverySocialNetwork'
import withFetchOrderIfNeeded from '../common/withFetchOrderIfNeeded'

const OrderModalSendDelivery = ({ order }) => {
  throw new Error(`Unknown order type ${order.type}`)
}

const enhance = compose(
  withFetchOrderIfNeeded({ modalTitleI18nKey: 'action:deliver' }),

  withProps(props => ({
    serviceType: R.path(['orderData', 'product', 'service'], props),
  })),

  branch(
    props =>
      props.serviceType === SERVICE_TYPES.WEB_POST ||
      props.serviceType === SERVICE_TYPES.WEB_ONLY_POST,
    renderComponent(OrderModalSendDeliveryWeb),
  ),

  branch(
    props => props.serviceType === SERVICE_TYPES.INSTAGRAM_STORY,
    renderComponent(OrderModalSendDeliveryInstagramStory),
    renderComponent(OrderModalSendDeliverySocialNetwork),
  ),
)

export default enhance(OrderModalSendDelivery)
