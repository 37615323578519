import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import HackFormattedNumber from '../HackFormattedNumber'
import BarData from '../data/BarData'
import SingleData from '../data/SingleData'
import SocialData from '../data/SocialData'
import { truncateNumber } from '../../app/utils'
import { RESOURCE_TYPES } from '../../services/resources/constants'

const CardWebData = ({
  i18n,
  twitter,
  twitterUrl,
  facebook,
  facebookUrl,
  instagram,
  instagramUrl,
  domAuthority,
  pageAuthority,
  visitors,
  uniqueVisitors,
  pageViews,
  male,
  female,
  ageRate,
  ageGroup,
  i18nNumber,
}) => {
  return (
    <div className="card-web-data">
      <div className="card-web-data-column card-web-data-social">
        <SocialData
          data={twitter}
          socialUrl={twitterUrl}
          type={RESOURCE_TYPES.TWITTER}
          isTruncated
        />
        <SocialData
          data={facebook}
          socialUrl={facebookUrl}
          type={RESOURCE_TYPES.FACEBOOK}
          isTruncated
        />
        <SocialData
          data={instagram}
          socialUrl={instagramUrl}
          type={RESOURCE_TYPES.INSTAGRAM}
          isTruncated
        />
      </div>
      <div className="card-web-data-column card-web-data-seo">
        <BarData label="DA" data={Math.ceil(domAuthority)} barColor="#c5b9e8" />
        <BarData
          label="PA"
          data={Math.ceil(pageAuthority)}
          barColor="#3e17b3"
        />
        <div className="card-web-data-legend">
          {i18n('message:seo-moz-data')}
        </div>
      </div>

      <div className="card-web-data-column card-web-data-analytics">
        {!visitors &&
        !uniqueVisitors &&
        !pageViews &&
        !male &&
        !female &&
        !ageRate ? (
          <div className="card-web-data-empty">
            {i18n('message:google-analytics-no-data')}
          </div>
        ) : null}
        {visitors ? (
          <SingleData label={i18n('noun:visitors')}>
            {truncateNumber(visitors, i18nNumber)}
          </SingleData>
        ) : null}
        {uniqueVisitors ? (
          <SingleData label={i18n('noun:unique-visitors')}>
            {truncateNumber(uniqueVisitors, i18nNumber)}
          </SingleData>
        ) : null}
        {pageViews ? (
          <SingleData label={i18n('noun:page-views')}>
            {truncateNumber(pageViews, i18nNumber)}
          </SingleData>
        ) : null}
        {male ? (
          <SingleData label={i18n('noun:males')}>
            <HackFormattedNumber
              value={male}
              maximumFractionDigits={2}
              style={`percent`}
            />
          </SingleData>
        ) : null}
        {female ? (
          <SingleData label={i18n('noun:females')}>
            <HackFormattedNumber
              value={female}
              maximumFractionDigits={2}
              style={`percent`}
            />
          </SingleData>
        ) : null}
        {ageRate ? (
          <SingleData label={i18n('noun:age')}>
            <HackFormattedNumber
              value={ageRate}
              maximumFractionDigits={2}
              style={`percent`}
            />
            &nbsp;{ageGroup}
          </SingleData>
        ) : null}
        <div className="card-web-data-legend">
          {i18n('message:google-analytics-data')}
        </div>
      </div>
    </div>
  )
}

CardWebData.propTypes = {
  twitter: PropTypes.number,
  twitterUrl: PropTypes.string,
  facebook: PropTypes.number,
  facebookUrl: PropTypes.string,
  instagram: PropTypes.number,
  instagramUrl: PropTypes.string,
  domAuthority: PropTypes.number,
  pageAuthority: PropTypes.number,
  visitors: PropTypes.number,
  uniqueVisitors: PropTypes.number,
  pageViews: PropTypes.number,
  male: PropTypes.number,
  female: PropTypes.number,
  ageRate: PropTypes.number,
  ageGroup: PropTypes.string,
}

export default withTranslations(CardWebData)
