import {
  compose,
  withProps,
  withHandlers,
  withPropsOnChange,
  lifecycle,
  withStateHandlers,
} from 'recompose'
import { connect } from 'react-redux'
import { injectStripe } from 'react-stripe-elements'
import CheckoutPayment from '../../ui/checkout/CheckoutPayment'
import { withWallet } from '../../services/wallet/hocs'
import withTotalCartPrice from './withTotalCartPrice'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import {
  SUBMIT_PAYMENT,
  VALIDATE_DISCOUNT_COUPON,
} from '../../services/checkout/action-types'
import {
  submitPaymentWithWallet,
  submitPaymentWithCard,
  clearSubmitPaymentRequest,
} from '../../services/checkout'
import { withCart } from '../../services/checkout/hocs'
import {
  validateDiscountCoupon,
  removeAppliedCoupon,
  getAppliedCouponPaymentData,
} from '../../services/checkout'
import withTranslations from '../../hocs/withTranslations'
import withEffect from '../../hocs/withEffect'
import { moneyToFloat } from '../utils'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { PAYMENT_METHODS } from '../../services/checkout/business'
import withBillingDataModal from './withBillingDataModal'
import withCardPaymentHandlers from './withCartPaymentHandlers'
import withDiscountCoupon from './withDiscountCoupon'
import { getBrandCurrency } from '../../services/brand'

const getPaymentData = (isPaymentWithCard, couponPaymentData, cart) =>
  isPaymentWithCard && couponPaymentData
    ? couponPaymentData
    : cart.paymentTypes
    ? cart.paymentTypes.find(
        ({ paymentMethod }) =>
          paymentMethod ===
          (isPaymentWithCard
            ? PAYMENT_METHODS.CREDIT_CARD
            : PAYMENT_METHODS.WALLET_BALANCE),
      )
    : {}

const withPaymentType = compose(
  withStateHandlers(
    ({ balance, totalCartPrice }) => ({
      // Pre-select payment with card if balance is not enough
      isPaymentWithCard: moneyToFloat(balance) < totalCartPrice,
    }),
    {
      onPaymentWithBalanceClick: _ => _ => ({
        isPaymentWithCard: false,
      }),
      onPaymentWithCardClick: _ => _ => ({
        isPaymentWithCard: true,
      }),
    },
  ),

  // Disable payment with balance if it's not enough
  withPropsOnChange(
    ['balance', 'totalCartPrice'],
    ({ balance, totalCartPrice }) => ({
      isPaymentWithBalanceDisabled: moneyToFloat(balance) < totalCartPrice,
    }),
  ),

  // If payment with balance gets disabled, automatically change to card
  // payment
  withEffect(
    ({
      isPaymentWithCard,
      isPaymentWithBalanceDisabled,
      onPaymentWithCardClick,
    }) => {
      if (!isPaymentWithCard && isPaymentWithBalanceDisabled) {
        onPaymentWithCardClick()
      }
    },
    ({
      isPaymentWithCard,
      isPaymentWithBalanceDisabled,
      onPaymentWithCardClick,
    }) => [
      isPaymentWithCard,
      isPaymentWithBalanceDisabled,
      onPaymentWithCardClick,
    ],
  ),
)

const withPaymentSummary = compose(
  withPropsOnChange(
    ['isPaymentWithCard', 'balance', 'cart', 'couponPaymentData'],
    ({ isPaymentWithCard, balance, cart, couponPaymentData }) => {
      const paymentData = getPaymentData(
        isPaymentWithCard,
        couponPaymentData,
        cart,
      )

      return {
        amountBalance: balance,
        totalOrder: paymentData.cartItemsSubtotal
          ? paymentData.cartItemsSubtotal
          : undefined,
        totalDiscount: paymentData.couponAmount
          ? paymentData.couponAmount
          : undefined,
        vatPercentage: paymentData.vatRate
          ? moneyToFloat(paymentData.vatRate)
          : undefined,
        totalVat: paymentData.vatAmount ? paymentData.vatAmount : undefined,
        total: paymentData.cartTotalPayment
          ? paymentData.cartTotalPayment
          : undefined,
        appliedCouponCode: paymentData.coupon
          ? paymentData.coupon.id
          : undefined,
      }
    },
  ),
)

const withPayment = compose(
  connect(
    state => ({
      currency: getBrandCurrency(state),
    }),
    {
      submitPaymentWithWallet,
      submitPaymentWithCard,
      clearSubmitPaymentRequest,
    },
  ),

  withHandlers({
    onSubmit: props => e => {
      e.preventDefault()

      // Show billing data modal
      if (!props.hasBillingData) {
        props.openBillingDataModal()
        return
      }

      const paymentData = getPaymentData(
        props.isPaymentWithCard,
        props.couponPaymentData,
        props.cart,
      )

      if (props.isPaymentWithCard) {
        if (
          !props.cardNumberComplete ||
          !props.cardExpirationComplete ||
          !props.cardCVCComplete
        ) {
          props.showRequiredErrors()
          return
        }

        if (
          props.cardNumberError ||
          props.cardExpirationError ||
          props.cardCVCError
        ) {
          return
        }

        props.submitPaymentWithCard(
          props.appliedCouponCode,
          paymentData.cartTotalPayment,
          props.stripe,
        )
      } else {
        props.submitPaymentWithWallet(
          props.appliedCouponCode,
          paymentData.cartTotalPayment,
        )
      }
    },
  }),
  withIsRequestPending(SUBMIT_PAYMENT),
  withIsRequestSucceeded(SUBMIT_PAYMENT),
  withRequestError(SUBMIT_PAYMENT),
  withDeleteRequestOnUnmount(SUBMIT_PAYMENT),
  withProps(({ requestError }) => ({
    globalMessages: !!requestError ? requestError.globalMessages : undefined,
  })),
  // Clear payment request when changing payment method
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        prevProps.isPaymentWithCard !== this.props.isPaymentWithCard &&
        this.props.globalMessages
      ) {
        this.props.clearSubmitPaymentRequest()
      }
    },
  }),
)

const enhance = compose(
  injectStripe,

  withTranslations,
  withWallet,
  withCart,
  withTotalCartPrice,

  withBillingDataModal(),
  withPaymentType,
  withCardPaymentHandlers,

  connect(
    state => ({
      couponPaymentData: getAppliedCouponPaymentData(state),
    }),
    { validateDiscountCoupon, removeAppliedCoupon },
  ),
  withDiscountCoupon({
    couponDataProp: 'couponPaymentData',
    requestType: VALIDATE_DISCOUNT_COUPON,
  }),

  withPaymentSummary,
  withPayment,
)

export default enhance(CheckoutPayment)
