import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { STATISTICS_TABS } from '../../../services/campaigns/business'
import {
  MediaQueryPalm,
  MediaQueryLap,
  MediaQueryDesk,
} from '../../shared/MediaQueries'
import { FormattedDate } from 'react-intl'
import withTranslations from '../../../hocs/withTranslations'
import PublicationsTable from '../tables/PublicationsTable'
import PublicationsRow from '../tables/PublicationsRow'
import AnchorLink from '../../texts/AnchorLink'
import {
  ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID,
  prettifyConstant,
} from '../../../app/common/routes'

const PublicationsTableFacebook = ({
  publications,
  isLoading,
  i18n,
  i18nNumber,
}) => {
  return (
    <PublicationsTable
      type={STATISTICS_TABS.TWITCH}
      isLoading={isLoading}
      headRowCells={
        <>
          <div className="publications-head-row-item publications-table-name">
            {i18n('metrics:publication')}
          </div>
          <div className="publications-head-row-item publications-table-views">
            {i18n('metrics:views')}
          </div>
          <div className="publications-head-row-item publications-table-viewers">
            {i18n('metrics:viewers')}
          </div>
          <MediaQueryLap>
            <div className="publications-head-row-item publications-table-date">
              {i18n('noun:publication-date')}
            </div>
          </MediaQueryLap>
          <MediaQueryDesk>
            <div className="publications-head-row-item publications-table-action"></div>
          </MediaQueryDesk>
        </>
      }
    >
      {publications.map(publication => {
        const linkUrl = ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.linkTo({
          campaignId: publication.campaignId,
          resourceType: prettifyConstant(STATISTICS_TABS.TWITCH),
          orderId: publication.id,
        })

        return (
          <PublicationsRow key={publication.id} linkUrl={linkUrl}>
            <div className="publications-row-item publications-table-name">
              {publication.name}
            </div>
            <div className="publications-row-item publications-table-views">
              {!R.isNil(publication.views) && (
                <>
                  <MediaQueryLap>{i18nNumber(publication.views)}</MediaQueryLap>
                  <MediaQueryPalm>
                    {i18n('metrics:views-cell', {
                      views: i18nNumber(publication.views),
                    })}
                  </MediaQueryPalm>
                </>
              )}
            </div>
            <div className="publications-row-item publications-unique-viewers">
              {!R.isNil(publication.viewers) && (
                <>
                  <MediaQueryLap>
                    {i18nNumber(publication.viewers)}
                  </MediaQueryLap>
                  <MediaQueryPalm>
                    {i18n('metrics:viewers-cell', {
                      viewers: i18nNumber(publication.viewers),
                    })}
                  </MediaQueryPalm>
                </>
              )}
            </div>
            <MediaQueryLap>
              <div className="publications-row-item publications-table-date">
                <FormattedDate format="coobis" value={publication.date} />
              </div>
            </MediaQueryLap>
            <MediaQueryDesk>
              <div className="publications-row-item publications-table-action">
                <AnchorLink
                  linkUrl={linkUrl}
                  text={i18n('action:see-detail')}
                ></AnchorLink>
              </div>
            </MediaQueryDesk>
          </PublicationsRow>
        )
      })}
    </PublicationsTable>
  )
}

PublicationsTableFacebook.propTypes = {
  publications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      views: PropTypes.number,
      viewers: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  isLoading: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(PublicationsTableFacebook)
