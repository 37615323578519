import { SERVICE } from './constants'

export const FETCH_DASHBOARD_CAMPAIGNS = `${SERVICE}/FETCH_DASHBOARD_CAMPAIGNS`

export const REPEATING_FETCH_DASHBOARD_CAMPAIGNS = `${SERVICE}/REPEATING_FETCH_DASHBOARD_CAMPAIGNS`

export const CANCEL_REPEATING_FETCH_DASHBOARD_CAMPAIGNS = `${SERVICE}/CANCEL_REPEATING_FETCH_DASHBOARD_CAMPAIGNS`

export const FETCH_DASHBOARD_ORDERS = `${SERVICE}/FETCH_DASHBOARD_ORDERS`

export const REPEATING_FETCH_DASHBOARD_ORDERS = `${SERVICE}/REPEATING_FETCH_DASHBOARD_ORDERS`

export const CANCEL_REPEATING_FETCH_DASHBOARD_ORDERS = `${SERVICE}/CANCEL_REPEATING_FETCH_DASHBOARD_ORDERS`

export const FETCH_FEATURED_RESOURCES = `${SERVICE}/FETCH_FEATURED_RESOURCES`
