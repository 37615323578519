import React from 'react'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import ImageEmptyOrdersSmall from './../shared/images/emptyResultSmallOrders.png'
import ImageEmptyOrdersBig from './../shared/images/emptyResultBigOrders.png'
import EmptyResult from '../shared/EmptyResult'
import { AuthScope } from '../../services/auth/business'

const OrderDetailDeliveryEmpty = ({ i18n, scope }) => {
  return (
    <OrderDetailSection isEmpty>
      <EmptyResult
        imageSmall={ImageEmptyOrdersSmall}
        imageBig={ImageEmptyOrdersBig}
        subtitle={
          scope === AuthScope.MEDIA
            ? i18n('order-detail-delivery:no-deliveries-media')
            : i18n('order-detail-delivery:no-deliveries-brand')
        }
        title={i18n('message:without-deliveries')}
      />
    </OrderDetailSection>
  )
}

export default withTranslations(OrderDetailDeliveryEmpty)
