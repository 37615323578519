import * as hash from 'reduken/hash'
import * as entities from 'reduken/entities'
import {
  DOMAIN,
  HASH_KEY_MEDIA_ORDERS_IN_PROGRESS_IDS,
  HASH_KEY_MEDIA_ORDERS_FINISHED_IDS,
  ENTITY_KEY_MEDIA_ORDERS_FINISHED,
  ENTITY_KEY_MEDIA_ORDERS_IN_PROGRESS,
  HASH_KEY_ORDER_CANCEL_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_COUNTERBRIEFING_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_PREDELIVERY_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_DELIVERY_MODAL_ID,
  HASH_KEY_ORDER_REVIEW_MODAL_ID,
  HASH_KEY_ORDER_SEND_PREDELIVERY_MODAL_STATUS,
  HASH_KEY_ORDER_SEND_DELIVERY_MODAL_STATUS,
  ENTITY_KEY_ORDER_HISTORY,
  ENTITY_KEY_MEDIA_ORDER_DETAILS,
  ENTITY_KEY_BRAND_ORDER_DETAILS,
  HASH_KEY_BRAND_ORDERS_IN_PROGRESS_IDS,
  ENTITY_KEY_BRAND_ORDERS_IN_PROGRESS,
  ENTITY_KEY_CURRENT_CAMPAIGN_ORDERS,
  ENTITY_KEY_CANCELLED_CAMPAIGN_ORDERS,
} from './constants'
import { createSelector } from 'reselect'
import { isNilOrEmpty } from '../../app/utils'

export const getMediaOrdersInProgressIds = hash.getFromPath(
  DOMAIN,
  HASH_KEY_MEDIA_ORDERS_IN_PROGRESS_IDS,
)

export const getBrandOrdersInProgressIds = hash.getFromPath(
  DOMAIN,
  HASH_KEY_BRAND_ORDERS_IN_PROGRESS_IDS,
)

export const getMediaOrdersFinishedIds = hash.getFromPath(
  DOMAIN,
  HASH_KEY_MEDIA_ORDERS_FINISHED_IDS,
)

export const getMediaOrderInProgressById = entities.getOne(
  ENTITY_KEY_MEDIA_ORDERS_IN_PROGRESS,
)

export const getBrandOrderInProgressById = entities.getOne(
  ENTITY_KEY_BRAND_ORDERS_IN_PROGRESS,
)

export const getMediaOrderFinishedById = entities.getOne(
  ENTITY_KEY_MEDIA_ORDERS_FINISHED,
)

export const getMediaOrdersInProgress = state => {
  const orders = getMediaOrdersInProgressIds(state)

  if (!orders) {
    return orders
  }

  return orders.map(id => getMediaOrderInProgressById(id, state))
}

export const getBrandOrdersInProgress = state => {
  const orders = getBrandOrdersInProgressIds(state)

  if (!orders) {
    return orders
  }

  return orders.map(id => getBrandOrderInProgressById(id, state))
}

export const getMediaOrdersFinished = state => {
  const orders = getMediaOrdersFinishedIds(state)

  if (!orders) {
    return orders
  }

  return orders.map(id => getMediaOrderFinishedById(id, state))
}

export const getMediaOrderDetailById = entities.getOne(
  ENTITY_KEY_MEDIA_ORDER_DETAILS,
)

export const getBrandOrderDetailById = entities.getOne(
  ENTITY_KEY_BRAND_ORDER_DETAILS,
)

export const getOrderHistoryById = createSelector(
  entities.getOne(ENTITY_KEY_ORDER_HISTORY),
  orderHistory =>
    !isNilOrEmpty(orderHistory) ? Object.values(orderHistory) : null,
)

export const getOrderCancelModalId = hash.getFromPath(
  DOMAIN,
  HASH_KEY_ORDER_CANCEL_MODAL_ID,
)

export const getOrderReviewCounterbriefingModalId = hash.getFromPath(
  DOMAIN,
  HASH_KEY_ORDER_REVIEW_COUNTERBRIEFING_MODAL_ID,
)

export const getOrderReviewPredeliveryModalId = hash.getFromPath(
  DOMAIN,
  HASH_KEY_ORDER_REVIEW_PREDELIVERY_MODAL_ID,
)
export const getOrderReviewDeliveryModalId = hash.getFromPath(
  DOMAIN,
  HASH_KEY_ORDER_REVIEW_DELIVERY_MODAL_ID,
)

export const getOrderReviewModalId = hash.getFromPath(
  DOMAIN,
  HASH_KEY_ORDER_REVIEW_MODAL_ID,
)

export const getOrderSendPredeliveryModalStatus = hash.getFromPath(
  DOMAIN,
  HASH_KEY_ORDER_SEND_PREDELIVERY_MODAL_STATUS,
)

export const getOrderSendDeliveryModalStatus = hash.getFromPath(
  DOMAIN,
  HASH_KEY_ORDER_SEND_DELIVERY_MODAL_STATUS,
)

export const getCurrentOrdersForCampaign = createSelector(
  entities.getOne(ENTITY_KEY_CURRENT_CAMPAIGN_ORDERS),
  currentOrders =>
    !isNilOrEmpty(currentOrders) ? Object.values(currentOrders) : [],
)

export const getCancelledOrdersForCampaign = createSelector(
  entities.getOne(ENTITY_KEY_CANCELLED_CAMPAIGN_ORDERS),
  cancelledOrders =>
    !isNilOrEmpty(cancelledOrders) ? Object.values(cancelledOrders) : [],
)
