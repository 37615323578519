import React from 'react'
import PropTypes from 'prop-types'
import GlobalMessage from './GlobalMessage'
import withTranslations from '../../hocs/withTranslations'
import img500Big from './images/internalBig.png'
import img500Small from './images/internalSmall.png'
import { ROUTE_ROOT } from '../../app/common/routes'

const InternalErrorMessage = ({ i18n, onReport }) => {
  return (
    <GlobalMessage
      message={i18n('error:internal-error')}
      imageBig={img500Big}
      imageSmall={img500Small}
      onReport={onReport}
    >
      <a
        className="rdx-button rdx-button-primary--default has-height-fixed has-width-adaptative"
        href={ROUTE_ROOT.linkTo()}
      >
        {i18n('navigation:go-dashboard')}
      </a>
    </GlobalMessage>
  )
}

InternalErrorMessage.propTypes = {
  onReport: PropTypes.func,
}

export default withTranslations(InternalErrorMessage)
