import { compose, withProps, branch } from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
  FETCH_BRAND_ORDER_DETAIL,
  FETCH_MEDIA_ORDER_DETAIL,
} from '../../services/orders/action-types'
import {
  fetchBrandOrderDetail,
  getBrandOrderDetailById,
  getMediaOrderDetailById,
  fetchMediaOrderDetail,
} from '../../services/orders'
import withEffect from '../../hocs/withEffect'
import withHasRequestWithIdError from '../../hocs/withHasRequestWithIdError'
import withIsRequestWithIdPending from '../../hocs/withIsRequestWithIdPending'
import withSpinnerWithId from '../../hocs/withSpinnerWithId'
import { AuthScope } from '../../services/auth/business'
import { getScope } from '../../services/auth'
import withIsRequestWithIdSucceeded from '../../hocs/withIsRequestWithIdSucceeded'
import enhanceModalSpinner from './enhanceModalSpinner'

const PENDING_PROP = 'withFetchOrderIfNeeded/isFetchOrderPending'
const SUCCEEDED_PROP = 'withFetchOrderIfNeeded/isFetchOrderSucceeded'
const HAS_ERROR_PROP = 'withFetchOrderIfNeeded/hasFetchOrderError'

// TODO: handle fetch errors somehow
const withFetchOrderIfNeeded = ({
  idProp = 'orderId',
  outProp = 'orderData',
  modalTitleI18nKey = undefined,
} = {}) =>
  compose(
    connect(state => ({ scope: getScope(state) })),

    branch(
      ({ scope }) => scope === AuthScope.BRAND,
      compose(
        connect(
          (state, { [idProp]: orderId }) => ({
            [outProp]: getBrandOrderDetailById(orderId, state),
          }),
          { fetchOrderDetail: fetchBrandOrderDetail },
        ),

        withIsRequestWithIdPending(FETCH_BRAND_ORDER_DETAIL, {
          idProp,
          outProp: PENDING_PROP,
        }),
        withIsRequestWithIdSucceeded(FETCH_BRAND_ORDER_DETAIL, {
          idProp,
          outProp: SUCCEEDED_PROP,
        }),
        withHasRequestWithIdError(FETCH_BRAND_ORDER_DETAIL, {
          idProp,
          outProp: HAS_ERROR_PROP,
        }),
      ),
    ),

    branch(
      ({ scope }) => scope === AuthScope.MEDIA,
      compose(
        connect(
          (state, { [idProp]: orderId }) => ({
            [outProp]: getMediaOrderDetailById(orderId, state),
          }),
          { fetchOrderDetail: fetchMediaOrderDetail },
        ),

        withIsRequestWithIdPending(FETCH_MEDIA_ORDER_DETAIL, {
          idProp,
          outProp: PENDING_PROP,
        }),
        withIsRequestWithIdSucceeded(FETCH_MEDIA_ORDER_DETAIL, {
          idProp,
          outProp: SUCCEEDED_PROP,
        }),
        withHasRequestWithIdError(FETCH_MEDIA_ORDER_DETAIL, {
          idProp,
          outProp: HAS_ERROR_PROP,
        }),
      ),
    ),

    withEffect(
      ({
        [idProp]: orderId,
        [PENDING_PROP]: isFetchOrderPending,
        [SUCCEEDED_PROP]: hasFetchOrderSucceeded,
        [HAS_ERROR_PROP]: hasFetchOrderError,
        [outProp]: orderData,
        fetchOrderDetail,
      }) => {
        if (
          !orderData &&
          !isFetchOrderPending &&
          !hasFetchOrderSucceeded &&
          !hasFetchOrderError
        ) {
          fetchOrderDetail(orderId)
        }
      },
      ({
        [idProp]: orderId,
        [PENDING_PROP]: isFetchOrderPending,
        [SUCCEEDED_PROP]: hasFetchOrderSucceeded,
        [HAS_ERROR_PROP]: hasFetchOrderError,
        [outProp]: orderData,
        fetchOrderDetail,
      }) => [
        orderId,
        isFetchOrderPending,
        hasFetchOrderSucceeded,
        hasFetchOrderError,
        orderData,
        fetchOrderDetail,
      ],
    ),

    branch(
      ({ scope }) => scope === AuthScope.BRAND,
      withSpinnerWithId(FETCH_BRAND_ORDER_DETAIL, {
        idProp: 'orderId',
        enhanceSpinner: enhanceModalSpinner(modalTitleI18nKey),
      }),
    ),
    branch(
      ({ scope }) => scope === AuthScope.MEDIA,
      withSpinnerWithId(FETCH_MEDIA_ORDER_DETAIL, {
        idProp: 'orderId',
        enhanceSpinner: enhanceModalSpinner(modalTitleI18nKey),
      }),
    ),

    withProps(R.omit([PENDING_PROP, SUCCEEDED_PROP, HAS_ERROR_PROP])),
  )

export default withFetchOrderIfNeeded
