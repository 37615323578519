import { getFromPath } from 'reduken/hash'
import { createSelector } from 'reselect'
import * as R from 'ramda'
import {
  DOMAIN,
  HASH_KEY_CATEGORIES,
  HASH_KEY_COUNTRIES,
  HASH_KEY_CURRENCIES,
} from './constants'
import { getLanguage } from '../me'

export const getCategories = getFromPath(DOMAIN, HASH_KEY_CATEGORIES)
export const getCountries = getFromPath(DOMAIN, HASH_KEY_COUNTRIES)
export const getCurrencies = getFromPath(DOMAIN, HASH_KEY_CURRENCIES)
export const getTranslatedCountries = createSelector(
  getLanguage,
  getCountries,
  (language, countries) =>
    R.map(
      country => ({
        id: R.prop('id', country),
        label: R.path(['translations', language], country),
        provinces: R.map(
          province => ({
            id: R.prop('id', province),
            label: R.path(['translations', language], province),
          }),
          country.provinces,
        ),
      }),
      R.values(countries),
    ),
)
