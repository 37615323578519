import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { prettifyConstant } from '../../../app/common/routes'
import { STATISTICS_TABS } from '../../../services/campaigns/business'
import Card from '../../cards/Card'
import PublicationsHeadRow from './PublicationsHeadRow'
import useScrollEnds from '../../../hooks/useScrollEnds'

const PublicationsTable = ({ type, children, headRowCells }) => {
  const { handleScroll, setScrollerRef, scrollEnds } = useScrollEnds({
    top: 20,
    bottom: 20,
  })

  const publicationsTableClasses = cx(
    `publications-table publications-table-${prettifyConstant(type)}`,
    {
      'has-top-gradient': !scrollEnds.top,
      'has-bottom-gradient': !scrollEnds.bottom,
    },
  )

  return (
    <Card isContained>
      <div className="publications-card">
        <div
          className={publicationsTableClasses}
          ref={setScrollerRef}
          onScroll={handleScroll}
        >
          <PublicationsHeadRow>{headRowCells}</PublicationsHeadRow>
          <div className="publications-table-content">{children}</div>
        </div>
      </div>
    </Card>
  )
}

PublicationsTable.propTypes = {
  type: PropTypes.oneOf(Object.values(STATISTICS_TABS)),
  children: PropTypes.node,
  headRowCells: PropTypes.node,
}

export default PublicationsTable
