import React from 'react'
import PropTypes from 'prop-types'
import GlobalMessage from './GlobalMessage'
import withTranslations from '../../hocs/withTranslations'
import img404Big from './images/404Big.png'
import img404Small from './images/404Small.png'
import { ROUTE_ROOT } from '../../app/common/routes'

const Error404Message = ({ i18n, onReport }) => {
  return (
    <GlobalMessage
      message={i18n('error:404')}
      imageBig={img404Big}
      imageSmall={img404Small}
      onReport={onReport}
    >
      <a
        className="rdx-button rdx-button-primary--default has-height-fixed has-width-adaptative"
        href={ROUTE_ROOT.linkTo()}
      >
        {i18n('navigation:go-dashboard')}
      </a>
    </GlobalMessage>
  )
}

Error404Message.propTypes = {
  onReport: PropTypes.func,
}

export default withTranslations(Error404Message)
