import React from 'react'
import { safelyRender } from '../../app/utils'
import Button from '@redradix/components.button'
import withTranslations from '../../hocs/withTranslations'

const TourCard = ({
  title,
  titleIcon,
  texts,
  renderIcon,
  nextButtonText,
  onNextClick,
  onCloseClick,
  i18n,
}) => (
  <div className="tour-card">
    <div className="tour-card-info">
      <p>
        <span className="tour-card-title">{title}</span>
        {titleIcon && (
          <span className="tour-card-icon card-label-new">{titleIcon}</span>
        )}
      </p>
      {texts.map((text, i) => (
        <p key={i}>
          <span className="tour-card-text">{text}</span>
        </p>
      ))}
      <div className="tour-card-image">
        {safelyRender(renderIcon, { className: 'icon' })}
      </div>
      <div className="tour-card-buttons">
        <Button hierarchy="primary" variant="neutral" onClick={onCloseClick}>
          {i18n('tour:skip')}
        </Button>
        <Button
          hierarchy="primary"
          variant="alternate"
          heightBehaviour="adaptative"
          type="button"
          onClick={onNextClick}
        >
          {nextButtonText}
        </Button>
      </div>
    </div>
  </div>
)

export default withTranslations(TourCard)
