import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import Textarea from './../form-elements/Textarea'
import Datepicker from '../form-elements/Datepicker'
import OrderModalReviewDeliveryDownloads from './OrderModalReviewDeliveryDownloads'

const TIME_OPTIONS = Array(24)
  .fill()
  .map((_, i) => ({ hours: i, minutes: 0 }))

const OrderModalReviewDeliveryCorrect = ({
  webUrl,
  facebookUrl,
  twitterUrl,
  instagram,
  socialNetworkUrl,
  instagramUrl,
  instagramFilename,
  onCancelClick,
  isExpress,

  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  dateInfo,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal order-modal-compact-vertical">
        <ModalSimpleContent
          doubleActions
          action1Text={i18n('action:cancel')}
          action1={onCancelClick}
          action2Text={i18n('action:amend')}
          buttonVariant2="default"
          isButton2Loading={isRequestPending}
          isButton2Submit
          isButtonDisabled={isRequestPending}
        >
          <ModalHeader title={i18n('action:amend-delivery')} />

          <OrderModalReviewDeliveryDownloads
            webUrl={webUrl}
            facebookUrl={facebookUrl}
            twitterUrl={twitterUrl}
            instagram={instagram}
            socialNetworkUrl={socialNetworkUrl}
            instagramUrl={instagramUrl}
            instagramFilename={instagramFilename}
          />

          <Textarea
            name="comments"
            label={i18n('action:add-comment-for-media')}
            placeholder={i18n('action:write-your-comment-here')}
            onBlur={handleBlur}
            onChange={handleChange}
            errorText={
              validationMessages.comments && (
                <FormattedFormErrors errors={validationMessages.comments} />
              )
            }
            isTouched={touched.comments}
            isDisabled={isRequestPending}
            value={values.comments}
            isRequired
          />

          {!isExpress ? (
            <>
              <Datepicker
                id="deliveryDate"
                name="deliveryDate"
                timeOptions={TIME_OPTIONS}
                value={values.deliveryDate}
                onChange={handleChange}
                onBlur={handleBlur}
                datepickerLabel={i18n('noun:delivery-date')}
                datepickerSubtitle={i18n('description:delivery-date-brand')}
                modalTitle={i18n('checkout:choose-date-hour')}
                isTouched={touched.deliveryDate}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.deliveryDate && (
                    <FormattedFormErrors
                      errors={validationMessages.deliveryDate}
                    />
                  )
                }
                minDateTime={dateInfo.deliveryDate.min}
                maxDateTime={dateInfo.deliveryDate.max}
                placeholder={dateInfo.deliveryDate.default}
              />

              <Datepicker
                id="approveDate"
                name="approveDate"
                timeOptions={TIME_OPTIONS}
                value={values.approveDate}
                onChange={handleChange}
                onBlur={handleBlur}
                datepickerLabel={i18n('noun:approval-date')}
                datepickerSubtitle={i18n('description:approval-date-brand')}
                modalTitle={i18n('checkout:choose-date-hour')}
                isTouched={touched.approveDate}
                isDisabled={isRequestPending}
                errorText={
                  validationMessages.approveDate && (
                    <FormattedFormErrors
                      errors={validationMessages.approveDate}
                    />
                  )
                }
                minDateTime={dateInfo.approveDate.min}
                maxDateTime={dateInfo.approveDate.max}
                placeholder={dateInfo.approveDate.default}
              />
            </>
          ) : null}

          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  comments: PropTypes.string.isRequired,
  deliveryDate: PropTypes.instanceOf(moment),
  approveDate: PropTypes.instanceOf(moment),
}

const dateInfoPropType = PropTypes.shape({
  min: PropTypes.instanceOf(moment),
  max: PropTypes.instanceOf(moment),
  default: PropTypes.instanceOf(moment),
})

OrderModalReviewDeliveryCorrect.propTypes = {
  webUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagram: CoobisPropTypes.urlFile,
  socialNetworkUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  instagramFilename: PropTypes.string,
  onCancelClick: PropTypes.func,
  isExpress: PropTypes.bool,

  dateInfo: PropTypes.shape({
    deliveryDate: dateInfoPropType.isRequired,
    approveDate: dateInfoPropType.isRequired,
  }).isRequired,
  isRequestPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(OrderModalReviewDeliveryCorrect)
