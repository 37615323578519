import React, { Fragment } from 'react'
import cx from 'classnames'
import { ReactComponent as Remove } from '../../../styles/icons/close.svg'
import { safelyRender } from '../../../app/utils'

// Custom components injected to react-select Select component.
// For reference, refer to https://react-select.com/components

const isEmpty = value => !(value && Object.keys(value).length)

const isDynamic = props =>
  props.componentsProps.behaviour === 'dynamic' && isEmpty(props.value)

const shouldUseFadeAnimation = props =>
  props.placeholder !== props.componentsProps.label && isDynamic(props)

const iconClasses = 'rdx-icon rdx-select-field-icon'
const requiredIcon = <span className="rdx-icon--require">*</span>

const ClearIndicator = ({ innerProps, selectProps }) =>
  safelyRender(selectProps.componentsProps.renderClearIcon, {
    ...innerProps,
    className: `${iconClasses} rdx-select-field-icon-clear`,
  })

const Control = ({ selectProps, innerProps, innerRef, children }) => {
  return (
    <div
      aria-expanded={selectProps.menuIsOpen}
      className="rdx-select-field-wrapper"
      ref={innerRef}
      {...innerProps}
    >
      {children}
      {selectProps.componentsProps.label && (
        <label className="rdx-select-field-label" htmlFor={selectProps.id}>
          {selectProps.componentsProps.label}
          {selectProps.componentsProps.isRequired ? requiredIcon : null}
        </label>
      )}
    </div>
  )
}

const DropdownIndicator = ({ selectProps, innerProps }) =>
  safelyRender(selectProps.componentsProps.renderDownIcon, {
    ...innerProps,
    className: `${iconClasses} rdx-select-field-icon-open`,
  })

const IndicatorsContainer = ({ children }) => (
  <div className="rdx-select-field-icons-wrapper">{children}</div>
)

const IndicatorSeparator = () => null

const Input = ({ selectProps, innerRef, isDisabled, ...props }) => {
  const showHint =
    !isEmpty(selectProps.value) && selectProps.componentsProps.searchHint
  const classes = cx('rdx-select-field-select', {
    //'is-hidden': !selectProps.isMulti && selectProps.value,
  })

  // remove some props incompatible with input tag
  const { cx: cxremoved, getStyles, isHidden, ...inputProps } = props

  return (
    <input
      className={classes}
      disabled={isDisabled}
      ref={innerRef}
      {...inputProps}
      placeholder={
        showHint ? selectProps.componentsProps.searchHint : undefined
      }
    />
  )
}

const Menu = ({ innerRef, innerProps, children }) => (
  <div className="rdx-select-field-items" ref={innerRef} {...innerProps}>
    {children}
  </div>
)

const MenuList = ({ innerRef, children }) => (
  <ul aria-label="submenu" ref={innerRef}>
    {children}
  </ul>
)

const MultiValue = ({ children, data, removeProps, isFocused }) => {
  const classes = cx('rdx-select-field-tag', {
    'is-fixed': data.isFixed,
    'is-focused': isFocused,
  })
  return (
    <button className={classes} tabIndex={-1}>
      <p>{children}</p>

      {!data.isFixed && (
        <div
          className="rdx-select-field-icon-close"
          role="button"
          {...removeProps}
        >
          <Remove className={'rdx-icon rdx-icon-close'} />
        </div>
      )}
    </button>
  )
}

const Option = ({
  data,
  selectProps,
  isFocused,
  innerRef,
  innerProps,
  label,
  value,
  onOptionClick,
}) => {
  const singleAndSelected =
    !selectProps.isMulti &&
    selectProps.value &&
    value === selectProps.value.value
  const isSelected = singleAndSelected && !selectProps.isDisabled
  const classes = cx(`rdx-select-field-option`, {
    'is-disabled': data.isDisabled,
    'is-link': data.isLink,
    'is-focused': isFocused,
    'is-selected': isSelected,
  })

  return (
    <li
      ref={innerRef}
      className={classes}
      aria-selected={isSelected}
      role="option"
      {...innerProps}
    >
      {data.imgPath && (
        <span className="rdx-select-option-image-wrapper">
          <img
            className="rdx-select-option-image"
            src={data.imgPath}
            alt={data.imgAlt}
          />
        </span>
      )}
      {data.renderIcon && (
        <span className="rdx-select-option-icon-wrapper">
          {safelyRender(data.renderIcon, {
            className: 'rdx-select-option-icon',
          })}
        </span>
      )}

      <span>{label}</span>
    </li>
  )
}

const SelectContainer = ({ selectProps, innerProps, children, isFocused }) => {
  const selectFieldClasses = cx(
    `rdx-select-field rdx-select-field-${selectProps.componentsProps.appearance} is-custom`,
    {
      'has-error': selectProps.componentsProps.hasError,
      'has-label': selectProps.componentsProps.label,
      'has-width-fixed': selectProps.componentsProps.widthBehaviour === 'fixed',
      'has-width-adaptative':
        selectProps.componentsProps.widthBehaviour === 'adaptative',
      'has-height-fixed':
        selectProps.componentsProps.heightBehaviour === 'fixed',
      'has-height-adaptative':
        selectProps.componentsProps.heightBehaviour === 'adaptative',
      'has-width-full': selectProps.componentsProps.widthBehaviour === 'full',
      'is-disabled': selectProps.isDisabled,
      'is-dynamic': isDynamic(selectProps),
      'is-fading': shouldUseFadeAnimation(selectProps),
      'is-focused': isFocused,
      'is-multi': selectProps.isMulti,
      'is-open-down':
        selectProps.menuIsOpen &&
        selectProps.componentsProps.dropDownPos === 'down',
      'is-open-up':
        selectProps.menuIsOpen &&
        selectProps.componentsProps.dropDownPos === 'up',
      'is-required': selectProps.componentsProps.isRequired,
      'is-searchable': selectProps.isSearchable,
      'is-touched': selectProps.componentsProps.isTouched,
    },
  )
  return (
    <div className={selectFieldClasses} {...innerProps}>
      {children}
      {selectProps.componentsProps.helperText &&
      !selectProps.componentsProps.errorText ? (
        <div className="rdx-select-field-helper">
          {selectProps.componentsProps.helperText}
        </div>
      ) : null}
      {selectProps.componentsProps.hasError && !selectProps.isDisabled ? (
        <div className="rdx-select-field-helper">
          {selectProps.componentsProps.errorText}
        </div>
      ) : null}
    </div>
  )
}

const Placeholder = ({ children, selectProps }) => {
  return isDynamic(selectProps) &&
    !shouldUseFadeAnimation(selectProps) ? null : (
    <div className="rdx-select-field-placeholder">
      {children}
      {selectProps.componentsProps.isRequired &&
      !selectProps.componentsProps.label
        ? requiredIcon
        : null}
    </div>
  )
}

const SingleValue = ({ selectProps, children }) => {
  return (
    <Fragment>
      {selectProps.options.map(option => {
        if (option.label === children) {
          if (option.imgPath) {
            return (
              <span
                className="rdx-select-option-image-wrapper"
                key={option.value}
              >
                <img
                  className="rdx-select-option-image"
                  src={option.imgPath}
                  alt={option.imgAlt}
                />
              </span>
            )
          }
          if (option.renderIcon) {
            return (
              <span
                className="rdx-select-option-icon-wrapper"
                key={option.value}
              >
                {safelyRender(option.renderIcon, {
                  className: 'rdx-icon rdx-select-option-icon',
                })}
              </span>
            )
          }
          return null
        }
        return null
      })}
      <div className="rdx-select-field-value">{children}</div>
    </Fragment>
  )
}

const ValueContainer = ({ children }) => children

export default {
  ClearIndicator,
  Control,
  DropdownIndicator,
  IndicatorsContainer,
  IndicatorSeparator,
  Input,
  Menu,
  MenuList,
  MultiValue,
  Option,
  Placeholder,
  SelectContainer,
  SingleValue,
  ValueContainer,
}
