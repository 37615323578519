import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import Paragraph from './../texts/Paragraph'
import AnchorLink from './../texts/AnchorLink'
import Textarea from './../form-elements/Textarea'

const OrderModalReviewPredeliveryApprove = ({
  linkText,
  linkUrl,
  downloadFileName,
  downloadUrl,

  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  onCancelClick,
  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal">
        <ModalSimpleContent
          doubleActions
          action1Text={i18n('action:cancel')}
          action1={onCancelClick}
          action2Text={i18n('action:approve')}
          buttonVariant2="success"
          isButton2Loading={isRequestPending}
          isButton2Submit
          isButtonDisabled={isRequestPending}
        >
          <ModalHeader title={i18n('action:approve-predelivery')} />

          {downloadFileName || downloadUrl ? (
            <Paragraph title={i18n('order-modal:predelivery-file')}>
              <AnchorLink
                text={downloadFileName}
                linkUrl={downloadUrl}
                download={downloadFileName}
                isNative
              />
            </Paragraph>
          ) : null}

          {linkText || linkUrl ? (
            <Paragraph title={i18n('order-modal:predelivery-link')}>
              <AnchorLink text={linkText} linkUrl={linkUrl} isNewTab isNative />
            </Paragraph>
          ) : null}

          <Textarea
            name="comments"
            label={i18n('action:add-optional-comment-for-media')}
            placeholder={i18n('action:write-your-comment-here')}
            onBlur={handleBlur}
            onChange={handleChange}
            errorText={
              validationMessages.comments && (
                <FormattedFormErrors errors={validationMessages.comments} />
              )
            }
            isTouched={touched.comments}
            isDisabled={isRequestPending}
            value={values.comments}
          />

          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  comments: PropTypes.string,
}

OrderModalReviewPredeliveryApprove.propTypes = {
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  downloadFileName: PropTypes.string,
  downloadUrl: PropTypes.string,
  isRequestPending: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func,
  // withTranslations
  i18n: PropTypes.func,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(OrderModalReviewPredeliveryApprove)
