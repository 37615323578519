import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import InputNumber from './InputNumber'
import * as R from 'ramda'
import { safelyRender } from '../../../src/app/utils'
import { ReactComponent as IconDefaultDown } from '../../styles/icons/chevron-down.svg'
import { ReactComponent as IconDefaultUp } from '../../styles/icons/chevron-up.svg'

const InputNumberControls = ({
  id,
  isDisabled,
  isReadonly,
  isRequired,
  isTouched,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  renderUpIcon,
  renderDownIcon,
  step,
  value,
  widthBehaviour,
}) => {
  const inputControlClasses = cx('input-number-controls', {
    'is-disabled': isDisabled,
  })

  const cleanValue = Number(value) || 0

  const onUpClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      onChange({
        target: {
          name,
          value: !R.isNil(max) && cleanValue >= max ? max : cleanValue + 1,
        },
      })
      onBlur(e)
    },
    [cleanValue, max, name, onBlur, onChange],
  )

  const onDownClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      onChange({
        target: {
          name,
          value: !R.isNil(min) && cleanValue <= min ? min : cleanValue - 1,
        },
      })
      onBlur(e)
    },
    [cleanValue, min, name, onBlur, onChange],
  )

  const handleBlur = useCallback(
    e => {
      if (!R.isNil(min) && value < min) {
        onChange({ target: { name, value: min } })
      }

      if (!R.isNil(max) && value > max) {
        onChange({ target: { name, value: max } })
      }

      onBlur(e)
    },
    [max, min, name, onBlur, onChange, value],
  )

  return (
    <div className={inputControlClasses}>
      <InputNumber
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        isDisabled={isDisabled}
        isReadonly={isReadonly}
        isRequired={isRequired}
        isTouched={isTouched}
        widthBehaviour={widthBehaviour}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={onFocus}
        min={min}
        max={max}
        step={step}
      />
      <div className="input-number-controls-handlers">
        <button onClick={onUpClick}>
          {safelyRender(renderUpIcon, {
            className: 'rdx-icon',
          }) || <IconDefaultUp className="rdx-icon" />}
        </button>
        <button onClick={onDownClick}>
          {safelyRender(renderDownIcon, {
            className: 'rdx-icon',
          }) || <IconDefaultDown className="rdx-icon" />}
        </button>
      </div>
    </div>
  )
}

InputNumberControls.propTypes = {
  /** String that defines a unique identifier of the <input> */
  id: PropTypes.string.isRequired,
  /** Boolean that defines whether the <input> is disabled */
  isDisabled: PropTypes.bool,
  /** Boolean that defines whether the <input> can be modified */
  isReadonly: PropTypes.bool,
  /** Boolean that defines whether the <input> is required */
  isRequired: PropTypes.bool,
  /** Boolean that specifies whether the <input> field has been modified */
  isTouched: PropTypes.bool,
  /** Number that specifies the maximum number of the <input> */
  max: PropTypes.number,
  /** Number that specifies the minimum number of the <input> */
  min: PropTypes.number,
  /** String that specifies the name of an <input> */
  name: PropTypes.string.isRequired,
  /** Function that is triggered when the <input> field loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when the <input> is changed */
  onChange: PropTypes.func,
  /** Function that is triggered when the <input> gets focus */
  onFocus: PropTypes.func,
  /** String that describes the expected value of the <input> */
  placeholder: PropTypes.string,
  /** Function that is triggered to render the icon 'up' */
  renderUpIcon: PropTypes.func,
  /** Function that is triggered to render the icon 'down' */
  renderDownIcon: PropTypes.func,
  /** Number that specifies the legal number intervals of the <input>*/
  step: PropTypes.number,
  /** String that specifies the value of the <input> */
  value: PropTypes.number,
  /** String that defines the behaviour of the input width */
  widthBehaviour: PropTypes.oneOf(['fixed', 'full']),
}

export default InputNumberControls
