import * as actionTypes from './action-types'
import * as hash from 'reduken/hash'
import {
  DOMAIN,
  HASH_KEY_BALANCE_RECHARGE_MODAL,
  HASH_KEY_RECHARGE_DISCOUNT_COUPON,
} from './constants'

export const periodicFetchWallet = () => ({
  type: actionTypes.PERIODIC_FETCH_WALLET,
})

export const fetchWalletMovements = page => ({
  type: actionTypes.FETCH_MOVEMENTS,
  payload: { page: page ? page : 1 },
})

export const periodicFetchBrandWalletMovements = page => ({
  type: actionTypes.PERIODIC_FETCH_BRAND_MOVEMENTS,
  payload: { page: page ? page : 1 },
})

export const periodicFetchMediaWalletMovements = page => ({
  type: actionTypes.PERIODIC_FETCH_MEDIA_MOVEMENTS,
  payload: { page: page ? page : 1 },
})

export const openBalanceRechargeModal = () =>
  hash.set(DOMAIN, HASH_KEY_BALANCE_RECHARGE_MODAL, true)

export const closeBalanceRechargeModal = () =>
  hash.set(DOMAIN, HASH_KEY_BALANCE_RECHARGE_MODAL, false)

export const validateRechargeDiscountCoupon = (amount, coupon) => ({
  type: actionTypes.VALIDATE_RECHARGE_DISCOUNT_COUPON,
  payload: { amount, coupon },
})

export const removeRechargeDiscountCoupon = () =>
  hash.remove(DOMAIN, HASH_KEY_RECHARGE_DISCOUNT_COUPON)

export const openWithdrawFundsModal = () => ({
  type: actionTypes.OPEN_WITHDRAW_FUNDS_MODAL,
})

export const closeWithdrawFundsModal = () => ({
  type: actionTypes.CLOSE_WITHDRAW_FUNDS_MODAL,
})

export const fetchWithdrawFundsData = () => ({
  type: actionTypes.FETCH_WITHDRAW_FUNDS_DATA,
})

export const withdrawFunds = ({
  amount,
  rate,
  method,
  invoiceNumber,
  invoiceUpload,
}) => ({
  type: actionTypes.WITHDRAW_FUNDS,
  payload: {
    amount,
    rate,
    method,
    invoiceNumber,
    invoiceUpload,
  },
})

export const rechargeFunds = ({
  amount,
  description,
  couponCode,
  totalPayment,
  stripe,
}) => ({
  type: actionTypes.RECHARGE_FUNDS,
  payload: {
    amount,
    description,
    couponCode,
    totalPayment,
    stripe,
  },
})
