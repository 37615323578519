import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import { MediaQueryDesk, MediaQueryPortable } from '../../shared/MediaQueries'
import GlobalContent from '../../shared/GlobalContent'
import Spinner from '../../loaders/Spinner'
import Avatar from '../../avatar/Avatar'
import Rating from '../../rating/Rating'
import CardLabel from '../../cards/CardLabel'
import ButtonDropdown from '../../dropdown/ButtonDropdown'
import CardBadge from '../../cards/CardBadge'
import { ReactComponent as IconDots } from '../../../styles/icons/ellipsis.svg'
import { ReactComponent as IconAlertTriangle } from '../../../styles/icons/alert-triangle.svg'
import { RESOURCE_TYPES } from '../../../services/resources/constants'
import { ReactComponent as Fav } from '../../../styles/icons/fav.svg'
import { ReactComponent as FavFilled } from '../../../styles/icons/fav-filled.svg'

const ResourceDetailContainer = ({
  image,
  name,
  location,
  category,
  description,
  type,
  rating,
  valuations,
  isNew,
  isLoading,
  isUserVerified,
  isFavourited,
  children,
  onReport,
  onFavClick,
  backLinkAction,
  i18n,
}) => (
  <GlobalContent hasPanelBackground backLinkAction={backLinkAction}>
    <div className="resource-detail">
      {isLoading ? (
        <Spinner size={75} text={i18n('status:loading')} />
      ) : (
        <>
          <Avatar iconBorder image={image} avatarName={name} channel={type} />
          <MediaQueryPortable>
            {isNew && <CardLabel type="new" />}
          </MediaQueryPortable>
          <div className="resource-detail-extra">
            <MediaQueryDesk>{isNew && <CardLabel type="new" />}</MediaQueryDesk>
            <button
              className="resource-detail-extra-action"
              onClick={onFavClick}
            >
              {isFavourited ? (
                <FavFilled className="icon rdx-button-icon filled-icon" />
              ) : (
                <Fav className="icon rdx-button-icon" />
              )}
            </button>
            <ButtonDropdown
              content={[
                {
                  buttonIcon: props => <IconAlertTriangle {...props} />,
                  isWarning: true,
                  action: onReport,
                  actionText: 'action:report-media',
                },
              ]}
              renderIcon={props => <IconDots {...props} />}
            />
          </div>
          <div className="resource-detail-wrapper">
            <div className="resource-detail-intro">
              <header className="resource-detail-header">
                <h2 className="resource-detail-name">
                  {name}
                  {isUserVerified && <CardBadge isVerified={isUserVerified} />}
                </h2>
                <Rating rating={rating} valuations={valuations} />
              </header>
              <div>
                <p className="resource-detail-location">{location}</p>
                <p className="resource-detail-category">{category}</p>
              </div>
              <p className="resource-detail-description">{description}</p>
            </div>
            <div className="resource-detail-sections">{children}</div>
          </div>
        </>
      )}
    </div>
  </GlobalContent>
)

ResourceDetailContainer.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  // First rendered time gets react-router location prop
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  category: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  rating: PropTypes.number,
  valuations: PropTypes.number,
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUserVerified: PropTypes.bool,
  isFavourited: PropTypes.bool,
  children: PropTypes.node,
  onReport: PropTypes.func,
  onFavClick: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceDetailContainer)
