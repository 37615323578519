import { withProps } from 'recompose'

// Add selected link based on the location.pathname
const withActiveLink = withProps(({ location, navItems }) => {
  const { pathname } = location

  const selectedItem = navItems.find(item =>
    new RegExp(item.match).test(pathname),
  )

  return { current: selectedItem ? selectedItem.i18nKey : undefined }
})

export default withActiveLink
