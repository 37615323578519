import React from 'react'
import PropTypes, { oneOf } from 'prop-types'
import cx from 'classnames'
import * as R from 'ramda'
import { ReactComponent as Star } from '../../styles/icons/star.svg'
import withTranslations from '../../hocs/withTranslations'

const Rating = ({ rating, valuations, variant, i18n }) => {
  const classes = cx('rating', {
    'is-empty': variant === 'empty',
    'is-filled': variant === 'fill',
  })
  return (
    <div className={classes}>
      <div className="rating-stars">
        {[...Array(5)].map((n, i) => (
          <Star key={i} className={i < rating ? 'star-filled' : 'star-empty'} />
        ))}
      </div>
      {!R.isNil(valuations) ? (
        <div className="rating-text">
          {i18n('search-result:ratings-count', { count: valuations })}
        </div>
      ) : null}
    </div>
  )
}

Rating.defaultProps = {
  variant: 'fill',
}

Rating.propTypes = {
  rating: PropTypes.number,
  valuations: PropTypes.number,
  variant: oneOf(['empty', 'fill']),
}

export default withTranslations(Rating)
