import React from 'react'
import { FormattedNumberParts, FormattedNumber, injectIntl } from 'react-intl'
import * as R from 'ramda'
import LOCALES from '../config/locales'
import { hackFormatNumber } from '../libs/i18n'

// See hackFormatNumber in i18n libs
const HackFormattedNumber = ({ intl, ...props }) => {
  // Grouping is disabled if explicitly `false`, use default component
  if (props.useGrouping === false) {
    return <FormattedNumber {...props} />
  }

  // Find our locale config
  const localeConfig = LOCALES.find(({ match }) => match.test(intl.locale))

  // We couldn't find our locale config or thousands hack is not enabled
  // Use default component
  if (!localeConfig || !localeConfig.thousandsHackEnabled) {
    return <FormattedNumber {...props} />
  }

  // Thousands hack is enabled, format to parts first
  return (
    <FormattedNumberParts {...R.omit(['children'], props)}>
      {parts => {
        // Hack parts into our expected output
        const numberString = hackFormatNumber(
          intl,
          R.omit(['value', 'children'], props),
          parts,
        )

        if (typeof props.children === 'function') {
          return props.children(numberString)
        }

        const Text = intl.textComponent

        return <Text>{numberString}</Text>
      }}
    </FormattedNumberParts>
  )
}

HackFormattedNumber.propTypes = FormattedNumber.propTypes

export default injectIntl(HackFormattedNumber)
