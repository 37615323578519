import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { SORT_TYPES } from '../../app/search/sortUtils'
import withTranslations from '../../hocs/withTranslations'
import SortResults from './SortResults'
import SortItem from './SortItem'
import SortDrop from './SortDrop'

const SortTabs = ({ value, hasWeb, hasSocialNetwork, onChange, i18n }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleCollapse = useCallback(() => setIsCollapsed(true), [
    setIsCollapsed,
  ])

  return (
    <div className="sort-tabs">
      <SortResults>
        <SortItem
          isSelected={value === SORT_TYPES.RATING}
          text={i18n('search-sort:best-rating')}
          onSort={() => onChange(SORT_TYPES.RATING)}
        />
        <SortItem
          isSelected={value === SORT_TYPES.CREATED_DESC}
          text={i18n('search-sort:newest')}
          onSort={() => onChange(SORT_TYPES.CREATED_DESC)}
        />
        <SortItem
          isSelected={value === SORT_TYPES.FOLLOWERS}
          text={i18n('search-sort:followers')}
          isDisabled={!hasSocialNetwork}
          onSort={() => onChange(SORT_TYPES.FOLLOWERS)}
        />
        <SortItem
          isSelected={value === SORT_TYPES.ENGAGEMENTS}
          text={i18n('search-sort:engagements')}
          onSort={() => onChange(SORT_TYPES.ENGAGEMENTS)}
        />
        <SortItem
          isSelected={value === SORT_TYPES.PRICE_ASC}
          text={i18n('search-sort:price-asc')}
          onSort={() => onChange(SORT_TYPES.PRICE_ASC)}
        />
        <SortDrop
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          isSelected={
            value === SORT_TYPES.CREATED_ASC ||
            value === SORT_TYPES.PRICE_DESC ||
            value === SORT_TYPES.UNIQUE_USERS_DESC ||
            value === SORT_TYPES.UNIQUE_USERS_ASC ||
            value === SORT_TYPES.PAGE_AUTHORITY_DESC ||
            value === SORT_TYPES.PAGE_AUTHORITY_ASC ||
            value === SORT_TYPES.ENGAGEMENT_RATE ||
            value === SORT_TYPES.PAGE_AUTHORITY_PRICE_RATIO ||
            value === SORT_TYPES.USERS_PRICE_RATIO
          }
        >
          <div className="sort-drop-group-title">
            {i18n('search-sort:group-date')}
          </div>
          <SortItem
            isSelected={value === SORT_TYPES.CREATED_ASC}
            text={i18n('search-sort:oldest')}
            onSort={() => {
              onChange(SORT_TYPES.CREATED_ASC)
              handleCollapse()
            }}
          />
          <div className="sort-drop-group-title">
            {i18n('search-sort:group-price')}
          </div>
          <SortItem
            isSelected={value === SORT_TYPES.PRICE_DESC}
            text={i18n('search-sort:price-desc')}
            onSort={() => {
              onChange(SORT_TYPES.PRICE_DESC)
              handleCollapse()
            }}
          />
          <div className="sort-drop-group-title">
            {i18n('search-sort:group-metrics')}
          </div>
          <SortItem
            isSelected={value === SORT_TYPES.UNIQUE_USERS_DESC}
            text={i18n('search-sort:unique-users-desc')}
            isDisabled={!hasWeb}
            onSort={() => {
              onChange(SORT_TYPES.UNIQUE_USERS_DESC)
              handleCollapse()
            }}
          />
          <SortItem
            isSelected={value === SORT_TYPES.UNIQUE_USERS_ASC}
            text={i18n('search-sort:unique-users-asc')}
            isDisabled={!hasWeb}
            onSort={() => {
              onChange(SORT_TYPES.UNIQUE_USERS_ASC)
              handleCollapse()
            }}
          />
          <SortItem
            isSelected={value === SORT_TYPES.PAGE_AUTHORITY_DESC}
            text={i18n('search-sort:page-authority-desc')}
            isDisabled={!hasWeb}
            onSort={() => {
              onChange(SORT_TYPES.PAGE_AUTHORITY_DESC)
              handleCollapse()
            }}
          />
          <SortItem
            isSelected={value === SORT_TYPES.PAGE_AUTHORITY_ASC}
            text={i18n('search-sort:page-authority-asc')}
            isDisabled={!hasWeb}
            onSort={() => {
              onChange(SORT_TYPES.PAGE_AUTHORITY_ASC)
              handleCollapse()
            }}
          />
          <SortItem
            isSelected={value === SORT_TYPES.ENGAGEMENT_RATE}
            text={i18n('search-sort:engagement-rate')}
            onSort={() => {
              onChange(SORT_TYPES.ENGAGEMENT_RATE)
              handleCollapse()
            }}
          />
          <div className="sort-drop-group-title">
            {i18n('search-sort:group-ratios')}
          </div>
          <SortItem
            isSelected={value === SORT_TYPES.PAGE_AUTHORITY_PRICE_RATIO}
            text={i18n('search-sort:pa-price')}
            isDisabled={!hasWeb}
            onSort={() => {
              onChange(SORT_TYPES.PAGE_AUTHORITY_PRICE_RATIO)
              handleCollapse()
            }}
          />
          <SortItem
            isSelected={value === SORT_TYPES.USERS_PRICE_RATIO}
            text={i18n('search-sort:users-price')}
            isDisabled={!hasWeb}
            onSort={() => {
              onChange(SORT_TYPES.USERS_PRICE_RATIO)
              handleCollapse()
            }}
          />
        </SortDrop>
      </SortResults>
    </div>
  )
}

SortTabs.propTypes = {
  value: PropTypes.oneOf(Object.values(SORT_TYPES)).isRequired,
  hasWeb: PropTypes.bool.isRequired,
  hasSocialNetwork: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withTranslations(SortTabs)
