import React from 'react'
import PropTypes from 'prop-types'
import { Input as RdxInput } from '@redradix/components.input'

const InputPrefix = ({
  autoFocus,
  errorText,
  id,
  prefix,
  isTouched,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  widthBehaviour,
  value,
  label,
  ...props
}) => {
  return (
    <div className="input-prefix">
      {label && <p className="label">{label}</p>}
      <div className="input-box">
        <span className="prefix">{prefix}</span>
        <RdxInput
          {...props}
          autoFocus={autoFocus}
          errorText={errorText}
          label={false}
          id={id}
          isTouched={isTouched}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          value={value}
          widthBehaviour={widthBehaviour}
        />
      </div>
    </div>
  )
}

InputPrefix.propTypes = {
  /** Boolean that specifies whether an <input> should automatically get focus when the page loads */
  autoFocus: PropTypes.bool,
  /** String that defines the error text of the <input> */
  errorText: PropTypes.any,
  /** String that specifies the helper text of the <input> */
  id: PropTypes.string.isRequired,
  /** Boolean that defines whether the <input> is disabled */
  isTouched: PropTypes.bool,
  /** String with the text of the <label> */
  label: PropTypes.string,
  /** String that specifies the name of an <input> */
  prefix: PropTypes.string,
  /** String that adds a prefix */
  name: PropTypes.string.isRequired,
  /** Function that is triggered when the <input> field loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when the <input> is changed */
  onChange: PropTypes.func,
  /** Function that is triggered when the <input> gets focus */
  onFocus: PropTypes.func,
  /** String that describes the expected value of the <input> */
  placeholder: PropTypes.string,
  /** String or number that specifies the value of the <input> */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** String that defines the behaviour of the input width */
  widthBehaviour: PropTypes.oneOf(['fixed', 'full']),
}

export default InputPrefix
