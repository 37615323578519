import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { FormattedMessage } from 'react-intl'
import withTranslations from '../../hocs/withTranslations'
import DashboardPanel from './DashboardPanel'
import ActionCard from '../cards/ActionCard'
import imgBalanceBig from '../cards/images/balanceBig.jpg'
import imgBalanceSmall from '../cards/images/balanceSmall.jpg'
import LoadingCard from '../cards/LoadingCard'
import HackFormattedMoney from '../HackFormattedMoney'

const BalancePanel = ({
  balance,
  i18n,
  hasTitle,
  isLoading,
  onBalanceAction,
}) => {
  return "";
}

BalancePanel.defaultProps = {}

BalancePanel.propTypes = {
  balance: CoobisPropTypes.money,
  hasTitle: PropTypes.bool,
  isLoading: PropTypes.bool,
  onBalanceAction: PropTypes.func,
}

export default withTranslations(BalancePanel)
