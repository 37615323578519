import React from 'react'
import PropTypes from 'prop-types'

const BreadcrumbNavigation = ({ children }) => {
  return (
    <nav className="breadcrumb-navigation">
      <ol className="breadcrumb-navigation-list">{children}</ol>
    </nav>
  )
}

BreadcrumbNavigation.propTypes = {
  onClickItem: PropTypes.func,
}

export default BreadcrumbNavigation
