export const DATE_FORMAT_OPTIONS = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

export const TIME_FORMAT_OPTIONS = {
  hour: '2-digit',
  minute: '2-digit',
}

export const COOBIS_CURRENCIES = {
  EUR: { isoCode: 'EUR', symbol: '€' },
  USD: { isoCode: 'USD', symbol: '$' },
}

export const COOBIS_CURRENCY_OPTIONS = {
  style: 'currency',
  currency: COOBIS_CURRENCIES.EUR.isoCode,
  symbol: COOBIS_CURRENCIES.EUR.symbol,
  useGrouping: true,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

export const COOBIS_PERCENT_OPTIONS = {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}

export const COOBIS_PERCENT_OPTIONS_NO_MIN_DECIMALS = {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
}

export const COOBIS_WITHOUT_DECIMALS = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

export const CUSTOM_FORMATS = {
  date: {
    coobis: DATE_FORMAT_OPTIONS,
  },

  time: {
    coobis: TIME_FORMAT_OPTIONS,
  },

  number: {
    coobisCurrency: COOBIS_CURRENCY_OPTIONS,
  },
}
