import {
  compose,
  withStateHandlers,
  withHandlers,
  withPropsOnChange,
  lifecycle,
} from 'recompose'
import { connect } from 'react-redux'
import { deleteRequest } from '../../services/communication'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { isNil } from 'ramda'
import { moneyToFloat } from '../utils'

const withDiscountCoupon = ({
  couponDataProp = 'couponData',
  validateCouponProp = 'validateDiscountCoupon',
  removeAppliedCouponProp = 'removeAppliedCoupon',
  requestType,
}) =>
  compose(
    withStateHandlers(
      () => ({
        discountCoupon: '',
      }),
      {
        onDiscountCouponChange: _ => e => ({
          discountCoupon: e.target.value,
        }),
      },
    ),

    connect(
      null,
      { deleteRequest },
    ),

    withHandlers({
      onCouponSubmit: ({ discountCoupon, ...restProps }) => e => {
        e.preventDefault()
        e.stopPropagation()

        const validateDiscountCoupon = restProps[validateCouponProp]

        if (!isNil(discountCoupon) && discountCoupon.length > 0) {
          validateDiscountCoupon(discountCoupon)
        }
      },
    }),

    withIsRequestPending(requestType, 'isValidatingCoupon'),
    withIsRequestSucceeded(requestType, 'isValidCoupon'),
    withRequestError(requestType, 'couponServerErrors'),
    withDeleteRequestOnUnmount(requestType),

    withPropsOnChange([couponDataProp], props => {
      const couponData = props[couponDataProp]

      if (couponData && couponData.coupon) {
        return {
          successCode: {
            percentage: couponData.coupon.discountValue,
            minAmount: moneyToFloat(couponData.coupon.minAmount),
            maxUses: couponData.coupon.maxUses,
            expirationDate: new Date(couponData.coupon.expirationDate),
          },
          errorCode: null,
        }
      }
    }),

    withPropsOnChange(['couponServerErrors'], ({ couponServerErrors }) => {
      if (couponServerErrors && couponServerErrors.globalMessages) {
        return {
          errorCode: couponServerErrors.globalMessages,
          successCode: null,
        }
      }
    }),

    lifecycle({
      componentDidUpdate(prevProps) {
        const { couponServerErrors, discountCoupon, deleteRequest } = this.props
        const { discountCoupon: prevDiscountCoupon } = prevProps

        // Clean errors when changing coupon in text
        if (
          prevDiscountCoupon !== discountCoupon &&
          couponServerErrors &&
          couponServerErrors.globalMessages
        ) {
          deleteRequest(requestType)
        }
      },

      // Remove applied coupon when leaving form
      componentWillUnmount() {
        this.props[removeAppliedCouponProp]()
      },
    }),
  )

export default withDiscountCoupon
