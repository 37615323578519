import { compose, branch, renderComponent, renderNothing } from 'recompose'
import { STATISTICS_TABS } from '../../services/campaigns/business'
import CampaignDetailWebBrandingPublication from './CampaignDetailWebBrandingPublication'
import CampaignDetailFacebookPublication from './CampaignDetailFacebookPublication'
import CampaignDetailInstagramPublication from './CampaignDetailInstagramPublication'
import CampaignDetailTwitterPublication from './CampaignDetailTwitterPublication'
import CampaignDetailYoutubePublication from './CampaignDetailYoutubePublication'
import CampaignDetailTwitchPublication from './CampaignDetailTwitchPublication'
import CampaignDetailTiktokPublication from './CampaignDetailTiktokPublication'
import CampaignDetailWebSeoURL from './CampaignDetailWebSeoURL'

const enhance = compose(
  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.WEB_BRANDING,
    renderComponent(CampaignDetailWebBrandingPublication),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.WEB_SEO,
    renderComponent(CampaignDetailWebSeoURL),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.FACEBOOK,
    renderComponent(CampaignDetailFacebookPublication),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.TWITTER,
    renderComponent(CampaignDetailTwitterPublication),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.INSTAGRAM,
    renderComponent(CampaignDetailInstagramPublication),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.YOUTUBE,
    renderComponent(CampaignDetailYoutubePublication),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.TWITCH,
    renderComponent(CampaignDetailTwitchPublication),
  ),

  branch(
    ({ resourceType }) => resourceType === STATISTICS_TABS.TIKTOK,
    renderComponent(CampaignDetailTiktokPublication),
  ),
)

export default enhance(renderNothing)
