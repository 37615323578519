import { compose } from 'recompose'
import * as Yup from 'yup'
import { CREATE_CAMPAIGN } from '../../services/campaigns/action-types'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation } from '../common/validation'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import { withCampaigns } from '../../services/campaigns/hocs'
import CreateCampaignModal from '../../ui/modals/CreateCampaignModal'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18nValidation.required),
})

const enhance = compose(
  withCampaigns,

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      name: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.createCampaign(values.name)
    },
  }),

  withIsRequestPending(CREATE_CAMPAIGN),
  withIsRequestSucceeded(CREATE_CAMPAIGN),
  withRequestError(CREATE_CAMPAIGN),
  withDeleteRequestOnUnmount(CREATE_CAMPAIGN),
  withFormErrors(['name']),
)

export default enhance(CreateCampaignModal)
