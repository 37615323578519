import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import ChipBasic from '../chips/ChipBasic'
import PaperInfo from '../papers/PaperInfo'
import { AuthScope } from '../../services/auth/business'
import { renderDateTimeValue } from '../../app/utils'

const OrderDetailDates = ({
  acceptanceDate,
  approvalDate,
  reviewDeliveryDate,
  reviewDate,
  deliveryDate,
  hasExpressDelivery,
  scope,
  i18n,
  i18nDate,
  i18nTime,
}) => {
  return (
    <OrderDetailSection title={i18n('sections:dates')}>
      {hasExpressDelivery ? (
        <PaperInfo
          title={i18n('order-detail:express-delivery-title')}
          type="express"
        >
          {i18n('order-detail:express-delivery-description')}
        </PaperInfo>
      ) : (
        <>
          {acceptanceDate && (
            <ChipBasic
              title={i18n('noun:acceptance-date')}
              subtitle={i18n(
                scope === AuthScope.BRAND
                  ? 'description:acceptance-date-brand'
                  : 'description:acceptance-date-media',
              )}
              text={renderDateTimeValue(i18nDate, i18nTime, acceptanceDate)}
            />
          )}
          {reviewDeliveryDate && (
            <ChipBasic
              title={i18n('noun:submission-review-date')}
              subtitle={i18n(
                scope === AuthScope.BRAND
                  ? 'description:submission-review-date-brand'
                  : 'description:submission-review-date-media',
              )}
              text={renderDateTimeValue(i18nDate, i18nTime, reviewDeliveryDate)}
            />
          )}
          {reviewDate && (
            <ChipBasic
              title={i18n('noun:review-date')}
              subtitle={i18n(
                scope === AuthScope.BRAND
                  ? 'description:review-date-brand'
                  : 'description:review-date-media',
              )}
              text={renderDateTimeValue(i18nDate, i18nTime, reviewDate)}
            />
          )}
          {deliveryDate && (
            <ChipBasic
              title={i18n('noun:delivery-date')}
              subtitle={i18n(
                scope === AuthScope.BRAND
                  ? 'description:delivery-date-brand'
                  : 'description:delivery-date-media',
              )}
              text={renderDateTimeValue(i18nDate, i18nTime, deliveryDate)}
            />
          )}
          {approvalDate && (
            <ChipBasic
              title={i18n('noun:approval-date')}
              subtitle={i18n(
                scope === AuthScope.BRAND
                  ? 'description:approval-date-brand'
                  : 'description:approval-date-media',
              )}
              text={renderDateTimeValue(i18nDate, i18nTime, approvalDate)}
            />
          )}
        </>
      )}
    </OrderDetailSection>
  )
}

OrderDetailDates.propTypes = {
  acceptanceDate: PropTypes.string,
  approvalDate: PropTypes.string,
  reviewDeliveryDate: PropTypes.string,
  reviewDate: PropTypes.string,
  deliveryDate: PropTypes.string,
  hasExpressDelivery: PropTypes.bool,
  scope: PropTypes.oneOf(Object.values(AuthScope)).isRequired,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailDates)
