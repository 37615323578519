import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import CampaignDetailSlider from '../CampaignDetailSlider'
import CampaignDetailSlide from '../CampaignDetailSlide'
import usePermanentScript from '../../../hooks/usePermanentScript'

const CampaignPreviewInstagram = ({ items }) => {
  const embedTimeoutRef = useRef()

  usePermanentScript('https://www.instagram.com/embed.js')

  useEffect(() => {
    if (window.instgrm && window.instgrm.Embeds) {
      // This is wrapped in a timeout to avoid Instagram's embed choking for
      // a few seconds
      if (embedTimeoutRef.current) {
        clearTimeout(embedTimeoutRef.current)
      }

      embedTimeoutRef.current = setTimeout(() => {
        window.instgrm.Embeds.process()
      }, 1)
    }
  }, [items])

  return (
    <CampaignDetailSlider>
      {items.map((item, i) => (
        <CampaignDetailSlide type="instagram" key={i}>
          <div dangerouslySetInnerHTML={{ __html: item }}></div>
        </CampaignDetailSlide>
      ))}
    </CampaignDetailSlider>
  )
}

CampaignPreviewInstagram.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
}

export default CampaignPreviewInstagram
