import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailTwitterPublication from '../../ui/campaigns/views/CampaignDetailTwitterPublication'
import withExportReportContents from './withExportReportContents'

const enhance = compose(
  withPropsOnChange(['stats'], ({ stats }) => ({
    engagementRate: stats.kpis.engagementRate,
    engagements: stats.kpis.engagements,
    impressions: stats.kpis.impressions,
    likes: stats.kpis.likes,
    retweets: stats.kpis.retweets,
    comments: stats.kpis.comments,
  })),
  withExportReportContents(),
)

export default enhance(CampaignDetailTwitterPublication)
