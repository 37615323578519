import { call, take, fork, cancel } from 'redux-saga/effects'
import * as actionTypes from './communication/action-types'

export function* waitCommunicationResult(requestType) {
  const result = yield take(
    ({ type, payload }) =>
      (type === actionTypes.SUCCESS || type === actionTypes.ERROR) &&
      payload.requestType === requestType,
  )

  return result.type === actionTypes.SUCCESS
}

export function takeLatestPerKey(
  patternOrChannel,
  worker,
  keySelector,
  ...args
) {
  return fork(function*() {
    const tasks = {}
    while (true) {
      const action = yield take(patternOrChannel)
      const key = yield call(keySelector, action)
      if (tasks[key] && tasks[key].isRunning()) {
        yield cancel(tasks[key])
      }
      tasks[key] = yield fork(worker, ...args, action)
    }
  })
}

export function takeLeadingPerKey(
  patternOrChannel,
  worker,
  keySelector,
  ...args
) {
  return fork(function*() {
    const tasks = {}
    while (true) {
      const action = yield take(patternOrChannel)
      const key = yield call(keySelector, action)
      if (!tasks[key] || !tasks[key].isRunning()) {
        tasks[key] = yield fork(worker, ...args, action)
      }
    }
  })
}
