import React from 'react'
import PropTypes from 'prop-types'
import CampaignDetailSlider from '../CampaignDetailSlider'
import CampaignDetailSlide from '../CampaignDetailSlide'
import useScript from '../../../hooks/useScript'

const CampaignPreviewTiktok = ({ items }) => {
  useScript('https://www.tiktok.com/embed.js')
  return (
    <CampaignDetailSlider>
      {items.map((item, i) => (
        <CampaignDetailSlide type="tiktok" key={i}>
          <div dangerouslySetInnerHTML={{ __html: item }}></div>
        </CampaignDetailSlide>
      ))}
    </CampaignDetailSlider>
  )
}

CampaignPreviewTiktok.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
}

export default CampaignPreviewTiktok
