import React from 'react'
import PropTypes from 'prop-types'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import * as R from 'ramda'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import withTranslations from '../../hocs/withTranslations'
import Button from '@redradix/components.button'
import TabsTitle from '../texts/TabsTitle'

import Input from '../form-elements/Input'
import Textarea from '../form-elements/Textarea'

import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import FormField from '../form-elements/FormField'
import FormFieldRow from '../form-elements/FormFieldRow'
import InputNumberControls from '../form-elements/InputNumberControls'
import { ReactComponent as Up } from '../../styles/icons/chevron-up.svg'
import { ReactComponent as Down } from '../../styles/icons/chevron-down.svg'
import InputFile from '../form-elements/InputFile'
import Checkbox from '@redradix/components.checkbox'

const ALLOWED_DOCUMENT_EXTENSIONS = '.jpg,.png,.pdf,.docx'

const BriefingYoutubeForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  title,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,

  downloadFileLink,
}) => {
  return (
    <form className="briefing-form" onSubmit={handleSubmit}>
      <div className="tabs-header">
        <TabsTitle text={title} />
      </div>
      <Textarea
        name="description"
        label={i18n('checkout:post-description')}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('checkout:post-description-long')}
        isTouched={touched.description}
        isDisabled={isRequestPending}
        isRequired
        errorText={
          validationMessages.description && (
            <FormattedFormErrors errors={validationMessages.description} />
          )
        }
      />
      <Input
        type="text"
        name="titleWords"
        label={i18n('checkout:title-words')}
        placeholder={i18n('checkout:words-explanation')}
        widthBehaviour="full"
        value={values.titleWords}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.titleWords}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.titleWords && (
            <FormattedFormErrors errors={validationMessages.titleWords} />
          )
        }
      />
      <Input
        type="text"
        name="descriptionWords"
        label={i18n('checkout:description-words')}
        placeholder={i18n('checkout:words-explanation')}
        widthBehaviour="full"
        value={values.descriptionWords}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.descriptionWords}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.descriptionWords && (
            <FormattedFormErrors errors={validationMessages.descriptionWords} />
          )
        }
      />
      <Input
        type="text"
        name="hashtags"
        label={i18n('checkout:description-tags')}
        placeholder={i18n('checkout:description-tags')}
        widthBehaviour="full"
        value={values.hashtags}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.hashtags}
        isDisabled={isRequestPending}
        isMulti={true}
        multiPrefix="#"
        errorText={
          validationMessages.hashtags && (
            <FormattedFormErrors errors={validationMessages.hashtags} />
          )
        }
      />
      <Checkbox
        name="isAdvertising"
        checked={values.isAdvertising}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.isAdvertising}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.isAdvertising && (
            <FormattedFormErrors errors={validationMessages.isAdvertising} />
          )
        }
      >
        <span className="text">{i18n('checkout:advertising-warning')}</span>
      </Checkbox>
      <Input
        type="text"
        name="url"
        label={i18n('checkout:link-url')}
        placeholder={i18n('checkout:link-url')}
        widthBehaviour="full"
        value={values.url}
        onChange={handleChange}
        onBlur={handleBlur}
        isTouched={touched.url}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.url && (
            <FormattedFormErrors errors={validationMessages.url} />
          )
        }
      />
      <FormFieldRow compact>
        <FormField
          label={i18n('checkout:min-duration')}
          // Required for showing errors
          isTouched={touched.minVideoLength}
          errorText={
            validationMessages.minVideoLength && (
              <FormattedFormErrors
                errors={[
                  ...(Array.isArray(validationMessages.minVideoLength)
                    ? validationMessages.minVideoLength
                    : []),
                  ...R.pathOr(
                    [],
                    ['minVideoLength', 'minutes'],
                    validationMessages,
                  ),
                  ...R.pathOr(
                    [],
                    ['minVideoLength', 'seconds'],
                    validationMessages,
                  ),
                ]}
              />
            )
          }
        >
          <InputNumberControls
            id="inputNumber1"
            name="minVideoLength.minutes"
            onBlur={() => handleBlur({ target: { name: 'minVideoLength' } })}
            onChange={handleChange}
            isDisabled={isRequestPending}
            placeholder="00"
            renderUpIcon={props => <Up {...props} />}
            renderDownIcon={props => <Down {...props} />}
            widthBehaviour="fixed"
            value={R.path(['minVideoLength', 'minutes'], values)}
            isTouched={R.path(['minVideoLength', 'minutes'], touched)}
            min={0}
          />
          <InputNumberControls
            id="inputNumber2"
            name="minVideoLength.seconds"
            onBlur={() => handleBlur({ target: { name: 'minVideoLength' } })}
            onChange={handleChange}
            isDisabled={isRequestPending}
            placeholder="00"
            renderUpIcon={props => <Up {...props} />}
            renderDownIcon={props => <Down {...props} />}
            widthBehaviour="fixed"
            value={R.path(['minVideoLength', 'seconds'], values)}
            isTouched={R.path(['minVideoLength', 'seconds'], touched)}
            min={0}
            max={59}
          />
        </FormField>
        <FormField
          label={i18n('checkout:max-duration')}
          // Required for showing errors
          isTouched={touched.maxVideoLength}
          errorText={
            validationMessages.maxVideoLength && (
              <FormattedFormErrors
                errors={[
                  ...(Array.isArray(validationMessages.maxVideoLength)
                    ? validationMessages.maxVideoLength
                    : []),
                  ...R.pathOr(
                    [],
                    ['maxVideoLength', 'minutes'],
                    validationMessages,
                  ),
                  ...R.pathOr(
                    [],
                    ['maxVideoLength', 'seconds'],
                    validationMessages,
                  ),
                ]}
              />
            )
          }
        >
          <InputNumberControls
            id="inputNumber3"
            name="maxVideoLength.minutes"
            // Required for showing errors
            onBlur={() => handleBlur({ target: { name: 'maxVideoLength' } })}
            onChange={handleChange}
            isDisabled={isRequestPending}
            placeholder="00"
            renderUpIcon={props => <Up {...props} />}
            renderDownIcon={props => <Down {...props} />}
            widthBehaviour="fixed"
            value={R.path(['maxVideoLength', 'minutes'], values)}
            isTouched={R.path(['maxVideoLength', 'minutes'], touched)}
            min={0}
          />
          <InputNumberControls
            id="inputNumber4"
            name="maxVideoLength.seconds"
            // Required for showing errors
            onBlur={() => handleBlur({ target: { name: 'maxVideoLength' } })}
            onChange={handleChange}
            isDisabled={isRequestPending}
            placeholder="00"
            renderUpIcon={props => <Up {...props} />}
            renderDownIcon={props => <Down {...props} />}
            widthBehaviour="fixed"
            value={R.path(['maxVideoLength', 'seconds'], values)}
            isTouched={R.path(['maxVideoLength', 'seconds'], touched)}
            min={0}
            max={59}
          />
        </FormField>
      </FormFieldRow>
      <InputFile
        label={i18n('checkout:images-videos-docs')}
        name="file"
        widthBehaviour="full"
        id="file"
        accept={ALLOWED_DOCUMENT_EXTENSIONS}
        linkText={i18n('action:download-file')}
        linkUrl={downloadFileLink}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('action:upload-document')}
        fileName={R.path(['file', 'name'], values)}
        isUploaded={R.path(['file', 'isUploaded'], values)}
        isTouched={touched.file}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.file && (
            <FormattedFormErrors errors={validationMessages.file} />
          )
        }
      />
      <Textarea
        name="urlSocialNetworks"
        label={i18n('checkout:description-social-network')}
        value={values.urlSocialNetworks}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={i18n('checkout:description-social-network')}
        isTouched={touched.urlSocialNetworks}
        isDisabled={isRequestPending}
        errorText={
          validationMessages.urlSocialNetworks && (
            <FormattedFormErrors
              errors={validationMessages.urlSocialNetworks}
            />
          )
        }
      />

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}
      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  description: PropTypes.string,
  titleWords: PropTypes.string,
  descriptionWords: PropTypes.string,
  hashtags: PropTypes.string,
  url: PropTypes.string,
  minVideoLength: CoobisPropTypes.duration,
  maxVideoLength: CoobisPropTypes.duration,
  file: PropTypes.any,
  urlSocialNetworks: PropTypes.string,
  isAdvertising: PropTypes.bool,
}

BriefingYoutubeForm.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  downloadFileLink: PropTypes.string,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(BriefingYoutubeForm)
