import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import ChartCard from '../../cards/ChartCard'
import MultilineChart from '../../charts/MultilineChart'

const CampaignDetailWebSeo = ({
  publicationsTableData,
  i18n,
  // Metrics values
  webPublications,
  domainAuthority,
  pageAuthority,
  authorityEvolutionData,
  linksEvolutionData,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        <AnalyticsKpi
          iconType="megaphone"
          value={webPublications}
          label={i18n('metrics:web-publications')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="da"
          value={domainAuthority}
          label={i18n('metrics:domain-authority')}
          hasInfo
          infoText={i18n('reports:web-seo-da-tooltip')}
          hasFixedWidthInfo
        />
        <AnalyticsKpi
          iconType="pa"
          value={pageAuthority}
          label={i18n('metrics:page-authority')}
          hasInfo
          infoText={i18n('reports:web-seo-pa-tooltip')}
          hasFixedWidthInfo
        />
      </div>
      <div className="campaign-detail-section-row">
        <div className="authority-evolution campaign-detail-section-cell one-whole">
          <ChartCard title={i18n('metrics:authority-evolution')}>
            <MultilineChart data={authorityEvolutionData} />
          </ChartCard>
        </div>
      </div>
      <div className="campaign-detail-section-row">
        <div className="links-evolution campaign-detail-section-cell one-whole">
          <ChartCard title={i18n('metrics:links-evolution')}>
            <MultilineChart data={linksEvolutionData} />
          </ChartCard>
        </div>
      </div>
    </>
  )
}

CampaignDetailWebSeo.propTypes = {
  i18n: PropTypes.func.isRequired,
  // Metrics values
  webPublications: PropTypes.number,
  domainAuthority: PropTypes.number,
  pageAuthority: PropTypes.number,
  authorityEvolutionData: PropTypes.array,
  linksEvolutionData: PropTypes.array,
}

export default withTranslations(CampaignDetailWebSeo)
