import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import Checkbox from '@redradix/components.checkbox'
import Panel from '../panels/Panel'
import InputSuffix from '../form-elements/InputSuffix'
import PaperInfo from '../papers/PaperInfo'
import { MediaQueryPortable, MediaQueryDesk } from '../shared/MediaQueries'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import Tooltip from '@redradix/components.tooltip'
import FormattedCurrency from '../FormattedCurrency'

const getTooltipWithOptions = (options, i18n) => {
  return (
    <Tooltip
      position={options.position}
      widthFixed={options.widthFixed}
      renderTooltip={() => (
        <>
          <TooltipRegularText text={i18n('web-resources:prices-tooltip')} />
        </>
      )}
      renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
        <TooltipTrigger
          classNames={triggerClasses}
          onMouseOver={open}
          onMouseOut={close}
          ref={triggerRef}
        />
      )}
    />
  )
}

const TiktokServicesPrices = ({
  values,
  calculatedValues,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  isPricesVipResource,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  const touchedAndWithErrors = useCallback(
    fieldName =>
      touched[fieldName] &&
      validationMessages[fieldName] &&
      Array.isArray(validationMessages[fieldName]),
    [touched, validationMessages],
  )

  return (
    <form className="basic-form" onSubmit={handleSubmit}>
      <div className="indented-block">
        <Checkbox
          name="shortEnabled"
          checked={values.shortEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.shortEnabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('social-resource:tiktok-short-title')}</p>
          <p className="subtitle">
            {i18n('social-resource:tiktok-long-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="shortBasePrice"
              name="shortBasePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.shortBasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.shortBasePrice}
              isDisabled={
                isRequestPending || !isPricesVipResource || !values.shortEnabled
              }
              suffix={<FormattedCurrency value={values.shortCurrency} />}
            />
            <InputSuffix
              type="number"
              id="shortDiscount"
              name="shortDiscount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              value={values.shortDiscount}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.shortDiscount}
              isDisabled={isRequestPending || !values.shortEnabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="shortTotalPrice"
              name="shortTotalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.shortTotalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.shortCurrency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('shortBasePrice') ||
          touchedAndWithErrors('shortDiscount') ||
          validationMessages.shortTotalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.shortBasePrice || []),
                  ...(validationMessages.shortDiscount || []),
                  ...(validationMessages.shortTotalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>
      <div className="indented-block">
        <Checkbox
          name="longEnabled"
          checked={values.longEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.longEnabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('social-resource:tiktok-long-title')}</p>
          <p className="subtitle">
            {i18n('social-resource:tiktok-long-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="longBasePrice"
              name="longBasePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.longBasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.longBasePrice}
              isDisabled={
                isRequestPending || !isPricesVipResource || !values.longEnabled
              }
              suffix={<FormattedCurrency value={values.longCurrency} />}
            />
            <InputSuffix
              type="number"
              id="longDiscount"
              name="longDiscount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              value={values.longDiscount}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.longDiscount}
              isDisabled={isRequestPending || !values.longEnabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="longTotalPrice"
              name="longTotalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.longTotalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.longCurrency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('longBasePrice') ||
          touchedAndWithErrors('longDiscount') ||
          validationMessages.longTotalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.longBasePrice || []),
                  ...(validationMessages.longDiscount || []),
                  ...(validationMessages.longTotalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>

      <div className="indented-block">
        <PaperInfo type="notice">
          {i18n('social-resource:price-considerations')}
        </PaperInfo>
      </div>

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  shortEnabled: PropTypes.bool,
  shortBasePrice: PropTypes.number,
  shortDiscount: PropTypes.number,
  longEnabled: PropTypes.bool,
  longBasePrice: PropTypes.number,
  longDiscount: PropTypes.number,
  currency: PropTypes.string,
}

TiktokServicesPrices.propTypes = {
  calculatedValues: PropTypes.shape({
    shortTotalPrice: PropTypes.number,
    longTotalPrice: PropTypes.number,
  }),
  isPricesVipResource: PropTypes.bool,
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(TiktokServicesPrices)
