import React from 'react'
import { compose, withProps, withPropsOnChange } from 'recompose'
import * as Yup from 'yup'
import * as R from 'ramda'
import WebResources from '../../ui/web-resources/WebResources'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withTranslations from '../../hocs/withTranslations'
import { WEB_RESOURCE_SECTIONS } from '../../services/resources/constants'
import { editWebResourceServicesPrices } from '../../services/resources'
import { EDIT_WEB_RESOURCE_SERVICES_PRICES } from '../../services/resources/action-types'
import WebResourcesServicesPricesForm from '../../ui/web-resources/WebResourcesServicesPrices'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { floatToMoney } from '../utils'
import { i18nValidation } from '../common/validation'
import { connect } from 'react-redux'
import withWebResource from './withWebResource'
import { SERVICE_TYPES } from '../../services/orders/business'
import { calculateTotalPrice } from './util'

const validationSchema = Yup.object().shape({
  basePrice: Yup.number()
    .required(i18nValidation.required)
    .min(0, i18nValidation.min),
  discount: Yup.number()
    .required(i18nValidation.required)
    .min(0, i18nValidation.min)
    .max(100, i18nValidation.max),
  broadcastBasePrice: Yup.number()
    .required(i18nValidation.required)
    .min(0, i18nValidation.min),
  enabled: Yup.boolean().required(i18nValidation.required),

  postBasePrice: Yup.number()
    .required(i18nValidation.required)
    .min(0, i18nValidation.min),
  postDiscount: Yup.number()
    .required(i18nValidation.required)
    .min(0, i18nValidation.min)
    .max(100, i18nValidation.max),
  postBroadcastBasePrice: Yup.number()
    .required(i18nValidation.required)
    .min(0, i18nValidation.min),
  postEnabled: Yup.boolean().required(i18nValidation.required),
})

const WebResourcesServicesPrices = ({ i18n, ...props }) => (
  <WebResources
    title={i18n('sections:services-prices-long')}
    currentNavItem={WEB_RESOURCE_SECTIONS.SERVICES}
    reachedNavItem={props.reachedNavItem}
    isEditing={props.isEditing}
    resourceId={props.resourceId}
  >
    <WebResourcesServicesPricesForm {...props} />
  </WebResources>
)

const hasTotalPriceError = ({ totalPrice = 0 }, touched) =>
  totalPrice < 10 && (touched.basePrice || touched.discount)

const hasPostTotalPriceError = ({ postTotalPrice = 0 }, touched) =>
  postTotalPrice < 10 && (touched.postBasePrice || touched.postDiscount)

const hasBroadcastTotalPriceError = ({ broadcastTotalPrice = 0 }, touched) =>
  broadcastTotalPrice < 10 && (touched.broadcastBasePrice || touched.discount)

const hasPostBroadcastTotalPriceError = (
  { postBroadcastTotalPrice = 0 },
  touched,
) =>
  postBroadcastTotalPrice < 10 &&
  (touched.postBroadcastBasePrice || touched.postDiscount)

const hasCalculatedPricesErrors = (
  calculatedValues = {},
  acceptsBroadcasts,
) => {
  const touched = { basePrice: true, discount: true, broadcastBasePrice: true }
  const postTouched = {
    postBasePrice: true,
    postDiscount: true,
    postBroadcastBasePrice: true,
  }

  return (
    hasTotalPriceError(calculatedValues, touched) ||
    (acceptsBroadcasts
      ? hasBroadcastTotalPriceError(calculatedValues, touched)
      : false) ||
    hasPostTotalPriceError(calculatedValues, postTouched) ||
    (acceptsBroadcasts
      ? hasPostBroadcastTotalPriceError(calculatedValues, postTouched)
      : false)
  )
}

const calculatePrices = values => ({
  totalPrice: calculateTotalPrice(values.basePrice, values.discount),
  postTotalPrice: calculateTotalPrice(
    values.postBasePrice,
    values.postDiscount,
  ),
  broadcastTotalPrice: calculateTotalPrice(
    values.broadcastBasePrice,
    values.discount,
  ),
  postBroadcastTotalPrice: calculateTotalPrice(
    values.postBroadcastBasePrice,
    values.postDiscount,
  ),
})

const enhance = compose(
  withTranslations,

  withWebResource(WEB_RESOURCE_SECTIONS.SERVICES),

  connect(
    null,
    {
      editWebResourceServicesPrices,
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ resourceData }) => ({
      basePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.WEB_POST, 'basePrice'],
        resourceData,
      ),
      discount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.WEB_POST, 'discount'],
        resourceData,
      ),
      broadcastBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.WEB_POST, 'broadcastBasePrice'],
        resourceData,
      ),
      enabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.WEB_POST, 'enabled'],
            resourceData,
          ),
      currency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.WEB_POST, 'currency'],
        resourceData,
      ),
      broadcastCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.WEB_POST, 'broadcastCurrency'],
        resourceData,
      ),
      postBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.WEB_ONLY_POST, 'basePrice'],
        resourceData,
      ),
      postDiscount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.WEB_ONLY_POST, 'discount'],
        resourceData,
      ),
      postBroadcastBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.WEB_ONLY_POST, 'broadcastBasePrice'],
        resourceData,
      ),
      postEnabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.WEB_ONLY_POST, 'enabled'],
            resourceData,
          ),
      postCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.WEB_ONLY_POST, 'currency'],
        resourceData,
      ),
      postBroadcastCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.WEB_ONLY_POST, 'broadcastCurrency'],
        resourceData,
      ),
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      if (
        hasCalculatedPricesErrors(
          calculatePrices(values),
          props.acceptsBroadcasts,
        )
      ) {
        return
      }

      const { enabled, postEnabled } = values
      const valuesToSend = [
        enabled
          ? {
              serviceType: SERVICE_TYPES.WEB_POST,
              enabled: true,
              basePrice: floatToMoney(values.basePrice, values.currency),
              broadcastBasePrice: floatToMoney(
                values.broadcastBasePrice,
                values.broadcastCurrency,
              ),
              discount: values.discount,
            }
          : { serviceType: SERVICE_TYPES.WEB_POST, enabled: false },
        postEnabled
          ? {
              serviceType: SERVICE_TYPES.WEB_ONLY_POST,
              enabled: true,
              basePrice: floatToMoney(
                values.postBasePrice,
                values.postCurrency,
              ),
              broadcastBasePrice: floatToMoney(
                values.postBroadcastBasePrice,
                values.postBroadcastCurrency,
              ),
              discount: values.postDiscount,
            }
          : { serviceType: SERVICE_TYPES.WEB_ONLY_POST, enabled: false },
      ]

      props.editWebResourceServicesPrices(props.resourceId, valuesToSend)
    },
  }),

  withIsRequestPending(EDIT_WEB_RESOURCE_SERVICES_PRICES),
  withIsRequestSucceeded(EDIT_WEB_RESOURCE_SERVICES_PRICES),
  withRequestError(EDIT_WEB_RESOURCE_SERVICES_PRICES),
  withDeleteRequestOnUnmount(EDIT_WEB_RESOURCE_SERVICES_PRICES),

  withFormErrors([
    'basePrice',
    'discount',
    'broadcastBasePrice',
    'enabled',
    'postBasePrice',
    'postDiscount',
    'postBroadcastBasePrice',
    'postEnabled',
  ]),

  withProps(({ resourceData }) => ({
    isPricesVipResource: resourceData.isVipPrices,
    acceptsBroadcasts: R.pathOr(
      false,
      ['socialNetworks', 'acceptsBroadcast'],
      resourceData,
    ),
  })),

  // Change final prices when changes offer
  withPropsOnChange(['values'], ({ values }) => ({
    calculatedValues: calculatePrices(values),
  })),

  // Inject calculatedPrices errors
  withPropsOnChange(
    ['calculatedValues', 'validationMessages', 'touched'],
    ({ calculatedValues, validationMessages, touched }) => {
      const showTotalPriceError = hasTotalPriceError(calculatedValues, touched)

      const showBroadcastTotalPriceError = hasBroadcastTotalPriceError(
        calculatedValues,
        touched,
      )

      return {
        validationMessages: {
          ...validationMessages,
          totalPrice: showTotalPriceError
            ? [i18nValidation.min({ path: 'totalPrice', min: 10 })]
            : undefined,
          broadcastTotalPrice: showBroadcastTotalPriceError
            ? [i18nValidation.min({ path: 'broadcastTotalPrice', min: 10 })]
            : undefined,
        },
      }
    },
  ),
)

export default enhance(WebResourcesServicesPrices)
