import React, { useState } from 'react'
import ConfirmSubmitModal from '../ui/modals/ConfirmSubmitModal'

const withConfirmSubmitModal = (
  {
    title,
    description,
    submitText,
    cancelText,
    isSmallSized,
    isConfirmRequiredProp,
  } = { isConfirmRequiredProp: 'isConfirmRequired' },
) => BaseComponent => ({ handleSubmit, ...props }) => {
  const [isConfirmSubmitModalOpen, setConfirmSubmitModalOpen] = useState(false)

  return (
    <>
      <BaseComponent
        handleSubmit={
          props[isConfirmRequiredProp]
            ? e => {
                e.preventDefault()
                e.stopPropagation()
                setConfirmSubmitModalOpen(true)
              }
            : handleSubmit
        }
        {...props}
      />

      {isConfirmSubmitModalOpen && (
        <ConfirmSubmitModal
          title={props.i18n(title)}
          description={props.i18n(description)}
          submitText={props.i18n(submitText)}
          cancelText={props.i18n(cancelText)}
          onSubmit={e => {
            setConfirmSubmitModalOpen(false)
            handleSubmit(e)
          }}
          onCancel={e => setConfirmSubmitModalOpen(false)}
          isSmallSized={isSmallSized}
          isSubmitInProgress={false}
        />
      )}
    </>
  )
}

export default withConfirmSubmitModal
