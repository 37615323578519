import * as hash from 'reduken/hash'
import { createSelector } from 'reselect'
import { isNilOrEmpty } from '../../app/utils'
import { DOMAIN, HASH_KEY_TOAST_DATA } from './constants'

export const getToast = hash.getFromPath(DOMAIN, HASH_KEY_TOAST_DATA)

export const hasToast = createSelector(
  getToast,
  toast => !isNilOrEmpty(toast),
)
