import * as actionTypes from './action-types'
import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { takeLeadingPerKey } from '../saga-utils'
import {
  updateEntities,
  updateEntity,
  removeAll,
  mergeEntities,
} from 'reduken/entities'
import * as hash from 'reduken/hash'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
  startRequestWithId,
  endRequestWithIdError,
  endRequestWithIdSuccess,
  deleteRequest,
} from '../communication/actions'
import * as api from './api'
import * as R from 'ramda'
import { entitiesArrayToKeyedObject, isNilOrEmpty } from '../../app/utils'
import {
  ENTITY_KEY_RESOURCES,
  HASH_KEY_RESOURCES_META,
  RESOURCES_PER_PAGE,
  DOMAIN,
  HASH_KEY_LAST_TEMPORARY_IMAGE,
  ENTITY_KEY_RESOURCE_DETAILS,
  getSocialProfilesDomain,
  getSocialDataDomain,
  ENTITY_KEY_RESOURCE_STATUS,
} from './constants'
import { push } from 'connected-react-router'
import {
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID,
  ROUTE_MEDIA_RESOURCES,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID,
  ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES,
  prettifyConstant,
} from '../../app/common/routes'
import { parseResourcePrices } from './business'
import { getResourceById } from './selectors'
import { addWarningToast } from '../toasts'
import { SOCIAL_SERVICES } from './constants'

function* fetchResourcesSaga({ payload: { page, itemsPerPage } }) {
  yield put(startRequest(actionTypes.FETCH_RESOURCES))

  const response = yield call(api.fetchResources, page, itemsPerPage)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_RESOURCES, response))
    return
  }

  yield put(
    hash.set(
      DOMAIN,
      HASH_KEY_RESOURCES_META,
      R.path(['data', 'meta'], response),
    ),
  )

  const resources = R.pipe(
    R.path(['data', 'data']),
    R.map(parseResourcePrices),
  )(response)

  yield put(removeAll(ENTITY_KEY_RESOURCES))
  yield put(
    updateEntities({
      [ENTITY_KEY_RESOURCES]: entitiesArrayToKeyedObject(resources),
    }),
  )

  yield put(endRequestSuccess(actionTypes.FETCH_RESOURCES))
}

function* fetchResourceByIdSaga({ payload: { id } }) {
  yield put(startRequestWithId(actionTypes.FETCH_RESOURCE_BY_ID, id))

  const response = yield call(api.fetchResourceById, id)

  if (response.error) {
    yield put(
      endRequestWithIdError(actionTypes.FETCH_RESOURCE_BY_ID, id, response),
    )
    return
  }

  const parsedResource = parseResourcePrices(response.data)

  yield put(
    updateEntity(ENTITY_KEY_RESOURCES, response.data.id, parsedResource),
  )

  yield put(endRequestWithIdSuccess(actionTypes.FETCH_RESOURCE_BY_ID, id))
}

function* resourceImageSaga(action) {
  switch (action.type) {
    case actionTypes.REQUEST_SCREENSHOT: {
      yield put(deleteRequest(actionTypes.UPLOAD_RESOURCE_IMAGE))
      yield call(requestScreenshotSaga, action)
      return
    }

    case actionTypes.UPLOAD_RESOURCE_IMAGE: {
      yield put(deleteRequest(actionTypes.REQUEST_SCREENSHOT))
      yield call(uploadResourceImageSaga, action)
      return
    }

    default:
      throw new Error(`Unexpected actionType: ${action.type}`)
  }
}

function* requestScreenshotSaga({ payload: { url } }) {
  yield put(startRequest(actionTypes.REQUEST_SCREENSHOT))

  const response = yield call(api.requestScreenshot, url)

  if (response.error) {
    yield put(endRequestError(actionTypes.REQUEST_SCREENSHOT, response))
    return
  }

  yield put(
    hash.set(DOMAIN, HASH_KEY_LAST_TEMPORARY_IMAGE, {
      ...response.data,
      captureURL: url,
    }),
  )

  yield put(endRequestSuccess(actionTypes.REQUEST_SCREENSHOT))
}

function* uploadResourceImageSaga({ payload: { file } }) {
  yield put(startRequest(actionTypes.UPLOAD_RESOURCE_IMAGE))

  const response = yield call(api.uploadResourceImage, file)

  if (response.error) {
    yield put(endRequestError(actionTypes.UPLOAD_RESOURCE_IMAGE, response))
    return
  }

  yield put(hash.set(DOMAIN, HASH_KEY_LAST_TEMPORARY_IMAGE, response.data))

  yield put(endRequestSuccess(actionTypes.UPLOAD_RESOURCE_IMAGE))
}

function* createWebResourceSaga({ payload: { data } }) {
  yield put(startRequest(actionTypes.CREATE_WEB_RESOURCE))

  const response = yield call(api.createResource, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.CREATE_WEB_RESOURCE, response))
    return
  }

  const parsedResource = parseResourcePrices(response.data)

  yield put(
    updateEntity(ENTITY_KEY_RESOURCES, response.data.id, parsedResource),
  )

  // Redirect to step 2 (social networks)
  yield put(
    push(
      ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID.linkTo({
        id: response.data.id,
      }),
    ),
  )

  yield put(endRequestSuccess(actionTypes.CREATE_WEB_RESOURCE))
}

function* editWebResourceGeneralDataSaga({ payload: { id, data } }) {
  yield put(startRequest(actionTypes.EDIT_WEB_RESOURCE_GENERAL_DATA))

  const response = yield call(api.updateResource, id, data)

  if (response.error) {
    yield put(
      endRequestError(actionTypes.EDIT_WEB_RESOURCE_GENERAL_DATA, response),
    )
    return
  }

  const parsedResource = parseResourcePrices(response.data)

  yield put(
    updateEntity(ENTITY_KEY_RESOURCES, response.data.id, parsedResource),
  )

  // Redirect to step 2 (social networks)
  yield put(
    push(
      ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID.linkTo({
        id: response.data.id,
      }),
    ),
  )

  yield put(endRequestSuccess(actionTypes.EDIT_WEB_RESOURCE_GENERAL_DATA))
}

function* editWebResourcesSocialMediaSaga({ payload: { id, data } }) {
  yield put(startRequest(actionTypes.EDIT_WEB_RESOURCE_SOCIAL_MEDIA))

  const response = yield call(api.updateResource, id, {
    socialNetworks: data,
  })

  if (response.error) {
    yield put(
      endRequestError(actionTypes.EDIT_WEB_RESOURCE_SOCIAL_MEDIA, response),
    )
    return
  }

  const parsedResource = parseResourcePrices(response.data)

  yield put(
    updateEntity(ENTITY_KEY_RESOURCES, response.data.id, parsedResource),
  )

  // Redirect to step 3 (analytics)
  yield put(
    push(
      ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID.linkTo({
        id: response.data.id,
      }),
    ),
  )

  yield put(endRequestSuccess(actionTypes.EDIT_WEB_RESOURCE_SOCIAL_MEDIA))
}

function* createSocialResourceSaga({ payload: { socialNetwork, data } }) {
  yield put(startRequest(actionTypes.CREATE_SOCIAL_RESOURCE))

  const response = yield call(api.createResource, data)

  if (response.error) {
    yield put(endRequestError(actionTypes.CREATE_SOCIAL_RESOURCE, response))
    return
  }

  const parsedResource = parseResourcePrices(response.data)

  yield put(
    updateEntity(ENTITY_KEY_RESOURCES, response.data.id, parsedResource),
  )

  // Redirect to nextStep
  const nextStep = ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES

  yield put(
    push(
      nextStep.linkTo({
        socialNetwork: prettifyConstant(socialNetwork),
        id: response.data.id,
      }),
    ),
  )

  yield put(endRequestSuccess(actionTypes.CREATE_SOCIAL_RESOURCE))
}

function* editSocialResourceGeneralDataSaga({
  payload: { id, socialNetwork, data },
}) {
  yield put(startRequest(actionTypes.EDIT_SOCIAL_RESOURCE_GENERAL_DATA))

  const response = yield call(api.updateResource, id, data)

  if (response.error) {
    yield put(
      endRequestError(actionTypes.EDIT_SOCIAL_RESOURCE_GENERAL_DATA, response),
    )
    return
  }

  const parsedResource = parseResourcePrices(response.data)

  yield put(
    updateEntity(ENTITY_KEY_RESOURCES, response.data.id, parsedResource),
  )

  // Redirect to nextStep
  const nextStep = ROUTE_MEDIA_EDIT_SOCIAL_NETWORK_RESOURCES_SERVICES

  yield put(
    push(
      nextStep.linkTo({
        socialNetwork: prettifyConstant(socialNetwork),
        id: response.data.id,
      }),
    ),
  )

  yield put(endRequestSuccess(actionTypes.EDIT_SOCIAL_RESOURCE_GENERAL_DATA))
}

function* editSocialResourceServicesPricesSaga({
  payload: { id, socialNetwork, data },
}) {
  yield put(startRequest(actionTypes.EDIT_SOCIAL_RESOURCE_SERVICES_PRICES))

  const response = yield call(api.editResourcePrices, id, data)

  if (response.error) {
    yield put(
      endRequestError(
        actionTypes.EDIT_SOCIAL_RESOURCE_SERVICES_PRICES,
        response,
      ),
    )
    return
  }

  const resourceData = yield select(getResourceById(id))
  const resourcePrices = response.data

  const parsedResource = parseResourcePrices({
    ...resourceData,
    prices: resourcePrices,
  })

  yield put(
    updateEntity(ENTITY_KEY_RESOURCES, response.data.id, parsedResource),
  )

  // Redirect to resource list
  yield put(push(ROUTE_MEDIA_RESOURCES.linkTo()))

  yield put(endRequestSuccess(actionTypes.EDIT_SOCIAL_RESOURCE_SERVICES_PRICES))
}

function* deleteResourceSaga({ payload: { id } }) {
  yield put(startRequestWithId(actionTypes.DELETE_RESOURCE, id))

  const response = yield call(api.deleteResource, id)

  if (response.error) {
    yield put(endRequestWithIdError(actionTypes.DELETE_RESOURCE, id, response))
    return
  }

  yield call(fetchResourcesSaga, {
    payload: { page: 1, itemsPerPage: RESOURCES_PER_PAGE },
  })

  yield put(endRequestWithIdSuccess(actionTypes.DELETE_RESOURCE, id))
}

function* requestConnectSocialSaga({ payload: { id, socialService } }) {
  yield put(startRequest(actionTypes.REQUEST_CONNECT_SOCIAL))

  const response = yield call(
    api.requestConnectSocial,
    socialService,
    window.location.href,
    id,
  )

  if (response.error) {
    yield put(endRequestError(actionTypes.REQUEST_CONNECT_SOCIAL, response))
    return
  }

  // Redirect to social login
  window.location = response.data.url

  yield put(endRequestSuccess(actionTypes.REQUEST_CONNECT_SOCIAL))
}

function* fetchSocialProfilesSaga({ payload: { id, socialService } }) {
  yield put(startRequest(actionTypes.FETCH_SOCIAL_PROFILES))

  const response = yield call(api.fetchSocialProfiles, socialService, id)

  if (response.error) {
    const profilesNotFound =
      response.errorData &&
      response.errorData.status &&
      (response.errorData.status === 404 || response.errorData.status === 403)

    if (profilesNotFound) {
      yield put(hash.set(getSocialProfilesDomain(socialService), id, []))
      yield put(endRequestSuccess(actionTypes.FETCH_SOCIAL_PROFILES))

      const helpLink =
        socialService === SOCIAL_SERVICES.INSTAGRAM_BUSINESS
          ? 'web-resources:instagram-business-help-link'
          : undefined
      const helpLinkText =
        socialService === SOCIAL_SERVICES.INSTAGRAM_BUSINESS
          ? 'web-resources:instagram-business-help-link-text'
          : undefined

      yield put(
        addWarningToast(
          'web-resources:' + socialService + '-profiles-not-found-title',
          'web-resources:' + socialService + '-profiles-not-found-description',
          helpLink
            ? { helpLink: { url: helpLink, text: helpLinkText } }
            : undefined,
        ),
      )
      return
    } else {
      yield put(endRequestError(actionTypes.FETCH_SOCIAL_PROFILES, response))
      return
    }
  }

  yield put(hash.set(getSocialProfilesDomain(socialService), id, response.data))
  yield put(endRequestSuccess(actionTypes.FETCH_SOCIAL_PROFILES))
}

function* fetchSocialDataSaga({ payload: { id, socialService } }) {
  yield put(startRequest(actionTypes.FETCH_SOCIAL_DATA))

  const response = yield call(api.fetchSocialData, socialService, id)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_SOCIAL_DATA, response))
    return
  }

  yield put(hash.set(getSocialDataDomain(socialService), id, response.data))

  yield put(endRequestSuccess(actionTypes.FETCH_SOCIAL_DATA))
}

function* fetchResourceDetailsSaga({ payload: { id } }) {
  yield put(startRequestWithId(actionTypes.FETCH_RESOURCE_DETAILS, id))

  const response = yield call(api.fetchResourceDetails, id)

  const data = R.pipe(
    R.pathOr([], ['hits', 'hits']),
    R.find(R.propEq('_id', id)),
    R.propOr({}, '_source'),
  )(response.data)

  // Force 404 when no resource is found
  if (R.pathEq(['hits', 'total', 'value'], 0, response.data)) {
    yield put(
      endRequestWithIdError(actionTypes.FETCH_RESOURCE_DETAILS, id, {
        ...response,
        status: 404,
      }),
    )
    return
  }

  if (response.error || isNilOrEmpty(data)) {
    yield put(
      endRequestWithIdError(actionTypes.FETCH_RESOURCE_DETAILS, id, response),
    )
    return
  }

  yield put(
    updateEntities({
      [ENTITY_KEY_RESOURCE_DETAILS]: { [id]: data },
    }),
  )

  yield put(endRequestWithIdSuccess(actionTypes.FETCH_RESOURCE_DETAILS, id))
}

function* editWebResourceAnalyticsSaga({ payload: { id, data } }) {
  yield put(startRequest(actionTypes.EDIT_WEB_RESOURCE_ANALYTICS))

  const response = yield call(api.editResourceAnalytics, id, data)

  if (response.error) {
    yield put(
      endRequestError(actionTypes.EDIT_WEB_RESOURCE_ANALYTICS, response),
    )
    return
  }

  yield call(fetchResourceByIdSaga, { payload: { id } })

  // Update data (maybe new base prices are coming)
  const resourceData = yield select(getResourceById(id))

  yield put(updateEntity(ENTITY_KEY_RESOURCES, id, resourceData))

  // Redirect to step 4 (prices)
  yield put(
    push(
      ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID.linkTo({
        id: resourceData.id,
      }),
    ),
  )

  yield put(endRequestSuccess(actionTypes.EDIT_WEB_RESOURCE_ANALYTICS))
}

function* editWebResourceServicesPricesSaga({ payload: { id, data } }) {
  yield put(startRequest(actionTypes.EDIT_WEB_RESOURCE_SERVICES_PRICES))
  const response = yield call(api.editResourcePrices, id, data)

  if (response.error) {
    yield put(
      endRequestError(actionTypes.EDIT_WEB_RESOURCE_SERVICES_PRICES, response),
    )
    return
  }

  const resourceData = yield select(getResourceById(id))
  const resourcePrices = response.data

  const parsedResource = parseResourcePrices({
    ...resourceData,
    prices: resourcePrices,
  })

  yield put(updateEntity(ENTITY_KEY_RESOURCES, id, parsedResource))

  // Redirect to resource list
  yield put(push(ROUTE_MEDIA_RESOURCES.linkTo()))

  yield put(endRequestSuccess(actionTypes.EDIT_WEB_RESOURCE_SERVICES_PRICES))
}

function* updateResourceStatsSaga({ payload: { id } }) {
  yield put(startRequestWithId(actionTypes.UPDATE_RESOURCE_STATS, id))

  const response = yield call(api.updateResourceStats, id)

  if (response.error) {
    yield put(
      endRequestWithIdError(actionTypes.UPDATE_RESOURCE_STATS, id, response),
    )
    return
  }

  yield put(endRequestWithIdSuccess(actionTypes.UPDATE_RESOURCE_STATS, id))
}

function* updateResourceStatusSaga({ payload: { id, status } }) {
  yield put(startRequest(actionTypes.UPDATE_RESOURCE_STATUS))

  yield put(mergeEntities({ [ENTITY_KEY_RESOURCE_STATUS]: { [id]: status } }))

  yield put(endRequestSuccess(actionTypes.UPDATE_RESOURCE_STATUS))
}

export default function*() {
  yield all([
    takeLatest(actionTypes.FETCH_RESOURCES, fetchResourcesSaga),
    takeLatest(actionTypes.FETCH_RESOURCE_BY_ID, fetchResourceByIdSaga),
    // We handle both actions in the same Saga so that takeLatest cancels them
    // mutually
    takeLatest(
      [actionTypes.REQUEST_SCREENSHOT, actionTypes.UPLOAD_RESOURCE_IMAGE],
      resourceImageSaga,
    ),
    takeLatest(actionTypes.CREATE_WEB_RESOURCE, createWebResourceSaga),
    takeLatest(
      actionTypes.EDIT_WEB_RESOURCE_GENERAL_DATA,
      editWebResourceGeneralDataSaga,
    ),
    takeLatest(
      actionTypes.EDIT_WEB_RESOURCE_SOCIAL_MEDIA,
      editWebResourcesSocialMediaSaga,
    ),

    takeLatest(actionTypes.CREATE_SOCIAL_RESOURCE, createSocialResourceSaga),
    takeLatest(
      actionTypes.EDIT_SOCIAL_RESOURCE_GENERAL_DATA,
      editSocialResourceGeneralDataSaga,
    ),
    takeLatest(
      actionTypes.EDIT_SOCIAL_RESOURCE_SERVICES_PRICES,
      editSocialResourceServicesPricesSaga,
    ),
    takeLatest(actionTypes.DELETE_RESOURCE, deleteResourceSaga),
    // Social connect
    takeLatest(actionTypes.REQUEST_CONNECT_SOCIAL, requestConnectSocialSaga),
    takeLatest(actionTypes.FETCH_SOCIAL_PROFILES, fetchSocialProfilesSaga),
    takeLatest(actionTypes.FETCH_SOCIAL_DATA, fetchSocialDataSaga),
    takeLatest(
      actionTypes.EDIT_WEB_RESOURCE_ANALYTICS,
      editWebResourceAnalyticsSaga,
    ),
    takeLatest(actionTypes.FETCH_RESOURCE_DETAILS, fetchResourceDetailsSaga),
    takeLatest(
      actionTypes.EDIT_WEB_RESOURCE_SERVICES_PRICES,
      editWebResourceServicesPricesSaga,
    ),
    takeLeadingPerKey(
      actionTypes.UPDATE_RESOURCE_STATS,
      updateResourceStatsSaga,
      ({ payload: { id } }) => id,
    ),
    takeLatest(actionTypes.UPDATE_RESOURCE_STATUS, updateResourceStatusSaga),
  ])
}
