import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import ResourceDetailSection from './ResourceDetailSection'
import IconSocial from '../../icons/IconSocial'
import { RESOURCE_TYPES } from '../../../services/resources/constants'

const ResourceDetailLinks = ({
  webUrl,
  facebookUrl,
  twitterUrl,
  instagramUrl,
  youtubeUrl,
  twitchUrl,
  tiktokUrl,
  i18n,
}) => {
  return (
    <ResourceDetailSection titleText={i18n('sections:links')}>
      <div className="resource-detail-links-list">
        {webUrl && (
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={webUrl}
            title={i18n('action:open-web')}
          >
            <IconSocial type={RESOURCE_TYPES.WEB} />
          </a>
        )}
        {twitterUrl && (
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={twitterUrl}
            title={i18n('action:open-twitter')}
          >
            <IconSocial type={RESOURCE_TYPES.TWITTER} />
          </a>
        )}
        {facebookUrl && (
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={facebookUrl}
            title={i18n('action:open-facebook')}
          >
            <IconSocial type={RESOURCE_TYPES.FACEBOOK} />
          </a>
        )}
        {instagramUrl && (
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={instagramUrl}
            title={i18n('action:open-instagram')}
          >
            <IconSocial type={RESOURCE_TYPES.INSTAGRAM} />
          </a>
        )}
        {youtubeUrl && (
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={youtubeUrl}
            title={i18n('action:open-youtube')}
          >
            <IconSocial type={RESOURCE_TYPES.YOUTUBE} />
          </a>
        )}
        {twitchUrl && (
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={twitchUrl}
            title={i18n('action:open-twitch')}
          >
            <IconSocial type={RESOURCE_TYPES.TWITCH} />
          </a>
        )}
        {tiktokUrl && (
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={tiktokUrl}
            title={i18n('action:open-tiktok')}
          >
            <IconSocial type={RESOURCE_TYPES.TIKTOK} />
          </a>
        )}
      </div>
    </ResourceDetailSection>
  )
}

ResourceDetailLinks.propTypes = {
  webUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  twitchUrl: PropTypes.string,
  tiktokUrl: PropTypes.string,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ResourceDetailLinks)
