import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import Button from '@redradix/components.button'

const SearchFiltersContent = ({ i18n, onShow, children, results }) => (
  <div className="search-filters-content">
    {children}
    <div className="search-filters-content-action">
      <Button onClick={onShow} hierarchy="secondary" widthBehaviour="full">
        {results === null
          ? i18n('message:loading-results')
          : i18n('action:show-n-results', { results: results })}
      </Button>
    </div>
  </div>
)

SearchFiltersContent.propTypes = {
  children: PropTypes.node,
  onShow: PropTypes.func.isRequired,
  i18n: PropTypes.func.isRequired,
  results: PropTypes.number,
}

export default withTranslations(SearchFiltersContent)
