import {
  call,
  race,
  take,
  delay,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects'
import { getIsRequestPending } from './communication'

// Allows calling a fetch saga repeatedly
const repeatingFetchEffect = ({
  fetchSaga,
  intervalMillisecs,
  startAction,
  requestActionType,
  cancelActionType = undefined,
  limitRepeat = undefined,
}) =>
  takeLatest(startAction.type, function*({ payload, count }) {
    const isRequesting = yield select(getIsRequestPending(requestActionType))

    // If we are not online, we cannot fetch
    //
    // If we're in the middle of another request (perhaps from another effect)
    // don't repeat the request
    if (navigator.onLine && !isRequesting) {
      yield call(fetchSaga, { payload })
    }

    // Wait until delay finishes or an optional cancelation is requested
    const result = yield !!cancelActionType
      ? race({
          cancel: take(cancelActionType),
          delay: delay(intervalMillisecs),
        })
      : delay(intervalMillisecs)

    // Increment count and check limitRepeat
    count = count ? Math.min(count + 1, 100) : 2
    if (limitRepeat && count && count > limitRepeat) {
      return
    }

    // If delay finished (no cancellation), start again
    if (!cancelActionType || result.delay) {
      yield put({ ...startAction, payload, count })
    }
  })

export default repeatingFetchEffect
