import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from '@redradix/components.button'
import ModalTitle from '../texts/ModalTitle'
import Subtitle from '../texts/Subtitle'

const ModalSimpleContent = ({
  title,
  children,

  actionText,
  buttonHierarchy,
  buttonVariant,
  action,

  action1Text,
  buttonHierarchy1,
  buttonVariant1,
  isButton1Loading,
  isButton1Submit,
  action1,

  action2Text,
  buttonHierarchy2,
  buttonVariant2,
  isButton2Loading,
  isButton2Submit,
  action2,

  action3Text,
  buttonHierarchy3,
  buttonVariant3,
  isButton3Loading,
  isButton3Submit,
  action3,

  isButtonDisabled,
  isButtonLoading,
  doubleActions,
  tripleActions,
  subtitle,
}) => {
  const actionGroupClasses = cx('action-group', {
    'is-triple': tripleActions,
  })
  return (
    <div className="modal-simple-content">
      {(title || subtitle) && (
        <div className="modal-header">
          {title && <ModalTitle text={title} />}
          {subtitle && <Subtitle text={subtitle} />}
        </div>
      )}
      {children}
      {(doubleActions || tripleActions || actionText) && (
        <div className="modal-actions">
          <div className={actionGroupClasses}>
            {tripleActions ? (
              <>
                <Button
                  type={isButton1Submit ? 'submit' : 'button'}
                  hierarchy={buttonHierarchy1 || 'primary'}
                  variant={buttonVariant1 || 'neutral'}
                  isDisabled={isButtonDisabled && !isButton1Loading}
                  isLoading={isButton1Loading}
                  onClick={action1}
                >
                  {action1Text}
                </Button>
                <Button
                  type={isButton2Submit ? 'submit' : 'button'}
                  hierarchy={buttonHierarchy2 || 'primary'}
                  variant={buttonVariant2 || 'destruct'}
                  isDisabled={isButtonDisabled && !isButton2Loading}
                  isLoading={isButton2Loading}
                  onClick={action2}
                >
                  {action2Text}
                </Button>
                <Button
                  type={isButton3Submit ? 'submit' : 'button'}
                  hierarchy={buttonHierarchy3 || 'primary'}
                  variant={buttonVariant3 || 'destruct'}
                  isDisabled={isButtonDisabled && !isButton3Loading}
                  isLoading={isButton3Loading}
                  onClick={action3}
                >
                  {action3Text}
                </Button>
              </>
            ) : doubleActions ? (
              <>
                <Button
                  type={isButton1Submit ? 'submit' : 'button'}
                  hierarchy={buttonHierarchy1 || 'primary'}
                  variant={buttonVariant1 || 'neutral'}
                  isDisabled={isButtonDisabled && !isButton1Loading}
                  isLoading={isButton1Loading}
                  onClick={action1}
                >
                  {action1Text}
                </Button>
                <Button
                  type={isButton2Submit ? 'submit' : 'button'}
                  hierarchy={buttonHierarchy2 || 'primary'}
                  variant={buttonVariant2 || 'destruct'}
                  isDisabled={isButtonDisabled && !isButton2Loading}
                  isLoading={isButton2Loading}
                  onClick={action2}
                >
                  {action2Text}
                </Button>
              </>
            ) : (
              <Button
                type="submit"
                hierarchy={buttonHierarchy}
                variant={buttonVariant}
                isDisabled={isButtonDisabled && !isButtonLoading}
                isLoading={isButtonLoading}
                onClick={action}
              >
                {actionText}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

ModalSimpleContent.propTypes = {
  title: PropTypes.string,
  actionText: PropTypes.string,
  buttonHierarchy: PropTypes.string,
  buttonVariant: PropTypes.string,
  action1Text: PropTypes.string,
  buttonHierarchy1: PropTypes.string,
  buttonVariant1: PropTypes.string,
  isButton1Submit: PropTypes.bool,
  action1: PropTypes.func,
  action2Text: PropTypes.string,
  buttonHierarchy2: PropTypes.string,
  buttonVariant2: PropTypes.string,
  isButton2Submit: PropTypes.bool,
  action2: PropTypes.func,
  buttonHierarchy3: PropTypes.string,
  buttonVariant3: PropTypes.string,
  isButton3Submit: PropTypes.bool,
  action3: PropTypes.func,
  children: PropTypes.node,
  isButtonDisabled: PropTypes.bool,
  isButtonLoading: PropTypes.bool,
  isButton1Loading: PropTypes.bool,
  isButton2Loading: PropTypes.bool,
  isButton3Loading: PropTypes.bool,
  doubleActions: PropTypes.bool,
  tripleActions: PropTypes.bool,
  subtitle: PropTypes.string,
}

ModalSimpleContent.defaultProps = {
  buttonHierarchy: 'primary',
}

export default ModalSimpleContent
