import * as R from 'ramda'
import { ROUTE_BRAND_RESOURCES_BY_ID } from './routes'
import {
  isNewResource,
  resourceAvatarURL,
} from '../../services/resources/business'
import { isNilOrEmpty, getAmountForCurrency } from '../utils'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import moment from 'moment'

const resourceToViewProps = (
  resource,
  isBroadcastChecked,
  selectedService,
  inCartItems,
  currency,
) => {
  const image = resourceAvatarURL(resource.id)

  const url = ROUTE_BRAND_RESOURCES_BY_ID.linkTo({ id: resource.id })

  const isNew = isNewResource(resource)

  const price = getAmountForCurrency(
    isBroadcastChecked ? selectedService.priceBroadcast : selectedService.price,
    currency,
  )

  const finalPrice = getAmountForCurrency(
    isBroadcastChecked
      ? selectedService.finalPriceBroadcast
      : selectedService.finalPrice,
    currency,
  )

  const acceptsBroadcast =
    resource.socialNetworks && resource.socialNetworks.acceptsBroadcast

  const broadcastSocialNetworks = [
    ...(resource.socialNetworks &&
    !isNilOrEmpty(resource.socialNetworks.facebook)
      ? [RESOURCE_TYPES.FACEBOOK]
      : []),
    ...(resource.socialNetworks &&
    !isNilOrEmpty(resource.socialNetworks.twitter)
      ? [RESOURCE_TYPES.TWITTER]
      : []),
    ...(resource.socialNetworks &&
    !isNilOrEmpty(resource.socialNetworks.instagram)
      ? [RESOURCE_TYPES.INSTAGRAM]
      : []),
  ]

  const now = moment()

  const holidaysEndDate =
    resource.holidays &&
    resource.holidays.beginAt &&
    resource.holidays.endAt &&
    moment(resource.holidays.beginAt).isBefore(now) &&
    moment(resource.holidays.endAt).isAfter(now)
      ? moment(resource.holidays.endAt).toDate()
      : null

  const isInCart =
    !isNilOrEmpty(inCartItems) &&
    !R.isNil(inCartItems.find(item => item.service === selectedService.service))

  return {
    image,
    url,
    isNew,
    price,
    finalPrice,
    acceptsBroadcast,
    broadcastSocialNetworks,
    holidaysEndDate,
    isInCart,
  }
}

export default resourceToViewProps
