import * as actionTypes from './action-types'

export const fetchCategories = () => ({
  type: actionTypes.FETCH_CATEGORIES,
})

export const fetchCountries = () => ({
  type: actionTypes.FETCH_COUNTRIES,
})

export const fetchCurrencies = () => ({
  type: actionTypes.FETCH_CURRENCIES,
})
