import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBodyCell,
  TableBodyRow,
  TableHeaderCell,
  TableHeaderRow,
} from '@redradix/components.table'
import Button from '@redradix/components.button'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const TableSocialProfileSynchronizeData = ({
  tableData,
  onSelect,
  i18n,
  i18nNumber,
}) => {
  return (
    <div className="basic-table">
      <Table
        tableHeader={
          <TableHeaderRow>
            <TableHeaderCell>{i18n('noun:profile')}</TableHeaderCell>
            <TableHeaderCell>{i18n('noun:url')}</TableHeaderCell>
            <TableHeaderCell>{i18n('noun:fans')}</TableHeaderCell>
            <TableHeaderCell>{i18n('action:select')}</TableHeaderCell>
          </TableHeaderRow>
        }
      >
        {tableData.map(item => (
          <TableBodyRow key={item.id}>
            <TableBodyCell>
              <strong>{item.profile}</strong>
            </TableBodyCell>
            <TableBodyCell>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.link}
              </a>
            </TableBodyCell>
            <TableBodyCell>{i18nNumber(item.fanCount)}</TableBodyCell>
            <TableBodyCell>
              <Button
                hierarchy="secondary"
                onClick={() => {
                  onSelect(item.id)
                }}
              >
                {i18n('action:choose-profile')}
              </Button>
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </Table>
    </div>
  )
}

TableSocialProfileSynchronizeData.propTypes = {
  tableData: PropTypes.arrayOf(CoobisPropTypes.facebookProfile),
  onSelect: PropTypes.func.isRequired,
}

export default withTranslations(TableSocialProfileSynchronizeData)
