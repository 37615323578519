import { compose, withPropsOnChange } from 'recompose'
import * as R from 'ramda'
import ImgLanguageEnglish from '../ui/form-elements/images/flags/icon-language-gb.svg'
import ImgLanguageSpanish from '../ui/form-elements/images/flags/icon-language-sp.svg'
import ImgLanguageGerman from '../ui/form-elements/images/flags/icon-language-de.svg'
import ImgLanguageItalian from '../ui/form-elements/images/flags/icon-language-it.svg'
import ImgLanguageFrench from '../ui/form-elements/images/flags/icon-language-fr.svg'
import LOCALES from '../config/locales'
import withTranslations from './withTranslations'

export const LANGUAGES = ['es', 'en', 'de', 'fr', 'it']

export const LANGUAGES_WITH_LOCALE = LOCALES.map(item => item.locale)

const FLAGS = {
  es: ImgLanguageSpanish,
  en: ImgLanguageEnglish,
  de: ImgLanguageGerman,
  fr: ImgLanguageFrench,
  it: ImgLanguageItalian,
}

const withLanguageSelectField = (
  languages,
  {
    optionsProp = 'languageOptions',
    valuesProp = 'values',
    valueField = 'language',
  } = {},
) =>
  compose(
    withTranslations,

    withPropsOnChange(['i18n'], ({ i18n }) => ({
      [optionsProp]: R.pipe(
        R.map(item => ({
          label: i18n(`language:${item}`),
          value: item,
          isDisabled: false,
          imgPath: FLAGS[item],
        })),
        R.sort((a, b) => a.label.localeCompare(b.label)),
      )(languages),
    })),

    withPropsOnChange(['i18n', valuesProp], ({ i18n, ...rest }) => ({
      [valuesProp]: {
        ...rest[valuesProp],
        [valueField]: !R.isNil(rest[valuesProp][valueField])
          ? {
              label: i18n(`language:${rest[valuesProp][valueField]}`),
              value: rest[valuesProp][valueField],
              isDisabled: false,
            }
          : null,
      },
    })),
  )

export default withLanguageSelectField
