import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { compose } from 'recompose'
import * as Yup from 'yup'
import { i18nValidation } from '../common/validation'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { connect } from 'react-redux'
import { editMediaData } from '../../services/media'
import { EDIT_MEDIA_DATA } from '../../services/media/action-types'
import withRequestError from '../../hocs/withRequestError'
import MediaDataForm from '../../ui/profile/MediaDataForm'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withLanguageSelectField, {
  LANGUAGES_WITH_LOCALE,
} from '../../hocs/withLanguageSelectField'
import withCleanFormSuccess from '../../hocs/withCleanFormSuccess'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18nValidation.required),
  surname: Yup.string().required(i18nValidation.required),
  email: Yup.string()
    .email(i18nValidation.invalidMail)
    .required(i18nValidation.required),
  phone: Yup.string().required(i18nValidation.required),
  language: Yup.mixed()
    .oneOf(LANGUAGES_WITH_LOCALE, i18nValidation.required)
    .required(i18nValidation.required),
})

const enhance = compose(
  connect(
    null,
    { editMediaData },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ mediaInfo }) => ({
      name: mediaInfo.name || '',
      surname: mediaInfo.surname || '',
      email: mediaInfo.email || '',
      phone: mediaInfo.phone || '',
      language: mediaInfo.language,
    }),

    selectFields: ['language'],

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.editMediaData(values)
    },
  }),

  withIsRequestPending(EDIT_MEDIA_DATA),
  withIsRequestSucceeded(EDIT_MEDIA_DATA),
  withRequestError(EDIT_MEDIA_DATA),
  withDeleteRequestOnUnmount(EDIT_MEDIA_DATA),
  withFormErrors(['name', 'surname', 'email', 'phone', 'language']),

  withLanguageSelectField(LANGUAGES_WITH_LOCALE),
  withCleanFormSuccess(),
)

export default enhance(MediaDataForm)
