import React from 'react'
import { MediaQueryLap } from '../shared/MediaQueries'
import OrderDetail from './OrderDetail'
import OrderDetailTabs from './OrderDetailTabs'
import OrderDetailDates from './OrderDetailDates'
import OrderDetailBriefing from './OrderDetailBriefing'
import OrderDetailDocuments from './OrderDetailDocuments'
import OrderDetailLinks from './OrderDetailLinks'
import OrderDetailAdvices from './OrderDetailAdvices'
import OrderDetailTags from './OrderDetailTags'
import OrderDetailPreDelivery from './OrderDetailPreDelivery'
import OrderDetailDelivery from './OrderDetailDelivery'
import OrderDetailComments from './OrderDetailComments'
import OrderDetailHistory from './OrderDetailHistory'
import { ReactComponent as ChevronLeft } from './../../styles/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from './../../styles/icons/chevron-right.svg'
import OrderDetailConditions from './OrderDetailConditions'
import OrderDetailVideo from './OrderDetailVideo'
import { isNilOrEmpty } from '../../app/utils'
import OrderActionButton from './OrderActionButton'
import OrderDetailCampaign from './OrderDetailCampaign'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import { AuthScope } from '../../services/auth/business'
import {
  mapServiceTypeToOrderTypeI18n,
  mapServiceTypeToResourceType,
} from '../../services/orders/business'
import IconSocial from '../icons/IconSocial'
import OrderDetailDeliveryEmpty from './OrderDetailDeliveryEmpty'
import OrderDetailGeneralData from './OrderDetailGeneralData'

const HEADING_I18N_KEY_BY_SCOPE = {
  [AuthScope.BRAND]: 'order-detail-brand:heading',
  [AuthScope.MEDIA]: 'order-detail-media:heading',
}

const OrderDetailView = ({
  scope,
  resourceName,
  brandName,
  brandUrl,
  orderType,
  orderStatus,

  controllers,
  selectedIndex,
  handleTabChange,

  hasActions,
  onActionClick,

  deliveryDates,
  postDescription,
  sampleMessage,
  campaignObjective,
  showDeliveryHelp,
  showTrackerHelp,
  trackerTag,
  onCopyTrackerTagClick,
  acceptsBroadcasts,
  hasPredelivery,
  words,
  urlSocialNetworks,

  campaignOptions,
  values,
  touched,
  isRequestPending,
  hasSucceeded,
  handleChange,
  handleBlur,
  handleSubmit,
  showSaveCampaignButton,
  validationMessages,
  globalMessages,

  orderDocument,
  orderLinks,
  orderAdvices,
  orderTags,
  isAdvertising,
  orderUploadCondition,
  minVideoLength,
  maxVideoLength,

  orderHistory,
  isHistoryLoaded,

  orderPredelivery,
  orderDelivery,

  rating,

  i18n,
}) => {
  const hasOrderPreDeliveryData =
    !isNilOrEmpty(orderPredelivery.url) ||
    !isNilOrEmpty(orderPredelivery.fileUrl)

  const hasOrderDeliveryData =
    !isNilOrEmpty(orderDelivery.url) ||
    !isNilOrEmpty(orderDelivery.fileUrl) ||
    !isNilOrEmpty(orderDelivery.facebook) ||
    !isNilOrEmpty(orderDelivery.twitter) ||
    !isNilOrEmpty(orderDelivery.instagram)

  return (
    <OrderDetail
      heading={i18n(HEADING_I18N_KEY_BY_SCOPE[scope], {
        resourceName,
        brandName,
        orderType: (
          <>
            <MediaQueryLap>
              <span className="order-type-separator"> - </span>
            </MediaQueryLap>
            <span className="order-type-group">
              <span className="order-type-icon">
                <IconSocial type={mapServiceTypeToResourceType[orderType]} />
              </span>
              <span className="order-type-text">
                {i18n(mapServiceTypeToOrderTypeI18n[orderType])}
              </span>
            </span>
          </>
        ),
      })}
      hasActions={hasActions}
      actionsSlot={
        <div className="order-detail-header-actions">
          <OrderActionButton
            scope={scope}
            orderStatus={orderStatus}
            hasPreDelivery={hasPredelivery}
            onClick={onActionClick}
            isOrderDetail
          />
        </div>
      }
    >
      <OrderDetailTabs
        renderLeftIcon={props => <ChevronLeft {...props} />}
        renderRightIcon={props => <ChevronRight {...props} />}
        controllers={controllers}
        selectedIndex={selectedIndex}
        onChange={handleTabChange}
      >
        {/* Instrucciones */}
        <div>
          <OrderDetailGeneralData
            brand={brandName}
            web={brandUrl}
            serviceType={orderType}
          />

          <OrderDetailDates {...deliveryDates} scope={scope} />

          <OrderDetailBriefing
            postDescription={postDescription}
            sampleMessage={sampleMessage}
            campaignObjective={campaignObjective}
            showDeliveryHelp={showDeliveryHelp}
            showTrackerHelp={showTrackerHelp}
            trackerTag={trackerTag}
            onCopyTrackerTagClick={onCopyTrackerTagClick}
            hasSocialBroadcasting={acceptsBroadcasts}
            hasPredelivery={hasPredelivery}
            words={words}
            urlSocialNetworks={urlSocialNetworks}
          />

          {!isNilOrEmpty(campaignOptions) ? (
            <>
              <OrderDetailCampaign
                campaigns={campaignOptions}
                handleSubmit={handleSubmit}
                showSaveCampaignButton={showSaveCampaignButton}
                selectedCampaign={values.campaign}
                isSelectorTouched={touched.campaign}
                isLoading={isRequestPending}
                hasSucceeded={hasSucceeded}
                onSelectorChange={item =>
                  handleChange({
                    target: { name: 'campaign', value: item.value },
                  })
                }
                onSelectorBlur={_ =>
                  handleBlur({ target: { name: 'campaign' } })
                }
                errorText={
                  validationMessages.campaign && (
                    <FormattedFormErrors errors={validationMessages.campaign} />
                  )
                }
              />

              {globalMessages && (
                <WarningMessage
                  text={<FormattedFormErrors errors={globalMessages} />}
                />
              )}
            </>
          ) : null}

          {!isNilOrEmpty(orderDocument) ? (
            <OrderDetailDocuments items={orderDocument} />
          ) : null}

          {!isNilOrEmpty(orderLinks) ? (
            <OrderDetailLinks items={orderLinks} />
          ) : null}

          {!isNilOrEmpty(orderAdvices) ? (
            <OrderDetailAdvices items={orderAdvices} />
          ) : null}

          {!isNilOrEmpty(orderTags) ? (
            <OrderDetailTags items={orderTags} isAdvertising={isAdvertising} />
          ) : null}

          {orderUploadCondition ? (
            <OrderDetailConditions
              scope={scope}
              condition={orderUploadCondition}
            />
          ) : null}

          {minVideoLength && maxVideoLength ? (
            <OrderDetailVideo
              minLength={minVideoLength}
              maxLength={maxVideoLength}
            />
          ) : null}
        </div>
        {/* Historial */}
        <div>
          <OrderDetailHistory
            items={orderHistory}
            isLoading={!isHistoryLoaded}
            resourceName={resourceName}
            brandName={brandName}
          />
        </div>
        {/* Entrega */}
        <div>
          {hasOrderPreDeliveryData ? (
            <OrderDetailPreDelivery
              url={orderPredelivery.url}
              fileUrl={orderPredelivery.fileUrl}
              fileTitle={orderPredelivery.fileTitle}
              fileSize={orderPredelivery.fileSize}
              isDisabled={orderPredelivery.isDisabled}
            />
          ) : null}

          {hasOrderDeliveryData ? (
            <OrderDetailDelivery
              orderType={orderType}
              url={orderDelivery.url}
              facebookUrl={orderDelivery.facebook}
              twitterUrl={orderDelivery.twitter}
              instagram={orderDelivery.instagram}
              fileUrl={orderDelivery.fileUrl}
              fileTitle={orderDelivery.fileTitle}
              fileSize={orderDelivery.fileSize}
              isDisabled={orderDelivery.isDisabled}
            />
          ) : null}

          {!isNilOrEmpty(orderPredelivery.comment) ||
          !isNilOrEmpty(orderDelivery.comment) ||
          !isNilOrEmpty(rating) ? (
            <OrderDetailComments
              predeliveryText={orderPredelivery.comment}
              deliveryText={orderDelivery.comment}
              rating={rating}
            />
          ) : !hasOrderPreDeliveryData && !hasOrderDeliveryData ? (
            <OrderDetailDeliveryEmpty scope={scope} />
          ) : null}
        </div>
      </OrderDetailTabs>
    </OrderDetail>
  )
}

export default withTranslations(OrderDetailView)
