import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { isNilOrEmpty } from '../../app/utils'
import DashboardPanel from './DashboardPanel'
import NotificationsCard from '../cards/NotificationsCard'
import LoadingCard from '../cards/LoadingCard'
import NoNotificationsCard from '../cards/NoNotificationsCard'

const NotificationsPanel = ({
  notifications,
  onNotificationClick,
  readAllNotifications,
  isReadingAllNotifications,
  isLoading,
  i18n,
}) => {
  return (
    <DashboardPanel title={i18n('noun:notifications')} size="one-third">
      {isLoading ? (
        <LoadingCard />
      ) : !isNilOrEmpty(notifications) ? (
        <NotificationsCard
          notifications={notifications}
          onNotificationClick={onNotificationClick}
          isMarkAllLoading={isReadingAllNotifications}
          onMarkAllClick={readAllNotifications}
        />
      ) : (
        <NoNotificationsCard />
      )}
    </DashboardPanel>
  )
}

NotificationsPanel.propTypes = {
  notifications: PropTypes.array,
  isLoading: PropTypes.bool,
}

export default withTranslations(NotificationsPanel)
