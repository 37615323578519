import * as actionTypes from './action-types'

export const fetchMedia = () => ({
  type: actionTypes.FETCH_MEDIA,
})

export const registerMedia = data => ({
  type: actionTypes.REGISTER_MEDIA,
  payload: { data },
})

export const editMediaData = data => ({
  type: actionTypes.EDIT_MEDIA_DATA,
  payload: { data },
})

export const editMediaBillingData = data => ({
  type: actionTypes.EDIT_MEDIA_BILLING_DATA,
  payload: { data },
})

export const fetchMediaStatus = () => ({
  type: actionTypes.FETCH_MEDIA_STATUS,
})

export const periodicFetchMediaStatus = () => ({
  type: actionTypes.PERIODIC_FETCH_MEDIA_STATUS,
})
