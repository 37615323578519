import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import Dashboard from '../dashboard/Dashboard'
import CampaignsPanel from '../dashboard/CampaignsPanel'
import BalancePanel from '../dashboard/BalancePanel'
import NotificationsPanel from '../dashboard/NotificationsPanel'
import OrdersPanel from '../dashboard/OrdersPanel'
import { MediaQueryDesk } from '../shared/MediaQueries'
import withTranslations from '../../hocs/withTranslations'
import { isNil } from 'ramda'
import FavouritesPanel from './FavouritesPanel'
import RecommendationsPanel from './RecommendationsPanel'
import Tour from '../tour/Tour'
import { getBrandSteps } from '../../app/common/tourSteps'
import { TOUR_WELCOME_BRAND } from '../../services/tour/constants'

const BrandDashboard = ({
  username,
  orders,
  campaigns,
  balance,
  isLoadingBalance,
  isLoadingOrders,
  isLoadingCampaigns,
  isLoadingFavorites,
  handleBalanceRecharge,
  favorites,
  renderFavCard,
  isLoadingFeaturedResources,
  featuredResources,
  renderRecommendedCard,
  notifications,
  onNotificationClick,
  readAllNotifications,
  isReadingAllNotifications,
  i18n,
  isWelcomeBrandTourViewed,
  markTourAsViewed,
}) => (
  <Dashboard title={i18n('dashboard:message', { username })}>
    {!isWelcomeBrandTourViewed ? (
      <Tour
        steps={getBrandSteps(i18n)}
        markTourAsViewed={markTourAsViewed}
        tourName={TOUR_WELCOME_BRAND}
      />
    ) : null}
    <OrdersPanel orders={orders} isLoading={isLoadingOrders && isNil(orders)} />
    <MediaQueryDesk>
      <NotificationsPanel
        notifications={notifications}
        onNotificationClick={onNotificationClick}
        readAllNotifications={readAllNotifications}
        isReadingAllNotifications={isReadingAllNotifications}
      />
    </MediaQueryDesk>
    <CampaignsPanel
      campaigns={campaigns}
      isLoading={isLoadingCampaigns && isNil(campaigns)}
    />
    <MediaQueryDesk>
      <BalancePanel
        hasTitle
        balance={balance}
        isLoading={isLoadingBalance}
        onBalanceAction={handleBalanceRecharge}
      />
    </MediaQueryDesk>
    <RecommendationsPanel
      resources={featuredResources}
      isLoading={isLoadingFeaturedResources}
      renderRecommendedCard={renderRecommendedCard}
    />
    <FavouritesPanel
      resources={favorites}
      isLoading={isLoadingFavorites}
      renderFavCard={renderFavCard}
    />
  </Dashboard>
)

BrandDashboard.propTypes = {
  username: PropTypes.string,
  orders: PropTypes.arrayOf(CoobisPropTypes.orderRow),
  campaigns: PropTypes.arrayOf(CoobisPropTypes.campaign),
  balance: CoobisPropTypes.money,
  isLoadingBalance: PropTypes.bool.isRequired,
  isLoadingOrders: PropTypes.bool.isRequired,
  isLoadingCampaigns: PropTypes.bool.isRequired,
  isLoadingFavorites: PropTypes.bool.isRequired,
  handleBalanceRecharge: PropTypes.func,
  renderFavCard: PropTypes.func,
  isWelcomeBrandTourViewed: PropTypes.bool,
  markTourAsViewed: PropTypes.func,
}

export default withTranslations(BrandDashboard)
