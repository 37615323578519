import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const SortItem = ({ text, onSort, isSelected, isDisabled }) => {
  const classes = cx('sort-item', {
    'is-selected': isSelected,
    'is-disabled': isDisabled,
  })
  return (
    <button
      className={classes}
      data-text={text}
      onClick={onSort}
      disabled={isDisabled}
    >
      <span className="sort-item-content">{text}</span>
    </button>
  )
}

SortItem.defaultProps = {
  isSelected: false,
  isDisabled: false,
}

SortItem.propTypes = {
  onSort: PropTypes.func,
  isSelected: PropTypes.bool,
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default SortItem
