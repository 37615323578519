import React from 'react'
import { compose, withProps, branch, renderComponent } from 'recompose'
import * as R from 'ramda'
import withIsRequestWithIdSucceeded from './withIsRequestWithIdSucceeded'
import withHasRequestWithIdError from './withHasRequestWithIdError'
import Spinner from '../ui/loaders/Spinner'
import withTranslations from './withTranslations'

const IS_SUCCEEDED_PROP = 'withSpinnerWithId/isRequestSucceeded'
const HAS_ERROR_PROP = 'withSpinnerWithId/hasRequestError'

const withSpinnerWithId = (
  requestType,
  { i18nKey = 'status:loading', idProp = 'id', enhanceSpinner = x => x } = {},
) => {
  const EnhancedSpinner = enhanceSpinner(({ i18n }) => (
    <Spinner text={i18n(i18nKey)} />
  ))

  return compose(
    withTranslations,
    withIsRequestWithIdSucceeded(requestType, {
      idProp,
      outProp: IS_SUCCEEDED_PROP,
    }),
    withHasRequestWithIdError(requestType, { idProp, outProp: HAS_ERROR_PROP }),

    branch(
      ownProps => !ownProps[IS_SUCCEEDED_PROP] && !ownProps[HAS_ERROR_PROP],
      renderComponent(EnhancedSpinner),
    ),

    withProps(R.omit([IS_SUCCEEDED_PROP, HAS_ERROR_PROP])),
  )
}

export default withSpinnerWithId
