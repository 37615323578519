import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'

import PaperInfo from '../papers/PaperInfo'
import Button from '@redradix/components.button'

class OrderTrackerTagView extends React.Component {
  static propTypes = {
    trackerTag: PropTypes.string,
    onCopyTrackerTagClick: PropTypes.func,
    i18n: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props)
    this.state = { copied: false }
  }
  handleTrackerTagCopy = () => {
    this.props.onCopyTrackerTagClick()
    this.handleTrackerTagCopied()
  }
  handleTrackerTagCopied = () => {
    this.setState({ copied: true })
    setTimeout(() => this.setState({ copied: false }), 2000)
  }
  render() {
    const { trackerTag, i18n } = this.props
    return (
      <PaperInfo title={i18n('order-detail:tracker-help-title')} type="help">
        {i18n('order-detail:tracker-help', {
          trackerHelpLink: (
            <a href={i18n('order-detail:tracker-help-link-url')}>
              {i18n('order-detail:tracker-help-link')}
            </a>
          ),
        })}
        <div className="tracker-code-block">
          <div className="tracker-code">{trackerTag}</div>
          <div className="right-align">
            <Button
              className={'anchor-link'}
              heightBehaviour="adaptative"
              isDisabled={this.state.copied}
              onClick={this.handleTrackerTagCopy}
            >
              {this.state.copied
                ? i18n('order-detail:tracker-help-code-copied')
                : i18n('order-detail:tracker-help-copy-code')}
            </Button>
          </div>
        </div>
      </PaperInfo>
    )
  }
}

export default withTranslations(OrderTrackerTagView)
