import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'

const ProfileMovementsHeadRow = ({ i18n }) => {
  return (
    <div className="profile-movements-head-row">
      <div className="profile-movements-head-row-item profile-movements-row-date">
        {i18n('noun:date')}
      </div>
      <div className="profile-movements-head-row-item profile-movements-row-concept">
        {i18n('noun:concept')}
      </div>
      <div className="profile-movements-head-row-item profile-movements-row-resource">
        {i18n('noun:resources')}
      </div>
      <div className="profile-movements-head-row-item profile-movements-row-amount">
        {i18n('noun:amount')}
      </div>
      <div className="profile-movements-head-row-item profile-movements-row-balance">
        {i18n('noun:balance')}
      </div>
      <div className="profile-movements-head-row-item profile-movements-row-action">
        {i18n('noun:download')}
      </div>
    </div>
  )
}

ProfileMovementsHeadRow.propTypes = {
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(ProfileMovementsHeadRow)
