import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailTiktokAll from '../../ui/campaigns/views/CampaignDetailTiktokAll'
import withTimeSeriesGraphDynamicLegend from './withTimeSeriesGraphDynamicLegend'
import withPublicationsTable from './withPublicationsTable'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  publications: stats.kpis.totalOrders,
  views: stats.kpis.totalViews,
  likes: stats.kpis.totalFollowers,
  engagements: stats.kpis.totalEngagements,
  comments: stats.kpis.totalComments,
}))

const withTiktokPublicationsTable = withPublicationsTable(orderStatsDetail => ({
  views: orderStatsDetail.kpis.views,
  likes: orderStatsDetail.kpis.followers,
  engagements: orderStatsDetail.kpis.engagements,
}))

const withViewsGraph = withTimeSeriesGraphDynamicLegend(
  stats => stats.orderStatsDetail,
  orderStatsDetail => ({
    id: orderStatsDetail.orderId,
    date: orderStatsDetail.deliveryDate,
    name: orderStatsDetail.resource.name,
    items: orderStatsDetail.series.views,
  }),
  {
    dataOutProp: 'viewsData',
    legendOptionsOutProp: 'viewsLegendOptions',
    onLegendDeleteOutProp: 'onViewsLegendDelete',
    onLegendOptionChangeOutProp: 'onViewsLegendOptionChange',
  },
)

const withLikesGraph = withTimeSeriesGraphDynamicLegend(
  stats => stats.orderStatsDetail,
  orderStatsDetail => ({
    id: orderStatsDetail.orderId,
    date: orderStatsDetail.deliveryDate,
    name: orderStatsDetail.resource.name,
    items: orderStatsDetail.series.followers,
  }),
  {
    dataOutProp: 'likesData',
    legendOptionsOutProp: 'likesLegendOptions',
    onLegendDeleteOutProp: 'onLikesLegendDelete',
    onLegendOptionChangeOutProp: 'onLikesLegendOptionChange',
  },
)

const withEngagementsGraph = withTimeSeriesGraphDynamicLegend(
  stats => stats.orderStatsDetail,
  orderStatsDetail => ({
    id: orderStatsDetail.orderId,
    date: orderStatsDetail.deliveryDate,
    name: orderStatsDetail.resource.name,
    items: orderStatsDetail.series.engagements,
  }),
  {
    dataOutProp: 'engagementsData',
    legendOptionsOutProp: 'engagementsLegendOptions',
    onLegendDeleteOutProp: 'onEngagementsLegendDelete',
    onLegendOptionChangeOutProp: 'onEngagementsLegendOptionChange',
  },
)

const enhance = compose(
  withKPIs,
  withTiktokPublicationsTable,
  withViewsGraph,
  withLikesGraph,
  withEngagementsGraph,
  withExportReportContents(({ viewsData }) => ({
    viewsGraphIds: viewsData.map(({ id }) => id),
  })),
)

export default enhance(CampaignDetailTiktokAll)
