import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import ChartLegend from './ChartLegend'
import withTranslations from '../../hocs/withTranslations'
import * as R from 'ramda'
import { truncateNumber } from '../../app/utils'
am4core.useTheme(am4themes_animated)

const COLORS = ['#a9b3ff', '#fa5992', '#ffd960', '#cccdd8']

const BULLET_RADIUS = 20

const createChart = (element, popupI18NKey, i18n, i18nNumber) => {
  // Create chart instance
  const chart = am4core.create(element, am4charts.XYChart)

  // Category axis (X)
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
  categoryAxis.dataFields.category = 'category'
  categoryAxis.renderer.grid.template.strokeOpacity = 0
  categoryAxis.renderer.grid.template.location = 0
  categoryAxis.renderer.minGridDistance = 20
  categoryAxis.renderer.labels.template.visible = false
  categoryAxis.renderer.labels.template.adapter.add('textOutput', text =>
    text ? text.replace(/-.+$/, '') : text,
  )

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.min = 0
  valueAxis.renderer.minGridDistance = 50
  valueAxis.renderer.labels.template.visible = false
  valueAxis.renderer.grid.template.strokeOpacity = 1
  valueAxis.renderer.baseGrid.strokeOpacity = 0
  valueAxis.renderer.grid.template.stroke = am4core.color('#f2f2f5')
  valueAxis.width = 0

  chart.series.push(createSeries(popupI18NKey, i18n, i18nNumber))

  // Remove padding
  chart.paddingBottom = 0

  return chart
}

// Create series
function createSeries(popupI18NKey, i18n, i18nNumber) {
  const series = new am4charts.ColumnSeries()
  series.dataFields.valueY = 'value'
  series.dataFields.categoryX = 'category'
  series.tooltip.autoTextColor = false
  series.tooltip.label.fontSize = '14'
  series.columns.template.tooltipText = `{username}: [font-weight:bold]{valueY}`
  series.columns.template.adapter.add('tooltipText', (_, target) =>
    i18n(popupI18NKey, {
      username: target.dataItem.dataContext.username,
      accounts: i18nNumber(target.dataItem.valueY),
    }),
  )
  series.columns.template.column.cornerRadiusTopLeft = 6
  series.columns.template.column.cornerRadiusTopRight = 6
  series.columns.template.column.cornerRadiusBottomRight = 0
  series.columns.template.column.cornerRadiusBottomLeft = 0
  series.columns.template.column.strokeWidth = 0
  series.columns.template.column.paddingTop = BULLET_RADIUS
  // Create image
  const bullet = series.columns.template.createChild(am4charts.CircleBullet)
  bullet.circle.radius = BULLET_RADIUS
  bullet.valign = 'top'
  bullet.align = 'center'
  bullet.isMeasured = true
  bullet.mouseEnabled = false
  bullet.verticalCenter = 'bottom'
  bullet.strokeWidth = 0
  bullet.marginTop = BULLET_RADIUS

  // Hide if there is no picture
  bullet.adapter.add(
    'hidden',
    (_, target) => !target.dataItem.dataContext.picture,
  )

  const outlineCircle = bullet.createChild(am4core.Circle)
  outlineCircle.adapter.add('radius', function(radius, target) {
    const circleBullet = target.parent
    return circleBullet.circle.pixelRadius
  })

  const image = bullet.createChild(am4core.Image)
  image.width = 40
  image.height = 40
  image.horizontalCenter = 'middle'
  image.verticalCenter = 'middle'
  image.propertyFields.href = 'picture'

  // If image has load error, set fallback image
  image.events.on('ready', ({ target }) => {
    const imageTag = target.dom.children[0]

    imageTag.onerror = e => {
      imageTag.onerror = undefined
      imageTag.remove()
    }
  })

  image.adapter.add('mask', function(mask, target) {
    const circleBullet = target.parent
    return circleBullet.circle
  })

  // Column color
  series.columns.template.adapter.add('fill', (fill, target) => {
    return COLORS[target.dataItem.index]
  })

  return series
}

const UserBarChart = ({ data, popupI18NKey, i18n, i18nNumber }) => {
  const chart = useRef()
  const chartElementRef = useRef()

  // Create and destroy chart on (un)mount
  useEffect(() => {
    chart.current = createChart(
      chartElementRef.current,
      popupI18NKey,
      i18n,
      i18nNumber,
    )

    return () => {
      if (chart.current) {
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [popupI18NKey, i18n, i18nNumber])

  // Handle data updates
  useEffect(() => {
    // Return if chart is unmounted
    if (!chart.current) {
      return
    }

    if (chart.current.data.length === 0) {
      // Set initial data
      chart.current.data = R.clone(data)
    } else if (chart.current.data.length === data.length) {
      // Update current data
      // Needs to be imperative to trigger animation
      for (let i = 0; i < data.length; i++) {
        chart.current.data[i].username = data[i].username
        chart.current.data[i].value = data[i].value
        chart.current.data[i].picture = data[i].picture
      }

      // Trigger animation
      chart.current.invalidateRawData()
    } else {
      // Different data points: completely update
      chart.current.data = R.clone(data)
    }
  }, [data, popupI18NKey, i18n, i18nNumber])

  const legendItems = useMemo(
    () =>
      data
        ? data.map((item, i) => ({
            label: item.username,
            value: truncateNumber(item.value, i18nNumber),
            color: COLORS[i],
          }))
        : [],
    [data, i18nNumber],
  )

  return (
    <div className="chart-wrapper">
      <div className="user-bar-chart" ref={chartElementRef} />
      {legendItems && <ChartLegend items={legendItems} isRowed></ChartLegend>}
    </div>
  )
}

UserBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      username: PropTypes.string,
      value: PropTypes.number,
      picture: PropTypes.string,
    }),
  ),
}

export default withTranslations(UserBarChart)
