import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import ChipBasic from './../chips/ChipBasic'
import PaperInfo from '../papers/PaperInfo'

const OrderDetailTags = ({ items, isAdvertising, i18n }) => {
  return (
    <OrderDetailSection title={i18n('sections:tags')}>
      {items.map((item, i) => (
        <ChipBasic key={i} title={item.title} text={item.description} />
      ))}

      {isAdvertising ? (
        <PaperInfo
          title={i18n('order-detail:advertising-title')}
          type="advertising"
        >
          {i18n('order-detail:advertising-description')}
        </PaperInfo>
      ) : null}
    </OrderDetailSection>
  )
}

OrderDetailTags.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailTags)
