import { getFromPath } from 'reduken/hash'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import { DOMAIN, HASH_KEY_BRAND_INFO, HASH_KEY_BRAND_STATUS } from './constants'
import { billingDataSchema } from './business'
import { moneyToFloat } from '../../app/utils'
import { getTranslatedCountries } from '../config/selectors'

export const getBrandInfo = getFromPath(DOMAIN, HASH_KEY_BRAND_INFO)

export const getIsVIPBrand = createSelector(
  getBrandInfo,
  brandInfo => (!brandInfo ? undefined : brandInfo.isVip),
)

export const getHasCompletedBillingData = createSelector(
  getTranslatedCountries,
  getBrandInfo,
  (translatedCountries, brandInfo) => {
    if (!brandInfo) {
      return false
    }

    const billingData = {
      ...brandInfo.billing,
      currency: R.pathOr(null, ['currency', 'isoCode'], brandInfo.billing),
    }

    try {
      billingDataSchema({ translatedCountries }).validateSync(billingData)
      return true
    } catch (error) {
      return false
    }
  },
)

export const getVATRate = createSelector(
  getBrandInfo,
  brandInfo =>
    !brandInfo ||
    R.isNil(brandInfo.billing) ||
    R.isNil(brandInfo.billing.vatRate)
      ? 21
      : moneyToFloat(brandInfo.billing.vatRate),
)

export const getBrandCurrency = createSelector(
  getBrandInfo,
  brandInfo => brandInfo.billing.currency,
)

export const getBrandStatus = getFromPath(DOMAIN, HASH_KEY_BRAND_STATUS)
