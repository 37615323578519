import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { FormattedMessage } from 'react-intl'
import { MediaQueryPalm, MediaQueryLap } from '../shared/MediaQueries'
import BreadcrumbNavigation from '../navigation/BreadcrumbNavigation'
import BreadcrumbItem from '../navigation/BreadcrumbItem'
import {
  ROUTE_BRAND_CHECKOUT_GENERAL_DATA,
  ROUTE_BRAND_CHECKOUT_BRIEFING,
  ROUTE_BRAND_CHECKOUT_PAYMENT,
} from '../../app/common/routes'
import HackFormattedMoney from '../HackFormattedMoney'

const CheckoutHeader = ({
  i18n,
  price,
  currentNavItem,
  reachedNavItem,
  onClickItem,
}) => {
  return (
    <div className="steps-layout-header">
      <div className="wrapper">
        <div className="steps-layout-header-content">
          <MediaQueryPalm>
            <BreadcrumbNavigation>
              <BreadcrumbItem
                url={ROUTE_BRAND_CHECKOUT_GENERAL_DATA.linkTo()}
                text={i18n('sections:data-short')}
                listNumber={1}
                onClick={onClickItem}
                isVisited={currentNavItem >= 1}
                isActive={currentNavItem === 1}
                isDisabled={reachedNavItem < 1}
              />
              <BreadcrumbItem
                url={ROUTE_BRAND_CHECKOUT_BRIEFING.linkTo()}
                text={i18n('sections:briefing-short')}
                listNumber={2}
                onClick={onClickItem}
                isVisited={currentNavItem >= 2}
                isActive={currentNavItem === 2}
                isDisabled={reachedNavItem < 2}
              />
              <BreadcrumbItem
                url={ROUTE_BRAND_CHECKOUT_PAYMENT.linkTo()}
                text={i18n('sections:payment-short')}
                listNumber={3}
                onClick={onClickItem}
                isVisited={currentNavItem >= 3}
                isActive={currentNavItem === 3}
                isDisabled={reachedNavItem < 3}
              />
            </BreadcrumbNavigation>
          </MediaQueryPalm>
          <MediaQueryLap>
            <BreadcrumbNavigation>
              <BreadcrumbItem
                url={ROUTE_BRAND_CHECKOUT_GENERAL_DATA.linkTo()}
                text={i18n('sections:data-long')}
                listNumber={1}
                onClick={onClickItem}
                isVisited={currentNavItem >= 1}
                isActive={currentNavItem === 1}
                isDisabled={reachedNavItem < 1}
              />
              <BreadcrumbItem
                url={ROUTE_BRAND_CHECKOUT_BRIEFING.linkTo()}
                text={i18n('sections:briefing-long')}
                listNumber={2}
                onClick={onClickItem}
                isVisited={currentNavItem >= 2}
                isActive={currentNavItem === 2}
                isDisabled={reachedNavItem < 2}
              />
              <BreadcrumbItem
                url={ROUTE_BRAND_CHECKOUT_PAYMENT.linkTo()}
                text={i18n('sections:payment-long')}
                listNumber={3}
                onClick={onClickItem}
                isVisited={currentNavItem >= 3}
                isActive={currentNavItem === 3}
                isDisabled={reachedNavItem < 3}
              />
            </BreadcrumbNavigation>
            {price && (
              <div className="steps-layout-header-price">
                <FormattedMessage
                  id="checkout:total-price"
                  defaultMessage="checkout:total-price"
                  values={{
                    price: (
                      <span className="checkout-header-price">
                        <HackFormattedMoney value={price} />
                      </span>
                    ),
                  }}
                />
              </div>
            )}
          </MediaQueryLap>
        </div>
      </div>
    </div>
  )
}

CheckoutHeader.propTypes = {
  price: PropTypes.number,
  currentNavItem: PropTypes.number,
  reachedNavItem: PropTypes.number,
  onClickItem: PropTypes.func,
}

export default withTranslations(CheckoutHeader)
