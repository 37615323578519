import {
  compose,
  withHandlers,
  withPropsOnChange,
  withState,
  lifecycle,
} from 'recompose'
import { connect } from 'react-redux'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import {
  REQUEST_SCREENSHOT,
  UPLOAD_RESOURCE_IMAGE,
} from '../../services/resources/action-types'
import {
  requestScreenshot,
  uploadResourcePhoto,
  getLastTemporaryImage,
  clearTemporaryImage,
} from '../../services/resources'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { resourceAvatarURL } from '../../services/resources/business'
import withHasRequestError from '../../hocs/withHasRequestError'
import withRequestError from '../../hocs/withRequestError'
import { getErrorTextFromUploadError } from '../common/uploads-utils'

const withImageField = compose(
  connect(
    state => ({
      lastTemporaryImage: getLastTemporaryImage(state),
    }),
    {
      requestScreenshot,
      uploadResourcePhoto,
      clearTemporaryImage,
    },
  ),

  withHandlers({
    onScreenshotClick: props => _ => {
      props.requestScreenshot(props.values.url)
    },

    maybeRequestScreenshot: props => url => {
      // must request an screenshot if...
      const mustRequestScreenshot =
        // ...last temporary image is a capture, and URL does not match
        (props.lastTemporaryImage &&
          props.lastTemporaryImage.captureURL &&
          url !== props.lastTemporaryImage.captureURL) ||
        // ...or there is no last temporary image, but there was already an URL
        // in the resource that doesn't match the new URL
        (!props.lastTemporaryImage &&
          props.resourceData &&
          props.resourceData.url &&
          url !== props.resourceData.url) ||
        // ...or there is no last temporary image, nor an URL already in the resource
        (!props.lastTemporaryImage &&
          (!props.resourceData || !props.resourceData.url))

      if (mustRequestScreenshot) {
        props.requestScreenshot(url)
      }
    },

    // Handle image upload
    onPhotoUpload: props => ev => {
      if (ev.target.files.length === 1) {
        props.uploadResourcePhoto(ev.target.files[0])
        // Need this in order to upload without reloading the page
        ev.target.value = ''
      }
    },

    onPhotoUploadDrop: props => ev => {
      if (ev.dataTransfer.files.length === 1) {
        props.uploadResourcePhoto(ev.dataTransfer.files[0])
      }
    },
  }),

  withHandlers({
    // Take screenshot when losing focus of a (modified) URL
    handleBlur: props => (...args) => {
      if (args[0].target.name === 'url') {
        const isValidURL = !!props.values.url && !props.errors.url

        if (isValidURL) {
          props.maybeRequestScreenshot(props.values.url)
        }
      }

      return props.handleBlur(...args)
    },
  }),

  // Handle request status
  withIsRequestPending(REQUEST_SCREENSHOT, 'isScreenshotRequestPending'),
  withHasRequestError(REQUEST_SCREENSHOT, 'hasScreenshotRequestError'),
  withDeleteRequestOnUnmount(REQUEST_SCREENSHOT),

  withIsRequestPending(
    UPLOAD_RESOURCE_IMAGE,
    'isUploadResourceImageRequestPending',
  ),
  withHasRequestError(
    UPLOAD_RESOURCE_IMAGE,
    'hasUploadResourceImageRequestError',
  ),
  withRequestError(UPLOAD_RESOURCE_IMAGE, 'uploadResourceImageRequestError'),
  withDeleteRequestOnUnmount(UPLOAD_RESOURCE_IMAGE),

  // Inject props based on image states
  withPropsOnChange(
    ['isEditing', 'lastTemporaryImage', 'resourceData'],
    ({ isEditing, lastTemporaryImage, resourceData }) => ({
      photoURL: lastTemporaryImage
        ? resourceAvatarURL(lastTemporaryImage.fileName)
        : isEditing
        ? resourceAvatarURL(resourceData.id)
        : undefined,
    }),
  ),

  // To show fallback when photo errors out
  withState('erroredPhotoURL', 'setErroredPhotoURL', false),
  withState('loadedPhotoURL', 'setLoadedPhotoURL', false),

  withPropsOnChange(
    ['loadedPhotoURL', 'erroredPhotoURL', 'photoURL'],
    ({ loadedPhotoURL, erroredPhotoURL, photoURL }) => ({
      isPhotoLoaded: loadedPhotoURL === photoURL && photoURL !== undefined,
      isPhotoErrored: erroredPhotoURL === photoURL && photoURL !== undefined,
      isLoadingPhoto:
        loadedPhotoURL !== photoURL &&
        erroredPhotoURL !== photoURL &&
        photoURL !== undefined,
    }),
  ),

  withPropsOnChange(
    [
      'i18n',
      'isPhotoErrored',
      'hasScreenshotRequestError',
      'hasUploadResourceImageRequestError',
      'uploadResourceImageRequestError',
    ],
    ({
      i18n,
      isPhotoErrored,
      hasScreenshotRequestError,
      hasUploadResourceImageRequestError,
      uploadResourceImageRequestError,
    }) => ({
      photoError: hasUploadResourceImageRequestError
        ? getErrorTextFromUploadError(
            uploadResourceImageRequestError,
            i18n('resources:error-image-upload'),
            i18n,
          )
        : hasScreenshotRequestError
        ? i18n('resources:error-image-screenshot')
        : isPhotoErrored
        ? i18n('resources:error-image-download')
        : undefined,
    }),
  ),

  // Clear temporary image when leaving the form
  lifecycle({
    componentWillUnmount() {
      this.props.clearTemporaryImage()
    },
  }),
)

export default withImageField
