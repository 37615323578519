import React from 'react'
import PropTypes from 'prop-types'

const TooltipLargeWrapper = props => {
  return <div className="tooltip-large-wrapper">{props.children}</div>
}

TooltipLargeWrapper.propTypes = {
  children: PropTypes.node,
}

export default TooltipLargeWrapper
