import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import {
  getRequest,
  postRequest,
  patchRequest,
  postFormRequest,
} from '../api-call'
import { parseResponseErrors } from '../error-handling'
import { i18nErrorKeys } from '../../app/common/validation'
import { HISTORY_ACTIONS } from './business'
import { formatBytes } from '../../app/utils'

// ENDPOINTS
const MEDIA_ORDERS_IN_PROGRESS_ENDPOINT = `${envConfig.usersBackendUrl}/media/orders/in-progress`

const MEDIA_ORDERS_FINISHED_ENDPOINT = `${envConfig.usersBackendUrl}/media/orders/finished`

const BRAND_ORDERS_IN_PROGRESS_ENDPOINT = `${envConfig.usersBackendUrl}/brand/orders/in-progress`

const orderActionEndpoint = (id, action) =>
  `${envConfig.usersBackendUrl}/orders/${id}/${action}`

const mediaOrderDetailEndpoint = id =>
  `${envConfig.usersBackendUrl}/media/orders/${id}`

const brandOrderDetailEndpoint = id =>
  `${envConfig.usersBackendUrl}/brand/orders/${id}`

const orderHistoryEndpoint = id =>
  `${envConfig.usersBackendUrl}/orders/${id}/history`

const orderFileEndpoint = (id, action) =>
  `${envConfig.usersBackendUrl}/orders/${id}/${action}/file`

const currentCampaignOrdersEndpoint = id =>
  `${envConfig.usersBackendUrl}/brand/campaigns/${id}/orders/in-progress`

const cancelledCampaignOrdersEndpoint = id =>
  `${envConfig.usersBackendUrl}/brand/orders/rejected-cancelled/campaign/${id}`

// MAP GLOBAL ERRORS
const MAP_GLOBAL_ORDER_NOT_FOUND = {
  OrderNotFoundException: values => ({
    key: i18nErrorKeys.ORDER_NOT_FOUND,
    values,
  }),
}

const MAP_GLOBAL_ORDER_UPLOAD_FILE_EXCEPTIONS = {
  OrderFileMaxSizeException: ({ value: { fileSize }, expected: maxSize }) => ({
    key: i18nErrorKeys.ORDER_UPLOADED_FILE_EXCEED_MAX_FILE_SIZE,
    values: {
      fileSize: formatBytes(fileSize, 1),
      maxSize: formatBytes(maxSize, 1),
    },
  }),
  OrderFileMimeTypeException: ({ expected: allowedFormats }) => ({
    key: i18nErrorKeys.ORDER_UPLOADED_FILE_FORMAT_NOT_ALLOWED,
    values: { allowedFormats: allowedFormats.join(', ') },
  }),
}

// API FUNCTIONS
export function* fetchMediaOrdersInProgress() {
  const response = yield call(getRequest, MEDIA_ORDERS_IN_PROGRESS_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchMediaOrdersFinished() {
  const response = yield call(getRequest, MEDIA_ORDERS_FINISHED_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchMediaOrderDetail(id) {
  const response = yield call(getRequest, mediaOrderDetailEndpoint(id), {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_NOT_FOUND,
  })

  return parsedResponse
}

export function* fetchBrandOrdersInProgress() {
  const response = yield call(getRequest, BRAND_ORDERS_IN_PROGRESS_ENDPOINT, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchBrandOrderDetail(id) {
  const response = yield call(getRequest, brandOrderDetailEndpoint(id), {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_NOT_FOUND,
  })

  return parsedResponse
}

export function* fetchOrderHistory(id) {
  const response = yield call(getRequest, orderHistoryEndpoint(id), {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* changeOrderCampaign(campaignId, orderId) {
  const response = yield call(
    patchRequest,
    brandOrderDetailEndpoint(orderId),
    { campaignId },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_NOT_FOUND,
  })

  return parsedResponse
}

export function* fetchCurrentCampaignOrders(campaignId) {
  const response = yield call(
    getRequest,
    currentCampaignOrdersEndpoint(campaignId),
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_NOT_FOUND,
  })

  return parsedResponse
}

export function* fetchCancelledCampaignOrders(campaignId) {
  const response = yield call(
    getRequest,
    cancelledCampaignOrdersEndpoint(campaignId),
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_NOT_FOUND,
  })

  return parsedResponse
}

export function* cancelOrder(orderId, comments) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.CANCEL),
    { comments },
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* acceptCounterbriefing(orderId) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.ACCEPT_BRIEFING),
    {},
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* rejectCounterbriefing(orderId) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.REJECT_BRIEFING),
    {},
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* acceptOrder(orderId) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.ACCEPT),
    {},
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* rejectOrder(orderId, comments) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.REJECT),
    { comments },
    { parseJSON: false },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* acceptOrderReviewWithChanges(orderId, comments) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.REQUEST_BRIEFING),
    { comments },
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

const MAP_GLOBAL_ORDER_DELIVERY = {
  TrackerMissingException: values => ({
    key: i18nErrorKeys.TRACKER_MISSING,
    values,
  }),
  OrderDeliveryUrlResourceException: values => ({
    key: i18nErrorKeys.ORDER_DELIVERY_URL_RESOURCE_MISMATCH,
    values: { resourceUrl: values.value.resourceUrl },
  }),
}

export function* sendOrderDelivery(orderId, data) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.SEND_DELIVERY),
    data,
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_DELIVERY,
  })

  return parsedResponse
}

export function* fixOrderDelivery(orderId, data) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.FIX_DELIVERY),
    data,
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_DELIVERY,
  })

  return parsedResponse
}

export function* acceptOrderDelivery(orderId, data) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.APPROVE_DELIVERY),
    data,
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* rejectOrderDelivery(orderId, data) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.REJECT_DELIVERY),
    data,
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* sendOrderPreDelivery(orderId, data) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.SEND_REVIEW),
    data,
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_UPLOAD_FILE_EXCEPTIONS,
  })

  return parsedResponse
}

export function* fixOrderPreDelivery(orderId, data) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.FIX_REVIEW),
    data,
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_UPLOAD_FILE_EXCEPTIONS,
  })

  return parsedResponse
}

export function* acceptOrderPreDelivery(orderId, comments) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.ACCEPT_REVIEW),
    { comments },
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* rejectOrderPreDelivery(orderId, data) {
  const response = yield call(
    postRequest,
    orderActionEndpoint(orderId, HISTORY_ACTIONS.REJECT_REVIEW),
    data,
    {
      parseJSON: false,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* uploadOrderFile(orderId, action, file) {
  const response = yield call(
    postFormRequest,
    orderFileEndpoint(orderId, action),
    {
      file,
    },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_ORDER_UPLOAD_FILE_EXCEPTIONS,
  })

  return parsedResponse
}
