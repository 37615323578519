import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { getAmountForCurrency, moneyToFloat } from '../../app/utils'
import { COOBIS_CURRENCIES } from '../../config/formats'
import * as R from 'ramda'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ResultView from '../../ui/search/Result'
import withTranslations from '../../hocs/withTranslations'
import { useResourceServices } from '../common/useResourceServices'
import withFavoriteHandlers from '../common/withFavoriteHandlers'
import withAddToCart from '../common/withAddToCart'
import { ROUTE_BRAND_RESOURCES_BY_ID } from '../common/routes'
import {
  getResourceStatus,
  updateResourceStatus,
} from '../../services/resources'
import { SERVICE_TYPES } from '../../services/orders/business'

const Result = ({
  item,
  translateCategory,
  minPrice,
  maxPrice,
  currency,
  addItem,
  isAddingItem,
  i18n,
  handleAddToFavorites,
  handleRemoveFavorite,
  isFavorite,
  inCartItems,
  resourceStatus,
  updateResourceStatus,
  location,
}) => {
  const {
    services,
    actualSelectedService,
    handleCartClick,
    handleServiceChange,
    isBroadcastChecked,
    handleBroadcastChange,
  } = useResourceServices({
    item,
    addItem,
    i18n,
    resourceStatus,
    updateResourceStatus,
  })

  const servicesWithPriceLimits = useMemo(
    () =>
      services.map(service => {
        const finalPrice = getAmountForCurrency(
          service.finalPrice,
          COOBIS_CURRENCIES['EUR'],
        )
        const matchesMin = !minPrice || moneyToFloat(finalPrice) >= minPrice
        const matchesMax = !maxPrice || moneyToFloat(finalPrice) <= maxPrice

        return {
          ...service,
          isDisabled: !matchesMin || !matchesMax,
        }
      }),
    [maxPrice, minPrice, services],
  )

  // Add filter query params to search result links
  const url = ROUTE_BRAND_RESOURCES_BY_ID.linkTo({ id: item.id })
  const urlWithFilters = `${url}${location.search}`

  const fixSelectedServiceIfDisabled = (selectedService, services) => {
    if (selectedService !== undefined) {
      const fixedSelectedService = checkServiceIsDisabled(
        selectedService,
        services,
      )
        ? findCheapestEnabledService(services)
        : selectedService
      return fixedSelectedService ? fixedSelectedService : selectedService
    }
    return selectedService
  }

  const checkServiceIsDisabled = (selectedService, services) =>
    services.find(
      service =>
        !service.isDisabled &&
        service.service === selectedService.service &&
        service.words === selectedService.words,
    )
      ? false
      : true

  const findCheapestEnabledService = services => {
    const infinityPrice = { finalPrice: { EUR: Infinity, USD: Infinity } }
    const service = R.pipe(
      R.filter(service => !service.isDisabled),
      R.reduce(
        R.minBy(
          service =>
            (service.service === SERVICE_TYPES.WEB_ONLY_POST ? 1000 : 1) *
            service.finalPrice.EUR,
        ),
        infinityPrice,
      ),
    )(services)
    return service != infinityPrice ? service : null
  }

  const selectedService = fixSelectedServiceIfDisabled(
    actualSelectedService,
    servicesWithPriceLimits,
  )

  // If inbetween loadings, sometimes results aren't updated yet so, even if
  // they should be filtered out, it still tries to render and fails because
  // no service actually matches the filtered-out prices
  if (selectedService === undefined) {
    // Just render null instead meanwhile
    return null
  }

  const [majorityAgeGroup, majorityAgeRate] = R.pipe(
    R.pick([
      'age18_24',
      'age25_34',
      'age35_44',
      'age45_54',
      'age55_64',
      'age65+',
    ]),
    R.toPairs,
    R.filter(([k, _]) => k.startsWith('age')),
    R.reduce(R.maxBy(R.nth(1)), [undefined, -Infinity]),
  )(item.statistics.demographics)

  return (
    <ResultView
      item={item}
      url={urlWithFilters}
      services={servicesWithPriceLimits}
      selectedService={selectedService}
      currency={currency}
      onCartClick={handleCartClick}
      isCartLoading={isAddingItem}
      onServiceChange={handleServiceChange}
      majorityAgeGroup={majorityAgeGroup}
      majorityAgeRate={majorityAgeRate}
      translateCategory={translateCategory}
      isBroadcastChecked={isBroadcastChecked}
      onBroadcastChange={handleBroadcastChange}
      handleAddToFavorites={handleAddToFavorites}
      handleRemoveFavorite={handleRemoveFavorite}
      isFavorite={isFavorite}
      inCartItems={inCartItems}
    />
  )
}

Result.propTypes = {
  item: PropTypes.object,
  translateCategory: PropTypes.func.isRequired,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  currency: CoobisPropTypes.currency,
  isAddingItem: PropTypes.bool,
}

const enhance = compose(
  withTranslations,
  withAddToCart,
  withRouter,
  withFavoriteHandlers({
    favoritesProp: 'favorites',
    resourceIdProp: 'item',
  }),
  connect(
    (state, { item }) => ({
      resourceStatus: getResourceStatus(item.id, state),
    }),
    {
      updateResourceStatus,
    },
  ),
)

export default enhance(React.memo(Result))
