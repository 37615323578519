import React from 'react'
import Card from './Card'
import Spinner from '../loaders/Spinner'

const LoadingCard = () => {
  return (
    <Card>
      <div className="loading-card">
        <Spinner size={60} />
      </div>
    </Card>
  )
}

export default LoadingCard
