import React from 'react'
import PropTypes from 'prop-types'
import Button from '@redradix/components.button'
import AnchorButton from '../buttons/AnchorButton'
import Card from './Card'

const ActionCard = ({
  messageSlot,
  onClick,
  linkUrl,
  linkText,
  imageBig,
  imageSmall,
  actionGroupSlot,
}) => {
  return (
    <Card isHighlighted>
      <div className="action-card">
        <div className="action-card-image">
          <picture>
            <source media="(max-width: 969px)" srcSet={imageSmall} />
            <source media="(min-width: 970px)" srcSet={imageBig} />
            <img src={imageBig} alt="Decoration" />
          </picture>
        </div>
        <div className="action-card-contents">
          {messageSlot}
          {actionGroupSlot ? (
            <div className="action-card-link-group">{actionGroupSlot}</div>
          ) : linkUrl ? (
            <AnchorButton onClick={onClick} linkUrl={linkUrl}>
              {linkText}
            </AnchorButton>
          ) : (
            <Button onClick={onClick}>{linkText}</Button>
          )}
        </div>
      </div>
    </Card>
  )
}

ActionCard.propTypes = {
  messageSlot: PropTypes.node,
  onClick: PropTypes.func,
  actionGroupSlot: PropTypes.node,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  imageBig: PropTypes.string,
  imageSmall: PropTypes.string,
}

export default ActionCard
