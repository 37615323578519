import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { isNilOrEmpty } from '../../app/utils'
import { MediaQueryLap, MediaQueryPalm } from '../shared/MediaQueries'
import WelcomeDashboardWrapper from '../dashboard/WelcomeDashboardWrapper'
import DashboardPanel from '../dashboard/DashboardPanel'
import AffiliatesSection from './AffiliatesSection'
import ShareLinkWidget from '../blocks/ShareLinkWidget'
import Card from '../cards/Card'
import CardKpi from '../cards/CardKpi'
import NoAffiliatesCard from '../cards/NoAffiliatesCard'
import AffiliatesCard from '../cards/AffiliatesCard'
import { ReactComponent as Euro } from '../../styles/icons/euro.svg'

const Affiliates = ({
  sharingLinkText,
  facebookLinkUrl,
  twitterLinkUrl,
  mediaComission,
  brandComission,
  affiliates,
  onCopyClick,
  i18n,
  i18nNumber,
}) => {
  return (
    <WelcomeDashboardWrapper
      title={i18n('noun:affiliates')}
      hasBoldTitle
      isTopSpaced
    >
      <DashboardPanel size="one-whole" autoHeight>
        <div className="affiliates">
          <MediaQueryPalm>
            <div className="affiliates-column">
              {isNilOrEmpty(affiliates) ? (
                <NoAffiliatesCard />
              ) : (
                <AffiliatesCard affiliates={affiliates}></AffiliatesCard>
              )}
            </div>
          </MediaQueryPalm>
          <div className="affiliates-column">
            <AffiliatesSection title={i18n('affiliates:get-income-title')}>
              <p>{i18n('affiliates:get-income-description-1')}</p>
              <p>{i18n('affiliates:get-income-description-2')}</p>
              <h3 className="affiliates-section-subtitle">
                {i18n('action:share-your-link')}
              </h3>
              <ShareLinkWidget
                linkText={sharingLinkText}
                facebookLinkUrl={facebookLinkUrl}
                twitterLinkUrl={twitterLinkUrl}
                onCopyClick={onCopyClick}
              ></ShareLinkWidget>
            </AffiliatesSection>
            <AffiliatesSection title={i18n('affiliates:comissions-title')}>
              <p>
                {i18n('affiliates:comissions-description', {
                  mediaComission: i18nNumber(mediaComission * 100),
                  brandComission: i18nNumber(brandComission * 100),
                })}
              </p>
              <div className="affiliates-kpi-group">
                <Card isHighlighted>
                  <CardKpi
                    figure={mediaComission}
                    label={i18n('affiliates:comission-register-media')}
                    isPercentage
                  >
                    <Euro className="icon" />
                  </CardKpi>
                </Card>
                <Card isHighlighted>
                  <CardKpi
                    figure={brandComission}
                    label={i18n('affiliates:comission-register-brand')}
                    isPercentage
                  >
                    <Euro className="icon" />
                  </CardKpi>
                </Card>
              </div>
            </AffiliatesSection>
          </div>
          <MediaQueryLap>
            <div className="affiliates-column">
              {isNilOrEmpty(affiliates) ? (
                <NoAffiliatesCard />
              ) : (
                <AffiliatesCard affiliates={affiliates}></AffiliatesCard>
              )}
            </div>
          </MediaQueryLap>
        </div>
      </DashboardPanel>
    </WelcomeDashboardWrapper>
  )
}

Affiliates.propTypes = {
  sharingLinkText: PropTypes.string,
  facebookLinkUrl: PropTypes.string,
  twitterLinkUrl: PropTypes.string,
  mediaComission: PropTypes.number,
  brandComission: PropTypes.number,
  affiliates: PropTypes.array,
  onCopyClick: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(Affiliates)
