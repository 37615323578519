import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as IconInfo } from '../../styles/icons/global.svg'
import { ReactComponent as IconHelp } from '../../styles/icons/help.svg'
import { ReactComponent as IconNotice } from '../../styles/icons/megaphone.svg'
import { ReactComponent as IconExpress } from '../../styles/icons/rocket.svg'

const PaperInfo = ({ children, title, type }) => {
  let Icon

  switch (type) {
    case 'default':
      Icon = IconInfo
      break
    case 'help':
      Icon = IconHelp
      break
    case 'notice':
      Icon = IconNotice
      break
    case 'advertising':
      Icon = IconNotice
      break
    case 'express':
      Icon = IconExpress
      break
    default:
      Icon = IconInfo
  }

  const classes = cx('paper-info', {
    'is-default':
      type === 'default' || type === 'express' || type === 'advertising',
    'is-help': type === 'help' || type === 'notice',
  })

  return (
    <div className={classes}>
      <div className="paper-info-header">
        <Icon className="paper-info-icon" />
        <div className="paper-info-title">{title}</div>
      </div>
      <div className="paper-info-content">{children}</div>
    </div>
  )
}

PaperInfo.defaultProps = {
  type: 'default',
}

PaperInfo.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'default',
    'help',
    'express',
    'notice',
    'advertising',
  ]),
}

export default PaperInfo
