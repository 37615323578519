import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { FormattedMessage } from 'react-intl'
import Button from '@redradix/components.button'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import HackFormattedMoney from '../HackFormattedMoney'
import { moneyToFloat } from '../../app/utils'
import imageBig from './images/balanceBig.jpg'
import imageSmall from './images/balanceSmall.jpg'
import FormattedCurrency from '../FormattedCurrency'

const MovementsWithdrawFunds = ({
  balanceAmount,
  incomeAmount,
  isWithdrawFundsDisabled,
  onWithdrawFunds,
  i18n,
}) => {
  return (
    <div className="profile-movements-funds">
      <div className="profile-movements-funds-contents">
        <div className="profile-movements-funds-image">
          <picture>
            <source media="(max-width: 969px)" srcSet={imageSmall} />
            <source media="(min-width: 970px)" srcSet={imageBig} />
            <img src={imageBig} alt="Decoration" />
          </picture>
        </div>
        <p className="profile-movements-funds-message">
          <FormattedMessage
            id="message:current-balance"
            defaultMessage="message:current-balance"
            values={{
              balance: (
                <span className="message-amount">
                  <HackFormattedMoney
                    value={balanceAmount}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              ),
            }}
          />
        </p>
        {incomeAmount.map(income => {
          return (
            <p className="profile-movements-funds-secondary-message">
              <FormattedMessage
                id="message:total-income"
                defaultMessage="message:total-income"
                values={{
                  currency: <FormattedCurrency value={income.total.currency} />,
                  income: (
                    <span className="message-amount">
                      <HackFormattedMoney
                        value={income.total}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </span>
                  ),
                }}
              />
            </p>
          )
        })}

        {moneyToFloat(balanceAmount) > 0 && (
          <div className="profile-movements-funds-action">
            <Button
              type="button"
              hierarchy="secondary"
              isDisabled={isWithdrawFundsDisabled}
              onClick={onWithdrawFunds}
            >
              {i18n('action:withdraw-funds')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

MovementsWithdrawFunds.propTypes = {
  balanceAmount: CoobisPropTypes.money,
  incomeAmount: PropTypes.array,
  isWithdrawFundsDisabled: PropTypes.bool,
  onWithdrawFunds: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(MovementsWithdrawFunds)
