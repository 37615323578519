import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../../hocs/withFormErrors'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import Button from '@redradix/components.button'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import withTranslations from '../../hocs/withTranslations'
import Checkbox from '@redradix/components.checkbox'
import Panel from '../panels/Panel'
import InputSuffix from '../form-elements/InputSuffix'
import PaperInfo from '../papers/PaperInfo'
import { MediaQueryPortable, MediaQueryDesk } from '../shared/MediaQueries'
import TooltipRegularText from '../texts/TooltipRegularText'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import Tooltip from '@redradix/components.tooltip'
import FormattedCurrency from '../FormattedCurrency'

const getTooltipWithOptions = (options, i18n) => {
  return (
    <Tooltip
      position={options.position}
      widthFixed={options.widthFixed}
      renderTooltip={() => (
        <>
          <TooltipRegularText text={i18n('web-resources:prices-tooltip')} />
        </>
      )}
      renderTrigger={({ open, close, triggerRef }, triggerClasses) => (
        <TooltipTrigger
          classNames={triggerClasses}
          onMouseOver={open}
          onMouseOut={close}
          ref={triggerRef}
        />
      )}
    />
  )
}

const YoutubeServicesPrices = ({
  values,
  calculatedValues,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  isPricesVipResource,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  const touchedAndWithErrors = useCallback(
    fieldName =>
      touched[fieldName] &&
      validationMessages[fieldName] &&
      Array.isArray(validationMessages[fieldName]),
    [touched, validationMessages],
  )

  return (
    <form className="basic-form" onSubmit={handleSubmit}>
      <div className="indented-block">
        <Checkbox
          name="mentionEnabled"
          checked={values.mentionEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.mentionEnabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('social-resource:youtube-mention')}</p>
          <p className="subtitle">
            {i18n('social-resource:youtube-mention-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="mentionBasePrice"
              name="mentionBasePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.mentionBasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.mentionBasePrice}
              isDisabled={
                isRequestPending ||
                !isPricesVipResource ||
                !values.mentionEnabled
              }
              suffix={<FormattedCurrency value={values.mentionCurrency} />}
            />
            <InputSuffix
              type="number"
              id="mentionDiscount"
              name="mentionDiscount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              value={values.mentionDiscount}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.mentionDiscount}
              isDisabled={isRequestPending || !values.mentionEnabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="mentionTotalPrice"
              name="mentionTotalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.mentionTotalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.mentionCurrency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('mentionBasePrice') ||
          touchedAndWithErrors('mentionDiscount') ||
          validationMessages.mentionTotalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.mentionBasePrice || []),
                  ...(validationMessages.mentionDiscount || []),
                  ...(validationMessages.mentionTotalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>
      <div className="indented-block">
        <Checkbox
          name="dedicatedEnabled"
          checked={values.dedicatedEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
          isTouched={touched.dedicatedEnabled}
          isDisabled={isRequestPending}
        >
          <p className="title">{i18n('social-resource:youtube-video')}</p>
          <p className="subtitle">
            {i18n('social-resource:youtube-video-description')}
          </p>
        </Checkbox>
        <Panel dark>
          <div className="inline-digits">
            <InputSuffix
              type="number"
              id="dedicatedBasePrice"
              name="dedicatedBasePrice"
              label={i18n('label:base-price')}
              placeholder="30"
              widthBehaviour="full"
              value={values.dedicatedBasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.dedicatedBasePrice}
              isDisabled={
                isRequestPending ||
                !isPricesVipResource ||
                !values.dedicatedEnabled
              }
              suffix={<FormattedCurrency value={values.dedicatedCurrency} />}
            />
            <InputSuffix
              type="number"
              id="dedicatedDiscount"
              name="dedicatedDiscount"
              label={i18n('label:offer')}
              placeholder="15"
              widthBehaviour="full"
              value={values.dedicatedDiscount}
              onChange={handleChange}
              onBlur={handleBlur}
              isTouched={touched.dedicatedDiscount}
              isDisabled={isRequestPending || !values.dedicatedEnabled}
              suffix="%"
            />
            <InputSuffix
              type="number"
              id="dedicatedTotalPrice"
              name="dedicatedTotalPrice"
              label={i18n('label:total-price')}
              placeholder="30"
              widthBehaviour="full"
              value={calculatedValues.dedicatedTotalPrice}
              isDisabled={true}
              suffix={<FormattedCurrency value={values.dedicatedCurrency} />}
            />
            <MediaQueryPortable>
              {getTooltipWithOptions(
                { position: 'bottom', widthFixed: false },
                i18n,
              )}
            </MediaQueryPortable>
            <MediaQueryDesk>
              {getTooltipWithOptions(
                { position: 'right', widthFixed: true },
                i18n,
              )}
            </MediaQueryDesk>
          </div>
        </Panel>

        {(touchedAndWithErrors('dedicatedBasePrice') ||
          touchedAndWithErrors('dedicatedDiscount') ||
          validationMessages.dedicatedTotalPrice) && (
          <WarningMessage
            text={
              <FormattedFormErrors
                errors={[
                  ...(validationMessages.dedicatedBasePrice || []),
                  ...(validationMessages.dedicatedDiscount || []),
                  ...(validationMessages.dedicatedTotalPrice || []),
                ]}
              />
            }
          />
        )}
      </div>

      <div className="indented-block">
        <PaperInfo type="notice">
          {i18n('social-resource:price-considerations')}
        </PaperInfo>
      </div>

      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}

      <div className="form-actions">
        <Button type="submit" isLoading={isRequestPending}>
          {i18n('action:continue')}
        </Button>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  mentionEnabled: PropTypes.bool,
  mentionBasePrice: PropTypes.number,
  mentionDiscount: PropTypes.number,
  dedicatedEnabled: PropTypes.bool,
  dedicatedBasePrice: PropTypes.number,
  dedicatedDiscount: PropTypes.number,
  currency: PropTypes.string,
}

YoutubeServicesPrices.propTypes = {
  calculatedValues: PropTypes.shape({
    mentionTotalPrice: PropTypes.number,
    dedicatedTotalPrice: PropTypes.number,
  }),
  isPricesVipResource: PropTypes.bool,
  isRequestPending: PropTypes.bool.isRequired,
  isRequestSucceeded: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(YoutubeServicesPrices)
