import React from 'react'
import ProfileContainer from '../../ui/profile/ProfileContainer'
import ProfileTabs from '../../ui/profile/ProfileTabs'
import { ReactComponent as ChevronLeft } from './../../styles/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from './../../styles/icons/chevron-right.svg'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import withTranslations from '../../hocs/withTranslations'
import {
  ROUTE_BRAND_PERSONAL_DATA,
  ROUTE_BRAND_WALLET,
  ROUTE_MEDIA_PERSONAL_DATA,
  ROUTE_MEDIA_WALLET,
} from './routes'
import { AuthScope } from '../../services/auth/business'
import { withScope } from '../../services/auth/hocs'
import { withRouter } from 'react-router'

const ProfileContainerWithTabs = ({
  children,
  controllers,
  selectedIndex,
  handleTabChange,
}) => (
  <ProfileContainer>
    <ProfileTabs
      renderLeftIcon={props => <ChevronLeft {...props} />}
      renderRightIcon={props => <ChevronRight {...props} />}
      controllers={controllers}
      selectedIndex={selectedIndex}
      onChange={handleTabChange}
    >
      {children}
    </ProfileTabs>
  </ProfileContainer>
)

const TAB_URL_BY_SCOPE = {
  [AuthScope.BRAND]: {
    personalData: ROUTE_BRAND_PERSONAL_DATA,
    wallet: ROUTE_BRAND_WALLET,
  },

  [AuthScope.MEDIA]: {
    personalData: ROUTE_MEDIA_PERSONAL_DATA,
    wallet: ROUTE_MEDIA_WALLET,
  },
}

const TABS_INDEXES = {
  personalData: 0,
  wallet: 1,
}

const enhance = compose(
  withTranslations,
  withRouter,
  withScope,

  withPropsOnChange(['i18n'], ({ i18n }) => ({
    controllers: [
      {
        name: <span>{i18n(`noun:personal-data`)}</span>,
      },
      {
        name: <span>{i18n(`noun:balance-movements`)}</span>,
      },
    ],
  })),

  withPropsOnChange(['scope', 'location'], ({ scope, location }) => {
    const TAB_URLS = TAB_URL_BY_SCOPE[scope]

    if (TAB_URLS.personalData.match(location.pathname)) {
      return { selectedIndex: TABS_INDEXES.personalData }
    }

    if (TAB_URLS.wallet.match(location.pathname)) {
      return { selectedIndex: TABS_INDEXES.wallet }
    }
  }),

  withHandlers({
    handleTabChange: ({ history, scope }) => tabIndex => {
      const TAB_URLS = TAB_URL_BY_SCOPE[scope]

      if (tabIndex === TABS_INDEXES.personalData) {
        return history.replace(TAB_URLS.personalData.linkTo())
      }

      if (tabIndex === TABS_INDEXES.wallet) {
        return history.replace(TAB_URLS.wallet.linkTo())
      }
    },
  }),
)

export default enhance(ProfileContainerWithTabs)
