import { compose, branch, renderComponent, withProps } from 'recompose'
import * as R from 'ramda'
import withRequestWithIdError from './withRequestWithIdError'
import Error404Message from '../ui/global-message/Error404Message'

const OUT_PROP = 'withRoute404ErrorWithId/requestError'

const withRoute404ErrorWithId = (requestType, idProp = 'id') =>
  compose(
    withRequestWithIdError(requestType, { idProp, outProp: OUT_PROP }),
    branch(
      ownProps => ownProps[OUT_PROP] && ownProps[OUT_PROP].status === 404,
      renderComponent(Error404Message),
    ),
    withProps(R.dissoc(OUT_PROP)),
  )

export default withRoute404ErrorWithId
