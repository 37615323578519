import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getBrandInfo } from '../../services/brand'
import { compose } from 'recompose'
import ProfileDataSection from '../../ui/profile/ProfileDataSection'
import AccountDeleter from '../../ui/form-elements/AccountDeleter'
import ChangePasswordForm from '../common/ChangePasswordForm'
import ProfilePictureForm from '../common/ProfilePictureForm'
import BrandDataForm from './BrandDataForm'
import BrandBillingForm from './BrandBillingForm'
import withTranslations from '../../hocs/withTranslations'
import Modal from '../common/Modal'
import AccountDeleteForm from '../common/AccountDeleteForm'
import { AuthScope } from '../../services/auth/business'
import ProfileContainerWithTabs from '../common/ProfileContainerWithTabs'
import ProfileMovements from '../../ui/profile/ProfileMovements'
import ProfileMovementsList from '../../ui/profile/ProfileMovementsList'
import { NumberPagination } from '@redradix/components.pagination'
import MovementsAddFunds from '../../ui/profile/MovementsAddFunds'
import { withWallet } from '../../services/wallet/hocs'
import withBalanceRecharge from '../wallet/withBalanceRecharge'
import withWalletMovements from '../wallet/withWalletMovements'
import useMovementsPagination from '../wallet/useMovementsPagination'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const BRAND_DELETE_REASONS_I18N = [
  'brand-delete-reason:no-longer-needed',
  'brand-delete-reason:does-not-fill-my-needs',
  'brand-delete-reason:not-fitting-budget',
  'brand-delete-reason:could-not-find',
]

const BrandProfile = ({
  brandInfo,
  balance,
  fetchWalletMovements,
  movements,
  movementsMeta,
  isLoadingMovements,
  handleBalanceRecharge,
  i18n,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const { currentPage, onPageClick } = useMovementsPagination(
    movementsMeta,
    fetchWalletMovements,
  )

  return (
    <ProfileContainerWithTabs>
      <>
        <ProfileDataSection title={i18n('profile:my-data')}>
          <ProfilePictureForm />
          <BrandDataForm brandInfo={brandInfo} />
        </ProfileDataSection>

        <ProfileDataSection
          title={i18n('profile:billing')}
          description={i18n('profile:brand-billing-description')}
        >
          <BrandBillingForm brandInfo={brandInfo} />
        </ProfileDataSection>

        <ProfileDataSection title={i18n('profile:change-password')}>
          <ChangePasswordForm />
        </ProfileDataSection>

        <AccountDeleter
          descriptionText={i18n('profile:delete-description')}
          buttonText={i18n('profile:delete-action')}
          onDelete={() => setDeleteModalOpen(true)}
          i18n={i18n}
        />

        {isDeleteModalOpen && (
          <Modal onClose={() => setDeleteModalOpen(false)}>
            <AccountDeleteForm
              scope={AuthScope.BRAND}
              reasonOptions={BRAND_DELETE_REASONS_I18N}
            />
          </Modal>
        )}
      </>

      <ProfileMovements>
        {/*
        <MovementsAddFunds
          balanceAmount={balance}
          isAddFundsDisabled={false}
          onAddFunds={handleBalanceRecharge}
        />
        */}

        <ProfileMovementsList
          movements={movements}
          isLoading={isLoadingMovements}
        />

        <NumberPagination
          currentPage={currentPage}
          totalPages={movementsMeta.totalPages}
          onPageClick={onPageClick}
        />
      </ProfileMovements>
    </ProfileContainerWithTabs>
  )
}

BrandProfile.propTypes = {
  balance: CoobisPropTypes.money.isRequired,
}

const enhance = compose(
  withTranslations,
  withWallet,

  connect(state => ({ brandInfo: getBrandInfo(state) })),

  withWalletMovements,
  withBalanceRecharge,
)

export default enhance(BrandProfile)
