import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import {
  globalMessagesPropType,
  validationMessagesPropType,
} from '../../hocs/withFormErrors'
import FormattedFormErrors from '../FormattedFormErrors'
import WarningMessage from '../messages/WarningMessage'
import ModalSimpleContent from '../../ui/modals/ModalSimpleContent'
import ModalHeader from '../../ui/modals/ModalHeader'
import Paragraph from './../texts/Paragraph'
import Textarea from './../form-elements/Textarea'

const OrderModalCancel = ({
  sourceName,

  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  i18n,
  validationMessages,
  globalMessages,
  isRequestPending,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="order-modal">
        <ModalSimpleContent
          actionText={i18n('action:cancel')}
          buttonHierarchy="primary"
          buttonVariant="destruct"
          isButtonLoading={isRequestPending}
        >
          <ModalHeader title={i18n('action:cancel-order')} />
          <Paragraph title={i18n('order-modal:order-cancel-resource-name')}>
            <p>{sourceName}</p>
          </Paragraph>
          <Textarea
            name="comments"
            label={i18n('order-modal:order-cancel-reasons')}
            placeholder={i18n('action:write-your-comment-here')}
            onBlur={handleBlur}
            onChange={handleChange}
            errorText={
              validationMessages.comments && (
                <FormattedFormErrors errors={validationMessages.comments} />
              )
            }
            isTouched={touched.comments}
            isDisabled={isRequestPending}
            value={values.comments}
          />
          {globalMessages && (
            <WarningMessage
              text={<FormattedFormErrors errors={globalMessages} />}
            />
          )}
        </ModalSimpleContent>
      </div>
    </form>
  )
}

const FORM_VALUES_PROPTYPES = {
  comments: PropTypes.string,
}

OrderModalCancel.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isRequestPending: PropTypes.bool.isRequired,
  sourceName: PropTypes.string,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(OrderModalCancel)
