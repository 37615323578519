import React from 'react'
import PropTypes from 'prop-types'

const ModalTagline = props => {
  return <p className="modal-tagline">{props.text}</p>
}

ModalTagline.propTypes = {
  text: PropTypes.string,
}

export default ModalTagline
