import React from 'react'
import WebResources from '../../ui/web-resources/WebResources'
import WebResourcesSocialMediaForm from '../../ui/web-resources/WebResourcesSocialMediaForm'
import { WEB_RESOURCE_SECTIONS } from '../../services/resources/constants'
import * as R from 'ramda'
import { editWebResourceSocialMedia } from '../../services/resources'
import { compose } from 'recompose'
import withTranslations from '../../hocs/withTranslations'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import { EDIT_WEB_RESOURCE_SOCIAL_MEDIA } from '../../services/resources/action-types'
import withFormErrors from '../../hocs/withFormErrors'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { connect } from 'react-redux'
import { isNilOrEmpty } from '../utils'
import { getWebResourcesSocialNetworks } from '../../services/resources/business'
import { webResourcesSocialNetworksSchema } from '../../services/resources/validation'
import withWebResource from './withWebResource'

const WebResourcesSocialMedia = ({ i18n, ...props }) => (
  <WebResources
    title={i18n('sections:social-networks-long')}
    currentNavItem={WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS}
    reachedNavItem={props.reachedNavItem}
    isEditing={props.isEditing}
    resourceId={props.resourceId}
  >
    <WebResourcesSocialMediaForm {...props} />
  </WebResources>
)

const enhance = compose(
  withTranslations,

  withWebResource(WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS),

  connect(
    null,
    { editWebResourceSocialMedia },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: getWebResourcesSocialNetworks,

    validationSchema: webResourcesSocialNetworksSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSend = R.filter(R.complement(isNilOrEmpty), values)

      props.editWebResourceSocialMedia(props.resourceId, {
        ...valuesToSend,
        acceptsBroadcast: !R.isEmpty(valuesToSend),
      })
    },
  }),

  withIsRequestPending(EDIT_WEB_RESOURCE_SOCIAL_MEDIA),
  withIsRequestSucceeded(EDIT_WEB_RESOURCE_SOCIAL_MEDIA),
  withRequestError(EDIT_WEB_RESOURCE_SOCIAL_MEDIA),
  withDeleteRequestOnUnmount(EDIT_WEB_RESOURCE_SOCIAL_MEDIA),

  withFormErrors(['facebook', 'twitter', 'instagram']),
)

export default enhance(WebResourcesSocialMedia)
