export const SERVICE = 'AFFILIATE'
export const DOMAIN = 'affiliates'
export const ENTITY_KEY_AFFILIATES = 'affiliates'

export const BRAND_AFFILIATES_COMISSION = Number(
  process.env.REACT_APP_BRAND_AFFILIATES_COMISSION,
)

export const MEDIA_AFFILIATES_COMISSION = Number(
  process.env.REACT_APP_MEDIA_AFFILIATES_COMISSION,
)
