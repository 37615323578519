import React, { useCallback, useMemo } from 'react'
import { ReactiveList } from '@appbaseio/reactivesearch'
import { connect as reactiveConnect } from '@appbaseio/reactivesearch/lib/utils'
import { FullPagination, usePagination } from '@redradix/components.pagination'
import { useQueryParam, JsonParam } from 'use-query-params'
import Result from './Result'
import SortTabs from '../../ui/sort/SortTabs'
import { SORT_QUERIES, DEFAULT_SORT_TYPE } from './sortUtils'
import { FILTERS } from './filterUtils'
import { compose, withProps, withPropsOnChange } from 'recompose'
import withResultsCount from './withResultsCount'
import { connect } from 'react-redux'
import { saveCurrentSearch } from '../../services/search'
import { getCategories } from '../../services/config'
import { getLanguage } from '../../services/me'
import * as R from 'ramda'
import withTranslations from '../../hocs/withTranslations'
import LoaderOverlay from '../../ui/loaders/LoaderOverlay'
import Spinner from '../../ui/loaders/Spinner'
import EmptyResult from '../../ui/search/EmptyResult'
import {
  RESOURCE_TYPES,
  SOCIAL_NETWORK_RESOURCE_TYPES,
} from '../../services/resources/constants'
import { withFetchFavorites } from '../../services/favorites/hocs'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import { ADD_TO_FAVORITES } from '../../services/favorites/action-types'
import { getBrandCurrency } from '../../services/brand'

const SEARCH_MAX_ITEMS = process.env.REACT_APP_SEARCH_MAX_ITEMS || 10000

const ITEMS_PER_PAGE = 10

const RESULT_FIELDS = [
  'id',
  'categories',
  'createdAt',
  'name',
  'url',
  'image',
  'type',
  'meta_followers',
  'prices',
  'statistics',
  'socialNetworks',
  'acceptanceRate',
  'rating',
  'totalRating',
  'isVerified',
  'holidays',
]

const REACTIVELIST_REACT = {
  and: Object.values(FILTERS),
}

const SearchContent = props => {
  // Pagination
  const [maybePage, setPage] = useQueryParam('page', JsonParam)
  const page = maybePage || 1

  // Sorting
  const [sortBy, setSortBy] = useQueryParam('sortBy', JsonParam)

  return (
    <SearchContentsView
      {...props}
      page={page}
      setPage={setPage}
      sortBy={sortBy}
      setSortBy={setSortBy}
      currency={props.currency}
    />
  )
}

const itemsPropsAreEqual = (prevProps, nextProps) =>
  prevProps.loading === nextProps.loading &&
  prevProps.error === nextProps.error &&
  R.equals(prevProps.data, nextProps.data) &&
  R.equals(prevProps.currency, nextProps.currency) &&
  prevProps.translateCategory === nextProps.translateCategory &&
  R.equals(prevProps.priceMinMax, nextProps.priceMinMax) &&
  R.equals(prevProps.favorites, nextProps.favorites)

const RenderItems = React.memo(
  ({ data, currency, translateCategory, priceMinMax, favorites }) => (
    <div className="result-list">
      {data.map(item => (
        <Result
          item={item}
          currency={currency}
          key={item.id}
          translateCategory={translateCategory}
          minPrice={priceMinMax ? priceMinMax[0] : undefined}
          maxPrice={priceMinMax ? priceMinMax[1] : undefined}
          favorites={favorites}
        />
      ))}
    </div>
  ),
  itemsPropsAreEqual,
)

const renderNoResults = () => <EmptyResult />

const SearchContentsView = React.memo(
  ({
    hasWeb,
    hasSocialNetwork,
    resultsCount,
    priceFilter,
    priceMinMax,

    categories,
    language,
    favorites,

    isLoading,

    onQueryChange,

    sortBy,
    setSortBy,
    page,
    setPage,

    currency,

    i18n,
  }) => {
    // Customize query with pagination + sort
    const paginationProps = usePagination({
      page: page,
      onPageChange: page => {
        setPage(page)
        window.scrollTo(0, 0)
      },
      totalItems: Math.min(resultsCount, SEARCH_MAX_ITEMS),
      itemsPerPage: ITEMS_PER_PAGE,
    })

    const defaultSortQuery = useMemo(
      () =>
        (SORT_QUERIES[sortBy] || SORT_QUERIES[DEFAULT_SORT_TYPE])(priceFilter),
      [sortBy, priceFilter],
    )

    const from = (page - 1) * ITEMS_PER_PAGE

    const isMaxSearchPage = from + ITEMS_PER_PAGE > SEARCH_MAX_ITEMS

    const defaultQuery = useCallback(
      () => ({
        ...defaultSortQuery,
        size: isMaxSearchPage ? SEARCH_MAX_ITEMS - from : ITEMS_PER_PAGE,
        from,
        track_total_hits: true,
      }),
      [defaultSortQuery, from, isMaxSearchPage],
    )

    // Translate categories for results
    const translateCategory = useCallback(
      id => R.pathOr(id, [id, 'translations', language], categories),
      [categories, language],
    )

    // Optimizations
    const renderResults = useCallback(
      data => (
        <RenderItems
          {...data}
          currency={currency}
          translateCategory={translateCategory}
          priceMinMax={priceMinMax}
          favorites={favorites}
        />
      ),
      [currency, translateCategory, priceMinMax, favorites],
    )

    return (
      <>
        <SortTabs
          value={sortBy || DEFAULT_SORT_TYPE}
          onChange={setSortBy}
          hasWeb={hasWeb}
          hasSocialNetwork={hasSocialNetwork}
        />

        <div className="results-wrapper">
          {isLoading && (
            <LoaderOverlay>
              <Spinner text={i18n('status:loading-results')} size={60} />
            </LoaderOverlay>
          )}
          <ReactiveList
            listClass="result-list"
            componentId="searchResult"
            dataField="id"
            title="Results"
            scrollOnChange={false}
            showResultStats={false}
            react={REACTIVELIST_REACT}
            onQueryChange={onQueryChange}
            URLParams={false}
            defaultQuery={defaultQuery}
            infiniteScroll={false}
            render={renderResults}
            renderNoResults={renderNoResults}
            includeFields={RESULT_FIELDS}
          />
        </div>

        {!isLoading && resultsCount > 0 && (
          <FullPagination
            {...paginationProps}
            currentPage={page}
            totalPages={paginationProps.lastPage}
          />
        )}
      </>
    )
  },
)

const EMPTY_ARRAY = []

const enhance = compose(
  withTranslations,
  reactiveConnect(
    state => ({
      selectedChannels: R.pathOr(
        EMPTY_ARRAY,
        ['selectedValues', 'channels', 'value'],
        state,
      ),
      priceFilter: R.pathOr(
        undefined,
        ['queryList', 'price', 'nested', 'query'],
        state,
      ),
      priceMinMax: R.pathOr(
        undefined,
        ['selectedValues', 'price', 'value'],
        state,
      ),
      isLoadingResults: R.pathOr(
        undefined,
        ['isLoading', 'searchResult'],
        state,
      ),
    }),
    {},
  ),

  withFetchFavorites,
  withIsRequestPending(ADD_TO_FAVORITES, 'isLoadingFavorites'),
  withPropsOnChange(
    ['isLoadingResults', 'isLoadingFavorites'],
    ({ isLoadingResults, isLoadingFavorites }) => ({
      isLoading: isLoadingResults || isLoadingFavorites,
    }),
  ),

  connect(
    state => ({
      // For category translations in results
      categories: getCategories(state),
      language: getLanguage(state),
      currency: getBrandCurrency(state),
    }),
    {
      onQueryChange: (prevQuery, nextQuery) => saveCurrentSearch(nextQuery),
    },
  ),

  withProps(({ selectedChannels }) => ({
    hasWeb:
      selectedChannels.length === 0 ||
      selectedChannels.includes(RESOURCE_TYPES.WEB),
    hasSocialNetwork:
      selectedChannels.length === 0 ||
      SOCIAL_NETWORK_RESOURCE_TYPES.some(socialNetwork =>
        selectedChannels.includes(socialNetwork),
      ),
  })),

  withResultsCount,
)

export default enhance(SearchContent)
