import React from 'react'
import PropTypes from 'prop-types'

import { Input, PasswordInput } from '@redradix/components.input'
import Checkbox from '@redradix/components.checkbox'
import Button from '@redradix/components.button'
import FormattedFormErrors from './FormattedFormErrors'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../hocs/withFormErrors'
import CoobisPropTypes from '../app/common/coobis-prop-types'
import withTranslations from '../hocs/withTranslations'

const RegisterMedia = ({
  isRequestPending,
  // withTranslations
  i18n,
  // withFormErrors
  validationMessages,
  globalMessages,
  // withFormik
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
}) => (
  <form onSubmit={handleSubmit}>
    <h2>{i18n('register:media')}</h2>

    <Input
      type="text"
      name="name"
      placeholder={i18n('label:name')}
      widthBehaviour="full"
      value={values.name}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={isRequestPending}
      isTouched={touched.name}
      errorText={
        validationMessages.name && (
          <FormattedFormErrors errors={validationMessages.name} />
        )
      }
      autoComplete="given-name"
    />

    <Input
      type="text"
      name="surname"
      placeholder={i18n('label:surname')}
      widthBehaviour="full"
      value={values.surname}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={isRequestPending}
      isTouched={touched.surname}
      errorText={
        validationMessages.surname && (
          <FormattedFormErrors errors={validationMessages.surname} />
        )
      }
      autoComplete="family-name"
    />

    <Input
      type="email"
      name="email"
      placeholder={i18n('label:email')}
      widthBehaviour="full"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={isRequestPending}
      isTouched={touched.email}
      errorText={
        validationMessages.email && (
          <FormattedFormErrors errors={validationMessages.email} />
        )
      }
      autoComplete="email username"
    />

    <Input
      type="text"
      name="phone"
      placeholder={i18n('label:phone')}
      widthBehaviour="full"
      value={values.phone}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={isRequestPending}
      isTouched={touched.phone}
      errorText={
        validationMessages.phone && (
          <FormattedFormErrors errors={validationMessages.phone} />
        )
      }
      autoComplete="tel"
    />

    <PasswordInput
      name="password"
      placeholder={i18n('label:password')}
      widthBehaviour="full"
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={isRequestPending}
      isTouched={touched.password}
      errorText={
        validationMessages.password && (
          <FormattedFormErrors errors={validationMessages.password} />
        )
      }
      autoComplete="new-password"
    />

    <PasswordInput
      name="confirmPassword"
      placeholder={i18n('label:confirm-password')}
      widthBehaviour="full"
      value={values.confirmPassword}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={isRequestPending}
      isTouched={touched.confirmPassword}
      errorText={
        validationMessages.confirmPassword && (
          <FormattedFormErrors errors={validationMessages.confirmPassword} />
        )
      }
      autoComplete="new-password"
    />

    <Checkbox
      name="acceptance"
      checked={values.acceptance}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={isRequestPending}
      isTouched={touched.acceptance}
      errorText={
        validationMessages.acceptance && (
          <FormattedFormErrors errors={validationMessages.acceptance} />
        )
      }
    >
      {i18n('label:acceptance')}
    </Checkbox>

    {globalMessages && <FormattedFormErrors errors={globalMessages} />}

    <Button type="submit" widthBehaviour="full" isLoading={isRequestPending}>
      {i18n('register:action-media')}
    </Button>
  </form>
)

const FORM_VALUES_PROPTYPES = {
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  acceptance: PropTypes.bool,
}

RegisterMedia.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(RegisterMedia)
