import * as R from 'ramda'
import es from './es.json'
import en from './en.json'
import fr from './fr.json'
import it from './it.json'

const enWithFallback = R.mergeRight(es, en)
const frWithFallback = R.mergeRight(enWithFallback, fr)
const itWithFallback = R.mergeRight(enWithFallback, it)

export default {
  es,
  en: enWithFallback,
  fr: frWithFallback,
  it: itWithFallback,
}
