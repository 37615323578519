import React from 'react'
import { FormattedMessage } from 'react-intl'
import { i18nErrorMessagePropType } from '../hocs/withFormErrors'
import withTranslations from '../hocs/withTranslations'

const FormattedFormError = ({ error, i18n }) => (
  <FormattedMessage
    id={error.key}
    values={{
      ...error.values,
      trackerlink: text => (
        <a href={i18n('errors:tracker-missing-link-url')}>{text}</a>
      ),
    }}
    defaultMessage={error.key}
    tagName={'p'}
  />
)

FormattedFormError.propTypes = {
  error: i18nErrorMessagePropType.isRequired,
}

export default withTranslations(FormattedFormError)
