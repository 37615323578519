import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import {
  mapOrderStatusToI18n,
  ORDER_STATUS,
} from '../../services/orders/business'

const mapOrderStatusToClassname = {
  [ORDER_STATUS.DRAFT]: 'order-status-draft',
  [ORDER_STATUS.REQUESTED]: 'order-status-requested',
  [ORDER_STATUS.CANCELED]: 'order-status-canceled',
  [ORDER_STATUS.PENDING]: 'order-status-pending',
  [ORDER_STATUS.ACCEPTED]: 'order-status-accepted',
  [ORDER_STATUS.REJECTED]: 'order-status-rejected',
  [ORDER_STATUS.IN_REVIEW]: 'order-status-in-review',
  [ORDER_STATUS.REVIEWED_NOK]: 'order-status-pending-corrections-pre',
  [ORDER_STATUS.REVIEWED]: 'order-status-reviewed',
  [ORDER_STATUS.DELIVERED]: 'order-status-delivered',
  [ORDER_STATUS.DELIVERED_NOK]: 'order-status-pending-corrections',
  [ORDER_STATUS.APPROVED]: 'order-status-approved',
}

const OrderStatus = ({ i18n, status }) => {
  const classes = cx(`order-status ${mapOrderStatusToClassname[status]}`)
  const statusType = mapOrderStatusToI18n[status]
  return (
    <div className={classes}>
      <span className="order-status-indicator" />
      <span className="order-status-text">{i18n(statusType)}</span>
    </div>
  )
}

OrderStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)),
}

export default withTranslations(OrderStatus)
