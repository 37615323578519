import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import IconFacebook from './images/facebook.svg'
import IconInstagram from './images/instagram.svg'
import IconTwitch from './images/twitch.svg'
import IconTwitter from './images/twitter.svg'
import IconWeb from './images/web.svg'
import IconYoutube from './images/youtube.svg'
import IconTiktok from './images/tiktok.svg'

import LogoYoutube from './logos/youtube.svg'

import { RESOURCE_TYPES } from '../../services/resources/constants'

const IconSocial = ({ type, border }) => {
  let IconUrl
  if (type === RESOURCE_TYPES.FACEBOOK) {
    IconUrl = IconFacebook
  } else if (type === RESOURCE_TYPES.INSTAGRAM) {
    IconUrl = IconInstagram
  } else if (type === RESOURCE_TYPES.TWITCH) {
    IconUrl = IconTwitch
  } else if (type === RESOURCE_TYPES.TWITTER) {
    IconUrl = IconTwitter
  } else if (type === RESOURCE_TYPES.WEB) {
    IconUrl = IconWeb
  } else if (type === RESOURCE_TYPES.YOUTUBE) {
    IconUrl = IconYoutube
  } else if (type === RESOURCE_TYPES.TIKTOK) {
    IconUrl = IconTiktok
  }
  const classes = cx('icon-social', type.toLowerCase(), {
    'has-border': border,
  })
  return <img className={classes} src={IconUrl} alt={type} />
}

IconSocial.defaultProps = {
  border: false,
}

IconSocial.propTypes = {
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  border: PropTypes.bool.isRequired,
}

const LogoSocial = ({ type }) => {
  const logos = {
    [RESOURCE_TYPES.YOUTUBE]: LogoYoutube,
  }
  const classes = cx('logo-social', type.toLowerCase())
  return <img className={classes} src={logos[type]} alt={type} />
}

LogoSocial.propTypes = {
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
}

export default IconSocial
export { IconSocial, LogoSocial }
