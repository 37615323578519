import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import SocialResource from '../../ui/social-resource/SocialResource'
import withTranslations from '../../hocs/withTranslations'
import {
  SOCIAL_RESOURCE_SECTIONS,
  RESOURCE_TYPES,
} from '../../services/resources/constants'
import withSocialResource from './withSocialResource'
import SocialFacebookServicesPricesForm from './SocialFacebookServicesPricesForm'
import SocialTwitterServicesPricesForm from './SocialTwitterServicesPricesForm'
import SocialInstagramServicesPricesForm from './SocialInstagramServicesPricesForm'
import SocialYoutubeServicesPricesForm from './SocialYoutubeServicesPricesForm'
import SocialTwitchServicesPricesForm from './SocialTwitchServicesPricesForm'
import SocialTiktokServicesPricesForm from './SocialTiktokServicesPricesForm'
import withIsVipSocialNetworks from './withIsVipSocialNetworks'

const SocialResourceServicesPrices = ({ i18n, ...props }) => (
  <SocialResource
    title={i18n('sections:services-prices-long')}
    currentNavItem={SOCIAL_RESOURCE_SECTIONS.SERVICES}
    reachedNavItem={props.reachedNavItem}
    resourceId={props.resourceId}
    channel={props.channel}
  >
    {props.channel === RESOURCE_TYPES.FACEBOOK && (
      <SocialFacebookServicesPricesForm {...props} />
    )}
    {props.channel === RESOURCE_TYPES.TWITTER && (
      <SocialTwitterServicesPricesForm {...props} />
    )}
    {props.channel === RESOURCE_TYPES.INSTAGRAM && (
      <SocialInstagramServicesPricesForm {...props} />
    )}
    {props.channel === RESOURCE_TYPES.YOUTUBE && (
      <SocialYoutubeServicesPricesForm {...props} />
    )}
    {props.channel === RESOURCE_TYPES.TWITCH && (
      <SocialTwitchServicesPricesForm {...props} />
    )}
    {props.channel === RESOURCE_TYPES.TIKTOK && (
      <SocialTiktokServicesPricesForm {...props} />
    )}
  </SocialResource>
)

const enhance = compose(
  withTranslations,
  withSocialResource(SOCIAL_RESOURCE_SECTIONS.SERVICES),
  withIsVipSocialNetworks,
  withPropsOnChange(
    ['resourceData', 'isVipSocialNetworks'],
    ({ resourceData, isVipSocialNetworks }) => ({
      isPricesVipResource: resourceData.isVipPrices || isVipSocialNetworks,
    }),
  ),
)

export default enhance(SocialResourceServicesPrices)
