import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Card from './Card'
import Select from '../form-elements/Select'

const ChartCard = ({
  title,
  selectorOptions,
  selectorValue,
  isSelectorDisabled,
  isDouble,
  isPie,
  hasSelector,
  onSelectorChange,
  children,
}) => {
  const wrapperClasses = cx('chart-card-wrapper', {
    'is-double': isDouble,
    'is-pie': isPie,
  })
  return (
    <Card isHighlighted>
      <div className="chart-card">
        <div className="chart-card-header">
          {title && <h3 className="chart-card-title">{title}</h3>}
          {hasSelector && (
            <div className="chart-card-selector">
              <Select
                options={selectorOptions}
                isDisabled={isSelectorDisabled}
                onChange={onSelectorChange}
                value={selectorValue}
              />
            </div>
          )}
        </div>
        <div className={wrapperClasses}>{children}</div>
      </div>
    </Card>
  )
}

ChartCard.propTypes = {
  title: PropTypes.string,
  selectorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
    }),
  ),
  selectorValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  isSelectorDisabled: PropTypes.bool,
  isDouble: PropTypes.bool,
  isPie: PropTypes.bool,
  hasSelector: PropTypes.bool,
  onSelectorChange: PropTypes.func,
  children: PropTypes.node,
}

export default ChartCard
