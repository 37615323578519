import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import ChartLegend from './ChartLegend'
import withTranslations from '../../hocs/withTranslations'
import DynamicChartLegend from './DynamicChartLegend'
import {
  getChartMinAndMaxDates,
  getSeriesMaxInitialDay,
} from '../../app/common/statistics-utils'
import { DATE_FORMAT_OPTIONS } from '../../config/formats'

import am4lang_en_US from '@amcharts/amcharts4/lang/en_US'
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES'
import am4lang_de_DE from '@amcharts/amcharts4/lang/de_DE'
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR'
import am4lang_it_IT from '@amcharts/amcharts4/lang/it_IT'

const LOCALE_TO_AMCHARTS_LOCALE = {
  es: am4lang_es_ES,
  en: am4lang_en_US,
  de: am4lang_de_DE,
  fr: am4lang_fr_FR,
  it: am4lang_it_IT,
}

am4core.useTheme(am4themes_animated)

const GRIP_SIZE = 15
const SCROLLBAR_HEIGHT = 5
const SCROLLBAR_MARGIN_BOTTOM = 25
const SCROLLBAR_RADIUS = 5
const UNSELECTED_RANGE = '#EBE7F7' // unselected range ($purple-10)
const BUTTON = '#C5B9E8' // buttons ($purple-40) - also for selected range (thumb)
const BUTTON_HOVER = '#A9B3FF' // hovered buttons ($purple-light)
const BUTTON_ACTIVE = '#8B74D1' // active buttons ($purple-60)

function applyButtonStyles(element) {
  const normal = element.background
  const down = element.background.states.getKey('down').properties
  const hover = element.background.states.getKey('hover').properties
  normal.fill = am4core.color(BUTTON)
  normal.fillOpacity = 1
  hover.fill = am4core.color(BUTTON_HOVER)
  hover.fillOpacity = 1
  down.fill = am4core.color(BUTTON_ACTIVE)
  down.fillOpacity = 1
}

const createChart = (element, i18nDate, locale) => {
  // Create chart instance
  const chart = am4core.create(element, am4charts.XYChart)

  chart.language.locale = LOCALE_TO_AMCHARTS_LOCALE[locale]

  // Set input format for the dates
  //chart.dateFormatter.inputDateFormat = 'MMM-yy'

  // Category axis (X)
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())

  dateAxis.renderer.grid.template.location = 0
  dateAxis.renderer.cellStartLocation = 0.4
  dateAxis.renderer.cellEndLocation = 0.6
  dateAxis.renderer.minGridDistance = 50
  dateAxis.renderer.grid.template.stroke = am4core.color('#999ab1')
  dateAxis.renderer.labels.template.fill = am4core.color('#999ab1')
  dateAxis.renderer.labels.template.fontSize = 12
  dateAxis.tooltipDateFormat = 'i'
  dateAxis.tooltip.autoTextColor = false
  dateAxis.tooltip.background.fill = am4core.color('#eee')
  dateAxis.tooltip.getFillFromObject = false
  dateAxis.tooltip.label.fill = am4core.color('#999ab1')
  dateAxis.tooltip.background.strokeOpacity = 0
  dateAxis.tooltip.label.fontSize = '12'
  dateAxis.adapter.add('getTooltipText', text => {
    return i18nDate(text, DATE_FORMAT_OPTIONS)
  })

  // Value axis (Y)
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.min = 0
  valueAxis.renderer.minGridDistance = 50
  valueAxis.renderer.grid.template.stroke = am4core.color('#999ab1')
  valueAxis.renderer.baseGrid.disabled = true //remove baseline
  valueAxis.renderer.labels.template.fill = am4core.color('#999ab1')
  valueAxis.renderer.labels.template.fontSize = 12
  valueAxis.tooltip.autoTextColor = false
  valueAxis.tooltip.background.fill = am4core.color('#eee')
  valueAxis.tooltip.getFillFromObject = false
  valueAxis.tooltip.label.fill = am4core.color('#999ab1')
  valueAxis.tooltip.background.strokeOpacity = 0
  valueAxis.tooltip.label.fontSize = '12'

  // Add cursor
  chart.cursor = new am4charts.XYCursor()
  chart.cursor.lineX.disabled = true

  // Add scrollbar
  chart.scrollbarX = new am4charts.XYChartScrollbar()

  chart.scrollbarX.height = SCROLLBAR_HEIGHT
  chart.scrollbarX.minHeight = SCROLLBAR_HEIGHT
  chart.scrollbarX.maxHeight = SCROLLBAR_HEIGHT
  chart.scrollbarX.marginBottom = SCROLLBAR_MARGIN_BOTTOM

  // Set colors
  applyButtonStyles(chart.zoomOutButton)
  applyButtonStyles(chart.scrollbarX.startGrip)
  applyButtonStyles(chart.scrollbarX.endGrip)
  applyButtonStyles(chart.scrollbarX.thumb)

  chart.scrollbarX.startGrip.width = GRIP_SIZE
  chart.scrollbarX.startGrip.height = GRIP_SIZE
  chart.scrollbarX.startGrip.icon.disabled = true
  chart.scrollbarX.endGrip.width = GRIP_SIZE
  chart.scrollbarX.endGrip.height = GRIP_SIZE
  chart.scrollbarX.endGrip.icon.disabled = true

  chart.scrollbarX.background.cornerRadius(
    SCROLLBAR_RADIUS,
    SCROLLBAR_RADIUS,
    SCROLLBAR_RADIUS,
    SCROLLBAR_RADIUS,
  )
  chart.scrollbarX.background.fill = am4core.color(UNSELECTED_RANGE)
  chart.scrollbarX.background.fillOpacity = 1
  chart.scrollbarX.unselectedOverlay.fillOpacity = 0

  // Hide series datapoints in scrollbar
  chart.scrollbarX.scrollbarChart.seriesContainer.hide()
  chart.scrollbarX.scrollbarChart.bulletsContainer.hide()

  // Set scrollbar locale
  chart.scrollbarX.scrollbarChart.language.locale =
    LOCALE_TO_AMCHARTS_LOCALE[locale]

  // Add scroll date axis
  const scrollDateAxis = chart.scrollbarX.scrollbarChart.xAxes.push(
    new am4charts.DateAxis(),
  )
  scrollDateAxis.renderer.labels.template.dy = -28
  scrollDateAxis.renderer.grid.template.location = 0
  scrollDateAxis.renderer.cellStartLocation = 0.4
  scrollDateAxis.renderer.cellEndLocation = 0.6
  scrollDateAxis.renderer.minGridDistance = 50
  scrollDateAxis.renderer.grid.template.stroke = am4core.color('#999ab1')
  scrollDateAxis.renderer.labels.template.fill = am4core.color('#999ab1')
  scrollDateAxis.renderer.labels.template.fontSize = 10

  // Mandatory, but we want it hidden
  const scrollValueAxis = chart.scrollbarX.scrollbarChart.yAxes.push(
    new am4charts.ValueAxis(),
  )
  scrollValueAxis.hide()

  // Zoom to first MAX_INITIAL_MONTHS months if there are more in the series
  chart.events.on('ready', () => {
    const allSeriesDates = getChartMinAndMaxDates(
      chart.series.values,
      serie => serie.data,
    )

    const minDate = new Date(allSeriesDates.minDate)
    const seriesMaxInitialDay = getSeriesMaxInitialDay(minDate)

    const maxDate = new Date(allSeriesDates.maxDate)
    const seriesMaxDateNextMonthDay = new Date(
      maxDate.getFullYear(),
      maxDate.getMonth() + 1,
      1,
    )

    const hasInitialZoom = +seriesMaxDateNextMonthDay > +seriesMaxInitialDay

    if (hasInitialZoom) {
      dateAxis.zoomToDates(minDate, new Date(seriesMaxInitialDay), false, true)
    }
  })

  return chart
}

const createSeries = (id, name, data, color) => {
  const series = new am4charts.LineSeries()
  series.id = id
  series.data = data
  series.dataFields.valueY = 'value'
  series.dataFields.dateX = 'date'
  series.name = name
  series.stroke = am4core.color(color)
  series.strokeWidth = 3
  series.tensionX = 0.8
  const bullet = series.bullets.push(new am4charts.CircleBullet())
  bullet.fill = am4core.color('#FFFFFF')
  bullet.strokeWidth = 3
  let bullethover = bullet.states.create('hover')
  bullethover.properties.scale = 1.3
  series.tooltip.autoTextColor = false
  series.tooltip.background.fill = am4core.color('#fff')
  series.tooltip.getFillFromObject = false
  series.tooltip.label.fill = am4core.color('#999ab1')
  series.tooltip.label.fontSize = '12'
  series.tooltipText = '{valueY}'
  series.legendSettings.valueText = '{name}'

  return series
}

const MultilineChart = ({
  data,
  onLegendDelete,
  legendOptions,
  onLegendOptionChange,
  maxLegendItems,
  i18nDate,
  locale,
}) => {
  const chart = useRef()
  const chartElementRef = useRef()

  useEffect(() => {
    chart.current = createChart(chartElementRef.current, i18nDate, locale)

    return () => {
      if (chart.current) {
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [i18nDate, locale])

  useEffect(() => {
    // Return if chart is unmounted
    if (!chart.current) {
      return
    }

    // Set data

    // Remove series that have been removed
    for (let i = 0; i < chart.current.series.length; ) {
      const isSeriesInData =
        data.find(serie => serie.id === chart.current.series.values[i].id) !==
        undefined

      if (isSeriesInData) {
        i++
      } else {
        chart.current.series.removeIndex(i).dispose()
        chart.current.scrollbarX.scrollbarChart.series.removeIndex(i).dispose()
      }
    }

    // Add new series
    for (const serie of data) {
      const isDataInSeries =
        chart.current.series.values.find(
          currentSerie => currentSerie.id === serie.id,
        ) !== undefined

      if (!isDataInSeries) {
        chart.current.series.push(
          createSeries(serie.id, serie.name, serie.items, serie.color),
        )
        chart.current.scrollbarX.scrollbarChart.series.push(
          createSeries(serie.id, serie.name, serie.items, serie.color),
        )
      }
    }

    // Update X axes
    chart.current.xAxes.values[0].invalidate()
    chart.current.scrollbarX.scrollbarChart.xAxes.values[0].invalidate()
  }, [data])

  const legendItems = useMemo(
    () =>
      data.map(item => ({
        id: item.id,
        label: item.name,
        color: item.color,
      })),
    [data],
  )

  return (
    <div className="chart-wrapper">
      <div className="multiline-chart" ref={chartElementRef} />
      {legendOptions ? (
        <DynamicChartLegend
          items={legendItems}
          onDelete={onLegendDelete}
          options={legendOptions}
          onOptionChange={onLegendOptionChange}
          maxItems={maxLegendItems}
        />
      ) : (
        data.length > 1 && <ChartLegend items={legendItems} />
      )}
    </div>
  )
}

MultilineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      color: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
    }),
  ),
  onLegendDelete: PropTypes.func,
  legendOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
      isFixed: PropTypes.bool,
    }),
  ),
  onLegendOptionChange: PropTypes.func,
  maxLegendItems: PropTypes.number,
}

export default withTranslations(MultilineChart)
