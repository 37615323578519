import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailFacebookAll from '../../ui/campaigns/views/CampaignDetailFacebookAll'
import withPublicationsTable from './withPublicationsTable'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  publications: stats.kpis.totalOrders,
  fanpages: stats.kpis.totalResources,
  reach: stats.kpis.totalReach,
  engagements: stats.kpis.totalEngagements,
  clicks: stats.kpis.totalClicks,
}))

const withFacebookPublicationsTable = withPublicationsTable(
  orderStatsDetail => ({
    likes: orderStatsDetail.kpis.likes,
    engagements: orderStatsDetail.kpis.engagements,
  }),
)

const enhance = compose(
  withKPIs,
  withFacebookPublicationsTable,
  withExportReportContents(),
)

export default enhance(CampaignDetailFacebookAll)
