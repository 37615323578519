import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import OrderDetailSection from './OrderDetailSection'
import AnchorDownload from './../texts/AnchorDownload'
import { formatBytes } from '../../app/utils'

const OrderDetailDocuments = ({ items, i18n }) => {
  return (
    <OrderDetailSection title={i18n('sections:documents')}>
      {items.map((item, i) => {
        const documentTitle = `${item.title}`
        return (
          <AnchorDownload
            key={i}
            extraText={formatBytes(item.size)}
            linkUrl={item.url}
            text={item.title}
            title={i18n('message:download-document', { documentTitle })}
            isDisabled={item.isDisabled}
          />
        )
      })}
    </OrderDetailSection>
  )
}

OrderDetailDocuments.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      size: PropTypes.string,
      isDisabled: PropTypes.bool,
    }),
  ),
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(OrderDetailDocuments)
