import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const FormFieldRow = ({ children, hasTreeCols, compact }) => {
  const className = cx('form-field-row', {
    'has-three-cols': hasTreeCols,
    compact: compact,
  })
  return <div className={className}>{children}</div>
}

FormFieldRow.propTypes = {
  children: PropTypes.node,
  hasTreeCols: PropTypes.bool,
  compact: PropTypes.bool,
}

export default FormFieldRow
