import { connect } from 'react-redux'
import { fetchMyAffiliates } from './actions'
import { compose } from 'recompose'
import withEffect from '../../hocs/withEffect'

export const withFetchAffiliates = compose(
  connect(
    null,
    { fetchMyAffiliates },
  ),
  withEffect(
    ({ fetchMyAffiliates }) => {
      fetchMyAffiliates()
    },
    ({ fetchMyAffiliates }) => [fetchMyAffiliates],
  ),
)
