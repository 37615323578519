import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import {
  getRequest,
  postRequest,
  postFormRequest,
  putRequest,
  deleteRequest,
} from '../api-call'
import { parseResponseErrors } from '../error-handling'
import { i18nErrorKeys } from '../../app/common/validation'
import { prettifyConstant } from '../../app/common/routes'
import { SOCIAL_SERVICES } from './constants'

const RESOURCES_ENDPOINT = `${envConfig.usersBackendUrl}/media/resources`

const RESOURCE_SCREENSHOT_ENDPOINT = `${RESOURCES_ENDPOINT}/screenshot`

const RESOURCE_UPLOAD_IMAGE_ENDPOINT = `${RESOURCES_ENDPOINT}/document`

const resourceListEndpoint = (page, itemsPerPage) =>
  `${RESOURCES_ENDPOINT}?page=${page}&limit=${itemsPerPage}`

const resourceByIdEndpoint = id => `${RESOURCES_ENDPOINT}/${id}`

const resourceAnalyticsEndpoint = id => `${RESOURCES_ENDPOINT}/${id}/analytics`

const resourcePricesEndpoint = id => `${RESOURCES_ENDPOINT}/${id}/prices`

const resourceUpdateStatsEndpoint = id =>
  `${RESOURCES_ENDPOINT}/${id}/stats-update`

const resourceUpdateInstagramBusinessEndpoint = id =>
  `${RESOURCES_ENDPOINT}/${id}/instagram-business`

const resourceDetailsEndpoint = id =>
  `${envConfig.searchBackendUrl}/resources/${id}`

const socialServiceConnectEndpoint = (
  socialService,
  redirect,
  resourceId = undefined,
) =>
  `${
    envConfig.socialBackendUrl
  }/api/session/social/${socialService}?redirect=${encodeURIComponent(
    redirect,
  )}${resourceId ? `&resourceId=${resourceId}` : ''}`

const socialServiceProfilesEndpoint = (socialService, resourceId) => {
  let serviceType = socialService
  let profileType

  switch (socialService) {
    case SOCIAL_SERVICES.GOOGLE: {
      profileType = 'profiles'
      break
    }
    case SOCIAL_SERVICES.FACEBOOK: {
      profileType = 'pages'
      break
    }
    case SOCIAL_SERVICES.INSTAGRAM_BUSINESS: {
      serviceType = SOCIAL_SERVICES.INSTAGRAM
      profileType = 'businessaccounts'
      break
    }
    default: {
      throw new Error(
        `Social service ${serviceType} does not support profile fetching`,
      )
    }
  }

  return `${envConfig.socialBackendUrl}/api/social/${serviceType}/${profileType}?resourceId=${resourceId}`
}

const socialServiceDataEndpoint = (socialService, resourceId) =>
  `${envConfig.socialBackendUrl}/api/social/${prettifyConstant(
    socialService,
  )}/resource?resourceId=${resourceId}`

export function* fetchResources(page, itemsPerPage) {
  const response = yield call(
    getRequest,
    resourceListEndpoint(page, itemsPerPage),
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchResourceById(id) {
  const response = yield call(getRequest, resourceByIdEndpoint(id), {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* requestScreenshot(url) {
  const response = yield call(
    postRequest,
    RESOURCE_SCREENSHOT_ENDPOINT,
    { url },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* uploadResourceImage(file) {
  const response = yield call(
    postFormRequest,
    RESOURCE_UPLOAD_IMAGE_ENDPOINT,
    { file },
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

const MAP_GLOBAL_WEB_RESOURCES = {
  ResourceNotFoundException: values => ({
    key: i18nErrorKeys.RESOURCE_NOT_FOUND,
    values,
  }),
  ResourceUrlExistException: values => ({
    key: i18nErrorKeys.RESOURCE_URL_EXIST,
    values,
  }),
}

export function* createResource(data) {
  const response = yield call(postRequest, RESOURCES_ENDPOINT, data, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_WEB_RESOURCES,
  })

  return parsedResponse
}

export function* updateResource(id, data) {
  const response = yield call(putRequest, resourceByIdEndpoint(id), data, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_WEB_RESOURCES,
  })

  return parsedResponse
}

export function* deleteResource(id) {
  const response = yield call(deleteRequest, resourceByIdEndpoint(id), {})

  const parsedResponse = parseResponseErrors(response, {
    mapGlobal: MAP_GLOBAL_WEB_RESOURCES,
  })

  return parsedResponse
}

export function* requestConnectSocial(socialService, redirect, resourceId) {
  console.log('requestConnectSocial', socialService, redirect, resourceId)
  const response = yield call(
    getRequest,
    socialServiceConnectEndpoint(socialService, redirect, resourceId),
    { credentials: 'include', parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchSocialProfiles(socialService, resourceId) {
  const response = yield call(
    getRequest,
    socialServiceProfilesEndpoint(socialService, resourceId),
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchSocialData(socialService, resourceId) {
  const response = yield call(
    getRequest,
    socialServiceDataEndpoint(socialService, resourceId),
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* fetchResourceDetails(resourceId) {
  const response = yield call(getRequest, resourceDetailsEndpoint(resourceId), {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* editResourceAnalytics(resourceId, data) {
  const response = yield call(
    putRequest,
    resourceAnalyticsEndpoint(resourceId),
    data,
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* updateResourceInstagramBusiness(resourceId, data) {
  const response = yield call(
    putRequest,
    resourceUpdateInstagramBusinessEndpoint(resourceId),
    data,
    { parseJSON: true },
  )
  const parsedResponse = parseResponseErrors(response)
  return parsedResponse
}

export function* editResourcePrices(resourceId, data) {
  const response = yield call(
    putRequest,
    resourcePricesEndpoint(resourceId),
    data,
    { parseJSON: true },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* updateResourceStats(resourceId) {
  const response = yield call(
    postRequest,
    resourceUpdateStatsEndpoint(resourceId),
    {},
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}
