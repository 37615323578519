import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { MediaQueryDesk, MediaQueryPortable } from '../shared/MediaQueries'
import Card from './Card'
import GaugeBar from '../charts/GaugeBar'
import IconSocial from '../icons/IconSocial'
import imgMozLogo from './images/logo-moz.png'
import { truncateNumber } from '../../app/utils'
import { renderSocialValue } from '../../app/common/statistics-utils'
import { RESOURCE_TYPES } from '../../services/resources/constants'

const MetricsCard = ({
  facebookFollowers,
  twitterFollowers,
  DAmozScore,
  PAmozScore,
  visits,
  users,
  pageViews,
  i18n,
  i18nNumber,
}) => {
  return (
    <Card isHighlighted>
      <div className="metrics-card">
        <div className="metrics-card-section-group">
          <div className="metrics-card-section">
            <div className="metrics-card-section-wrapper">
              <ul className="metrics-card-icon-list">
                <li>
                  <IconSocial type={RESOURCE_TYPES.FACEBOOK} />
                  <span>
                    {renderSocialValue(
                      truncateNumber(facebookFollowers, i18nNumber),
                    )}
                  </span>
                </li>

                <li>
                  <IconSocial type={RESOURCE_TYPES.TWITTER} />
                  <span>
                    {renderSocialValue(
                      truncateNumber(twitterFollowers, i18nNumber),
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <MediaQueryDesk>
            <div className="metrics-card-section">
              <div className="metrics-card-section-wrapper">
                <div className="logo">
                  <img src={imgMozLogo} alt="Moz" />
                </div>
                <GaugeBar
                  hasLabel
                  labelText="DA"
                  color="#c5b9e8"
                  total={100}
                  percent={Math.ceil(DAmozScore)}
                />
                <GaugeBar
                  hasLabel
                  labelText="PA"
                  color="#3e17b3"
                  total={100}
                  percent={Math.ceil(PAmozScore)}
                />
              </div>
            </div>
          </MediaQueryDesk>
          <div className="metrics-card-section">
            <div className="metrics-card-section-wrapper">
              <ul className="metrics-card-definition-list">
                <li>
                  <span className="definition-term">{i18n('noun:visits')}</span>
                  <span className="definition-value">
                    {truncateNumber(visits, i18nNumber)}
                  </span>
                </li>
                <li>
                  <span className="definition-term">{i18n('noun:users')}</span>
                  <span className="definition-value">
                    {truncateNumber(users, i18nNumber)}
                  </span>
                </li>
                <li>
                  <span className="definition-term">
                    {i18n('noun:page-views')}
                  </span>
                  <span className="definition-value">
                    {truncateNumber(pageViews, i18nNumber)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <MediaQueryPortable>
          <div className="metrics-card-bar-group">
            <div className="logo">
              <img src={imgMozLogo} alt="Moz" />
            </div>
            <GaugeBar
              hasLabel
              labelText="DA"
              color="#c5b9e8"
              total={100}
              percent={Math.ceil(DAmozScore)}
            />
            <GaugeBar
              hasLabel
              labelText="PA"
              color="#3e17b3"
              total={100}
              percent={Math.ceil(PAmozScore)}
            />
          </div>
        </MediaQueryPortable>
      </div>
    </Card>
  )
}

MetricsCard.propTypes = {
  facebookFollowers: PropTypes.number,
  twitterFollowers: PropTypes.number,
  visits: PropTypes.number,
  users: PropTypes.number,
  pageViews: PropTypes.number,
  DAmozScore: PropTypes.number,
  PAmozScore: PropTypes.number,
  // withTranslations
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(MetricsCard)
