import { connect } from 'react-redux'
import { getLanguage, setLanguage, getMyInfo } from './'
import { compose } from 'recompose'
import withRouteServerError from '../../hocs/withRouteServerError'
import { FETCH_MY_INFO } from './action-types'
import withSpinner from '../../hocs/withSpinner'

export const withLanguageSelect = connect(
  state => ({
    language: getLanguage(state),
  }),
  { setLanguage },
)

export const withEnsureMyInfo = compose(
  withRouteServerError(FETCH_MY_INFO),
  withSpinner(FETCH_MY_INFO),
)

export const withMyInfo = connect(state => ({
  myInfo: getMyInfo(state),
}))
