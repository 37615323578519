import * as hash from 'reduken/hash'
import * as R from 'ramda'
import {
  DOMAIN,
  HASH_KEY_UNREAD_NOTIFICATIONS,
  HASH_KEY_UNREAD_NOTIFICATIONS_TOTAL,
} from './constants'
import { createDeepEqualSelector } from '../../app/utils'

export const getUnreadNotifications = createDeepEqualSelector(
  hash.getFromPath(DOMAIN, HASH_KEY_UNREAD_NOTIFICATIONS),
  notifications =>
    !R.isNil(notifications)
      ? !R.isEmpty(notifications)
        ? Object.values(notifications)
        : []
      : null,
)

export const getNotificationsCount = hash.getFromPath(
  DOMAIN,
  HASH_KEY_UNREAD_NOTIFICATIONS_TOTAL,
)
