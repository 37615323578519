import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { AuthScope } from '../services/auth/business'
import { Input, PasswordInput } from '@redradix/components.input'
import Button from '@redradix/components.button'
import FormattedFormErrors from './FormattedFormErrors'
import {
  validationMessagesPropType,
  globalMessagesPropType,
} from '../hocs/withFormErrors'
import CoobisPropTypes from '../app/common/coobis-prop-types'
import withTranslations from '../hocs/withTranslations'
import { ROUTE_PASSWORD_RECOVERY_REQUEST } from '../app/common/routes'
import WarningMessage from './messages/WarningMessage'
import { PASSWORD_MAX_LENGTH } from '../config/constants'
import RecaptchaTermsConditions from './RecaptchaTermsConditions'

const Login = ({
  needsScope,
  handleLoginWithScope,
  isLoginPending,
  isLoginAsMediaPending,
  isLoginAsBrandPending,
  recaptcha,
  // withTranslations
  i18n,
  // withFormErrors
  validationMessages,
  globalMessages,
  // withRouter
  location,
  // withFormik
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
}) => {
  let content

  if (needsScope) {
    const handleScope = scope => e => {
      e.preventDefault()
      handleLoginWithScope(values.email, values.password, scope)
    }

    content = (
      <>
        <div className="action-group role-selection">
          <div className="action">
            <Button
              onClick={handleScope(AuthScope.BRAND)}
              isDisabled={isLoginAsMediaPending}
              isLoading={isLoginAsBrandPending}
              widthBehaviour="full"
            >
              {i18n('login:as-brand')}
            </Button>
          </div>
          <div className="action">
            <Button
              onClick={handleScope(AuthScope.MEDIA)}
              isDisabled={isLoginAsBrandPending}
              isLoading={isLoginAsMediaPending}
              hierarchy="secondary"
              widthBehaviour="full"
            >
              {i18n('login:as-media')}
            </Button>
          </div>
        </div>
      </>
    )
  } else {
    content = (
      <>
        <Input
          type="email"
          name="email"
          label={i18n('label:email')}
          placeholder={i18n('label:email')}
          widthBehaviour="full"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isLoginPending}
          isTouched={touched.email}
          errorText={
            validationMessages.email && (
              <FormattedFormErrors errors={validationMessages.email} />
            )
          }
          autoComplete="username email"
        />

        <PasswordInput
          name="password"
          label={i18n('label:password')}
          placeholder={i18n('label:password')}
          widthBehaviour="full"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isLoginPending}
          isTouched={touched.password}
          maxLength={PASSWORD_MAX_LENGTH}
          errorText={
            validationMessages.password && (
              <FormattedFormErrors errors={validationMessages.password} />
            )
          }
          autoComplete="current-password"
        />

        {globalMessages && (
          <WarningMessage
            text={<FormattedFormErrors errors={globalMessages} />}
          />
        )}

        <Link
          className="password-link"
          to={{
            pathname: ROUTE_PASSWORD_RECOVERY_REQUEST.linkTo(),
            state: { from: location.pathname }, // push current location so we can go back
          }}
        >
          {i18n('login:forgot-password')}
        </Link>

        <Button
          type="submit"
          widthBehaviour="full"
          hierarchy="tertiary"
          isLoading={isLoginPending}
        >
          {i18n('login:action-login')}
        </Button>

        <RecaptchaTermsConditions i18n={i18n} />

        <div className="new-account-links">
          <span>{i18n('public:new-account-text')}</span>
          <a href={i18n('public:action-brand-register-link')}>
            {i18n('public:action-brand-register')}
          </a>
          <a href={i18n('public:action-media-register-link')}>
            {i18n('public:action-media-register')}
          </a>
        </div>
      </>
    )
  }

  return (
    <div className="login-form-wrapper is-public">
      <form onSubmit={handleSubmit} className="public-form" noValidate>
        <h2>{i18n('login:login')}</h2>
        {content}
      </form>
    </div>
  )
}

const FORM_VALUES_PROPTYPES = {
  email: PropTypes.string,
  password: PropTypes.string,
}

Login.propTypes = {
  handleLoginWithScope: PropTypes.func.isRequired,
  needsScope: PropTypes.bool,
  isLoginPending: PropTypes.bool.isRequired,
  isLoginAsMediaPending: PropTypes.bool.isRequired,
  isLoginAsBrandPending: PropTypes.bool.isRequired,
  // withTranslations
  i18n: PropTypes.func.isRequired,
  // withFormErrors
  validationMessages: validationMessagesPropType(
    Object.keys(FORM_VALUES_PROPTYPES),
  ),
  globalMessages: globalMessagesPropType,
  // withRouter
  location: PropTypes.object,
  // withFormik
  ...CoobisPropTypes.makeFormikPropTypes(FORM_VALUES_PROPTYPES),
}

export default withTranslations(Login)
