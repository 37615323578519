import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import CampaignDetailPanel from '../CampaignDetailPanel'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import PublicationsTableTwitter from '../tables/PublicationsTableTwitter'
import CampaignPreviewTwitter from '../social-previews/CampaignPreviewTwitter'
import { isNilOrEmpty } from '../../../app/utils'

const CampaignDetailTwitterAll = ({
  previews,
  publicationsTableData,
  i18n,
  // Metrics values
  tweets,
  profiles,
  impressions,
  engagements,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        <AnalyticsKpi
          iconType="megaphone"
          value={tweets}
          label={i18n('metrics:tweets')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="like"
          value={profiles}
          label={i18n('metrics:profiles')}
          isTruncated
        />
        {impressions > 0 && (
          <AnalyticsKpi
            iconType="target"
            value={impressions}
            label={i18n('metrics:impressions')}
            isTruncated
          />
        )}
        {engagements > 0 && (
          <AnalyticsKpi
            iconType="heart"
            value={engagements}
            label={i18n('metrics:engagements')}
            isTruncated
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewTwitter items={previews} />
      ) : null}
      <CampaignDetailPanel title={i18n('noun:publications-table')}>
        <PublicationsTableTwitter
          publications={publicationsTableData}
        ></PublicationsTableTwitter>
      </CampaignDetailPanel>
    </>
  )
}

CampaignDetailTwitterAll.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  publicationsTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      engagements: PropTypes.number,
      impressions: PropTypes.number,
      likes: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  tweets: PropTypes.number,
  profiles: PropTypes.number,
  impressions: PropTypes.number,
  engagements: PropTypes.number,
}

export default withTranslations(CampaignDetailTwitterAll)
