import envConfig from '../../environments'
import * as R from 'ramda'
import { moneyToFloat, toMomentOnlyDate } from '../../app/utils'
import {
  webResourcesGeneralDataSchema,
  webResourcesSocialNetworksSchema,
  webResourcesAnalyticsSchema,
  socialResourcesGeneralDataSchema,
} from './validation'
import { validateYupSchema } from 'formik'
import {
  WEB_RESOURCE_SECTIONS,
  RESOURCE_TYPES,
  SOCIAL_RESOURCE_SECTIONS,
} from './constants'

export const resourceAvatarURL = resourceId =>
  `${envConfig.usersBackendUrl}/media/resources/${resourceId}/screenshot`

export const parseResourcePrices = resource => {
  const pricesTransformations = {
    basePrice: moneyToFloat,
    broadcastBasePrice: moneyToFloat,
  }

  return R.evolve(
    {
      prices: R.pipe(
        R.map(x => {
          x.currency = R.pathOr('', ['currency'], x.basePrice)
          x.broadcastCurrency = R.pathOr('', ['currency'], x.broadcastBasePrice)
          return [x.serviceType, R.evolve(pricesTransformations, x)]
        }),
        R.fromPairs,
      ),
    },
    resource,
  )
}

export const isNewResource = resource =>
  +new Date() - +new Date(resource.createdAt) <=
  process.env.REACT_APP_SEARCH_NEW_DAYS * 24 * 60 * 60 * 1000

export const hasFollowersData = type => type !== RESOURCE_TYPES.WEB

// Map props to values
export const getWebResourcesGeneralData = ({ resourceData }) => ({
  name: R.propOr('', 'name', resourceData),
  url: R.propOr('', 'url', resourceData),
  countryId: R.propOr(null, 'countryId', resourceData),
  languageId: R.propOr(null, 'languageId', resourceData),
  categoryId: R.propOr(null, 'categoryId', resourceData),
  secondaryCategoryId: R.propOr(null, 'secondaryCategoryId', resourceData),
  description: R.propOr('', 'description', resourceData),
  acceptsFollowLinks: R.propOr(true, 'acceptsFollowLinks', resourceData),
  acceptsWritingArticles: R.propOr(
    true,
    'acceptsWritingArticles',
    resourceData,
  ),
  marksSponsoredContent: R.propOr(true, 'marksSponsoredContent', resourceData),
  holidaysBeginAt: R.pipe(
    R.pathOr(null, ['holidays', 'beginAt']),
    R.unless(R.isNil, toMomentOnlyDate),
  )(resourceData),
  holidaysEndAt: R.pipe(
    R.pathOr(null, ['holidays', 'endAt']),
    R.unless(R.isNil, toMomentOnlyDate),
  )(resourceData),
})

export const getSocialResourcesGeneralData = ({
  resourceData,
  isSelectingProfile,
}) => ({
  name: R.propOr('', 'name', resourceData),
  url: R.propOr('', 'url', resourceData),
  countryId: R.propOr(null, 'countryId', resourceData),
  languageId: R.propOr(null, 'languageId', resourceData),
  categoryId: R.propOr(null, 'categoryId', resourceData),
  secondaryCategoryId: R.propOr(null, 'secondaryCategoryId', resourceData),
  description: R.propOr('', 'description', resourceData),
  marksSponsoredContent: R.propOr(true, 'marksSponsoredContent', resourceData),
  holidaysBeginAt: R.pipe(
    R.pathOr(null, ['holidays', 'beginAt']),
    R.unless(R.isNil, toMomentOnlyDate),
  )(resourceData),
  holidaysEndAt: R.pipe(
    R.pathOr(null, ['holidays', 'endAt']),
    R.unless(R.isNil, toMomentOnlyDate),
  )(resourceData),
  profileId: isSelectingProfile
    ? ''
    : R.pathOr('', ['stats', 'profileId'], resourceData),
})

export const getWebResourcesSocialNetworks = ({ resourceData }) => ({
  facebook: R.pathOr('', ['socialNetworks', 'facebook'], resourceData),
  twitter: R.pathOr('', ['socialNetworks', 'twitter'], resourceData),
  instagram: R.pathOr('', ['socialNetworks', 'instagram'], resourceData),
})

export const getWebResourcesAnalyticsData = ({
  resourceData,
  isSelectingProfile,
}) => ({
  profileId: isSelectingProfile
    ? ''
    : R.pathOr('', ['stats', 'profileId'], resourceData),
  pageviews: R.pathOr('', ['stats', 'stats', 'pageviews'], resourceData),
  visits: R.pathOr('', ['stats', 'stats', 'visits'], resourceData),
  visitors: R.pathOr('', ['stats', 'stats', 'visitors'], resourceData),
})

// Web services breadcrumbs

const hasCompletedWebGeneralData = (
  resourceData,
  { countries, categories },
) => {
  const values = getWebResourcesGeneralData({ resourceData })
  const schema = webResourcesGeneralDataSchema({ countries, categories })

  try {
    validateYupSchema(values, schema, true)
    return true
  } catch (err) {
    return false
  }
}

const hasCompletedWebSocialNetworks = resourceData => {
  const values = getWebResourcesSocialNetworks({ resourceData })

  try {
    validateYupSchema(values, webResourcesSocialNetworksSchema, true)
    return true
  } catch (err) {
    return false
  }
}

const hasCompletedWebAnalyticsData = resourceData => {
  const values = getWebResourcesAnalyticsData({ resourceData })
  const schema = webResourcesAnalyticsSchema(resourceData)

  try {
    validateYupSchema(values, schema, true)
    return true
  } catch (err) {
    return false
  }
}

export const getReachedWebNavItem = ({
  resourceData,
  countries,
  categories,
}) => {
  if (!resourceData) return WEB_RESOURCE_SECTIONS.GENERAL_DATA

  if (!hasCompletedWebGeneralData(resourceData, { countries, categories })) {
    return WEB_RESOURCE_SECTIONS.GENERAL_DATA
  }

  if (!hasCompletedWebSocialNetworks(resourceData)) {
    return WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS
  }

  if (!hasCompletedWebAnalyticsData(resourceData)) {
    return WEB_RESOURCE_SECTIONS.ANALYTICS
  }

  return WEB_RESOURCE_SECTIONS.SERVICES
}

// Social services breadcrumbs

const hasCompletedSocialGeneralData = (
  resourceData,
  { countries, categories },
) => {
  const values = getSocialResourcesGeneralData({ resourceData })
  const schema = socialResourcesGeneralDataSchema({
    channel: resourceData.type,
    countries,
    categories,
  })

  try {
    validateYupSchema(values, schema, true)
    return true
  } catch (err) {
    return false
  }
}

export const getReachedSocialNavItem = ({
  resourceData,
  countries,
  categories,
}) => {
  if (!resourceData) return SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA

  if (!hasCompletedSocialGeneralData(resourceData, { countries, categories })) {
    return SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA
  }

  return SOCIAL_RESOURCE_SECTIONS.SERVICES
}
