import { compose, branch, withPropsOnChange } from 'recompose'
import * as Yup from 'yup'
import * as R from 'ramda'
import { connect } from 'react-redux'
import OrderModalReviewDeliveryCorrect from '../../ui/order-modals/OrderModalReviewDeliveryCorrect'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import {
  testRelativeDateMinHours,
  testRelativeDateMaxHours,
  i18nValidation,
} from '../common/validation'
import { withDatesConfig } from '../../services/checkout/hocs'
import withSpinner from '../../hocs/withSpinner'
import { FETCH_DATES_CONFIG } from '../../services/checkout/action-types'
import { momentToISO8601WithTimezone } from '../utils'
import { yupToFormErrors, validateYupSchema } from 'formik'
import { getYupHoursContext } from '../../services/checkout/validation'
import { getDeliveryDateInfoFromDateConfig } from '../../services/order-dates'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import { correctOrderDelivery } from '../../services/orders'
import { CORRECT_ORDER_DELIVERY } from '../../services/orders/action-types'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import enhanceModalSpinner from '../common/enhanceModalSpinner'

const DATE_FIELDS = ['deliveryDate', 'approveDate']

const validationSchema = ({ isExpress }) =>
  Yup.object().shape(
    R.filter(R.complement(R.isNil), {
      comments: Yup.string().required(i18nValidation.required),

      deliveryDate: !isExpress
        ? Yup.date()
            .nullable()
            .test(
              testRelativeDateMinHours(
                Yup.ref('$now'),
                undefined,
                Yup.ref('$deliveryMinHours'),
                i18nValidation.minRelativeToNow,
              ),
            )
            .test(
              testRelativeDateMaxHours(
                Yup.ref('$now'),
                undefined,
                Yup.ref('$deliveryMaxHours'),
                i18nValidation.maxRelativeToNow,
              ),
            )
        : undefined,

      approveDate: !isExpress
        ? Yup.date()
            .nullable()
            .test(
              testRelativeDateMinHours(
                Yup.ref('deliveryDate'),
                Yup.ref('$deliveryDateDefault'),
                Yup.ref('$approveMinHours'),
                i18nValidation.minRelativeToDeliveryDate,
              ),
            )
            .test(
              testRelativeDateMaxHours(
                Yup.ref('deliveryDate'),
                Yup.ref('$deliveryDateDefault'),
                Yup.ref('$approveMaxHours'),
                i18nValidation.maxRelativeToDeliveryDate,
              ),
            )
        : undefined,
    }),
  )

const enhance = compose(
  withDatesConfig,

  withSpinner(FETCH_DATES_CONFIG, {
    enhanceSpinner: enhanceModalSpinner('action:amend-delivery'),
  }),

  connect(
    null,
    {
      correctOrderDelivery,
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ isExpress }) =>
      isExpress
        ? { comments: '' }
        : {
            comments: '',
            deliveryDate: null,
            approveDate: null,
          },

    dateFields: DATE_FIELDS,

    validate: (values, { isExpress, datesConfig }) => {
      const dateInfo = getDeliveryDateInfoFromDateConfig(
        undefined,
        R.pick(DATE_FIELDS, values),
        datesConfig,
      )

      const context = !isExpress
        ? getYupHoursContext(dateInfo, datesConfig)
        : undefined

      try {
        validateYupSchema(
          values,
          validationSchema({ isExpress }),
          true,
          context,
        )
      } catch (err) {
        return yupToFormErrors(err)
      }

      return {}
    },

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const dateInfo = getDeliveryDateInfoFromDateConfig(
        undefined,
        R.pick(DATE_FIELDS, values),
        props.datesConfig,
      )

      const valuesToSubmit = R.pipe(
        // Add default dates for missing dates
        R.mergeLeft(
          R.mapObjIndexed(
            (value, field) => (!values[field] ? value.default : values[field]),
            dateInfo,
          ),
        ),

        // Send dates to backend including timezones
        R.evolve({
          deliveryDate: momentToISO8601WithTimezone,
          approveDate: momentToISO8601WithTimezone,
        }),
      )(values)

      props.correctOrderDelivery(props.orderId, valuesToSubmit)
    },
  }),

  withIsRequestPending(CORRECT_ORDER_DELIVERY),
  withIsRequestSucceeded(CORRECT_ORDER_DELIVERY),
  withRequestError(CORRECT_ORDER_DELIVERY),
  withDeleteRequestOnUnmount(CORRECT_ORDER_DELIVERY),

  branch(
    ({ isExpress }) => isExpress,
    withFormErrors(['comments']),
    withFormErrors(['comments', ...DATE_FIELDS]),
  ),

  withPropsOnChange(['values', 'datesConfig'], ({ values, datesConfig }) => ({
    dateInfo: getDeliveryDateInfoFromDateConfig(
      undefined,
      R.pick(DATE_FIELDS, values),
      datesConfig,
    ),
  })),
)

export default enhance(OrderModalReviewDeliveryCorrect)
