import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const InfoPiece = ({ inline, label, value }) => {
  const classname = cx('info-piece', {
    inline: inline,
  })
  return (
    <div className={classname}>
      <p className="label">{label}</p>
      <p className="value">{value}</p>
    </div>
  )
}

InfoPiece.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
}

export default InfoPiece
