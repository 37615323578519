import { SERVICE } from './constants'

export const FETCH_RESOURCES = `${SERVICE}/FETCH_RESOURCES`

export const FETCH_RESOURCE_BY_ID = `${SERVICE}/FETCH_RESOURCE_BY_ID`

export const REQUEST_SCREENSHOT = `${SERVICE}/REQUEST_SCREENSHOT`

export const UPLOAD_RESOURCE_IMAGE = `${SERVICE}/UPLOAD_RESOURCE_IMAGE`

export const CREATE_WEB_RESOURCE = `${SERVICE}/CREATE_WEB_RESOURCE`

export const EDIT_WEB_RESOURCE_GENERAL_DATA = `${SERVICE}/EDIT_WEB_RESOURCE_GENERAL_DATA`

export const EDIT_WEB_RESOURCE_SOCIAL_MEDIA = `${SERVICE}/EDIT_WEB_RESOURCE_SOCIAL_MEDIA`

export const EDIT_WEB_RESOURCE_ANALYTICS = `${SERVICE}/EDIT_WEB_RESOURCE_ANALYTICS`

export const EDIT_WEB_RESOURCE_SERVICES_PRICES = `${SERVICE}/EDIT_WEB_RESOURCE_SERVICES_PRICES`

export const CREATE_SOCIAL_RESOURCE = `${SERVICE}/CREATE_SOCIAL_RESOURCE`

export const EDIT_SOCIAL_RESOURCE_GENERAL_DATA = `${SERVICE}/EDIT_SOCIAL_RESOURCE_GENERAL_DATA`

export const EDIT_SOCIAL_RESOURCE_SERVICES_PRICES = `${SERVICE}/EDIT_SOCIAL_RESOURCE_SERVICES_PRICES`

export const DELETE_RESOURCE = `${SERVICE}/DELETE_RESOURCE`

export const REQUEST_CONNECT_SOCIAL = `${SERVICE}/REQUEST_CONNECT_SOCIAL`

export const FETCH_SOCIAL_PROFILES = `${SERVICE}/FETCH_SOCIAL_PROFILES`

export const FETCH_SOCIAL_DATA = `${SERVICE}/FETCH_SOCIAL_DATA`

export const FETCH_RESOURCE_DETAILS = `${SERVICE}/FETCH_RESOURCE_DETAILS`

export const UPDATE_RESOURCE_STATS = `${SERVICE}/UPDATE_RESOURCE_STATS`

export const UPDATE_RESOURCE_STATUS = `${SERVICE}/UPDATE_RESOURCE_STATUS`
