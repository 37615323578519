import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import OrderStatus from './OrderStatus'
import OrderType from './OrderType'
import {
  mapServiceTypeToOrderTypeI18n,
  mapServiceTypeToResourceType,
  mapServiceTypeToI18nWithoutWords,
  ORDER_STATUS,
} from '../../services/orders/business'
import withTranslations from '../../hocs/withTranslations'
import { SERVICE_TYPES } from '../../services/orders/business'

const OrderPendingRow = props => {
  const { status, type, campaign, resource, date, linkUrl, i18n } = props

  return (
    <Link to={linkUrl} className="order-row">
      <div className="order-row-item orders-pending-table-status">
        <OrderStatus status={status} />
      </div>
      <div
        className="order-row-item orders-pending-table-service"
        title={i18n(mapServiceTypeToI18nWithoutWords[type])}
      >
        <OrderType
          i18nKey={mapServiceTypeToOrderTypeI18n[type]}
          icon={mapServiceTypeToResourceType[type]}
        />
      </div>
      <div
        className="order-row-item orders-pending-table-campaign"
        title={campaign}
      >
        {campaign}
      </div>
      <div
        className="order-row-item orders-pending-table-media"
        title={resource}
      >
        {resource}
      </div>
      <div className="order-row-item orders-pending-table-date">
        <FormattedDate format="coobis" value={date} />
      </div>
    </Link>
  )
}

OrderPendingRow.propTypes = {
  linkUrl: PropTypes.string,
  status: PropTypes.oneOf(Object.values(ORDER_STATUS)),
  type: PropTypes.oneOf(Object.values(SERVICE_TYPES)),
  campaign: PropTypes.string,
  resource: PropTypes.string,
  date: PropTypes.instanceOf(Date),
}

export default withTranslations(OrderPendingRow)
