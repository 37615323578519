import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '@redradix/components.button'

import RegisterBrand from './RegisterBrand'
import RegisterMedia from './RegisterMedia'
import { refreshToken } from '../../services/auth'
import { REFRESH_TOKEN } from '../../services/auth/action-types'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withTranslations from '../../hocs/withTranslations'
import { ROUTE_ROOT } from '../common/routes'

const Debug = ({ handleRefreshToken, isRefreshPending, i18n }) => (
  <>
    <Link to={ROUTE_ROOT.linkTo()}>X</Link>

    <hr />

    <RegisterBrand />

    <hr />

    <RegisterMedia />

    <hr />

    <Button
      type="button"
      onClick={handleRefreshToken}
      isLoading={isRefreshPending}
    >
      {i18n('logout:action-refresh')}
    </Button>
  </>
)

const enhance = compose(
  withTranslations,

  connect(
    null,
    {
      handleRefreshToken: refreshToken,
    },
  ),

  withIsRequestPending(REFRESH_TOKEN, 'isRefreshPending'),
)

export default enhance(Debug)
