import React from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { deleteCampaign, renameCampaign } from '../../services/campaigns'
import {
  DELETE_CAMPAIGN,
  RENAME_CAMPAIGN,
} from '../../services/campaigns/action-types'
import withIsRequestWithIdPending from '../../hocs/withIsRequestWithIdPending'
import withRequestWithIdError from '../../hocs/withRequestWithIdError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import * as Yup from 'yup'
import { i18nValidation } from '../common/validation'
import {
  compose,
  withPropsOnChange,
  withStateHandlers,
  withHandlers,
  lifecycle,
  branch,
  renderNothing,
} from 'recompose'
import {
  unprettifyConstant,
  ROUTE_BRAND_CAMPAIGNS_BY_ID,
  prettifyConstant,
} from '../common/routes'
import DeleteCampaignModal from '../../ui/campaigns/modals/DeleteCampaignModal'
import Modal from '../common/Modal'
import RenameCampaignModal from '../../ui/campaigns/modals/RenameCampaignModal'
import withIsRequestWithIdSucceeded from '../../hocs/withIsRequestWithIdSucceeded'
import withCampaignSummary from './withCampaignSummary'
import withEffect from '../../hocs/withEffect'
import { STATISTICS_TABS } from '../../services/campaigns/business'
import withExportReportContainer from './withExportReportContainer'

const withCampaignModals = BaseComponent => ({
  onRenameCampaign,
  isRenameModalOpen,
  openRenameModal,
  closeRenameModal,
  isRenamingCampaign,
  renameCampaignGlobalErrors,
  renameCampaignValidationMessages,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,

  onDeleteCampaign,
  handleDeleteCampaign,
  isDeleteModalOpen,
  openDeleteModal,
  closeDeleteModal,
  isDeletingCampaign,
  deleteCampaignGlobalErrors,

  ...props
}) => (
  <>
    <BaseComponent
      {...props}
      onDeleteCampaign={openDeleteModal}
      onEditCampaign={openRenameModal}
    />

    {isRenameModalOpen && (
      <Modal onClose={closeRenameModal}>
        <RenameCampaignModal
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          touched={touched}
          validationMessages={renameCampaignValidationMessages}
          globalMessages={renameCampaignGlobalErrors}
          isRequestPending={isRenamingCampaign}
        ></RenameCampaignModal>
      </Modal>
    )}

    {isDeleteModalOpen && (
      <Modal onClose={closeDeleteModal}>
        <DeleteCampaignModal
          onSubmit={handleDeleteCampaign}
          onCancel={closeDeleteModal}
          globalMessages={deleteCampaignGlobalErrors}
          isDeletingCampaign={isDeletingCampaign}
        ></DeleteCampaignModal>
      </Modal>
    )}
  </>
)

const withCampaignDeletion = compose(
  connect(
    null,
    { deleteCampaign },
  ),

  withStateHandlers(
    {
      isDeleteModalOpen: false,
    },
    {
      openDeleteModal: _ => () => ({
        isDeleteModalOpen: true,
      }),
      closeDeleteModal: _ => () => ({
        isDeleteModalOpen: false,
      }),
    },
  ),

  withHandlers({
    handleDeleteCampaign: props => () => {
      props.deleteCampaign(props.campaignId)
    },
  }),

  withPropsOnChange(['summary'], ({ summary }) => ({
    isCampaignDeletable: summary.orders === 0,
  })),

  withIsRequestWithIdPending(DELETE_CAMPAIGN, {
    idProp: 'campaignId',
    outProp: 'isDeletingCampaign',
  }),
  withRequestWithIdError(DELETE_CAMPAIGN, {
    idProp: 'campaignId',
    outProp: 'deleteCampaignError',
  }),
  withFormErrors([], {
    serverErrorsProp: 'deleteCampaignError',
    globalMessagesProp: 'deleteCampaignGlobalErrors',
  }),
)

const withCampaignRename = compose(
  connect(
    null,
    { renameCampaign },
  ),

  withStateHandlers(
    {
      isRenameModalOpen: false,
    },
    {
      openRenameModal: _ => () => ({
        isRenameModalOpen: true,
      }),
      closeRenameModal: _ => () => ({
        isRenameModalOpen: false,
      }),
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: props => ({
      name: props.campaignName || '',
    }),

    validationSchema: Yup.object().shape({
      name: Yup.string().required(i18nValidation.required),
    }),

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.renameCampaign(props.campaignId, values.name)
    },
  }),

  withIsRequestWithIdPending(RENAME_CAMPAIGN, {
    idProp: 'campaignId',
    outProp: 'isRenamingCampaign',
  }),
  withIsRequestWithIdSucceeded(RENAME_CAMPAIGN, {
    idProp: 'campaignId',
    outProp: 'isCampaignRenamed',
  }),
  withRequestWithIdError(RENAME_CAMPAIGN, {
    idProp: 'campaignId',
    outProp: 'renameCampaignError',
  }),
  withFormErrors(['name'], {
    serverErrorsProp: 'renameCampaignError',
    globalMessagesProp: 'renameCampaignGlobalErrors',
    validationMessagesProp: 'renameCampaignValidationMessages',
  }),

  lifecycle({
    componentDidUpdate(prev) {
      const { isCampaignRenamed, closeRenameModal } = this.props

      if (prev.isCampaignRenamed === false && isCampaignRenamed === true) {
        closeRenameModal()
      }
    },
  }),
)

// Redirect to Global if current statistics is not available
const withRedirectIfNotAvailable = compose(
  withEffect(
    ({ summary, resourceType, campaignId, history }) => {
      if (
        resourceType &&
        resourceType !== STATISTICS_TABS.GLOBAL &&
        !(summary.publishedStatisticsType[prettifyConstant(resourceType)] > 0)
      ) {
        history.replace(
          ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
            campaignId,
            resourceType: prettifyConstant(STATISTICS_TABS.GLOBAL),
          }),
        )
      }
    },
    ({ summary, resourceType, history, campaignId }) => [
      summary,
      resourceType,
      history,
      campaignId,
    ],
  ),

  branch(
    ({ summary, resourceType }) =>
      resourceType &&
      resourceType !== STATISTICS_TABS.GLOBAL &&
      !(summary.publishedStatisticsType[prettifyConstant(resourceType)] > 0),
    renderNothing,
  ),
)

// Available navigation tabs
const withAvailableNavigation = withPropsOnChange(
  ['summary'],
  ({ summary }) => ({
    availableStats: R.pipe(
      R.values,
      R.filter(
        type => summary.publishedStatisticsType[prettifyConstant(type)] > 0,
      ),
    )(STATISTICS_TABS),
  }),
)

const enhance = compose(
  withPropsOnChange(['match'], ({ match }) => ({
    campaignId: Number(match.params.campaignId) || undefined,
    resourceType: match.params.resourceType
      ? unprettifyConstant(match.params.resourceType)
      : undefined,
    orderId: R.path(['params', 'orderId'], match),
  })),

  withEffect(
    ({ resourceType, campaignId, history }) => {
      if (!resourceType) {
        history.replace(
          ROUTE_BRAND_CAMPAIGNS_BY_ID.linkTo({
            campaignId,
            resourceType: prettifyConstant(STATISTICS_TABS.GLOBAL),
          }),
        )
      }
    },
    ({ resourceType, campaignId, history }) => [
      resourceType,
      campaignId,
      history,
    ],
  ),

  withCampaignSummary,
  withCampaignDeletion,
  withCampaignRename,
  withRedirectIfNotAvailable,
  withAvailableNavigation,
  withExportReportContainer,

  withCampaignModals,
)

export default enhance
