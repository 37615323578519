import { applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import sagas from './sagas'
import rootReducer from './root-reducer'

const MAX_INSPECTOR_ACTIONS = 100

export default (initialState = {}, history, onSagasError) => {
  // midleware

  const middleware = [sagas.middleware]

  // redux extension

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          maxAge: MAX_INSPECTOR_ACTIONS,
        })
      : compose

  const dataLayer = [{}]

  // create store

  const analytics = () => next => action => {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({
      virtualPageURL: window.location.href,
      virtualPageTitle: window.location.href,
    })
    return next(action)
  }

  const store = createStore(
    rootReducer(initialState, history),
    initialState,
    composeEnhancers(
      applyMiddleware(routerMiddleware(history), analytics, ...middleware),
    ),
  )

  store.asyncReducers = {}

  // sagas

  sagas.start(onSagasError)

  return store
}
