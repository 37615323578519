import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from '@redradix/components.button'

const IconButton = props => {
  const {
    className,
    altTitle,
    children,
    isDisabled,
    isLoading,
    type,
    hierarchy,
    variant,
    onClick,
  } = props

  return (
    <Button
      className={cx(`icon-button ${className}`)}
      type={type}
      hierarchy={hierarchy}
      variant={variant}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={onClick}
      title={altTitle}
    >
      {!isLoading && children}
    </Button>
  )
}

IconButton.propTypes = {
  className: PropTypes.string,
  altTitle: PropTypes.string,
  /** Content wrapped by the <Button> */
  children: PropTypes.node.isRequired,
  /** String that defines the type of the <Button> */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  /** String that defines the category of the <Button> */
  hierarchy: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /** Boolean indicating whether the <Button> should be rendered as disabled */
  isDisabled: PropTypes.bool,
  /** Boolean indicating whether the <Button> should be rendered as loading */
  isLoading: PropTypes.bool,
  /** String that defines the variant of the <Button> that influences the styles */
  variant: PropTypes.oneOf([
    'default',
    'alternate',
    'destruct',
    'success',
    'neutral',
  ]),
  /** Function that is triggered when the <Button> is clicked */
  onClick: PropTypes.func.isRequired,
}

export default IconButton
