import * as hash from 'reduken/hash'
import * as actionTypes from './action-types'
import { DOMAIN, HASH_KEY_LAST_TEMPORARY_IMAGE } from './constants'

export const fetchResources = (page, itemsPerPage) => ({
  type: actionTypes.FETCH_RESOURCES,
  payload: { page, itemsPerPage },
})

export const fetchResourceById = id => ({
  type: actionTypes.FETCH_RESOURCE_BY_ID,
  payload: { id },
})

export const requestScreenshot = url => ({
  type: actionTypes.REQUEST_SCREENSHOT,
  payload: { url },
})

export const uploadResourcePhoto = file => ({
  type: actionTypes.UPLOAD_RESOURCE_IMAGE,
  payload: { file },
})

export const clearTemporaryImage = () =>
  hash.remove(DOMAIN, HASH_KEY_LAST_TEMPORARY_IMAGE)

export const createWebResource = data => ({
  type: actionTypes.CREATE_WEB_RESOURCE,
  payload: { data },
})

export const editWebResourceGeneralData = (id, data) => ({
  type: actionTypes.EDIT_WEB_RESOURCE_GENERAL_DATA,
  payload: { id, data },
})

export const editWebResourceSocialMedia = (id, data) => ({
  type: actionTypes.EDIT_WEB_RESOURCE_SOCIAL_MEDIA,
  payload: { id, data },
})

export const editWebResourceAnalytics = (id, data) => ({
  type: actionTypes.EDIT_WEB_RESOURCE_ANALYTICS,
  payload: { id, data },
})

export const editWebResourceServicesPrices = (id, data) => ({
  type: actionTypes.EDIT_WEB_RESOURCE_SERVICES_PRICES,
  payload: { id, data },
})

export const createSocialResource = (socialNetwork, data) => ({
  type: actionTypes.CREATE_SOCIAL_RESOURCE,
  payload: { socialNetwork, data },
})

export const editSocialResourceGeneralData = (id, socialNetwork, data) => ({
  type: actionTypes.EDIT_SOCIAL_RESOURCE_GENERAL_DATA,
  payload: { id, socialNetwork, data },
})

export const editSocialResourceServicesPrices = (id, socialNetwork, data) => ({
  type: actionTypes.EDIT_SOCIAL_RESOURCE_SERVICES_PRICES,
  payload: { id, socialNetwork, data },
})

export const deleteResource = resourceId => ({
  type: actionTypes.DELETE_RESOURCE,
  payload: { id: resourceId },
})

export const requestConnectSocial = (resourceId, socialService) => ({
  type: actionTypes.REQUEST_CONNECT_SOCIAL,
  payload: { id: resourceId, socialService },
})

export const fetchSocialProfiles = (resourceId, socialService) => ({
  type: actionTypes.FETCH_SOCIAL_PROFILES,
  payload: { id: resourceId, socialService },
})

export const fetchSocialData = (resourceId, socialService) => ({
  type: actionTypes.FETCH_SOCIAL_DATA,
  payload: { id: resourceId, socialService },
})

export const fetchResourceDetails = id => ({
  type: actionTypes.FETCH_RESOURCE_DETAILS,
  payload: { id },
})

export const updateResourceStats = id => ({
  type: actionTypes.UPDATE_RESOURCE_STATS,
  payload: { id },
})

export const updateResourceStatus = (id, status) => ({
  type: actionTypes.UPDATE_RESOURCE_STATUS,
  payload: { id, status },
})
