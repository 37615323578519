import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@redradix/components.button'
import { ReactComponent as ErrorPhoto } from '../../styles/icons/errorphoto.svg'
import { ReactComponent as NoPhoto } from '../../styles/icons/nophoto.svg'
import cx from 'classnames'
import withTranslations from '../../hocs/withTranslations'
import WarningMessage from '../messages/WarningMessage'
import AnchorLinkButton from '../texts/AnchorLinkButton'

const ALLOWED_IMAGE_EXTENSIONS = '.png,.jpg,.jpeg,.img,.gif'

const preventDefault = e => {
  e.preventDefault()
  e.stopPropagation()
}

const handleDragEffect = e => {
  e.preventDefault()
  e.stopPropagation()

  e.dataTransfer.dropEffect = 'copy'
}

const ProfilePictureBlock = ({
  label,
  onUpload,
  onUploadDrop,
  photoURL,
  setLoadedURL,
  setErroredURL,
  photoErrored,
  errorText,
  onScreenshotClick,
  isCapturingScreenshot,
  isUploadingPhoto,
  isLoadingPhoto,
  isScreenshotCaptureDisabled,
  isDisabled,
  i18n,
}) => {
  const inputFileRef = useRef()

  const triggerFileInputClick = useCallback(
    () => inputFileRef.current.click(),
    [inputFileRef],
  )

  const handleDrop = useCallback(
    e => {
      preventDefault(e)
      onUploadDrop(e)
    },
    [onUploadDrop],
  )

  const hasPhoto = !!photoURL

  const className = cx('profile-picture-draggable-area', {
    'has-picture': hasPhoto,
  })

  const buttonText = hasPhoto
    ? i18n('resources:action-image-change')
    : i18n('resources:action-image-upload')

  return (
    <div
      className="profile-picture-draggable"
      onDragOver={handleDragEffect}
      onDragEnter={handleDragEffect}
      onDragLeave={preventDefault}
      onDrop={handleDrop}
    >
      {label && <p className="label">{label}</p>}
      <div className={className}>
        <input
          type="file"
          className="hidden-input-file"
          ref={inputFileRef}
          onChange={onUpload}
          accept={ALLOWED_IMAGE_EXTENSIONS}
          hidden
        />
        <div className="profile-picture-image">
          {!hasPhoto && <NoPhoto className="icon" />}

          {hasPhoto && photoErrored && <ErrorPhoto className="icon" />}

          {hasPhoto && !photoErrored && (
            <picture className="image" key={photoURL}>
              <source srcSet={photoURL} />
              <img
                src={photoURL}
                alt="Avatar"
                onLoad={() => setLoadedURL(photoURL)}
                onError={() => setErroredURL(photoURL)}
              />
            </picture>
          )}
        </div>
        <div className="profile-picture-actions">
          {!hasPhoto && (
            <div className="profile-picture-block">
              <p className="profile-picture-title">
                {i18n('profile:drag-image')}
              </p>
              <p className="profile-picture-subtitle">{i18n('profile:or')}</p>
              <Button
                type="button"
                hierarchy="tertiary"
                variant="default"
                widthBehaviour="adaptative"
                heightBehaviour="fixed"
                onClick={onScreenshotClick}
                isLoading={isCapturingScreenshot}
                isDisabled={
                  isDisabled ||
                  isScreenshotCaptureDisabled ||
                  isUploadingPhoto ||
                  isLoadingPhoto
                }
              >
                <p>{i18n('resources:action-image-screenshot')}</p>
              </Button>

              <Button
                type="button"
                hierarchy="tertiary"
                variant="default"
                widthBehaviour="adaptative"
                heightBehaviour="fixed"
                onClick={triggerFileInputClick}
                isLoading={isUploadingPhoto}
                isDisabled={
                  isDisabled || isCapturingScreenshot || isLoadingPhoto
                }
              >
                <p>{buttonText}</p>
              </Button>
              <p className="profile-picture-subtitle">
                {i18n('profile:image-restraints')}
              </p>
            </div>
          )}
        </div>
      </div>

      {hasPhoto && (
        <div className="profile-picture-outer-actions">
          <div className="profile-picture-outer-action">
            <AnchorLinkButton
              type="button"
              text={i18n('resources:action-image-screenshot')}
              onClick={onScreenshotClick}
              isLoading={isCapturingScreenshot}
              isDisabled={
                isDisabled ||
                isScreenshotCaptureDisabled ||
                isUploadingPhoto ||
                isLoadingPhoto
              }
            />
          </div>
          <div className="profile-picture-outer-action">
            <AnchorLinkButton
              type="button"
              text={buttonText}
              onClick={triggerFileInputClick}
              isLoading={isUploadingPhoto}
              isDisabled={isDisabled || isCapturingScreenshot || isLoadingPhoto}
            />
          </div>
        </div>
      )}

      {errorText && <WarningMessage text={<>errorText</>} />}
    </div>
  )
}

ProfilePictureBlock.propTypes = {
  label: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onUploadDrop: PropTypes.func,
  photoURL: PropTypes.string,
  setLoadedURL: PropTypes.func,
  setErroredURL: PropTypes.func,
  photoErrored: PropTypes.bool,
  errorText: PropTypes.string,
  onScreenshotClick: PropTypes.func,
  isCapturingScreenshot: PropTypes.bool,
  isUploadingPhoto: PropTypes.bool,
  isLoadingPhoto: PropTypes.bool,
  isScreenshotCaptureDisabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default withTranslations(ProfilePictureBlock)
