import { remove } from 'reduken/hash'
import * as actionTypes from './action-types'
import { DOMAIN, HASH_KEY_REQUEST_STATUS } from './constants'

export const startRequest = requestType => ({
  type: actionTypes.START,
  payload: { requestType },
})

export const endRequestSuccess = requestType => ({
  type: actionTypes.SUCCESS,
  payload: { requestType },
})

export const endRequestError = (requestType, error) => ({
  type: actionTypes.ERROR,
  payload: { requestType, error },
})

export const deleteRequest = requestType =>
  remove(DOMAIN, `${HASH_KEY_REQUEST_STATUS}_${requestType}`)

// With id

export const startRequestWithId = (requestType, id) => ({
  type: actionTypes.START,
  payload: { requestType: `${requestType}_${id}` },
})

export const endRequestWithIdSuccess = (requestType, id) => ({
  type: actionTypes.SUCCESS,
  payload: { requestType: `${requestType}_${id}` },
})

export const endRequestWithIdError = (requestType, id, error) => ({
  type: actionTypes.ERROR,
  payload: { requestType: `${requestType}_${id}`, error },
})

export const deleteRequestWithId = (requestType, id) =>
  deleteRequest(`${requestType}_${id}`)
