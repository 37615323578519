import React, { useState, useMemo } from 'react'
import {
  compose,
  withPropsOnChange,
  withHandlers,
  lifecycle,
  branch,
} from 'recompose'
import * as R from 'ramda'
import Checkout from '../../ui/checkout/Checkout'
import withTranslations from '../../hocs/withTranslations'
import withTotalCartPrice from './withTotalCartPrice'
import { withCart, withReachedCheckoutStep } from '../../services/checkout/hocs'
import VerticalTabs from '../../ui/tabs/VerticalTabs'
import TabItem from '../../ui/tabs/atoms/TabItem'
import BriefingWebOnlyPostForm from './BriefingWebOnlyPostForm'
import BriefingWebForm from './BriefingWebForm'
import BriefingFacebookForm from './BriefingFacebookForm'
import BriefingTwitterForm from './BriefingTwitterForm'
import BriefingInstagramPhotoForm from './BriefingInstagramPhotoForm'
import BriefingInstagramVideoForm from './BriefingInstagramVideoForm'
import BriefingInstagramStoryForm from './BriefingInstagramStoryForm'
import BriefingYoutubeMentionForm from './BriefingYoutubeMentionForm'
import BriefingYoutubeDedicatedForm from './BriefingYoutubeDedicatedForm'
import BriefingTwitchForm from './BriefingTwitchForm'
import BriefingTiktokShortForm from './BriefingTiktokShortForm'
import BriefingTiktokLongForm from './BriefingTiktokLongForm'
import {
  mapServiceTypeToResourceType,
  SERVICE_TYPES,
} from '../../services/orders/business'
import { mapResourceTypeToI18N } from '../../services/resources/constants'
import { BRIEFINGS_ORDER } from '../../services/checkout/business'
import {
  ROUTE_BRAND_CHECKOUT_BRIEFING,
  prettifyConstant,
  ROUTE_BRAND_CHECKOUT_GENERAL_DATA,
} from '../common/routes'
import {
  STEPPER_NAV_ITEMS,
  getPendingBriefings,
  getFirstBriefing,
} from '../../services/checkout/business'
import withSpinner from '../../hocs/withSpinner'
import { FETCH_CART } from '../../services/checkout/action-types'

const mapServiceTypeToSubtitle = {
  [SERVICE_TYPES.WEB_ONLY_POST]: 'service-subtitle:web-only-post',
  [SERVICE_TYPES.WEB_POST]: 'service-subtitle:web-post',
  [SERVICE_TYPES.FACEBOOK]: 'service-subtitle:facebook-post',
  [SERVICE_TYPES.TWITTER]: 'service-subtitle:twitter-tweet',
  [SERVICE_TYPES.INSTAGRAM_PHOTO]: 'service-subtitle:instagram-photo',
  [SERVICE_TYPES.INSTAGRAM_VIDEO]: 'service-subtitle:instagram-video',
  [SERVICE_TYPES.INSTAGRAM_STORY]: 'service-subtitle:instagram-story',
  [SERVICE_TYPES.YOUTUBE_MENTION]: 'service-subtitle:youtube-mention',
  [SERVICE_TYPES.YOUTUBE_DEDICATED]: 'service-subtitle:youtube-dedicated-video',
  [SERVICE_TYPES.TWITCH]: 'service-subtitle:twitch',
  [SERVICE_TYPES.TIKTOK_SHORT]: 'service-subtitle:tiktok-short',
  [SERVICE_TYPES.TIKTOK_LONG]: 'service-subtitle:tiktok-long',
}

const orderTypeToTabController = i18n => orderType => {
  const resourceType = mapServiceTypeToResourceType[orderType]

  return {
    name: (
      <TabItem
        title={i18n(mapResourceTypeToI18N[resourceType])}
        subtitle={i18n(mapServiceTypeToSubtitle[orderType])}
        iconType={resourceType}
      />
    ),
  }
}

const mapOrderTypeToBriefingPanel = {
  [SERVICE_TYPES.WEB_ONLY_POST]: BriefingWebOnlyPostForm,
  [SERVICE_TYPES.WEB_POST]: BriefingWebForm,
  [SERVICE_TYPES.FACEBOOK]: BriefingFacebookForm,
  [SERVICE_TYPES.TWITTER]: BriefingTwitterForm,
  [SERVICE_TYPES.INSTAGRAM_PHOTO]: BriefingInstagramPhotoForm,
  [SERVICE_TYPES.INSTAGRAM_VIDEO]: BriefingInstagramVideoForm,
  [SERVICE_TYPES.INSTAGRAM_STORY]: BriefingInstagramStoryForm,
  [SERVICE_TYPES.YOUTUBE_MENTION]: BriefingYoutubeMentionForm,
  [SERVICE_TYPES.YOUTUBE_DEDICATED]: BriefingYoutubeDedicatedForm,
  [SERVICE_TYPES.TWITCH]: BriefingTwitchForm,
  [SERVICE_TYPES.TIKTOK_SHORT]: BriefingTiktokShortForm,
  [SERVICE_TYPES.TIKTOK_LONG]: BriefingTiktokLongForm,
}

// Preserve form values if tab changes
const useValuesCache = orderTypes => {
  const [valuesCache, setValuesCache] = useState({})

  const valuesCacheHandlers = useMemo(() => {
    const handlers = {}

    for (const type of orderTypes) {
      handlers[type] = values => {
        setValuesCache(cache => ({ ...cache, [type]: values }))
      }
    }

    return handlers
  }, [orderTypes])

  return {
    valuesCache,
    valuesCacheHandlers,
  }
}

const CheckoutBriefing = ({
  orderTypes,
  totalCartPrice,
  i18n,
  selectedIndex,
  handleTabChange,
  reachedNavItem,
}) => {
  const { valuesCache, valuesCacheHandlers } = useValuesCache(orderTypes)

  return (
    <Checkout
      title={i18n('sections:briefing-long')}
      price={totalCartPrice}
      currentNavItem={STEPPER_NAV_ITEMS.BRIEFINGS}
      reachedNavItem={reachedNavItem}
      hasContentTabs={true}
    >
      <VerticalTabs
        controllers={orderTypes.map(orderTypeToTabController(i18n))}
        selectedIndex={selectedIndex}
        onChange={handleTabChange}
      >
        {orderTypes.map(orderType => {
          const BriefingPanel = mapOrderTypeToBriefingPanel[orderType]

          return (
            <BriefingPanel
              key={orderType}
              cachedValues={valuesCache[orderType]}
              onValuesChange={valuesCacheHandlers[orderType]}
            />
          )
        })}
      </VerticalTabs>
    </Checkout>
  )
}

const withURLControlledTabs = compose(
  withPropsOnChange(['match', 'orderTypes'], ({ match, orderTypes }) => {
    const urlServiceType = R.path(['params', 'serviceType'], match)
    const tabIndex = orderTypes.map(prettifyConstant).indexOf(urlServiceType)
    return { selectedIndex: tabIndex > 0 ? tabIndex : 0 }
  }),

  withHandlers({
    handleTabChange: ({ history, orderTypes }) => tabIndex =>
      history.replace(
        ROUTE_BRAND_CHECKOUT_BRIEFING.linkTo({
          serviceType: prettifyConstant(orderTypes[tabIndex]),
        }),
      ),
  }),
)

const withRedirectToFirstPendingBriefing = lifecycle({
  componentDidMount() {
    const { match, history, cart, orderTypes } = this.props
    const urlServiceType = R.path(['params', 'serviceType'], match)

    if (
      !urlServiceType ||
      !orderTypes.map(prettifyConstant).includes(urlServiceType)
    ) {
      const firstPendingBriefing =
        getPendingBriefings(cart)[0] || getFirstBriefing(cart)

      if (!firstPendingBriefing) {
        history.replace(ROUTE_BRAND_CHECKOUT_GENERAL_DATA.linkTo())
      } else {
        history.replace(
          ROUTE_BRAND_CHECKOUT_BRIEFING.linkTo({
            serviceType: prettifyConstant(firstPendingBriefing),
          }),
        )
      }
    }
  },
})

const enhance = compose(
  withTranslations,
  withCart,
  // Do not show spinner in cart periodic fetches
  branch(
    ({ cart }) => !cart,
    withSpinner(FETCH_CART, { i18nKey: 'status:loading-cart' }),
  ),
  withReachedCheckoutStep(STEPPER_NAV_ITEMS.BRIEFINGS),
  withTotalCartPrice,

  // Take list of needed briefings from cart
  withPropsOnChange(['cart'], ({ cart }) => {
    const servicesInCart = R.pipe(
      R.propOr([], 'items'),
      R.pluck('service'),
    )(cart)

    const orderedServices = BRIEFINGS_ORDER.filter(type =>
      servicesInCart.includes(type),
    )

    return {
      orderTypes: orderedServices,
    }
  }),

  withURLControlledTabs,
  withRedirectToFirstPendingBriefing,
)

export default enhance(CheckoutBriefing)
