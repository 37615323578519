import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Panel = ({ dark, children }) => {
  const classname = cx('panel', {
    dark: dark,
  })
  return <div className={classname}>{children}</div>
}

Panel.propTypes = {
  dark: PropTypes.bool,
  children: PropTypes.node,
}

export default Panel
