import React from 'react'
import PropTypes from 'prop-types'
import InputFile from './InputFile'
import cx from 'classnames'

const InputFileWithSubtitle = ({
  autoFocus,
  errorText,
  id,
  inputLabel,
  inputSubtitle,
  isTouched,
  isRequired,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  widthBehaviour,
  value,
  fileName,
  linkText,
  linkUrl,
  isUploaded,
  accept,
  ...props
}) => {
  const classes = cx(`input-wrapper`, {
    'is-required': isRequired,
  })
  return (
    <div className={classes}>
      <div className="input-header">
        <label className="input-label">{inputLabel}</label>
        {inputSubtitle && <p className="input-subtitle">{inputSubtitle}</p>}
      </div>
      <InputFile
        {...props}
        autoFocus={autoFocus}
        errorText={errorText}
        fileName={fileName}
        linkText={linkText}
        linkUrl={linkUrl}
        id={id}
        isTouched={isTouched}
        isUploaded={isUploaded}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        value={value}
        widthBehaviour={widthBehaviour}
        accept={accept}
      />
    </div>
  )
}

InputFileWithSubtitle.propTypes = {
  linkText: PropTypes.string,
  /** String that defines the error text of the <input> */
  errorText: PropTypes.node,
  /** String that defines the file title loaded */
  fileName: PropTypes.string,
  /** String that specifies the helper text of the <input> */
  id: PropTypes.string.isRequired,
  /** String that specifies the name of an <input> */
  name: PropTypes.string.isRequired,
  /** Function that is triggered when the <input> field loses focus */
  onBlur: PropTypes.func,
  /** Function that is triggered when the <input> is changed */
  onChange: PropTypes.func,
  /** String that describes the expected value of the <input> */
  placeholder: PropTypes.string,
  /** String or number that specifies the value of the <input> */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** String that defines the behaviour of the input width */
  widthBehaviour: PropTypes.oneOf(['fixed', 'full']),
  /** Boolean that defines whether the <input> is disabled */
  isTouched: PropTypes.bool,
  /** String with the text of the <label> */
  inputLabel: PropTypes.string.isRequired,
  /** String with the text of the subtitle */
  inputSubtitle: PropTypes.string,
  /** String that defined the accepted files in input file */
  accept: PropTypes.string,
  isUploaded: PropTypes.bool,
}

export default InputFileWithSubtitle
