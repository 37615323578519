import { compose, withProps, withPropsOnChange } from 'recompose'
import CampaignDetailWebSeo from '../../ui/campaigns/views/CampaignDetailWebSeo'
import withTimeSeriesGraphStaticLegend from './withTimeSeriesGraphStaticLegend'
import withExportReportContents from './withExportReportContents'

const withKPIs = withPropsOnChange(['stats'], ({ stats }) => ({
  webPublications: stats.kpis.totalOrders,
  domainAuthority: stats.kpis.domainAuthority,
  pageAuthority: stats.kpis.pageAuthority,
}))

const withDAPAGraph = withTimeSeriesGraphStaticLegend(
  stats => [
    {
      id: 'DA',
      labelKey: 'metrics:da-legend',
      items: stats.series.domainAuthority,
    },
    {
      id: 'PA',
      labelKey: 'metrics:pa-legend',
      items: stats.series.pageAuthority,
    },
  ],
  (series, { i18n }) => ({
    id: series.id,
    name: i18n(series.labelKey),
    items: series.items,
  }),
  {
    dataOutProp: 'authorityEvolutionData',
    dependentPropNames: ['i18n'],
  },
)

const withExternalLinksGraph = withTimeSeriesGraphStaticLegend(
  stats => [stats.series.externalLinks],
  (series, { i18n }) => ({
    id: 'External links',
    name: i18n('metrics:external-links-legend'),
    items: series,
  }),
  {
    dataOutProp: 'linksEvolutionData',
    dependentPropNames: ['i18n'],
  },
)

const enhance = compose(
  withProps({
    linksEvolutionData: [],
  }),

  withKPIs,
  withDAPAGraph,
  withExternalLinksGraph,
  withExportReportContents(),
)

export default enhance(CampaignDetailWebSeo)
