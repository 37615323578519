import React from 'react'
import ResourceDetailWeb from '../../ui/resources/resource-detail/ResourceDetailWeb'
import * as R from 'ramda'
import { compose, withProps, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchResourceDetails,
  getResourceDetails,
} from '../../services/resources/'
import {
  resourceAvatarURL,
  isNewResource,
} from '../../services/resources/business'
import {
  withTranslatedCountries,
  withCategories,
} from '../../services/config/hocs'
import withTranslations from '../../hocs/withTranslations'
import { getLanguage } from '../../services/me'
import ClusteredBarsChart from '../../ui/charts/ClusteredBarChart'
import PieChart from '../../ui/charts/PieChart'
import MapChart from '../../ui/charts/MapChart'
import withIsRequestWithIdSucceeded from '../../hocs/withIsRequestWithIdSucceeded'
import { FETCH_RESOURCE_DETAILS } from '../../services/resources/action-types'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'
import withEffect from '../../hocs/withEffect'
import withCountryWidget from '../common/withCountryWidget'
import withCityWidget from '../common/withCityWidget'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import {
  prettifyConstant,
  ROUTE_BRAND_RESOURCES_SEARCH,
} from '../common/routes'
import { SERVICE_TYPES } from '../../services/orders/business'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import {
  getGenderAudienceData,
  getCountryDensityDataForResource,
  getAgeAudienceData,
  containsNotNullValues,
} from '../common/statistics-utils'
import withFavoriteHandlers from '../common/withFavoriteHandlers'
import withAddToCart from '../common/withAddToCart'
import { withFetchFavorites } from '../../services/favorites/hocs'
import { FETCH_MY_FAVORITES } from '../../services/favorites/action-types'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import PropTypes from 'prop-types'
import {
  getResourceStatus,
  updateResourceStatus,
} from '../../services/resources'
import { getBrandCurrency } from '../../services/brand'

const ResourceDetail = props => {
  return (
    <ResourceDetailWeb
      {...props}
      item={props.item}
      ageChartSlot={
        props.ageAudienceData ? (
          <ClusteredBarsChart data={props.ageAudienceData} />
        ) : (
          props.ageAudienceData
        )
      }
      genderChartSlot={
        props.genderAudienceData ? (
          <PieChart data={props.genderAudienceData} />
        ) : (
          props.genderAudienceData
        )
      }
      countryChartSlot={
        props.countryDensityData ? (
          <MapChart
            data={props.countryDensityData}
            hasGaugeWidget
            gaugeWidgetData={props.countryWidgetData}
            isPrevPaginationDisabled={props.isPrevCountryDisabled}
            isNextPaginationDisabled={props.isNextCountryDisabled}
            onPrevPaginationClick={props.onPrevCountryHandler}
            onNextPaginationClick={props.onNextCountryHandler}
            totalCountries={props.countryWidgetTotal}
            hasCityWidget
            cityWidgetData={props.cityWidgetData}
            isPrevCityDisabled={props.isPrevCityDisabled}
            isNextCityDisabled={props.isNextCityDisabled}
            onPrevCityHandler={props.onPrevCityHandler}
            onNextCityHandler={props.onNextCityHandler}
            totalCities={props.cityWidgetTotal}
          />
        ) : (
          props.countryDensityData
        )
      }
    ></ResourceDetailWeb>
  )
}

const SERVICE_I18N_KEY = {
  [SERVICE_TYPES.WEB_ONLY_POST]: 'resources-detail:web-only-post-description',
  [SERVICE_TYPES.WEB_POST]: 'resources-detail:web-post-description',
  [SERVICE_TYPES.FACEBOOK]: 'resources-detail:facebook-description',
  [SERVICE_TYPES.TWITTER]: 'resources-detail:twitter-description',
  [SERVICE_TYPES.INSTAGRAM_PHOTO]:
    'resources-detail:instagram-photo-description',
  [SERVICE_TYPES.INSTAGRAM_STORY]:
    'resources-detail:instagram-story-description',
  [SERVICE_TYPES.INSTAGRAM_VIDEO]:
    'resources-detail:instagram-video-description',
  [SERVICE_TYPES.YOUTUBE_MENTION]:
    'resources-detail:youtube-mention-description',
  [SERVICE_TYPES.YOUTUBE_DEDICATED]:
    'resources-detail:youtube-dedicated-description',
  [SERVICE_TYPES.TWITCH]: [
    'resources-detail:twitch-description-1',
    'resources-detail:twitch-description-2',
    'resources-detail:twitch-description-3',
  ],
  [SERVICE_TYPES.TIKTOK_SHORT]: 'resources-detail:tiktok-short-description',
  [SERVICE_TYPES.TIKTOK_LONG]: 'resources-detail:tiktok-long-description',
}

const RESOURCE_TYPE_TO_URL_PROP = {
  [RESOURCE_TYPES.WEB]: 'webUrl',
  [RESOURCE_TYPES.FACEBOOK]: 'facebookUrl',
  [RESOURCE_TYPES.TWITTER]: 'twitterUrl',
  [RESOURCE_TYPES.INSTAGRAM]: 'instagramUrl',
  [RESOURCE_TYPES.YOUTUBE]: 'youtubeUrl',
  [RESOURCE_TYPES.TWITCH]: 'twitchUrl',
  [RESOURCE_TYPES.TIKTOK]: 'tiktokUrl',
}

ResourceDetail.propTypes = {
  item: PropTypes.object,
}

const enhance = compose(
  withRouter,
  withTranslations,
  withTranslatedCountries(),
  withCategories,

  withProps(props => ({
    resourceId: R.path(['match', 'params', 'id'], props),
  })),

  withHandlers({
    backLinkAction: props => _ => {
      props.history.push({
        pathname: ROUTE_BRAND_RESOURCES_SEARCH.linkTo(),
        search: window.location.search,
      })
    },
  }),

  connect(
    (state, props) => {
      const {
        resourceId,
        translatedCountries,
        categories,
        i18n,
        i18nNumber,
      } = props
      const resourceData = getResourceDetails(resourceId, state)

      // Charts Data
      const { male, female, ...demographicsData } = R.pathOr(
        {},
        ['statistics', 'demographics'],
        resourceData,
      )

      const countryDemographicsData = R.pathOr(
        [],
        ['statistics', 'countryDemographics'],
        resourceData,
      )

      const language = getLanguage(state)

      const currency = getBrandCurrency(state)

      const resourceStatus = getResourceStatus(resourceId, state)

      return resourceData
        ? {
            resourceData,
            item: resourceData,
            type: resourceData.type,
            name: resourceData.name,
            image: resourceAvatarURL(resourceId),
            isNew: isNewResource(resourceData),
            location: translatedCountries.find(
              country => country.id === resourceData.country,
            ).label,
            category: R.pipe(
              R.map(R.prop('id')),
              R.map(categoryId =>
                R.pathOr(
                  categoryId,
                  [categoryId, 'translations', language],
                  categories,
                ),
              ),
              R.join(' - '),
            )(resourceData.categories),
            rating: resourceData.rating,
            valuations: resourceData.totalRating,
            interactions: R.path(
              ['statistics', 'engagements', 'engagements'],
              resourceData,
            ),
            followers: resourceData.meta_followers,
            engagement: R.path(
              ['statistics', 'engagements', 'rate'],
              resourceData,
            ),
            acceptance: resourceData.acceptanceRate,
            description: resourceData.description,
            facebookUrl: R.path(['socialNetworks', 'facebook'], resourceData),
            twitterUrl: R.path(['socialNetworks', 'twitter'], resourceData),
            instagramUrl: R.path(['socialNetworks', 'instagram'], resourceData),

            // Map resource.url to expected view prop
            [RESOURCE_TYPE_TO_URL_PROP[resourceData.type]]: resourceData.url,

            facebookFollowers: R.path(
              ['statistics', 'socialNetworks', 'facebook'],
              resourceData,
            ),
            twitterFollowers: R.path(
              ['statistics', 'socialNetworks', 'twitter'],
              resourceData,
            ),
            instagramFollowers: R.path(
              ['statistics', 'socialNetworks', 'instagram'],
              resourceData,
            ),
            visits: R.path(
              ['statistics', 'googleAnalytics', 'visitorsPerMonth'],
              resourceData,
            ),
            users: R.path(
              ['statistics', 'googleAnalytics', 'uniqueVisitorsPerMonth'],
              resourceData,
            ),
            pageViews: R.path(
              ['statistics', 'googleAnalytics', 'pageViewsPerMonth'],
              resourceData,
            ),
            DAmozScore: R.path(
              ['statistics', 'seoMOZ', 'domainAuthority'],
              resourceData,
            ),
            PAmozScore: R.path(
              ['statistics', 'seoMOZ', 'pageAuthority'],
              resourceData,
            ),
            services: resourceData.prices.map(item => {
              const serviceType = prettifyConstant(item.service)
              const descriptionText = SERVICE_I18N_KEY[item.service]

              return {
                service: item.service,
                title: i18n(`resources-detail:${serviceType}-title`),
                finalPrice: item.finalPrice,
                price: item.price,
                description: Array.isArray(descriptionText) ? (
                  <ul>
                    {descriptionText.map(key => (
                      <li key={key}>{i18n(key, item)}</li>
                    ))}
                  </ul>
                ) : (
                  i18n(descriptionText, item)
                ),
              }
            }),
            currency: currency,
            // Charts
            ageAudienceData: containsNotNullValues(demographicsData)
              ? getAgeAudienceData(demographicsData)
              : undefined,
            genderAudienceData: containsNotNullValues({ male, female })
              ? getGenderAudienceData(i18n, i18nNumber, {
                  male,
                  female,
                })
              : undefined,
            countryDensityData: countryDemographicsData.some(i => i.density > 0)
              ? getCountryDensityDataForResource(
                  translatedCountries,
                  countryDemographicsData,
                )
              : undefined,
            isUserVerified: resourceData.isVerified,

            resourceStatus: resourceStatus,
          }
        : {}
    },
    { fetchResourceDetails, updateResourceStatus },
  ),

  withEffect(
    ({ fetchResourceDetails, resourceId }) => {
      fetchResourceDetails(resourceId)
    },
    ({ fetchResourceDetails }) => [fetchResourceDetails],
  ),

  withIsRequestWithIdSucceeded(FETCH_RESOURCE_DETAILS, {
    idProp: 'resourceId',
    outProp: 'isResourceDetailsLoaded',
  }),
  withRoute404ErrorWithId(FETCH_RESOURCE_DETAILS, 'resourceId'),
  withRouteServerErrorWithId(FETCH_RESOURCE_DETAILS, 'resourceId'),

  // Favorites
  withFetchFavorites,
  withIsRequestSucceeded(FETCH_MY_FAVORITES, 'isFavoritesLoaded'),
  withFavoriteHandlers({
    resourceProp: 'resourceData',
  }),
  withAddToCart,
  withPropsOnChange(
    ['isResourceDetailsLoaded', 'isFavoritesLoaded'],
    ({ isResourceDetailsLoaded, isFavoritesLoaded }) => ({
      isResourceLoaded: isResourceDetailsLoaded && isFavoritesLoaded,
    }),
  ),
  withCountryWidget({
    dataPath: ['resourceData', 'statistics', 'countryDemographics'],
  }),
  withCityWidget({
    dataPath: ['resourceData', 'statistics', 'cityDemographics'],
  }),
)

export default enhance(ResourceDetail)
