import { compose, withPropsOnChange } from 'recompose'
import CampaignDetailInstagramPublication from '../../ui/campaigns/views/CampaignDetailInstagramPublication'
import withExportReportContents from './withExportReportContents'

const enhance = compose(
  withPropsOnChange(['stats'], ({ stats }) => ({
    engagementRate: stats.kpis.engagementRate,
    engagements: stats.kpis.engagements,
    likes: stats.kpis.likes,
    comments: stats.kpis.comments,
    reach: stats.kpis.reach,
    views: stats.kpis.views,
    saved: stats.kpis.saved,
  })),
  withExportReportContents(),
)

export default enhance(CampaignDetailInstagramPublication)
