import { getFromPath } from 'reduken/hash'
import {
  DOMAIN,
  HASH_KEY_DASHBOARD_CAMPAIGNS,
  HASH_KEY_DASHBOARD_ORDERS,
  HASH_KEY_FEATURED_RESOURCES,
} from './constants'
import { createSelector } from 'reselect'
import { isNilOrEmpty } from '../../app/utils'

export const getDashboardCampaigns = getFromPath(
  DOMAIN,
  HASH_KEY_DASHBOARD_CAMPAIGNS,
)

export const getDashboardOrders = getFromPath(DOMAIN, HASH_KEY_DASHBOARD_ORDERS)

export const getFeaturedResources = createSelector(
  getFromPath(DOMAIN, HASH_KEY_FEATURED_RESOURCES),
  featuredResources =>
    !isNilOrEmpty(featuredResources) ? featuredResources : [],
)
