import React from 'react'
import PropTypes from 'prop-types'

const PublicationsHeadRow = props => {
  const { children } = props
  return <div className="publications-head-row">{children}</div>
}

PublicationsHeadRow.propTypes = {
  children: PropTypes.node,
}

export default PublicationsHeadRow
