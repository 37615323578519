import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as BagIcon } from '../../styles/icons/bag.svg'

const CartIndicator = ({ onClick, cartItems }) => {
  return (
    <button className="cart-indicator" onClick={onClick}>
      <BagIcon className="cart-indicator-icon" />
      {cartItems > 0 ? (
        <span className="cart-indicator-number">{cartItems}</span>
      ) : null}
    </button>
  )
}

CartIndicator.propTypes = {
  onClick: PropTypes.func,
  cartItems: PropTypes.number,
}

export default CartIndicator
