import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import IconIndicator from '../indicators/IconIndicator'
import { FormattedMessage } from 'react-intl'

const HelpMessage = ({ iconType, phone, formattedPhone, i18n }) => {
  const type = iconType || 'help'
  return (
    <div className="help-message">
      <IconIndicator type={type} />
      <div className="help-message-text">
        <p className="title">
          <FormattedMessage
            id="message:help-title"
            defaultMessage="message:help-title"
            values={{
              phone: (
                <a href={`tel:${phone}`} className="phone">
                  {formattedPhone}
                </a>
              ),
            }}
          />
        </p>
        <p className="schedule">{i18n('message:help-schedule')}</p>
      </div>
    </div>
  )
}

HelpMessage.propTypes = {
  iconType: PropTypes.string,
  phone: PropTypes.string,
  formattedPhone: PropTypes.string,
}

export default withTranslations(HelpMessage)
