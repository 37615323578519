import createSagaMiddleware from 'redux-saga'
import { all, fork, take, cancel } from 'redux-saga/effects'
import authSagas from '../services/auth/sagas'
import passwordRecoverySagas from '../services/password-recovery/sagas'
import meSagas from '../services/me/sagas'
import brandSagas from '../services/brand/sagas'
import mediaSagas from '../services/media/sagas'
import communicationSagas from '../services/communication/sagas'
import toastSagas from '../services/toasts/sagas'
import alertsSagas from '../services/alerts/sagas'
import walletSagas from '../services/wallet/sagas'
import dashboardSagas from '../services/dashboard/sagas'
import configSagas from '../services/config/sagas'
import navigationSagas from '../services/navigation/sagas'
import photoSagas from '../services/photo/sagas'
import ordersSagas from '../services/orders/sagas'
import checkoutSagas from '../services/checkout/sagas'
import campaignsSagas from '../services/campaigns/sagas'
import resourcesSagas from '../services/resources/sagas'
import favoritesSagas from '../services/favorites/sagas'
import notificationSagas from '../services/notifications/sagas'
import tourSagas from '../services/tour/sagas'
import searchSagas from '../services/search/sagas'
import affiliatesSagas from '../services/affiliates/sagas'
import { LOGOUT_COMPLETE } from '../services/auth/action-types'

const sagasMiddleware = createSagaMiddleware()

// related documentation
// https://github.com/redux-saga/redux-saga/blob/master/docs/advanced/RootSaga.md

function* allSagas(onError) {
  try {
    yield all([
      authSagas(),
      passwordRecoverySagas(),
      meSagas(),
      brandSagas(),
      mediaSagas(),
      communicationSagas(),
      toastSagas(),
      walletSagas(),
      dashboardSagas(),
      configSagas(),
      navigationSagas(),
      photoSagas(),
      ordersSagas(),
      checkoutSagas(),
      campaignsSagas(),
      resourcesSagas(),
      favoritesSagas(),
      notificationSagas(),
      tourSagas(),
      searchSagas(),
      affiliatesSagas(),
      alertsSagas(),
    ])
  } catch (e) {
    console.error(e)
    onError(e)
  }
}

function* rootSaga(onError) {
  try {
    while (true) {
      const allSagasFork = yield fork(allSagas, onError) // launch root saga on a thread
      yield take(LOGOUT_COMPLETE) // leave running until LOGOUT_COMPLETE action
      yield cancel(allSagasFork) // needed to avoid lingering sagas running on a wiped store
    }
  } catch (error) {
    console.error('Uncaught Sagas error:', error)
    onError(error)
  }
}

export default {
  middleware: sagasMiddleware,
  start: onError => sagasMiddleware.run(rootSaga, onError),
}
