import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import * as R from 'ramda'
import ChartLegend from './ChartLegend'
import withTranslations from '../../hocs/withTranslations'
import { isNilOrEmpty } from '../../app/utils'

const PIE_CHART_COLORS = ['#ffd960', '#3e17b3', '#fa5992']

am4core.useTheme(am4themes_animated)

const createChart = (element, colors, i18n, i18nNumber) => {
  // Create chart instance
  const chart = am4core.create(element, am4charts.PieChart)

  // Add label
  chart.innerRadius = 100

  // Add and configure Series
  const pieSeries = chart.series.push(new am4charts.PieSeries())
  pieSeries.dataFields.value = 'value'
  pieSeries.dataFields.category = 'name'

  // Let's cut a hole in our Pie chart the size of 30% the radius
  chart.innerRadius = am4core.percent(70)

  pieSeries.alignLabels = false
  pieSeries.labels.template.bent = true
  pieSeries.labels.template.radius = 3
  pieSeries.labels.template.padding(0, 0, 0, 0)

  pieSeries.labels.template.disabled = true
  pieSeries.ticks.template.disabled = true
  pieSeries.slices.template.tooltipText = '{category}: {value}%'
  pieSeries.slices.template.adapter.add('tooltipText', (_, target) =>
    i18n('pie-chart:tooltip', {
      category: target.dataItem.category,
      value: i18nNumber(target.dataItem.value),
    }),
  )
  pieSeries.tooltip.autoTextColor = false
  pieSeries.tooltip.background.fill = am4core.color('#fff')
  pieSeries.tooltip.getFillFromObject = false
  pieSeries.tooltip.label.fill = am4core.color('#999ab1')
  pieSeries.tooltip.label.fontSize = '12'
  pieSeries.tooltip.pointerOrientation = 'vertical'

  pieSeries.ticks.template.disabled = true

  // Color list
  pieSeries.colors.list = colors.map(color => am4core.color(color))

  // Create a base filter effect (as if it's not there) for the hover to return to
  const shadow = pieSeries.slices.template.filters.push(
    new am4core.DropShadowFilter(),
  )
  shadow.opacity = 0

  // Create hover state
  const hoverState = pieSeries.slices.template.states.getKey('hover') // normally we have to create the hover state, in this case it already exists

  // Slightly shift the shadow and make it more prominent on hover
  const hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter())
  hoverShadow.opacity = 0.4
  hoverShadow.blur = 4

  return chart
}

const PieChart = ({ data, colors, i18n, i18nNumber, title }) => {
  const chart = useRef()
  const chartElementRef = useRef()

  // Create and destroy chart on (un)mount or changing colors
  useEffect(() => {
    chart.current = createChart(
      chartElementRef.current,
      colors,
      i18n,
      i18nNumber,
    )

    return () => {
      if (chart.current) {
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [colors, i18n, i18nNumber])

  // Handle data updates
  useEffect(() => {
    // Return if chart is unmounted
    if (!chart.current) {
      return
    }

    if (
      // Check that we have the exact same series
      !data.every(
        newItem =>
          chart.current.data.find(item => item.name === newItem.name) !==
          undefined,
      ) ||
      !chart.current.data.every(
        item => data.find(newItem => item.name === newItem.name) !== undefined,
      )
    ) {
      // Set initial data
      chart.current.data = R.clone(data)
    } else {
      // Update current data
      // Needs to be imperative to trigger animation
      for (let i = 0; i < data.length; i++) {
        chart.current.data.find(item => item.name === data[i].name).value =
          data[i].value
      }

      // Trigger animation
      chart.current.invalidateRawData()
    }
  }, [data, i18n, i18nNumber])

  const highestValueItem = !isNilOrEmpty(data)
    ? data.reduce((acc, x) => (x.value > acc.value ? x : acc))
    : 0

  const legendItems = useMemo(
    () =>
      (data || []).map((dataPoint, i) => ({
        label: dataPoint.name,
        value: dataPoint.legendValue,
        color: colors[i],
      })),
    [data, colors],
  )

  return (
    <div className="chart-wrapper">
      <div className="pie-chart-wrapper">
        {title && <h3 className="chart-card-title">{title}</h3>}
        <div ref={chartElementRef} className="pie-chart" />
        <div className="pie-chart-inner-info">
          <p className="title">{i18nNumber(highestValueItem.value)}%</p>{' '}
          <p className="subtitle">{highestValueItem.name}</p>{' '}
        </div>
      </div>
      {legendItems && (
        <ChartLegend items={legendItems} isVertical></ChartLegend>
      )}
    </div>
  )
}

PieChart.defaultProps = {
  colors: PIE_CHART_COLORS,
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      legendValue: PropTypes.string,
    }),
  ),
  colors: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default withTranslations(PieChart)
