import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import CampaignPreviewTwitch from '../social-previews/CampaignPreviewTwitch'
import { isNilOrEmpty } from '../../../app/utils'

const CampaignDetailTwitchPublication = ({
  previews,
  i18n,
  // Metrics values
  averageViewers,
  maxViewers,
  followers,
  viewers,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        {averageViewers > 0 && (
          <AnalyticsKpi
            iconType="user"
            value={averageViewers}
            label={i18n('metrics:average-viewers')}
            isTruncated
          />
        )}
        {maxViewers > 0 && (
          <AnalyticsKpi
            iconType="maximum"
            value={maxViewers}
            label={i18n('metrics:maximum-viewers-peak')}
            isTruncated
          />
        )}
        {followers > 0 && (
          <AnalyticsKpi
            iconType="follower"
            value={followers}
            label={i18n('metrics:followers')}
            isTruncated
          />
        )}
        {viewers > 0 && (
          <AnalyticsKpi
            iconType="user"
            value={viewers}
            label={i18n('metrics:viewers')}
            isTruncated
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewTwitch items={previews} />
      ) : null}
    </>
  )
}

CampaignDetailTwitchPublication.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  averageViewers: PropTypes.number,
  maxViewers: PropTypes.number,
  followers: PropTypes.number,
  viewers: PropTypes.number,
}

export default withTranslations(CampaignDetailTwitchPublication)
