import React from 'react'
import PropTypes from 'prop-types'
import WebResourcesHeader from './WebResourcesHeader'

const WebResources = ({
  title,
  currentNavItem,
  reachedNavItem,
  resourceId,
  children,
}) => {
  return (
    <div className="steps-layout">
      <WebResourcesHeader
        currentNavItem={currentNavItem}
        reachedNavItem={reachedNavItem}
        resourceId={resourceId}
      />
      <div className="steps-layout-content">
        {title && (
          <h1 className="step-title">
            {currentNavItem && <span className="step">{currentNavItem}</span>}
            <span className="text">{title}</span>
          </h1>
        )}
        {children}
      </div>
    </div>
  )
}

WebResources.propTypes = {
  title: PropTypes.string,
  currentNavItem: PropTypes.number,
  reachedNavItem: PropTypes.number,
  resourceId: PropTypes.string,
  children: PropTypes.node,
}

export default WebResources
