import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../avatar/Avatar'
import withTranslations from '../../hocs/withTranslations'
import { FormattedRelativeTime } from 'react-intl'

const Notification = ({ photoURL, date, titleSlot, onClick }) => {
  return (
    <button type="button" className="notification" onClick={onClick}>
      <Avatar image={photoURL} />
      <div className="notification-content">
        <div className="notification-header">
          <div className="notification-title">{titleSlot}</div>
          <div className="notification-date">
            <FormattedRelativeTime value={date} updateIntervalInSeconds={60} />
          </div>
        </div>
      </div>
    </button>
  )
}

Notification.propTypes = {
  photoUrl: PropTypes.string,
  date: PropTypes.string,
  username: PropTypes.string,
  titleSlot: PropTypes.node,
  onClick: PropTypes.func,
}

export default withTranslations(Notification)
