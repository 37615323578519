import { compose, withPropsOnChange, withState, withHandlers } from 'recompose'
import * as R from 'ramda'
import {
  getLimitDateForStatus,
  mapOrderStatusToI18n,
  mapServiceTypeToResourceType,
  mapServiceTypeToOrderTypeI18n,
} from '../../services/orders/business'
import { mapResourceTypeToI18N } from '../../services/resources/constants'
import { remainingDays, simplifyString } from '../utils'
import withTranslations from '../../hocs/withTranslations'
import withSortOrders, {
  CURRENT_ORDERS_DEFAULT_SORT_TYPE,
  getBrandCurrentOrderSortBy,
} from '../common/withSortOrders'
import withOrderModalActions from '../common/withOrderModalActions'

const fixOrdersForOrdersPanel = ({ orders }) => ({
  orders: !!orders
    ? orders.map(order => {
        const limitDateToAction = getLimitDateForStatus(
          order.status,
          order.dates,
        )
        return {
          ...order,
          type: order.service,
          limitDateToAction,
          remainDays: limitDateToAction
            ? remainingDays(limitDateToAction)
            : undefined,
        }
      })
    : [],
  hadOrders: !!orders ? orders.length > 0 : false,
})

const filterOrdersBySearchValue = ({ orders, orderSearchValue, i18n }) => {
  const simpleSearchValue = simplifyString(orderSearchValue)
  return {
    orders:
      !!orders && orderSearchValue !== '' && i18n
        ? R.filter(
            order =>
              // Status
              simplifyString(i18n(mapOrderStatusToI18n[order.status])).includes(
                simpleSearchValue,
              ) ||
              // Resource
              simplifyString(order.resource.name).includes(simpleSearchValue) ||
              // Service (channel)
              simplifyString(
                i18n(
                  mapResourceTypeToI18N[
                    mapServiceTypeToResourceType[order.type]
                  ],
                ),
              ).includes(simpleSearchValue) ||
              // Service (service)
              simplifyString(
                i18n(mapServiceTypeToOrderTypeI18n[order.type]),
              ).includes(simpleSearchValue) ||
              // Campaign
              simplifyString(order.campaign.name).includes(simpleSearchValue),
            orders,
          )
        : orders,
  }
}

const withSearchOrders = ({ inProp }) =>
  compose(
    withTranslations,
    withState('orderSearchValue', 'setOrderSearchValue', ''),
    withHandlers({
      onOrderSearchChange: props => e => {
        props.setOrderSearchValue(e.target.value)
      },
    }),
    withPropsOnChange(
      [inProp, 'orderSearchValue', 'i18n'],
      filterOrdersBySearchValue,
    ),
  )

const withOrders = ({ inProp }) =>
  compose(
    withPropsOnChange([inProp], fixOrdersForOrdersPanel),
    withSearchOrders({ inProp }),
    withSortOrders({
      inProp,
      sortValueProp: 'ordersSortValue',
      onSortChangeProp: 'onOrdersSortChange',
      defaultSortType: CURRENT_ORDERS_DEFAULT_SORT_TYPE,
      getSortByFunction: getBrandCurrentOrderSortBy,
    }),
    withOrderModalActions,
    withHandlers({
      onOrderActionClick: props => (id, action) => {
        props.handleOrderAction(id, action)
      },
    }),
  )

export default withOrders
