import { prettifyConstant } from '../common/routes'
import { SERVICE_TYPES } from '../../services/orders/business'
import { RESOURCE_TYPES } from '../../services/resources/constants'

export const calculateTotalPrice = (basePrice, discount) =>
  discount > 1
    ? Math.round(
        Number(basePrice) - (Number(basePrice) * Number(discount)) / 100,
      )
    : basePrice

export const getSocialProfileUrlPlaceholder = (resourceType, i18n) =>
  i18n(`placeholder:${prettifyConstant(resourceType)}-profile-url`)

export const getSocialPostUrlPlaceholder = (serviceType, i18n) => {
  const getSocialPostI18NKey = resourceType =>
    i18n(`placeholder:${prettifyConstant(resourceType)}-post-url`)

  switch (serviceType) {
    case SERVICE_TYPES.FACEBOOK: {
      return getSocialPostI18NKey(RESOURCE_TYPES.FACEBOOK)
    }

    case SERVICE_TYPES.TWITTER: {
      return getSocialPostI18NKey(RESOURCE_TYPES.TWITTER)
    }

    case SERVICE_TYPES.TWITCH: {
      return getSocialPostI18NKey(RESOURCE_TYPES.TWITCH)
    }

    case SERVICE_TYPES.INSTAGRAM_PHOTO:
    case SERVICE_TYPES.INSTAGRAM_VIDEO: {
      return getSocialPostI18NKey(RESOURCE_TYPES.INSTAGRAM)
    }

    case SERVICE_TYPES.YOUTUBE_DEDICATED:
    case SERVICE_TYPES.YOUTUBE_MENTION: {
      return getSocialPostI18NKey(RESOURCE_TYPES.YOUTUBE)
    }

    case SERVICE_TYPES.TIKTOK_SHORT:
    case SERVICE_TYPES.TIKTOK_LONG: {
      return getSocialPostI18NKey(RESOURCE_TYPES.TIKTOK)
    }

    default: {
      throw new Error(`Unknown service type ${serviceType}`)
    }
  }
}
