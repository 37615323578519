import { connect } from 'react-redux'
import { compose, withHandlers, branch, lifecycle } from 'recompose'
import withRouteServerError from '../../hocs/withRouteServerError'
import withSpinner from '../../hocs/withSpinner'
import {
  getUnreadNotifications,
  readNotification,
  readAllNotifications,
  periodicFetchUnreadNotifications,
} from './'
import {
  FETCH_UNREAD_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
} from './action-types'
import { isNilOrEmpty } from '../../app/utils'
import { withScope } from '../auth/hocs'
import { AuthScope } from '../auth/business'
import {
  ROUTE_MEDIA_ORDER_BY_ID,
  ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID,
} from '../../app/common/routes'
import withIsRequestPending from '../../hocs/withIsRequestPending'

export const withNotifications = compose(
  withScope,
  connect(
    (state, { scope }) => {
      const notifications = getUnreadNotifications(state)

      if (isNilOrEmpty(notifications)) {
        return { notifications: [] }
      }

      const formattedNotifications = notifications.map(notification => {
        const {
          brand,
          brandName,
          resourceName,
          mediaName,
        } = notification.i18nText.values

        const username = scope === AuthScope.MEDIA ? brandName : mediaName

        const dateDelta =
          -(+Date.now() - +new Date(notification.createdAt)) / 1000

        return {
          id: notification.id,
          photoURL: notification.image,
          date: dateDelta,
          actionText: notification.i18nText.key,
          linkURL: notification.url,
          orderId: notification.orderId,
          text: isNilOrEmpty(notification.i18nText) ? notification.text : null,
          resourceName,
          brandName: brand,
          username,
        }
      })

      return {
        notifications: formattedNotifications,
      }
    },
    { readNotification, readAllNotifications },
  ),

  withHandlers({
    onNotificationClick: ({
      readNotification,
      history,
      scope,
    }) => notification => {
      const { orderId, campaignId = 0 } = notification

      readNotification(notification)

      history.push(
        scope === AuthScope.MEDIA
          ? ROUTE_MEDIA_ORDER_BY_ID.linkTo({ orderId })
          : ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.linkTo({ campaignId, orderId }),
      )
    },
  }),
  withIsRequestPending(READ_ALL_NOTIFICATIONS, 'isReadingAllNotifications'),
)

export const withFetchNotifications = compose(
  connect(
    state => ({ notifications: getUnreadNotifications(state) }),
    { periodicFetchUnreadNotifications },
  ),

  lifecycle({
    componentDidMount() {
      this.props.periodicFetchUnreadNotifications()
    },
  }),

  withRouteServerError(FETCH_UNREAD_NOTIFICATIONS),

  branch(
    props => props.notifications === null,
    withSpinner(FETCH_UNREAD_NOTIFICATIONS, {
      i18nKey: 'status:loading-notifications',
    }),
  ),
)
