import React from 'react'
import PropTypes from 'prop-types'

const EmptyResult = ({ imageBig, imageSmall, subtitle, title }) => (
  <div className="empty-result">
    <picture className="empty-result-image">
      <source media="(max-width: 799px)" srcSet={imageSmall} />
      <source media="(min-width: 800px)" srcSet={imageBig} />
      <img src={imageBig} alt="Decoration" />
    </picture>
    <div className="empty-result-contents">
      <p className="empty-result-title">{title}</p>
      <p className="empty-result-description">{subtitle}</p>
    </div>
  </div>
)

EmptyResult.propTypes = {
  imageBig: PropTypes.string,
  imageSmall: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

export default EmptyResult
