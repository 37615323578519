import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import CoobisPropTypes from '../common/coobis-prop-types'
import ProfileDataSection from '../../ui/profile/ProfileDataSection'
import AccountDeleter from '../../ui/form-elements/AccountDeleter'
import ChangePasswordForm from '../common/ChangePasswordForm'
import withTranslations from '../../hocs/withTranslations'
import { getMediaInfo } from '../../services/media'
import Modal from '../common/Modal'
import AccountDeleteForm from '../common/AccountDeleteForm'
import { AuthScope } from '../../services/auth/business'
import MediaBillingForm from './MediaBillingForm'
import MediaDataForm from './MediaDataForm'
import ProfilePictureForm from '../common/ProfilePictureForm'
import ProfileContainerWithTabs from '../common/ProfileContainerWithTabs'
import ProfileMovements from '../../ui/profile/ProfileMovements'
import ProfileMovementsList from '../../ui/profile/ProfileMovementsList'
import { NumberPagination } from '@redradix/components.pagination'
import MovementsWithdrawFunds from '../../ui/profile/MovementsWithdrawFunds'
import { withWallet } from '../../services/wallet/hocs'
import useMovementsPagination from '../wallet/useMovementsPagination'
import withWalletMovements from '../wallet/withWalletMovements'
import withWithdrawFunds from '../wallet/withWithdrawFunds'

const MEDIA_DELETE_REASONS_I18N = [
  'media-delete-reason:closed-media',
  'media-delete-reason:not-interested',
  'media-delete-reason:insufficient-payment',
]

const MediaProfile = ({
  mediaInfo,

  balance,
  totalCurrency,

  fetchWalletMovements,
  movements,
  movementsMeta,
  isLoadingMovements,
  openWithdrawFundsModal,

  i18n,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const { currentPage, onPageClick } = useMovementsPagination(
    movementsMeta,
    fetchWalletMovements,
  )

  return (
    <ProfileContainerWithTabs>
      <>
        <ProfileDataSection title={i18n('profile:my-data')}>
          <ProfilePictureForm />
          <MediaDataForm mediaInfo={mediaInfo} />
        </ProfileDataSection>

        <ProfileDataSection
          title={i18n('profile:billing')}
          description={i18n('profile:media-billing-description')}
        >
          <MediaBillingForm mediaInfo={mediaInfo} />
        </ProfileDataSection>

        <ProfileDataSection title={i18n('profile:change-password')}>
          <ChangePasswordForm />
        </ProfileDataSection>

        <AccountDeleter
          descriptionText={i18n('profile:delete-description')}
          buttonText={i18n('profile:delete-action')}
          onDelete={() => setDeleteModalOpen(true)}
          i18n={i18n}
        />

        {isDeleteModalOpen && (
          <Modal onClose={() => setDeleteModalOpen(false)}>
            <AccountDeleteForm
              scope={AuthScope.MEDIA}
              reasonOptions={MEDIA_DELETE_REASONS_I18N}
            />
          </Modal>
        )}
      </>

      <ProfileMovements>
        <MovementsWithdrawFunds
          balanceAmount={balance}
          incomeAmount={totalCurrency}
          isWithdrawFundsDisabled={false}
          onWithdrawFunds={openWithdrawFundsModal}
        />

        <ProfileMovementsList
          movements={movements}
          isLoading={isLoadingMovements}
        />

        <NumberPagination
          currentPage={currentPage}
          totalPages={movementsMeta.totalPages}
          onPageClick={onPageClick}
        />
      </ProfileMovements>
    </ProfileContainerWithTabs>
  )
}

MediaProfile.propTypes = {
  balance: CoobisPropTypes.money.isRequired,
}

const enhance = compose(
  withTranslations,
  withWallet,

  connect(state => ({
    mediaInfo: getMediaInfo(state),
  })),

  withWalletMovements,
  withWithdrawFunds,
)

export default enhance(MediaProfile)
