import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import withTranslations from '../../hocs/withTranslations'
import cx from 'classnames'

const NavItem = ({ i18nKey, url, i18n, isActive, isMobile, onClick }) => {
  const classes = cx('main-navigation-item', {
    'is-active': isActive,
    'animated-nav-only': isMobile,
  })
  return (
    <li className={classes} data-i18nkey={i18nKey}>
      <Link className="main-navigation-link" to={url} onClick={onClick}>
        {i18n(i18nKey)}
      </Link>
    </li>
  )
}

NavItem.defaultProps = {
  isActive: false,
  isMobile: false,
}

NavItem.propTypes = {
  i18nKey: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default withTranslations(NavItem)
