import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { CardCVCElement } from 'react-stripe-elements'
import { ReactComponent as CardCvc } from '../../styles/icons/card-cvc.svg'

const InputCardCvc = ({
  label,
  placeholder,
  errorText,
  isDisabled,
  onBlur,
  onChange,
  onFocus,
  onReady,
}) => {
  const classes = cx('rdx-input-field input-card-cvc', {
    'has-label': label,
    'has-error': errorText,
  })

  const stripeStyle = {
    base: {
      color: '#282828',
      fontFamily: 'Rubik, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',

      '::placeholder': {
        color: `#999ab1`,
      },
    },
  }

  return (
    <div className={classes}>
      <div className="rdx-input-field-wrapper">
        <label className="rdx-input-field-label">
          <span className="label-text">{label}</span>

          <CardCVCElement
            placeholder={placeholder}
            disabled={isDisabled}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onReady={onReady}
            style={stripeStyle}
          />
        </label>
        <div className="icon-card-wrapper">
          <CardCvc className="icon-card" />
        </div>
      </div>
      <div className="rdx-input-field-helper">{errorText}</div>
    </div>
  )
}

InputCardCvc.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorText: PropTypes.node,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onReady: PropTypes.func,
}

export default InputCardCvc
