import { useEffect } from 'react'

const useScript = (url, cleanUpHandler) => {
  useEffect(() => {
    // Ensure we're only loading it once
    if (document.querySelector(`script[src="${url}"]`)) {
      return
    }

    const script = document.createElement('script')
    script.src = url
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      if (cleanUpHandler) {
        cleanUpHandler()
      }
    }
  }, [url, cleanUpHandler])
}

export default useScript
