import { SERVICE } from './constants'

export const FETCH_MEDIA_ORDERS_IN_PROGRESS = `${SERVICE}/FETCH_MEDIA_ORDERS_IN_PROGRESS`
export const FETCH_MEDIA_ORDERS_FINISHED = `${SERVICE}/FETCH_MEDIA_ORDERS_FINISHED`
export const FETCH_MEDIA_ORDER_DETAIL = `${SERVICE}/FETCH_MEDIA_ORDER_DETAIL`
export const FETCH_BRAND_ORDERS_IN_PROGRESS = `${SERVICE}/FETCH_BRAND_ORDERS_IN_PROGRESS`
export const FETCH_BRAND_ORDER_DETAIL = `${SERVICE}/FETCH_BRAND_ORDER_DETAIL`
export const FETCH_ORDER_HISTORY = `${SERVICE}/FETCH_ORDER_HISTORY`
export const CHANGE_ORDER_CAMPAIGN = `${SERVICE}/CHANGE_ORDER_CAMPAIGN`

export const FETCH_CURRENT_CAMPAIGN_ORDERS = `${SERVICE}/FETCH_CURRENT_CAMPAIGN_ORDERS`
export const PERIODIC_FETCH_CURRENT_CAMPAIGN_ORDERS = `${SERVICE}/PERIODIC_FETCH_CURRENT_CAMPAIGN_ORDERS`
export const CANCEL_PERIODIC_FETCH_CURRENT_CAMPAIGN_ORDERS = `${SERVICE}/CANCEL_PERIODIC_FETCH_CURRENT_CAMPAIGN_ORDERS`

export const FETCH_CANCELLED_CAMPAIGN_ORDERS = `${SERVICE}/FETCH_CANCELLED_CAMPAIGN_ORDERS`
export const PERIODIC_FETCH_CANCELLED_CAMPAIGN_ORDERS = `${SERVICE}/PERIODIC_FETCH_CANCELLED_CAMPAIGN_ORDERS`
export const CANCEL_PERIODIC_FETCH_CANCELLED_CAMPAIGN_ORDERS = `${SERVICE}/CANCEL_PERIODIC_FETCH_CANCELLED_CAMPAIGN_ORDERS`

export const PERIODIC_FETCH_BRAND_ORDERS_IN_PROGRESS = `${SERVICE}/PERIODIC_FETCH_BRAND_ORDERS_IN_PROGRESS`
export const CANCEL_PERIODIC_FETCH_BRAND_ORDERS_IN_PROGRESS = `${SERVICE}/CANCEL_PERIODIC_FETCH_BRAND_ORDERS_IN_PROGRESS`

// Order modal actions
export const CLOSE_ORDER_MODAL = `${SERVICE}/CLOSE_ORDER_MODAL`

// Brand
export const CANCEL_ORDER = `${SERVICE}/CANCEL_ORDER`
export const ACCEPT_ORDER_COUNTERBRIEFING = `${SERVICE}/ACCEPT_ORDER_COUNTERBRIEFING`
export const REJECT_ORDER_COUNTERBRIEFING = `${SERVICE}/REJECT_ORDER_COUNTERBRIEFING`
export const APPROVE_ORDER_PREDELIVERY = `${SERVICE}/APPROVE_ORDER_PREDELIVERY`
export const CORRECT_ORDER_PREDELIVERY = `${SERVICE}/CORRECT_ORDER_PREDELIVERY`
export const APPROVE_ORDER_DELIVERY = `${SERVICE}/APPROVE_ORDER_DELIVERY`
export const CORRECT_ORDER_DELIVERY = `${SERVICE}/CORRECT_ORDER_DELIVERY`

// Media
export const ACCEPT_ORDER_REVIEW = `${SERVICE}/ACCEPT_ORDER_REVIEW`
export const REJECT_ORDER_REVIEW = `${SERVICE}/REJECT_ORDER_REVIEW`
export const ACCEPT_ORDER_REVIEW_WITH_CHANGES = `${SERVICE}/ACCEPT_ORDER_REVIEW_WITH_CHANGES`

export const SEND_ORDER_PREDELIVERY = `${SERVICE}/SEND_ORDER_PREDELIVERY`

export const SEND_ORDER_DELIVERY_WEB = `${SERVICE}/SEND_ORDER_DELIVERY_WEB`
export const SEND_ORDER_DELIVERY_STORY = `${SERVICE}/SEND_ORDER_DELIVERY_STORY`
export const SEND_ORDER_DELIVERY_SOCIAL = `${SERVICE}/SEND_ORDER_DELIVERY_SOCIAL`
