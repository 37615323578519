import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { postRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

export const SEARCH_EXPORT_ENDPOINT = `${envConfig.searchBackendUrl}/_msearch/resource-services-export`
export const RESOURCE_SERVICES_EXPORT_ENDPOINT = `${envConfig.searchBackendUrl}/resource-services-export`

const downloadExportEndpoint = id =>
  `${envConfig.authBackendUrl}/session/resource-services-export/${id}`

export function* fetchSearchExport(currentSearch) {
  const response = yield call(
    postRequest,
    SEARCH_EXPORT_ENDPOINT,
    currentSearch,
    { parseJSON: true },
  )
  return parseResponseErrors(response)
}

export function* fetchResourceServicesExport(resourceServices) {
  const response = yield call(
    postRequest,
    RESOURCE_SERVICES_EXPORT_ENDPOINT,
    resourceServices,
    { parseJSON: true },
  )
  return parseResponseErrors(response)
}

export const downloadResourcesServicesExport = fileId => {
  window.location.assign(downloadExportEndpoint(fileId))
}
