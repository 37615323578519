import OrderModalSendDeliveryWeb from '../../ui/order-modals/OrderModalSendDeliveryWeb'
import { compose, withProps } from 'recompose'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import * as Yup from 'yup'
import * as R from 'ramda'
import withFormErrors from '../../hocs/withFormErrors'
import {
  i18nValidation,
  validateMaxFileSize,
  getPostUrlRegexpForServiceType,
} from '../common/validation'
import { BRIEFINGS_MAX_FILE_SIZE } from '../../services/checkout/business'
import { isNilOrEmpty } from '../utils'
import { connect } from 'react-redux'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import { SEND_ORDER_DELIVERY_WEB } from '../../services/orders/action-types'
import { sendOrderDeliveryWeb } from '../../services/orders'
import { SERVICE_TYPES } from '../../services/orders/business'

const validationSchema = ({
  hasFacebookBroadcast,
  hasTwitterBroadcast,
  hasInstagramBroadcast,
}) =>
  Yup.object().shape(
    R.filter(R.complement(R.isNil), {
      url: Yup.string()
        .url(i18nValidation.invalidURL)
        .required(i18nValidation.required),
      facebook: hasFacebookBroadcast
        ? Yup.string()
            .matches(
              getPostUrlRegexpForServiceType(SERVICE_TYPES.FACEBOOK),
              i18nValidation.invalidURL,
            )
            .required(i18nValidation.required)
        : undefined,
      twitter: hasTwitterBroadcast
        ? Yup.string()
            .matches(
              getPostUrlRegexpForServiceType(SERVICE_TYPES.TWITTER),
              i18nValidation.invalidURL,
            )
            .required(i18nValidation.required)
        : undefined,
      instagram: hasInstagramBroadcast
        ? Yup.mixed()
            .test(validateMaxFileSize(BRIEFINGS_MAX_FILE_SIZE))
            .required(i18nValidation.required)
        : undefined,
      comments: Yup.string(),
    }),
  )

const enhance = compose(
  connect(
    null,
    { sendOrderDeliveryWeb },
  ),

  withProps(({ orderData }) => {
    const broadcastEnabled = R.path(['product', 'broadcast'], orderData)

    return {
      hasFacebookBroadcast:
        !!R.path(['product', 'broadcastNetworks', 'facebook'], orderData) &&
        broadcastEnabled,
      hasTwitterBroadcast:
        !!R.path(['product', 'broadcastNetworks', 'twitter'], orderData) &&
        broadcastEnabled,
      hasInstagramBroadcast:
        !!R.path(['product', 'broadcastNetworks', 'instagram'], orderData) &&
        broadcastEnabled,
    }
  }),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({
      hasFacebookBroadcast,
      hasTwitterBroadcast,
      hasInstagramBroadcast,
    }) =>
      R.filter(R.complement(R.isNil), {
        url: '',
        facebook: hasFacebookBroadcast ? '' : undefined,
        twitter: hasTwitterBroadcast ? '' : undefined,
        instagram: hasInstagramBroadcast ? '' : null,
        comments: '',
      }),

    fileFields: ['instagram'],

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSubmit = R.filter(R.complement(isNilOrEmpty), values)
      props.sendOrderDeliveryWeb(props.orderId, props.isFix, valuesToSubmit)
    },
  }),

  withIsRequestPending(SEND_ORDER_DELIVERY_WEB),
  withIsRequestSucceeded(SEND_ORDER_DELIVERY_WEB),
  withRequestError(SEND_ORDER_DELIVERY_WEB),
  withDeleteRequestOnUnmount(SEND_ORDER_DELIVERY_WEB),

  withFormErrors(['url', 'facebook', 'twitter', 'instagram', 'comments']),
)

export default enhance(OrderModalSendDeliveryWeb)
