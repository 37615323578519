import React from 'react'
import withTranslations from '../../hocs/withTranslations'
import { ROUTE_BRAND_RESOURCES_SEARCH } from '../../app/common/routes'
import InitialCard from './InitialCard'
import imageBig from './images/searchBig.png'
import imageSmall from './images/searchSmall.png'

const SearchCard = ({ i18n }) => {
  return (
    <InitialCard
      linkUrl={ROUTE_BRAND_RESOURCES_SEARCH.linkTo()}
      linkText={i18n('action:search-media-influencers')}
      imageBigUrl={imageBig}
      imageSmallUrl={imageSmall}
    />
  )
}

export default withTranslations(SearchCard)
