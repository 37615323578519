import React from 'react'
import PropTypes from 'prop-types'
import HackFormattedNumber from '../HackFormattedNumber'

const AlternativeGaugeBar = ({ label, color, value }) => {
  return (
    <div className="alternative-gauge-bar">
      <p className="alternative-gauge-bar-data">
        <span className="alternative-gauge-bar-label">{label}</span>
        <span className="alternative-gauge-bar-value">
          <HackFormattedNumber
            value={value}
            maximumFractionDigits={2}
            style={`percent`}
          />
        </span>
      </p>
      <div className="alternative-gauge-bar-total-bar">
        <div
          className="alternative-gauge-bar-percent-bar"
          style={{ backgroundColor: `${color}`, width: `${value * 100}%` }}
        />
      </div>
    </div>
  )
}

AlternativeGaugeBar.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.number.isRequired,
}

export default AlternativeGaugeBar
