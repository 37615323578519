import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'

import './styles/styles.scss'
import './twitter-intent-handler'
import './facebook-share-handler'

import App from './app/App'
//import * as serviceWorker from './serviceWorker'
import * as serviceWorkerCustom from './serviceWorkerCustom'
import createStore from './redux/create-store'
import { reportSentryException } from './services/sentry-error'
import AppProvider from './app/AppProvider'
import AppErrorHandler from './app/AppErrorHandler'
import InternalError from './app/InternalError'

const mountPoint = document.getElementById('root')
const initialState = window.___INITIAL_STATE__
const history = createBrowserHistory()
const store = createStore(initialState, history, handleError)

function handleError(error, info = undefined) {
  ReactDOM.render(
    <Provider store={store}>
      <AppProvider history={history}>
        <InternalError />
      </AppProvider>
    </Provider>,
    mountPoint,
  )

  reportSentryException(error, store.getState(), info).then(
    ({ eventId, user }) => {
      ReactDOM.render(
        <Provider store={store}>
          <AppProvider history={history}>
            <InternalError eventId={eventId} user={user} />
          </AppProvider>
        </Provider>,
        mountPoint,
      )
    },
  )
}

ReactDOM.render(
  <Provider store={store}>
    <AppProvider history={history}>
      <AppErrorHandler onError={handleError}>
        <App />
      </AppErrorHandler>
    </AppProvider>
  </Provider>,
  mountPoint,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorkerCustom.register()
