import { compose, withHandlers, withPropsOnChange } from 'recompose'

const withHolidays = compose(
  withHandlers({
    // Clear holidays fields
    onClearHolidays: props => _ => {
      const hadHolidaysBeginAt = props.values.holidaysBeginAt !== null
      const hadHolidaysEndAt = props.values.holidaysEndAt !== null

      props.setFieldValue('holidaysBeginAt', null, true)
      props.setFieldValue('holidaysEndAt', null, true)

      if (hadHolidaysBeginAt) {
        props.setFieldTouched('holidaysBeginAt', true, true)
      }

      if (hadHolidaysEndAt) {
        props.setFieldTouched('holidaysBeginAt', true, true)
      }
    },
  }),

  // Normalize holidays field names for server errors
  withPropsOnChange(['requestError'], ({ requestError, ...props }) => ({
    requestError:
      requestError && requestError.validationMessages
        ? {
            ...requestError,
            validationMessages: {
              ...requestError.validationMessages,
              holidaysBeginAt: requestError.validationMessages.beginAt,
              beginAt: undefined,
              holidaysEndAt: requestError.validationMessages.endAt,
              endAt: undefined,
            },
          }
        : requestError,
  })),
)

export default withHolidays
