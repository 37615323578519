import { connect } from 'react-redux'
import {
  getCountries,
  getCategories,
  getTranslatedCountries,
  getCurrencies,
} from './selectors'

export const withTranslatedCountries = ({
  outProp = 'translatedCountries',
} = {}) =>
  connect(state => ({
    [outProp]: getTranslatedCountries(state),
  }))

export const withCountries = connect(state => ({
  countries: getCountries(state),
}))

export const withCategories = connect(state => ({
  categories: getCategories(state),
}))

export const withCurrencies = connect(state => ({
  currencies: getCurrencies(state),
}))
