import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import Select from 'react-select'
import * as R from 'ramda'

const MultiSelectSearchable = ({
  data,
  allData,
  value,
  placeholder,
  isSearchable,
  handleChange,
  translateKey = R.identity,
}) => {
  const options = useMemo(() => {
    const translatedData = R.pipe(
      R.map(item => ({
        label: translateKey(item.key),
        value: item.key,
        isDisabled: item.doc_count === 0,
      })),
      R.sort((a, b) => a.label.localeCompare(b.label)),
    )(data)

    const emptyData = !!allData
      ? R.pipe(
          R.filter(key => !data.find(item => item.key === key)),
          R.map(key => ({
            label: translateKey(key),
            value: key,
            isDisabled: true,
          })),
          R.sort((a, b) => a.label.localeCompare(b.label)),
        )(allData)
      : []

    return [...translatedData, ...emptyData]
  }, [data, allData, translateKey])

  const values = R.pipe(
    R.keys,
    R.map(item => ({ label: translateKey(item), value: item })),
  )(value)

  const onChange = useCallback(
    (selectedValue, { removedValue }) => {
      if (removedValue) {
        handleChange(removedValue.value)
        return
      }

      if (!R.isEmpty(selectedValue)) {
        R.pipe(
          R.last,
          R.prop('value'),
          handleChange,
        )(selectedValue)
      }
    },
    [handleChange],
  )

  return (
    <Select
      className="select-searchable"
      classNamePrefix="select-searchable"
      isSearchable={isSearchable}
      isClearable={false}
      isMulti={true}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      value={values}
    />
  )
}

MultiSelectSearchable.defaultProps = {
  isSearchable: true,
}

MultiSelectSearchable.propTypes = {
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default MultiSelectSearchable
