import * as actionTypes from './action-types'
import { call, put, all, takeLeading } from 'redux-saga/effects'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
} from '../communication/actions'
import {
  fetchSearchExport,
  fetchResourceServicesExport,
  downloadResourcesServicesExport,
} from './api'

function* fetchSearchExportSaga({ payload: { search } }) {
  yield put(startRequest(actionTypes.FETCH_SEARCH_EXPORT))

  const response = yield call(fetchSearchExport, search)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_SEARCH_EXPORT, response))
    return
  }

  yield put(endRequestSuccess(actionTypes.FETCH_SEARCH_EXPORT))

  downloadResourcesServicesExport(response.data.fileName)
}

function* fetchCartExportSaga({ payload: { cartItems } }) {
  yield put(startRequest(actionTypes.FETCH_CART_EXPORT))

  const response = yield call(fetchResourceServicesExport, cartItems)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_CART_EXPORT, response))
    return
  }

  yield put(endRequestSuccess(actionTypes.FETCH_CART_EXPORT))

  downloadResourcesServicesExport(response.data.fileName)
}

export default function*() {
  yield all([
    takeLeading(actionTypes.FETCH_SEARCH_EXPORT, fetchSearchExportSaga),
    takeLeading(actionTypes.FETCH_CART_EXPORT, fetchCartExportSaga),
  ])
}
