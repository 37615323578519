import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CardKpi from './CardKpi'
import * as R from 'ramda'
import { ReactComponent as Target } from '../../styles/icons/target.svg'
import { ReactComponent as Click } from '../../styles/icons/click.svg'
import { ReactComponent as Heart } from '../../styles/icons/heart.svg'
import { ReactComponent as Percent } from '../../styles/icons/percent.svg'
import { hasFollowersData } from '../../services/resources/business'

const StripCardKpi = ({
  type,

  followers,
  followersNotifications,
  hasFollowersInfo,
  infoFollowersText,

  interactions,
  interactionsNotifications,
  hasInteractionsInfo,
  infoInteractionsText,

  engagement,
  engagementNotifications,
  hasEngagementInfo,
  infoEngagementText,

  acceptance,
  acceptanceNotifications,
  hasAcceptanceInfo,
  infoAcceptanceText,

  i18n,
}) => {
  return (
    <div className="strip-card-kpi">
      {!R.isNil(followers) && hasFollowersData(type) && (
        <CardKpi
          className="card-kpi-followers"
          figure={followers}
          label={i18n('noun:followers')}
          isTruncated
          notifications={followersNotifications}
          hasInfo={hasFollowersInfo}
          infoText={infoFollowersText}
        >
          <Target className="icon" />
        </CardKpi>
      )}

      {!R.isNil(interactions) ? (
        <CardKpi
          className="card-kpi-interactions"
          figure={interactions}
          label={i18n('noun:interactions')}
          isTruncated
          notifications={interactionsNotifications}
          hasInfo={hasInteractionsInfo}
          infoText={infoInteractionsText}
        >
          <Click className="icon" />
        </CardKpi>
      ) : null}

      {!R.isNil(engagement) ? (
        <CardKpi
          className="card-kpi-engagement"
          figure={engagement}
          label={i18n('noun:engagement')}
          isPercentage
          notifications={engagementNotifications}
          hasInfo={hasEngagementInfo}
          infoText={infoEngagementText}
        >
          <Heart className="icon" />
        </CardKpi>
      ) : null}

      {!R.isNil(acceptance) ? (
        <CardKpi
          className="card-kpi-acceptance"
          figure={acceptance}
          label={i18n('noun:acceptance')}
          isPercentage
          notifications={acceptanceNotifications}
          hasInfo={hasAcceptanceInfo}
          infoText={infoAcceptanceText}
        >
          <Percent className="icon" />
        </CardKpi>
      ) : null}
    </div>
  )
}

StripCardKpi.propTypes = {
  followers: PropTypes.number,
  interactions: PropTypes.number,
  engagement: PropTypes.number,
  acceptance: PropTypes.number,
  i18n: PropTypes.func,
}

export default withTranslations(StripCardKpi)
