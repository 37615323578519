import React from 'react'
import PropTypes from 'prop-types'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import HackFormattedMoney from '../HackFormattedMoney'
import { moneyToFloat } from '../../app/utils'
import { FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import Button from '@redradix/components.button'
import withTranslations from '../../hocs/withTranslations'
import Card from './Card'
import CardKpi from './CardKpi'
import Avatar from '../avatar/Avatar'
import CardLabel from './CardLabel'
import Rating from '../rating/Rating'
import CardWebData from './CardWebData'
import Select from '../form-elements/Select'
import CardSocialBroadcast from './CardSocialBroadcast'
import Tooltip from '@redradix/components.tooltip'
import TooltipTrigger from '../tooltips/TooltipTrigger'
import TooltipSubtitle from '../texts/TooltipSubtitle'
import TooltipRegularText from '../texts/TooltipRegularText'
import CardBadge from '../cards/CardBadge'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import { ReactComponent as Cart } from './../../styles/icons/cart.svg'
import { ReactComponent as Fav } from './../../styles/icons/fav.svg'
import { ReactComponent as FavFilled } from './../../styles/icons/fav-filled.svg'

const ResultCard = ({
  i18n,
  linkUrl,
  isNew,
  image,
  tooltipContainer,
  name,
  url,
  category,
  type,
  followers,
  engagements,
  engagementRate,
  price,
  finalPrice,
  rating,
  valuations,
  acceptanceRate,
  twitterFollowers,
  twitterUrl,
  facebookFollowers,
  facebookUrl,
  instagramFollowers,
  instagramUrl,
  domAuthority,
  pageAuthority,
  visitors,
  uniqueVisitors,
  pageViews,
  male,
  female,
  ageRate,
  ageGroup,
  services,
  selectedService,
  isCartDisabled,
  isCartLoading,
  isUserVerified,
  onServiceChange,
  acceptsBroadcast,
  broadcastSocialNetworks,
  isFavourited,
  onFavClick,
  onCartClick,
  isBroadcastChecked,
  onBroadcastChange,
  holidaysEndDate,
  isInCart,
}) => {
  return (
    <Card isHighlighted>
      <div className="result-card">
        <div className="result-card-content">
          <Link to={linkUrl} className="result-card-link">
            <div className="result-card-extra">
              {moneyToFloat(finalPrice) < moneyToFloat(price) ? (
                <CardLabel type="sale" price={price} />
              ) : null}
              {isNew ? <CardLabel type="new" /> : null}
              <button
                className="result-card-extra-action"
                title={i18n('noun:favourites')}
                onClick={onFavClick}
              >
                {isFavourited ? (
                  <FavFilled className="icon rdx-button-icon filled-icon" />
                ) : (
                  <Fav className="icon rdx-button-icon" />
                )}
              </button>
            </div>
            <Avatar
              iconBorder
              image={image}
              avatarName={name}
              channel={type}
              url={url}
            />
            <div className="result-card-info">
              <div className="result-card-user">
                <div className="result-card-name">
                  {name}
                  {isUserVerified && <CardBadge isVerified={isUserVerified} />}
                </div>
                <div className="result-card-category">{category}</div>
                <Rating rating={rating} valuations={valuations} />
              </div>
              {followers || engagements || engagementRate || acceptanceRate ? (
                <div className="result-card-kpis">
                  {followers ? (
                    <CardKpi
                      figure={followers}
                      label={i18n('noun:followers')}
                      isTruncated
                    />
                  ) : null}
                  {engagements ? (
                    <CardKpi
                      figure={engagements}
                      label={i18n('noun:interactions')}
                      isTruncated
                    />
                  ) : null}
                  {engagementRate ? (
                    <CardKpi
                      figure={engagementRate}
                      label={i18n('noun:engagement')}
                      isPercentage
                    />
                  ) : null}
                  {acceptanceRate ? (
                    <CardKpi
                      figure={acceptanceRate}
                      label={i18n('noun:acceptance')}
                      isPercentage
                    />
                  ) : null}
                  <div className="result-card-tooltip">
                    <Tooltip
                      position="right"
                      container={tooltipContainer}
                      renderTooltip={() => (
                        <>
                          <TooltipSubtitle text={i18n('noun:interactions')} />
                          <TooltipRegularText
                            text={i18n('description:interactions')}
                          />
                          <TooltipSubtitle
                            text={i18n('noun:engagement-rate')}
                          />
                          <TooltipRegularText
                            text={i18n('description:engagement-rate')}
                          />
                          <TooltipSubtitle
                            text={i18n('noun:acceptance-rate')}
                          />
                          <TooltipRegularText
                            text={i18n('description:engagement-rate')}
                          />
                        </>
                      )}
                      renderTrigger={(
                        { open, close, triggerRef },
                        triggerClasses,
                      ) => (
                        <TooltipTrigger
                          classNames={triggerClasses}
                          onMouseOver={open}
                          onMouseOut={close}
                          ref={triggerRef}
                        />
                      )}
                      hasArrow={false}
                      widthFixed
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </Link>
          <div className="result-card-actions">
            {acceptsBroadcast && !holidaysEndDate ? (
              <CardSocialBroadcast
                socialNetworks={broadcastSocialNetworks}
                checked={isBroadcastChecked}
                onChange={onBroadcastChange}
              />
            ) : null}
            <div className="result-card-price">
              <HackFormattedMoney value={finalPrice} />
            </div>
            <div className="result-card-action">
              {holidaysEndDate ? (
                <p className="card-holidays-indicator">
                  <span>{i18n('noun:vacations-until')} </span>
                  <span>
                    <FormattedDate format="coobis" value={holidaysEndDate} />
                  </span>
                </p>
              ) : (
                <>
                  {services.length > 1 && (
                    <Select
                      options={services}
                      value={selectedService}
                      isDisabled={isCartDisabled}
                      onChange={onServiceChange}
                    />
                  )}
                  <Button
                    isDisabled={isCartDisabled || isInCart}
                    isLoading={isCartLoading}
                    onClick={onCartClick}
                  >
                    <Cart className="icon rdx-button-icon" />
                    <span>
                      {i18n(isInCart ? 'action:added' : 'action:add')}
                    </span>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        {type === RESOURCE_TYPES.WEB ? (
          <Link to={linkUrl}>
            <CardWebData
              twitter={twitterFollowers}
              twitterUrl={twitterUrl}
              facebook={facebookFollowers}
              facebookUrl={facebookUrl}
              instagram={instagramFollowers}
              instagramUrl={instagramUrl}
              domAuthority={domAuthority}
              pageAuthority={pageAuthority}
              visitors={visitors}
              uniqueVisitors={uniqueVisitors}
              pageViews={pageViews}
              male={male}
              female={female}
              ageRate={ageRate}
              ageGroup={ageGroup}
            />
          </Link>
        ) : null}
      </div>
    </Card>
  )
}

ResultCard.propTypes = {
  linkUrl: PropTypes.string,
  isNew: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  category: PropTypes.string,
  image: PropTypes.string,
  tooltipContainer: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  followers: PropTypes.number,
  engagements: PropTypes.number,
  engagementRate: PropTypes.number,
  acceptanceRate: PropTypes.number,
  price: CoobisPropTypes.money,
  finalPrice: CoobisPropTypes.money,
  rating: PropTypes.number,
  valuations: PropTypes.number,
  twitterFollowers: PropTypes.number,
  twitterUrl: PropTypes.string,
  facebookFollowers: PropTypes.number,
  facebookUrl: PropTypes.string,
  instagramFollowers: PropTypes.number,
  instagramUrl: PropTypes.string,
  domAuthority: PropTypes.number,
  pageAuthority: PropTypes.number,
  visitors: PropTypes.number,
  uniqueVisitors: PropTypes.number,
  pageViews: PropTypes.number,
  male: PropTypes.number,
  female: PropTypes.number,
  ageRate: PropTypes.number,
  ageGroup: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
    }),
  ),
  selectedService: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  isCartDisabled: PropTypes.bool,
  isCartLoading: PropTypes.bool,
  isUserVerified: PropTypes.bool,
  onServiceChange: PropTypes.func,
  acceptsBroadcast: PropTypes.bool,
  broadcastSocialNetworks: PropTypes.arrayOf(
    PropTypes.oneOf([RESOURCE_TYPES.FACEBOOK, RESOURCE_TYPES.TWITTER]),
  ),
  isFavourited: PropTypes.bool,
  onCartClick: PropTypes.func,
  onFavClick: PropTypes.func,
  isBroadcastChecked: PropTypes.bool,
  onBroadcastChange: PropTypes.func,
  holidaysEndDate: PropTypes.object,
  isInCart: PropTypes.bool,
}

export default withTranslations(ResultCard)
