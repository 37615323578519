import {
  compose,
  withPropsOnChange,
  lifecycle,
  withHandlers,
  branch,
} from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getBrandOrderDetailById,
  fetchBrandOrderDetail,
  changeOrderCampaign,
} from '../../services/orders'
import {
  FETCH_BRAND_ORDER_DETAIL,
  CHANGE_ORDER_CAMPAIGN,
} from '../../services/orders/action-types'
import { withScope } from '../../services/auth/hocs'
import withOrderModalActions from '../common/withOrderModalActions'
import OrderDetailView from '../../ui/orders/OrderDetailView'
import { isNilOrEmpty } from '../utils'
import withSpinnerWithId from '../../hocs/withSpinnerWithId'
import { ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID } from '../common/routes'
import withTranslations from '../../hocs/withTranslations'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withOrderDeliveryHistory from './withOrderDeliveryHistory'
import withOrderDetailTabs from './withOrderDetailTabs'
import orderDataToViewProps from './orderDataToViewProps'
import {
  withCampaigns,
  withSelectCampaignAfterCreation,
  withCampaignsSelectOptions,
} from '../../services/campaigns/hocs'
import withSpinner from '../../hocs/withSpinner'
import { FETCH_CAMPAIGNS } from '../../services/campaigns/action-types'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestWithIdPending from '../../hocs/withIsRequestWithIdPending'
import withRequestWithIdError from '../../hocs/withRequestWithIdError'
import withEffect from '../../hocs/withEffect'
import withCleanFormSuccess from '../../hocs/withCleanFormSuccess'
import withIsRequestWithIdSucceeded from '../../hocs/withIsRequestWithIdSucceeded'

const enhance = compose(
  withTranslations,
  withCampaigns,
  withScope,

  withPropsOnChange(['match'], ({ match }) => ({
    campaignId: Number(match.params.campaignId) || 'no-campaign-id',
    orderId: Number(match.params.orderId) || 'no-order-id',
  })),

  withOrderDetailTabs((selectedTab, { orderId, campaignId }) =>
    ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.linkTo({
      orderId,
      campaignId,
      selectedTab,
    }),
  ),

  connect(
    (state, { orderId, scope, i18n }) => {
      const orderData = getBrandOrderDetailById(orderId, state)
      if (!orderData) return {}

      const viewProps = orderDataToViewProps(orderData, scope, i18n)
      return { orderData, ...viewProps }
    },
    { fetchOrderDetail: fetchBrandOrderDetail, changeOrderCampaign },
  ),

  lifecycle({
    componentDidMount() {
      this.props.fetchOrderDetail(this.props.orderId)
      this.props.fetchCampaigns()
    },
  }),

  // Show global spinner only the first time it is loaded
  branch(
    ({ orderData }) => isNilOrEmpty(orderData),
    withSpinnerWithId(FETCH_BRAND_ORDER_DETAIL, {
      idProp: 'orderId',
    }),
  ),

  // Show spinner only if campaigns haven't been loaded yet
  branch(
    ({ campaigns }) => isNilOrEmpty(campaigns),
    withSpinner(FETCH_CAMPAIGNS),
  ),

  withRoute404ErrorWithId(FETCH_BRAND_ORDER_DETAIL, 'orderId'),
  withRouteServerErrorWithId(FETCH_BRAND_ORDER_DETAIL, 'orderId'),

  // Replace campaignId in URL if it doesn't match order data
  withRouter,
  withEffect(
    ({ campaignId, orderId, orderData, history, match }) => {
      if (campaignId !== orderData.campaignId) {
        history.replace(
          ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.linkTo({
            campaignId: orderData.campaignId,
            orderId,
            selectedTab: match.params.selectedTab,
          }),
        )
      }
    },
    ({ campaignId, orderId, orderData, history, match }) => [
      campaignId,
      orderId,
      orderData,
      history,
      match,
    ],
  ),

  withOrderModalActions,
  withHandlers({
    onActionClick: props => action => {
      props.handleOrderAction(props.orderId, action)
    },
  }),
  withOrderDeliveryHistory,

  // Campaigns selector
  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: props => ({
      campaign: props.campaignId,
    }),

    selectFields: ['campaign'],

    validationSchema: {},

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.changeOrderCampaign(values.campaign, props.orderId)
    },
  }),

  withIsRequestWithIdPending(CHANGE_ORDER_CAMPAIGN, { idProp: 'orderId' }),
  withIsRequestWithIdSucceeded(CHANGE_ORDER_CAMPAIGN, { idProp: 'orderId' }),
  withRequestWithIdError(CHANGE_ORDER_CAMPAIGN, { idProp: 'orderId' }),
  withFormErrors(['campaign']),
  withCleanFormSuccess(['campaign']),

  withSelectCampaignAfterCreation,
  withCampaignsSelectOptions,

  withPropsOnChange(
    ['values', 'campaignId'],
    ({ values, campaignId, lastCreatedCampaignId }) => {
      if (!values.campaign) return {}

      const campaignValue = values.campaign.value

      return {
        showSaveCampaignButton:
          campaignValue !== campaignId &&
          campaignValue !== lastCreatedCampaignId,
      }
    },
  ),

  // Change order campaign without touching save button
  lifecycle({
    componentDidUpdate(prev) {
      const { lastCreatedCampaignId, changeOrderCampaign, orderId } = this.props

      if (prev.lastCreatedCampaignId !== lastCreatedCampaignId) {
        changeOrderCampaign(lastCreatedCampaignId, orderId)
      }
    },
  }),
)

export default enhance(OrderDetailView)
