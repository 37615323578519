import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import { MediaQueryPalm, MediaQueryLap } from '../shared/MediaQueries'
import BreadcrumbNavigation from '../navigation/BreadcrumbNavigation'
import BreadcrumbItem from '../navigation/BreadcrumbItem'
import {
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID,
  ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID,
} from '../../app/common/routes'
import { WEB_RESOURCE_SECTIONS } from '../../services/resources/constants'

const WebResourcesHeader = ({
  i18n,
  currentNavItem,
  reachedNavItem,
  resourceId,
  onClickItem,
}) => {
  const generalDataURL = resourceId
    ? ROUTE_MEDIA_EDIT_WEB_RESOURCES_GENERAL_DATA_BY_ID.linkTo({
        id: resourceId,
      })
    : ''

  const socialNetworksURL = resourceId
    ? ROUTE_MEDIA_EDIT_WEB_RESOURCES_SOCIAL_NETWORKS_BY_ID.linkTo({
        id: resourceId,
      })
    : ''

  const analyticsURL = resourceId
    ? ROUTE_MEDIA_EDIT_WEB_RESOURCES_ANALYTICS_BY_ID.linkTo({
        id: resourceId,
      })
    : ''

  const servicesURL = resourceId
    ? ROUTE_MEDIA_EDIT_WEB_RESOURCES_SERVICES_BY_ID.linkTo({
        id: resourceId,
      })
    : ''

  return (
    <div className="steps-layout-header">
      <div className="wrapper">
        <div className="steps-layout-header-content">
          <MediaQueryPalm>
            <BreadcrumbNavigation>
              <BreadcrumbItem
                url={generalDataURL}
                text={i18n('sections:data-short')}
                listNumber={WEB_RESOURCE_SECTIONS.GENERAL_DATA}
                onClick={onClickItem}
                isActive={currentNavItem === WEB_RESOURCE_SECTIONS.GENERAL_DATA}
                isDisabled={reachedNavItem < WEB_RESOURCE_SECTIONS.GENERAL_DATA}
              />
              <BreadcrumbItem
                url={socialNetworksURL}
                text={i18n('sections:social-networks-short')}
                listNumber={WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS}
                onClick={onClickItem}
                isActive={
                  currentNavItem === WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS
                }
                isDisabled={
                  reachedNavItem < WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS
                }
              />
              <BreadcrumbItem
                url={analyticsURL}
                text={i18n('sections:google-analytics-short')}
                listNumber={WEB_RESOURCE_SECTIONS.ANALYTICS}
                onClick={onClickItem}
                isActive={currentNavItem === WEB_RESOURCE_SECTIONS.ANALYTICS}
                isDisabled={reachedNavItem < WEB_RESOURCE_SECTIONS.ANALYTICS}
              />
              <BreadcrumbItem
                url={servicesURL}
                text={i18n('sections:services-prices-short')}
                listNumber={WEB_RESOURCE_SECTIONS.SERVICES}
                onClick={onClickItem}
                isActive={currentNavItem === WEB_RESOURCE_SECTIONS.SERVICES}
                isDisabled={reachedNavItem < WEB_RESOURCE_SECTIONS.SERVICES}
              />
            </BreadcrumbNavigation>
          </MediaQueryPalm>
          <MediaQueryLap>
            <BreadcrumbNavigation>
              <BreadcrumbItem
                url={generalDataURL}
                text={i18n('sections:data-long')}
                listNumber={WEB_RESOURCE_SECTIONS.GENERAL_DATA}
                onClick={onClickItem}
                isActive={currentNavItem === WEB_RESOURCE_SECTIONS.GENERAL_DATA}
                isDisabled={reachedNavItem < WEB_RESOURCE_SECTIONS.GENERAL_DATA}
              />
              <BreadcrumbItem
                url={socialNetworksURL}
                text={i18n('sections:social-networks-long')}
                listNumber={WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS}
                onClick={onClickItem}
                isActive={
                  currentNavItem === WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS
                }
                isDisabled={
                  reachedNavItem < WEB_RESOURCE_SECTIONS.SOCIAL_NETWORKS
                }
              />
              <BreadcrumbItem
                url={analyticsURL}
                text={i18n('sections:google-analytics-long')}
                listNumber={WEB_RESOURCE_SECTIONS.ANALYTICS}
                onClick={onClickItem}
                isActive={currentNavItem === WEB_RESOURCE_SECTIONS.ANALYTICS}
                isDisabled={reachedNavItem < WEB_RESOURCE_SECTIONS.ANALYTICS}
              />
              <BreadcrumbItem
                url={servicesURL}
                text={i18n('sections:services-prices-long')}
                listNumber={WEB_RESOURCE_SECTIONS.SERVICES}
                onClick={onClickItem}
                isActive={currentNavItem === WEB_RESOURCE_SECTIONS.SERVICES}
                isDisabled={reachedNavItem < WEB_RESOURCE_SECTIONS.SERVICES}
              />
            </BreadcrumbNavigation>
          </MediaQueryLap>
        </div>
      </div>
    </div>
  )
}

WebResourcesHeader.propTypes = {
  currentNavItem: PropTypes.number,
  reachedNavItem: PropTypes.number,
  resourceId: PropTypes.string,
  onClickItem: PropTypes.func,
}

export default withTranslations(WebResourcesHeader)
