import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { safelyRender } from '../../../src/app/utils'

const IconText = ({ isReverse, renderIcon, text }) => {
  const classes = cx('icon-text', {
    'is-reverse': isReverse,
  })
  return (
    <div className={classes}>
      {safelyRender(renderIcon, { className: 'icon' })}
      <p className="text">{text}</p>
    </div>
  )
}

IconText.defaulProps = {
  isReverse: false,
}

IconText.propTypes = {
  isReverse: PropTypes.bool,
  renderIcon: PropTypes.func.isRequired,
  text: PropTypes.string,
}

export default IconText
