import { connect } from 'react-redux'
import { compose } from 'recompose'
import * as Yup from 'yup'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { rejectOrderReview } from '../../services/orders'
import { i18nValidation } from '../common/validation'
import { REJECT_ORDER_REVIEW } from '../../services/orders/action-types'
import withRequestError from '../../hocs/withRequestError'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withFormErrors from '../../hocs/withFormErrors'
import OrderModalReviewOrderReject from '../../ui/order-modals/OrderModalReviewOrderReject'

const validationSchema = Yup.object().shape({
  comments: Yup.string().required(i18nValidation.required),
})

const enhance = compose(
  connect(
    null,
    { rejectOrderReview },
  ),
  withIsRequestPending(REJECT_ORDER_REVIEW),
  withRequestError(REJECT_ORDER_REVIEW),
  withDeleteRequestOnUnmount(REJECT_ORDER_REVIEW),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      comments: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.rejectOrderReview(props.orderId, values.comments)
    },
  }),

  withFormErrors(['comments']),
)

export default enhance(OrderModalReviewOrderReject)
