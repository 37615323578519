import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import CampaignSelector from './CampaignSelector'
import ReportsDownloadDropdown from '../dropdown/ReportsDownloadDropdown'
import AnchorButton from '../buttons/AnchorButton'
import { ReactComponent as Download2 } from './../../styles/icons/download-2.svg'

const CampaignDetailContent = ({
  title,
  selectorOptions,
  selectorValue,
  selectorErrorText,
  children,
  isLoading,
  hasHiddenStats,
  onDownloadButtonClick,
  isDownloadButtonDisabled,
  isDownloadButtonLoading,
  downloadButtonURL,
  downloadPPTURL,
  downloadPDFURL,
  isSelectorTouched,
  isSelectorDisabled,
  onSelectorBlur,
  onSelectorChange,
  onSelectorFocus,
  i18n,
}) => {
  return (
    <div className="campaign-detail-content">
      <div className="wrapper">
        {!isLoading && !hasHiddenStats && (
          <div className="campaign-detail-actions">
            {selectorOptions ? (
              <CampaignSelector
                options={selectorOptions}
                value={selectorValue}
                errorText={selectorErrorText}
                isDisabled={isSelectorDisabled}
                isTouched={isSelectorTouched}
                onBlur={onSelectorBlur}
                onChange={onSelectorChange}
                onFocus={onSelectorFocus}
              ></CampaignSelector>
            ) : (
              <h2 className="campaign-detail-content-title">{title}</h2>
            )}
            <ReportsDownloadDropdown>
              <AnchorButton
                onClick={onDownloadButtonClick}
                isLoading={isDownloadButtonLoading}
                isDisabled={isDownloadButtonDisabled}
                linkUrl={downloadPPTURL}
                isNative
                download
              >
                <Download2 className="icon rdx-button-icon" />
                <span>{i18n('noun:ppt')}</span>
              </AnchorButton>
              <AnchorButton
                onClick={onDownloadButtonClick}
                isLoading={isDownloadButtonLoading}
                isDisabled={isDownloadButtonDisabled}
                linkUrl={downloadPDFURL}
                isNative
                download
              >
                <Download2 className="icon rdx-button-icon" />
                <span>{i18n('noun:pdf')}</span>
              </AnchorButton>
            </ReportsDownloadDropdown>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

CampaignDetailContent.propTypes = {
  title: PropTypes.string,
  downloadPPTURL: PropTypes.string,
  downloadPDFURL: PropTypes.string,
  selectorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
      imgPath: PropTypes.string,
      imgAlt: PropTypes.string,
    }),
  ),
  selectorValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  selectorErrorText: PropTypes.node,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  hasHiddenStats: PropTypes.bool,
  onDownloadButtonClick: PropTypes.func,
  isDownloadButtonDisabled: PropTypes.bool,
  isDownloadButtonLoading: PropTypes.bool,
  isSelectorDisabled: PropTypes.bool,
  isSelectorTouched: PropTypes.bool,
  onSelectorBlur: PropTypes.func,
  onSelectorChange: PropTypes.func,
  onSelectorFocus: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(CampaignDetailContent)
