import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  addToFavorites,
  removeFavorite,
} from '../../services/favorites/actions'
import { isNilOrEmpty } from '../utils'

const withFavoriteHandlers = ({
  favoritesProp = 'favorites',
  resourceProp = 'item',
}) =>
  compose(
    connect(
      null,
      { addToFavorites, removeFavorite },
    ),

    withPropsOnChange([favoritesProp, resourceProp], props => {
      const favorites = props[favoritesProp]
      const itemId = props[resourceProp] ? props[resourceProp].id : null

      return !isNilOrEmpty(favorites) && itemId
        ? { isFavorite: favorites.map(f => f.id).includes(itemId) }
        : {}
    }),

    withHandlers({
      handleAddToFavorites: props => e => {
        e.preventDefault()
        e.stopPropagation()

        props.addToFavorites(props[resourceProp])
      },

      handleRemoveFavorite: props => e => {
        e.preventDefault()
        e.stopPropagation()

        props.removeFavorite(props[resourceProp].id)
      },
    }),
  )

export default withFavoriteHandlers
