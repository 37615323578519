import { connect } from 'react-redux'
import { getIsRequestWithIdPending } from '../services/communication/selectors'

const withIsRequestWithIdPending = (
  requestType,
  { idProp = 'id', outProp = 'isRequestPending' } = {},
) =>
  connect((state, ownProps) => ({
    [outProp]: getIsRequestWithIdPending(requestType, ownProps[idProp], state),
  }))

export default withIsRequestWithIdPending
