import { call } from 'redux-saga/effects'
import envConfig from '../../environments'
import { getRequest, putRequest } from '../api-call'
import { parseResponseErrors } from '../error-handling'

const BASE_ENDPOINT = `${envConfig.usersBackendUrl}/notifications`

const ENDPOINTS = {
  fetchUnreadNotifications: `${BASE_ENDPOINT}/unread`,
  readAllNotifications: `${BASE_ENDPOINT}/read`,
}

export function* fetchUnreadNotifications() {
  const response = yield call(getRequest, ENDPOINTS.fetchUnreadNotifications, {
    parseJSON: true,
  })

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* readAllOrderNotifications(orderId) {
  const response = yield call(
    putRequest,
    ENDPOINTS.readAllNotifications,
    { orderId },
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}

export function* readAllNotifications() {
  const response = yield call(
    putRequest,
    ENDPOINTS.readAllNotifications,
    {},
    {
      parseJSON: true,
    },
  )

  const parsedResponse = parseResponseErrors(response)

  return parsedResponse
}
