import React, { useMemo } from 'react'
import * as R from 'ramda'
import RatingCheckbox from '../../ui/form-elements/RatingCheckbox'
import { ReactComponent as Star } from '../../styles/icons/star.svg'
import { entitiesArrayToKeyedObject } from '../utils'
import withTranslations from '../../hocs/withTranslations'

const RatingFilter = ({ data, value, handleChange, i18n }) => {
  const memoizedData = useMemo(() => {
    const emptyData = [0, 1, 2, 3, 4, 5].map(item => ({
      key: item,
      doc_count: 0,
    }))

    const finalData = R.mergeDeepLeft(
      entitiesArrayToKeyedObject(data, 'key'),
      entitiesArrayToKeyedObject(emptyData, 'key'),
    )

    return R.values(finalData)
  }, [data])

  return memoizedData.map(item => {
    const checked = value[item.key] || false

    return (
      item.key > 0 && (
        <RatingCheckbox
          key={item.key}
          name={String(item.key)}
          id={String(item.key)}
          checked={checked}
          isDisabled={!checked && item.doc_count === 0}
          onChange={() => handleChange(String(item.key))}
        >
          {R.times(
            i => (
              <Star key={i} />
            ),
            item.key,
          )}
        </RatingCheckbox>
      )
    )
  })
}

export default withTranslations(RatingFilter)
