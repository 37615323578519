import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import CampaignDetailPanel from '../CampaignDetailPanel'
import AnalyticsKpi from '../../cards/AnalyticsKpi'
import PublicationsTableTwitch from '../tables/PublicationsTableTwitch'
import ChartCard from '../../cards/ChartCard'
import MultilineChart from '../../charts/MultilineChart'
import CampaignPreviewTwitch from '../social-previews/CampaignPreviewTwitch'
import { MAX_LEGEND_ITEMS } from '../../../services/campaigns/constants'
import { isNilOrEmpty } from '../../../app/utils'
import { containsNotNullValuesMultilineChart } from '../../../app/common/statistics-utils'

const CampaignDetailTwitchAll = ({
  previews,
  publicationsTableData,
  i18n,
  // Metrics values
  publications,
  followers,
  views,
  viewers,
  viewersData,
  onViewersLegendDelete,
  viewersLegendOptions,
  onViewersLegendOptionChange,
}) => {
  return (
    <>
      <div className="analytics-kpi-group">
        <AnalyticsKpi
          iconType="megaphone"
          value={publications}
          label={i18n('metrics:publications')}
          isTruncated
        />
        <AnalyticsKpi
          iconType="follower"
          value={followers}
          label={i18n('metrics:followers')}
          isTruncated
        />
        {views > 0 && (
          <AnalyticsKpi
            iconType="eye"
            value={views}
            label={i18n('metrics:total-views')}
            isTruncated
          />
        )}
        {viewers > 0 && (
          <AnalyticsKpi
            iconType="user"
            value={viewers}
            label={i18n('metrics:total-viewers')}
            isTruncated
          />
        )}
      </div>
      {!isNilOrEmpty(previews) ? (
        <CampaignPreviewTwitch items={previews} />
      ) : null}
      <div className="campaign-detail-section-row">
        {containsNotNullValuesMultilineChart(viewersData) && (
          <div className="viewers campaign-detail-section-cell one-whole">
            <ChartCard title={i18n('metrics:viewers')}>
              <MultilineChart
                data={viewersData}
                onLegendDelete={onViewersLegendDelete}
                legendOptions={viewersLegendOptions}
                onLegendOptionChange={onViewersLegendOptionChange}
                maxLegendItems={MAX_LEGEND_ITEMS}
              />
            </ChartCard>
          </div>
        )}
      </div>

      <CampaignDetailPanel title={i18n('noun:publications-table')}>
        <PublicationsTableTwitch
          publications={publicationsTableData}
        ></PublicationsTableTwitch>
      </CampaignDetailPanel>
    </>
  )
}

CampaignDetailTwitchAll.propTypes = {
  previews: PropTypes.arrayOf(PropTypes.any),
  publicationsTableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campaignId: PropTypes.number,
      name: PropTypes.string,
      views: PropTypes.number,
      viewers: PropTypes.number,
      date: PropTypes.instanceOf(Date),
    }),
  ),
  i18n: PropTypes.func.isRequired,
  // Metrics values
  publications: PropTypes.number,
  followers: PropTypes.number,
  views: PropTypes.number,
  viewers: PropTypes.number,
  viewersData: PropTypes.array,
  onViewersLegendDelete: PropTypes.func,
  viewersLegendOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDisabled: PropTypes.bool,
      isFixed: PropTypes.bool,
    }),
  ),
  onViewersLegendOptionChange: PropTypes.func,
}

export default withTranslations(CampaignDetailTwitchAll)
