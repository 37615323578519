import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import Paragraph from './../texts/Paragraph'
import AnchorLink from './../texts/AnchorLink'
import AnchorDownload from '../texts/AnchorDownload'
import SocialHeading from '../texts/SocialHeading'
import { RESOURCE_TYPES } from '../../services/resources/constants'
import CoobisPropTypes from '../../app/common/coobis-prop-types'

const OrderModalReviewDeliveryDownloads = ({
  webUrl,
  facebookUrl,
  twitterUrl,
  instagram,
  socialNetworkUrl,
  instagramUrl,
  instagramFilename,
  i18n,
}) => (
  <>
    {webUrl ? (
      <>
        {facebookUrl || twitterUrl ? (
          <Paragraph>
            <SocialHeading
              type={RESOURCE_TYPES.WEB}
              text={i18n('social-resource:post-url')}
            />

            <div>
              <AnchorLink text={webUrl} linkUrl={webUrl} isNative isNewTab />
            </div>
          </Paragraph>
        ) : (
          <Paragraph title={i18n('order-modal:delivery-link')}>
            <AnchorLink text={webUrl} linkUrl={webUrl} isNative isNewTab />
          </Paragraph>
        )}

        {facebookUrl ? (
          <Paragraph>
            <SocialHeading
              type={RESOURCE_TYPES.FACEBOOK}
              text={i18n('social-resource:facebook-url')}
            />

            <div>
              <AnchorLink
                text={facebookUrl}
                linkUrl={facebookUrl}
                isNative
                isNewTab
              />
            </div>
          </Paragraph>
        ) : null}

        {twitterUrl ? (
          <Paragraph>
            <SocialHeading
              type={RESOURCE_TYPES.TWITTER}
              text={i18n('social-resource:twitter-url')}
            />

            <div>
              <AnchorLink
                text={twitterUrl}
                linkUrl={twitterUrl}
                isNative
                isNewTab
              />
            </div>
          </Paragraph>
        ) : null}

        {instagram ? (
          <Paragraph>
            <SocialHeading
              type={RESOURCE_TYPES.INSTAGRAM}
              text={i18n('service-type:instagram-story')}
            />
            <div>
              <AnchorDownload urlFile={instagram} i18n={i18n} />
            </div>
          </Paragraph>
        ) : null}
      </>
    ) : null}

    {socialNetworkUrl ? (
      <Paragraph title={i18n('order-modal:delivery-link')}>
        <AnchorLink
          text={socialNetworkUrl}
          linkUrl={socialNetworkUrl}
          isNative
          isNewTab
        />
      </Paragraph>
    ) : null}

    {instagramUrl ? (
      <Paragraph title={i18n('order-modal:delivery-file')}>
        <AnchorLink
          text={i18n('action:download-file')}
          linkUrl={instagramUrl}
          isNative
          download={instagramFilename}
        />
      </Paragraph>
    ) : null}
  </>
)

OrderModalReviewDeliveryDownloads.propTypes = {
  webUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagram: CoobisPropTypes.urlFile,
  socialNetworkUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  instagramFilename: PropTypes.string,
}

export default withTranslations(OrderModalReviewDeliveryDownloads)
