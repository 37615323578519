import * as actionTypes from './action-types'

export const fetchMyInfo = () => ({
  type: actionTypes.FETCH_MY_INFO,
})

export const setLanguage = language => ({
  type: actionTypes.SET_LANGUAGE,
  payload: { language },
})
