import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Card = props => {
  const classes = cx('card', {
    'is-highlighted': props.isHighlighted,
    'is-contained': props.isContained,
  })
  return <div className={classes}>{props.children}</div>
}

Card.propTypes = {
  isHighlighted: PropTypes.bool,
  isContained: PropTypes.bool,
}

export default Card
