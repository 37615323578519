import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../hocs/withTranslations'
import Card from './Card'
import emptyMessageImage from './images/noNotifications.svg'

const NoNotificationsCard = ({ i18n }) => {
  return (
    <div className="no-notifications-card">
      <Card>
        <div className="no-notifications-card-image">
          <picture>
            <img src={emptyMessageImage} alt="Decoration" />
          </picture>
        </div>
        <div className="no-notifications-card-texts">
          <p className="no-notifications-card-title">
            {i18n('message:no-notifications')}
          </p>
        </div>
      </Card>
    </div>
  )
}

NoNotificationsCard.propTypes = {
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(NoNotificationsCard)
