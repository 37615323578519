import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import withTranslations from '../../hocs/withTranslations'
import Avatar from '../avatar/Avatar'
import UserNavigation from '../navigation/UserNavigation'
import { ReactComponent as Chevron } from '../../styles/icons/chevron-up.svg'
import { AuthScope } from '../../services/auth/business'

const UserDropdown = ({
  userName,
  userAvatar,
  userProfile,
  scope,
  onLogout,
  i18n,
  location,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const classes = cx('user-dropdown', {
    'is-collapsed': isCollapsed,
  })

  // Toggle collapsed state when clicking inside
  const toggleState = useCallback(
    () => setIsCollapsed(isCollapsed => !isCollapsed),
    [],
  )

  // Close dropdown when clicking outside
  const dropdownRef = useRef()
  const collapse = useCallback(() => setIsCollapsed(true), [])
  useOnClickOutside(dropdownRef, collapse)

  return (
    <div className={classes} ref={dropdownRef}>
      <button className="user-dropdown-button" onClick={toggleState}>
        <Avatar image={userAvatar} />
        <div className="user-dropdown-info">
          <span className="user-dropdown-name">{userName}</span>
          <span className="user-dropdown-profile">{i18n(userProfile)}</span>
        </div>
        <Chevron className="user-dropdown-icon" />
      </button>
      <div className="user-dropdown-content">
        <UserNavigation
          onClickItem={collapse}
          userName={userName}
          userProfile={userProfile}
          userAvatar={userAvatar}
          onLogout={onLogout}
          scope={scope}
          location={location}
        />
      </div>
    </div>
  )
}

UserDropdown.propTypes = {
  userName: PropTypes.string,
  userAvatar: PropTypes.string,
  userProfile: PropTypes.string,
  onLogout: PropTypes.func,
  scope: PropTypes.oneOf([AuthScope.BRAND, AuthScope.MEDIA]),
}

export default withTranslations(UserDropdown)
