import * as actionTypes from './action-types'
import { set } from 'reduken/hash'
import * as api from './api'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import {
  startRequest,
  endRequestSuccess,
  endRequestError,
} from '../communication/actions'
import { ENTITY_KEY_AFFILIATES, DOMAIN } from './constants'

function* fetchAffiliatesSaga() {
  yield put(startRequest(actionTypes.FETCH_MY_AFFILIATES))
  const response = yield call(api.fetchAffiliates)
  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_MY_AFFILIATES, response))
    return
  }

  if (!response.data) {
    yield put(
      endRequestError(
        actionTypes.FETCH_MY_AFFILIATES,
        new Error('Missing Orders'),
      ),
    )
    return
  }

  yield put(set(DOMAIN, ENTITY_KEY_AFFILIATES, response.data))
  yield put(endRequestSuccess(actionTypes.FETCH_MY_AFFILIATES))
}

export default function*() {
  yield all([takeLatest(actionTypes.FETCH_MY_AFFILIATES, fetchAffiliatesSaga)])
}
