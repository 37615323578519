import React from 'react'
import PropTypes from 'prop-types'
import withTranslations from '../../../hocs/withTranslations'
import { globalMessagesPropType } from '../../../hocs/withFormErrors'
import FormattedFormErrors from '../../FormattedFormErrors'
import MessageModalContent from '../../modals/MessageModalContent'
import Button from '@redradix/components.button'
import WarningMessage from '../../messages/WarningMessage'
import completeImage from '../images/complete-info-small.png'
import AnchorButton from '../../buttons/AnchorButton'
import { ROUTE_MEDIA_PERSONAL_DATA } from '../../../app/common/routes'

const CompleteBillingInformationModal = ({
  isAcceptButtonDisabled,
  isCancelButtonDisabled,
  globalMessages,
  onAcceptClick,
  onCancelClick,
  i18n,
}) => {
  return (
    <MessageModalContent
      imageUrl={completeImage}
      title={i18n('noun:billing-information')}
      actions={
        <>
          <div>
            <AnchorButton
              hierarchy="secondary"
              isDisabled={isAcceptButtonDisabled}
              onClick={onAcceptClick}
              linkUrl={ROUTE_MEDIA_PERSONAL_DATA.linkTo()}
            >
              {i18n('action:complete-billing-information')}
            </AnchorButton>
          </div>
          <div>
            <Button
              hierarchy="secondary"
              variant="alternate"
              isDisabled={isCancelButtonDisabled}
              onClick={onCancelClick}
            >
              {i18n('message:not-now')}
            </Button>
          </div>
        </>
      }
    >
      <p>{i18n('message:billing-information-withdraw-funds')}</p>
      {globalMessages && (
        <WarningMessage
          text={<FormattedFormErrors errors={globalMessages} />}
        />
      )}
    </MessageModalContent>
  )
}

CompleteBillingInformationModal.propTypes = {
  // withFormErrors
  globalMessages: globalMessagesPropType,

  isAcceptButtonDisabled: PropTypes.bool,
  isCancelButtonDisabled: PropTypes.bool,
  onAcceptClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(CompleteBillingInformationModal)
