import React from 'react'
import PropTypes from 'prop-types'

const InfoBlock = ({ title, children }) => {
  return (
    <div className="info-block">
      {title && <p className="info-block-title">{title}</p>}
      <div className="info-block-content">{children}</div>
    </div>
  )
}

InfoBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default InfoBlock
