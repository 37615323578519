import { connect } from 'react-redux'
import { compose } from 'recompose'

import { getAlerts, periodicFetchMyAlerts, discardAlert } from './'
import withEffect from '../../hocs/withEffect'
import { getLanguage } from '../../services/me'

export const withAlerts = connect(
  state => ({ alerts: getAlerts(state) }),
  { discardAlert },
)

export const withFetchAlerts = compose(
  connect(
    state => ({ language: getLanguage(state) }),
    { periodicFetchMyAlerts },
  ),
  withEffect(
    ({ periodicFetchMyAlerts, language }) => {
      periodicFetchMyAlerts(language)
    },
    ({ periodicFetchMyAlerts, language }) => [periodicFetchMyAlerts, language],
  ),
)
