import { compose, lifecycle, branch } from 'recompose'
import { connect } from 'react-redux'
import { getHasCompletedBillingData } from '../../services/brand'
import { deleteRequest } from '../../services/communication'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import { EDIT_BRAND_BILLING_DATA } from '../../services/brand/action-types'
import {
  openBillingDataModal,
  closeBillingDataModal,
  getIsBillingDataModalOpen,
} from '../../services/checkout'
import { identity } from 'ramda'

const withBillingDataModal = (successCallback = identity) =>
  compose(
    connect(
      state => ({
        isBillingModalOpen: getIsBillingDataModalOpen(state),
        hasBillingData: getHasCompletedBillingData(state),
      }),
      { openBillingDataModal, closeBillingDataModal, deleteRequest },
    ),

    // Close modal after submit succeeded, only if is modal open
    branch(
      props => props.isBillingModalOpen,
      compose(
        withIsRequestSucceeded(
          EDIT_BRAND_BILLING_DATA,
          'billingModalSubmitSucceeded',
        ),
        lifecycle({
          componentDidUpdate(prev) {
            const {
              billingModalSubmitSucceeded,
              closeBillingDataModal,
            } = this.props

            if (
              prev.billingModalSubmitSucceeded === false &&
              billingModalSubmitSucceeded === true
            ) {
              successCallback(this.props)
              closeBillingDataModal()
              deleteRequest(EDIT_BRAND_BILLING_DATA)
            }
          },
        }),
      ),
    ),
  )

export default withBillingDataModal
