import { useState, useEffect } from 'react'

const getCurrentSize = () => ({
  innerWidth: window.innerWidth,
  innerHeight: window.innerHeight,
  outerWidth: window.outerWidth,
  outerHeight: window.outerHeight,
})

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getCurrentSize())

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getCurrentSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [setWindowSize])

  return windowSize
}

export default useWindowSize
