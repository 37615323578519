import { compose } from 'recompose'
import { connect } from 'react-redux'

import { registerMedia } from '../../services/media'
import RegisterMedia from '../../ui/RegisterMedia'
import { REGISTER_MEDIA } from '../../services/media/action-types'
import { BasicRegisterDataSchema } from '../common/validation'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withRequestError from '../../hocs/withRequestError'
import withFormErrors from '../../hocs/withFormErrors'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'

const RegisterMediaSchema = BasicRegisterDataSchema

const enhance = compose(
  connect(
    null,
    { registerMedia },
  ),

  withEnhancedFormik({
    enableReinitialize: false,

    mapPropsToValues: () => ({
      name: 'Jane',
      surname: 'Doe',
      email: 'jane.doe@redradix.com',
      phone: '910000000',
      password: 'redradix',
      confirmPassword: 'redradix',
      acceptance: false,
    }),

    validationSchema: RegisterMediaSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      props.registerMedia({
        ...values,
        acceptance: values.acceptance ? 1 : 0,

        type: 'P', // ?
        country: 'ES', // ?
        paypal: 'paypal-editor@coobis.com', // ?
        iban: 'ES34343433344333434', // ?
        ip: '62.22.69.56', // ?
        language: 'es',
      })
    },
  }),

  withIsRequestPending(REGISTER_MEDIA),
  withRequestError(REGISTER_MEDIA),
  withDeleteRequestOnUnmount(REGISTER_MEDIA),
  withFormErrors([
    'name',
    'surname',
    'email',
    'phone',
    'password',
    'confirmPassword',
    'acceptance',
  ]),
)

export default enhance(RegisterMedia)
