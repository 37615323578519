import * as Yup from 'yup'
import * as R from 'ramda'
import {
  i18nValidation,
  FACEBOOK_URL_REGEXP,
  TWITTER_URL_REGEXP,
  INSTAGRAM_URL_REGEXP,
} from '../../app/common/validation'

import { LANGUAGES } from '../../hocs/withLanguageSelectField'
import { mapResourceTypeToSocialConnectionI18N } from './constants'

import { DESCRIPTION_MAX_CHARACTERS, RESOURCE_TYPES } from './constants'

export const webResourcesGeneralDataSchema = ({ countries, categories }) =>
  Yup.object().shape(
    {
      name: Yup.string().required(i18nValidation.required),
      url: Yup.string()
        .required(i18nValidation.required)
        .url(i18nValidation.invalidURL),
      countryId: Yup.string()
        .nullable()
        .required(i18nValidation.required)
        .oneOf(Object.keys(countries), i18nValidation.required),
      languageId: Yup.string()
        .nullable()
        .required(i18nValidation.required)
        .oneOf(LANGUAGES, i18nValidation.required),
      categoryId: Yup.string()
        .nullable()
        .required(i18nValidation.required)
        .oneOf(Object.keys(categories), i18nValidation.required),
      secondaryCategoryId: Yup.string()
        .nullable()
        .oneOf([null, ...Object.keys(categories)], i18nValidation.required),
      description: Yup.string()
        .required(i18nValidation.required)
        .max(DESCRIPTION_MAX_CHARACTERS, i18nValidation.maxLength),
      acceptsFollowLinks: Yup.boolean().required(i18nValidation.required),
      acceptsWritingArticles: Yup.boolean().required(i18nValidation.required),
      marksSponsoredContent: Yup.boolean().required(i18nValidation.required),
      holidaysBeginAt: Yup.date()
        .nullable()
        .when('holidaysEndAt', {
          is: holidaysEndAt => !R.isNil(holidaysEndAt),
          then: Yup.date()
            .nullable()
            .required(i18nValidation.beforeEndDate)
            .max(Yup.ref('holidaysEndAt'), i18nValidation.beforeEndDate),
          otherwise: Yup.date().nullable(),
        }),
      holidaysEndAt: Yup.date()
        .nullable()
        .when('holidaysBeginAt', {
          is: holidaysBeginAt => !R.isNil(holidaysBeginAt),
          then: Yup.date()
            .nullable()
            .required(i18nValidation.afterStartDate)
            .min(Yup.ref('holidaysBeginAt'), i18nValidation.afterStartDate),
          otherwise: Yup.date().nullable(),
        }),
    },
    ['holidaysBeginAt', 'holidaysEndAt'],
  )

const getURLRegexpByResourceType = resourceType => {
  switch (resourceType) {
    case RESOURCE_TYPES.FACEBOOK: {
      return /facebook.com/
    }

    case RESOURCE_TYPES.INSTAGRAM: {
      return /instagram.com/
    }

    case RESOURCE_TYPES.TWITCH: {
      return /twitch.tv/
    }

    case RESOURCE_TYPES.TIKTOK: {
      return /^https:\/\/www\.tiktok\.com\/@[\w.]+$|^https:\/\/vm\.tiktok\.com\/[\w.]+\/?/i
    }

    case RESOURCE_TYPES.TWITTER: {
      return /twitter.com/
    }

    case RESOURCE_TYPES.YOUTUBE: {
      return /youtube.com/
    }

    default: {
      throw new Error(`Unknown resource type ${resourceType}`)
    }
  }
}

export const socialResourcesGeneralDataSchema = ({
  channel,
  countries,
  categories,
  i18n,
}) =>
  Yup.object().shape(
    {
      name: Yup.string().required(i18nValidation.required),
      url: Yup.string()
        .required(i18nValidation.required)
        .url(i18nValidation.invalidURL)
        .matches(
          getURLRegexpByResourceType(channel),
          i18nValidation.invalidURL,
        ),
      countryId: Yup.string()
        .nullable()
        .required(i18nValidation.required)
        .oneOf(Object.keys(countries), i18nValidation.required),
      languageId: Yup.string()
        .nullable()
        .required(i18nValidation.required)
        .oneOf(LANGUAGES, i18nValidation.required),
      categoryId: Yup.string()
        .nullable()
        .required(i18nValidation.required)
        .oneOf(Object.keys(categories), i18nValidation.required),
      secondaryCategoryId: Yup.string()
        .nullable()
        .oneOf([null, ...Object.keys(categories)], i18nValidation.required),
      description: Yup.string()
        .required(i18nValidation.required)
        .max(DESCRIPTION_MAX_CHARACTERS, i18nValidation.maxLength),
      marksSponsoredContent: Yup.boolean().required(i18nValidation.required),
      holidaysBeginAt: Yup.date()
        .nullable()
        .when('holidaysEndAt', {
          is: holidaysEndAt => !R.isNil(holidaysEndAt),
          then: Yup.date()
            .nullable()
            .required(i18nValidation.beforeEndDate)
            .max(Yup.ref('holidaysEndAt'), i18nValidation.beforeEndDate),
          otherwise: Yup.date().nullable(),
        }),
      holidaysEndAt: Yup.date()
        .nullable()
        .when('holidaysBeginAt', {
          is: holidaysBeginAt => !R.isNil(holidaysBeginAt),
          then: Yup.date()
            .nullable()
            .required(i18nValidation.afterStartDate)
            .min(Yup.ref('holidaysBeginAt'), i18nValidation.afterStartDate),
          otherwise: Yup.date().nullable(),
        }),
      profileId: profileIdValidation(channel, i18n),
    },
    ['holidaysBeginAt', 'holidaysEndAt'],
  )

const profileIdIsRequired = channel =>
  [RESOURCE_TYPES.FACEBOOK, RESOURCE_TYPES.INSTAGRAM].includes(channel)

const profileIdValidation = (channel, i18n) =>
  !profileIdIsRequired(channel)
    ? Yup.string().nullable()
    : Yup.string()
        .nullable()
        .required(values => {
          const connectionName = i18n(
            mapResourceTypeToSocialConnectionI18N[channel],
          )
          return i18nValidation.required({ connectionName, ...values })
        })

export const webResourcesSocialNetworksSchema = Yup.object().shape({
  facebook: Yup.string()
    .url(i18nValidation.invalidURL)
    .matches(FACEBOOK_URL_REGEXP, i18nValidation.invalidURL),
  twitter: Yup.string()
    .url(i18nValidation.invalidURL)
    .matches(TWITTER_URL_REGEXP, i18nValidation.invalidURL),
  instagram: Yup.string()
    .url(i18nValidation.invalidURL)
    .matches(INSTAGRAM_URL_REGEXP, i18nValidation.invalidURL),
})

export const webResourcesAnalyticsSchema = ({ isVipAnalytics }) =>
  Yup.object().shape({
    profileId: isVipAnalytics
      ? Yup.string().nullable()
      : Yup.string()
          .nullable()
          .typeError(i18nValidation.required)
          .required(i18nValidation.required),
    pageviews: !isVipAnalytics
      ? Yup.mixed()
      : Yup.number()
          .nullable()
          .typeError(i18nValidation.required)
          .required(i18nValidation.required)
          .min(1, i18nValidation.min),
    visits: !isVipAnalytics
      ? Yup.mixed()
      : Yup.number()
          .nullable()
          .typeError(i18nValidation.required)
          .required(i18nValidation.required)
          .min(1, i18nValidation.min),
    visitors: !isVipAnalytics
      ? Yup.mixed()
      : Yup.number()
          .nullable()
          .typeError(i18nValidation.required)
          .required(i18nValidation.required)
          .min(1, i18nValidation.min),
  })
