import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import * as R from 'ramda'
import YoutubeServicesPrices from '../../ui/social-resource/YoutubeServicesPrices'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import { i18nValidation } from '../common/validation'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withFormErrors from '../../hocs/withFormErrors'
import { editSocialResourceServicesPrices } from '../../services/resources'
import { EDIT_SOCIAL_RESOURCE_SERVICES_PRICES } from '../../services/resources/action-types'
import { floatToMoney } from '../utils'
import { SERVICE_TYPES } from '../../services/orders/business'
import { calculateTotalPrice } from './util'

const validationSchema = Yup.object().shape({
  mentionEnabled: Yup.boolean().required(i18nValidation.required),
  mentionBasePrice: Yup.number().when('mentionEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min),
  }),
  mentionDiscount: Yup.number().when('mentionEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min)
      .max(100, i18nValidation.max),
  }),

  dedicatedEnabled: Yup.boolean().required(i18nValidation.required),
  dedicatedBasePrice: Yup.number().when('dedicatedEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min),
  }),
  dedicatedDiscount: Yup.number().when('dedicatedEnabled', {
    is: true,
    then: Yup.number()
      .required(i18nValidation.required)
      .min(0, i18nValidation.min)
      .max(100, i18nValidation.max),
  }),
})

const hasMentionTotalPriceError = ({ mentionTotalPrice = 0 }, touched) =>
  mentionTotalPrice < 10 &&
  (touched.mentionBasePrice || touched.mentionDiscount)

const hasDedicatedTotalPriceError = ({ dedicatedTotalPrice = 0 }, touched) =>
  dedicatedTotalPrice < 10 &&
  (touched.dedicatedBasePrice || touched.dedicatedDiscount)

const calculatePrices = values => ({
  mentionTotalPrice: calculateTotalPrice(
    values.mentionBasePrice,
    values.mentionDiscount,
  ),
  dedicatedTotalPrice: calculateTotalPrice(
    values.dedicatedBasePrice,
    values.dedicatedDiscount,
  ),
})

const hasCalculatedPricesErrors = values => {
  const calculatedValues = calculatePrices(values)
  const { mentionEnabled, dedicatedEnabled } = values

  const touched = {
    mentionBasePrice: true,
    mentionDiscount: true,
    dedicatedBasePrice: true,
    dedicatedDiscount: true,
  }

  return (
    (mentionEnabled && hasMentionTotalPriceError(calculatedValues, touched)) ||
    (dedicatedEnabled && hasDedicatedTotalPriceError(calculatedValues, touched))
  )
}

const enhance = compose(
  connect(
    null,
    {
      editSocialResourceServicesPrices,
    },
  ),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ resourceData }) => ({
      mentionBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.YOUTUBE_MENTION, 'basePrice'],
        resourceData,
      ),
      mentionDiscount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.YOUTUBE_MENTION, 'discount'],
        resourceData,
      ),
      mentionEnabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.YOUTUBE_MENTION, 'enabled'],
            resourceData,
          ),
      mentionCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.YOUTUBE_MENTION, 'currency'],
        resourceData,
      ),
      dedicatedBasePrice: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.YOUTUBE_DEDICATED, 'basePrice'],
        resourceData,
      ),
      dedicatedDiscount: R.pathOr(
        0,
        ['prices', SERVICE_TYPES.YOUTUBE_DEDICATED, 'discount'],
        resourceData,
      ),
      dedicatedEnabled: resourceData.blocked
        ? false
        : R.pathOr(
            true,
            ['prices', SERVICE_TYPES.YOUTUBE_DEDICATED, 'enabled'],
            resourceData,
          ),
      dedicatedCurrency: R.pathOr(
        true,
        ['prices', SERVICE_TYPES.YOUTUBE_DEDICATED, 'currency'],
        resourceData,
      ),
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      if (hasCalculatedPricesErrors(values)) {
        return
      }

      const { mentionEnabled, dedicatedEnabled } = values

      const valuesToSend = [
        mentionEnabled
          ? {
              serviceType: SERVICE_TYPES.YOUTUBE_MENTION,
              enabled: true,
              basePrice: floatToMoney(
                values.mentionBasePrice,
                values.mentionCurrency,
              ),
              discount: values.mentionDiscount,
            }
          : {
              serviceType: SERVICE_TYPES.YOUTUBE_MENTION,
              enabled: false,
            },
        dedicatedEnabled
          ? {
              serviceType: SERVICE_TYPES.YOUTUBE_DEDICATED,
              enabled: true,
              basePrice: floatToMoney(
                values.dedicatedBasePrice,
                values.dedicatedCurrency,
              ),
              discount: values.dedicatedDiscount,
            }
          : {
              serviceType: SERVICE_TYPES.YOUTUBE_DEDICATED,
              enabled: false,
            },
      ]

      props.editSocialResourceServicesPrices(
        props.resourceId,
        props.channel,
        valuesToSend,
      )
    },
  }),

  withIsRequestPending(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withIsRequestSucceeded(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withRequestError(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),
  withDeleteRequestOnUnmount(EDIT_SOCIAL_RESOURCE_SERVICES_PRICES),

  withFormErrors([
    'mentionBasePrice',
    'mentionDiscount',
    'mentionEnabled',
    'dedicatedBasePrice',
    'dedicatedDiscount',
    'dedicatedEnabled',
  ]),

  // Change final prices when changes offer
  withPropsOnChange(['values'], ({ values }) => ({
    calculatedValues: calculatePrices(values),
  })),

  // Inject calculatedPrices errors
  withPropsOnChange(
    ['calculatedValues', 'validationMessages', 'touched', 'values'],
    ({ calculatedValues, validationMessages, touched, values }) => {
      const { mentionEnabled, dedicatedEnabled } = values

      const showMentionTotalPriceError = hasMentionTotalPriceError(
        calculatedValues,
        touched,
      )

      const showDedicatedTotalPriceError = hasDedicatedTotalPriceError(
        calculatedValues,
        touched,
      )

      return {
        validationMessages: {
          ...validationMessages,
          mentionTotalPrice:
            showMentionTotalPriceError && mentionEnabled
              ? [i18nValidation.min({ path: 'mentionTotalPrice', min: 10 })]
              : undefined,
          dedicatedTotalPrice:
            showDedicatedTotalPriceError && dedicatedEnabled
              ? [i18nValidation.min({ path: 'dedicatedTotalPrice', min: 10 })]
              : undefined,
        },
      }
    },
  ),
)

export default enhance(YoutubeServicesPrices)
