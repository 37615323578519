import { connect } from 'react-redux'
import { compose, lifecycle, withProps } from 'recompose'
import { getCart, deleteCart, getDatesConfig, fetchDatesConfig } from './'
import { getReachedNavItem, STEPPER_NAV_ITEMS } from './business'
import {
  ROUTE_BRAND_CHECKOUT_BRIEFING,
  ROUTE_BRAND_CHECKOUT_GENERAL_DATA,
  ROUTE_BRAND_DASHBOARD,
  ROUTE_BRAND_CHECKOUT_PAYMENT,
} from '../../app/common/routes'
import { isNilOrEmpty } from '../../app/utils'
import { FETCH_DATES_CONFIG, SUBMIT_PAYMENT } from './action-types'
import withSpinner from '../../hocs/withSpinner'
import withIsRequestPending from '../../hocs/withIsRequestPending'

export const withCart = connect(
  state => ({
    cart: getCart(state),
  }),
  {
    deleteCart,
  },
)

export const withDatesConfig = compose(
  connect(
    state => ({
      datesConfig: getDatesConfig(state),
    }),
    {
      fetchDatesConfig,
    },
  ),

  lifecycle({
    componentDidMount() {
      if (isNilOrEmpty(this.props.datesConfig)) {
        this.props.fetchDatesConfig()
      }
    },
  }),
)

const NAV_ITEM_ROUTES = {
  [STEPPER_NAV_ITEMS.NO_CART]: ROUTE_BRAND_DASHBOARD.linkTo(),
  [STEPPER_NAV_ITEMS.GENERAL]: ROUTE_BRAND_CHECKOUT_GENERAL_DATA.linkTo(),
  [STEPPER_NAV_ITEMS.BRIEFINGS]: ROUTE_BRAND_CHECKOUT_BRIEFING.linkTo(),
  [STEPPER_NAV_ITEMS.PAYMENT]: ROUTE_BRAND_CHECKOUT_PAYMENT.linkTo(),
}

const REACHED_CHECKOUT_STEP_PAYMENT_PENDING_PROP =
  'withReachedCheckoutStep/isPaymentPending'

export const withReachedCheckoutStep = currentNavItem =>
  compose(
    withDatesConfig,
    withSpinner(FETCH_DATES_CONFIG),
    withProps(({ cart, datesConfig }) => ({
      reachedNavItem: getReachedNavItem(cart, datesConfig),
    })),
    withIsRequestPending(
      SUBMIT_PAYMENT,
      REACHED_CHECKOUT_STEP_PAYMENT_PENDING_PROP,
    ),
    lifecycle({
      componentDidMount() {
        const { reachedNavItem, history } = this.props

        if (reachedNavItem < currentNavItem) {
          history.replace(NAV_ITEM_ROUTES[reachedNavItem])
        }
      },
      // Exit checkout process when empty cart
      // Exception: if payment is pending the cart is empty for a second but
      // we will get routed to campaigns
      componentDidUpdate(prev) {
        const {
          cart,
          history,
          [REACHED_CHECKOUT_STEP_PAYMENT_PENDING_PROP]: isPaymentPending,
        } = this.props

        if (
          !isPaymentPending &&
          !isNilOrEmpty(prev.cart.items) &&
          isNilOrEmpty(cart.items)
        ) {
          history.replace(ROUTE_BRAND_DASHBOARD.linkTo())
        }
      },
    }),
  )
