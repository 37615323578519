import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import CoobisLogo from './logo-coobis.png'
import { ReactComponent as NavbarLauncher } from './navbar-launcher.svg'
import { ReactComponent as ButtonArrow } from './button-arrow.svg'
import { ReactComponent as Globe } from './globe.svg'
import { ReactComponent as Close } from './close.svg'
import ImgLanguageEnglish from '../form-elements/images/flags/icon-language-gb.svg'
import ImgLanguageSpanish from '../form-elements/images/flags/icon-language-sp.svg'
import ImgLanguageItalian from '../form-elements/images/flags/icon-language-it.svg'
import ImgLanguageFrench from '../form-elements/images/flags/icon-language-fr.svg'
import { ROUTE_ROOT } from '../../app/common/routes'
import withTranslations from '../../hocs/withTranslations'
import LOCALES from '../../config/locales'

const FLAGS = {
  es: ImgLanguageSpanish,
  en: ImgLanguageEnglish,
  fr: ImgLanguageFrench,
  it: ImgLanguageItalian,
}

const LANGUAGES = LOCALES.map(item => item.locale)

const HeaderPublic = ({ language, setLanguage, i18n }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isLanguageOpen, setIsLanguageOpen] = useState(false)
  const [isNavOpen, setIsNavOpen] = useState(false)

  const dropdownOpen = useCallback(() => setIsDropdownOpen(true), [])
  const languageOpen = useCallback(() => setIsLanguageOpen(true), [])
  const toggleNav = useCallback(() => setIsNavOpen(isNavOpen => !isNavOpen), [])

  const dropdownClasses = cx('button primary-button', {
    'is-open': isDropdownOpen,
  })
  const languageClasses = cx('language-button language-launcher', {
    'is-open': isLanguageOpen,
  })
  const navbarClasses = cx('public-header-navbar', { 'is-open': isNavOpen })

  const dropdownRef = useRef()
  const languageRef = useRef()

  const closeDropdown = useCallback(() => setIsDropdownOpen(false), [])
  const closeLanguage = useCallback(() => setIsLanguageOpen(false), [])

  useOnClickOutside(dropdownRef, closeDropdown)
  useOnClickOutside(languageRef, closeLanguage)

  return (
    <header className="public-header">
      <div className="public-header-wrapper block-wrapper">
        <Link className="coobis-logo" to={ROUTE_ROOT.linkTo()}>
          <img src={CoobisLogo} alt="Coobis logo" />
        </Link>
        <div className="navbar-launcher" onClick={toggleNav}>
          <div className="icon-wrapper">
            <NavbarLauncher />
          </div>
        </div>
        <div className={navbarClasses}>
          <Link className="coobis-logo" to={ROUTE_ROOT.linkTo()}>
            <img src={CoobisLogo} alt="Coobis logo" />
          </Link>
          <div className="navbar-launcher is-closer" onClick={toggleNav}>
            <div className="icon-wrapper">
              <Close />
            </div>
          </div>
          <a
            className="public-header-link link"
            href={i18n('public:blog-link')}
          >
            {i18n('public:blog')}
          </a>
          <Link className="public-header-link link" to={ROUTE_ROOT.linkTo()}>
            {i18n('login:login')}
          </Link>
          <button className={dropdownClasses} onClick={dropdownOpen}>
            <span className="button-text">
              {i18n('public:action-register')}
            </span>
            <div className="icon-wrapper">
              <ButtonArrow />
            </div>
          </button>
          <div className="public-header-dropdown" ref={dropdownRef}>
            <a
              className="public-header-link link"
              onClick={closeDropdown}
              href={i18n('public:action-brand-register-link')}
            >
              {i18n('public:action-brand-register')}
            </a>
            <a
              className="public-header-link link"
              onClick={closeDropdown}
              href={i18n('public:action-media-register-link')}
            >
              {i18n('public:action-media-register')}
            </a>
          </div>
          <button className={languageClasses} onClick={languageOpen}>
            <div className="icon-wrapper">
              <Globe />
            </div>
            <span className="button-text">
              {i18n(`public:language-short-${language}`)}
            </span>
          </button>
          <div className="public-header-dropdown" ref={languageRef}>
            {LANGUAGES.map(language => (
              <button
                className="language-button"
                key={language}
                onClick={() => {
                  setLanguage(language)
                  closeLanguage()
                }}
              >
                <img src={FLAGS[language]} className="flag" alt="flag" />
                <span className="button-text">
                  {i18n(`language:${language}`)}
                </span>
              </button>
            ))}
          </div>
        </div>
        <div className="public-header-fade" onClick={toggleNav}></div>
      </div>
    </header>
  )
}

HeaderPublic.propTypes = {
  language: PropTypes.oneOf(LANGUAGES),
  setLanguage: PropTypes.func,
}

export default withTranslations(HeaderPublic)
