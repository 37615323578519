import React from 'react'
import PropTypes from 'prop-types'
import CampaignDetailSlider from '../CampaignDetailSlider'
import CampaignDetailSlide from '../CampaignDetailSlide'

const CampaignPreviewTwitch = ({ items }) => {
  return (
    <CampaignDetailSlider>
      {items.map((item, i) => (
        <CampaignDetailSlide type="twitch" key={i}>
          <div dangerouslySetInnerHTML={{ __html: item }}></div>
        </CampaignDetailSlide>
      ))}
    </CampaignDetailSlider>
  )
}

CampaignPreviewTwitch.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
}

export default CampaignPreviewTwitch
