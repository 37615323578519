import { connect } from '@appbaseio/reactivesearch/lib/utils'
import { pathOr } from 'ramda'

const withResultsCount = connect(
  state => ({
    resultsCount: pathOr(null, ['hits', 'searchResult', 'total'], state),
  }),
  {},
)

export default withResultsCount
