import { compose, withPropsOnChange, withStateHandlers } from 'recompose'
import * as R from 'ramda'
import { memoize1NAry } from '../utils'

const MAX_COUNTRIES = 12

const withCountryWidget = ({ dataPath, perPage = 4 }) =>
  compose(
    withPropsOnChange(
      (props, nextProps) =>
        !R.equals(
          {
            data: R.pathOr([], dataPath, nextProps),
            translatedCountries: R.path(['translatedCountries'], nextProps),
            i18n: R.path(['i18n'], nextProps),
          },
          {
            data: R.pathOr([], dataPath, props),
            translatedCountries: R.path(['translatedCountries'], props),
            i18n: R.path(['i18n'], props),
          },
        ),

      ({ translatedCountries, i18n, ...props }) => {
        const countryDemographics = R.path(dataPath, props) || []
        const countryWidgetData = getCountryWidgetData(
          countryDemographics,
          translatedCountries,
        )
        return {
          countryWidgetData,
          countryWidgetTotal: countryWidgetData.length,
        }
      },
    ),
    withStateHandlers(() => ({ countryWidgetPage: 0 }), {
      onPrevCountryHandler: ({ countryWidgetPage }) => _ => ({
        countryWidgetPage: Math.max(countryWidgetPage - 1, 0),
      }),
      onNextCountryHandler: ({ countryWidgetPage }) => _ => ({
        countryWidgetPage: Math.min(
          countryWidgetPage + 1,
          MAX_COUNTRIES / perPage - 1,
        ),
      }),
    }),
    withPropsOnChange(
      ['countryWidgetData', 'countryWidgetPage'],
      ({ countryWidgetData, countryWidgetPage }) => {
        const lastInCurrentPage = (countryWidgetPage + 1) * perPage
        const currentPageData = countryWidgetData.slice(
          countryWidgetPage * perPage,
          lastInCurrentPage,
        )
        return {
          countryWidgetData: currentPageData,
          isPrevCountryDisabled: countryWidgetPage === 0,
          isNextCountryDisabled: lastInCurrentPage >= countryWidgetData.length,
        }
      },
    ),
  )

const getCountryWidgetData = memoize1NAry(
  (countryDemographics, translatedCountries) => {
    const countryDensityData = parseDensityData(countryDemographics)
    return R.pipe(
      R.filter(R.propSatisfies(d => d > 0, 'density')),
      R.sort(R.descend(R.prop('density'))),
      R.slice(0, MAX_COUNTRIES),
      R.map(({ country, density }) => {
        const { label } = translatedCountries.find(t => t.id === country) || {}
        return { id: country, label: label || country, value: density }
      }),
    )(countryDensityData)
  },
)

const parseDensityData = countryDemographics =>
  R.is(Array, countryDemographics)
    ? countryDemographics
    : R.pipe(
        R.toPairs,
        R.map(([country, density]) => ({ country, density })),
      )(countryDemographics)

export default withCountryWidget
