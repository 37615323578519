import * as actionTypes from './action-types'
import * as api from './api'
import * as hash from 'reduken/hash'
import {
  all,
  call,
  put,
  select,
  delay,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects'
import { getAlerts } from './selectors'
import {
  startRequest,
  startRequestWithId,
  endRequestSuccess,
  endRequestWithIdSuccess,
  endRequestError,
  endRequestWithIdError,
} from '../communication/actions'
import {
  DOMAIN,
  HASH_KEY_ALERTS_DATA,
  ALERTS_REFRESH_TIME,
  ALERT_TRANSITION_MSECS,
} from './constants'

import repeatingFetchEffect from '../repeating-fetch-effect'
import { periodicFetchMyAlerts } from './actions'

export function* fetchMyAlertsSaga({ payload: { language } }) {
  yield put(startRequest(actionTypes.FETCH_MY_ALERTS))

  const response = yield call(api.fetchAlerts, language)

  if (response.error) {
    yield put(endRequestError(actionTypes.FETCH_MY_ALERTS, response))
    return
  }

  yield put(
    hash.set(
      DOMAIN,
      HASH_KEY_ALERTS_DATA,
      response.data.map(a => ({ ...a, isVisible: true })),
    ),
  )

  yield put(endRequestSuccess(actionTypes.FETCH_MY_ALERTS))
}

export function* discardAlertSaga({ payload: { alertId } }) {
  yield put(startRequestWithId(actionTypes.DISCARD_ALERT, alertId))

  const response = yield call(api.markAlertAsRead, alertId)

  if (response.error) {
    yield put(endRequestWithIdError(actionTypes.DISCARD_ALERT, alertId))
    return
  }

  const alerts = yield select(getAlerts)

  // Start close animation
  const alertVisibility = alerts.map(alert =>
    alert.id !== alertId ? alert : { ...alert, isVisible: false },
  )
  yield put(hash.set(DOMAIN, HASH_KEY_ALERTS_DATA, alertVisibility))

  // Wait for close animation to finish
  yield delay(ALERT_TRANSITION_MSECS)

  // Remove alert from state
  const filteredAlerts = alerts.filter(alert => alert.id !== alertId)
  yield put(hash.set(DOMAIN, HASH_KEY_ALERTS_DATA, filteredAlerts))

  yield put(endRequestWithIdSuccess(actionTypes.DISCARD_ALERT, alertId))
}

export default function*() {
  yield all([
    takeLatest(actionTypes.FETCH_MY_ALERTS, fetchMyAlertsSaga),
    takeLeading(actionTypes.DISCARD_ALERT, discardAlertSaga),
    repeatingFetchEffect({
      fetchSaga: fetchMyAlertsSaga,
      intervalMillisecs: ALERTS_REFRESH_TIME,
      startAction: periodicFetchMyAlerts(),
      requestActionType: actionTypes.FETCH_MY_ALERTS,
    }),
  ])
}
