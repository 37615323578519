import React from 'react'
import {
  compose,
  branch,
  withHandlers,
  withPropsOnChange,
  lifecycle,
  withState,
} from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import SocialResource from '../../ui/social-resource/SocialResource'
import SocialResourceGeneralDataForm from '../../ui/social-resource/SocialResourceGeneralDataForm'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import withIsRequestPending from '../../hocs/withIsRequestPending'
import withIsRequestSucceeded from '../../hocs/withIsRequestSucceeded'
import withRequestError from '../../hocs/withRequestError'
import withTranslations from '../../hocs/withTranslations'
import {
  SOCIAL_RESOURCE_SECTIONS,
  RESOURCE_TYPES,
  SOCIAL_SERVICES,
} from '../../services/resources/constants'
import {
  CREATE_SOCIAL_RESOURCE,
  EDIT_SOCIAL_RESOURCE_GENERAL_DATA,
  FETCH_SOCIAL_DATA,
} from '../../services/resources/action-types'
import {
  createSocialResource,
  editSocialResourceGeneralData,
  getSocialData,
  fetchSocialData,
} from '../../services/resources'
import withDeleteRequestOnUnmount from '../../hocs/withDeleteRequestOnUnmount'
import withLanguageSelectField, {
  LANGUAGES,
} from '../../hocs/withLanguageSelectField'
import withCountrySelectField from '../../hocs/withCountrySelectField'
import withCategoriesSelectField from '../../hocs/withCategoriesSelectField'
import { getSocialResourcesGeneralData } from '../../services/resources/business'
import { socialResourcesGeneralDataSchema } from '../../services/resources/validation'
import { withUploadedImage } from '../../services/resources/hocs'
import withImageField from './withImageField'
import withHolidays from './withHolidays'
import { withCountries, withCategories } from '../../services/config/hocs'
import withSocialResource from './withSocialResource'
import withTemporaryResourceId from './withTemporaryResourceId'
import withSocialConnect from './withSocialConnect'
import withIsVipSocialNetworks from './withIsVipSocialNetworks'
import withHasRequestError from '../../hocs/withHasRequestError'
import withRouteServerError from '../../hocs/withRouteServerError'
import withEffect from '../../hocs/withEffect'
import withProfileSelectModal from './withProfileSelectModal'
import Modal from '../common/Modal'
import TableSocialProfileSynchronizeData from '../../ui/tables/TableSocialProfileSynchronizeData'
import withValidationMessagePromotedToGlobalMessage from './withValidationMessagePromotedToGlobalMessage'
import queryString from 'query-string'
import { i18nValidation } from '../common/validation'

const SocialResourceGeneralData = ({ i18n, ...props }) => (
  <SocialResource
    title={i18n('sections:data-long')}
    currentNavItem={SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA}
    reachedNavItem={props.reachedNavItem}
    resourceId={props.resourceId}
    channel={props.channel}
    onClickConnect={props.onClickConnect}
    isConnected={props.isConnected}
    isConnectionPending={props.isRequestingSocialConnect}
    isConnectionDisabled={props.isRequestPending}
    hasSocialHeader
  >
    <SocialResourceGeneralDataForm
      {...props}
      isDisabled={props.isFormDisabled}
    />

    {props.isSynchronizeModalOpen && (
      <Modal onClose={props.onProfileSelectCancel}>
        <TableSocialProfileSynchronizeData
          tableData={props.tableData}
          onSelect={props.onProfileSelect}
        />
      </Modal>
    )}
  </SocialResource>
)

const withFetchResourceData = compose(
  connect(
    (state, ownProps) => {
      const socialData = getSocialData(
        ownProps.channel,
        ownProps.lastTemporaryResourceId,
        state,
      )

      return socialData
        ? {
            socialData: {
              name: socialData.resourceName,
              url: socialData.resourceUrl,
            },
          }
        : {}
    },
    (dispatch, ownProps) => ({
      fetchSocialData: () =>
        dispatch(
          fetchSocialData(ownProps.lastTemporaryResourceId, ownProps.channel),
        ),
    }),
  ),

  withIsRequestPending(FETCH_SOCIAL_DATA, 'isRequestingSocialData'),
  withHasRequestError(FETCH_SOCIAL_DATA, 'hasSocialDataRequestError'),
  withIsRequestSucceeded(FETCH_SOCIAL_DATA, 'isSocialDataRequestSucceeded'),
  withDeleteRequestOnUnmount(FETCH_SOCIAL_DATA),
  withRouteServerError(FETCH_SOCIAL_DATA),

  withHandlers({
    maybeFetchProfiles: props => _ => {
      const {
        temporaryResourceId,
        isRequestingSocialData,
        isSocialDataRequestSucceeded,
        hasSocialDataRequestError,
      } = props

      if (
        !!temporaryResourceId &&
        !isRequestingSocialData &&
        !isSocialDataRequestSucceeded &&
        !hasSocialDataRequestError
      ) {
        props.fetchSocialData()
      }
    },
  }),

  lifecycle({
    componentDidMount() {
      this.props.maybeFetchProfiles()
    },

    componentDidUpdate(prevProps) {
      this.props.maybeFetchProfiles()
    },
  }),

  withEffect(
    ({ socialData, setFieldValue, maybeRequestScreenshot }) => {
      if (!socialData) return

      setFieldValue('name', socialData.name, true)
      setFieldValue('url', socialData.url, true)
      maybeRequestScreenshot(socialData.url)
    },
    ({ socialData, setFieldValue, maybeRequestScreenshot }) => [
      socialData,
      setFieldValue,
      maybeRequestScreenshot,
    ],
  ),
)

const withSocialProfileModal = (socialService, profileMapper) =>
  compose(
    withProfileSelectModal(socialService),
    withPropsOnChange(['socialProfiles'], ({ socialProfiles }) => ({
      tableData:
        !!socialProfiles && socialProfiles.length > 0
          ? socialProfiles.map(profileMapper)
          : [],
    })),
  )

const withSocialProfileSelectValues = compose(
  withState('selectedProfileId', 'setSelectedProfileId'),
  withHandlers({
    onProfileSelect: props => profileId => {
      const profileData = props.socialProfiles.find(
        profile => profile.id === profileId,
      )
      if (!profileData) {
        throw new Error('Could not find selected profile')
      }
      props.setSelectedProfileId(profileData.id)
      props.setFieldValue('profileId', profileData.id)
      props.setFieldValue('name', profileData.name, true)
      props.setFieldValue('url', profileData.link, true)
      props.maybeRequestScreenshot(profileData.link)
      props.onSynchronizeModalClose()
    },
    onProfileSelectCancel: props => () => {
      props.setSelectedProfileId('')
      props.setFieldValue('profileId', '')
      props.setFieldValue('name', '', true)
      props.setFieldValue('url', '', true)
      props.onSynchronizeModalClose()
    },
  }),

  // If there is only a single page/profile, select it
  withEffect(
    ({ selectedProfileId, socialProfiles, onProfileSelect }) => {
      if (
        R.isNil(selectedProfileId) &&
        !!socialProfiles &&
        socialProfiles.length === 1
      ) {
        onProfileSelect(socialProfiles[0].id)
      }
    },
    ({ selectedProfileId, socialProfiles, onProfileSelect }) => [
      selectedProfileId,
      socialProfiles,
      onProfileSelect,
    ],
  ),
)

const withFacebookProfileModal = withSocialProfileModal(
  SOCIAL_SERVICES.FACEBOOK,
  profile => ({
    id: profile.id,
    profile: profile.name,
    link: profile.link,
    fanCount: profile.fan_count,
  }),
)

const withInstagramBusinessProfileModal = withSocialProfileModal(
  SOCIAL_SERVICES.INSTAGRAM_BUSINESS,
  profile => ({
    id: profile.id,
    profile: profile.name,
    link: profile.link,
    fanCount: profile.followers_count,
  }),
)

const enhance = compose(
  withTranslations,

  withSocialResource(SOCIAL_RESOURCE_SECTIONS.GENERAL_DATA),

  withTemporaryResourceId,
  withSocialConnect(),
  withPropsOnChange(['requestSocialConnect'], ({ requestSocialConnect }) => ({
    onConnectNetwork: requestSocialConnect,
  })),
  withIsVipSocialNetworks,

  branch(
    ({ channel }) => channel === RESOURCE_TYPES.FACEBOOK,
    withFacebookProfileModal,
  ),

  branch(
    ({ channel }) => channel === RESOURCE_TYPES.INSTAGRAM,
    withInstagramBusinessProfileModal,
  ),

  branch(
    ({ isEditing }) => !isEditing,

    // Creation
    compose(
      withIsRequestPending(CREATE_SOCIAL_RESOURCE),
      withIsRequestSucceeded(CREATE_SOCIAL_RESOURCE),
      withRequestError(CREATE_SOCIAL_RESOURCE),
      withDeleteRequestOnUnmount(CREATE_SOCIAL_RESOURCE),
      connect(
        null,
        (dispatch, ownProps) => ({
          submitForm: data => {
            dispatch(createSocialResource(ownProps.channel, data))
          },
        }),
      ),
    ),

    // Edition
    compose(
      withIsRequestPending(EDIT_SOCIAL_RESOURCE_GENERAL_DATA),
      withIsRequestSucceeded(EDIT_SOCIAL_RESOURCE_GENERAL_DATA),
      withRequestError(EDIT_SOCIAL_RESOURCE_GENERAL_DATA),
      withDeleteRequestOnUnmount(EDIT_SOCIAL_RESOURCE_GENERAL_DATA),
      connect(
        null,
        (dispatch, ownProps) => ({
          submitForm: data => {
            dispatch(
              editSocialResourceGeneralData(
                ownProps.resourceId,
                ownProps.channel,
                data,
              ),
            )
          },
        }),
      ),
    ),
  ),

  withUploadedImage,

  withCountries,
  withCategories,
  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: ({ isEditing, resourceData }) =>
      isEditing
        ? getSocialResourcesGeneralData({ resourceData })
        : {
            name: '',
            url: '',
            countryId: null,
            languageId: null,
            categoryId: null,
            secondaryCategoryId: null,
            description: '',
            marksSponsoredContent: true,
            holidaysBeginAt: null,
            holidaysEndAt: null,
            profileId: '',
          },

    selectFields: [
      'countryId',
      'languageId',
      'categoryId',
      'secondaryCategoryId',
    ],
    dateFields: ['holidaysBeginAt', 'holidaysEndAt'],

    validationSchema: socialResourcesGeneralDataSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {
      const valuesToSend = {
        ...values,
        fileName: props.lastTemporaryImage
          ? props.lastTemporaryImage.fileName
          : undefined,
        holidaysBeginAt: undefined,
        holidaysEndAt: undefined,
        holidays: {
          beginAt: values.holidaysBeginAt,
          endAt: values.holidaysEndAt,
        },
        type: props.channel,
        temporaryResourceId: props.lastTemporaryResourceId,
      }

      props.submitForm(valuesToSend)
    },
  }),

  withHandlers({
    // Make marksSponsoredContent boolean value
    handleChange: props => (...args) => {
      const fieldName = args[0].target.name

      if (fieldName === 'marksSponsoredContent') {
        return props.handleChange(
          {
            target: {
              name: fieldName,
              value: args[0].target.value === 'true' ? true : false,
            },
          },
          ...args.slice(1),
        )
      }

      return props.handleChange(...args)
    },
  }),

  withHolidays,

  withFormErrors(
    [
      'name',
      'url',
      'countryId',
      'languageId',
      'categoryId',
      'secondaryCategoryId',
      'description',
      'marksSponsoredContent',
      'holidaysBeginAt',
      'holidaysEndAt',
      'profileId',
    ],
    { formikValidateOnSubmit: true },
  ),

  withValidationMessagePromotedToGlobalMessage(
    'profileId',
    'errors:required',
    i18nValidation.requireSocialProfileIdSelected,
  ),

  withCountrySelectField({ valueField: 'countryId' }),
  withLanguageSelectField(LANGUAGES, { valueField: 'languageId' }),
  withCategoriesSelectField({ categoryValueField: 'categoryId' }),
  withCategoriesSelectField({ categoryValueField: 'secondaryCategoryId' }),

  withImageField,
  withFetchResourceData,

  withPropsOnChange(
    ['isRequestingSocialConnect', 'isRequestingSocialData'],
    ({ isRequestingSocialConnect, isRequestingSocialData }) => ({
      isRequestingSocialConnect:
        isRequestingSocialConnect || isRequestingSocialData,
    }),
  ),

  withPropsOnChange(
    ['resourceData', 'lastTemporaryResourceId'],
    ({ resourceData, lastTemporaryResourceId }) => ({
      isConnected:
        (!!resourceData &&
          !!resourceData.stats &&
          !!resourceData.stats.isConnected) ||
        !!lastTemporaryResourceId,
    }),
  ),

  withPropsOnChange(
    [
      'isVipSocialNetworks',
      'lastTemporaryResourceId',
      'isEditing',
      'isRequestingSocialConnect',
      'isRequestingSocialData',
    ],
    ({
      isVipSocialNetworks,
      lastTemporaryResourceId,
      isEditing,
      isRequestingSocialConnect,
      isRequestingSocialData,
      channel,
    }) => ({
      isVipSocialNetworks:
        isVipSocialNetworks || channel === RESOURCE_TYPES.TIKTOK,
      isFormDisabled:
        ((!isVipSocialNetworks && !lastTemporaryResourceId && !isEditing) ||
          isRequestingSocialConnect ||
          isRequestingSocialData) &&
        channel !== RESOURCE_TYPES.TIKTOK,
    }),
  ),

  withPropsOnChange(['isConnected'], ({ isConnected, ...props }) => ({
    onClickConnect: !!isConnected
      ? () => {
          const query = queryString.parse(props.location.search)
          delete query.temporaryResourceId
          const queryWithoutTemporaryResourceId = queryString.stringify(query)

          props.history.replace({
            ...props.location,
            search: queryWithoutTemporaryResourceId,
          })
          props.onConnectNetwork()
        }
      : props.onConnectNetwork,
  })),

  branch(
    ({ channel }) =>
      [RESOURCE_TYPES.FACEBOOK, RESOURCE_TYPES.INSTAGRAM].includes(channel),
    withSocialProfileSelectValues,
  ),
)

export default enhance(SocialResourceGeneralData)
