import { compose, branch } from 'recompose'
import withRoute404ErrorWithId from '../../hocs/withRoute404ErrorWithId'
import withRouteServerErrorWithId from '../../hocs/withRouteServerErrorWithId'
import { FETCH_CAMPAIGN_SUMMARY_BY_ID } from '../../services/campaigns/action-types'
import { connect } from 'react-redux'
import {
  getCampaignSummaryById,
  fetchCampaignSummaryById,
} from '../../services/campaigns'
import withEffect from '../../hocs/withEffect'
import withSpinnerWithId from '../../hocs/withSpinnerWithId'

const withCampaignSummary = compose(
  withRoute404ErrorWithId(FETCH_CAMPAIGN_SUMMARY_BY_ID, 'campaignId'),
  withRouteServerErrorWithId(FETCH_CAMPAIGN_SUMMARY_BY_ID, 'campaignId'),

  connect(
    (state, { campaignId }) => {
      const summary = getCampaignSummaryById(campaignId, state)

      return {
        summary,
        campaignName: summary ? summary.name : '',
      }
    },
    {
      fetchCampaignSummaryById,
    },
  ),

  withEffect(
    ({ fetchCampaignSummaryById, campaignId }) => {
      fetchCampaignSummaryById(campaignId)
    },
    ({ fetchCampaignSummaryById, campaignId }) => [
      fetchCampaignSummaryById,
      campaignId,
    ],
  ),

  branch(
    ({ summary }) => !summary,
    withSpinnerWithId(FETCH_CAMPAIGN_SUMMARY_BY_ID, {
      idProps: 'campaignId',
    }),
  ),
)

export default withCampaignSummary
