import React from 'react'
import PropTypes from 'prop-types'
import ModalTitle from '../texts/ModalTitle'
import withTranslations from '../../hocs/withTranslations'

const PreviewModalContent = ({ i18n, children }) => {
  return (
    <div className="preview-modal-content">
      <ModalTitle text={i18n('checkout:publication-preview')} />
      {children}
    </div>
  )
}

PreviewModalContent.propTypes = {
  children: PropTypes.node,
  // withTranslations
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(PreviewModalContent)
