import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@redradix/components.checkbox'

const RatingCheckbox = ({
  name,
  checked,
  errorText,
  id,
  isDisabled,
  isFocused,
  isTouched,
  onBlur,
  onChange,
  onClick,
  children,
}) => {
  // Pendiente revisar el tipo del value con la próxima actualización de la librería
  return (
    <Checkbox
      name={name}
      checked={checked}
      errorText={errorText}
      id={id}
      isFocused={isFocused}
      isTouched={isTouched}
      onBlur={onBlur}
      isDisabled={isDisabled}
      onChange={isDisabled ? () => {} : onChange}
      onClick={onClick}
    >
      <span className="rating-icon-group">{children}</span>
    </Checkbox>
  )
}

RatingCheckbox.propTypes = {
  /** Boolean indicating whether the checkbox is checked */
  name: PropTypes.string.isRequired,
  /** String that defines the input error message */
  checked: PropTypes.bool.isRequired,
  /** Property indicating the value of the component */
  errorText: PropTypes.string,
  /** String that defines the input id */
  id: PropTypes.string.isRequired,
  /** Boolean indicating whether the checkbox should be rendered as disabled */
  isDisabled: PropTypes.bool,
  /** Boolean indicating whether the checkbox is focused */
  isFocused: PropTypes.bool,
  /** Boolean indicating whether the checkbox has been touched (interacted with) by the user */
  isTouched: PropTypes.bool,
  /** String that defines the input name */
  onBlur: PropTypes.func,
  /** Function that is triggered when the input is changing */
  onChange: PropTypes.func,
  /** Function that is triggered when the input is clicked */
  onClick: PropTypes.func,
}

export default RatingCheckbox
