import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import CoobisPropTypes from '../../app/common/coobis-prop-types'
import { Link } from 'react-router-dom'
import useScrollEnds from '../../hooks/useScrollEnds'
import { MediaQueryLap, MediaQueryPalm } from '../shared/MediaQueries'
import withTranslations from '../../hocs/withTranslations'
import {
  mapServiceTypeToResourceType,
  mapServiceTypeToOrderTypeI18n,
  mapServiceTypeToI18nWithoutWords,
} from '../../services/orders/business'
import DashboardPanel from '../dashboard/DashboardPanel'
import { AuthScope } from '../../services/auth/business'
import { SORT_ORDERS } from '../../app/common/withSortOrders'
import SearchInput from '../form-elements/SearchInput'
import Card from '../cards/Card'
import OrderStatus from '../orders/OrderStatus'
import OrderType from '../orders/OrderType'
import OrderActionButton from '../orders/OrderActionButton'
import OrderSort from '../orders/OrderSort'
import NoOrdersCard from '../cards/NoOrdersCard'
import { ReactComponent as ClockIcon } from '../../styles/icons/clock.svg'
import { ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID } from '../../app/common/routes'
import { NumberPagination } from '@redradix/components.pagination'
import LoadingCard from '../cards/LoadingCard'
import { isNilOrEmpty } from '../../app/utils'

const CampaignsPendingOrdersPanel = ({
  i18n,
  orders,
  isLoading,
  sortValue,
  searchValue,
  currentPage,
  totalPages,
  onPageClick,
  onSortChange,
  onSearchChange,
  onActionClick,
  hadOrders,
  className,
  isGlobal,
}) => {
  const { handleScroll, setScrollerRef, scrollEnds } = useScrollEnds({
    top: 20,
    bottom: 20,
  })

  const tableClasses = cx('orders-current-table', {
    'has-top-gradient': !scrollEnds.top,
    'has-bottom-gradient': !scrollEnds.bottom,
  })
  return (
    <DashboardPanel
      autoHeight={!orders ? false : true}
      className={className}
      isGlobal={isGlobal}
      title={
        hadOrders || isLoading
          ? i18n('noun:ongoing-orders')
          : i18n('message:no-pending-orders')
      }
    >
      <div className="campaigns-list-section">
        <div className="campaigns-list-section-actions">
          {!isNilOrEmpty(orders) ? (
            <SearchInput
              id="searchInput"
              name="searchInput"
              onChange={onSearchChange}
              value={searchValue}
              placeholder={i18n('action:search')}
            />
          ) : null}
        </div>
        {!isNilOrEmpty(orders) ? (
          <>
            <Card isHighlighted>
              <div className="orders-current-card">
                <div className={tableClasses}>
                  <div className="order-head-row">
                    <div className="order-head-row-item orders-current-table-status">
                      <OrderSort
                        text={i18n('noun:status')}
                        isActiveDesc={sortValue === SORT_ORDERS.STATUS_DESC}
                        isActiveAsc={sortValue === SORT_ORDERS.STATUS_ASC}
                        onSort={() =>
                          onSortChange(
                            // Lexicographical sort: ascending by default
                            sortValue === SORT_ORDERS.STATUS_ASC
                              ? SORT_ORDERS.STATUS_DESC
                              : SORT_ORDERS.STATUS_ASC,
                          )
                        }
                      />
                    </div>
                    <div className="order-head-row-item orders-current-table-resource">
                      <OrderSort
                        text={i18n('noun:media')}
                        isActiveDesc={sortValue === SORT_ORDERS.RESOURCE_DESC}
                        isActiveAsc={sortValue === SORT_ORDERS.RESOURCE_ASC}
                        onSort={() =>
                          onSortChange(
                            // Lexicographical sort: ascending by default
                            sortValue === SORT_ORDERS.RESOURCE_ASC
                              ? SORT_ORDERS.RESOURCE_DESC
                              : SORT_ORDERS.RESOURCE_ASC,
                          )
                        }
                      />
                    </div>
                    <div className="order-head-row-item orders-current-table-service">
                      <OrderSort
                        text={i18n('noun:service')}
                        isActiveDesc={sortValue === SORT_ORDERS.SERVICE_DESC}
                        isActiveAsc={sortValue === SORT_ORDERS.SERVICE_ASC}
                        onSort={() =>
                          onSortChange(
                            // Lexicographical sort: ascending by default
                            sortValue === SORT_ORDERS.SERVICE_ASC
                              ? SORT_ORDERS.SERVICE_DESC
                              : SORT_ORDERS.SERVICE_ASC,
                          )
                        }
                      />
                    </div>
                    <div className="order-head-row-item orders-current-table-campaign">
                      <OrderSort
                        text={i18n('noun:campaign')}
                        isActiveDesc={sortValue === SORT_ORDERS.CAMPAIGN_DESC}
                        isActiveAsc={sortValue === SORT_ORDERS.CAMPAIGN_ASC}
                        onSort={() =>
                          onSortChange(
                            // Lexicographical sort: ascending by default
                            sortValue === SORT_ORDERS.CAMPAIGN_ASC
                              ? SORT_ORDERS.CAMPAIGN_DESC
                              : SORT_ORDERS.CAMPAIGN_ASC,
                          )
                        }
                      />
                    </div>
                    <div className="order-head-row-item orders-current-table-date">
                      <OrderSort
                        text={i18n('noun:limit-date')}
                        isActiveDesc={sortValue === SORT_ORDERS.DATE_DESC}
                        isActiveAsc={sortValue === SORT_ORDERS.DATE_ASC}
                        onSort={() =>
                          onSortChange(
                            // Chronological sort: descending by default
                            sortValue === SORT_ORDERS.DATE_DESC
                              ? SORT_ORDERS.DATE_ASC
                              : SORT_ORDERS.DATE_DESC,
                          )
                        }
                      />
                    </div>
                    <div className="order-head-row-item orders-current-table-actions" />
                  </div>
                  <div
                    className="orders-current-table-content"
                    ref={setScrollerRef}
                    onScroll={handleScroll}
                  >
                    {orders.map(order => (
                      <Link
                        to={ROUTE_BRAND_CAMPAIGN_ORDER_BY_ID.linkTo({
                          orderId: order.id,
                          campaignId: order.campaign.id,
                        })}
                        className="order-row"
                        key={order.id}
                      >
                        <div className="order-row-item orders-current-table-status">
                          <OrderStatus status={order.status} />
                        </div>
                        <div
                          className="order-row-item orders-current-table-resource"
                          title={order.resource.name}
                        >
                          {order.resource.name}
                        </div>
                        <div
                          className="order-row-item orders-current-table-service"
                          title={i18n(
                            mapServiceTypeToI18nWithoutWords[order.type],
                          )}
                        >
                          <OrderType
                            i18nKey={mapServiceTypeToOrderTypeI18n[order.type]}
                            icon={mapServiceTypeToResourceType[order.type]}
                          />
                        </div>
                        <div
                          className="order-row-item orders-current-table-campaign"
                          title={order.campaign.name}
                        >
                          {order.campaign.name}
                        </div>
                        <div className="order-row-item orders-current-table-date">
                          <MediaQueryPalm>
                            <ClockIcon className="icon" />
                            {!order.dates.express
                              ? order.remainDays >= 0
                                ? i18n('message:remain-n-days', {
                                    days: order.remainDays,
                                  })
                                : i18n('message:has-expired')
                              : i18n('message:is-express')}
                          </MediaQueryPalm>
                          <MediaQueryLap>
                            {!order.dates.express
                              ? order.remainDays >= 0
                                ? i18n('message:remain-n-days', {
                                    days: order.remainDays,
                                  })
                                : i18n('message:has-expired')
                              : i18n('message:is-express')}
                          </MediaQueryLap>
                        </div>
                        <div className="order-row-item orders-current-table-actions">
                          <OrderActionButton
                            scope={AuthScope.BRAND}
                            orderStatus={order.status}
                            hasPreDelivery={order.hasPredelivery}
                            onClick={action => {
                              onActionClick(order.id, action)
                            }}
                          />
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
            {totalPages > 1 && (
              <NumberPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageClick={onPageClick}
              />
            )}
          </>
        ) : isLoading && isNilOrEmpty(orders) ? (
          <LoadingCard />
        ) : (
          <NoOrdersCard />
        )}
      </div>
    </DashboardPanel>
  )
}

CampaignsPendingOrdersPanel.propTypes = {
  orders: PropTypes.arrayOf(CoobisPropTypes.orderRow),
  isLoading: PropTypes.bool,
  sortValue: PropTypes.string,
  searchValue: PropTypes.string,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPageClick: PropTypes.func,
  onSortChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onActionClick: PropTypes.func,
  hadOrders: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
}

export default withTranslations(CampaignsPendingOrdersPanel)
