import {
  compose,
  withHandlers,
  withPropsOnChange,
  withProps,
  lifecycle,
} from 'recompose'
import { COOBIS_CURRENCY_OPTIONS } from '../../config/formats'
import { connect } from 'react-redux'
import {
  closeBalanceRechargeModal,
  getAppliedRechargeDiscountCouponData,
  validateRechargeDiscountCoupon,
  removeRechargeDiscountCoupon,
  rechargeFunds,
} from '../../services/wallet'
import withEnhancedFormik from '../../hocs/withEnhancedFormik'
import withFormErrors from '../../hocs/withFormErrors'
import withCardPaymentHandlers from '../checkout/withCartPaymentHandlers'
import withTranslations from '../../hocs/withTranslations'
import withDiscountCoupon from '../checkout/withDiscountCoupon'
import {
  VALIDATE_RECHARGE_DISCOUNT_COUPON,
  RECHARGE_FUNDS,
} from '../../services/wallet/action-types'
import { floatToMoney, moneyToFloat } from '../utils'
import AddFundsModal from '../../ui/profile/modals/AddFundsModal'
import * as Yup from 'yup'
import { i18nValidation } from '../common/validation'
import { injectStripe } from 'react-stripe-elements'

import withIsRequestPending from '../../hocs/withIsRequestPending'
import withRequestError from '../../hocs/withRequestError'
import { deleteRequest } from '../../services/communication'

import { getBrandCurrency } from '../../services/brand'

const validationSchema = Yup.object().shape({
  newAmount: Yup.number().required(i18nValidation.required),
  concept: Yup.string()
    .nullable()
    .required(i18nValidation.required),
})

const withDiscountCouponLogic = compose(
  connect(
    state => ({
      couponPaymentData: getAppliedRechargeDiscountCouponData(state),
    }),
    {
      validateDiscountCoupon: validateRechargeDiscountCoupon,
      removeAppliedCoupon: removeRechargeDiscountCoupon,
    },
  ),

  withHandlers({
    // We need to inject also amount in validateRechargeDiscountCoupon
    validateCouponWrapper: ({
      validateDiscountCoupon,
      currency,
      values,
    }) => discountCoupon => {
      const amount = floatToMoney(values.newAmount || 0, currency)
      validateDiscountCoupon(amount, discountCoupon)
    },
  }),

  withDiscountCoupon({
    couponDataProp: 'couponPaymentData',
    validateCouponProp: 'validateCouponWrapper',
    requestType: VALIDATE_RECHARGE_DISCOUNT_COUPON,
  }),

  withHandlers({
    // Get payment summary when blur on recharge amount
    onNewAmountBlur: ({
      validateCouponWrapper,
      handleBlur,
      discountCoupon,
    }) => e => {
      validateCouponWrapper(discountCoupon)
      handleBlur(e)
    },
  }),
)

const withPaymentSummary = withPropsOnChange(
  ['couponPaymentData'],
  ({ couponPaymentData }) => {
    if (!couponPaymentData) return {}

    const {
      couponAmount,
      totalPayment,
      vatAmount,
      vatRate,
      itemsSubtotal,
    } = couponPaymentData

    return {
      totalGross: itemsSubtotal,
      totalDiscount: couponAmount,
      total: totalPayment,
      vatPercentage: vatRate ? moneyToFloat(vatRate) : undefined,
      totalVat: vatAmount,
      appliedCouponCode: couponPaymentData.coupon
        ? couponPaymentData.coupon.id
        : undefined,
    }
  },
)

const enhance = compose(
  injectStripe,
  withTranslations,

  connect(
    state => ({
      currency: getBrandCurrency(state),
    }),
    { closeBalanceRechargeModal, rechargeFunds },
  ),

  withPropsOnChange(['currency'], ({ currency }) => ({
    formatOptions: {
      ...COOBIS_CURRENCY_OPTIONS,
      currency: currency.isoCode,
      symbol: currency.symbol,
    },
  })),

  withEnhancedFormik({
    isInitialValid: true,

    enableReinitialize: true,

    mapPropsToValues: () => ({
      newAmount: 0,
      concept: '',
    }),

    validationSchema,

    validateOnBlur: true,
    validateOnChange: true,

    handleSubmit: (values, { props }) => {},
  }),
  withFormErrors(['newAmount', 'concept']),

  withCardPaymentHandlers,
  withDiscountCouponLogic,
  withPaymentSummary,

  withHandlers({
    onSubmit: props => e => {
      e.preventDefault()
      e.stopPropagation()

      if (
        !props.cardNumberComplete ||
        !props.cardExpirationComplete ||
        !props.cardCVCComplete
      ) {
        props.showRequiredErrors()
        return
      }

      if (
        props.cardNumberError ||
        props.cardExpirationError ||
        props.cardCVCError
      ) {
        return
      }

      props.rechargeFunds({
        stripe: props.stripe,
        amount: floatToMoney(props.values.newAmount, props.currency),
        description: props.values.concept,
        couponCode: props.appliedCouponCode,
        totalPayment: props.total,
      })
    },
  }),
  withIsRequestPending(RECHARGE_FUNDS),
  withRequestError(RECHARGE_FUNDS),
  withProps(({ requestError }) => ({
    globalMessages: !!requestError ? requestError.globalMessages : undefined,
  })),
  // Clear payment request when Modal is Opened
  compose(
    connect(
      null,
      { deleteRequest },
    ),
    lifecycle({
      componentDidMount(prevProps) {
        this.props.deleteRequest(RECHARGE_FUNDS)
      },
    }),
  ),
)

export default enhance(AddFundsModal)
