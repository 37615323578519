import * as R from 'ramda'
import fold from 'accent-fold'
import moment from 'moment'

import {
  DATE_FORMAT_OPTIONS,
  TIME_FORMAT_OPTIONS,
  COOBIS_CURRENCIES,
  COOBIS_CURRENCY_OPTIONS,
} from '../config/formats'

import { createSelectorCreator, defaultMemoize } from 'reselect'

export const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty])

export const splitByKeys = R.converge(
  (intersection, difference) => [intersection, difference],
  [R.pick, R.omit],
)

export const arrayItemsEqual = R.pipe(
  R.zip,
  R.map(R.apply(R.equals)),
  R.all(R.identity),
)

export const entitiesArrayToKeyedObject = (entities, idKey = 'id') =>
  R.pipe(
    R.map(R.juxt([R.prop(idKey), R.identity])),
    R.fromPairs,
  )(entities)

export const truncateNumber = (num, i18nNumber) => {
  if (num >= 1000000000) {
    return (
      i18nNumber(num / 1000000000, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + 'G'
    )
  }
  if (num >= 1000000) {
    return (
      i18nNumber(num / 1000000, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + 'M'
    )
  }
  if (num >= 1000) {
    return (
      i18nNumber(num / 1000, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + 'K'
    )
  }
  return num
}

export const remainingDays = targetDate =>
  Math.ceil((+targetDate - Date.now()) / (1000 * 60 * 60 * 24))

export const ascendLocale = fn => (a, b) => fn(a).localeCompare(fn(b))

export const descendLocale = fn => (a, b) => ascendLocale(fn)(b, a)

export const isFile = obj => obj instanceof File

export const ensureFile = obj => (isFile(obj) ? obj : undefined)

export const simplifyString = string => fold(string.toLowerCase())

//Function that calls a render prop that might or not be defined so it never breaks
export const safelyRender = (renderFn, ...args) =>
  typeof renderFn === 'function' && renderFn(...args)

export const momentToISO8601WithTimezone = R.unless(
  moment => !moment,
  moment => moment.toISOString(true),
)

export const prepareMultiValueInputToSave = (mvString, prefix) =>
  splitByWhitespace(mvString)
    .map(v => removeStringPrefix(v, prefix))
    .join(',')

export const splitByWhitespace = string => string.split(/\s+/)

export const removeStringPrefix = (string, prefix) =>
  string.replace(new RegExp('^' + prefix), '')

export const trimUnnecessarySpaces = string =>
  string
    .split(/,+/)
    .map(x => x.trim())
    .filter(x => x.length > 0)
    .join(',')

export const splitByCommas = string =>
  string
    .split(/,+/)
    .map(x => x.trim())
    .filter(x => x.length > 0)

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const oneKilobyte = 1024
  const safetyDecimals = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const unitIndex = Math.floor(Math.log(bytes) / Math.log(oneKilobyte))
  const formatedNumber = parseFloat(
    (bytes / Math.pow(oneKilobyte, unitIndex)).toFixed(safetyDecimals),
  )
  const unit = sizes[unitIndex]

  return `${formatedNumber} ${unit}`
}

export const moneyToFloat = ({ integer, decimal }) =>
  Number(`${integer}.${decimal.toString().padStart(2, '0')}`)

export const floatToMoney = (float, currency) => {
  const [integer, decimal = 0] = float.toFixed(2).split('.')
  return {
    integer: Number(integer),
    decimal: Number(decimal),
    currency: currency
      ? typeof currency === 'object'
        ? currency
        : COOBIS_CURRENCIES[currency]
      : COOBIS_CURRENCIES[COOBIS_CURRENCY_OPTIONS.currency],
  }
}

export const getAmountForCurrency = (multiAmount, currency) => {
  return floatToMoney(R.prop(currency.isoCode, multiAmount), currency)
}

export const toMomentOnlyHours = dateLike =>
  moment(dateLike).set({
    minute: 0,
    second: 0,
    millisecond: 0,
  })

export const toMomentOnlyDate = dateLike =>
  moment(dateLike).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

export const formatTimeObject = ({ minutes = '', seconds = '' }) =>
  `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

export const memoize1NAry = fn => {
  let lastArgs, cachedResult

  return (...args) => {
    if (
      !lastArgs ||
      args.length !== lastArgs.length ||
      !R.equals(args, lastArgs)
    ) {
      lastArgs = args
      cachedResult = fn(...args)
    }

    return cachedResult
  }
}

export const renderDateTimeValue = (i18nDate, i18nTime, value) => {
  if (!value) {
    return undefined
  }

  const dateText = i18nDate(value.toDate(), DATE_FORMAT_OPTIONS)
  const timeText = i18nTime(value.toDate(), TIME_FORMAT_OPTIONS)
  return `${dateText} ${timeText}`
}

export const renderDateValue = (i18nDate, value) => {
  if (!value) {
    return undefined
  }

  const dateText = i18nDate(value.toDate(), DATE_FORMAT_OPTIONS)
  return `${dateText}`
}

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  R.equals,
)

export const fixChannelUrl = (url, channel) => {
  return channel === 'TIKTOK' ? fixUrlTiktok(url) : url
}

const fixUrlTiktok = url =>
  fixUrl(url).replace(/^https?:\/\/tiktok.com/i, 'https://www.tiktok.com')

const fixUrl = url =>
  url.trim().length > 8 && !/^https?:\/\//i.test(url.trim())
    ? 'https://' + url.trim()
    : url.trim()
