import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as R from 'ramda'

import Layout from '../../ui/shared/Layout'
import { AuthScope } from '../../services/auth/business'
import { logout } from '../../services/auth'
import {
  ROUTE_MEDIA_DASHBOARD,
  ROUTE_MEDIA_DASHBOARD_MATCH,
  ROUTE_MEDIA_RESOURCES,
  ROUTE_MEDIA_RESOURCES_MATCH,
  ROUTE_MEDIA_AFFILIATES,
  ROUTE_MEDIA_AFFILIATES_MATCH,
} from '../common/routes'
import { getMediaInfo } from '../../services/media'
import { getPhotoURL } from '../../services/photo/selectors'
import withActiveLink from '../common/withActiveLink'
import withPeriodicFetchWallet from '../common/withPeriodicFetchWallet'
import {
  closeOrderModal,
  getOrderReviewModalId,
  getOrderSendPredeliveryModalStatus,
  getOrderSendDeliveryModalStatus,
} from '../../services/orders'
import Modal from '../common/Modal'
import OrderModalReviewOrder from './OrderModalReviewOrder'
import OrderModalSendPredelivery from './OrderModalSendPredelivery'
import OrderModalSendDelivery from './OrderModalSendDelivery'
import { withWallet } from '../../services/wallet/hocs'
import withWithdrawFunds from '../wallet/withWithdrawFunds'
import WithdrawFundsModal from '../wallet/WithdrawFundsModal'
import withRouteServerError from '../../hocs/withRouteServerError'
import { FETCH_WITHDRAW_FUNDS_DATA } from '../../services/wallet/action-types'
import CompleteBillingInformationModal from '../../ui/profile/modals/CompleteBillingInformationModal'
import WithdrawRequestConfirmationModal from '../../ui/profile/modals/WithdrawRequestConfirmationModal'
import { getNotificationsCount } from '../../services/notifications'
import { withNotifications } from '../../services/notifications/hocs'

const MEDIA_NAVIGATION_ITEMS = [
  {
    i18nKey: 'noun:orders',
    url: ROUTE_MEDIA_DASHBOARD.linkTo(),
    match: ROUTE_MEDIA_DASHBOARD_MATCH,
  },
  {
    i18nKey: 'noun:resources',
    url: ROUTE_MEDIA_RESOURCES.linkTo(),
    match: ROUTE_MEDIA_RESOURCES_MATCH,
  },
  {
    i18nKey: 'noun:affiliates',
    url: ROUTE_MEDIA_AFFILIATES.linkTo(),
    match: ROUTE_MEDIA_AFFILIATES_MATCH,
  },
]

const MediaLayout = props => {
  const {
    children,
    handleCloseOrderModal,
    orderReviewModalId,
    orderSendPredeliveryModalStatus,
    orderSendDeliveryModalStatus,
    isWithdrawFundsModalOpen,
    isBillingDataDisclaimerModalOpen,
    isWithdrawSuccessModalOpen,
    openWithdrawFundsModal,
    closeWithdrawFundsModal,
    // Notifications
    notifications,
    isReadingAllNotifications,
    readAllNotifications,
    onNotificationClick,
    notificationCount,
  } = props

  return (
    <Layout
      {...props}
      onBalanceAction={openWithdrawFundsModal}
      notifications={notifications}
      onNotificationClick={onNotificationClick}
      onMarkAllClick={readAllNotifications}
      isMarkAllLoading={isReadingAllNotifications}
      numberAlerts={notificationCount}
    >
      {children}

      {!R.isNil(orderReviewModalId) && (
        <Modal onClose={handleCloseOrderModal}>
          <OrderModalReviewOrder orderId={orderReviewModalId} />
        </Modal>
      )}

      {!R.isNil(orderSendPredeliveryModalStatus) && (
        <Modal onClose={handleCloseOrderModal}>
          <OrderModalSendPredelivery
            orderId={orderSendPredeliveryModalStatus.orderId}
            isFix={orderSendPredeliveryModalStatus.isFix}
          />
        </Modal>
      )}

      {!R.isNil(orderSendDeliveryModalStatus) && (
        <Modal onClose={handleCloseOrderModal}>
          <OrderModalSendDelivery
            orderId={orderSendDeliveryModalStatus.orderId}
            isFix={orderSendDeliveryModalStatus.isFix}
          />
        </Modal>
      )}

      {isWithdrawFundsModalOpen && (
        <Modal isDarkThemed onClose={closeWithdrawFundsModal}>
          <WithdrawFundsModal />
        </Modal>
      )}

      {isBillingDataDisclaimerModalOpen && (
        <Modal onClose={closeWithdrawFundsModal}>
          <CompleteBillingInformationModal
            onAcceptClick={closeWithdrawFundsModal}
            onCancelClick={closeWithdrawFundsModal}
          />
        </Modal>
      )}

      {isWithdrawSuccessModalOpen && (
        <Modal onClose={closeWithdrawFundsModal}>
          <WithdrawRequestConfirmationModal />
        </Modal>
      )}
    </Layout>
  )
}

const enhance = compose(
  withRouter,

  // Add navigation links depending on user type
  connect(
    state => ({
      myInfo: getMediaInfo(state),
      userAvatar: getPhotoURL(state),
      notificationCount: getNotificationsCount(state),

      orderReviewModalId: getOrderReviewModalId(state),
      orderSendPredeliveryModalStatus: getOrderSendPredeliveryModalStatus(
        state,
      ),
      orderSendDeliveryModalStatus: getOrderSendDeliveryModalStatus(state),
    }),
    {
      onLogout: logout,
      handleCloseOrderModal: closeOrderModal,
    },
  ),

  withPropsOnChange(['myInfo'], ({ myInfo }) => {
    if (!myInfo) {
      return {
        isPublic: true,
        navItems: [],
        userName: undefined,
        useProfile: undefined,
        scope: undefined,
      }
    }

    return {
      isPublic: false,
      isMedia: true,
      navItems: MEDIA_NAVIGATION_ITEMS,
      userName: myInfo.name,
      userProfile: 'user-dropdown:profile-media',
      scope: AuthScope.MEDIA,
    }
  }),

  withActiveLink,
  withNotifications,
  withWallet,
  withPeriodicFetchWallet,
  withWithdrawFunds,

  withRouteServerError(FETCH_WITHDRAW_FUNDS_DATA),
)

export default enhance(MediaLayout)
